import { CreateFormButton, EmptyState } from '@Shared/DS';
import { Meh } from '@Shared/Icons';

export const NoForms = () => (
  <EmptyState
    button={<CreateFormButton />}
    description={
      <>
        <p>It looks like you don’t have any forms created yet.</p>
        <p>
          To get started, create a new form that you can add to your runbook and
          start collecting the data you need.
        </p>
      </>
    }
    icon={<Meh />}
    title="Oops, no forms available..."
  />
);
