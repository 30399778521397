import type { ISvgIcon } from './types';

export const LogoIcon = ({
  className,
  width = '136.426',
  height = '24',
}: ISvgIcon) => (
  <svg
    className={className}
    height={height}
    viewBox="0 0 136.426 24"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Path_63"
      data-name="Path 63"
      d="M12.237,11.175l9.22,12.14H14.83L8.4,15.047,5.347,17.672v5.643H0V.676H5.347V8.584h.819L12.992.676h8.267ZM22.99,23.315h5.347V.676H22.99ZM46.265,12.521h-.59L37.21.676H31.4V23.315H36.75V11.471h.59l8.433,11.844h5.808V.676H46.265Zm8.381,10.795h5.348V15.342H69.41v-6.3H59.993V6.976h10.6V.676H54.646ZM92.189,3.458c4.71,4.71,4.593,11.971-.255,16.82-4.873,4.871-12.11,4.964-16.819.255S70.5,8.585,75.369,3.714c4.849-4.849,12.111-4.965,16.82-.256m-3.9,3.9c-2.135-2.134-5.731-1.739-8.376.9s-3.039,6.241-.9,8.376,5.731,1.74,8.376-.9,3.039-6.241.9-8.376M103.18.678H97.832V23.317h15.289v-6.3H103.18Zm24.026,10.5,9.022-10.5H127.96l-6.825,7.907h-.82V.678h-5.348V23.317h5.348V17.674l3.051-2.625,6.431,8.268h6.628Z"
      transform="translate(0.001 0)"
      fill="#25272A"
    />
  </svg>
);
