import { BlockBuilder } from '@Shared/Components/BlockBuilder/BlockBuilder';
import { BlocksLoop } from '@Shared/Components/BlocksLoop/BlocksLoop';
import { EmbeddedContentModal } from '@Shared/Components/BlockTypes/EmbeddedContent/EmbeddedContentModal/EmbeddedContentModal';
import { EditAlertModal } from '@Shared/Components/EditAlertModal/EditAlertModal';
import { Milestones } from '@Shared/DS';
import { scrollToTop } from '@Shared/utils/utils';
import Loader from '@Shared/Components/Loader/Loader';
import { useTemplateBuilder } from './useTemplateBuilder';
import { TemplateHeader } from './Components/TemplateHeader/TemplateHeader';

export const TemplateBuilder = () => {
  const {
    currentTemplate,
    isEditable,
    showBlockBuilder,
    selectedMilestoneIndex,
    milestones,
    showEmbeddedContentModal,
    showEditModal,
    showLoader,
    showSaved,
    isBusy,
    selectedTab,
    isTemplateLoading,
    setSelectedTab,
    setIsBusy,
    setShowEmbeddedContentModal,
    setSelectedMilestoneIndex,
    setShowBlockBuilder,
    addNewBlock,
    handleMilestoneTextChange,
    addNewMilestone,
    removeMilestone,
    handleBack,
    handleUpdateMilestoneBlocks,
    addEmbeddedContentBlock,
    setShowEditModal,
    handleDraftTemplate,
    setShowLoader,
    setShowSaved,
    updateDeletedBlock,
    setMilestones,
    handleReorderMilestones,
    viewTemplate,
    exitPreview,
  } = useTemplateBuilder();

  return (
    <div className="container-fluid builder-view-wrapper">
      {isTemplateLoading ? (
        <Loader active={isTemplateLoading} />
      ) : (
        <div className="row">
          <Milestones
            milestones={milestones}
            selectedMilestoneIndex={selectedMilestoneIndex}
            setSelectedMilestoneIndex={setSelectedMilestoneIndex}
            handleMilestoneTextChange={handleMilestoneTextChange}
            addNewMilestone={addNewMilestone}
            removeMilestone={removeMilestone}
            isEditable={isEditable}
            mode={currentTemplate.mode}
            isParentPlaybook={true}
            handleReorderMilestones={handleReorderMilestones}
            isBusy={isBusy}
          />
          <div
            className={
              isEditable
                ? `col-12 col-lg-9 col-xl-10`
                : `col-12 col-lg-12 col-xl-12`
            }
          >
            <TemplateHeader
              templateName={currentTemplate.name}
              activeTab={selectedTab}
              isEditable={isEditable}
              showLoader={showLoader}
              showSaved={showSaved}
              onTabClick={setSelectedTab}
              onBack={handleBack}
              onViewTemplate={viewTemplate}
              exitPreview={exitPreview}
            />
            <div className={`col-12 col-lg-12 col-xl-12`}>
              <div className="container">
                <div className="row px-5 builder-content mt-3 mb-5">
                  <div className="col-12">
                    <div className="builder-title">
                      {milestones.length &&
                        milestones[selectedMilestoneIndex].name}
                    </div>
                    <BlocksLoop
                      milestones={milestones}
                      isParentPlaybook={true}
                      selectedMilestoneIndex={selectedMilestoneIndex}
                      isEditable={isEditable}
                      handleUpdateMilestoneBlocks={handleUpdateMilestoneBlocks}
                      setShowLoader={setShowLoader}
                      setShowSaved={setShowSaved}
                      handleDeleteBlock={updateDeletedBlock}
                      isBusy={isBusy}
                      setMilestones={setMilestones}
                      setIsBusy={setIsBusy}
                    />

                    <div
                      className={`col-12 next-milestone d-flex ${
                        selectedMilestoneIndex > 0
                          ? 'justify-content-between'
                          : 'justify-content-end'
                      } mt-4 me-4`}
                    >
                      {selectedMilestoneIndex > 0 && (
                        <button
                          className="btn border-0"
                          onClick={() => {
                            setSelectedMilestoneIndex(
                              selectedMilestoneIndex - 1,
                            );
                            scrollToTop();
                          }}
                        >
                          <i className="bi bi-chevron-left cursor-pointer"></i>{' '}
                          {milestones[selectedMilestoneIndex - 1].name}
                        </button>
                      )}

                      {selectedMilestoneIndex + 1 < milestones.length && (
                        <button
                          className="btn border-0"
                          onClick={() => {
                            setSelectedMilestoneIndex(
                              selectedMilestoneIndex + 1,
                            );
                            scrollToTop();
                          }}
                        >
                          {milestones[selectedMilestoneIndex + 1].name}{' '}
                          <i className="bi bi-chevron-right cursor-pointer"></i>
                        </button>
                      )}
                    </div>

                    {isEditable && (
                      <div className="col-12 mb-4">
                        {!showBlockBuilder ? (
                          <button
                            className="btn btn-green ms-5 rounded-pill fs-16-medium-bold py-3 px-5 mt-5"
                            onClick={() => setShowBlockBuilder(true)}
                          >
                            Add new block
                          </button>
                        ) : (
                          <div className="col-12 cursor-pointer mt-4">
                            <BlockBuilder addNewBlock={addNewBlock} />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <EmbeddedContentModal
              showModal={showEmbeddedContentModal}
              handleShowModal={setShowEmbeddedContentModal}
              addEmbeddedContentBlock={addEmbeddedContentBlock}
            />
            <EditAlertModal
              title="Edit template"
              description="Your template will be unpublished while you make your edits.
        You will need to click to publish it again for other people to find it."
              showModal={showEditModal}
              handleShowModal={setShowEditModal}
              handleProceed={handleDraftTemplate}
              showLoader={showLoader}
            />
          </div>
        </div>
      )}
    </div>
  );
};
