import { type ICreateUpdateAction } from '@base/models/journeyLibrary.model';
import { Offcanvas } from 'react-bootstrap';
import { CreateUpdateFormEventPanelContent } from './CreateUpdateFormEventPanelContent';

export const CreateUpdateFormEventPanel = (props: ICreateUpdateAction) => {
  const { actionEvent: event, showModal, handleShowModal } = props;

  return (
    <Offcanvas
      className="create-update-action-wrapper"
      backdrop="static"
      placement="end"
      keyboard={false}
      show={showModal}
      onHide={() => handleShowModal(false)}
    >
      <Offcanvas.Header closeButton className="px-3">
        <Offcanvas.Title>
          {event.id ? 'Update form event' : 'Send a form'}
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="px-3 mt-4">
        <CreateUpdateFormEventPanelContent {...props} />
      </Offcanvas.Body>
    </Offcanvas>
  );
};
