import { UserType } from '@base/API';
import { type InviteNewEmployeeParams } from '@base/models/settings.model';

export const emptyParams: InviteNewEmployeeParams = {
  firstName: '',
  lastName: '',
  email: '',
  startDate: '',
  isNewHire: true,
  sendWelcomeEmail: true,
  type: UserType.COMPANY_MEMBER,
};
