import { createUserDigest, deleteUserDigest, listUserDigest } from '@api/apis';
import { Channel, type Digest, DigestType, Frequency } from '@base/API';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { Keys } from '@base/keys/queryKeys';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

export const useNotificationSettings = () => {
  const { user } = useUserContext();
  type IDigest = Digest & { isSubscribed: boolean };
  const [issueNotificationDigest, setIssueNotificationDigest] = useState<
    Partial<IDigest>
  >({
    frequency: Frequency.DAILY,
    channel: Channel.EMAIL,
    isSubscribed: false,
  });
  const [isBusy, setIsBusy] = useState(false);

  useQuery({
    queryKey: Keys.getUserDigests(user.id),
    queryFn: async () => {
      const userDigests = await listUserDigest({ userId: { eq: user.id } });

      if (!userDigests) {
        return [];
      }

      const issueNotificationDigest = userDigests.find(
        (digest) => digest.type === DigestType.ISSUE_NOTIFICATIONS,
      );
      if (!issueNotificationDigest) {
        return userDigests;
      }

      setIssueNotificationDigest({
        ...issueNotificationDigest,
        isSubscribed: true,
        frequency: (issueNotificationDigest.frequency.charAt(0) +
          issueNotificationDigest.frequency
            .slice(1)
            .toLowerCase()) as Frequency,
        channel: (issueNotificationDigest.channel.charAt(0) +
          issueNotificationDigest.channel.slice(1).toLowerCase()) as Channel,
      });
    },
    enabled: !!user.id,
  });

  const saveIssueNotificationSettings = async (value: boolean) => {
    setIsBusy(true);

    // TODO: Uncomment this code when other controllers are enabled i.e frequency, channel
    // if (issueNotificationDigest.id) {
    //   if (issueNotificationDigest.isSubscribed) {
    //     await updateUserDigest({
    //       ...issueNotificationDigest,
    //       frequency: issueNotificationDigest.frequency?.toUpperCase(),
    //       channel: issueNotificationDigest.channel?.toUpperCase(),
    //     } as Digest);
    //   } else {
    //     setIssueNotificationDigest({ isSubscribed: false });
    //     await deleteUserDigest(issueNotificationDigest.id);
    //   }
    // } else {
    //   const digestId = await createUserDigest({
    //     ...issueNotificationDigest,
    //     organizationId: user.userOrganizationId,
    //     userId: user.id,
    //     frequency: issueNotificationDigest.frequency?.toUpperCase(),
    //     channel: issueNotificationDigest.channel?.toUpperCase(),
    //     type: DigestType.ISSUE_NOTIFICATIONS,
    //   } as Digest);

    //   if (digestId) {
    //     setIssueNotificationDigest({ ...issueNotificationDigest, id: digestId });
    //   }
    // }

    if (value) {
      const digestId = await createUserDigest({
        ...issueNotificationDigest,
        organizationId: user.userOrganizationId,
        userId: user.id,
        frequency: issueNotificationDigest.frequency?.toUpperCase(),
        channel: issueNotificationDigest.channel?.toUpperCase(),
        type: DigestType.ISSUE_NOTIFICATIONS,
      } as Digest);

      if (digestId) {
        setIssueNotificationDigest({
          ...issueNotificationDigest,
          id: digestId,
          isSubscribed: true,
        });
      }
    } else if (issueNotificationDigest.id) {
      await deleteUserDigest(issueNotificationDigest.id);
      setIssueNotificationDigest({
        isSubscribed: false,
        frequency: Frequency.DAILY,
        channel: Channel.EMAIL,
      });
    }

    setIsBusy(false);
  };

  return {
    issueNotificationDigest,
    setIssueNotificationDigest,
    isBusy,
    saveIssueNotificationSettings,
  };
};
