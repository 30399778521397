/* eslint-disable jsx-a11y/anchor-is-valid */
import { formatDistanceToNowStrict } from 'date-fns';
import { BuilderType, type Journey } from '@API';
import './PlaybookCard.scss';
import { memo } from 'react';
import { formateDate } from '@Shared/utils/utils';
import { InfoModal } from '@Shared/Components/InfoModal/InfoModal';
import { BsPeople } from 'react-icons/bs';
import { GoKebabHorizontal } from 'react-icons/go';
import { CopyPlaybookModal } from '../CopyPlaybookModal/CopyPlaybookModal';
import { usePlaybookCard } from './usePlaybookCard';

interface PlaybookCardProps {
  journey: Journey;
  journeyStartDate?: string | null;
  handleViewPlaybook: Function;
  handleArchiveJourney: Function;
  handleUpdateJourneys: Function;
  backgroundImage: string;
}

export const PlaybookCard = memo((props: PlaybookCardProps) => {
  const {
    totalPlaybooks,
    showCopyJourneyModal,
    showModalToConfirmArchive,
    setShowModalToConfirmArchive,
    setShowCopyJourneyModal,
    archiveJourney,
  } = usePlaybookCard(props.journey, props.handleArchiveJourney);

  return (
    <div className="playbook-card-wrapper">
      <div
        className="pc d-flex flex-column justify-content-between mb-4"
        style={{ backgroundImage: `url(${props.journey.image})` }}
      >
        <div className="pc__head cursor-pointer">
          <div className="dropdown">
            <button
              className="btn btn-dropdown focus-ring"
              type="button"
              id="dropdown-options"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <GoKebabHorizontal />
            </button>
            <ul
              className="dropdown-menu dropdown-menu-end"
              aria-labelledby="dropdown-options"
            >
              <li>
                <a
                  className="dropdown-item"
                  onClick={() => setShowModalToConfirmArchive(true)}
                >
                  Archive
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  onClick={() => setShowCopyJourneyModal(true)}
                >
                  Copy
                </a>
              </li>
            </ul>
          </div>
          <div
            className="pc__title"
            onClick={() => props.handleViewPlaybook(props.journey)}
          >
            {props.journey.name?.replaceAll('&amp;', '&')}
          </div>
        </div>
        <div
          className="w-100 h-100 cursor-pointer"
          onClick={() => props.handleViewPlaybook(props.journey)}
        ></div>
        <div className="pc__footer d-flex justify-content-between">
          <span>
            {props.journey.type === BuilderType.INITIATIVE
              ? props.journeyStartDate
                ? `Start: ${formateDate(new Date(props.journeyStartDate), true)}`
                : 'No start date'
              : `Updated ${formatDistanceToNowStrict(props.journey.updatedAt, { addSuffix: true })}`}
          </span>
          <span title="Number of users assigned">
            <BsPeople />{' '}
            {totalPlaybooks === 0 ? (
              <span className="no-assignee">No assignee</span>
            ) : (
              `${totalPlaybooks}`
            )}
          </span>
        </div>
      </div>

      <CopyPlaybookModal
        showModal={showCopyJourneyModal}
        handleShowModal={(value: boolean) => setShowCopyJourneyModal(value)}
        journey={props.journey}
        handleUpdateJourneys={props.handleUpdateJourneys}
      />

      <InfoModal
        title={`Archive "${props.journey.name}"`}
        description="Are you sure you want to archive this runbook?"
        size="md"
        showModal={showModalToConfirmArchive}
        onClickConfirmButton={archiveJourney}
        handleShowModal={(value: boolean) =>
          setShowModalToConfirmArchive(value)
        }
      ></InfoModal>
    </div>
  );
});
