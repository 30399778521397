import { EventStatus } from '@API';
import { StatusPill } from '@Shared/DS/Atoms/StatusPill';

interface EventStatusPillProps {
  status: EventStatus;
  className?: string;
}

export const EventStatusPill = (props: EventStatusPillProps) => {
  const { status, className } = props;

  switch (status) {
    case EventStatus.DONE:
      return (
        <StatusPill className={className} variant="completed">
          Done
        </StatusPill>
      );
    case EventStatus.SENT:
      return (
        <StatusPill className={className} variant="completed">
          Sent
        </StatusPill>
      );
    case EventStatus.SCHEDULED:
      return (
        <StatusPill className={className} variant="scheduled">
          Scheduled
        </StatusPill>
      );
    case EventStatus.DRAFT:
      return (
        <StatusPill className={className} variant="notStarted">
          Draft
        </StatusPill>
      );
    case EventStatus.SKIPPED:
      return (
        <StatusPill className={className} variant="skipped">
          Skipped
        </StatusPill>
      );
    case EventStatus.FAILED:
      return (
        <StatusPill className={className} variant="skipped">
          Failed
        </StatusPill>
      );
    case EventStatus.OVERDUE:
      return (
        <StatusPill className={className} variant="skipped">
          Overdue
        </StatusPill>
      );

    default:
      return <div></div>;
  }
};
