import '@Shared/scss/global.scss';
import '@Shared/scss/deprecated-general.scss';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import * as Sentry from '@sentry/react';
import reportWebVitals from './reportWebVitals';
import App from './App';
import { Analytics } from './analytics/analytics';

export const BUILD_TRIGGER = 1;
const INTERCOM_APP_ID = 'kfabnp1p';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost'],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

Analytics.init({
  token: process.env.REACT_APP_MIXPANEL_TOKEN ?? '',
  trackPageView: process.env.REACT_APP_MIXPANEL_TRACK_PAGEVIEW === 'true',
  debug: process.env.REACT_APP_MIXPANEL_DEBUG === 'true',
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <IntercomProvider appId={INTERCOM_APP_ID}>
      <Sentry.ErrorBoundary
        fallback={
          <div>
            Something went wrong. Please refresh the page and try again.
          </div>
        } // TODO: This can be a nicer UI fallback component
        onError={(e) => Analytics.clientError(e)}
      >
        <App />
      </Sentry.ErrorBoundary>
      <ToastContainer />
    </IntercomProvider>
  </BrowserRouter>,
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
