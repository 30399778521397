import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { updateJourney } from '@api/apis';
import { type Journey } from '@base/API';

export const usePlaybookShareModal = (playbookLink?: string | null) => {
  const [link, setLink] = useState<string | null | undefined>();
  const [isBusy, setIsBusy] = useState(false);
  const { id } = useParams();

  const createAndSavePlaybookLink = async () => {
    if (id) {
      const currentURL = window.location.href;
      let link = `${new URL(currentURL).origin}/invite-pub/${Date.now()}`;

      if (link.includes('localhost')) {
        link = `https://staging.d2d6twmbjv95bv.amplifyapp.com/invite-pub/${Date.now()}`;
      }

      setIsBusy(true);
      const res = await updateJourney({ id, publicLink: link } as Journey);

      if (res) {
        setLink(link);
      }
      setIsBusy(false);
    }
  };

  const disablePlaybookLink = async () => {
    if (id) {
      setIsBusy(true);
      const res = await updateJourney({ id, publicLink: null } as Journey);

      if (res) {
        setLink(null);
      }
      setIsBusy(false);
    }
  };

  useEffect(() => {
    setLink(playbookLink);
  }, [playbookLink]);

  return {
    link,
    isBusy,
    createAndSavePlaybookLink,
    disablePlaybookLink,
  };
};
