import { useIframelyEmbed } from './useIframelyEmbed';

interface IframelyProps {
  updatedUrl: string;
  iframeLoaded: boolean;
  setIframeLoaded: Function;
}
export const Iframely = (props: IframelyProps) => {
  const updatedUrl = props.updatedUrl;
  const { useIframely, html, showError } = useIframelyEmbed(
    props.updatedUrl,
    props.iframeLoaded,
    props.setIframeLoaded,
  );

  if (props.iframeLoaded) {
    return (
      <>
        {useIframely ? (
          <>
            {!showError ? (
              <div>
                <div dangerouslySetInnerHTML={html} />
              </div>
            ) : (
              <h6 className="no-preview-iframe-link">
                URL preview not available.{' '}
                <a href={updatedUrl}>Click here to navigate</a>
              </h6>
            )}
          </>
        ) : (
          <>
            <iframe
              className="w-100 iframe-radius videoWrapper-iframe"
              src={updatedUrl}
              title="Embed player"
              scrolling="auto"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </>
        )}
      </>
    );
  } else {
    return null;
  }
};
