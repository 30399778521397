import { PlaybookTypeCard } from '@Shared/DS/Molecules';
import { PlaybookMode } from '@base/API';
import { LuWorkflow } from 'react-icons/lu';
import { MdImportantDevices } from 'react-icons/md';

interface ICreateTemplateStepTwo {
  playbookMode: PlaybookMode | null;
  setPlaybookMode: (type: PlaybookMode) => void;
}

export const CreateTemplateStepTwo = ({
  playbookMode,
  setPlaybookMode,
}: ICreateTemplateStepTwo) => {
  const playbookTypes = [
    {
      title: 'In app',
      icon: <MdImportantDevices size={65} />,
      description: 'For an interactive experience, plus messages',
      selected: playbookMode === PlaybookMode.IMMERSIVE,
      handleClick: () => setPlaybookMode(PlaybookMode.IMMERSIVE),
    },
    {
      title: 'In workflows',
      icon: <LuWorkflow size={65} />,
      description: 'Only messages in email and Slack/Teams',
      selected: playbookMode === PlaybookMode.WORKFLOW,
      handleClick: () => setPlaybookMode(PlaybookMode.WORKFLOW),
    },
  ];

  return (
    <div>
      <div className="row flex-nowrap">
        {playbookTypes.map((playbookType, index) => (
          <PlaybookTypeCard key={index} {...playbookType} className="col-6" />
        ))}
      </div>
    </div>
  );
};
