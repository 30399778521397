interface CrossButtonProps {
  handleClick: Function;
  isDisabled?: boolean;
}

export const CrossButton = (props: CrossButtonProps) => {
  const { handleClick, isDisabled } = props;

  return (
    <svg
      onClick={() => (!isDisabled ? handleClick() : () => {})}
      className={` ${!isDisabled ? 'modal-close-btn cursor-pointer' : 'modal-close-btn--disabled'}`}
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z"
        fill={isDisabled ? '#A0A6AB' : 'white'}
      />
      <path
        d="M33.6592 16.3408C33.5512 16.2327 33.423 16.147 33.2818 16.0886C33.1407 16.0301 32.9894 16 32.8367 16C32.6839 16 32.5327 16.0301 32.3915 16.0886C32.2504 16.147 32.1222 16.2327 32.0142 16.3408L25.0002 23.3548L17.9852 16.3408C17.765 16.1334 17.4728 16.0199 17.1704 16.0244C16.8679 16.0289 16.5792 16.151 16.3653 16.3649C16.1515 16.5788 16.0293 16.8675 16.0248 17.1699C16.0203 17.4724 16.1338 17.7646 16.3412 17.9848L23.3552 24.9998L16.3412 32.0148C16.123 32.2329 16.0005 32.5288 16.0005 32.8373C16.0005 33.1458 16.123 33.4416 16.3412 33.6598C16.5593 33.8779 16.8552 34.0005 17.1637 34.0005C17.4722 34.0005 17.768 33.8779 17.9862 33.6598L25.0002 26.6448L32.0152 33.6598C32.2333 33.8779 32.5292 34.0005 32.8377 34.0005C33.1462 34.0005 33.442 33.8779 33.6602 33.6598C33.8783 33.4416 34.0009 33.1458 34.0009 32.8373C34.0009 32.5288 33.8783 32.2329 33.6602 32.0148L26.6452 24.9998L33.6602 17.9848C33.8779 17.7666 34.0001 17.4708 33.9999 17.1626C33.9998 16.8543 33.8772 16.5587 33.6592 16.3408Z"
        fill={isDisabled ? 'white' : '#25272A'}
      />
    </svg>
  );
};
