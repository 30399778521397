import { UserTypeDropDown } from '@Shared/Components/DropDownField/DropDownField';
import { Form } from 'react-bootstrap';
import { Button, TextField, ToggleSwitch } from '@Shared/DS';
import { useExistingEmployee } from './useExistingEmployee';
import './ExistingEmployee.scss';

interface ExistingEmployeeProps {
  handleShowModal: Function;
}

export const ExistingEmployee = (props: ExistingEmployeeProps) => {
  const {
    state,
    isSubmitted,
    isLoading,
    handleChange,
    handleSubmit,
    checkEmailAlreadyExist,
  } = useExistingEmployee(props.handleShowModal);
  const roles = {
    COMPANY_MEMBER: 'Member',
    COMPANY_ADMIN: 'Admin',
  };

  return (
    <div className="row existing-employee">
      <p className="heading">
        A welcome email will be sent to employees with login instructions. If
        you don't need them to log in now, turn off 'Send welcome email' and
        send a sign up link later.
      </p>

      <form className="mt-3" onSubmit={(e) => handleSubmit(e)}>
        <label
          className="fs-14-semi-bold col-12 col-lg-8 text-start d-none d-lg-inline-block"
          htmlFor="email"
        >
          Email address
        </label>
        <label
          className="fs-14-semi-bold col-12 col-lg-4 text-start px-3 d-none d-lg-inline-block"
          htmlFor="role"
        >
          Role
        </label>

        <div className="row mt-2 mt-lg-0">
          <div className="col-12 col-lg-8 px-2">
            <label
              className="fs-14-semi-bold text-start d-block d-lg-none"
              htmlFor="email"
            >
              Email address
            </label>
            <TextField
              id={'email'}
              type="email"
              defaultValue={state.email}
              required
              setValue={(value: string) => handleChange(value, 'email')}
              fieldName="Email"
              errorMessage={
                state.email && checkEmailAlreadyExist()
                  ? `${state.email} already exists`
                  : undefined
              }
              placeholder="Work email address"
              additionalClasses={`${(state.email.length === 0 || checkEmailAlreadyExist()) && isSubmitted ? 'is-invalid' : ''}`}
            />
          </div>

          <div className="col-12 col-lg-4 px-2">
            <label
              className="fs-14-semi-bold text-start mt-2 d-block d-lg-none"
              htmlFor="role"
            >
              Role
            </label>
            <UserTypeDropDown
              items={Object.values(roles)}
              disabled={false}
              firstItemIndex={0}
              onItemChange={(value: string) =>
                handleChange(
                  //@ts-ignore
                  Object.keys(roles).find((key) => roles[key] === value),
                  'role',
                )
              }
            />
          </div>
        </div>

        <Form.Group className="d-flex gap-3 align-items-center col-12 mt-2">
          <label
            className="fs-14-semi-bold text-start form-label mt-2"
            htmlFor="send-welcome-email"
          >
            Send welcome email
          </label>
          <ToggleSwitch
            initialValue={state.sendWelcomeEmail}
            setValue={() =>
              handleChange(!state.sendWelcomeEmail, 'sendWelcomeEmail')
            }
          />
        </Form.Group>

        <hr className="mt-4" />
        <div className="d-flex justify-content-end">
          <Button
            buttonStyle="outline"
            text="Cancel"
            onClick={() => {
              props.handleShowModal(false);
            }}
          />
          <Button
            disabled={isLoading}
            showLoading={isLoading}
            text="Invite"
            additionalClasses="ms-2"
            type="submit"
          />
        </div>
      </form>
    </div>
  );
};
