import { type ReactNode } from 'react';
import clsx from 'clsx';
import styles from './StatusPill.module.scss';

interface StatusPillProps {
  children?: ReactNode;
  className?: string;
  variant: 'completed' | 'inProgress' | 'notStarted' | 'skipped' | 'scheduled';
}

// TODO: refactor to Pill component
export const StatusPill = ({
  children,
  className,
  variant,
}: StatusPillProps) => (
  <div className={clsx(styles.statusPill, styles[variant], className)}>
    {children}
  </div>
);
