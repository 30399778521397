import type * as APITypes from '../API';
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

// Custom Queries
export const listDetailedUsers = /* GraphQL */ `
  query listDetailedUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        email
        jobTitle
        birthday
        startDate
        homeLocation
        team
        isActive
        photoUrl
        pronoun
        aboutMe
        mostHappy
        socialMediaUrl
        slackUserId
        type
        isFirstLogin
        isHomeModelViewed
        isProfileModelViewed
        Organization {
          id
          name
          preboarding
          slackAccessToken
          mergeCompanyToken
          outlookTenantId
          msTeamsTenantId
          createdAt
          updatedAt
          activeUntil
          hasSubscription
          features
        }
        WorkLocation {
          id
          name
          streetAddress
          city
          state
          zipCode
          country
          organizationID
          createdAt
          updatedAt
        }
        Interests {
          items {
            id
            interestID
            interest {
              id
              name
            }
          }
        }

        WorkPreference {
          id
          giveMeAllTheContext
          messageTimingPreferene
          syncxVsAsync
          talkTogetherVsAlone
          recognitionPrivateVsPrivate
          createdAt
          updatedAt
        }

        WorkStyle {
          id
          whatIDoAtWork
          myFocusTimeIs
          giveReceivingFeedback
          digestionInformation
          inMyElement
          createdAt
          updatedAt
        }

        lastName
        ShareInfoOn {
          items {
            id
            shareInfoID
            shareInfo {
              id
              name
            }
          }
        }
        UserBadges {
          items {
            createdAt
            id
            updatedAt
            playbookId
            userID
          }
        }
        LearnMoreOn {
          items {
            id
            shareInfoID
            shareInfo {
              id
              name
            }
          }
        }
        createdAt
        updatedAt
        userOrganizationId
        userWorkPreferenceId
        userWorkStyleId
      }
      nextToken
    }
  }
`;

export const getCompanyAdminOfOrganization = /* GraphQL */ `
  query getCompanyAdminOfOrganization(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        email
        Organization {
          name
        }
      }
    }
  }
`;
export const listInitialUsers = /* GraphQL */ `
  query listInitialUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        email
        personalEmail
        jobTitle
        photoUrl
        type
        isFirstLogin
        slackUserId
        homeLocation
        isActive
        team
        startDate
        userOrganizationId
      }
    }
  }
`;

export const listDetailedUsersWithNameAndId = /* GraphQL */ `
  query listDetailedUsersWithNameAndId(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        email
        team
        personalEmail
        photoUrl
        startDate
        userOrganizationId
        slackUserId
        Interests {
          items {
            interest {
              name
            }
          }
        }
      }
    }
  }
`;

export const listDetailedUsersWithPhotoUrl = /* GraphQL */ `
  query listDetailedUsersWithPhotoUrl(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        photoUrl
      }
    }
  }
`;

export const getUserProfileData = /* GraphQL */ `
  query getUserProfileData($id: ID!) {
    getUser(id: $id) {
      id
      firstName
      email
      jobTitle
      birthday
      startDate
      homeLocation
      team
      photoUrl
      pronoun
      aboutMe
      mostHappy
      socialMediaUrl
      slackUserId
      type
      Organization {
        id
        name
        preboarding
        slackAccessToken
        mergeCompanyToken
        createdAt
        updatedAt
      }
      WorkLocation {
        id
        name
        streetAddress
        city
        state
        zipCode
        country
        organizationID
        createdAt
        updatedAt
      }
      Interests {
        items {
          id
          interestID
          interest {
            id
            name
          }
        }
      }
      WorkPreference {
        id
        giveMeAllTheContext
        messageTimingPreferene
        syncxVsAsync
        talkTogetherVsAlone
        recognitionPrivateVsPrivate
        createdAt
        updatedAt
      }

      WorkStyle {
        id
        whatIDoAtWork
        myFocusTimeIs
        giveReceivingFeedback
        digestionInformation
        inMyElement
        createdAt
        updatedAt
      }

      lastName
      ShareInfoOn {
        items {
          id
          shareInfoID
          shareInfo {
            id
            name
          }
        }
      }

      LearnMoreOn {
        items {
          id
          shareInfoID
          shareInfo {
            id
            name
          }
        }
      }
      UserBadges {
        items {
          createdAt
          id
          updatedAt
          playbookId
          userID
        }
      }
      createdAt
      updatedAt
      userOrganizationId
      userWorkPreferenceId
      userWorkStyleId
    }
  }
`;

export const createMilestoneWithMilestoneIdReturn = /* GraphQL */ `
  mutation createMilestoneWithMilestoneIdReturn(
    $input: CreateMilestoneInput!
    $condition: ModelMilestoneConditionInput
  ) {
    createMilestone(input: $input, condition: $condition) {
      id
    }
  }
`;

export const listMilestoneWithBlocksByJourneyId =
  /* GraphQL */ `query listMilestoneWithBlocksByJourneyId(
  $journeyID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelMilestoneFilterInput
  $limit: Int
  $nextToken: String
) {
  listMilestoneByJourneyId(
    journeyID: $journeyID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      isCompleted
      parentMilestoneID
      orderNo
      isArchived
      EmbeddedContentBlocks(filter: { isArchived: { ne: true } }) {
        items {
          id
          isCompleted
          milestoneID
          description
          orderNo
          resourceLink
          isFormLinked
          title
          type
        }
      }
      FreeTextQuestionBlocks(filter: { isArchived: { ne: true } }) {
        items {
          description
          id
          isCompleted
          milestoneID
          orderNo
          text
          title
          type
        }
      }
      MultipleChoiceQuestionBlocks(filter: { isArchived: { ne: true } }) {
        items {
          answer
          description
          id
          isCompleted
          isRequired
          milestoneID
          option
          orderNo
          title
          type
        }
      }
      NoteBlocks(filter: { isArchived: { ne: true } }) {
        items {
          description
          id
          isCompleted
          isPrivate
          milestoneID
          orderNo
          text
          title
          type
        }
      }
      StandardContentBlocks(filter: { isArchived: { ne: true } }) {
        items {
          title
          orderNo
          milestoneID
          isCompleted
          id
          description
          type
        }
      }
      TaskBlocks(filter: { isArchived: { ne: true } }) {
        items {
          description
          id
          isCompleted
          milestoneID
          orderNo
          status
          title
          type
        }
      }
      createdAt
      updatedAt
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListMilestoneByJourneyIdQueryVariables,
    APITypes.ListMilestoneByJourneyIdQuery
  >;

export const listMilestoneWithBlocksByKinfolkTemplateId =
  /* GraphQL */ `query listMilestoneWithBlocksByKinfolkTemplateId(
  $kinfolkTemplateID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelMilestoneFilterInput
  $limit: Int
  $nextToken: String
) {
  listMilestoneByKinfolkTemplateId(
    kinfolkTemplateID: $kinfolkTemplateID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      isCompleted
      parentMilestoneID
      orderNo
      isArchived
      EmbeddedContentBlocks(filter: { isArchived: { ne: true } }) {
        items {
          id
          isCompleted
          milestoneID
          description
          orderNo
          resourceLink
          title
          type
        }
      }
      FreeTextQuestionBlocks(filter: { isArchived: { ne: true } }) {
        items {
          description
          id
          isCompleted
          milestoneID
          orderNo
          text
          title
          type
        }
      }
      MultipleChoiceQuestionBlocks(filter: { isArchived: { ne: true } }) {
        items {
          answer
          description
          id
          isCompleted
          isRequired
          milestoneID
          option
          orderNo
          title
          type
        }
      }
      NoteBlocks(filter: { isArchived: { ne: true } }) {
        items {
          description
          id
          isCompleted
          isPrivate
          milestoneID
          orderNo
          text
          title
          type
        }
      }
      StandardContentBlocks(filter: { isArchived: { ne: true } }) {
        items {
          title
          orderNo
          milestoneID
          isCompleted
          id
          description
          type
        }
      }
      TaskBlocks(filter: { isArchived: { ne: true } }) {
        items {
          description
          id
          isCompleted
          milestoneID
          orderNo
          status
          title
          type
        }
      }
      createdAt
      updatedAt
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListMilestoneByKinfolkTemplateIdQueryVariables,
    APITypes.ListMilestoneByKinfolkTemplateIdQuery
  >;

export const createStandardContentBlockWithIdReturn = /* GraphQL */ `
  mutation createStandardContentBlockWithIdReturn(
    $input: CreateStandardContentBlockInput!
    $condition: ModelStandardContentBlockConditionInput
  ) {
    createStandardContentBlock(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createMultipleChoiceQuestionBlockWithIdReturn = /* GraphQL */ `
  mutation createMultipleChoiceQuestionBlockWithIdReturn(
    $input: CreateMultipleChoiceQuestionBlockInput!
    $condition: ModelMultipleChoiceQuestionBlockConditionInput
  ) {
    createMultipleChoiceQuestionBlock(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createTaskBlockWithIdReturn = /* GraphQL */ `
  mutation createTaskBlockWithIdReturn(
    $input: CreateTaskBlockInput!
    $condition: ModelTaskBlockConditionInput
  ) {
    createTaskBlock(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createNoteBlockWithIdReturn = /* GraphQL */ `
  mutation createNoteBlockWithIdReturn(
    $input: CreateNoteBlockInput!
    $condition: ModelNoteBlockConditionInput
  ) {
    createNoteBlock(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createFreeTextQuestionBlockWithIdReturn = /* GraphQL */ `
  mutation createFreeTextQuestionBlockWithIdReturn(
    $input: CreateFreeTextQuestionBlockInput!
    $condition: ModelFreeTextQuestionBlockConditionInput
  ) {
    createFreeTextQuestionBlock(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createEmbeddedContentBlockWithIdReturn = /* GraphQL */ `
  mutation createEmbeddedContentBlockWithIdReturn(
    $input: CreateEmbeddedContentBlockInput!
    $condition: ModelEmbeddedContentBlockConditionInput
  ) {
    createEmbeddedContentBlock(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateStandardContentBlockWithIdReturn = /* GraphQL */ `
  mutation updateStandardContentBlockWithIdReturn(
    $input: UpdateStandardContentBlockInput!
    $condition: ModelStandardContentBlockConditionInput
  ) {
    updateStandardContentBlock(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateFreeTextQuestionBlockWithIdReturn = /* GraphQL */ `
  mutation updateFreeTextQuestionBlockWithIdReturn(
    $input: UpdateFreeTextQuestionBlockInput!
    $condition: ModelFreeTextQuestionBlockConditionInput
  ) {
    updateFreeTextQuestionBlock(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateNoteBlockWithIdReturn = /* GraphQL */ `
  mutation updateNoteBlockWithIdReturn(
    $input: UpdateNoteBlockInput!
    $condition: ModelNoteBlockConditionInput
  ) {
    updateNoteBlock(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateTaskBlockWithIdReturn = /* GraphQL */ `
  mutation updateTaskBlockWithIdReturn(
    $input: UpdateTaskBlockInput!
    $condition: ModelTaskBlockConditionInput
  ) {
    updateTaskBlock(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateMilestoneWithIdReturn = /* GraphQL */ `
  mutation updateMilestoneWithIdReturn(
    $input: UpdateMilestoneInput!
    $condition: ModelMilestoneConditionInput
  ) {
    updateMilestone(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateMultipleChoiceQuestionBlockWithIdReturn = /* GraphQL */ `
  mutation updateMultipleChoiceQuestionBlockWithIdReturn(
    $input: UpdateMultipleChoiceQuestionBlockInput!
    $condition: ModelMultipleChoiceQuestionBlockConditionInput
  ) {
    updateMultipleChoiceQuestionBlock(input: $input, condition: $condition) {
      id
    }
  }
`;

export const listUserJourneys = /* GraphQL */ `
  query listUserJourneys(
    $filter: ModelJourneyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJourneys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        status
        mode
        publicLink
        parentJourneyID
        organizationID
        lockChapters
        journeyProgress
        userCompletedAt
        collaborator
        assignedUserID
        startDate
        badgeID
        customBadgeName
        userStartedAt
        preboarding
        updatedAt
        isArchived
        type
        image
      }
      nextToken
    }
  }
`;

export const createJourneyWithIdReturn = /* GraphQL */ `
  mutation createJourneyWithIdReturn(
    $input: CreateJourneyInput!
    $condition: ModelJourneyConditionInput
  ) {
    createJourney(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateJourneyWithIdReturn = /* GraphQL */ `
  mutation updateJourneyWithIdReturn(
    $input: UpdateJourneyInput!
    $condition: ModelJourneyConditionInput
  ) {
    updateJourney(input: $input, condition: $condition) {
      id
    }
  }
`;

export const getJourneyWithIdAndName = /* GraphQL */ `
  query getJourneyWithIdAndName($id: ID!) {
    getJourney(id: $id) {
      id
      name
      status
      journeyProgress
      assignedUserID
      collaborator
      startDate
      parentJourneyID
      type
    }
  }
`;

export const updateEmbeddedContentBlockWithIdReturn = /* GraphQL */ `
  mutation updateEmbeddedContentBlockWithIdReturn(
    $input: UpdateEmbeddedContentBlockInput!
    $condition: ModelEmbeddedContentBlockConditionInput
  ) {
    updateEmbeddedContentBlock(input: $input, condition: $condition) {
      id
    }
  }
`;

export const listDetailedUser = /* GraphQL */ `
  query ListDetailedUser(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        email
        photoUrl
        type
        slackUserId
        userOrganizationId
      }
      nextToken
    }
  }
`;

export const updateLocationWithIdReturn = /* GraphQL */ `
  mutation updateLocationWithIdReturn(
    $input: UpdateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    updateLocation(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateUserWithIdReturn = /* GraphQL */ `
  mutation updateUserWithIdReturn(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
    }
  }
`;

export const listSecretKeyManagersWithId = /* GraphQL */ `
  query listSecretKeyManagersWithId(
    $filter: ModelSecretKeyManagerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSecretKeyManagers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationID
        clientEmail
        adminEmail
        createdAt
      }
      nextToken
    }
  }
`;

export const updateSecretKeyManagerDetails = /* GraphQL */ `
  mutation updateSecretKeyManagerDetails(
    $input: UpdateSecretKeyManagerInput!
    $condition: ModelSecretKeyManagerConditionInput
  ) {
    updateSecretKeyManager(input: $input, condition: $condition) {
      clientEmail
      createdAt
    }
  }
`;

export const getUserWithBasicData = /* GraphQL */ `
  query getUserWithBasicData($id: ID!) {
    getUser(id: $id) {
      id
      email
      firstName
      personalEmail
      lastName
      photoUrl
      team
      jobTitle
      startDate
      slackUserId
      UserBadges {
        items {
          createdAt
          id
          updatedAt
          playbookId
          userID
        }
      }
    }
  }
`;

export const listDetailedJourneys = /* GraphQL */ `
  query listDetailedJourneys(
    $filter: ModelJourneyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJourneys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        status
        lockChapters
        parentJourneyID
        journeyProgress
        assignedUserID
        mode
        userStartedAt
        startDate
        eventStartedAt
        type

        Milestone {
          items {
            EmbeddedContentBlocks(filter: { isArchived: { ne: true } }) {
              items {
                isCompleted
              }
            }
            FreeTextQuestionBlocks(filter: { isArchived: { ne: true } }) {
              items {
                isCompleted
              }
            }
            MultipleChoiceQuestionBlocks(filter: { isArchived: { ne: true } }) {
              items {
                isCompleted
              }
            }
            TaskBlocks(filter: { isArchived: { ne: true } }) {
              items {
                isCompleted
              }
            }
          }
        }
        events(filter: { archived: { eq: false } }) {
          items {
            id
            status
            reminders {
              items {
                id
                status
              }
            }
          }
        }
        startDate
        updatedAt
      }
      nextToken
    }
  }
`;

export const deleteMilestoneWithIdReturn = /* GraphQL */ `
  mutation deleteMilestoneWithIdReturn(
    $input: DeleteMilestoneInput!
    $condition: ModelMilestoneConditionInput
  ) {
    deleteMilestone(input: $input, condition: $condition) {
      id
    }
  }
`;

export const deleteMeetingWithIdAndReturn = /* GraphQL */ `
  mutation deleteMeetingWithIdAndReturn(
    $input: DeleteMeetingInput!
    $condition: ModelMeetingConditionInput
  ) {
    deleteMeeting(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateUserWithIdAndEmailReturn = /* GraphQL */ `
  mutation updateUserWithIdAndEmailReturn(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      email
    }
  }
`;

export const updateOrganizationWithIdReturn = /* GraphQL */ `
  mutation updateOrganizationWithIdReturn(
    $input: UpdateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    updateOrganization(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createMeetingWithIdReturn = /* GraphQL */ `
  mutation createMeetingWithIdReturn(
    $input: CreateMeetingInput!
    $condition: ModelMeetingConditionInput
  ) {
    createMeeting(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateMeetingWithIdReturn = /* GraphQL */ `
  mutation updateMeetingWithIdReturn(
    $input: UpdateMeetingInput!
    $condition: ModelMeetingConditionInput
  ) {
    updateMeeting(input: $input, condition: $condition) {
      id
    }
  }
`;

export const listJourneyCollaboratorsWithIdReturn = /* GraphQL */ `
  query listJourneyCollaboratorsWithIdReturn(
    $filter: ModelJourneyCollaboratorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJourneyCollaborators(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
      }
      nextToken
    }
  }
`;

export const createJourneyCollaboratorWithIdReturn = /* GraphQL */ `
  mutation createJourneyCollaboratorWithIdReturn(
    $input: CreateJourneyCollaboratorInput!
    $condition: ModelJourneyCollaboratorConditionInput
  ) {
    createJourneyCollaborator(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateJourneyCollaboratorData = /* GraphQL */ `
  mutation updateJourneyCollaboratorData(
    $input: UpdateJourneyCollaboratorInput!
    $condition: ModelJourneyCollaboratorConditionInput
  ) {
    updateJourneyCollaborator(input: $input, condition: $condition) {
      id
      assignedUserID
    }
  }
`;

export const createKinfolkTemplateWithIdReturn = /* GraphQL */ `
  mutation createKinfolkTemplateWithIdReturn(
    $input: CreateKinfolkTemplateInput!
    $condition: ModelKinfolkTemplateConditionInput
  ) {
    createKinfolkTemplate(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateKinfolkTemplateWithIdReturn = /* GraphQL */ `
  mutation updateKinfolkTemplateWithIdReturn(
    $input: UpdateKinfolkTemplateInput!
    $condition: ModelKinfolkTemplateConditionInput
  ) {
    updateKinfolkTemplate(input: $input, condition: $condition) {
      id
    }
  }
`;

export const listPreboardingUsersWithRelations = /* GraphQL */ `
  query listPreboardingUsersWithRelations(
    $filter: ModelPreboardingUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPreboardingUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        isSubmitted
        consent
        journeyId
        personalEmail
        photoUrl
        bankName
        bankBranch
        bankSortCode
        bankAccountNumber
        bankAccountName
        buildingSocietyRoll
        street1
        street2
        city
        state
        zip
        country
        birthday
        gender
        title
        nationality
        mobileNumber
        niNumber
        laptopPreference
        secondMonitor
        corporateCard
        corporatePhone
        healthCare
        dental
        optical
        jobDeclaration
        expatDeclaration
        studentLoanRepayment
        studentLoanType
        pensionScheme
        pensionSchemeOpt
        pensionContribution
        passportNumber
        passportIssueDate
        passportExpiryDate
        passportIssuingCountry
        confirmation
        emergencyContactName
        emergencyContactRelationship
        emergencyContactPhone
        emergencyContactEmailAddress
        emergencyContactStreet1
        emergencyContactStreet2
        emergencyContactCity
        emergencyContactState
        emergencyContactZip
        emergencyContactCountry
        createdAt
        updatedAt
        __typename
        PreboardingUserRelations {
          items {
            id
            firstName
            lastName
            relationshipToMember
            birthday
            userId
            formId
            healthCare
            dental
            optical
            createdAt
            updatedAt
            __typename
          }
        }
      }
      nextToken
      __typename
    }
  }
`;

export const listEventsWithRemindersByJourneyId =
  /* GraphQL */ `query listEventsWithRemindersByJourneyId(
  $journeyID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listEventsByJourneyId(
    journeyID: $journeyID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      daysFrom
      type
      role
      emailTo
      title
      message
      formId
      sendTime
      channel
      sendTimeDate
      status
      channelId
      parentId
      cc
      archived
      updatedInChild
      journeyID
      formId

      kinfolkTemplateID
      reminders {
        items {
          daysFrom
          eventId
          id
          sendTimeDate
          status
        }
      }
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListEventsByJourneyIdQueryVariables,
    APITypes.ListEventsByJourneyIdQuery
  >;

export const listEventsWithRemindersByKinfolkTemplateId =
  /* GraphQL */ `query listEventsWithRemindersByKinfolkTemplateId(
  $kinfolkTemplateID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listEventsByKinfolkTemplateId(
    kinfolkTemplateID: $kinfolkTemplateID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      daysFrom
      type
      role
      emailTo
      title
      message

      sendTime
      channel
      sendTimeDate
      status
      channelId
      parentId
      cc
      archived
      updatedInChild
      journeyID
      formId

      kinfolkTemplateID
      createdAt
      updatedAt
      __typename
      reminders {
        items {
          daysFrom
          eventId
          id
          sendTimeDate
          status
        }
      }
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListEventsByKinfolkTemplateIdQueryVariables,
    APITypes.ListEventsByKinfolkTemplateIdQuery
  >;
