import { type MouseEvent, type ReactNode } from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import { HamburgerIcon } from '../../Atoms/Icons';
import styles from './DropdownHamburger.module.scss';

interface DropdownHamburgerItem {
  title: ReactNode;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
}

interface DropdownHamburgerProps {
  items: DropdownHamburgerItem[];
}

export const DropdownHamburger = ({ items }: DropdownHamburgerProps) => (
  <Dropdown as={ButtonGroup}>
    <Dropdown.Toggle className={styles.dropdownHamburger} variant="transparent">
      <HamburgerIcon />
    </Dropdown.Toggle>

    <Dropdown.Menu className={styles.dropdownMenuBottomLeft}>
      {items.map(({ title, onClick }, index) => (
        <Dropdown.Item
          className={styles.dropdownMenuItem}
          key={index}
          onClick={onClick}
        >
          {title}
        </Dropdown.Item>
      ))}
    </Dropdown.Menu>
  </Dropdown>
);
