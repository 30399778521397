import { Offcanvas } from 'react-bootstrap';
import { ActivityPanelContent } from './ActivityPanelContent';
import styles from './ActivityPanel.module.scss';

interface ActivityPanelProps {
  isOpen: boolean;
  onClose: () => void;
  openCreateUpdateFormEventPanel: () => void;
}

export const ActivityPanel = ({
  isOpen,
  onClose,
  openCreateUpdateFormEventPanel,
}: ActivityPanelProps) => (
  <Offcanvas
    className={styles.panel}
    placement="end"
    show={isOpen}
    onHide={onClose}
  >
    <Offcanvas.Header closeButton className={styles.header}>
      <Offcanvas.Title>
        <h2 className={styles.title}>Forms & Automated Actions</h2>
        <h3 className={styles.subtitle}>Used in this runbook</h3>
      </Offcanvas.Title>
    </Offcanvas.Header>
    <Offcanvas.Body className={styles.body}>
      <ActivityPanelContent
        openCreateUpdateFormEventPanel={openCreateUpdateFormEventPanel}
      />
    </Offcanvas.Body>
  </Offcanvas>
);
