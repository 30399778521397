import { Modal } from 'react-bootstrap';
import { Button } from '@Shared/DS';
import { Typeahead } from 'react-bootstrap-typeahead';
import { TextField } from '../../../../../DS/Molecules/TextField/TextField';
import { useResourceUrlModal } from './useResourceUrlModal';

interface ResourceUrlModalProps {
  title: string;
  showModal: boolean;
  handleShowModal: Function;
  addEmbeddedContentBlock: Function;
  actionType: string;
  embedType?: string;
  assignedUserId?: string | null;
}

export const ResourceUrlModal = (props: ResourceUrlModalProps) => {
  const {
    url,
    isSubmitted,
    orgForms,
    setUrl,
    selectedForm,
    setSelectedForm,
    handleSubmit,
  } = useResourceUrlModal(
    props.handleShowModal,
    props.addEmbeddedContentBlock,
    props.assignedUserId,
  );

  return (
    <>
      <Modal
        size="lg"
        fullscreen={'md-down'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        show={props.showModal}
        onHide={() => props.handleShowModal(false)}
        className="about-modal-wrapper"
      >
        <Modal.Header>
          <Modal.Title className="fs-24-semi-bold-4C5564">
            {props.title}{' '}
          </Modal.Title>
          <button
            type="button"
            className="btn-close modal-close-btn"
            data-bs-dismiss="modal"
            onClick={() => props.handleShowModal(false)}
            aria-label="Close"
          ></button>
        </Modal.Header>
        <Modal.Body>
          {props.embedType !== 'Form' ? (
            <>
              <label className="fs-14-semi-bold col-12 text-start">
                Add URL
              </label>
              <div className="d-flex align-items-center">
                <TextField
                  id={'url'}
                  type="text"
                  defaultValue={url}
                  setValue={setUrl}
                  fieldName="url"
                  width=""
                  placeholder="https://example.com"
                  additionalClasses={` 
          ${!url && url.length === 0 && isSubmitted ? 'is-invalid' : ''} flex-frow-1`}
                />
                <Button
                  additionalClasses="mx-2"
                  text={props.actionType}
                  onClick={handleSubmit}
                />
              </div>
            </>
          ) : (
            <>
              <label className="text-start d-block form-label" htmlFor="form">
                Form
              </label>
              <div className="d-flex align-items-center">
                <Typeahead
                  id="form"
                  className="flex-grow-1"
                  isInvalid={!selectedForm && isSubmitted}
                  onChange={(forms: any[]) => {
                    setSelectedForm(forms[0]);
                  }}
                  labelKey={'name'}
                  options={orgForms as any[]}
                  placeholder="Start typing form name..."
                  selected={selectedForm ? [selectedForm] : []}
                />
                <Button
                  additionalClasses="mx-2"
                  text={props.actionType}
                  onClick={handleSubmit}
                />
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
