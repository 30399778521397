import { useQueryClient, useQuery } from '@tanstack/react-query';
import { useState, useEffect } from 'react';
import { type User, UserType } from '@API';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import {
  INVITED_ADMINS_SENDGRID_LIST_ID,
  addNotificationToApp,
} from '@Shared/utils/utils';
import {
  getUserDataForSettingsScreen,
  updateUserType,
  inviteExistingEmployee,
  updateUserStatus,
  updateSendgridContactList,
} from '@api/apis';
import { SettingsPageQueryKey } from '../../queries';

export const useMembersTab = () => {
  const { user } = useUserContext();
  const [allOrganizationUsers, setAllOrganizationUsers] = useState<User[]>([]);
  const [allOrganizationAdmins, setAllOrganizationAdmins] = useState<User[]>(
    [],
  );
  const [showModal, setShowModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState({} as User);
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [deletedUserId, setDeletedUserId] = useState('');
  const queryClient = useQueryClient();

  const getOrganizationUsers = async (): Promise<User[] | undefined> => {
    const organizationUsers = await getUserDataForSettingsScreen(
      user.userOrganizationId,
    );
    return organizationUsers;
  };
  const { data: organizationUsers } = useQuery({
    queryKey: [SettingsPageQueryKey.listOrganizationUsers],
    queryFn: getOrganizationUsers,
    enabled: !!user.userOrganizationId,
  });

  const handleShowUserDetailModal = (user: User) => {
    setSelectedUser(user);
    setShowUpdateModal(true);
  };

  const handleUpdateUserDetails = async () => {
    queryClient.invalidateQueries({
      queryKey: [SettingsPageQueryKey.listOrganizationUsers],
    });
    setShowUpdateModal(false);
  };

  const handleUpdateUserType = async (
    newType: string,
    userId: string,
  ): Promise<void> => {
    if (!organizationUsers) {
      return;
    }

    const type =
      newType === 'Member'
        ? UserType.COMPANY_MEMBER
        : newType === 'Admin'
          ? UserType.COMPANY_ADMIN
          : UserType.CANDIDATE;
    const id = await updateUserType(userId, type);
    if (id) {
      // Update cache
      queryClient.setQueryData(
        [SettingsPageQueryKey.listOrganizationUsers],
        (previous: User[] | undefined) =>
          previous?.map((user) =>
            user.id === userId ? { ...user, type: type } : user,
          ),
      );
    }
  };

  const sendInvitation = async (userInfo: User) => {
    setIsLoading(true);

    await inviteExistingEmployee(user.userOrganizationId, {
      inviteParams: {
        email: userInfo.email,
        personalEmail: userInfo.personalEmail || undefined,
        startDate: userInfo.startDate ?? undefined,
        type: userInfo.type,
        sendWelcomeEmail: true,
      },
      customFields: {},
    });
    setIsLoading(false);
  };

  const handleUpdateUserStatus = async (userId: string) => {
    setShowConfirmationModal(false);
    const selectedUser =
      allOrganizationAdmins.find((admin) => admin.id === userId) ||
      allOrganizationUsers.find((user) => user.id === userId);
    if (selectedUser && user.id !== userId) {
      const id = await updateUserStatus({
        id: userId,
        isActive: false,
      } as User);
      if (id) {
        // Update cache
        queryClient.setQueryData(
          [SettingsPageQueryKey.listOrganizationUsers],
          (previous: User[] | undefined) =>
            previous?.map((user) =>
              user.id === userId ? { ...user, isActive: false } : user,
            ),
        );
        addNotificationToApp(
          `User ${selectedUser.email} has been deactivated`,
          'success',
        );
        // remove user from sendgrid contact list if admin
        if (selectedUser.type === UserType.COMPANY_ADMIN) {
          await updateSendgridContactList(
            selectedUser.email,
            INVITED_ADMINS_SENDGRID_LIST_ID,
            'delete',
          );
        }
      }
    } else {
      addNotificationToApp('You are logged in through this account', 'error');
    }
  };

  useEffect(() => {
    if (organizationUsers) {
      let members: User[] = [];
      let admins: User[] = [];
      organizationUsers.forEach((user: User) => {
        if (
          user.type === UserType.COMPANY_MEMBER ||
          user.type === UserType.CANDIDATE
        ) {
          members.push(user);
        } else if (user.type === UserType.COMPANY_ADMIN) {
          admins.push(user);
        }
      });

      setAllOrganizationUsers(members);
      setAllOrganizationAdmins(admins);
    }
  }, [organizationUsers]);

  return {
    allOrganizationAdmins,
    allOrganizationUsers,
    showModal,
    showUpdateModal,
    selectedUser,
    isLoading,
    showConfirmationModal,
    deletedUserId,
    setShowModal,
    setShowUpdateModal,
    handleShowUserDetailModal,
    handleUpdateUserDetails,
    handleUpdateUserType,
    sendInvitation,
    handleUpdateUserStatus,
    setShowConfirmationModal,
    setDeletedUserId,
  };
};
