import { Modal, type ModalProps, Tab, Tabs } from 'react-bootstrap';
import { useTabs } from '@Hooks/useTabs';
import { memo } from 'react';
import { ExistingEmployee } from './ExistingEmployee/ExistingEmployee';
import { NewHire } from './NewHire/NewHire';

export const InviteMembersModal = memo((props: ModalProps) => {
  const { key, setKey } = useTabs('existing-employee');

  return (
    <>
      <Modal
        size="lg"
        fullscreen={'md-down'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        show={props.showModal}
        onHide={() => props.handleShowModal(false)}
        className="tabs-wrapper"
      >
        <Modal.Header>
          <Modal.Title className="mb-2">Invite members to Kinfolk</Modal.Title>
          <button
            type="button"
            className="btn-close modal-close-btn"
            data-bs-dismiss="modal"
            onClick={() => props.handleShowModal(false)}
            aria-label="Close"
          ></button>
        </Modal.Header>
        <Modal.Body className="pb-4">
          <Tabs
            id="invite-members-tab"
            activeKey={key}
            onSelect={(k) => setKey(k || '')}
            className="mb-3"
          >
            <Tab eventKey="existing-employee" title="Existing employees">
              <ExistingEmployee handleShowModal={props.handleShowModal} />
            </Tab>
            <Tab eventKey="new-hire" title="New hires">
              <NewHire handleShowModal={props.handleShowModal} />
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>
    </>
  );
});
