import ContentEditable from 'react-contenteditable';
import {
  type EditableContentProps,
  pasteAsPlainText,
} from '@Shared/utils/utils';
import sanitizeHtml from 'sanitize-html';
import { useDynamicTagsContext } from '@base/Context/DynamicTagsContext';

export const EditableContent = (props: EditableContentProps) => {
  const { substituteValues } = useDynamicTagsContext();
  const value =
    props.isEditable || !props.id
      ? props.value
      : (substituteValues(props.id) ?? props.value);

  return (
    <ContentEditable
      disabled={!props.isEditable}
      html={value}
      data-column="item"
      onPaste={pasteAsPlainText}
      onBlur={() => (props.onBlur ? props.onBlur() : {})}
      className={`content-editable w-100 p-0 ${props.className ?? ''}`}
      onChange={(e) => props.setValue(sanitizeHtml(e.target.value))}
    />
  );
};
