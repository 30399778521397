import { type SVGAttributes, type PropsWithChildren } from 'react';
import { IconBase } from 'react-icons';

export type IconProps = SVGAttributes<SVGElement>;

export const Icon = (props: PropsWithChildren<IconProps>) => (
  <IconBase
    viewBox="0 0 20 20"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  />
);
