import { useUserContext } from '@base/Context/UserContext/UserContext';
import { Container } from 'react-bootstrap';
import { Button } from '@Shared/DS';
import { openExternalUrl } from '@Shared/utils/utils';
import { SearchPeople } from './Components/SearchPeople/SearchPeople';
import './Components/SearchPeople/SearchPeople.scss';

export const People = () => {
  const { user } = useUserContext();

  return (
    <div className="people-page-wrapper page-wrapper d-flex">
      <Container className="p-4">
        <div className="row no-pad">
          <div className="col-md-12 col-lg-12">
            {!user?.Organization?.hasSubscription ? (
              <div className="people__locked">
                <span className="people__locked-text">
                  Employee profiles are available on the paid plan. Contact us
                  to see them in action.
                  <br />
                  <Button
                    buttonStyle="outline"
                    text="Book a call"
                    icon="bi-calendar-check"
                    additionalClasses="mt-2"
                    onClick={() =>
                      openExternalUrl('https://calendly.com/kinfolkhq/jeet')
                    }
                  />
                </span>
              </div>
            ) : (
              <SearchPeople />
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};
