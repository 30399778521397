import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { type MultipleChoiceQuestionBlock } from '@API';
import { deleteBlock, updateMultipleChoiceQuestionBlock } from '@api/apis';
import {
  addNotificationToApp,
  updateBlockDescription,
  MULTIPLE_CHOICE_QUESTION_LIMIT,
} from '@Shared/utils/utils';
import { useNonInitialEffect } from '@Hooks/useNonInitialEffect';
import { useUserContext } from '@base/Context/UserContext/UserContext';

export const useMultipleChoiceQuestionBlock = (
  block: MultipleChoiceQuestionBlock,
  handleUpdateMilestoneBlocks: Function,
  handleDeleteBlock: Function,
  setShowLoader: Function,
  setShowSaved: Function,
  isParentPlaybook: boolean,
  isEditable: boolean,
  isAssignee?: boolean,
  setIsSaving?: Function,
) => {
  const [title, setTitle] = useState(block.title);
  const [description, setDescription] = useState(block.description ?? '');
  const [options, setOptions] = useState(block.option ? block.option : []);
  const [answer, setAnswer] = useState(block.answer ? block.answer : []);
  const [isOptionTextChanged, setIsOptionTextChanged] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { user } = useUserContext();
  const { id } = useParams();

  const handleUpdateBlockDescription = async () => {
    if (isEditable && id && block.description !== description) {
      const updateDescriptionImages = await updateBlockDescription(
        description,
        block.id,
        block.type,
        block.milestoneID,
        user.userOrganizationId,
        id,
      );

      setShowLoader(true);
      await updateMultipleChoiceQuestionBlock({
        ...block,
        description: updateDescriptionImages,
        updatedInChild: !isParentPlaybook,
      });
      handleUpdateMilestoneBlocks({
        ...block,
        description: updateDescriptionImages,
      });
      setDescription(updateDescriptionImages);
    }
    setShowLoader(false);
    setShowSaved(true);
  };

  const addNewOption = () => {
    if (options.length < MULTIPLE_CHOICE_QUESTION_LIMIT) {
      options.push(`answer ${options.length + 1}`);
      setOptions([...options]);
    } else {
      addNotificationToApp('You can add up to 10 answers', 'info');
    }
  };

  const removeOption = () => {
    options.pop();
    setOptions([...options]);
  };

  const handleOptionsTextChange = (answer: string, index: number) => {
    options[index] = answer;
    setIsOptionTextChanged(true);
    setOptions([...options]);
  };

  const handleUpdateMultipleChoiceQuestionBlock = async () => {
    if (
      block.title !== title ||
      block.option?.length !== options.length ||
      isOptionTextChanged
    ) {
      setShowLoader(true);
      await updateMultipleChoiceQuestionBlock({
        ...block,
        title: title,
        option: options,
        updatedInChild: !isParentPlaybook,
      });
      handleUpdateMilestoneBlocks({
        ...block,
        title: title,
        option: options,
      });
      setIsOptionTextChanged(false);
      setShowLoader(false);
      setShowSaved(true);
    }
  };

  const handleAnswer = async (index: number) => {
    if (isAssignee) {
      setAnswer([options[index]]);
      await updateMultipleChoiceQuestionBlock({
        ...block,
        isCompleted: true,
        answer: [options[index]],
      });
      handleUpdateMilestoneBlocks({
        ...block,
        isCompleted: true,
        answer: [options[index]],
      });
    }
  };

  const removeBlock = async () => {
    let id;
    if (isParentPlaybook) {
      id = await deleteBlock(block.id, block.type);
    } else {
      id = await updateMultipleChoiceQuestionBlock({
        ...block,
        isArchived: true,
      });
    }

    if (id) {
      handleDeleteBlock(block.id, block.type, block.orderNo);
      setShowConfirmationModal(false);
    }
  };

  useEffect(() => {
    setTitle(block.title);
    setDescription(block.description ?? '');
    setOptions(block.option ? block.option : []);
    setAnswer(block.answer ? block.answer : []);
  }, [block]);

  useNonInitialEffect(() => {
    if (
      block.title !== title ||
      block.option?.length !== options.length ||
      isOptionTextChanged
    ) {
      setIsSaving?.(true);
    } else {
      setIsSaving?.(false);
    }
    const timeOut = setTimeout(() => {
      handleUpdateMultipleChoiceQuestionBlock();
    }, 1000);

    return () => clearTimeout(timeOut);
  }, [title, options]);

  useNonInitialEffect(() => {
    if (id && block.description !== description) {
      setIsSaving?.(true);
    } else {
      setIsSaving?.(false);
    }
    const timeOut = setTimeout(() => {
      handleUpdateBlockDescription();
    }, 1000);

    return () => clearTimeout(timeOut);
  }, [description]);

  return {
    title,
    description,
    options,
    answer,
    showConfirmationModal,
    setShowConfirmationModal,
    setDescription,
    setTitle,
    setOptions,
    addNewOption,
    handleOptionsTextChange,
    handleAnswer,
    removeBlock,
    removeOption,
  };
};
