import { Button } from '@Shared/DS/Atoms';
import './TrialEnded.scss';
import { openExternalUrl } from '@Shared/utils/utils';

export const TrialEnded = () => (
  <div className="trial__locked">
    <span className="trial__locked-text">
      You trial has now finished. Contact us if you would like to continue.
      <br />
      <Button
        buttonStyle="outline"
        text="Book a call"
        icon="bi-calendar-check"
        additionalClasses="mt-2"
        onClick={() => openExternalUrl('https://calendly.com/kinfolkhq/jeet')}
      />
    </span>
  </div>
);
