import { useState, useEffect } from 'react';
import { type KinfolkTemplate } from '@API';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { getKinfolkTemplates } from '@api/apis';
import { useQuery } from '@tanstack/react-query';
import { TemplateKeys } from './queries.key';

export const useTemplatePage = () => {
  const [showCreateTemplateModal, setShowCreateTemplateModal] = useState(false);
  const { user } = useUserContext();
  const [templates, setTemplates] = useState<KinfolkTemplate[] | undefined>([]);

  const getTemplates = async (): Promise<KinfolkTemplate[]> => {
    if (user.userOrganizationId) {
      const kinfolkTemplates = await getKinfolkTemplates();
      if (kinfolkTemplates) {
        return kinfolkTemplates.sort((a, b) => {
          if (a.orderNo && b.orderNo) {
            return a.orderNo - b.orderNo;
          }

          return 0;
        });
      }
    }

    return [] as KinfolkTemplate[];
  };
  const { data, isLoading } = useQuery({
    queryKey: [TemplateKeys.listKinfolkTemplates],
    queryFn: getTemplates,
    enabled: !!user.userOrganizationId,
  });

  useEffect(() => {
    if (data) {
      setTemplates(data);
    }
  }, [data]);

  const handleCreateTemplate = () => {
    setShowCreateTemplateModal(true);
  };

  return {
    showCreateTemplateModal,
    templates,
    isLoading,
    setShowCreateTemplateModal,
    handleCreateTemplate,
  };
};
