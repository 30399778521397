import { flexRender } from '@tanstack/react-table';
import { CaretUpFill, CaretDownFill } from 'react-bootstrap-icons';
import { type IJourneyPeopleTable } from '@base/models/journeyAssignees.model';
import { useNavigate } from 'react-router-dom';
import { useParticipantTable } from './useParticipantTable';
import './ParticipantTable.scss';

export const ParticipantTable = (props: IJourneyPeopleTable) => {
  const { childJourneys, showLoader } = props;
  const { table, data, columns } = useParticipantTable(childJourneys);
  const navigate = useNavigate();

  return (
    <div className="playbook-participants-table">
      <table className="kin-table table">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  className="px-4"
                  key={header.id}
                  style={{
                    width:
                      header.getSize() !== 0 ? header.getSize() : undefined,
                  }}
                >
                  {header.isPlaceholder ? null : (
                    <span
                      {...{
                        className: header.column.getCanSort()
                          ? 'post-sorting-icon cursor-pointer select-none d-flex'
                          : '',
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                      {{
                        asc: <CaretUpFill />,
                        desc: <CaretDownFill />,
                      }[header.column.getIsSorted() as string] ?? (
                        <CaretUpFill className="initial-sorting-icon" />
                      )}
                    </span>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {showLoader && (
            <tr>
              <td className="text-center" colSpan={columns.length}>
                <div className="spinner-border" role="status">
                  <span className="visually-hidden text-center">
                    Loading...
                  </span>
                </div>
              </td>
            </tr>
          )}
          {!showLoader && !data.length && (
            <tr>
              <td className="text-center" colSpan={columns.length}>
                It's empty here. Assign your first user today!
              </td>
            </tr>
          )}

          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              className="cursor-pointer form-row"
              onClick={() => navigate(`/runbook/${row.original.id}`)}
            >
              {row.getVisibleCells().map((cell) => (
                <td
                  className="px-4 align-middle"
                  key={cell.id}
                  style={{
                    width:
                      cell.column.getSize() !== 0
                        ? cell.column.getSize()
                        : undefined,
                  }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
