import {
  getMeetings,
  getPlaybookPreActivationChecklist,
  scheduleJourneyMeetings,
  sendPlaybookAssignEmail,
  updateJourney,
  updatePlaybookWorkflows,
} from '@api/apis';
import {
  type Journey,
  JourneyStatus,
  MeetingStatus,
  PlaybookMode,
} from '@base/API';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { Analytics } from '@base/analytics/analytics';
import { Keys } from '@base/keys/queryKeys';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const defaultCheckList = [
  { text: 'All events are in the future', isLoading: true, error: false },
  { text: 'All events are assigned', isLoading: true, error: false },
  { text: 'All meetings have attendees', isLoading: true, error: false },
];

export const usePlaybookActivationModal = (
  playbook: Journey,
  showModal: boolean,
  setShowModal: (value: boolean) => void,
) => {
  const [isBusy, setIsBusy] = useState(false);
  const [preActivationChecklist, setPreActivationChecklist] =
    useState(defaultCheckList);
  const [isCheckListRunning, setIsCheckListRunning] = useState(true);
  const [keepMicrosite, setKeepMicrosite] = useState(
    playbook.mode === PlaybookMode.IMMERSIVE,
  );
  const { user } = useUserContext();
  const queryClient = useQueryClient();
  const { id: parentPlaybookId } = useParams();

  const activatePlaybook = async () => {
    setIsBusy(true);
    const status =
      playbook.mode === PlaybookMode.WORKFLOW
        ? JourneyStatus.IN_PROGRESS
        : JourneyStatus.NOT_STARTED;
    const mode = keepMicrosite ? PlaybookMode.IMMERSIVE : PlaybookMode.WORKFLOW;
    let updatePlaybookRequest = { id: playbook.id, mode, status } as Journey;

    const playbookId = await updateJourney(updatePlaybookRequest);

    if (playbookId) {
      if (status === JourneyStatus.NOT_STARTED) {
        sendPlaybookAssignEmail(playbookId);
      }

      const toastId = toast.loading("We're activating workflows.");
      setIsBusy(false);
      setShowModal(false);
      const id = await updatePlaybookWorkflows(
        playbook.id,
        status,
        user.userOrganizationId,
        playbook.startDate,
      );

      if (id) {
        toast.update(toastId, {
          render: 'Workflows activated successfully',
          isLoading: false,
          type: 'success',
          autoClose: 2000,
        });
        // invalidate query to fetch runbooks with updated status
        queryClient.invalidateQueries({
          queryKey: Keys.getChildPlaybooks(parentPlaybookId),
        });
        // handle book meetings in the background
        bookPlaybookMeetings();

        Analytics.playbookActivated(playbook.id);
      }
    }
  };

  const bookPlaybookMeetings = async () => {
    const filter = {
      journeyID: { eq: playbook.id },
      organizedByUserID: { attributeExists: true },
      and: [
        { attendeesEmail: { notContains: null } },
        { attendeesEmail: { notContains: '' } },
      ],
      or: [
        { status: { eq: MeetingStatus.DRAFT } },
        { status: { eq: MeetingStatus.NOT_AVAILABLE } },
      ],
    };
    const meetings = await getMeetings(playbook.id, false, filter);

    if (meetings && meetings.length && playbook.startDate) {
      await scheduleJourneyMeetings(
        meetings,
        playbook.startDate,
        user.userOrganizationId,
        user.Organization.outlookTenantId,
      );
    }
  };

  const getPreActivationChecklist = useCallback(async () => {
    const checklist = await getPlaybookPreActivationChecklist(playbook.id);
    if (!checklist) {
      return;
    }

    const { eventsInPast, unAssignedEvents, meetingsMissingAttendee } =
      checklist;
    setPreActivationChecklist([
      {
        text:
          eventsInPast > 0
            ? `${eventsInPast} event${eventsInPast !== 1 ? 's are' : ' is'} in the past`
            : 'All event dates are valid',
        isLoading: false,
        error: eventsInPast > 0,
      },
      {
        text:
          unAssignedEvents > 0
            ? `${unAssignedEvents} event${unAssignedEvents !== 1 ? 's are' : ' is'} unassigned`
            : 'All events are assigned',
        isLoading: false,
        error: unAssignedEvents > 0,
      },
      {
        text:
          meetingsMissingAttendee > 0
            ? `${meetingsMissingAttendee} meeting${meetingsMissingAttendee !== 1 ? 's are' : ' is'} missing attendees`
            : 'All meetings have attendees',
        isLoading: false,
        error: meetingsMissingAttendee > 0,
      },
    ]);

    setIsCheckListRunning(false);
  }, [playbook.id]);

  useEffect(() => {
    if (showModal) {
      getPreActivationChecklist();
    } else {
      setPreActivationChecklist(defaultCheckList);
    }
  }, [showModal, getPreActivationChecklist]);

  return {
    isBusy,
    preActivationChecklist,
    isCheckListRunning,
    keepMicrosite,
    setKeepMicrosite,
    activatePlaybook,
  };
};
