import { useCallback, useEffect, useState } from 'react';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { Pronoun } from '@API';
import { PRONOUNS } from '@Shared/utils/utils';
import { getUserData } from '@api/apis';

export const usePersonalInfo = () => {
  const [showModal, setShowModal] = useState(false);
  const { user } = useUserContext();
  const startDate = new Date(user.startDate || new Date());
  const [pronoun, setPronoun] = useState('');
  const [userData, setUserData] = useState(user);

  const handlePronoun = useCallback(() => {
    if (user.pronoun === Pronoun.HE) {
      setPronoun(PRONOUNS.HE_HIM);
    } else if (user.pronoun === Pronoun.SHE) {
      setPronoun(PRONOUNS.SHE_HER);
    } else if (user.pronoun === Pronoun.THEY) {
      setPronoun(PRONOUNS.THEY_THEM);
    } else {
      setPronoun(Pronoun.NOT_SELECTED);
    }
  }, [user.pronoun]);

  const getProfileData = async () => {
    const userData = await getUserData();
    if (userData?.length) {
      setUserData(userData[0]);
    }
  };

  useEffect(() => {
    getProfileData();
    handlePronoun();
  }, [user, handlePronoun]);

  return {
    showModal,
    userData,
    pronoun,
    startDate,
    setPronoun,
    setShowModal,
    handlePronoun,
  };
};
