import { Buffer } from 'buffer';
import ReactQuill, { Quill } from 'react-quill';
import QuillMarkdown from 'quilljs-markdown';
import BlotFormatter from 'quill-blot-formatter';
import { useEffect, useMemo, useRef, useState } from 'react';
import { addNotificationToApp } from '@Shared/utils/utils';
import EditorToolbar from '@Shared/Components/TextEditor/QuillToolbar';
import clsx from 'clsx';
import { PlainClipboard } from './PlainClipboard';
import './MessageTextEditor.scss';

Quill.register('modules/blotFormatter', BlotFormatter);
Quill.register('modules/markdownOptions', QuillMarkdown);
Quill.register('modules/clipboard', PlainClipboard);

interface IMessageTextEditor {
  value: string;
  setValue: (value: string) => void;
  onBlur?: () => void;
  placeholder: string;
  isEditable: boolean;
  theme?: string;
  height?: string;
  className?: string;
  allowImageUpload?: boolean;
  containerClassName?: string;
  extraVerticalSpacing?: boolean;
}

export const MessageTextEditor = (props: IMessageTextEditor) => {
  const [description, setDescription] = useState(props.value);
  const quillRef = useRef<ReactQuill>(null);
  const id = useMemo(
    () => `toolbar-${Math.random().toString(36).substring(2, 9)}`,
    [],
  );

  const insertText = (text: string) => {
    if (quillRef.current) {
      quillRef.current.focus();
      const cursorPosition =
        quillRef.current.getEditor().getSelection()?.index ?? 0;
      quillRef.current.getEditor().insertText(cursorPosition, text);
    }
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: `#${id}`,
        handlers: {
          mention: () => {},
        },
      },
    }),
    [id],
  );

  const updateDescription = (value: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(value, 'text/html');
    const imgElements = doc.querySelectorAll('img');

    imgElements.forEach((img, index) => {
      if (!img.getAttribute('src')!.startsWith('https')) {
        const src = img!.getAttribute('src');
        if (src && !src.includes('https')) {
          let base64FileContent = src!
            .toString()
            .replace(/^data:image\/\w+;base64,/, '');
          let buffer = Buffer.from(base64FileContent, 'base64');
          let fileSizeInByte = buffer.length;
          if (fileSizeInByte >= 4000000) {
            img.setAttribute('src', '');
            addNotificationToApp(
              'Your image file must be smaller than 4MB',
              'error',
            );
          }
        }
      }
    });

    /* If we remove all the text from the rich text editor,
           then it returns the following html by default  */
    props.setValue(
      doc.body.innerHTML === '<p><br></p>' ? '' : doc.body.innerHTML,
    );
    setDescription(doc.body.innerHTML);
  };

  useEffect(() => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(description, 'text/html');
    const imgElements = doc.querySelectorAll('img');

    imgElements.forEach((img, index) => {
      if (!img.getAttribute('src')!.startsWith('https')) {
        const src = img!.getAttribute('src');
        if (src && src === '') {
          img.remove();
          setDescription(doc.body.innerHTML);
        }
      }
    });
  }, [description]);

  useEffect(() => {
    setDescription(props.value);
  }, [props.value]);

  return (
    <div className={clsx('text-editor', props.containerClassName)}>
      <EditorToolbar
        id={id}
        allowImageUpload={props.allowImageUpload ?? true}
        isMessageTextEditor={true}
        insertText={insertText}
        isEditable={props.isEditable}
      />
      <ReactQuill
        ref={quillRef}
        className={clsx('message-text-editor', props.className, {
          'extra-vertical-spacing': props.extraVerticalSpacing,
        })}
        readOnly={!props.isEditable}
        modules={modules}
        theme={props.theme ? props.theme : 'snow'}
        onBlur={() => (props.onBlur ? props.onBlur() : {})}
        style={{ height: props.height ?? '' }}
        value={description}
        placeholder={props.placeholder}
        onChange={(value, delta, source, editor) => updateDescription(value)}
      />
    </div>
  );
};
