import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BuilderType, type Journey, JourneyStatus, UserType } from '@API';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import {
  fetchJourneys,
  getKinfolkTemplates,
  listAssignedJourneys,
} from '@api/apis';
import { useQuery } from '@tanstack/react-query';
import { isInPast } from '@Shared/utils/utils';
import { HomeKey } from './queries.keys';

export const useHome = () => {
  const { user } = useUserContext();
  const navigate = useNavigate();
  const [userSuggestedJourneys, setUserSuggestedJourneys] = useState<Journey[]>(
    [],
  );

  const getCurrentUserAssignedJourneys = async (): Promise<Journey[]> => {
    const journeys = await listAssignedJourneys(user.id);
    return journeys && journeys.length ? journeys : [];
  };

  const { data: userAssignedJourneys, isFetching: isFetchingAssignedJourneys } =
    useQuery({
      queryKey: [HomeKey.getCurrentUserAssignedJourneys],
      queryFn: getCurrentUserAssignedJourneys,
      enabled: !!user.id,
    });

  const { data: suggestedJourneys, isFetching: isFetchingSuggestedJourneys } =
    useQuery({
      queryKey: [HomeKey.getSuggestedJourneys],
      queryFn: () =>
        fetchJourneys({
          organizationID: { eq: user.userOrganizationId },
          isArchived: { eq: false },
          type: { eq: BuilderType.SELFSERVE },
          status: { eq: JourneyStatus.NOT_STARTED },
          parentJourneyID: { attributeExists: false },
        }),
      enabled: !!user.userOrganizationId && user.type === UserType.CANDIDATE,
    });

  const { data: kinfolkTemplates, isFetching: isFetchingTemplates } = useQuery({
    queryKey: [HomeKey.getKinfolkTemplates],
    queryFn: () =>
      getKinfolkTemplates({
        filter: {
          isPublic: { eq: true },
        },
      }),
    enabled: !!user.id,
  });

  const openAssignedJourney = useCallback(
    (id: string) => {
      navigate(`/runbook/${id}/microsite`);
    },
    [navigate],
  );

  const openPlaybookLibrary = () => {
    navigate('/runbooks');
  };

  useEffect(() => {
    if (suggestedJourneys && userAssignedJourneys) {
      setUserSuggestedJourneys(
        suggestedJourneys.filter(
          (suggestedJourney) =>
            !userAssignedJourneys.some(
              (userJourney) =>
                userJourney.parentJourneyID === suggestedJourney.id,
            ),
        ),
      );
    }

    if (userAssignedJourneys) {
      if (
        (user.startDate &&
          !isInPast((new Date(user.startDate).getTime() / 1000).toString()) &&
          userAssignedJourneys.length === 1) ||
        user.type === UserType.ANONYMOUS
      ) {
        openAssignedJourney(userAssignedJourneys[0].id);
      }
    }
  }, [suggestedJourneys, userAssignedJourneys, user, openAssignedJourney]);

  const isFetching = useMemo(
    () =>
      isFetchingAssignedJourneys ||
      isFetchingSuggestedJourneys ||
      isFetchingTemplates,
    [
      isFetchingAssignedJourneys,
      isFetchingSuggestedJourneys,
      isFetchingTemplates,
    ],
  );

  return {
    user,
    userAssignedJourneys,
    userSuggestedJourneys,
    kinfolkTemplates,
    isFetching,
    openPlaybookLibrary,
    openAssignedJourney,
  };
};
