import './TemplateCard.scss';
import { memo } from 'react';
import { type ITemplateCard } from '@base/models/template.model';
import { Button } from '@Shared/DS';
import { useTemplateCard } from './useTemplateCard';

export const TemplateCard = memo((props: ITemplateCard) => {
  const {
    useTemplateButtonStyle,
    isBusy,
    setUseTemplateButtonStyle,
    createJourney,
    handleViewTemplate,
  } = useTemplateCard(props.template);

  return (
    <div className="template-card-wrapper col-12 col-md-6 col-lg-4 pe-3 mt-2">
      <div
        className="template d-flex flex-column justify-content-between mb-4"
        style={{ backgroundImage: `url(${props.template.image})` }}
        onMouseEnter={() => setUseTemplateButtonStyle('d-block')}
        onMouseLeave={() => setUseTemplateButtonStyle('d-none')}
      >
        <div className="template__head cursor-pointer">
          <div
            className="template__title"
            onClick={() => handleViewTemplate(props.template.id)}
          >
            {props.template.name?.replaceAll('&amp;', '&')}
          </div>
          <div className="template__description">
            {props.template.description}
          </div>
        </div>
        <div
          className="w-100 h-100 cursor-pointer"
          onClick={() => handleViewTemplate(props.template.id)}
        ></div>
        <div className="template__footer d-flex justify-content-between">
          {useTemplateButtonStyle === 'd-none' && !isBusy && (
            <div className="description mt-2 mb-2"></div>
          )}
          <div className="d-grid gap-2">
            <Button
              buttonStyle="outline"
              additionalClasses={`mt-2 py-3 ${!isBusy ? useTemplateButtonStyle : ''}`}
              text="Use template"
              disabled={isBusy}
              showLoading={isBusy}
              onClick={() => createJourney(props.template.id)}
            />
          </div>
        </div>
      </div>
    </div>
  );
});
