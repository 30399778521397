import { BREAKPOINTS_DESKTOP_1280 } from '@Shared/utils/utils';
import { memo, useMemo } from 'react';
import useBreakpoint from 'use-breakpoint';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { LogoIcon } from '../../Atoms';

export const NavbarLogo = memo(() => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS_DESKTOP_1280, 'tablet');
  const { user } = useUserContext();
  const organizationLogo = useMemo(
    () =>
      [
        {
          id: 'd3b55d30-bbf3-4786-a57a-a213c1693563',
          logo: 'https://kin-assets.s3.eu-west-1.amazonaws.com/ericsson-logo.png',
        },
        {
          id: 'c9e8d801-a55b-4a3a-9490-d62ffc2d242f',
          logo: 'https://kin-assets.s3.eu-west-1.amazonaws.com/hugo-logo.webp',
        },
        {
          id: 'bea98cc2-2075-4fb5-b25c-73191615fad9',
          logo: 'https://kin-assets.s3.eu-west-1.amazonaws.com/team17-logo.svg',
        },
        {
          id: '91e7d5ae-3884-4c18-a9b0-ef29a9fec561',
          logo: 'https://kin-assets.s3.eu-west-1.amazonaws.com/Creative-Fabrica-logo.png',
        },
        {
          id: '7e69f484-cdd0-4f8b-8b42-c5c5610ff96b',
          logo: 'https://kin-assets.s3.eu-west-1.amazonaws.com/Beam-Logo-300x119.png',
        },
        {
          id: 'b5f5fd1e-2805-49d5-8b5b-887dd5897227',
          logo: 'https://kin-assets.s3.eu-west-1.amazonaws.com/Birdie-logo.png',
        },
        {
          id: '7c0f9c74-e153-4610-b961-69d86c3cdff2',
          logo: 'https://kin-assets.s3.eu-west-1.amazonaws.com/lunio-logo.png',
        },
        {
          id: 'org_UG4mWwi0B0VpeJu18M9Js',
          logo: 'https://kin-assets.s3.eu-west-1.amazonaws.com/correla_logo.webp',
        },
        {
          id: 'c5e2150d-500c-4eac-91d9-f0f6bed95ae2',
          logo: 'https://kin-assets.s3.eu-west-1.amazonaws.com/rora-logo.png',
        },
        {
          id: 'org_UJGu6ko0LxSu62KGXpePk',
          logo: 'https://kin-assets.s3.eu-west-1.amazonaws.com/vividimpact-logo.png',
        },
        {
          id: 'd9ba16a7-12c8-4638-b78c-103e26506d8e',
          logo: 'https://kin-assets.s3.eu-west-1.amazonaws.com/vidyard_logo.png',
        },
      ].find((organization) => organization.id === user.userOrganizationId),
    [user],
  );

  return (
    <a
      className={`navbar-brand ${breakpoint === 'mobile' ? '' : 'pe-2'}`}
      href="/"
    >
      {!!organizationLogo ? (
        <img
          src={organizationLogo.logo}
          className="img-fluid"
          style={{ width: '100px' }}
          alt="company-logo"
        />
      ) : (
        <LogoIcon
          className="img-fluid"
          height={breakpoint === 'mobile' ? '18' : '24'}
          width={breakpoint === 'mobile' ? '125' : '136.426'}
        />
      )}
    </a>
  );
});
