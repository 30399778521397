import { type FormEvent, type RootFormEvent } from '../ActivityPanel.types';

const isRootEvent = (formEvent: FormEvent) =>
  !formEvent.meta?.automation?.eventId;

const getTriggeredFormIds = (formEvent: FormEvent) =>
  formEvent.formTriggers.onSubmit.flatMap((automation) =>
    automation.action === 'SEND_FORM' ? automation.params.form : [],
  );

const findChildFormEvents = (
  root: FormEvent,
  formEventsByFormId: Record<FormEvent['formId'], FormEvent>,
): FormEvent[] => {
  const childFormEvents: FormEvent[] = [];
  const queue = [root];

  while (queue.length > 0) {
    const currentFormEvent = queue.shift();

    if (!currentFormEvent) {
      continue;
    }

    const triggeredFormEvents = getTriggeredFormIds(currentFormEvent).flatMap(
      (formId) => formEventsByFormId[formId] ?? [],
    );

    childFormEvents.push(currentFormEvent);
    queue.push(...triggeredFormEvents);
  }

  return childFormEvents.slice(1);
};

/**
 * Returns an array of root form events i.e. events that are not triggered by other forms.
 * Form events triggered by the root form event or its children, grandchildren, etc.
 * are returned as `childFormEvents` array in BFS order.
 */
export const toRootFormEvents = (formEvents: FormEvent[]): RootFormEvent[] => {
  const formEventsByFormId = Object.fromEntries(
    formEvents.map((formEvent) => [formEvent.formId, formEvent]),
  );

  return formEvents.flatMap((formEvent) => {
    if (!isRootEvent(formEvent)) {
      return [];
    }

    return {
      ...formEvent,
      childFormEvents: findChildFormEvents(formEvent, formEventsByFormId),
    };
  });
};

const hasFailedAutomation = (formEvent: FormEvent) =>
  formEvent.formTriggers.onSubmit.some(
    (automation) => automation.status === 'FAILED',
  );

export const findFailedFormEvent = (rootFormEvents: RootFormEvent[]) =>
  rootFormEvents
    .flatMap((rootFormEvent) => [
      rootFormEvent,
      ...rootFormEvent.childFormEvents,
    ])
    .find(
      (formEvent) =>
        formEvent.status === 'FAILED' || hasFailedAutomation(formEvent),
    );
