import { memo } from 'react';
import { Card } from 'react-bootstrap';
import { Badge } from '@Shared/DS/Atoms';
import './UserBadges.scss';

export const UserBadges = memo(
  ({ badges }: { badges?: ({ badgeURL: string; name: string } | null)[] }) => (
    <Card>
      <Card.Body className="user-badges">
        <div className="ms-2 fs-20-semi-bold-4C5564">Badges</div>
        <div className="d-flex flex-wrap gap-4">
          {badges?.map((badge) =>
            badge ? (
              <div key={badge.badgeURL}>
                <Badge badgeURL={badge.badgeURL} badgeTitle={badge.name} />
                <div className="badge-name mt-2">{badge.name}</div>
              </div>
            ) : null,
          )}
        </div>
      </Card.Body>
    </Card>
  ),
);
