import { AutomationsListItem } from '../AutomationsListItem';
import { NoAutomations } from '../NoAutomations';
import { type FormEvent } from '../ActivityPanel.types';
import styles from './AutomationsList.module.scss';

interface AutomationsListProps {
  formEvent: FormEvent;
  isBlocked: boolean;
}

export const AutomationsList = ({
  formEvent,
  isBlocked,
}: AutomationsListProps) => {
  const automations = formEvent.formTriggers.onSubmit;

  if (automations.length === 0) {
    return <NoAutomations formId={formEvent.formId} />;
  }

  return (
    <ul className={styles.automations}>
      {automations.map((automation) => (
        <AutomationsListItem
          automation={automation}
          isBlocked={isBlocked}
          key={automation.id}
        />
      ))}
    </ul>
  );
};
