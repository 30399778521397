import clsx from 'clsx';
import { BlockType } from '@API';
import styles from './BlockBuilder.module.scss';

interface BlockBuilderProps {
  addNewBlock: Function;
}

export const BlockBuilder = (props: BlockBuilderProps) => {
  const blockTypes = [
    {
      id: 1,
      type: BlockType.StandardContentBlock,
      icon: 'icon-content-richmedia.png',
      name: 'Standard Content',
    },
    {
      id: 2,
      type: BlockType.EmbeddedContentBlock,
      icon: 'icon-content-embedded.png',
      name: 'Embedded Content',
    },
    {
      id: 3,
      type: BlockType.TaskBlock,
      icon: 'icon-content-task.png',
      name: 'Task',
    },
    {
      id: 4,
      type: BlockType.FreeTextQuestionBlock,
      icon: 'icon-content-freetext.png',
      name: 'Free Text Question',
    },
    {
      id: 5,
      type: BlockType.MultipleChoiceQuestionBlock,
      icon: 'icon-content-singleselect.png',
      name: 'Single Select Question',
    },
    {
      id: 6,
      type: BlockType.NoteBlock,
      icon: 'icon-content-note.png',
      name: 'Note',
    },
  ];

  return (
    <div className={clsx(styles.blockBuilderWrapper, 'ms-4')}>
      {blockTypes.map((blockType) => (
        <div
          key={blockType.id}
          className={styles.blockBuilderItem}
          onClick={() => props.addNewBlock(blockType.type)}
        >
          <div className={clsx(styles.blockIcon, 'd-inline-block')}>
            <img
              src={require(`@Assets/Images/${blockType.icon}`)}
              alt={blockType.name}
            />
          </div>
          <span className="ms-3 d-inline-block">{blockType.name}</span>
        </div>
      ))}
    </div>
  );
};
