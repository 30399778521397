import { TextField } from '@Shared/DS/Molecules';
import { Row, Col } from 'react-bootstrap';

interface ICreatePlaybookStepOne {
  playbookName: string;
  setPlaybookName(value: string): void;
  isSubmitted: boolean;
}
export const CreatePlaybookStepOne = ({
  playbookName,
  setPlaybookName,
  isSubmitted,
}: ICreatePlaybookStepOne) => (
  <Row>
    <Col>
      <form>
        <label
          className="fs-14-semi-bold col-12 form-label text-start"
          htmlFor={'playbookName'}
        >
          Runbook name<span className="required-sign">*</span>
        </label>
        <TextField
          id={'playbookName'}
          type="text"
          defaultValue={playbookName}
          setValue={setPlaybookName}
          fieldName="Runbook name"
          width=""
          placeholder="e.g Interview Training"
          additionalClasses={`${!playbookName && isSubmitted ? 'is-invalid' : ''}`}
        />
      </form>
    </Col>
  </Row>
);
