import clsx from 'clsx';
import { useId } from 'react';
import { Collapse } from 'react-bootstrap';
import { AutomationsList } from '../AutomationsList';
import { FormDetails } from '../FormDetails';
import { FormPreview } from '../FormPreview';
import { type FormEvent } from '../ActivityPanel.types';
import automationsListItemStyles from '../AutomationsListItem/AutomationsListItem.module.scss';
import styles from './FormsListItem.module.scss';

interface FormsListItemProps {
  formEvent: FormEvent;
  isExpanded: boolean;
  isLastForm: boolean;
  onToggle: () => void;
}

export const FormsListItem = ({
  formEvent,
  isExpanded,
  isLastForm,
  onToggle,
}: FormsListItemProps) => {
  const automationsListId = useId();
  const isBlocked = formEvent.status !== 'DONE';

  return (
    <li
      className={clsx(styles.container, {
        [styles.blocked]: isBlocked,
        [styles.lastForm]: isLastForm,
        [automationsListItemStyles.lastForm]: isLastForm,
      })}
    >
      <FormDetails
        aria-controls={automationsListId}
        aria-expanded={isExpanded}
        formEvent={formEvent}
        onClick={onToggle}
      />
      <FormPreview className={styles.previewButton} formId={formEvent.formId} />

      <Collapse in={isExpanded}>
        <div id={automationsListId}>
          <AutomationsList formEvent={formEvent} isBlocked={isBlocked} />
        </div>
      </Collapse>
    </li>
  );
};
