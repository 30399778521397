import React, {
  createContext,
  useState,
  useContext,
  type ReactNode,
} from 'react';

interface DynamicTagsContextType {
  values: { [key: string]: string };
  setTagsValues: (values: { [key: string]: string }) => void;
  substituteValues: (id: string) => string;
}

const DynamicTagsContext = createContext<DynamicTagsContextType | undefined>(
  undefined,
);

interface TagsProviderProps {
  children: ReactNode;
}

export const DynamicTagsProvider: React.FC<TagsProviderProps> = ({
  children,
}) => {
  const [values, setValues] = useState<{ [key: string]: string }>({});

  const setTagsValues = (values: { [key: string]: string }) => {
    setValues((prevValues) => ({ ...prevValues, ...values }));
  };

  const substituteValues = (id: string): string => values[id];

  return (
    <DynamicTagsContext.Provider
      value={{ values, setTagsValues, substituteValues }}
    >
      {children}
    </DynamicTagsContext.Provider>
  );
};

export const useDynamicTagsContext = (): DynamicTagsContextType => {
  const context = useContext(DynamicTagsContext);
  if (context === undefined) {
    throw new Error('useDynamicTagsContext must be used within a TagsProvider');
  }
  return context;
};
