import clsx from 'clsx';
import { type ReactNode } from 'react';
import styles from './EmptyState.module.scss';

interface EmptyStateProps {
  button?: ReactNode;
  className?: string;
  description: ReactNode;
  icon?: ReactNode;
  title: string;
}

export const EmptyState = ({
  button,
  className,
  description,
  icon,
  title,
}: EmptyStateProps) => (
  <section className={clsx(className, styles.emptyState)}>
    {icon}
    <h4 className={styles.title}>{title}</h4>
    <div className={clsx(styles.description, { [styles.withButton]: button })}>
      {description}
    </div>
    {button}
  </section>
);
