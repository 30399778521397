import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { type Organization } from '@API';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { getOrganizationData } from '@api/apis';

export const useIntegrationsTab = () => {
  const { user } = useUserContext();
  const [organization, setOrganization] = useState<Organization>();
  const [activeSubscription, setActiveSubscription] = useState(false);

  const getOrganization = async () => {
    const organizationInfo = await getOrganizationData(user.userOrganizationId);
    return organizationInfo;
  };
  const { data: organizationData } = useQuery({
    queryKey: ['getOrganization'],
    queryFn: getOrganization,
    enabled: !!user.userOrganizationId,
  });

  useEffect(() => {
    setActiveSubscription(!!user?.Organization?.hasSubscription);
  }, [user?.Organization?.hasSubscription]);

  useEffect(() => {
    if (organizationData) {
      setOrganization(organizationData);
    }
  }, [organizationData]);

  return {
    activeSubscription,
    organization,
  };
};
