import { type ComponentProps } from 'react';
import { Button } from '@Shared/DS';
import { useCreateForm } from './useCreateForm';

type CreateFormButtonProps = Omit<
  Partial<ComponentProps<typeof Button>>,
  'onClick' | 'disabled' | 'showLoading'
>;

export const CreateFormButton = (props: CreateFormButtonProps) => {
  const { handleCreateForm, isLoading } = useCreateForm();

  return (
    <Button
      buttonStyle="primary"
      text="Create a form"
      {...props}
      onClick={handleCreateForm}
      disabled={isLoading}
      showLoading={isLoading}
    />
  );
};
