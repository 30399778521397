import { BlockType } from '@API';
import {
  DragDropContext,
  Droppable,
  type DroppableProvided,
} from 'react-beautiful-dnd';
import { EmbeddedContentsBlock } from '../BlockTypes/EmbeddedContent/EmbeddedContent';
import { FreeTextQuestionsBlock } from '../BlockTypes/FreeTextQuestion/FreeTextQuestion';
import { MultipleChoiceQuestionsBlock } from '../BlockTypes/MultipleChoiceQuestion/MultipleChoiceQuestion';
import { NotesBlock } from '../BlockTypes/Notes/Notes';
import { StandardContentBlocks } from '../BlockTypes/StandardContent/StandardContent';
import { TaskBlocks } from '../BlockTypes/Task/Task';
import { useBlocksLoop } from './useBlocksLoop';

interface BlocksLoopProps {
  milestones: any;
  selectedMilestoneIndex: number;
  isEditable: boolean;
  isParentPlaybook: boolean;
  isAssignee?: boolean;
  handleUpdateMilestoneBlocks: Function;
  setShowSaved: Function;
  setShowLoader: Function;
  handleDeleteBlock: Function;
  isBusy: boolean;
  setIsBusy: Function;
  setMilestones: Function;
  setIsSaving?: Function;
}

export const BlocksLoop = (props: BlocksLoopProps) => {
  const {
    milestones,
    selectedMilestoneIndex,
    isEditable,
    isAssignee,
    isBusy,
    handleUpdateMilestoneBlocks,
    setShowSaved,
    setShowLoader,
    handleDeleteBlock,
    setMilestones,
    setIsBusy,
    setIsSaving,
    isParentPlaybook,
  } = props;
  const { blocks, handleOnDragEnd } = useBlocksLoop(
    milestones,
    selectedMilestoneIndex,
    setMilestones,
    setIsBusy,
    isParentPlaybook,
  );

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="content-blocks">
        {(provided: DroppableProvided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {blocks?.map((block: any, index: number) => {
              switch (block.type) {
                case BlockType.StandardContentBlock:
                  return (
                    <StandardContentBlocks
                      index={index}
                      key={block.id}
                      block={block}
                      isEditable={isEditable}
                      handleUpdateMilestoneBlocks={handleUpdateMilestoneBlocks}
                      setShowLoader={setShowLoader}
                      setIsSaving={setIsSaving}
                      setShowSaved={setShowSaved}
                      handleDeleteBlock={handleDeleteBlock}
                      isBusy={isBusy}
                      isParentPlaybook={isParentPlaybook}
                    />
                  );
                case BlockType.TaskBlock:
                  return (
                    <TaskBlocks
                      index={index}
                      key={block.id}
                      block={block}
                      isEditable={isEditable}
                      isAssignee={isAssignee}
                      handleUpdateMilestoneBlocks={handleUpdateMilestoneBlocks}
                      setShowLoader={setShowLoader}
                      setShowSaved={setShowSaved}
                      setIsSaving={setIsSaving}
                      handleDeleteBlock={handleDeleteBlock}
                      isBusy={isBusy}
                      isParentPlaybook={isParentPlaybook}
                    />
                  );
                case BlockType.NoteBlock:
                  return (
                    <NotesBlock
                      index={index}
                      key={block.id}
                      block={block}
                      isEditable={isEditable}
                      handleUpdateMilestoneBlocks={handleUpdateMilestoneBlocks}
                      setShowLoader={setShowLoader}
                      setShowSaved={setShowSaved}
                      setIsSaving={setIsSaving}
                      isAssignee={isAssignee}
                      handleDeleteBlock={handleDeleteBlock}
                      isBusy={isBusy}
                      isParentPlaybook={isParentPlaybook}
                    />
                  );
                case BlockType.FreeTextQuestionBlock:
                  return (
                    <FreeTextQuestionsBlock
                      index={index}
                      key={block.id}
                      block={block}
                      isEditable={isEditable}
                      handleUpdateMilestoneBlocks={handleUpdateMilestoneBlocks}
                      setShowLoader={setShowLoader}
                      setShowSaved={setShowSaved}
                      setIsSaving={setIsSaving}
                      isAssignee={isAssignee}
                      handleDeleteBlock={handleDeleteBlock}
                      isBusy={isBusy}
                      isParentPlaybook={isParentPlaybook}
                    />
                  );
                case BlockType.MultipleChoiceQuestionBlock:
                  return (
                    <MultipleChoiceQuestionsBlock
                      index={index}
                      key={block.id}
                      block={block}
                      isEditable={isEditable}
                      handleUpdateMilestoneBlocks={handleUpdateMilestoneBlocks}
                      setShowLoader={setShowLoader}
                      setShowSaved={setShowSaved}
                      setIsSaving={setIsSaving}
                      isAssignee={isAssignee}
                      handleDeleteBlock={handleDeleteBlock}
                      isBusy={isBusy}
                      isParentPlaybook={isParentPlaybook}
                    />
                  );
                case BlockType.EmbeddedContentBlock:
                  return (
                    <EmbeddedContentsBlock
                      index={index}
                      key={block.id}
                      block={block}
                      isEditable={isEditable}
                      isAssignee={isAssignee}
                      setShowLoader={setShowLoader}
                      setShowSaved={setShowSaved}
                      setIsSaving={setIsSaving}
                      handleUpdateMilestoneBlocks={handleUpdateMilestoneBlocks}
                      handleDeleteBlock={handleDeleteBlock}
                      isBusy={isBusy}
                      isParentPlaybook={isParentPlaybook}
                    />
                  );
                default:
                  return <div></div>;
              }
            })}

            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
