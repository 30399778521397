interface BackButtonProps {
  handleClick: Function;
}

export const BackButton = (props: BackButtonProps) => (
  <svg
    className="cursor-pointer"
    onClick={() => props.handleClick()}
    width="62"
    height="62"
    viewBox="0 0 62 62"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_237_3263)">
      <path
        d="M31 3C17.1929 3 6 14.1929 6 28C6 41.8071 17.1929 53 31 53C44.8071 53 56 41.8071 56 28C56 14.1929 44.8071 3 31 3Z"
        fill="white"
      />
    </g>
    <path
      d="M33.0201 36.6841L25.5201 28.6841C25.3463 28.4988 25.2496 28.2542 25.2496 28.0001C25.2496 27.746 25.3463 27.5015 25.5201 27.3161L33.0201 19.3161C33.2015 19.1225 33.4524 19.0089 33.7176 19.0003C33.9828 18.9916 34.2405 19.0887 34.4341 19.2701C34.6277 19.4515 34.7413 19.7024 34.75 19.9676C34.7586 20.2328 34.6615 20.4905 34.4801 20.6841L27.6211 28.0001L34.4801 35.3161C34.6615 35.5097 34.7586 35.7675 34.75 36.0326C34.7413 36.2978 34.6277 36.5487 34.4341 36.7301C34.2405 36.9115 33.9828 37.0086 33.7176 37C33.4524 36.9913 33.2015 36.8777 33.0201 36.6841Z"
      fill="#25272A"
    />
    <defs>
      <filter
        id="filter0_d_237_3263"
        x="0"
        y="0"
        width="62"
        height="62"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0666667 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.078 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_237_3263"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_237_3263"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
