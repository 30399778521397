import clsx from 'clsx';
import { Form } from 'react-bootstrap';
import { MessageTextEditor } from '@Shared/Components/TextEditor/MessageTextEditor/MessageTextEditor';
import { type AddMessageModalProps } from '@base/models/journeyLibrary.model';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useSearch } from '@base/Hooks/useSearch';
import { FloatingLabelDropdown } from '@Shared/DS';
import { Button } from '@Shared/DS/Atoms';
import { useEmailTab } from './useEmailTab';
import styles from './EmailTab.module.scss';

export const EmailTab = (props: AddMessageModalProps) => {
  const { isParentJourney, onHideModal, assigneeEmails } = props;
  const { users, isSearching, findUser } = useSearch();
  const {
    subject,
    messageBody,
    isSubmitted,
    isSaving,
    daysFromOptions,
    cc,
    selectedDaysFrom,
    isEditable,
    parentJourneyEmailOptions,
    selectedEmail,
    time,
    times,
    showCCField,
    showLoader,
    setShowCCField,
    setTime,
    setSelectedEmail,
    setCc,
    setSubject,
    setMessageBody,
    createNewMessage,
    setSelectedDaysFrom,
  } = useEmailTab(props);

  return (
    <div>
      <div className={styles.inputContainer}>
        <label htmlFor="message-recipient">To</label>
        <Form.Select
          value={selectedEmail ?? ''}
          disabled={!isEditable}
          onChange={(e) => setSelectedEmail(e.target.value)}
          aria-label="Message recipient"
          className={clsx(styles.formSelectField, 'ps-5')}
        >
          {[
            ...(isParentJourney ? parentJourneyEmailOptions : assigneeEmails),
          ].map((option, i) => (
            <option key={i} value={option}>
              {option}
            </option>
          ))}
        </Form.Select>
        {!showCCField && isEditable && (
          <span
            className={styles.ccOption}
            onClick={() => setShowCCField(true)}
          >
            CC
          </span>
        )}
      </div>

      {showCCField && (
        <div className={styles.inputContainer}>
          <label htmlFor="cc">Cc</label>
          <AsyncTypeahead
            className={styles.ccField}
            multiple={true}
            id="cc-recipient"
            allowNew
            newSelectionPrefix="External user: "
            isLoading={isSearching}
            selected={cc}
            disabled={!isEditable}
            onSearch={findUser}
            minLength={2}
            labelKey={(option: any) =>
              option.firstName
                ? `${option.firstName} ${option.lastName}`
                : option.email
                  ? `${option.email}`
                  : (option.role ?? option.label ?? '')
            }
            onChange={(users: any[]) => setCc(users)}
            options={users}
            renderMenuItemChildren={(option: any) => (
              <>
                <span>
                  {option.firstName && option.firstName !== ''
                    ? `${option.firstName}  ${option.lastName}<${option.email}>`
                    : option.email
                      ? ` ${option.email}`
                      : `${option.role}`}
                </span>
              </>
            )}
          />
        </div>
      )}

      <div className={styles.inputContainer}>
        <label htmlFor="message-subject">Subject</label>
        <Form.Control
          type="text"
          disabled={!isEditable}
          defaultValue={subject}
          onChange={(e) => setSubject(e.target.value)}
          className={clsx(styles.subjectField, {
            'is-invalid': subject.length === 0 && isSubmitted,
          })}
        />
        <div className="invalid-feedback text-start ms-3">
          Subject should not be empty
        </div>
      </div>

      <div className={styles.timeAndBodyContainer}>
        <div className="d-flex flex-wrap mt-3">
          <div className="flex-fill">
            <FloatingLabelDropdown
              label="Send timing"
              disabled={!isEditable}
              options={daysFromOptions}
              errorMessage="Please select day"
              selected={selectedDaysFrom}
              setSelected={setSelectedDaysFrom}
            />
          </div>

          <div className="flex-fill mt-2 mt-lg-0 ms-lg-2">
            <FloatingLabelDropdown
              label="Time (UTC)"
              disabled={!isEditable}
              options={times}
              selected={time}
              setSelected={setTime}
            />
          </div>
        </div>

        <div>
          <label
            className="fs-14-semi-bold-25272a col-12 text-start mt-3"
            htmlFor="message"
          >
            Message<span className="required-field-sign">*</span>
          </label>
          <MessageTextEditor
            value={messageBody}
            setValue={setMessageBody}
            theme="snow"
            isEditable={isEditable}
            placeholder="Write your message"
          />
        </div>
        <div className="col-12">
          {!messageBody.length && isSubmitted && (
            <div className="mt-1 error-text text-start">
              Message body shouldn't be empty
            </div>
          )}
        </div>

        <div className={styles.saveBtn}>
          <Button
            buttonStyle="outline"
            additionalClasses="me-2 mt-2"
            text="Cancel"
            onClick={onHideModal}
          />
          <Button
            additionalClasses="me-2 mt-2"
            showLoading={showLoader}
            text={`${
              isSaving ? (
                <>
                  <span role="status" aria-hidden="true">
                    Saving...
                  </span>
                </>
              ) : (
                'Save'
              )
            }`}
            disabled={!isEditable}
            type="submit"
            onClick={createNewMessage}
          />
        </div>
      </div>
    </div>
  );
};
