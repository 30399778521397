import { addNotificationToApp } from '@Shared/utils/utils';
import {
  activateChildPlaybooks,
  fetchPreboardingUsers,
  getJourneyCollaborators,
  getPlaybook,
  getUserBasicData,
  pausePlaybooks,
  updateChildJourneys,
  updateJourney,
} from '@api/apis';
import {
  type Journey,
  JourneyStatus,
  PlaybookMode,
  type PreboardingUser,
} from '@base/API';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { Analytics } from '@base/analytics/analytics';
import { Keys } from '@base/keys/queryKeys';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const usePlaybookHome = () => {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const [states, setStates] = useState({
    showActivateConfirmationModal: false,
    showPauseConfirmationModal: false,
    showPlaybookChangesModal: false,
    isGoingBackWithChanges: false,
    playbookName: '',
  });
  const {
    setCollaborators,
    setJourneyPreboardingUser,
    isParentJourneyChanged,
    setIsParentJourneyChanged,
    setPlaybook,
  } = useUserContext();
  const navigate = useNavigate();

  const {
    data: playbook,
    isLoading,
    isFetched,
  } = useQuery({
    queryKey: Keys.getPlaybook(id),
    queryFn: () => {
      if (!id) {
        return;
      }
      return getPlaybook(id);
    },
    enabled: !!id,
  });

  const { data: assignee } = useQuery({
    queryKey: Keys.getUser(playbook?.assignedUserID?.toString()),
    queryFn: () => {
      if (!id) {
        return;
      }
      return getUserBasicData(playbook?.assignedUserID?.toString() ?? '');
    },
    enabled: !!playbook && !!playbook.assignedUserID,
  });

  const { data: collaborators } = useQuery({
    queryKey: Keys.getPlaybookCollaborators(id),
    queryFn: () => {
      if (!id) {
        return [];
      }

      return getJourneyCollaborators(id, false);
    },
    enabled: !!id,
  });

  const { data: preboardingUser } = useQuery({
    queryKey: Keys.getPlaybookPreboardingUser(assignee?.id),
    queryFn: async () => {
      if (!assignee) {
        return;
      }

      const users = await fetchPreboardingUsers({
        userId: { eq: assignee.id },
      });
      if (!users || !users.length) {
        return {} as PreboardingUser;
      }

      const [preboardingUser] = users;
      return preboardingUser;
    },
    enabled: !!assignee,
  });

  /**
   * This function can be used to activate child and individual playbooks
   */
  const activatePlaybook = async () => {
    if (!id || !playbook) {
      return;
    }

    setStates((prevState) => ({
      ...prevState,
      showActivateConfirmationModal: false,
    }));
    addNotificationToApp('Activating the runbook', 'default');
    await activateChildPlaybooks(id);
    const status =
      playbook.mode === PlaybookMode.WORKFLOW || playbook.userStartedAt
        ? JourneyStatus.IN_PROGRESS
        : JourneyStatus.NOT_STARTED;
    queryClient.invalidateQueries({ queryKey: Keys.getChildPlaybooks(id) });
    refreshPlaybookWorkflows();
    updatePlaybook({ ...playbook, status });

    Analytics.playbookActivated(id);
  };

  /**
   * This function can be used to pause child and individual playbooks
   */
  const pausePlaybook = async () => {
    if (!id || !playbook) {
      return;
    }

    setStates((prevState) => ({
      ...prevState,
      showPauseConfirmationModal: false,
    }));
    addNotificationToApp('Pausing the runbook', 'default');
    await pausePlaybooks(id);
    queryClient.invalidateQueries({ queryKey: Keys.getChildPlaybooks(id) });
    refreshPlaybookWorkflows();
    updatePlaybook({ ...playbook, status: JourneyStatus.DRAFT });
    Analytics.playbookPaused(playbook.id);
  };

  const updatePlaybook = (playbook: Journey) => {
    queryClient.setQueryData(Keys.getPlaybook(id), playbook);
  };

  const refreshPlaybookWorkflows = () => {
    queryClient.invalidateQueries({ queryKey: Keys.getEvents(id) });
  };

  const propagatePlaybooks = async (update: boolean) => {
    setStates((prevState) => ({
      ...prevState,
      showPlaybookChangesModal: false,
    }));
    if (states.isGoingBackWithChanges) {
      setIsParentJourneyChanged(false);
      handleBack(true);
    }

    if (update && id) {
      addNotificationToApp(
        "It'll take a while to update, we'll let you know when we're done",
        'default',
      );
      await updateChildJourneys(id);
      setIsParentJourneyChanged(false);
      addNotificationToApp(
        'Hurray! your changes are published to all playbooks now.',
        'success',
      );
    }
  };

  const updatePlaybookName = async (id: string, name: string) => {
    if (playbook && name !== playbook.name) {
      await updateJourney({ id, name } as Journey);
      setIsParentJourneyChanged(true);
    }
  };

  const navigateTo = (path: string) => {
    navigate(path);
  };

  const handleBack = useCallback(
    (proceed: boolean) => {
      if (proceed) {
        navigate('/runbooks');
      } else {
        setStates((prevState) => ({
          ...prevState,
          showPlaybookChangesModal: true,
          isGoingBackWithChanges: true,
        }));
      }
    },
    [navigate],
  );

  useEffect(() => {
    if (isFetched && !playbook) {
      addNotificationToApp('Runbook not found', 'error');
      return;
    }

    setStates((states) => ({ ...states, playbookName: playbook?.name ?? '' }));
  }, [isFetched, playbook]);

  useEffect(() => {
    setCollaborators(collaborators ?? []);
    setJourneyPreboardingUser(preboardingUser?.id ? preboardingUser : null);
    if (playbook) {
      setPlaybook(playbook);
    }
  }, [
    collaborators,
    preboardingUser,
    setCollaborators,
    setJourneyPreboardingUser,
    playbook,
    setPlaybook,
  ]);

  useEffect(() => {
    window.history.pushState(null, '', window.location.pathname);
    const handleBackButton = (event: PopStateEvent) => {
      event.preventDefault();
      handleBack(!isParentJourneyChanged);
    };

    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [handleBack, isParentJourneyChanged]);

  return {
    playbook,
    assignee,
    isLoading,
    states,
    isParentJourneyChanged,
    setStates,
    updatePlaybook,
    activatePlaybook,
    propagatePlaybooks,
    handleBack,
    navigateTo,
    updatePlaybookName,
    pausePlaybook,
  };
};
