import { type ChangeEvent, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createJourneyCollaborator, createKinfolkTemplate } from '@api/apis';
import { uploadData } from '@aws-amplify/storage';
import {
  AssigneeRole,
  BuilderType,
  type JourneyCollaborator,
  type KinfolkTemplate,
  type PlaybookMode,
} from '@API';
import { BUCKET_USERIMAGES_URL, downloadAWSPhoto } from '@Shared/utils/utils';

export const useCreateTemplateModal = (handleShowModal: Function) => {
  const [templateName, setTemplateName] = useState('');
  const [description, setDescription] = useState('');
  const [banner, setBanner] = useState<{ file: File; url: string }>();
  const [isPublic, setIsPublic] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [isTemplateTypeSelected, setIsTemplateTypeSelected] = useState(false);
  const [selectedTemplateType, setSelectedTemplateType] =
    useState<BuilderType | null>(null);
  const [playbookMode, setPlaybookMode] = useState<PlaybookMode | null>(null);
  const [stepNumber, setStepNumber] = useState(1);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  const handleCreateTemplate = async () => {
    setIsSubmitted(true);
    setShowLoader(true);
    if (templateName.length > 0 && description.length > 0 && banner) {
      const { key } = await uploadData({
        key: `${BUCKET_USERIMAGES_URL}${banner.file.name.replace(' ', '')}`,
        data: banner.file,
        options: {
          contentType: banner.file.type,
        },
      }).result;

      const fetchedUrl = await downloadAWSPhoto(key);
      const templateId = await createKinfolkTemplate({
        name: templateName,
        image: fetchedUrl,
        description: description,
        type: selectedTemplateType,
        mode: playbookMode,
        isPublic,
      } as KinfolkTemplate);

      if (templateId) {
        Promise.all(
          Object.keys(AssigneeRole).map((role) =>
            createJourneyCollaborator({
              assigneeRole: role,
              kinfolkTemplateID: templateId,
            } as JourneyCollaborator),
          ),
        );
        navigate(`/template/${templateId}`);
      }
    }
    setShowLoader(false);
  };

  const selectFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const image = e.target.files[0];
      const imageURL = URL.createObjectURL(image);
      setBanner({ file: image, url: imageURL });
    }
  };

  const chooseBanner = (e: React.MouseEvent) => {
    e.preventDefault();
    if (fileInputRef.current !== null) {
      fileInputRef.current.click();
    }
  };

  const handleHideModal = () => {
    setIsSubmitted(false);
    setIsLoading(false);
    handleShowModal(false);
    setDescription('');
    setTemplateName('');
    setPlaybookMode(null);
    setStepNumber(1);
    setIsTemplateTypeSelected(false);
    setSelectedTemplateType(BuilderType.ONBOARDING);
    setBanner(undefined);
    setIsPublic(true);
  };

  const currentStep = useMemo(() => {
    if (stepNumber === 1) {
      return {
        title: 'Choose type',
        description:
          'Onboarding runbooks trigger on a new hire start dates, Initiatives on specific dates, and Self-serve when the assignee starts it.',
      };
    } else if (stepNumber === 2) {
      return {
        title: 'Choose mode',
        description:
          'Select how you want to enable employees. With in-app content and messages, or only messages.',
      };
    } else if (stepNumber === 3) {
      return {
        title: 'Name your template',
        description: 'Visible to Admins in the runbook library',
      };
    }
  }, [stepNumber]);

  return {
    stepNumber,
    currentStep,
    isSubmitted,
    isLoading,
    fileInputRef,
    banner,
    showLoader,
    playbookMode,
    templateName,
    description,
    isTemplateTypeSelected,
    selectedTemplateType,
    isPublic,
    setPlaybookMode,
    setStepNumber,
    setTemplateName,
    setIsTemplateTypeSelected,
    setSelectedTemplateType,
    setDescription,
    setIsPublic,
    handleCreateTemplate,
    handleHideModal,
    selectFile,
    chooseBanner,
  };
};
