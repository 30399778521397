import { type FormEvent, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { addNotificationToApp } from '@Shared/utils/utils';
import { autoSignIn, confirmSignUp, resendSignUpCode } from 'aws-amplify/auth';
import { useUserContext } from '@base/Context/UserContext/UserContext';

export const useAccountVerification = () => {
  const location = useLocation();
  const { email }: any = location.state;
  const [currentUserEmail, setCurrentUserEmail] = useState(email);
  const [verificationCode, setVerificationCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { getProfileData } = useUserContext();

  const handleEmailVerification = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setIsSubmitted(true);
      setIsLoading(true);

      if (currentUserEmail !== '' && verificationCode !== '') {
        await confirmSignUp({
          username: currentUserEmail,
          confirmationCode: verificationCode,
        });
        const { isSignedIn } = await autoSignIn();

        if (isSignedIn) {
          localStorage.setItem('isLoggedIn', 'true');
          await getProfileData();
        }
      }

      addNotificationToApp('Account verified successfully', 'success');
    } catch (e: any) {
      setIsLoading(false);
      addNotificationToApp(e.message, 'error');
    }
  };

  const resendVerificationCode = async () => {
    try {
      await resendSignUpCode({ username: currentUserEmail });
      addNotificationToApp('Code sent successfully', 'success');
    } catch (e) {
      addNotificationToApp('Failed to send verification code', 'error');
    }
  };

  return {
    currentUserEmail,
    verificationCode,
    isSubmitted,
    isLoading,
    setCurrentUserEmail,
    setVerificationCode,
    handleEmailVerification,
    resendVerificationCode,
  };
};
