import { memo } from 'react';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { Button } from '@Shared/DS';
import { AddCalendarKeysModal } from '../AddCalendarKeysModal/AddCalendarKeysModal';
import { useCalendar } from './useCalendar';

export const CalendarIntegrationCard = memo(() => {
  const { user } = useUserContext();
  const {
    secretKeyCreatedDate,
    secretManagerData,
    showAddKeysModal,
    isOutlookConnected,
    setShowAddKeysModal,
    setSecretKeyCreatedDate,
  } = useCalendar();

  return (
    <>
      {user.type !== 'SUPER_ADMIN' && (
        <>
          <div className="row g-0 px-4 py-4">
            <div className="col-sm-8 col-md-8 d-flex">
              <img
                alt="Google Calendar"
                src={require('../../../../Assets/Images/google-calendar.png')}
                className="settings-card-image"
              ></img>
              <p className="update-description px-3">
                <span className="fs-16-semi-bold">Google Calendar</span>
              </p>
            </div>

            <div className="col-sm-4 col-md-4">
              {secretKeyCreatedDate ? (
                <div>
                  <div className="col">
                    <div className="row">
                      <span>✅ **************</span>
                    </div>
                    <div className="row">
                      <div className="col-9">
                        <span className="fs-12-medium-bold">
                          Added{' '}
                          {new Date(secretKeyCreatedDate)
                            .toLocaleDateString('en-GB')
                            .replace(/\//g, '.')}
                        </span>
                      </div>
                      <div className="col-2">
                        <span
                          className="fs-12-medium-bold cursor-pointer"
                          onClick={() => {
                            setShowAddKeysModal(true);
                          }}
                        >
                          Edit
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <button
                    className="btn btn-button-outline rounded-pill fs-14-medium-bold w-100"
                    disabled={false}
                    onClick={() => setShowAddKeysModal(true)}
                  >
                    <span>Connect</span>
                  </button>
                </div>
              )}
            </div>

            <AddCalendarKeysModal
              showModal={showAddKeysModal}
              handleShowModal={setShowAddKeysModal}
              setSecretKeyCreatedDate={setSecretKeyCreatedDate}
              secretKeyManager={secretManagerData}
            ></AddCalendarKeysModal>
          </div>
          <div className="row g-0 px-4 py-4 mt-4">
            <div className="col-sm-8 col-md-8 d-flex">
              <img
                alt="Outlook Calendar"
                src={require('../../../../Assets/Images/microsoft-calendar.png')}
                className="settings-card-image"
              ></img>
              <p className="update-description px-3">
                <span className="fs-16-semi-bold">Office 365 Outlook</span>
              </p>
            </div>

            <div className="col-sm-4 col-md-4">
              <div>
                <a
                  rel="noopener noreferrer"
                  role="button"
                  aria-disabled={isOutlookConnected}
                  href={`https://login.microsoftonline.com/organizations/adminconsent?client_id=${process.env.REACT_APP_MICROSOFT_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_MICROSOFT_OAUTH_URL}&scope=openid%20profile%20email%20https://graph.microsoft.com/Calendars.ReadWrite%20https://graph.microsoft.com/MailboxSettings.Read&state=${user.userOrganizationId}`}
                  className={`${isOutlookConnected && 'disabled'}`}
                >
                  <Button
                    text={
                      isOutlookConnected ? 'Connected' : 'Connect your Outlook'
                    }
                    buttonStyle="outline"
                  />
                </a>
              </div>
            </div>
          </div>
        </>
      )}
      {user.type === 'SUPER_ADMIN' && (
        <div className="row g-0 px-4 py-4 mt-4">
          <div className="col-sm-8 col-md-7 d-flex">
            <p className="update-description px-3">
              <img
                alt="Google Calendar"
                src="https://kin-assets.s3.eu-west-1.amazonaws.com/Google_Calendar_icon.png"
                style={{
                  height: '45px',
                  width: 'auto',
                  paddingRight: '9px',
                  paddingBottom: '5px',
                }}
              />
              <span className="fs-16-semi-bold">Google Calendar</span>
              <span className="fs-16">
                <br />
                Connect your Google calendar and let Kinfolk schedule your
                onboarding meetings in 1-click.
              </span>
              <br /> <br />
              <span className="fs-13">
                Kinfolk's use and transfer of information received from Google
                APIs to any other app will adhere to Google API Services User
                Data Policy, including the Limited Use requirements. Read more
                from our{' '}
                <a
                  href="https://www.kinfolkhq.com/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  privacy policy here
                </a>
                .
              </span>
            </p>
          </div>

          <div className="col-sm-4 col-md-5">
            <div>
              <a
                rel="noopener noreferrer"
                href={`https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=985336834025-8t1a2aean3ek8cvfq6qtc9qnr6qodjq2.apps.googleusercontent.com&redirect_uri=${process.env.REACT_APP_GOOGLE_RETURN_URI}&scope=https://www.googleapis.com/auth/calendar&state=123`}
              >
                <button className="btn social-provider-button px-3">
                  Connect Google Calendar
                </button>
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
});
