import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { addOrganizationFromAdmin } from '@api/apis';
import {
  type AdminWithOrganization,
  type OrganizationInfoProps,
} from '@models/settings.model';

export const useOrganizationInfo = (props: OrganizationInfoProps) => {
  const [organizationName, setOrganizationName] = useState(props.orgName);
  const [adminEmail, setAdminEmail] = useState(props.adminEmail);
  const [isOrgAdded, setIsOrgAdded] = useState(props.alreadyPresent);
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();

  const addOrganization = async (
    adminEmail: string,
    organizationName: string,
  ) => {
    setIsLoading(true);
    const addOrgResponse = await addOrganizationFromAdmin(
      adminEmail,
      organizationName,
    );
    if (addOrgResponse) {
      const queryData = queryClient.getQueryData<AdminWithOrganization[]>([
        'getAdminWithOrganization',
      ]);
      if (queryData) {
        queryClient.setQueryData(
          ['getAdminWithOrganization'],
          [
            ...queryData,
            {
              email: adminEmail,
              name: organizationName,
            } as AdminWithOrganization,
          ],
        );
      }
      setIsOrgAdded(true);
    }
    setIsLoading(false);
  };

  return {
    organizationName,
    adminEmail,
    isOrgAdded,
    isLoading,
    setOrganizationName,
    setAdminEmail,
    addOrganization,
  };
};
