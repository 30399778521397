import {
  AddEventDropdown,
  Button,
  Collaborators,
  CreateUpdateAction,
  CreateUpdateFormEventPanel,
  DraggableEvents,
  Filter,
  WarningBanner,
} from '@Shared/DS';
import { type IPlaybookHome } from '@base/models/playbookHome.model';
import { AddMessageModal } from '@Shared/Components/AddMessageModal/AddMessageModal';
import { InfoModal } from '@Shared/Components/InfoModal/InfoModal';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { Link } from 'react-router-dom';
import { BuilderType } from '@base/API';
import Loader from '@Shared/Components/Loader/Loader';
import { Activity } from '@Shared/Icons';
import { ActivityPanel } from '@Shared/DS/Organism';
import { useFeatureFlags } from '@Hooks/useFeatureFlags';
import { usePlaybookCalendar } from './usePlaybookCalendar';

export const PlaybookCalendar = ({ playbook, assignee }: IPlaybookHome) => {
  const {
    id,
    states,
    filteredCalendarEvents,
    filterItems,
    isEventsLoading,
    setSelectedFilter,
    setStates,
    getDateFromWeekDay,
    refreshCalendarEvents,
    editEvent,
    createEvent,
    deleteEvent,
    testEvents,
    handleDragEnd,
  } = usePlaybookCalendar(playbook, assignee);
  const { user } = useUserContext();
  const featureFlags = useFeatureFlags();

  const isChildPlaybook = !!assignee;
  const canSeeActivityPanel = featureFlags.FORMS && isChildPlaybook && false; // Hidden for now because it's WIP

  return (
    <div className="playbook-calendar px-2">
      <div className="d-flex align-items-center justify-content-between mt-4">
        {assignee && (
          <Link
            to={`/profile/${assignee.id}`}
            className="nav-link d-flex align-items-center gap-2 mb-3"
          >
            <img
              alt="Avatar"
              src={assignee.photoUrl}
              className="rounded-circle object-fit-cover"
              width="31"
              height="31"
            ></img>
            <h1 className="mt-1">
              {assignee.firstName?.length
                ? `${assignee.firstName} ${assignee.lastName ?? ''}`
                : assignee.email}
            </h1>
          </Link>
        )}
        <div className="ms-auto d-flex gap-2">
          <AddEventDropdown
            buttonLabel={'Create event'}
            onSelect={createEvent}
          />
          <Collaborators isParentJourney={!isChildPlaybook} />
          {canSeeActivityPanel && (
            <Button
              buttonStyle="primary"
              icon={<Activity />}
              text="Form Activity"
              onClick={() =>
                setStates((prevStates) => ({
                  ...prevStates,
                  showActivityPanel: true,
                }))
              }
            />
          )}
        </div>
      </div>

      <div className="mt-1 mb-3">
        <Filter
          items={filterItems}
          active={states.selectedFilter}
          onSelect={setSelectedFilter}
        />
      </div>

      {isEventsLoading ? (
        <Loader active={isEventsLoading} />
      ) : (
        <DraggableEvents
          events={filteredCalendarEvents}
          assignee={assignee}
          playbookStatus={playbook.status}
          isParentPlaybook={
            !playbook.parentJourneyID ||
            (playbook.type === BuilderType.SELFSERVE && !playbook.userStartedAt)
          }
          handleDragEnd={handleDragEnd}
          showDeletingLoader={states.showDeletingLoader}
          createEvent={createEvent}
          editEvent={editEvent}
          deleteEvent={deleteEvent}
          isEditingAllowed={true}
          getDateFromWeekDay={getDateFromWeekDay}
        />
      )}

      <CreateUpdateAction
        showModal={states.showCreateUpdateActionPanel}
        handleShowModal={(value: boolean) =>
          setStates((prevStates) => ({
            ...prevStates,
            showCreateUpdateActionPanel: value,
          }))
        }
        builderId={id ?? ''}
        refreshEvents={refreshCalendarEvents}
        actionEvent={states.selectedEvent}
        runbookAssignee={assignee}
        isParentJourney={!playbook.parentJourneyID}
        journeyStartDate={playbook.startDate ?? assignee?.startDate}
        isTemplate={false}
        playbookType={playbook.type}
        playbookStatus={playbook.status}
        isEditingAllowed={true}
      />

      <CreateUpdateFormEventPanel
        key={states.selectedEvent.id ?? Date.now()}
        showModal={states.showCreateUpdateFormEventPanel}
        handleShowModal={(value: boolean) =>
          setStates((prevStates) => ({
            ...prevStates,
            showCreateUpdateFormEventPanel: value,
          }))
        }
        builderId={id ?? ''}
        refreshEvents={refreshCalendarEvents}
        actionEvent={states.selectedEvent}
        runbookAssignee={assignee}
        isParentJourney={!playbook.parentJourneyID}
        journeyStartDate={playbook.startDate ?? assignee?.startDate}
        isTemplate={false}
        playbookType={playbook.type}
        playbookStatus={playbook.status}
        isEditingAllowed={true}
      />

      <AddMessageModal
        showModal={states.showCreateUpdateMessagePanel}
        handleShowModal={(value: boolean) =>
          setStates((prevStates) => ({
            ...prevStates,
            showCreateUpdateMessagePanel: value,
          }))
        }
        handleSave={refreshCalendarEvents}
        builderType={playbook.type}
        handleUpdate={refreshCalendarEvents}
        playbookStatus={playbook.status}
        builderId={id ?? ''}
        isParentJourney={!playbook.parentJourneyID}
        messageEvent={states.selectedEvent}
        assigneeEmails={
          assignee ? [assignee.email, assignee.personalEmail ?? ''] : []
        }
        onHideModal={() =>
          setStates((prevStates) => ({
            ...prevStates,
            showCreateUpdateMessagePanel: false,
          }))
        }
        assigneeHireDate={assignee?.startDate}
        journeyStartDate={playbook.startDate}
        isTemplate={false}
        assigneeId={assignee?.id}
        assigneeSlackId={assignee?.slackUserId?.toString()}
        isEditingAllowed={true}
      />

      {canSeeActivityPanel && (
        <ActivityPanel
          isOpen={states.showActivityPanel}
          onClose={() =>
            setStates((prevStates) => ({
              ...prevStates,
              showActivityPanel: false,
            }))
          }
          openCreateUpdateFormEventPanel={() =>
            setStates((prevStates) => ({
              ...prevStates,
              showActivityPanel: false,
              showCreateUpdateFormEventPanel: true,
            }))
          }
        />
      )}

      <InfoModal
        showModal={states.showTestMessagesConfirmationModal}
        handleShowModal={(value: boolean) =>
          setStates((prevStates) => ({
            ...prevStates,
            showTestMessagesConfirmationModal: false,
          }))
        }
        onClickConfirmButton={testEvents}
        title="Test messages and actions"
        description={
          <>
            All of the messages and actions in this runbook will be sent to you.
            {!user.Organization.slackAccessToken && (
              <WarningBanner
                message={
                  <span>
                    To send Slack messages, install the Kinfolk Slack app from
                    the{' '}
                    <Link to="/settings" className="alert-link">
                      Settings
                    </Link>
                    .
                  </span>
                }
              />
            )}
          </>
        }
      />
    </div>
  );
};
