import {
  type SortingState,
  getSortedRowModel,
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
} from '@tanstack/react-table';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { JourneyStatusPill } from '@Shared/Components/JourneyStatusPill/JourneyStatusPill';
import { ProgressBar } from '@Shared/Components/ProgressBar/ProgressBar';
import { BuilderType, type Journey } from '@base/API';
import { formateDate } from '@Shared/utils/utils';

export const useUserPlaybooksCard = (playbooks: Journey[]) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const navigate = useNavigate();

  const handleViewJourney = (rowId: number) => {
    const playbookId = playbooks[rowId].id;
    navigate(`/runbook/${playbookId}`);
  };

  const columnHelper = createColumnHelper<Journey>();

  const columns = [
    columnHelper.accessor((row: Journey) => row.name, {
      id: 'playbookName',
      cell: (info) => <div>{info.getValue()}</div>,
      sortingFn: (rowA: any, rowB: any, columnId: any) => {
        const urlRegex = /(https?:\/\/[^\s]+)/;
        const rowAUsername = rowA
          .getValue(columnId)
          .replace(urlRegex, '')
          .trim();
        const rowBUsername = rowB
          .getValue(columnId)
          .replace(urlRegex, '')
          .trim();
        return rowAUsername.toLowerCase() < rowBUsername.toLowerCase() ? -1 : 1;
      },
      header: () => <div className="fs-12-semi-bold-4C5564">Runbook</div>,
      size: 0,
      minSize: 0,
    }),
    columnHelper.accessor((row) => row, {
      id: 'startDate',
      cell: (info) => (
        <div>
          {info.getValue().type === BuilderType.INITIATIVE
            ? ((info.getValue().startDate &&
                //@ts-ignore
                formateDate(new Date(info.getValue().startDate))) ??
              '-')
            : ((info.getValue().userStartedAt &&
                //@ts-ignore
                formateDate(new Date(info.getValue().userStartedAt))) ??
              '-')}
        </div>
      ),
      header: () => <span className="fs-12-semi-bold-4C5564">Start date</span>,
      size: 0,
      minSize: 0,
    }),
    columnHelper.accessor((row) => row.journeyProgress, {
      id: 'progress',
      cell: (info) => (
        <ProgressBar
          bars={[{ percentage: info.getValue(), bg: 'bg-success' }]}
        />
      ),

      header: () => <span className="fs-12-semi-bold-4C5564">Progress</span>,
      size: 0,
      minSize: 0,
    }),
    columnHelper.accessor((row) => row.status, {
      id: 'status',
      cell: (info) => (
        <div className="d-flex align-items-center justify-content-center">
          <JourneyStatusPill status={info.getValue()} />
        </div>
      ),

      header: () => <span className="fs-12-semi-bold-4C5564 ms-4">Status</span>,
      size: 0,
      minSize: 0,
    }),
  ];

  const table = useReactTable({
    data: playbooks,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    autoResetPageIndex: true,
    autoResetAll: true,
  });

  return {
    table,
    columns,
    handleViewJourney,
  };
};
