import './IntegrationsTab.scss';
import useBreakpoint from 'use-breakpoint';
import { memo } from 'react';
import { BREAKPOINTS_DESKTOP_1280, openExternalUrl } from '@Shared/utils/utils';
import { Button } from '@Shared/DS';
import { IntegrationEditInfo } from '../IntegrationEditInfo/IntegrationEditInfo';
import { CalendarIntegrationCard } from '../Calendar/Calendar';
import { useIntegrationsTab } from './useIntegrationsTab';

export const IntegrationsTab = memo(() => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS_DESKTOP_1280, 'tablet');
  const { activeSubscription, organization } = useIntegrationsTab();

  return (
    <div className="integrations-tab-wrapper pt-2">
      <div className="py-4">
        <div
          className={
            breakpoint === 'mobile' ? 'row pe-2' : 'row align-items-start'
          }
        >
          {breakpoint === 'mobile' && (
            <hr className="rounded settings-line-break-spacing my-4 px-5"></hr>
          )}
          {organization !== undefined && (
            <IntegrationEditInfo organization={organization} />
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-lg-4 col-md-12 col-sm-12 text-start settings-list-styling">
          <div className="fs-20-semi-bold">Link your calendar</div>
          <p className="fs-16 pt-2">
            Automatically schedule meetings based on availability, timezones and
            working hours. Connect once for the whole company. To set this up,
            let us know.
          </p>
        </div>
        <div className="col-lg-8 col-md-12 col-sm-12 mt-md-5 mt-lg-0">
          <div className="settings-list-container">
            {!activeSubscription ? (
              <div className="p-4">
                <p className="fs-6">
                  Calendar integrations are available on the paid plan. Contact
                  us to upgrade.
                  <br />
                  <Button
                    buttonStyle="outline"
                    text="Book a call"
                    icon="bi-calendar-check"
                    additionalClasses="mt-2"
                    onClick={() =>
                      openExternalUrl('https://calendly.com/kinfolkhq/jeet')
                    }
                  />
                </p>
              </div>
            ) : (
              <CalendarIntegrationCard />
            )}
          </div>
        </div>
      </div>
    </div>
  );
});
