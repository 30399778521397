import type { ISvgIcon } from './types';

export const ExpandIcon = ({
  className,
  width = '14',
  height = '13',
}: ISvgIcon) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.9004 0.657303V6.5C13.9004 6.67433 13.8311 6.84152 13.7079 6.96478C13.5846 7.08805 13.4174 7.1573 13.2431 7.1573C13.0688 7.1573 12.9016 7.08805 12.7783 6.96478C12.655 6.84152 12.5858 6.67433 12.5858 6.5V2.2436L3.14399 11.6854H7.40039C7.57472 11.6854 7.74191 11.7546 7.86517 11.8779C7.98844 12.0012 8.05769 12.1684 8.05769 12.3427C8.05769 12.517 7.98844 12.6842 7.86517 12.8075C7.74191 12.9307 7.57472 13 7.40039 13H1.55769C1.47138 13 1.3859 12.983 1.30615 12.95C1.22641 12.9169 1.15395 12.8685 1.09291 12.8075C0.969642 12.6842 0.900391 12.517 0.900391 12.3427V6.5C0.900391 6.32567 0.969642 6.15848 1.09291 6.03522C1.21618 5.91195 1.38337 5.8427 1.55769 5.8427C1.73202 5.8427 1.89921 5.91195 2.02248 6.03522C2.14575 6.15848 2.215 6.32567 2.215 6.5V10.7564L11.6568 1.31461H7.40039C7.22606 1.31461 7.05888 1.24536 6.93561 1.12209C6.81234 0.998819 6.74309 0.831631 6.74309 0.657303C6.74309 0.482976 6.81234 0.315788 6.93561 0.19252C7.05888 0.0692514 7.22606 0 7.40039 0L13.2431 0C13.4174 0 13.5846 0.0692514 13.7079 0.19252C13.8311 0.315788 13.9004 0.482976 13.9004 0.657303Z"
      fill="#CF8828"
    />
  </svg>
);
