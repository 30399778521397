import { CustomDropdown } from '@Shared/DS';
import { type Form } from '@base/models/form.model';
import { useFormActionDropdown } from './useFormActionDropdown';

interface IFormActionDropdown {
  className?: string;
  form: Form;
}

export const FormActionDropdown = ({
  className,
  form,
}: IFormActionDropdown) => {
  const { editForm, previewForm } = useFormActionDropdown(form);

  const items = [
    { title: 'Edit', onClick: editForm },
    { title: 'Preview', onClick: previewForm },
  ];

  return (
    <div className={className} onClick={(event) => event.stopPropagation()}>
      <CustomDropdown items={items} />
    </div>
  );
};
