import { useDynamicTags } from '@base/Hooks/useDynamicTags';
import { useEffect, useRef, useState } from 'react';
import { FiAtSign } from 'react-icons/fi';

interface QuillToolbarProps {
  id: string;
  isEditable: boolean;
  isMessageTextEditor: boolean;
  allowImageUpload?: boolean;
  insertText: (text: string) => void;
}

export const QuillToolbar = (props: QuillToolbarProps) => {
  const { id, isEditable, isMessageTextEditor, allowImageUpload, insertText } =
    props;
  const [showMenu, setShowMenu] = useState(false);
  const mentionRef = useRef<any>();
  const { dynamicTags } = useDynamicTags();

  const handleOptionClick = (value: string) => {
    const dynamicVariable = extractDynamicVariable(value);
    if (!dynamicVariable) {
      return;
    }

    insertText(dynamicVariable);
    setShowMenu(false);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        showMenu &&
        mentionRef.current &&
        !mentionRef.current.contains(e.target)
      ) {
        setShowMenu(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [showMenu]);

  const extractDynamicVariable = (str: string): string | null => {
    const regex = /({{(.*?)}})/;
    const match = str.match(regex);
    return match ? match[1] : null;
  };

  const renderWithHighlight = (text: string) => {
    const regex = /({{[^}]+}})/g;
    const parts = text.split(regex);

    return parts.map((part, index) => {
      if (regex.test(part)) {
        return (
          <span key={index} style={{ color: '#5d51f6' }}>
            {part}
          </span>
        );
      }
      return part;
    });
  };

  return (
    <div id={id}>
      {isMessageTextEditor ? (
        <span className="ql-formats">
          <button className="ql-bold" />
          <button className="ql-italic" />
          <button className="ql-list" value="ordered" />
          <button className="ql-list" value="bullet" />
          <button className="ql-link" />
          {allowImageUpload && <button className="ql-image" />}
          <button className="ql-code-block" />
          <span className="mention-container">
            <button className="ql-mention" onClick={() => setShowMenu(true)}>
              <FiAtSign />
            </button>

            {showMenu && isEditable && (
              <div className="custom-menu" ref={mentionRef}>
                {dynamicTags?.map((item) => (
                  <div key={item} onClick={() => handleOptionClick(item)}>
                    {renderWithHighlight(item)}
                  </div>
                ))}
              </div>
            )}
          </span>
          <button className="ql-clean" />
        </span>
      ) : (
        <span className="ql-formats">
          <button className="ql-bold" />
          <button className="ql-italic" />
          <button className="ql-underline" />
          <button className="ql-strike" />
          <button className="ql-list" value="ordered" />
          <button className="ql-list" value="bullet" />
          <button className="ql-link" />
          <button className="ql-image" />
          <button className="ql-code-block" />
          <select className="ql-align" />
          <select className="ql-color" />
          <select className="ql-background" />
          <select className="ql-header" defaultValue="3">
            <option value="1">Heading</option>
            <option value="2">Subheading</option>
            <option value="3">Normal</option>
          </select>
          <span className="mention-container">
            <button className="ql-mention" onClick={() => setShowMenu(true)}>
              <FiAtSign className="at-icon" />
            </button>

            {showMenu && isEditable && (
              <div className="custom-menu" ref={mentionRef}>
                {dynamicTags?.map((item) => (
                  <div key={item} onClick={() => handleOptionClick(item)}>
                    {renderWithHighlight(item)}
                  </div>
                ))}
              </div>
            )}
          </span>
          <button className="ql-clean" />
        </span>
      )}
    </div>
  );
};

export default QuillToolbar;
