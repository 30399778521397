import { FloatingLabel, Form } from 'react-bootstrap';
import './FloatingLabelDropdown.scss';

interface IFloatingLabelDropdown {
  label: string;
  options: string[];
  disabled?: boolean;
  className?: string;
  errorMessage?: string;
  selected: string;
  setSelected(selected: string): void;
}
export const FloatingLabelDropdown = (props: IFloatingLabelDropdown) => {
  const {
    label,
    options,
    disabled,
    className,
    errorMessage,
    selected,
    setSelected,
  } = props;

  return (
    <FloatingLabel controlId="floatingSelectGrid" label={label}>
      <Form.Select
        value={selected}
        className={className ?? ''}
        disabled={disabled}
        aria-label="message recipient dropdown"
        onChange={(value) => setSelected(value.target.value)}
      >
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </Form.Select>
      {className?.includes('is-invalid') && (
        <div className="invalid-feedback text-start">{errorMessage}</div>
      )}
    </FloatingLabel>
  );
};
