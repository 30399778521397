import { useEffect, useState } from 'react';
import { type EmbeddedContentBlock } from '@API';
import {
  deleteBlock,
  updateEmbeddedContentBlock,
  updateEmbeddedContentBlockLink,
} from '@api/apis';
import { useNonInitialEffect } from '@Hooks/useNonInitialEffect';

export const useEmbeddedContent = (
  block: EmbeddedContentBlock,
  handleDeleteBlock: Function,
  setShowSaved: Function,
  setShowLoader: Function,
  handleUpdateMilestoneBlocks: Function,
  isParentPlaybook: boolean,
  setIsSaving?: Function,
) => {
  const [contentUrl, setContentUrl] = useState(block.resourceLink);
  const [title, setTitle] = useState(block.title);
  const [description, setDescription] = useState(block.description ?? '');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showVideoUrlModal, setShowVideoUrlModal] = useState(false);
  const [iframeLoaded, setIframeLoaded] = useState(false);

  const updateBlock = async () => {
    if (block.title !== title || block.description !== description) {
      if (handleUpdateMilestoneBlocks) {
        handleUpdateMilestoneBlocks({
          ...block,
          title: title,
          description: description,
        });
        setShowLoader(true);
        await updateEmbeddedContentBlock({
          ...block,
          title: title,
          description: description,
          updatedInChild: !isParentPlaybook,
        });
        setShowLoader(false);
        setShowSaved(true);
      }
    }
  };

  const removeBlock = async () => {
    let id;

    if (isParentPlaybook) {
      id = await deleteBlock(block.id, block.type);
    } else {
      id = await updateEmbeddedContentBlock({
        ...block,
        isArchived: true,
      });
    }

    if (id) {
      handleDeleteBlock(block.id, block.type, block.orderNo);
      setShowConfirmationModal(false);
    }
  };

  const updateEmbeddedContentBlockUrl = async (updatedUrl: string) => {
    if (updatedUrl) {
      const embeddedContentBlockId = await updateEmbeddedContentBlockLink(
        block.id,
        updatedUrl,
      );
      if (embeddedContentBlockId) {
        let url;
        if (updatedUrl.includes('watch')) {
          url = updatedUrl.replace('watch?v=', 'embed/');
          setContentUrl(url);
        } else {
          url = updatedUrl;
          setContentUrl(updatedUrl);
        }

        handleUpdateMilestoneBlocks({ ...block, resourceLink: url });
        setShowVideoUrlModal(false);
      }
    }
  };

  useEffect(() => {
    if (block.resourceLink.includes('/watch?v=')) {
      const embeddedUrl = block.resourceLink.replace('watch?v=', 'embed/');
      setContentUrl(embeddedUrl);
    }

    setTitle(block.title);
    setDescription(block.description ?? '');
  }, [block]);

  useNonInitialEffect(() => {
    if (block.title !== title || block.description !== description) {
      setIsSaving?.(true);
    } else {
      setIsSaving?.(false);
    }
    const timeOut = setTimeout(() => {
      updateBlock();
    }, 1000);

    return () => clearTimeout(timeOut);
  }, [title, description]);

  return {
    title,
    description,
    contentUrl,
    showVideoUrlModal,
    setShowVideoUrlModal,
    showConfirmationModal,
    setShowConfirmationModal,
    setTitle,
    setDescription,
    removeBlock,
    updateEmbeddedContentBlockUrl,
    iframeLoaded,
    setIframeLoaded,
  };
};
