import { getUserBasicData, listChildJourneys } from '@api/apis';
import { Keys } from '@base/keys/queryKeys';
import { type IJourneyPeople } from '@base/models/journeyAssignees.model';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

export const usePlaybookParticipants = () => {
  const { id } = useParams();
  const [states, setStates] = useState({ showPlaybookAssignModal: false });

  const { data: childPlaybooks, isLoading } = useQuery({
    queryKey: Keys.getChildPlaybooks(id),
    queryFn: async () => {
      if (!id) {
        return [];
      }

      const playbooks = await listChildJourneys(id);
      if (!playbooks) {
        return [];
      }

      return await Promise.all(
        playbooks.map(async (playbook) => {
          if (!playbook.assignedUserID) {
            return { ...playbook } as IJourneyPeople;
          }

          const user = await getUserBasicData(playbook.assignedUserID);

          if (!user) {
            return { ...playbook } as IJourneyPeople;
          }

          return {
            ...playbook,
            assigneeProfilePhoto: user.photoUrl,
            assigneeName:
              user.firstName && user.firstName !== ''
                ? `${user.firstName} ${user.lastName ?? ''}`
                : user.email,
            assigneeStartDate: user?.startDate,
          } as IJourneyPeople;
        }),
      );
    },
    enabled: !!id,
  });

  return {
    childPlaybooks,
    isLoading,
    states,
    setStates,
  };
};
