import { type ReactNode } from 'react';
import { FiPlus } from 'react-icons/fi';
import { Dropdown } from 'react-bootstrap';
import './AddEventDropdownToggle.scss';

interface AddEventDropdownToggleProps {
  label: ReactNode;
}

export const AddEventDropdownToggle = ({
  label,
}: AddEventDropdownToggleProps) => (
  <Dropdown.Toggle className="add-event-dropdown-toggle btn-button btn-button-outline">
    <FiPlus className="add-event-toggle-plus-icon" />
    {label}
  </Dropdown.Toggle>
);
