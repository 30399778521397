import { EventType } from '@base/models/common.model';

export const dropdownItems = [
  {
    label: 'New assignee message',
    eventKey: EventType.MESSAGE,
  },
  {
    label: 'New collaborator action',
    eventKey: EventType.ACTION,
  },
  {
    label: 'Send a form',
    eventKey: EventType.FORM,
  },
];
