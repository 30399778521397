import { Row, Col, Card } from 'react-bootstrap';
import './Interests.scss';
import { type User } from '@API';

const Interests = (props: { user: User | undefined }) => {
  const { user } = props;

  return (
    <div className="interests-wrapper">
      <Card>
        <Card.Body>
          <Row>
            <Col>
              <h4 className="fs-20-semi-bold-4C5564">Ask me about</h4>
            </Col>
          </Row>
          {user?.Interests?.items?.map((interest: any, i: number) => (
            <div key={i} className="pill-button">
              {interest.interest !== undefined
                ? interest.interest.name
                : interest.name}
            </div>
          ))}
        </Card.Body>
      </Card>
    </div>
  );
};

export default Interests;
