import { Row, Container, Col } from 'react-bootstrap';
import './UserProfile.scss';
import { UserBadges, UserPlaybooksCard } from '@Shared/DS';
import Loader from '@Shared/Components/Loader/Loader';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { UserType } from '@base/API';
import WorkStyle from './Components/WorkStyle/WorkStyle';
import About from './Components/About/About';
import Happy from './Components/Happy/Happy';
import Interests from './Components/Interests/Interests';
import LearnMore from './Components/LearnMore/LearnMore';
import PersonalInfo from './Components/PersonalInfo/PersonalInfo';
import WorkPreferences from './Components/WorkPreferences/WorkPreferences';
import { useUserProfile } from './useUserProfile';

const UserProfile = () => {
  const {
    isLoading,
    user,
    playbooks,
    badges,
    isAboutPresent,
    isBadgesExists,
    isHappyPresent,
    isWorkPreferencesPresent,
    isWorkStylePresent,
    isLearnMorePresent,
    isInterestsPresent,
  } = useUserProfile();
  const { user: currentUser } = useUserContext();

  return (
    <div className="profile-page-wrapper d-flex">
      <Container fluid className="p-0">
        <Row className="no-pad">
          <div className="col-md-12 col-lg-12">
            <Loader active={isLoading} styling="absolute">
              {!isLoading && (
                <>
                  <div className="profile-page-container d-md-block px-3 pt-4">
                    <div className="container">
                      <Row>
                        <Col md="12" xs="12">
                          <PersonalInfo user={user} />
                        </Col>
                      </Row>
                      {isAboutPresent() && (
                        <Row>
                          <Col md="12">
                            <About user={user} />
                          </Col>
                        </Row>
                      )}

                      {(currentUser.type === UserType.COMPANY_ADMIN ||
                        currentUser.type === UserType.SUPER_ADMIN) && (
                        <UserPlaybooksCard playbooks={playbooks} />
                      )}

                      {isBadgesExists() && (
                        <Row>
                          <Col md="12">
                            <UserBadges badges={badges ?? []} />
                          </Col>
                        </Row>
                      )}

                      {isInterestsPresent() && (
                        <Row>
                          <Col md="12">
                            <Interests user={user} />
                          </Col>
                        </Row>
                      )}
                      {isLearnMorePresent() && (
                        <Row>
                          <Col md="12">
                            <LearnMore user={user} />
                          </Col>
                        </Row>
                      )}
                      {isHappyPresent() && (
                        <Row>
                          <Col md="12">
                            <Happy user={user} />
                          </Col>
                        </Row>
                      )}
                      {isWorkPreferencesPresent() && (
                        <Row>
                          <Col md="12">
                            <WorkPreferences user={user} />
                          </Col>
                        </Row>
                      )}
                      {isWorkStylePresent() && (
                        <Row>
                          <Col md="12">
                            <WorkStyle user={user} />
                          </Col>
                        </Row>
                      )}
                    </div>
                  </div>
                </>
              )}
            </Loader>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default UserProfile;
