import React, { useCallback } from 'react';
import { Container } from 'react-bootstrap';
import { PlaybookCard } from '@Pages/Playbooks/Components/PlaybookCard/PlaybookCard';
import { TemplateCard } from '@Shared/DS/Molecules/TemplateCard';
import Loader from '@Shared/Components/Loader/Loader';
import { Button } from '@Shared/DS';
import { usePlaybooksPage } from '../Playbooks/usePlaybooksPage';
import { CreatePlaybookModal } from './Components/CreatePlaybookModal/CreatePlaybookModal';

export const Playbooks = () => {
  const {
    journeys,
    isLoading,
    kinfolkTemplates,
    showCreatePlaybookModal,
    setShowCreatePlaybookModal,
    handleViewPlaybook,
    handleArchiveJourney,
    handleCreateJourney,
    handleUpdateJourneys,
  } = usePlaybooksPage();

  return (
    <div className="playbooks-page-wrapper d-flex">
      <Container fluid className="p-5">
        {isLoading ? (
          <Loader active={isLoading} />
        ) : (
          <div className="row no-pad">
            <div className="col-md-12 col-lg-12">
              <div className="d-flex justify-content-between mb-4">
                <div className="fs-30-semi-bold-25272A">Runbooks</div>
                <Button
                  text="Create new runbook"
                  icon="bi bi-brush"
                  tooltipPlacement="bottom"
                  onClick={handleCreateJourney}
                ></Button>
              </div>
              {!journeys.length ? (
                <p className="fs-15-25272A mt-3">
                  It's empty here. Create your first runbook.
                </p>
              ) : (
                <>
                  <div className="row row-cols-1 row-cols-xs-2 row-cols-sm-2 row-cols-md-3">
                    {journeys.map((journey) => (
                      <React.Fragment key={journey.id}>
                        <div className="col">
                          <PlaybookCard
                            journey={journey}
                            handleArchiveJourney={handleArchiveJourney}
                            handleViewPlaybook={handleViewPlaybook}
                            journeyStartDate={journey.startDate}
                            handleUpdateJourneys={handleUpdateJourneys}
                            backgroundImage={''}
                          />
                        </div>
                      </React.Fragment>
                    ))}
                  </div>
                </>
              )}

              <div className="row">
                {kinfolkTemplates.length ? (
                  <div className="fs-30-semi-bold-25272A mt-3 mb-2">
                    Kinfolk templates
                  </div>
                ) : null}
                {kinfolkTemplates.map((template) => (
                  <TemplateCard
                    key={template.id}
                    template={template}
                    isJourneyLibrary={true}
                  />
                ))}
              </div>
            </div>
          </div>
        )}
      </Container>

      <CreatePlaybookModal
        showModal={showCreatePlaybookModal}
        handleShowModal={useCallback(
          (value: boolean) => setShowCreatePlaybookModal(value),
          [setShowCreatePlaybookModal],
        )}
      />
    </div>
  );
};
