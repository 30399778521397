import { type FormEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { addNotificationToApp } from '@Shared/utils/utils';
import { signIn, signInWithRedirect } from '@aws-amplify/auth';
import { useUserContext } from '@base/Context/UserContext/UserContext';

export const useLogin = () => {
  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const navigate = useNavigate();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const { getProfileData } = useUserContext();

  const handleGoogleLogin = async () => {
    await signInWithRedirect({ provider: 'Google' });
    localStorage.setItem('isLoggedIn', 'true');
  };

  const handleFacebookLogin = () => {
    signInWithRedirect({ provider: 'Facebook' });
  };

  const handleLoginWithEmailAndPassword = async (
    e: FormEvent<HTMLFormElement>,
  ) => {
    e.preventDefault();
    try {
      setIsSubmitted(true);
      if (userEmail !== '' && userPassword !== '') {
        setIsLoading(true);
        const { isSignedIn, nextStep } = await signIn({
          username: userEmail,
          password: userPassword,
        });
        if (isSignedIn) {
          // save log in state to local storage
          localStorage.setItem('isLoggedIn', 'true');
          await getProfileData();
        }

        switch (nextStep.signInStep) {
          case 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED':
            setShowResetPasswordModal(true);
            break;
          case 'CONFIRM_SIGN_UP':
            navigate('/verify-email', {
              state: { isForgotPassword: false, email: userEmail },
            });
            break;
        }

        setIsLoading(false);
      }
    } catch (e: any) {
      if (e.message === 'User does not exist.') {
        addNotificationToApp(
          'The username entered does not match our records. Please double-check for any typos.',
          'error',
        );
        setIsLoading(false);
        return;
      }
      setIsLoading(false);
      addNotificationToApp(e.message, 'error');
    }
  };

  const handleForgotPassword = () => {
    navigate('/verify-email', { state: { isForgotPassword: true, email: '' } });
  };

  return {
    userEmail,
    userPassword,
    isSubmitted,
    isLoading,
    showResetPasswordModal,
    setShowResetPasswordModal,
    setUserEmail,
    setUserPassword,
    handleForgotPassword,
    handleGoogleLogin,
    handleFacebookLogin,
    handleLoginWithEmailAndPassword,
  };
};
