import { Button, EmptyState } from '@Shared/DS';

interface NoFormEventsProps {
  openCreateUpdateFormEventPanel: () => void;
}

export const NoFormEvents = ({
  openCreateUpdateFormEventPanel,
}: NoFormEventsProps) => (
  <EmptyState
    button={
      <Button
        buttonStyle="primary"
        onClick={openCreateUpdateFormEventPanel}
        text="Send a form"
      />
    }
    description={
      <>
        <p>No forms & Automations are currently scheduled in this runbook.</p>
        <p>Get started by sending a form as a runbook event.</p>
      </>
    }
    title="No runbook activity yet!"
  />
);
