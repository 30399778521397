import { TextField } from '@Shared/DS/Molecules';
import './AccountVerification.scss';
import { Button } from '@Shared/DS';
import { useAccountVerification } from './useAccountVerification';

export const AccountVerification = () => {
  const {
    currentUserEmail,
    verificationCode,
    isSubmitted,
    isLoading,
    setCurrentUserEmail,
    setVerificationCode,
    resendVerificationCode,
    handleEmailVerification,
  } = useAccountVerification();

  return (
    <div className="account-verification-wrapper text-center">
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col-md-auto">
            {' '}
            <div className="text-center">
              <img
                className="logo"
                src={require('../../Assets/Images/kinfolk_logo.png')}
                alt="Kinfolk logo"
              />
              <div className="mt-4 fs-24-25272a">Verify your account</div>
            </div>
            <div className="">
              <div className="mt-3">
                We've sent a verification code to{' '}
                <strong>{currentUserEmail}</strong>, enter the code below to log
                in.
              </div>
              <form
                id="account-verification-form"
                onSubmit={(e) => handleEmailVerification(e)}
              >
                <label
                  className="fs-14-semi-bold col-12 text-start mt-4"
                  htmlFor="email"
                >
                  Email address
                </label>
                <TextField
                  id={'email'}
                  type="email"
                  defaultValue={currentUserEmail}
                  setValue={setCurrentUserEmail}
                  fieldName="Email"
                  width=""
                  disabled
                  placeholder="Enter your work email address"
                  additionalClasses={`
            ${!currentUserEmail && currentUserEmail.length === 0 && isSubmitted ? 'is-invalid' : ''}`}
                />
                <label
                  className="fs-14-semi-bold col-12 text-start mt-4"
                  htmlFor="verification"
                >
                  Verification code
                </label>
                <TextField
                  id={'verification'}
                  type="text"
                  defaultValue={verificationCode}
                  setValue={setVerificationCode}
                  fieldName="Verification code"
                  width=""
                  placeholder="Enter your verification code"
                  additionalClasses={`
            ${!verificationCode && verificationCode.length === 0 && isSubmitted ? 'is-invalid' : ''}`}
                />
              </form>
            </div>
            <Button
              text="Continue"
              type="submit"
              additionalClasses="mt-4"
              disabled={isLoading}
              showLoading={isLoading}
              form="account-verification-form"
            />
            <div className="mt-3 resend-email">
              Didn't receive an email?{' '}
              <span onClick={resendVerificationCode}>Resend email</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
