import { useRef } from 'react';

export const useEditPersonalInfo = () => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (hiddenFileInput.current !== null) {
      hiddenFileInput.current.click();
    }
  };

  return {
    hiddenFileInput,
    handleClick,
  };
};
