import EditPersonalInfo from '@Shared/Components/EditPersonalInfo/EditPersonalInfo';
import './FirstOnboardingPage.scss';
import OnboardingButton from '@Shared/Components/OnboardingButton/OnboardingButton';
import { useEditPersonalInfoModal } from '@Hooks/useEditPersonalInfoModal';

export default function FirstOnboardingPage(props: any) {
  const {
    firstName,
    lastName,
    email,
    workLocation,
    isSubmitted,
    jobTitle,
    team,
    profileImageUrl,
    isUploading,
    setFirstName,
    setLastName,
    setEmail,
    setWorkLocation,
    setJobTitle,
    setTeam,
    setProfileImageUrl,
    fileSelectedHandler,
    handleSaveModal,
  } = useEditPersonalInfoModal(
    props.handleClick,
    true,
    props.handleUpdateUserPhoto,
  );

  return (
    <div className="first-onboarding-page-wrapper mx-4 pb-5 row justify-content-center">
      <div className="col-md-12 col-lg-12 d-flex justify-content-center mt-4">
        <h1>Hey{firstName ? ` ${firstName}` : ''}!</h1>
      </div>
      <div className="fs-24-medium-bold-25272a col-12 col-lg-8 d-flex justify-content-center text-cente mb-4">
        Let's get you set up.
      </div>
      <EditPersonalInfo
        photoUrl={profileImageUrl}
        setPhotoUrl={setProfileImageUrl}
        fileSelectedHandler={fileSelectedHandler}
        firstName={firstName}
        setFirstName={setFirstName}
        lastName={lastName}
        setLastName={setLastName}
        workLocation={workLocation}
        setWorkLocation={setWorkLocation}
        jobTitle={jobTitle}
        setJobTitle={setJobTitle}
        email={email}
        setEmail={setEmail}
        team={team}
        setTeam={setTeam}
        isSubmitted={isSubmitted}
        isOnboarding={true}
      />
      <div className="my-3 text-center">
        <p className="fs-15">
          You can check out your profile later for work styles, interests and
          more.
        </p>
      </div>
      <div className="button-padding col-12 d-flex justify-content-center">
        <OnboardingButton
          icon={true}
          text={isUploading ? 'Uploading...' : 'Next'}
          styling="px-4 py-2"
          theme="view-profile-button-wrapper"
          handleClick={handleSaveModal}
        />
      </div>
    </div>
  );
}
