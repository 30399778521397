import { type TextFieldProps } from '@Shared/utils/utils';
import './TextField.scss';

export const TextField = (props: TextFieldProps) => (
  <>
    <input
      id={props.id}
      name={props.name}
      type={props.type}
      onBlur={props.onBlur}
      required={props.required}
      min={props.id === 'meetingDuration' ? '1' : ''}
      onChange={(e) => (props.setValue ? props.setValue(e.target.value) : null)}
      className={`form-control text-field ${props.additionalClasses}`}
      style={{ width: `${props.width || ''}` }}
      placeholder={props.placeholder}
      value={props.defaultValue}
      disabled={props.disabled}
    />
    {props.additionalClasses?.includes('is-invalid') && (
      <div className="invalid-feedback text-start">
        {`${props.fieldName || ''} ${props.errorMessage || "shouldn't be empty"}`}{' '}
      </div>
    )}
  </>
);
