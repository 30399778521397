import { useEffect, useState } from 'react';
import { type DropResult } from 'react-beautiful-dnd';
import { addNotificationToApp, scrollToTop } from '@Shared/utils/utils';
import { type MilestoneProps } from '@base/models/journeyLibrary.model';

export const useMilestones = (params: MilestoneProps) => {
  const {
    setSelectedMilestoneIndex,
    handleReorderMilestones,
    removeMilestone,
    milestones,
  } = params;
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [milestoneNames, setMilestoneNames] = useState<string[]>([]);

  const handleDeleteMilestone = () => {
    removeMilestone();
    setShowConfirmationModal(false);
  };

  const handleShowConfirmationModal = () => {
    if (milestones.length > 1) {
      setShowConfirmationModal(true);
    } else {
      addNotificationToApp('Sorry, last milestone is not deletable', 'info');
    }
  };

  const handleActiveMilestone = (activeMilestoneIndex: number) => {
    scrollToTop();
    setSelectedMilestoneIndex(activeMilestoneIndex);
  };

  const onDragEnd = (result: DropResult) => {
    if (
      result.destination &&
      result.source.index !== result.destination.index
    ) {
      handleReorderMilestones(
        result.source.index,
        result.destination.index,
        milestones[result.source.index],
      );
      setSelectedMilestoneIndex(result.destination.index);
    }
  };

  const handleMilestoneName = (name: string, index: number) => {
    if (milestoneNames) {
      milestoneNames[index] = name;
      setMilestoneNames([...milestoneNames]);
    }
  };

  useEffect(() => {
    setMilestoneNames(() => milestones.map((milestone) => milestone.name));
  }, [milestones]);

  return {
    milestoneNames,
    showConfirmationModal,
    setShowConfirmationModal,
    handleDeleteMilestone,
    handleActiveMilestone,
    handleShowConfirmationModal,
    onDragEnd,
    handleMilestoneName,
  };
};
