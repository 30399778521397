import {
  useState,
  useCallback,
  useEffect,
  useRef,
  type ReactNode,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { HamburgerIcon, ExpandIcon } from '../../Atoms/Icons';
import { FloatingButton } from '../../Atoms/FloatingButton';

import './FloatingMenu.scss';

interface MenuItem {
  label: string;
  isSelected?: boolean;
  url?: string;
}

interface IFloatingMenu {
  title: ReactNode;
  ctaAction?: {
    label: string;
    onClick: () => void;
  };
  items?: MenuItem[];
  footerComponent?: ReactNode;
  fullScreen?: boolean;
  onSelect?: (item: MenuItem, index: number) => void;
}

export const FloatingMenu = ({
  fullScreen,
  title,
  ctaAction,
  items,
  onSelect,
  footerComponent,
}: IFloatingMenu) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const floatingMenuRef = useRef<HTMLInputElement>(null);

  const toggleMenu = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const onSelectHandle = useCallback(
    (item: MenuItem, index: number) => {
      if (item.url) {
        navigate(item.url);
      } else {
        onSelect?.(item, index);
      }
    },
    [onSelect, navigate],
  );
  useEffect(() => {
    function handleClickOutside(event: { target: any }) {
      if (
        floatingMenuRef.current &&
        !floatingMenuRef.current.contains(event.target)
      ) {
        setIsOpen(false); // Close the menu if click is outside
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [floatingMenuRef]); // Ensure the effect runs only once or when menuRef changes

  return (
    <div className="nav-floating-menu">
      <FloatingButton
        onClick={toggleMenu}
        position="middleLeft"
        icon={<HamburgerIcon />}
      />

      <div
        ref={floatingMenuRef}
        className={`floating-menu ${fullScreen ? 'full-screen' : ''} ${isOpen ? 'open' : ''}`}
      >
        <div className="floating-menu__header">
          <div className="floating-menu__header_title">{title}</div>
          <button
            onClick={toggleMenu}
            className="floating-menu__header_expand_icon"
          >
            <ExpandIcon />
          </button>
        </div>
        <div className="floating-menu__content">
          {items?.map((item, index) => (
            <div
              key={index}
              onClick={() => onSelectHandle(item, index)}
              className={`floating-menu__content_item ${item.isSelected ? 'selected' : ''}`}
            >
              {item.label}
            </div>
          ))}
        </div>

        {footerComponent}

        {ctaAction && (
          <button onClick={ctaAction.onClick} className="floating-menu__cta">
            {ctaAction.label}
          </button>
        )}
      </div>
    </div>
  );
};
