import { Col, Modal, Row } from 'react-bootstrap';
import { Button } from '../../DS/Atoms/Button';

interface EditTemplateModalProps {
  title: string;
  description: string;
  showModal: boolean;
  handleShowModal: Function;
  handleProceed: Function;
  showLoader: boolean;
  secondaryButtonText?: string;
  primaryButtonText?: string;
}
export const EditAlertModal = (props: EditTemplateModalProps) => (
  <Modal
    size="lg"
    fullscreen={'md-down'}
    aria-labelledby="contained-modal-title-vcenter"
    centered
    backdrop="static"
    show={props.showModal}
    onHide={() => props.handleShowModal(false)}
  >
    <Modal.Header>
      <Modal.Title className="fs-24-semi-bold-4C5564">
        {props.title}
      </Modal.Title>
      <button
        type="button"
        className="btn-close modal-close-btn"
        data-bs-dismiss="modal"
        onClick={() => props.handleShowModal(false)}
        aria-label="Close"
      ></button>
    </Modal.Header>
    <Modal.Body>
      <Row>
        <Col>
          <div className="fs-15">{props.description}</div>
        </Col>
      </Row>
    </Modal.Body>
    <Modal.Footer>
      <div className="d-flex justify-content-end">
        <Button
          buttonStyle="outline"
          text={props.secondaryButtonText ?? 'Cancel'}
          onClick={() => props.handleShowModal(false)}
        />
        <Button
          additionalClasses="ms-2"
          text={props.primaryButtonText ?? 'Proceed'}
          disabled={props.showLoader}
          showLoading={props.showLoader}
          onClick={() => props.handleProceed()}
        />
      </div>
    </Modal.Footer>
  </Modal>
);
