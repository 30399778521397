import { type Organization } from '@API';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { memo } from 'react';
import { Button } from '@Shared/DS';
import { openExternalUrl } from '@Shared/utils/utils';
import { useIntegrationEditInfo } from './useIntegrationEditInfo';

export const IntegrationEditInfo = memo(
  (props: { organization: Organization }) => {
    const {
      slackConnected,
      msTeamsConnected,
      handleRemoveSlack,
      handleRemoveMsTeams,
    } = useIntegrationEditInfo(props.organization);
    const { user } = useUserContext();

    const msTeamsConsentUrl = `https://login.microsoftonline.com/organizations/adminconsent?client_id=${process.env.REACT_APP_MICROSOFT_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_MICROSOFT_TEAMS_OAUTH_URL}&scope=openid%20profile%20email%20https://graph.microsoft.com/TeamsAppInstallation.ReadWriteSelfForTeam.All%20https://graph.microsoft.com/TeamsAppInstallation.ReadWriteSelfForUser.All&state=${user.userOrganizationId}`;
    const slackReferenceUrl = `${process.env.REACT_APP_SLACK_APP_DISTRIBUTE_LINK}&state=${user.userOrganizationId}`;

    return (
      <>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12 text-start settings-list-styling">
            <div className="fs-20-semi-bold">Slack</div>
            <p className="fs-16 pt-2">
              Send messages and tasks that can be ticked off in Slack.
            </p>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-8">
            <div className="settings-list-container p-4 d-flex flex-row">
              <div className={'col-' + (msTeamsConnected ? '12' : '9')}>
                <div className="d-flex">
                  <img
                    alt="latest update card"
                    src={require('../../../../Assets/Images/slack.png')}
                    className="rounded-circle settings-card-image d-inline"
                  ></img>
                  <p className="update-description px-3">
                    <span className="fs-16-semi-bold">Slack</span>
                  </p>
                  {msTeamsConnected && (
                    <>
                      <p className="update-description px-4">
                        Only one messenger bot can be installed at the same
                        time.
                      </p>
                    </>
                  )}
                </div>
              </div>
              <div className="col-sm-3 col-md-3 justify-content-end">
                {slackConnected ? (
                  <Button
                    text={'Remove Slack'}
                    buttonStyle="outline"
                    onClick={handleRemoveSlack}
                  />
                ) : (
                  <>
                    {!msTeamsConnected && (
                      <a href={slackReferenceUrl} rel="noopener noreferrer">
                        <Button text={'Add Slack'} buttonStyle="outline" />
                      </a>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Teams Section */}
        <div className="row mt-4">
          <div className="col-lg-4 col-md-4 col-sm-12 text-start settings-list-styling">
            <div className="fs-20-semi-bold">Microsoft Teams</div>
            <p className="fs-16 pt-2">
              Send messages and tasks that can be ticked off in Teams.
            </p>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-8">
            <div className="settings-list-container p-4 d-flex flex-row">
              <div className={'col-' + (slackConnected ? '12' : '9')}>
                <div className="d-flex">
                  <img
                    alt="latest update card"
                    src={require('../../../../Assets/Images/teams-logo.png')}
                    className="settings-card-image d-inline"
                  ></img>
                  <p className="update-description px-3">
                    <span className="fs-16-semi-bold">Teams</span>
                    <br />
                    <span className="fs-12">Microsoft</span>
                  </p>
                  {slackConnected && (
                    <>
                      <p className="update-description px-4">
                        Only one messenger bot can be installed at the same
                        time.
                      </p>
                    </>
                  )}
                </div>
              </div>
              <div className="col-sm-3 col-md-3 justify-content-end">
                {msTeamsConnected ? (
                  <Button
                    text={'Remove MS Teams'}
                    buttonStyle="outline"
                    onClick={handleRemoveMsTeams}
                  />
                ) : (
                  <>
                    {!slackConnected && (
                      <a href={msTeamsConsentUrl} rel="noopener noreferrer">
                        <Button text={'Add Teams'} buttonStyle="outline" />
                      </a>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-lg-4 col-md-4 col-sm-12 text-start settings-list-styling">
            <div className="fs-20-semi-bold">HR Systems</div>
            <p className="fs-16 pt-2">
              Update Kinfolk users based on HRIS data. Trigger runbooks to
              candidates based on ATS stage.
            </p>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-8">
            <div className="settings-list-container p-4 d-flex">
              {!user.Organization.hasSubscription ? (
                <p className="fs-6">
                  HR & ATS integrations are available on the paid plan. Contact
                  us to upgrade.
                  <br />
                  <Button
                    buttonStyle="outline"
                    text="Book a call"
                    icon="bi-calendar-check"
                    additionalClasses="mt-2"
                    onClick={() =>
                      openExternalUrl('https://calendly.com/kinfolkhq/jeet')
                    }
                  />
                </p>
              ) : (
                <>
                  <div className="col-sm-9 col-md-9 d-flex">
                    <img
                      alt="latest update card"
                      src={require('../../../../Assets/Images/hrintegration.png')}
                      className="rounded-circle settings-card-image"
                    ></img>
                    <p className="update-description px-3 d-inline">
                      <span className="fs-16-semi-bold">HR System</span>
                    </p>
                  </div>
                  <div className="col-sm-3 col-md-3">
                    <Button
                      buttonStyle="outline"
                      text="Contact us"
                      icon="bi-calendar-check"
                      additionalClasses="mt-2"
                      onClick={() =>
                        openExternalUrl('https://calendly.com/kinfolkhq/jeet')
                      }
                    />
                  </div>{' '}
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  },
);
