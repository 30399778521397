import { type EmbeddedContentBlock } from '@API';
import { Card } from 'react-bootstrap';
import { Draggable } from 'react-beautiful-dnd';
import { CrossButton } from '../../CrossButton/CrossButton';
import { EditableContent } from '../../EditableContent/EditableContent';
import { InfoModal } from '../../InfoModal/InfoModal';
import { TextEditor } from '../../TextEditor/TextEditor';
import './EmbeddedContent.scss';
import { DragButton } from '../../DragButton/DragButton';
import { useEmbeddedContent } from './useEmbeddedContent';
import { ResourceUrlModal } from './EmbeddedContentModal/ResourceUrlModal/ResourceUrlModal';
import { Iframely } from './IframelyEmbed/IframelyEmbed';
import { EmbeddedForm } from './EmbeddedForm';

interface EmbeddedContentBlockProps {
  index: number;
  block: EmbeddedContentBlock;
  isAssignee?: boolean;
  isEditable: boolean;
  setShowSaved: Function;
  setShowLoader: Function;
  handleUpdateMilestoneBlocks: Function;
  handleDeleteBlock: Function;
  setIsSaving?: Function;
  isBusy: boolean;
  isParentPlaybook: boolean;
}

export const EmbeddedContentsBlock = (props: EmbeddedContentBlockProps) => {
  const {
    title,
    description,
    contentUrl,
    showVideoUrlModal,
    showConfirmationModal,
    setShowVideoUrlModal,
    setShowConfirmationModal,
    setTitle,
    setDescription,
    removeBlock,
    updateEmbeddedContentBlockUrl,
    iframeLoaded,
    setIframeLoaded,
  } = useEmbeddedContent(
    props.block,
    props.handleDeleteBlock,
    props.setShowSaved,
    props.setShowLoader,
    props.handleUpdateMilestoneBlocks,
    props.isParentPlaybook,
    props.setIsSaving,
  );

  const { isFormLinked, id } = props.block;

  return (
    <>
      <Draggable
        key={props.block.id}
        draggableId={props.block.id}
        index={props.index}
        isDragDisabled={props.isBusy || !props.isEditable}
      >
        {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.draggableProps}>
            <Card
              className={`embedded-content-block border-radius-10 mt-3 pb-2 content-block ${
                snapshot.isDragging ? 'block-dragging-active' : ''
              }`}
            >
              <Card.Body>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="w-100">
                    <div className="fs-20-semi-bold-4C5564 d-flex">
                      {props.isEditable && (
                        <span
                          className="drag-button me-2"
                          {...provided.dragHandleProps}
                        >
                          <DragButton />
                        </span>
                      )}
                      <EditableContent
                        id={`${id}-title`}
                        value={title}
                        setValue={setTitle}
                        isEditable={props.isEditable}
                      />
                    </div>
                  </div>
                  {props.isEditable && (
                    <div className="row d-flex mt-3">
                      <div
                        className="col-md-6 mt-2 fs-16-semi-bold-4C5564 cursor-pointer resource-url-edit-btn"
                        onClick={() => setShowVideoUrlModal(true)}
                      >
                        Edit
                      </div>
                      <div className="col-md-5">
                        <CrossButton
                          isDisabled={props.isBusy}
                          handleClick={() => setShowConfirmationModal(true)}
                        />
                      </div>
                    </div>
                  )}
                </div>

                {!props.isEditable && description === '' ? null : (
                  <div className="row">
                    <div className="col-12 mt-2">
                      <TextEditor
                        id={id}
                        value={description}
                        setValue={setDescription}
                        isEditable={props.isEditable}
                      />
                    </div>
                  </div>
                )}
                <div className="row">
                  {!isFormLinked && !iframeLoaded && (
                    <div className="col-12 my-3">
                      <div className="spinner-border mx-2" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  )}

                  <div className="col-12 mt-2">
                    <div className="videoWrapper">
                      {isFormLinked ? (
                        <EmbeddedForm formUrl={contentUrl} />
                      ) : (
                        <Iframely
                          updatedUrl={contentUrl}
                          iframeLoaded={iframeLoaded}
                          setIframeLoaded={setIframeLoaded}
                        ></Iframely>
                      )}
                    </div>
                  </div>
                </div>
              </Card.Body>

              <InfoModal
                showModal={showConfirmationModal}
                handleShowModal={setShowConfirmationModal}
                description={
                  <div>Are you sure you want to delete this content block?</div>
                }
                title="Delete content block"
                onClickConfirmButton={removeBlock}
                isDeleteButton={true}
              />
            </Card>
          </div>
        )}
      </Draggable>

      <ResourceUrlModal
        title={'Update embedded resource'}
        actionType={'Update'}
        showModal={showVideoUrlModal}
        handleShowModal={setShowVideoUrlModal}
        addEmbeddedContentBlock={updateEmbeddedContentBlockUrl}
        embedType={props.block.isFormLinked ? 'Form' : ''}
      />
    </>
  );
};
