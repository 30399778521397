import {
  addNotificationToApp,
  API_BASE_URL,
  downloadCSV,
  formateDate,
  QUERY_LIMIT,
  type UrlMetadata,
} from '@Shared/utils/utils';
import {
  getKinfolkTemplate,
  getOrganization,
  listInterests,
  listJourneyCollaborators,
  listKinfolkTemplates,
  listLocations,
  listMeetings,
  listOrganizations,
  listPreboardingUserRelations,
  listShareInfos,
  listBadges as listBadgesQuery,
  getBadge as getBadgeQuery,
  getJourney,
  listJourneyCollaboratorByJourneyId,
  listJourneyCollaboratorByKinfolkTemplateId,
} from '@graphql/queries';
import {
  updateWorkPreference,
  updateWorkStyle,
  createInterest,
  createUserInterest,
  createLearnMoreInfo,
  createShareInfo,
  createUserShareInfo,
  deleteUserInterest,
  deleteLearnMoreInfo,
  deleteUserShareInfo,
  createSecretKeyManager,
  deleteStandardContentBlock,
  deleteFreeTextQuestionBlock,
  deleteMultipleChoiceQuestionBlock,
  deleteTaskBlock,
  deleteNoteBlock,
  deleteEmbeddedContentBlock,
  createBadge as createBadgeMutation,
  createUserBadge as createUserBadgeMutation,
  updateEvent,
  deleteEvent,
  createEvent,
  createEventReminder,
  updateEventReminder,
  deleteEventReminder,
} from '@graphql/mutations';
import {
  type Pronoun,
  type WorkStyle,
  type WorkPreference,
  type User,
  UserType,
  type Interest,
  type Organization,
  type ShareInfo,
  type Location,
  type ModelUserFilterInput,
  type Milestone,
  type StandardContentBlock,
  type TaskBlock,
  type FreeTextQuestionBlock,
  type NoteBlock,
  type MultipleChoiceQuestionBlock,
  type EmbeddedContentBlock,
  type Journey,
  JourneyStatus,
  TemplateStatus,
  MeetingStatus,
  type Meeting,
  type SecretKeyManager,
  BlockType,
  type JourneyCollaborator,
  JourneyAccess,
  type AssigneeRole,
  type BuilderType,
  type KinfolkTemplate,
  type PreboardingUser,
  type PreboardingUserRelation,
  type ModelBadgeFilterInput,
  type Badge,
  type UserBadge,
  type ModelJourneyFilterInput,
  type ModelJourneyCollaboratorFilterInput,
  type ModelMeetingFilterInput,
  type ModelSecretKeyManagerFilterInput,
  PlaybookMode,
  type ModelPreboardingUserFilterInput,
  type Event,
  type EventReminder,
  type Digest,
  type ModelDigestFilterInput,
  type ModelMilestoneFilterInput,
} from '@API';
import { fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth';
import { ApiClient } from '@base/config/amplify.config';
import { get, post, put, del } from 'aws-amplify/api';
import {
  type CustomPlaybookCollaborator,
  type OrgUserCustomFields,
  type PlaybookPreActivationChecklist,
  type UserData,
  type UserUpdateRequest,
} from '@base/models/common.model';
import {
  type DataStore,
  DataValueType,
  type Form,
  FormElementType,
  FormInputType,
  FormLinkType,
} from '@base/models/form.model';
import {
  type InviteExistingEmployee,
  type InviteNewEmployee,
} from '../models/settings.model';
import {
  getCompanyAdminOfOrganization,
  listDetailedUsers,
  listDetailedUsersWithNameAndId,
  listInitialUsers,
  listDetailedUsersWithPhotoUrl,
  createMilestoneWithMilestoneIdReturn,
  createStandardContentBlockWithIdReturn,
  createTaskBlockWithIdReturn,
  createFreeTextQuestionBlockWithIdReturn,
  createEmbeddedContentBlockWithIdReturn,
  createMultipleChoiceQuestionBlockWithIdReturn,
  createNoteBlockWithIdReturn,
  updateStandardContentBlockWithIdReturn,
  updateTaskBlockWithIdReturn,
  updateFreeTextQuestionBlockWithIdReturn,
  updateNoteBlockWithIdReturn,
  updateMilestoneWithIdReturn,
  updateMultipleChoiceQuestionBlockWithIdReturn,
  listUserJourneys,
  createJourneyWithIdReturn,
  updateJourneyWithIdReturn,
  listDetailedUser,
  updateEmbeddedContentBlockWithIdReturn,
  updateLocationWithIdReturn,
  updateUserWithIdReturn,
  listSecretKeyManagersWithId,
  updateSecretKeyManagerDetails,
  getUserWithBasicData,
  listDetailedJourneys,
  deleteMilestoneWithIdReturn,
  deleteMeetingWithIdAndReturn,
  updateOrganizationWithIdReturn,
  updateUserWithIdAndEmailReturn,
  createMeetingWithIdReturn,
  updateMeetingWithIdReturn,
  updateJourneyCollaboratorData,
  createJourneyCollaboratorWithIdReturn,
  createKinfolkTemplateWithIdReturn,
  updateKinfolkTemplateWithIdReturn,
  listPreboardingUsersWithRelations,
  listEventsWithRemindersByJourneyId,
  listEventsWithRemindersByKinfolkTemplateId,
  listMilestoneWithBlocksByJourneyId,
  listMilestoneWithBlocksByKinfolkTemplateId,
} from '../graphql/customQueries';
import {
  type DynamicFieldResponse,
  type PresignedURLRequest,
  type SubstituteDynamicFieldsRequest,
  type SubstituteDynamicFieldsResponse,
} from './api.model';

/**
 * get user data
 */
export const getUserData = async (): Promise<User[] | undefined> => {
  try {
    const email = await getCurrentUserEmail();
    const apiData: any = await getGraphQlData(listDetailedUsers, {
      filter: { email: { eq: email } },
      limit: QUERY_LIMIT,
    });

    return apiData.data.listUsers.items;
  } catch (err) {
    addNotificationToApp('Failed to get data', 'error');
  }
};

/**
 * get user data by email
 *
 * @param email user email
 */
export const getInitialUserData = async (
  email: string,
): Promise<User[] | undefined> => {
  try {
    const apiData: any = await getGraphQlData(listInitialUsers, {
      filter: { email: { eq: email } },
      limit: QUERY_LIMIT,
    });

    return apiData.data.listUsers.items;
  } catch (err) {
    addNotificationToApp('Failed to get data', 'error');
  }
};

/**
 * get user data
 */
export const getUserDataForSettingsScreen = async (
  userOrganizationId: string,
): Promise<User[] | undefined> => {
  try {
    const apiData: any = await getGraphQlData(listInitialUsers, {
      filter: {
        userOrganizationId: { eq: userOrganizationId },
        and: [
          { type: { ne: UserType.SUPER_ADMIN } },
          { type: { ne: UserType.ANONYMOUS } },
        ],
      },
      limit: QUERY_LIMIT,
    });

    return apiData.data.listUsers.items;
  } catch (err) {
    addNotificationToApp('Failed to get data', 'error');
  }
};

/**
 * update user data
 *
 * @param user
 */
export const updateUserData = async (user: User): Promise<void> => {
  try {
    await getGraphQlData(updateUserWithIdReturn, {
      input: {
        id: user.id,
        firstName: user.firstName?.trim(),
        lastName: user.lastName?.trim(),
        email: user.email.trim(),
        jobTitle: user.jobTitle?.trim(),
        team: user.team?.trim(),
      },
    });
  } catch (err) {
    addNotificationToApp('Failed to update user data', 'error');
  }
};

/**
 * check if user exit in database
 *
 * @param email
 */
export const isAuthenticatedUser = async (
  email: string,
): Promise<boolean | undefined> => {
  try {
    const apiData: any = await getGraphQlData(listInitialUsers, {
      filter: { email: { eq: email } },
      limit: QUERY_LIMIT,
    });

    return apiData.data.listUsers.items.length;
  } catch (err) {
    addNotificationToApp('Failed to login', 'error');
  }
};

/**
 * get current user email
 */
export const getCurrentUserEmail = async (): Promise<string | undefined> => {
  try {
    const { email } = await fetchUserAttributes();
    return email;
  } catch (err) {
    console.log('[apis][getCurrentUserEmail] failed to get current user email');
  }
};

/**
 * update user about email
 *
 * @param user User
 * @param aboutMe string
 */
export const updateUseAbout = async (
  user: User,
  aboutMe: string,
): Promise<void> => {
  try {
    await getGraphQlData(updateUserWithIdReturn, {
      input: {
        id: user.id,
        userOrganizationId: user.userOrganizationId,
        userWorkPreferenceId: user.userWorkPreferenceId,
        userWorkStyleId: user.userWorkStyleId,
        aboutMe: aboutMe.trim(),
      },
    });

    return;
  } catch (err) {
    console.log(err);
    addNotificationToApp('Failed to update about me', 'error');
  }
};

/**
 * update extra details
 *
 * @param user User to update
 * @param pronoun string
 * @param socialMediaUrl string
 */
export const updateUserExtraDetails = async (
  user: User,
  pronoun: Pronoun,
  socialMediaUrl: string,
): Promise<void> => {
  try {
    await getGraphQlData(updateUserWithIdReturn, {
      input: {
        id: user.id,
        userOrganizationId: user.userOrganizationId,
        userWorkPreferenceId: user.userWorkPreferenceId,
        userWorkStyleId: user.userWorkStyleId,
        socialMediaUrl,
        pronoun: pronoun.trim(),
      },
    });

    return;
  } catch (err) {
    console.log(err);
    addNotificationToApp('Failed to update extra details', 'error');
  }
};

/*
 * update most happy when
 *
 * @param user User to update
 * @param mostHappy string
 */
export const updateMostHappy = async (
  user: User,
  mostHappy: string,
): Promise<void> => {
  try {
    await getGraphQlData(updateUserWithIdReturn, {
      input: {
        id: user.id,
        userOrganizationId: user.userOrganizationId,
        userWorkPreferenceId: user.userWorkPreferenceId,
        userWorkStyleId: user.userWorkStyleId,
        mostHappy: mostHappy.trim(),
      },
    });

    return;
  } catch (err) {
    console.log(err);
    addNotificationToApp('Failed to update extra details', 'error');
  }
};

/**
 * Create interest data
 *
 * @param user User to create interest
 * @param interest string
 */
export const createInterestData = async (
  user: User,
  interest: string,
): Promise<Interest | undefined> => {
  try {
    const apiData: any = await getGraphQlData(createInterest, {
      input: {
        organizationID: user.userOrganizationId,
        name: interest.trim(),
      },
    });

    return apiData.data.createInterest;
  } catch (err) {
    console.log(err);
    addNotificationToApp('Failed to update extra details', 'error');
  }
};

/**
 * Create user interest link
 *
 * @param interestID string
 * @param userID string
 */
export const createUserInterestData = async (
  interestID: string,
  userID: string,
): Promise<void> => {
  try {
    await getGraphQlData(createUserInterest, {
      input: {
        interestID: interestID,
        userID: userID,
      },
    });

    return;
  } catch (err) {
    console.log(err);
    addNotificationToApp('Failed to update extra details', 'error');
  }
};

/**
 * Delete user interest link
 *
 * @param interestID string
 * @param userID string
 */
export const deleteUserInterestData = async (
  interestID: string,
): Promise<void> => {
  try {
    await getGraphQlData(deleteUserInterest, {
      input: {
        id: interestID,
      },
    });

    return;
  } catch (err) {
    console.log(err);
    addNotificationToApp('Failed to update extra details', 'error');
  }
};

/**
 * Create interest data
 *
 * @param user User to create interest
 * @param interest string
 */
export const createShareInfoData = async (
  user: User,
  interest: string,
): Promise<ShareInfo | undefined> => {
  try {
    const apiData: any = await getGraphQlData(createShareInfo, {
      input: {
        organizationID: user.userOrganizationId,
        name: interest.trim(),
      },
    });

    return apiData.data.createShareInfo;
  } catch (err) {
    console.log(err);
    addNotificationToApp('Failed to update extra details', 'error');
  }
};

/**
 * Create user learn more info link
 *
 * @param shareInfoID string
 * @param userID string
 */
export const createUserLearnMoreInfoData = async (
  shareInfoID: string,
  userID: string,
): Promise<void> => {
  try {
    await getGraphQlData(createLearnMoreInfo, {
      input: {
        shareInfoID: shareInfoID,
        userID: userID,
      },
    });

    return;
  } catch (err) {
    console.log(err);
    addNotificationToApp('Failed to update extra details', 'error');
  }
};

/**
 * Delete user learn more info link
 *
 * @param interestID string
 * @param userID string
 */
export const deleteUserLearnMoreInfoData = async (
  interestID: string,
): Promise<void> => {
  try {
    await getGraphQlData(deleteLearnMoreInfo, {
      input: {
        id: interestID,
      },
    });

    return;
  } catch (err) {
    console.log(err);
    addNotificationToApp('Failed to update extra details', 'error');
  }
};

/**
 * Create user learn more info link
 *
 * @param shareInfoID string
 * @param userID string
 */
export const createUserShareInfoData = async (
  shareInfoID: string,
  userID: string,
): Promise<void> => {
  try {
    await getGraphQlData(createUserShareInfo, {
      input: {
        shareInfoID: shareInfoID,
        userID: userID,
      },
    });

    return;
  } catch (err) {
    console.log(err);
    addNotificationToApp('Failed to update extra details', 'error');
  }
};

/**
 * Delete user share info data link
 *
 * @param interestID string
 * @param userID string
 */
export const deleteUserShareInfoData = async (
  interestID: string,
): Promise<void> => {
  try {
    await getGraphQlData(deleteUserShareInfo, {
      input: {
        id: interestID,
      },
    });

    return;
  } catch (err) {
    console.log(err);
    addNotificationToApp('Failed to update extra details', 'error');
  }
};

/**
 * update user work style
 *
 * @param workStyle WorkStyle
 */
export const updateUserWorkStyle = async (
  workStyle: WorkStyle,
): Promise<void> => {
  try {
    await getGraphQlData(updateWorkStyle, {
      input: {
        id: workStyle.id,
        whatIDoAtWork: workStyle.whatIDoAtWork?.trim(),
        myFocusTimeIs: workStyle.myFocusTimeIs?.trim(),
        giveReceivingFeedback: workStyle.giveReceivingFeedback?.trim(),
        digestionInformation: workStyle.digestionInformation?.trim(),
        inMyElement: workStyle.inMyElement?.trim(),
      },
    });

    return;
  } catch (err) {
    console.log(err);
    addNotificationToApp('Failed to update extra details', 'error');
  }
};

/**
 * Get organization by ID
 *
 * @param organizationID
 */
export const getOrganizationData = async (
  organizationID: string,
): Promise<Organization | undefined> => {
  try {
    const apiData: any = await getGraphQlData(getOrganization, {
      id: organizationID,
    });

    return apiData.data.getOrganization;
  } catch (err) {
    addNotificationToApp('Failed to get organization data', 'error');
  }
};

/**
 * list all organization interest
 *
 * @param organizationID
 */
export const ListOrganizationData = async (
  organizationID: string,
): Promise<Organization[] | undefined> => {
  try {
    const apiData: any = await getGraphQlData(listOrganizations, {
      filter: { id: { eq: organizationID } },
      limit: QUERY_LIMIT,
    });

    return apiData.data.listOrganizations.items;
  } catch (err) {
    addNotificationToApp('Failed to get organizations data', 'error');
  }
};

/**
 * list all organization interest
 *
 * @param organizationID
 */
export const ListInterestData = async (
  organizationID: string,
): Promise<Interest[] | undefined> => {
  try {
    const apiData: any = await getGraphQlData(listInterests, {
      filter: { organizationID: { eq: organizationID } },
      limit: QUERY_LIMIT,
    });

    return apiData.data.listInterests.items;
  } catch (err) {
    addNotificationToApp('Failed to get data', 'error');
  }
};

export const listShareInfosData = async (
  organizationID: string,
): Promise<ShareInfo[] | undefined> => {
  try {
    const apiData: any = await getGraphQlData(listShareInfos, {
      filter: { organizationID: { eq: organizationID } },
      limit: QUERY_LIMIT,
    });

    return apiData.data.listShareInfos.items;
  } catch (err) {
    addNotificationToApp('Failed to get data', 'error');
  }
};

/**
 * update user work preferences
 *
 * @param workPreferences WorkPreference
 */
export const updateUserWorkPreferences = async (
  workPreferences: WorkPreference,
): Promise<void> => {
  try {
    await getGraphQlData(updateWorkPreference, {
      input: {
        id: workPreferences.id,
        giveMeAllTheContext: workPreferences.giveMeAllTheContext,
        messageTimingPreferene: workPreferences.messageTimingPreferene,
        syncxVsAsync: workPreferences.syncxVsAsync,
        talkTogetherVsAlone: workPreferences.talkTogetherVsAlone,
        recognitionPrivateVsPrivate:
          workPreferences.recognitionPrivateVsPrivate,
      },
    });

    return;
  } catch (err) {
    console.log(err);
    addNotificationToApp('Failed to update user work preferences', 'error');
  }
};

/**
 * update user profile photo
 *
 * @param user User
 * @param photoUrl string
 */
export const updateUserProfilePhoto = async (
  user: User,
  photoUrl: string,
): Promise<void> => {
  try {
    await getGraphQlData(updateUserWithIdReturn, {
      input: {
        id: user.id,
        userOrganizationId: user.userOrganizationId,
        userWorkPreferenceId: user.userWorkPreferenceId,
        userWorkStyleId: user.userWorkStyleId,
        photoUrl,
      },
    });

    return;
  } catch (err) {
    console.log(err);
    addNotificationToApp('Failed to update profile photo', 'error');
  }
};

/**
 * update user profile photo
 *
 * @param user User
 * @param photoUrl string
 */
export const updateUserFirstLogin = async (
  user: User,
  isFirstLogin: boolean,
): Promise<void> => {
  try {
    await getGraphQlData(updateUserWithIdReturn, {
      input: {
        id: user.id,
        userOrganizationId: user.userOrganizationId,
        userWorkPreferenceId: user.userWorkPreferenceId,
        userWorkStyleId: user.userWorkStyleId,
        isFirstLogin: isFirstLogin,
      },
    });

    return;
  } catch (err) {
    console.log(err);
    addNotificationToApp('Failed to update user profile page viewed', 'error');
  }
};

/**
 * update user home page viewed
 *
 * @param user User
 * @param photoUrl string
 */
export const updateUserHomeViewed = async (
  user: User,
  isHomeModelViewed: boolean,
): Promise<void> => {
  try {
    await getGraphQlData(updateUserWithIdReturn, {
      input: {
        id: user.id,
        userOrganizationId: user.userOrganizationId,
        userWorkPreferenceId: user.userWorkPreferenceId,
        userWorkStyleId: user.userWorkStyleId,
        isHomeModelViewed: isHomeModelViewed,
      },
    });

    return;
  } catch (err) {
    console.log(err);
    addNotificationToApp('Failed to update user home page viewed', 'error');
  }
};

/**
 * list organization location
 *
 * @param organizationId
 */
export const listOrganizationLocations = async (
  organizationId: string,
): Promise<Location[] | undefined> => {
  try {
    const apiData: any = await getGraphQlData(listLocations, {
      filter: { organizationID: { eq: organizationId } },
      limit: QUERY_LIMIT,
    });

    return apiData.data.listLocations.items;
  } catch (err) {
    addNotificationToApp('Failed to get locations', 'error');
  }
};

/**
 * get filtered user
 *
 * @param filter to filter out users
 */
export const getAllOrganizationUsers = async (
  filter: ModelUserFilterInput,
): Promise<User[] | undefined> => {
  try {
    const apiData: any = await getGraphQlData(listDetailedUsersWithNameAndId, {
      filter,
      limit: QUERY_LIMIT,
    });

    return apiData.data.listUsers.items;
  } catch (err) {
    addNotificationToApp('Failed to get all organization users', 'error');
  }
};

/**
 * get organization admins
 *
 * @param organizationId
 * @param userType
 */
export const listOrganizationAdmins = async (
  userType: UserType,
): Promise<User[] | undefined> => {
  try {
    const apiData: any = await getGraphQlData(getCompanyAdminOfOrganization, {
      limit: QUERY_LIMIT,
      filter: {
        type: { eq: userType },
      },
    });

    return apiData.data.listUsers.items;
  } catch (err) {
    addNotificationToApp('Failed to get admins', 'error');
  }
};

/**
 * get filtered user
 *
 * @param organizationId
 * @param interests Interest[]
 */
export const getFilteredInterestsOfUser = async (
  organizationId: string,
  interests: any,
): Promise<Interest[] | undefined> => {
  try {
    if (interests.length !== 0) {
      //get Users for those Interests
      let userInterestFilter = {
        or: interests.map((interestID: any) => ({ id: { eq: interestID } })),
      };

      const apiData: any = await getGraphQlData(listInterests, {
        filter: {
          or: userInterestFilter,
        },
        limit: QUERY_LIMIT,
      });

      return apiData.data.listInterests.items;
    }
    return;
  } catch (err) {
    console.log(err);
    addNotificationToApp('Failed to get user interests', 'error');
  }
};

/**
 * get filtered user knowledge
 *
 * @param organizationId
 * @param knowledge ShareInfo[]
 */
export const getFilteredKnowledgeOfUser = async (
  organizationId: string,
  shareInfoList: any,
): Promise<ShareInfo[] | undefined> => {
  try {
    if (shareInfoList.length !== 0) {
      //get Users for those Interests
      let userShareInfoFilter = {
        or: shareInfoList.map((shareInfoID: any) => ({
          id: { eq: shareInfoID },
        })),
      };

      const apiData: any = await getGraphQlData(listShareInfos, {
        filter: {
          or: userShareInfoFilter,
        },
        limit: QUERY_LIMIT,
      });

      return apiData.data.listShareInfos.items;
    }
    return;
  } catch (err) {
    console.log(err);
    addNotificationToApp('Failed to get user share info list', 'error');
  }
};

export const recalculateStartDate = async (
  userId: string,
  newStartDate: string,
) => {
  try {
    await putApiData('organization', `${API_BASE_URL}/journey/user/${userId}`, {
      newStartDate,
    });
  } catch (err) {
    addNotificationToApp(
      'Failed to recalculate start dates for the user.',
      'error',
    );
  }
};
/**
 * get user data
 *
 * @param userId user id
 * @param organizationId organization id
 */
export const getUserDetailsById = async (
  userId: string,
  organizationId: string,
): Promise<User | undefined> => {
  try {
    const apiData: any = await getGraphQlData(listDetailedUsers, {
      filter: {
        id: { eq: userId },
        userOrganizationId: { eq: organizationId },
      },
      limit: QUERY_LIMIT,
    });

    if (!apiData.data.listUsers.items.length) {
      addNotificationToApp('User not found.', 'info');
    }

    return apiData.data.listUsers.items[0];
  } catch (err) {
    console.log(err);
    addNotificationToApp('Failed to get user by id', 'error');
  }
};

/*
 * add organization
 *
 * @param adminEmail
 * @param organizationName
 */
export const addOrganizationFromAdmin = async (
  adminEmail: string,
  organizationName: string,
): Promise<boolean | undefined> => {
  try {
    let baseUrl = `${API_BASE_URL}/organization`;
    const bodyObj = {
      adminEmail: adminEmail,
      organizationName: organizationName,
    };

    await postApiData('organization', baseUrl, bodyObj);

    addNotificationToApp('Organization created successfully.', 'success');
    return true;
  } catch (err) {
    addNotificationToApp(
      'Failed to create organization, please check email format',
      'error',
    );
  }
};

/*
 * Edit organization
 *
 * @param adminEmail
 * @param organizationName
 */
export const editOrganizationFromAdmin = async (
  organizationId: string,
  organizationName: string,
  mergeAccountToken?: string | null,
): Promise<boolean | undefined> => {
  try {
    let baseUrl = `${API_BASE_URL}/organization/${organizationId}`;
    const bodyObj = {
      organizationName: organizationName,
      mergeAccountToken: mergeAccountToken,
    };

    await putApiData('organization', baseUrl, bodyObj);
    addNotificationToApp(
      'Successfully Edited Organization: ' + organizationName,
      'success',
    );
    return true;
  } catch (err) {
    addNotificationToApp('Failed to add Organization', 'error');
  }
};

/*
 * Edit organization
 *
 * @param adminEmail
 * @param organizationName
 */
export const importOrganizationMembers = async (
  organizationId: string,
): Promise<boolean | undefined> => {
  try {
    let baseUrl = `${API_BASE_URL}/organization/${organizationId}/import`;

    await putApiData('organization', baseUrl, {});
    return true;
  } catch (err) {
    addNotificationToApp('Failed to Import Users', 'error');
  }
};

/**
 * list all users
 *
 * @param organizationId string
 * @param query string
 */
export const searchUser = async (
  organizationId: string,
  query: string,
): Promise<User[] | undefined> => {
  try {
    const apiData: any = await getGraphQlData(listDetailedUsersWithNameAndId, {
      filter: {
        or: [
          { firstName: { contains: query } },
          { lastName: { contains: query } },
          { email: { contains: query } },
          {
            firstName: {
              contains: query.charAt(0).toUpperCase() + query.slice(1),
            },
          },
          {
            lastName: {
              contains: query.charAt(0).toUpperCase() + query.slice(1),
            },
          },
          {
            email: {
              contains: query.charAt(0).toUpperCase() + query.slice(1),
            },
          },
          {
            firstName: {
              contains: query.charAt(0).toLowerCase() + query.slice(1),
            },
          },
          {
            lastName: {
              contains: query.charAt(0).toLowerCase() + query.slice(1),
            },
          },
          {
            firstName: {
              contains:
                query.split(' ')[0].charAt(0).toLowerCase() +
                query.split(' ')[0].slice(1),
            },
          },
          {
            firstName: {
              contains:
                query.split(' ')[0].charAt(0).toUpperCase() +
                query.split(' ')[0].slice(1),
            },
          },
          {
            lastName: {
              contains: query.split(' ')[1]
                ? query.split(' ')[1].charAt(0).toLowerCase() +
                  query.split(' ')[1].slice(1)
                : query,
            },
          },
          {
            lastName: {
              contains: query.split(' ')[1]
                ? query.split(' ')[1].charAt(0).toUpperCase() +
                  query.split(' ')[1].slice(1)
                : query,
            },
          },
          {
            email: {
              contains: query.charAt(0).toLowerCase() + query.slice(1),
            },
          },
        ],
        userOrganizationId: { eq: organizationId },
        and: [
          { type: { ne: UserType.SUPER_ADMIN } },
          { type: { ne: UserType.ANONYMOUS } },
        ],
      },
      limit: QUERY_LIMIT,
    });

    return apiData.data.listUsers.items;
  } catch (err) {
    addNotificationToApp('Failed to get searched user data', 'error');
  }
};

/**
 * get organization profile images
 *
 * @param organizationId string
 */
export const getOrganizationUsersImages = async (
  organizationId: string,
): Promise<User[] | undefined> => {
  try {
    const apiData: any = await getGraphQlData(listDetailedUsersWithPhotoUrl, {
      filter: {
        userOrganizationId: { eq: organizationId },
      },
      limit: 5,
    });

    return apiData.data.listUsers.items;
  } catch (err) {
    addNotificationToApp('Failed to get user profile images', 'error');
  }
};

const getGraphQlData = async (query: any, variables: any) => {
  const authMode =
    localStorage.getItem('isLoggedIn') === 'true' ? 'userPool' : 'iam';
  const apiData: any = await ApiClient.graphql({
    authMode,
    query,
    variables,
  });

  return apiData;
};

export const getApiData = async (
  apiName: string,
  path: string,
  bodyType: 'json' | 'text' | 'blob' = 'json',
): Promise<any> => {
  const { headers } = await getApiHeadersWithoutBody({});

  const res = await get({ apiName, path, options: { headers: headers } })
    .response;

  if (bodyType === 'json') {
    return await res.body.json();
  } else if (bodyType === 'text') {
    return await res.body.text();
  } else {
    return await res.body.blob();
  }
};

const putApiData = async (
  apiName: string,
  path: string,
  bodyObj: any,
): Promise<any> => {
  const { headers, body } = await getApiHeaders(bodyObj);
  const res = await put({ apiName, path, options: { headers, body } }).response;
  return await res.body.json();
};

const postApiData = async (
  apiName: string,
  path: string,
  bodyObj: any,
): Promise<any> => {
  const { headers, body } = await getApiHeaders(bodyObj);

  const res = await post({ apiName, path, options: { headers, body } })
    .response;
  return await res.body.json();
};

const deleteApiData = async (apiName: string, path: string, bodyObj: any) => {
  const { headers } = await getApiHeaders(bodyObj);

  return await del({ apiName, path, options: { headers } }).response;
};

const getApiHeadersWithoutBody = async (bodyObj: any) => {
  const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();

  const headers = {
    headers: {
      // Todo
      // using "x-amz-security-token" by amplify itself

      Authorization: `Bearer ${authToken}`,
    },
  };
  return headers;
};

const getApiHeaders = async (bodyObj: any) => {
  const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();

  const headers = {
    headers: {
      // Todo
      // using "x-amz-security-token" by amplify itself
      Authorization: `Bearer ${authToken}`,
    },

    body: bodyObj,
  };
  return headers;
};

/**
 * create new milestone
 *
 * @param milestone Milestone
 */
export const createNewMilestone = async (
  milestone: Milestone,
): Promise<string | undefined> => {
  try {
    const apiData: any = await getGraphQlData(
      createMilestoneWithMilestoneIdReturn,
      {
        input: {
          name: milestone.name.trim(),
          kinfolkTemplateID: milestone.kinfolkTemplateID,
          isCompleted: false,
          journeyID: milestone.journeyID,
          orderNo: milestone.orderNo + 1,
        },
      },
    );
    return apiData.data.createMilestone.id;
  } catch (err) {
    addNotificationToApp('Failed to create milestone', 'error');
  }
};

/**
 * getTemplateMilestones
 *
 * @param templateId string
 */
export const getTemplateMilestones = async (
  templateId: string,
  filter: ModelMilestoneFilterInput = {},
): Promise<Milestone[] | undefined> => {
  try {
    const apiData: any = await getGraphQlData(
      listMilestoneWithBlocksByKinfolkTemplateId,
      {
        kinfolkTemplateID: templateId,
        filter,
        limit: QUERY_LIMIT,
      },
    );

    return apiData.data.listMilestoneByKinfolkTemplateId.items;
  } catch (err) {
    addNotificationToApp('Failed to get template milestones', 'error');
  }
};

/**
 * add standard content block
 *
 * @param block StandardContentBlock
 */
export const addStandardContentBlock = async (
  block: StandardContentBlock,
): Promise<string | undefined> => {
  try {
    const apiData: any = await getGraphQlData(
      createStandardContentBlockWithIdReturn,
      {
        input: {
          milestoneID: block.milestoneID,
          title: block.title.trim(),
          description: block.description?.trim(),
          orderNo: block.orderNo + 1,
          type: block.type,
        },
      },
    );
    return apiData.data.createStandardContentBlock.id;
  } catch (err) {
    addNotificationToApp('Failed to create standard content block', 'error');
  }
};

/**
 * add task block
 *
 * @param block TasKBlock
 */
export const addTaskBlock = async (
  block: TaskBlock,
): Promise<string | undefined> => {
  try {
    const apiData: any = await getGraphQlData(createTaskBlockWithIdReturn, {
      input: {
        milestoneID: block.milestoneID,
        title: block.title.trim(),
        description: block.description?.trim(),
        orderNo: block.orderNo + 1,
        type: block.type,
      },
    });
    return apiData.data.createTaskBlock.id;
  } catch (err) {
    addNotificationToApp('Failed to create task block', 'error');
  }
};

/**
 * add free text question block
 *
 * @param block FreeTextQuestionBlock
 */
export const addFreeTextQuestionBlock = async (
  block: FreeTextQuestionBlock,
): Promise<string | undefined> => {
  try {
    const apiData: any = await getGraphQlData(
      createFreeTextQuestionBlockWithIdReturn,
      {
        input: {
          milestoneID: block.milestoneID,
          title: block.title.trim(),
          description: block.description?.trim(),
          text: block.text?.trim(),
          orderNo: block.orderNo + 1,
          type: block.type,
        },
      },
    );
    return apiData.data.createFreeTextQuestionBlock.id;
  } catch (err) {
    addNotificationToApp('Failed to create free text question block', 'error');
  }
};

/**
 * add note block
 *
 * @param block NoteBlock
 */
export const addNoteBlock = async (
  block: NoteBlock,
): Promise<string | undefined> => {
  try {
    const apiData: any = await getGraphQlData(createNoteBlockWithIdReturn, {
      input: {
        milestoneID: block.milestoneID,
        title: block.title.trim(),
        text: block.text?.trim(),
        description: block.description?.trim(),
        orderNo: block.orderNo + 1,
        type: block.type,
      },
    });
    return apiData.data.createNoteBlock.id;
  } catch (err) {
    addNotificationToApp('Failed to create standard content block', 'error');
  }
};

/**
 * add multiple choice question block
 *
 * @param block MultipleChoiceQuestionBlock
 */
export const addMultipleChoiceQuestionBlock = async (
  block: MultipleChoiceQuestionBlock,
): Promise<string | undefined> => {
  try {
    const apiData: any = await getGraphQlData(
      createMultipleChoiceQuestionBlockWithIdReturn,
      {
        input: {
          milestoneID: block.milestoneID,
          title: block.title.trim(),
          description: block.description?.trim(),
          orderNo: block.orderNo + 1,
          type: block.type,
        },
      },
    );
    return apiData.data.createMultipleChoiceQuestionBlock.id;
  } catch (err) {
    addNotificationToApp(
      'Failed to create multiple choice question block',
      'error',
    );
  }
};

/**
 * add embedded content block
 *
 * @param block MultipleChoiceQuestionBlock
 */
export const addEmbeddedContentBlock = async (
  block: EmbeddedContentBlock,
): Promise<string | undefined> => {
  try {
    const apiData: any = await getGraphQlData(
      createEmbeddedContentBlockWithIdReturn,
      {
        input: {
          milestoneID: block.milestoneID,
          title: block.title.trim(),
          resourceLink: block.resourceLink.trim(),
          description: block.description?.trim(),
          orderNo: block.orderNo + 1,
          type: block.type,
          isFormLinked: block.isFormLinked,
        },
      },
    );
    return apiData.data.createEmbeddedContentBlock.id;
  } catch (err) {
    addNotificationToApp('Failed to create embedded content block', 'error');
  }
};

/**
 * update standard content block
 *
 * @param block StandardContentBlock
 */
export const updateStandardContentBlock = async (
  block: StandardContentBlock,
): Promise<string | undefined> => {
  try {
    const apiData: any = await getGraphQlData(
      updateStandardContentBlockWithIdReturn,
      {
        input: {
          ...block,
          title: block.title.trim(),
          description: block.description?.trim(),
        },
      },
    );
    return apiData.data.updateStandardContentBlock.id;
  } catch (err) {
    addNotificationToApp('Failed to update standard content block', 'error');
  }
};

/**
 * update task block
 *
 * @param block TasKBlock
 */
export const updateTaskBlock = async (
  block: Partial<TaskBlock>,
): Promise<string | undefined> => {
  try {
    const apiData: any = await getGraphQlData(updateTaskBlockWithIdReturn, {
      input: {
        ...block,
      },
    });
    return apiData.data.updateTaskBlock.id;
  } catch (err) {
    addNotificationToApp('Failed to update task block', 'error');
  }
};

/**
 * update free text question block
 *
 * @param block FreeTextQuestionBlock
 */
export const updateFreeTextQuestionBlock = async (
  block: FreeTextQuestionBlock,
): Promise<string | undefined> => {
  try {
    const apiData = await getGraphQlData(
      updateFreeTextQuestionBlockWithIdReturn,
      {
        input: {
          ...block,
          title: block.title.trim(),
          description: block.description?.trim(),
          text: block.text?.trim(),
        },
      },
    );
    return apiData.data.updateFreeTextQuestionBlock.id;
  } catch (err) {
    addNotificationToApp('Failed to update free text question block', 'error');
  }
};
/**
 * update free text question block Completion
 *
 * @param block FreeTextQuestionBlock
 */
export const updateFreeTextQuestionBlockCompletion = async (
  block: FreeTextQuestionBlock,
): Promise<string | undefined> => {
  try {
    const apiData: any = await getGraphQlData(
      updateFreeTextQuestionBlockWithIdReturn,
      {
        input: {
          id: block.id,
          isCompleted: block.isCompleted,
        },
      },
    );
    return apiData.data.updateFreeTextQuestionBlock.id;
  } catch (err) {
    addNotificationToApp(
      'Failed to update free text question block Completion status',
      'error',
    );
  }
};

/**
 * update note block
 *
 * @param block NoteBlock
 */
export const updateNoteBlock = async (
  block: NoteBlock,
): Promise<string | undefined> => {
  try {
    const apiData: any = await getGraphQlData(updateNoteBlockWithIdReturn, {
      input: {
        ...block,
        title: block.title.trim(),
        description: block.description?.trim(),
        text: block.text?.trim(),
      },
    });
    return apiData.data.updateNoteBlock.id;
  } catch (err) {
    addNotificationToApp('Failed to update standard content block', 'error');
  }
};

/**
 * update multiple choice question block
 *
 * @param block NoteBlock
 */
export const updateMultipleChoiceQuestionBlock = async (
  block: MultipleChoiceQuestionBlock,
): Promise<string | undefined> => {
  try {
    const apiData: any = await getGraphQlData(
      updateMultipleChoiceQuestionBlockWithIdReturn,
      {
        input: {
          ...block,
          title: block.title.trim(),
          description: block.description?.trim(),
        },
      },
    );
    return apiData.data.updateMultipleChoiceQuestionBlock.id;
  } catch (err) {
    addNotificationToApp(
      'Failed to update multiple choice question block',
      'error',
    );
  }
};

/**
 * update milestone
 *
 * @param milestone Milestone
 */
export const updateMilestone = async (
  milestone: Milestone,
): Promise<string | undefined> => {
  try {
    if (milestone) {
      const apiData: any = await getGraphQlData(updateMilestoneWithIdReturn, {
        input: {
          id: milestone.id,
          name: milestone.name?.trim(),
          isCompleted: milestone.isCompleted,
          isArchived: milestone.isArchived,
        },
      });
      return apiData.data.updateMilestone.id;
    }
  } catch (err) {
    addNotificationToApp('Failed to update milestone', 'error');
  }
};

/**
 * fetch metadata
 *
 * @param url string
 */
export const fetchMetadata = async (
  url: string,
): Promise<UrlMetadata | undefined> => {
  try {
    url = encodeURIComponent(url);
    const apiData: any = await getApiData(
      'organization',
      `${API_BASE_URL}/scrap/${url}`,
    );

    return apiData.body.metadata;
  } catch (err) {
    addNotificationToApp('Failed to fetch metadata', 'error');
  }
};

/**
 * fetch journeys
 *
 * @param filters to filter out journeys
 */
export const fetchJourneys = async (
  filters: ModelJourneyFilterInput,
): Promise<Journey[] | undefined> => {
  try {
    const apiData: any = await getGraphQlData(listUserJourneys, {
      filter: filters,
      limit: QUERY_LIMIT,
    });

    return apiData.data.listJourneys.items;
  } catch (err) {
    addNotificationToApp('Failed to get journeys', 'error');
  }
};

/**
 * list user journeys
 *
 * @param userId string
 */
export const getUserJourneys = async (
  userId: string,
): Promise<Journey[] | undefined> => {
  try {
    if (userId) {
      const apiData: any = await getGraphQlData(listUserJourneys, {
        filter: {
          createdByUserID: { eq: userId },
          isArchived: { eq: false },
        },
        limit: QUERY_LIMIT,
      });

      return apiData.data.listJourneys.items;
    }
  } catch (err) {
    addNotificationToApp('Failed to get journeys', 'error');
  }
};

/**
 * create Journey From Scratch
 *
 * @param journeyName string
 * @param createdBy string
 * @param userId string
 * @param organizationId string
 * @param startDate of the journey
 * @param type journey type
 * @param mode playbook mode
 * @param image playbook image
 */
export const createNewJourney = async (
  journeyName: string,
  userId: string,
  organizationId: string,
  startDate: string | null,
  type: BuilderType,
  mode: PlaybookMode,
  image: string,
): Promise<string | undefined> => {
  try {
    const apiData: any = await getGraphQlData(createJourneyWithIdReturn, {
      input: {
        name: journeyName.trim(),
        organizationID: organizationId,
        journeyProgress: 0,
        status: JourneyStatus.DRAFT,
        createdByUserID: userId,
        type: type,
        mode,
        startDate: startDate
          ? new Date(startDate).toISOString().split('T')[0]
          : startDate,
        image,
      },
    });
    return apiData.data.createJourney.id;
  } catch (err) {
    addNotificationToApp('Failed to create journey', 'error');
  }
};

/**
 * get journey milestones
 *
 * @param journeyId string
 */
export const getJourneyMilestones = async (
  journeyId: string,
  filter: ModelMilestoneFilterInput = {},
): Promise<Milestone[] | undefined> => {
  try {
    const apiData: any = await getGraphQlData(
      listMilestoneWithBlocksByJourneyId,
      {
        journeyID: journeyId,
        filter,
        limit: QUERY_LIMIT,
      },
    );

    return apiData.data.listMilestoneByJourneyId.items;
  } catch (err) {
    addNotificationToApp('Failed to get journey milestones', 'error');
  }
};

/**
 * create journey from template
 *
 * @param organizationId string
 * @param templateId string
 * @param createdByUserID string
 */
export const createJourneyFromTemplate = async (
  organizationId: string,
  templateId: string,
  creatorId: string,
): Promise<string | undefined> => {
  try {
    const apiData: any = await postApiData(
      'organization',
      `${API_BASE_URL}/journey/from-template/${templateId}`,
      {
        organizationId,
        creatorId,
      },
    );

    return apiData.id;
  } catch (err) {
    console.error(
      '[apis][createJourneyFromTemplate] unable to create journey ',
      err,
    );
    addNotificationToApp('Failed to create journey', 'error');
  }
};

/**
 * assign journey
 *
 * @param journeyId string
 * @param assignees string
 * @param collaborators string
 * @param creatorId string
 * @param startDate assignee start date will be used if no start date is provided
 */
export const assignJourney = async (
  journeyId: string,
  assignees: User[],
  creatorId: string,
  startDate?: string,
): Promise<[string] | undefined> => {
  try {
    const res = await postApiData(
      'organization',
      `${API_BASE_URL}/journey/assign/${journeyId}`,
      {
        users: assignees,
        creatorId,
        startDate,
      },
    );

    return res.ids;
  } catch (err) {
    addNotificationToApp('Failed to assign runbook', 'error');
  }
};

/**
 * add new meeting
 *
 * @param meeting journeyNewMeetings
 */
export const addNewMeeting = async (
  meeting: Meeting,
): Promise<string | undefined> => {
  try {
    const apiData = await getGraphQlData(createMeetingWithIdReturn, {
      input: {
        journeyID: meeting.journeyID,
        kinfolkTemplateID: meeting.kinfolkTemplateID,
        title: meeting.title.trim(),
        description: meeting.description?.trim(),
        duration: meeting.duration.trim(),
        startTime: meeting.startTime,
        endTime: meeting.endTime,
        attendeesEmail: meeting.attendeesEmail,
        assigneeRole: meeting.assigneeRole,
        organizedByUserID: meeting.organizedByUserID,
        status: MeetingStatus.DRAFT,
      },
    });

    return apiData.data.createMeeting.id;
  } catch (err) {
    addNotificationToApp('Failed to add meeting', 'error');
  }
};

/**
 * update meeting
 *
 * @param meeting to be updated
 */
export const updateMeeting = async (
  meeting: Meeting,
): Promise<string | undefined> => {
  try {
    const apiData = await getGraphQlData(updateMeetingWithIdReturn, {
      input: {
        id: meeting.id,
        title: meeting.title.trim(),
        description: meeting.description?.trim(),
        duration: meeting.duration.trim(),
        startTime: meeting.startTime,
        endTime: meeting.endTime,
        attendeesEmail: meeting.attendeesEmail,
        status: meeting.status,
        assigneeRole: meeting.assigneeRole,
        updatedInChild: meeting.updatedInChild,
      },
    });

    return apiData.data.updateMeeting.id;
  } catch (err) {
    addNotificationToApp('Failed to update meeting', 'error');
  }
};

/**
 * get filtered user
 * @param filter to find user
 */
export const getFilteredUser = async (
  filter: ModelUserFilterInput,
): Promise<User | undefined> => {
  try {
    const apiData = await getGraphQlData(listDetailedUser, {
      filter: filter,
      limit: QUERY_LIMIT,
    });
    return apiData.data.listUsers.items[0];
  } catch (err) {
    addNotificationToApp('Failed to get user data', 'error');
  }
};

/**
 * get journey meeting
 *
 * @param builderId string
 * @param isTemplate is template
 * @param filter filter
 */
export const getMeetings = async (
  builderId: string,
  isTemplate: boolean,
  filters?: ModelMeetingFilterInput,
): Promise<Meeting[] | undefined> => {
  try {
    let filter: ModelMeetingFilterInput = {
      status: { ne: MeetingStatus.ARCHIVED },
    };
    if (isTemplate) {
      filter = { ...filter, kinfolkTemplateID: { eq: builderId } };
    } else {
      filter = { ...filter, journeyID: { eq: builderId } };
    }

    if (filters) {
      filter = { ...filters, ...filter };
    }
    const res = await getGraphQlData(listMeetings, {
      filter,
      limit: QUERY_LIMIT,
    });
    return res.data.listMeetings.items;
  } catch (err) {
    addNotificationToApp('Failed to get journey meetings', 'error');
  }
};

/**
 * scheduleJourneyMeetings
 *
 * @param meetings Meeting[]
 * @param journeyStartDate start date of the journey
 * @param organizationId id of the organization
 * @param outlookTenantId id of the organization tenant
 */
export const scheduleJourneyMeetings = async (
  meetings: Meeting[],
  journeyStartDate: string,
  organizationId: string,
  tenantId?: string | null,
): Promise<any> => {
  try {
    await putApiData(
      'organization',
      `${API_BASE_URL}/journey/schedule-meeting`,
      {
        journeyStartDate,
        meetings,
        organizationId,
        tenantId,
      },
    );
  } catch (err: any) {
    // if (!err.message.includes(TIME_OUT_MESSAGE)) {
    //   addNotificationToApp('Failed to schedule the journey meeting', 'error');
    // }
  }
};

/**
 * list current user's assigned journeys
 *
 * @param userId string
 */
export const listAssignedJourneys = async (
  userId: string,
): Promise<Journey[] | undefined> => {
  try {
    if (userId) {
      const apiData: any = await getGraphQlData(listUserJourneys, {
        filter: {
          assignedUserID: { eq: userId },
          isArchived: { eq: false },
          status: { ne: JourneyStatus.DRAFT },
          mode: { ne: PlaybookMode.WORKFLOW },
        },
        limit: QUERY_LIMIT,
      });

      return apiData.data.listJourneys.items;
    }
  } catch (err) {
    addNotificationToApp('Failed to list user assigned journeys', 'error');
  }
};

/**
 * list journeys on which current user is collaborating on
 *
 * @param userId string
 */
export const listCollaboratingJourneys = async (
  userId: string,
): Promise<Journey[] | undefined> => {
  try {
    if (userId) {
      const apiData: any = await getGraphQlData(listUserJourneys, {
        filter: {
          collaborator: { contains: userId },
          isArchived: { eq: false },
          status: { ne: JourneyStatus.DRAFT },
        },
        limit: QUERY_LIMIT,
      });

      return apiData.data.listJourneys.items;
    }
  } catch (err) {
    addNotificationToApp('Failed to list user collaborating journeys', 'error');
  }
};

/**
 * get journey data
 *
 * @param id journey id
 * @param organizationId user organization id
 */
export const getJourneyData = async (
  id: string,
  organizationId: string,
): Promise<Journey | undefined> => {
  try {
    const apiData: any = await getGraphQlData(listUserJourneys, {
      filter: {
        id: { eq: id },
        // organizationID: { eq: organizationId },
        isArchived: { eq: false },
      },
      limit: QUERY_LIMIT,
    });

    if (!apiData.data.listJourneys.items.length) {
      addNotificationToApp('Journey not found.', 'info');
      return;
    }
    return apiData.data.listJourneys.items[0];
  } catch (err) {
    addNotificationToApp('Failed to get journey', 'error');
  }
};

export const inviteNewEmployee = async (
  organizationId: string,
  user: InviteNewEmployee,
): Promise<void> => {
  try {
    await postApiData(
      'organization',
      `${API_BASE_URL}/${organizationId}/users/invite`,
      user,
    );
    addNotificationToApp('Invitation sent successfully', 'success');
  } catch (err) {
    addNotificationToApp('Failed to invite users', 'error');
  }
};

export const inviteExistingEmployee = async (
  organizationId: string,
  user: InviteExistingEmployee,
): Promise<void> => {
  try {
    await postApiData(
      'organization',
      `${API_BASE_URL}/${organizationId}/users/invite`,
      user,
    );
    addNotificationToApp('Invitation sent successfully', 'success');
  } catch (err) {
    addNotificationToApp('Failed to invite users', 'error');
  }
};

/**
 * update work location
 *
 * @param id block id
 * @param city string
 */
export const updateWorkLocation = async (
  id: string,
  city?: string | null,
): Promise<string | undefined> => {
  try {
    const apiData: any = await getGraphQlData(updateLocationWithIdReturn, {
      input: {
        id: id,
        city: city?.trim(),
      },
    });
    return apiData.data.updateLocation.id;
  } catch (err) {
    addNotificationToApp('Failed to update location', 'error');
  }
};

/**
 * Add new secret Manager
 *
 * @param user to be added as a secret manager
 * @param secretKeys secret manager keys
 */
export const addNewSecretKeyManager = async (
  user: User,
  secretKeys: SecretKeyManager,
): Promise<SecretKeyManager | undefined> => {
  try {
    const apiData = await getGraphQlData(createSecretKeyManager, {
      input: {
        organizationID: user.userOrganizationId,
        clientEmail: secretKeys.clientEmail.trim(),
        privateKey: secretKeys.privateKey.trim(),
        adminEmail: user.email.trim(),
      },
    });

    if (apiData.data.createSecretKeyManager) {
      addNotificationToApp('Successfully added the secrets', 'success');
    }
    return apiData.data.createSecretKeyManager;
  } catch (err) {
    addNotificationToApp('Failed to save secrets', 'error');
  }
};

/**
 * Get organization secret Manager
 *
 * @param organizationId id of the organization
 */
export const getSecretKeyManagerData = async (
  organizationId: string,
): Promise<SecretKeyManager | undefined> => {
  try {
    const apiData: any = await getGraphQlData(listSecretKeyManagersWithId, {
      filter: {
        organizationID: { eq: organizationId },
      } as ModelSecretKeyManagerFilterInput,
      limit: QUERY_LIMIT,
    });
    return apiData.data.listSecretKeyManagers.items[0];
  } catch (err) {
    addNotificationToApp('Failed to get organizational secrets', 'error');
  }
};

/**
 * update SecretManagerData
 *
 * @param user
 * @param secretKeys seceteKeys
 */
export const updateSecretKeyManagerData = async (
  secretKeys: SecretKeyManager,
): Promise<SecretKeyManager | undefined> => {
  try {
    const apiData: any = await getGraphQlData(updateSecretKeyManagerDetails, {
      input: {
        id: secretKeys.id,
        clientEmail: secretKeys.clientEmail.trim(),
        privateKey: secretKeys.privateKey.trim(),
      },
    });

    if (apiData.data.updateSecretKeyManager) {
      addNotificationToApp('Successfully updated the secrets', 'success');
    }
    return apiData.data.updateSecretKeyManager;
  } catch (err) {
    addNotificationToApp('Failed to update the google secrets', 'error');
  }
};

/**
 * list child journeys
 *
 * @param parentJourneyId string
 */
export const listChildJourneys = async (
  parentJourneyId: string,
): Promise<Journey[] | undefined> => {
  try {
    const apiData: any = await getGraphQlData(listDetailedJourneys, {
      filter: {
        parentJourneyID: { eq: parentJourneyId },
        isArchived: { eq: false },
      },
      limit: QUERY_LIMIT,
    });

    return apiData.data.listJourneys.items;
  } catch (err) {
    addNotificationToApp('Failed to get child journeys', 'error');
  }
};

/**
 * get user by id
 *
 * @param userId
 */
export const getUserBasicData = async (
  userId: string,
): Promise<User | undefined> => {
  try {
    const apiData: any = await getGraphQlData(getUserWithBasicData, {
      id: userId,
    });

    return apiData.data.getUser;
  } catch (err) {
    console.error('[api.tsx][getUserBasicData] unable to get user basic data');
    addNotificationToApp('Failed to get user by id', 'error');
  }
};

/**
 * list company admins of organization
 *
 * @param organizationId string
 */
export const listCompanyAdminsData = async (
  organizationId: string,
): Promise<User[] | undefined> => {
  try {
    const apiData: any = await getGraphQlData(listDetailedUsersWithNameAndId, {
      filter: {
        type: { eq: UserType.COMPANY_ADMIN },
        userOrganizationId: { eq: organizationId },
      },
      limit: QUERY_LIMIT,
    });
    return apiData.data.listUsers.items;
  } catch (err) {
    addNotificationToApp('Failed to get list of organization Admin', 'error');
  }
};

/**
 * delete standard content block
 *
 * @param id block id
 */
export const deleteBlock = async (
  id: string,
  type: BlockType,
): Promise<string | undefined> => {
  try {
    if (type === BlockType.StandardContentBlock) {
      const apiData = await getGraphQlData(deleteStandardContentBlock, {
        input: {
          id: id,
        },
      });

      return apiData.data.deleteStandardContentBlock.id;
    } else if (type === BlockType.FreeTextQuestionBlock) {
      const apiDate = await getGraphQlData(deleteFreeTextQuestionBlock, {
        input: {
          id: id,
        },
      });

      return apiDate.data.deleteFreeTextQuestionBlock.id;
    } else if (type === BlockType.MultipleChoiceQuestionBlock) {
      const apiData = await getGraphQlData(deleteMultipleChoiceQuestionBlock, {
        input: {
          id: id,
        },
      });

      return apiData.data.deleteMultipleChoiceQuestionBlock.id;
    } else if (type === BlockType.TaskBlock) {
      const apiData = await getGraphQlData(deleteTaskBlock, {
        input: {
          id: id,
        },
      });

      return apiData.data.deleteTaskBlock.id;
    } else if (type === BlockType.NoteBlock) {
      const apiData = await getGraphQlData(deleteNoteBlock, {
        input: {
          id: id,
        },
      });

      return apiData.data.deleteNoteBlock.id;
    } else if (type === BlockType.EmbeddedContentBlock) {
      const apiData = await getGraphQlData(deleteEmbeddedContentBlock, {
        input: {
          id: id,
        },
      });

      return apiData.data.deleteEmbeddedContentBlock.id;
    }
  } catch (err) {
    addNotificationToApp('Failed to delete block', 'error');
  }
};

/**
 * update block's order no after deletion
 *
 * @param milestone who's block's order needs to be updated
 * @param deletedBlockOrderNo id of the block which is deleted
 */
export const updateBlocksOrderNo = async (
  milestone: Milestone,
  deletedBlockOrderNo: number,
): Promise<void> => {
  try {
    await postApiData(
      'organization',
      `${API_BASE_URL}/journey/milestone/blocks/reorder/${deletedBlockOrderNo}`,
      { milestone },
    );
  } catch (err) {
    addNotificationToApp('Failed to update blocks order', 'error');
  }
};

/**
 * update user status
 *
 * @param user to be updated
 */
export const updateUserStatus = async (
  user: User,
): Promise<string | undefined> => {
  try {
    const apiData = await getGraphQlData(updateUserWithIdReturn, {
      input: {
        id: user.id,
        isActive: user.isActive,
        isFirstLogin: user.isFirstLogin,
      },
    });

    return apiData.data.updateUser.id;
  } catch (err) {
    addNotificationToApp('Failed to update user status', 'error');
  }
};

/**
 * delete journey milestone
 *
 * @param milestoneId string
 */
export const deleteJourneyMilestone = async (
  milestoneId: string,
): Promise<string | undefined> => {
  try {
    const apiData = await getGraphQlData(deleteMilestoneWithIdReturn, {
      input: {
        id: milestoneId,
      },
    });
    return apiData.data.deleteMilestone.id;
  } catch (err) {
    addNotificationToApp('Failed to delete milestone', 'error');
  }
};

/**
 * delete milestone blocks
 *
 * @param milestoneId string
 */
export const deleteMilestoneBlocks = async (
  milestoneId: string,
): Promise<void> => {
  try {
    await deleteApiData(
      'organization',
      `${API_BASE_URL}/journey/milestone/${milestoneId}`,
      {},
    );
  } catch (err) {
    console.error(
      '[api][deleteMilestoneBlocks] unable to delete milestone blocks',
    );
  }
};

/**
 * create copy Journey
 *
 * @param journeyName string
 * @param journeyName string
 * @param organizationId string
 */
export const createCopyJourney = async (
  journeyName: string,
  journeyId: string,
): Promise<string | undefined> => {
  try {
    const apiData = await postApiData(
      'organization',
      `${API_BASE_URL}//journey/copy`,
      {
        journeyId,
        journeyName: journeyName.trim(),
      },
    );

    return apiData.id;
  } catch (err) {
    addNotificationToApp('Failed to copy journey', 'error');
  }
};

/**
 * isOrganizationUser
 *
 * @param emailDomain string
 */
export const isOrganizationUser = async (
  emailDomain: string,
): Promise<User | undefined> => {
  try {
    const apiData = await getGraphQlData(listDetailedUsersWithNameAndId, {
      filter: {
        email: { contains: emailDomain },
        type: { eq: UserType.COMPANY_ADMIN },
      },
      limit: QUERY_LIMIT,
    });

    const user = apiData.data.listUsers.items.length
      ? apiData.data.listUsers.items[0]
      : undefined;
    return user;
  } catch (err) {
    addNotificationToApp('Failed to get user information', 'error');
  }
};

/**
 * create organization user
 *
 * @param organizationId string
 * @param userEmail string
 */
export const createOrganizationUser = async (
  organizationId: string,
  userEmail: string,
): Promise<boolean | undefined> => {
  try {
    await postApiData(
      'organization',
      `${API_BASE_URL}/${organizationId}/user`,
      {
        workEmail: userEmail.trim(),
      },
    );

    return true;
  } catch (err) {
    console.error(
      '[apis][createOrganizationUser] unable to create new user',
      err,
    );
    addNotificationToApp('Failed to create new user', 'error');
  }
};

/**
 * update embedded content block
 *
 * @param block EmbeddedContentBlock
 */
export const updateEmbeddedContentBlock = async (
  block: EmbeddedContentBlock,
): Promise<string | undefined> => {
  try {
    const apiData: any = await getGraphQlData(
      updateEmbeddedContentBlockWithIdReturn,
      {
        input: {
          ...block,
          title: block.title.trim(),
          description: block.description?.trim(),
        },
      },
    );
    return apiData.data.updateEmbeddedContentBlock.id;
  } catch (err) {
    addNotificationToApp('Failed to update embedded content block', 'error');
  }
};

/**
 * update journey
 *
 * @param journey to be updated
 */
export const updateJourney = async (
  journey: Journey,
): Promise<string | undefined> => {
  try {
    const res = await getGraphQlData(updateJourneyWithIdReturn, {
      input: {
        id: journey.id,
        assignedUserID: journey.assignedUserID,
        journeyProgress: journey.journeyProgress,
        name: journey.name?.trim(),
        lockChapters: journey.lockChapters,
        parentJourneyID: journey.parentJourneyID,
        badgeID: journey.badgeID,
        customBadgeName: journey.customBadgeName,
        startDate: journey.startDate,
        status: journey.status,
        publicLink: journey.publicLink,
        isArchived: journey.isArchived,
        userStartedAt: journey.userStartedAt,
        type: journey.type,
        mode: journey.mode,
        userCompletedAt: journey.userCompletedAt,
      },
    });

    return res.data.updateJourney.id;
  } catch (err) {
    addNotificationToApp('Failed to update runbook', 'error');
  }
};

/**
 * delete meeting
 *
 * @param meetingId string
 */
export const deleteMeeting = async (
  meetingId: string,
): Promise<string | undefined> => {
  try {
    const apiData = await getGraphQlData(deleteMeetingWithIdAndReturn, {
      input: {
        id: meetingId,
      },
    });
    return apiData.data.deleteMeeting.id;
  } catch (err) {
    addNotificationToApp('Failed to delete meeting', 'error');
  }
};

/**
 * update event and role assignee
 *
 * @param event Event

 */
export const updateEventAndRoleAssignee = async (
  event: Event,
): Promise<string | undefined> => {
  try {
    let filter = {
      assigneeRole: { eq: event.role },
    } as ModelJourneyCollaboratorFilterInput;
    if (event.kinfolkTemplateID) {
      filter = {
        ...filter,
        kinfolkTemplateID: { eq: event.kinfolkTemplateID },
      };
    } else {
      filter = { ...filter, journeyID: { eq: event.journeyID } };
    }

    const collaborator = await fetchCollaborator(filter);
    if (collaborator) {
      const id = await updatePlaybookEvent({
        ...event,
        role: collaborator.assigneeRole,
        userId: collaborator.assignedUserID,
      });
      return id;
    }
  } catch (err) {
    addNotificationToApp('Failed to update event', 'error');
  }
};

/**
 * update user details
 *
 * @param user User
 */
export const updateUserDetails = async (
  user: User,
): Promise<string | undefined> => {
  try {
    const apiData = await getGraphQlData(updateUserWithIdReturn, {
      input: {
        id: user.id,
        personalEmail: user.personalEmail !== '' ? user.personalEmail : null,
        email: user.email.trim(),
        firstName: user.firstName?.trim(),
        lastName: user.lastName?.trim(),
        startDate:
          user.startDate && user.startDate !== '' ? user.startDate : null,
        jobTitle: user.jobTitle?.trim(),
        team: user.team?.trim(),
        type: user.type,
      },
    });

    return apiData.data.updateUser.id;
  } catch (err) {
    addNotificationToApp('Failed to update user details', 'error');
  }
};

/**
 * listPublishedChildJourneys
 *
 * @param parentJourneyId string
 */
export const listPublishedChildJourneys = async (
  parentJourneyId: string,
): Promise<Journey[] | undefined> => {
  try {
    const apiData: any = await getGraphQlData(listUserJourneys, {
      filter: {
        parentJourneyID: { eq: parentJourneyId },
        isArchived: { eq: false },
        status: { ne: JourneyStatus.DRAFT },
      },
      limit: QUERY_LIMIT,
    });
    return apiData.data.listJourneys.items;
  } catch (err) {
    addNotificationToApp('Failed to list published journeys', 'error');
  }
};

/**
 * update user type
 *
 * @param userId string
 * @param UserType UserType
 */
export const updateUserType = async (
  userId: string,
  userType: string,
): Promise<string | undefined> => {
  try {
    const apiData = await getGraphQlData(updateUserWithIdAndEmailReturn, {
      input: {
        id: userId,
        type: userType,
      },
    });
    return apiData.data.updateUser.email;
  } catch (err) {
    addNotificationToApp('Failed to update user type', 'error');
  }
};

/**
 * remove organization slack tokens
 *
 * @param organizationId string
 */
export const removeOrganizationSlackTokens = async (
  organizationId: string,
): Promise<void> => {
  try {
    await getGraphQlData(updateOrganizationWithIdReturn, {
      input: {
        id: organizationId,
        slackAccessToken: null,
        slackUserAccessToken: null,
      },
    });
  } catch (err) {
    addNotificationToApp('Failed to remove Slack integration', 'error');
  }
};

/**
 * remove organization slack tokens
 *
 * @param organizationId string
 */
export const removeOrganizationMsTeamsTenantId = async (
  organizationId: string,
): Promise<void> => {
  try {
    await getGraphQlData(updateOrganizationWithIdReturn, {
      input: {
        id: organizationId,
        msTeamsTenantId: null,
      },
    });
  } catch (err) {
    addNotificationToApp('Failed to remove MS Teams integration', 'error');
  }
};

/**
 * update embedded content block resource link
 *
 * @param id block id
 * @param updatedResourceLink string
 */
export const updateEmbeddedContentBlockLink = async (
  id: string,
  updatedResourceLink: string,
): Promise<string | undefined> => {
  try {
    const apiData: any = await getGraphQlData(
      updateEmbeddedContentBlockWithIdReturn,
      {
        input: {
          id: id,
          resourceLink: updatedResourceLink.trim(),
        },
      },
    );
    return apiData.data.updateEmbeddedContentBlock.id;
  } catch (err) {
    addNotificationToApp(
      'Failed to update embedded content block link',
      'error',
    );
  }
};

/**
 * update block order no
 *
 * @param id block id
 * @param orderNo number
 * @param type block type
 * @param updatedInChild true if block is in child playbook, false otherwise
 */
export const updateBlockOrderNo = async (
  id: string,
  orderNo: number,
  type: BlockType,
  updatedInChild: boolean,
): Promise<void> => {
  try {
    if (type === BlockType.StandardContentBlock) {
      await getGraphQlData(updateStandardContentBlockWithIdReturn, {
        input: {
          id: id,
          orderNo,
          updatedInChild,
        },
      });
    } else if (type === BlockType.FreeTextQuestionBlock) {
      await getGraphQlData(updateFreeTextQuestionBlockWithIdReturn, {
        input: {
          id: id,
          orderNo,
          updatedInChild,
        },
      });
    } else if (type === BlockType.MultipleChoiceQuestionBlock) {
      await getGraphQlData(updateMultipleChoiceQuestionBlockWithIdReturn, {
        input: {
          id: id,
          orderNo,
          updatedInChild,
        },
      });
    } else if (type === BlockType.TaskBlock) {
      await getGraphQlData(updateTaskBlockWithIdReturn, {
        input: {
          id: id,
          orderNo,
          updatedInChild,
        },
      });
    } else if (type === BlockType.NoteBlock) {
      await getGraphQlData(updateNoteBlockWithIdReturn, {
        input: {
          id: id,
          orderNo,
          updatedInChild,
        },
      });
    } else if (type === BlockType.EmbeddedContentBlock) {
      await getGraphQlData(updateEmbeddedContentBlockWithIdReturn, {
        input: {
          id: id,
          orderNo,
          updatedInChild,
        },
      });
    }
  } catch (err) {
    addNotificationToApp('Failed to update block order', 'error');
  }
};

/**
 * update block's order no after drag and drop
 *
 * @param milestone Milestone
 * @param droppedBlockId id of the block which was dragged and dropped
 * @param source index of the block which was dragged and dropped
 * @param destination position where the block was dropped
 */
export const reorderBlocks = async (
  milestone: Milestone,
  droppedBlockId: string,
  source: number,
  destination: number,
): Promise<void> => {
  try {
    await postApiData(
      'organization',
      `${API_BASE_URL}/journey/milestone/block/${droppedBlockId}/reorder/${source}/${destination}`,
      {
        milestone,
      },
    );
  } catch (err) {
    addNotificationToApp('Failed to update blocks order', 'error');
  }
};

/**
 * update slack id of the newly joined user
 *
 * @param organizationId of the user
 * @param userId of the user
 * @param workEmail of the user
 */
export const updateUserSlackId = async (
  organizationId: string,
  userId: string,
  workEmail: string,
): Promise<void> => {
  try {
    await putApiData(
      'organization',
      `${API_BASE_URL}/${organizationId}/user/${userId}/slack`,
      {
        workEmail,
      },
    );
  } catch (err) {
    /* Failed to update user slack id */
  }
};

/**
 * update milestone order number
 *
 * @param id string
 * @param orderNumber number
 */
export const updateMilestoneOrder = async (
  id: string,
  orderNumber: number,
): Promise<void> => {
  try {
    const apiData: any = await getGraphQlData(updateMilestoneWithIdReturn, {
      input: {
        id: id,
        orderNo: orderNumber,
      },
    });
    return apiData.data.updateMilestone.id;
  } catch (err) {
    addNotificationToApp('Failed to update milestone order number', 'error');
  }
};

/**
 * search all users who's status is active
 *
 * @param organizationId string
 * @param query string
 */
export const searchAllActiveUsers = async (
  organizationId: string,
  query: string,
): Promise<User[] | undefined> => {
  try {
    const apiData: any = await getGraphQlData(listDetailedUsersWithNameAndId, {
      filter: {
        or: [
          { firstName: { contains: query } },
          { lastName: { contains: query } },
          { email: { contains: query } },
          {
            firstName: {
              contains: query.charAt(0).toUpperCase() + query.slice(1),
            },
          },
          {
            lastName: {
              contains: query.charAt(0).toUpperCase() + query.slice(1),
            },
          },
          {
            email: {
              contains: query.charAt(0).toUpperCase() + query.slice(1),
            },
          },
          {
            firstName: {
              contains: query.charAt(0).toLowerCase() + query.slice(1),
            },
          },
          {
            lastName: {
              contains: query.charAt(0).toLowerCase() + query.slice(1),
            },
          },
          {
            email: {
              contains: query.charAt(0).toLowerCase() + query.slice(1),
            },
          },
        ],
        userOrganizationId: { eq: organizationId },
        type: { ne: UserType.SUPER_ADMIN },
        isActive: { eq: true },
      },
      limit: QUERY_LIMIT,
    });

    return apiData.data.listUsers.items;
  } catch (err) {
    addNotificationToApp('Failed to get searched user data', 'error');
  }
};

/**
 * list journey collaborators by journey id
 *
 * @param journeyId journey id
 * @param filter filter journey collaborators
 * @returns Array of collaborators
 */
export const getJourneyCollaboratorsByJourneyId = async (
  journeyId: string,
  filter: ModelJourneyCollaboratorFilterInput = {},
): Promise<JourneyCollaborator[] | undefined> => {
  try {
    const res = await getGraphQlData(listJourneyCollaboratorByJourneyId, {
      filter,
      journeyID: journeyId,
      limit: QUERY_LIMIT,
    });

    const collaborators = res.data.listJourneyCollaboratorByJourneyId.items;
    return collaborators;
  } catch (error) {
    addNotificationToApp('Failed to get journey collaborator by id', 'error');
  }
};

/**
 * list journey collaborators by kinfolk template id
 *
 * @param templateId template id
 * @param filter filter journey collaborators
 * @returns Array of collaborators
 */
export const getJourneyCollaboratorsByKinfolkTemplateId = async (
  templateId: string,
  filter: ModelJourneyCollaboratorFilterInput = {},
): Promise<JourneyCollaborator[] | undefined> => {
  try {
    const res = await getGraphQlData(
      listJourneyCollaboratorByKinfolkTemplateId,
      {
        filter,
        kinfolkTemplateID: templateId,
        limit: QUERY_LIMIT,
      },
    );

    const collaborators =
      res.data.listJourneyCollaboratorByKinfolkTemplateId.items;
    return collaborators;
  } catch (error) {
    addNotificationToApp('Failed to get template collaborator by id', 'error');
  }
};

/**
 * create collaborator
 *
 * @param collaborator Collaborator
 */
export const createCollaborator = async (collaborator: {
  builderId: string;
  role: AssigneeRole;
  isTemplate: boolean;
  actionId?: string;
  meetingId?: string;
}): Promise<
  { id: string; assigneeId?: string; name?: string; email?: string } | undefined
> => {
  try {
    const { actionId, meetingId, builderId, role, isTemplate } = collaborator;
    let filters = { assigneeRole: { eq: role } };
    let collaborators;

    if (isTemplate) {
      collaborators = await getJourneyCollaboratorsByKinfolkTemplateId(
        builderId,
        filters,
      );
    } else {
      collaborators = await getJourneyCollaboratorsByJourneyId(
        builderId,
        filters,
      );
    }

    if (!collaborators || !collaborators.length) {
      return;
    }

    let input = {};

    if (actionId) {
      const previousActionIds = collaborators[0].actionID;
      input = {
        ...input,
        id: collaborators[0].id,
        actionID: [...new Set(previousActionIds || []), actionId],
      };
    } else if (meetingId) {
      const previousMeetingIds = collaborators[0].meetingID;
      input = {
        ...input,
        id: collaborators[0].id,
        meetingID: [...new Set(previousMeetingIds || []), meetingId],
      };
    }
    const updatedJourneyCollaboratorData = await getGraphQlData(
      updateJourneyCollaboratorData,
      {
        input,
      },
    );

    const updatedCollaborator = updatedJourneyCollaboratorData.data
      .updateJourneyCollaborator as JourneyCollaborator;
    let collaboratorUser;
    if (updatedCollaborator.assignedUserID) {
      collaboratorUser = await getUserBasicData(
        updatedCollaborator.assignedUserID,
      );
    }
    return {
      id: updatedCollaborator.id,
      assigneeId: collaboratorUser?.id,
      email: collaboratorUser?.email,
      name: collaboratorUser?.firstName
        ? `${collaboratorUser.firstName} ${collaboratorUser.lastName ?? ''}`
        : '',
    };
  } catch (err) {
    addNotificationToApp('Failed to get journey collaborators', 'error');
  }
};

/**
 * get journey collaborators
 *
 * @param builderId of the builder
 * @param isTemplate is template
 */
export const getJourneyCollaborators = async (
  builderId: string,
  isTemplate: boolean,
): Promise<CustomPlaybookCollaborator[] | undefined> => {
  try {
    let collaborators;
    if (isTemplate) {
      collaborators =
        await getJourneyCollaboratorsByKinfolkTemplateId(builderId);
    } else {
      collaborators = await getJourneyCollaboratorsByJourneyId(builderId);
    }

    if (!collaborators) {
      return;
    }

    let playbookCollaborators = [];
    for (const collaborator of collaborators) {
      let collaboratorUser;
      if (collaborator.assignedUserID) {
        collaboratorUser = await getUserBasicData(collaborator.assignedUserID);
      }

      playbookCollaborators.push({
        ...collaborator,
        assignedUserName: collaboratorUser?.firstName
          ? `${collaboratorUser.firstName} ${collaboratorUser.lastName ?? ''}`
          : undefined,
        assignedUserEmail: collaboratorUser?.email,
      });
    }

    return playbookCollaborators;
  } catch (err) {
    console.error(
      '[api][getJourneyCollaborators] unable to get journey collaborators data',
      err,
    );
    addNotificationToApp('Failed to get journey collaborators', 'error');
  }
};

/**
 * update journey collaborator assignee
 *
 * @param collaboratorId string
 * @param assigneeId string
 * @param assigneeName string
 * @param assigneeEmail: string
 * @param updatedInChild true if updated in child playbook
 */
export const updateJourneyCollaboratorAssignee = async (
  collaboratorId: string,
  assigneeId: string | null,
  updatedInChild: boolean,
): Promise<string | undefined> => {
  try {
    const apiData = await getGraphQlData(updateJourneyCollaboratorData, {
      input: {
        id: collaboratorId,
        assignedUserID: assigneeId,
        updatedInChild,
      },
    });

    return apiData.data.updateJourneyCollaborator.id;
  } catch (err) {
    console.error(
      '[api][updateJourneyCollaboratorAssignee] unable to update journey collaborator assignee',
      err,
    );
    addNotificationToApp('Failed to update journey collaborators', 'error');
  }
};

/**
 * create journey collaborator
 *
 * @param collaborator JourneyCollaborator
 */
export const createJourneyCollaborator = async (
  collaborator: JourneyCollaborator,
): Promise<string | undefined> => {
  try {
    const apiData = await getGraphQlData(
      createJourneyCollaboratorWithIdReturn,
      {
        input: {
          assignedUserID: collaborator.assignedUserID,
          journeyID: collaborator.journeyID,
          kinfolkTemplateID: collaborator.kinfolkTemplateID,
          assigneeRole: collaborator.assigneeRole,
          journeyAccess: JourneyAccess.VIEW_ONLY,
        },
      },
    );

    return apiData.data.createJourneyCollaborator.id;
  } catch (err) {
    console.error(
      '[api][createJourneyCollaborator] unable to create journey collaborator',
      err,
    );
    addNotificationToApp('Failed to create journey collaborators', 'error');
  }
};

/**
 * create action collaborators
 *
 * @param journeyIds
 */
export const createActionCollaborator = async (
  journeyIds: string[],
): Promise<void> => {
  try {
    await putApiData('organization', `${API_BASE_URL}/journey/collaborator`, {
      journeyIds,
    });
  } catch (err: any) {
    console.error(err.message);
    addNotificationToApp('Failed to create action collaborator', 'error');
  }
};

/**
 * update collaborator roles with assignee in events
 *
 * @param id of the builder
 * @param isTemplate boolean
 */
export const updateCollaboratorAssigneeInEvents = async (
  id: string,
  isTemplate: boolean,
): Promise<void> => {
  try {
    await putApiData(
      'organization',
      `${API_BASE_URL}/journey/collaborator/${id}`,
      { isTemplate },
    );
  } catch (err) {
    addNotificationToApp('Failed to update event collaborator', 'error');
  }
};

/**
 * list collaborating journey
 *
 * @param journeyId string
 */
export const fetchCollaboratingJourney = async (
  journeyId: string,
): Promise<Journey> => {
  try {
    const apiData: any = await getGraphQlData(listUserJourneys, {
      filter: {
        id: { eq: journeyId },
        status: { ne: JourneyStatus.DRAFT },
        isArchived: { eq: false },
        parentJourneyID: { attributeExists: true },
      },
      limit: QUERY_LIMIT,
    });

    const journeys = apiData.data.listJourneys.items;
    if (journeys && journeys.length > 0) {
      return apiData.data.listJourneys.items[0];
    }

    return {} as Journey;
  } catch (err) {
    addNotificationToApp('Failed to list user collaborating journey', 'error');
    return {} as Journey;
  }
};

/**
 * list journey where user is collaborating on
 *
 * @param userId string
 */
export const fetchUserCollaboratingJourney = async (
  userId: string,
): Promise<Journey[] | undefined> => {
  try {
    const apiData = await getGraphQlData(listJourneyCollaborators, {
      filter: {
        assignedUserID: { eq: userId },
      },
      limit: QUERY_LIMIT,
    });

    const collaborators: JourneyCollaborator[] =
      apiData.data.listJourneyCollaborators.items;
    return (
      await Promise.all(
        collaborators
          .filter((collaborator) => collaborator.journeyID)
          .map((filteredCollaborator) =>
            //@ts-ignore
            fetchCollaboratingJourney(filteredCollaborator.journeyID),
          ),
      )
    ).filter((journey) => journey && journey.id);
  } catch (err) {
    addNotificationToApp(
      'Failed to fetch user collaborating journeys',
      'error',
    );
  }
};

/**
 * is journey collaborator
 *
 * @param userId string
 * @param journeyId string
 */
export const isJourneyCollaborator = async (
  userId: string,
  journeyId: string,
): Promise<boolean | undefined> => {
  try {
    const apiData: any = await getGraphQlData(listJourneyCollaborators, {
      filter: {
        assignedUserID: { eq: userId },
        journeyID: { eq: journeyId },
      },
      limit: QUERY_LIMIT,
    });

    const collaborators = apiData.data.listJourneyCollaborators.items;
    return collaborators && collaborators.length > 0;
  } catch (err) {
    addNotificationToApp('Failed to get journey collaborators', 'error');
  }
};

/**
 * fetch collaborator
 *
 * @param filter any
 */
export const fetchCollaborator = async (
  filter: any,
): Promise<JourneyCollaborator | undefined> => {
  try {
    const apiData: any = await getGraphQlData(listJourneyCollaborators, {
      filter,
      limit: QUERY_LIMIT,
    });

    const collaborators = apiData.data.listJourneyCollaborators.items;
    return collaborators && collaborators.length > 0 && collaborators[0];
  } catch (err) {
    addNotificationToApp('Failed to get collaborator', 'error');
  }
};

/**
 * update meeting and role assignee
 *
 * @param meeting Action

 */
export const updateMeetingAndRoleAssignee = async (
  meeting: Meeting,
): Promise<string | undefined> => {
  try {
    let filter = {
      assigneeRole: { eq: meeting.assigneeRole },
    } as ModelJourneyCollaboratorFilterInput;
    if (meeting.kinfolkTemplateID) {
      filter = {
        ...filter,
        kinfolkTemplateID: { eq: meeting.kinfolkTemplateID },
      };
    } else {
      filter = { ...filter, journeyID: { eq: meeting.journeyID } };
    }

    const collaborator = await fetchCollaborator(filter);
    if (!collaborator) {
      return;
    }

    let collaboratorUser;
    if (collaborator.assignedUserID) {
      collaboratorUser = await getUserBasicData(collaborator.assignedUserID);
    }

    const apiData = await getGraphQlData(updateMeetingWithIdReturn, {
      input: {
        id: meeting.id,
        title: meeting.title.trim(),
        description: meeting.description?.trim(),
        duration: meeting.duration.trim(),
        startTime: meeting.startTime,
        endTime: meeting.endTime,
        attendeesEmail: collaboratorUser ? [collaboratorUser.email] : [],
        status: meeting.status,
        assigneeRole: meeting.assigneeRole,
        updatedInChild: meeting.updatedInChild,
      },
    });
    return apiData.data.updateMeeting.id;
  } catch (err) {
    addNotificationToApp('Failed to update meeting', 'error');
  }
};

/**
 * get kinfolk templates
 *
 * @param filter templates
 *
 */
export const getKinfolkTemplates = async (filter?: {
  filter: any;
}): Promise<KinfolkTemplate[] | undefined> => {
  try {
    const apiData: any = await getGraphQlData(listKinfolkTemplates, {
      filter: filter ? filter.filter : {},
      limit: QUERY_LIMIT,
    });

    return apiData.data.listKinfolkTemplates.items;
  } catch (err) {
    addNotificationToApp('Failed to fetch templates', 'error');
  }
};

/**
 * get kinfolk template
 *
 * @param id of the template
 */
export const fetchKinfolkTemplate = async (
  id: string,
): Promise<KinfolkTemplate | undefined> => {
  try {
    const apiData: any = await getGraphQlData(getKinfolkTemplate, { id });

    return apiData.data.getKinfolkTemplate;
  } catch (err) {
    addNotificationToApp('Failed to fetch template', 'error');
  }
};

/**
 * create kinfolk template
 *
 * @param template to be created
 *
 */
export const createKinfolkTemplate = async (
  template: KinfolkTemplate,
): Promise<string | undefined> => {
  try {
    const apiData: any = await getGraphQlData(
      createKinfolkTemplateWithIdReturn,
      {
        input: {
          name: template.name.trim(),
          description: template.description.trim(),
          image: template.image,
          type: template.type,
          mode: template.mode,
          isPublic: template.isPublic,
          status: TemplateStatus.DRAFT,
        },
      },
    );

    return apiData.data.createKinfolkTemplate.id;
  } catch (err) {
    addNotificationToApp('Failed to create template', 'error');
  }
};

/**
 * update kinfolk template
 *
 * @param template to be updated
 *
 */
export const updateKinfolkTemplate = async (
  template: KinfolkTemplate,
): Promise<string | undefined> => {
  try {
    const apiData: any = await getGraphQlData(
      updateKinfolkTemplateWithIdReturn,
      {
        input: {
          id: template.id,
          name: template.name.trim(),
          isPublic: template.isPublic,
          status: template.status,
        },
      },
    );

    return apiData.data.updateKinfolkTemplate.id;
  } catch (err) {
    addNotificationToApp('Failed to update template', 'error');
  }
};

/**
 * fetch preboarding users
 *
 * @param filter to filter out journeys
 */
export const fetchPreboardingUsers = async (
  filter: ModelPreboardingUserFilterInput,
): Promise<PreboardingUser[] | undefined> => {
  try {
    const res = await getGraphQlData(listPreboardingUsersWithRelations, {
      filter: {
        ...filter,
      },
      limit: QUERY_LIMIT,
    });

    return res.data.listPreboardingUsers.items;
  } catch (err) {
    addNotificationToApp('Failed to fetch preboarding users', 'error');
  }
};

/**
 * fetch preboarding user relations
 *
 * @param filter to filter out journeys
 */
export const fetchPreboardingUserRelations = async (
  filter: any,
): Promise<PreboardingUserRelation[] | undefined> => {
  try {
    const res = await getGraphQlData(listPreboardingUserRelations, {
      filter: {
        ...filter,
      },
      limit: QUERY_LIMIT,
    });

    return res.data.listPreboardingUserRelations.items;
  } catch (err) {
    addNotificationToApp('Failed to fetch preboarding user relations', 'error');
  }
};

/**
 * update child journeys
 *
 * @param journeyId
 */
export const updateChildJourneys = async (journeyId: string): Promise<void> => {
  try {
    await putApiData(
      'organization',
      `${API_BASE_URL}/journey/child-journeys/${journeyId}`,
      {},
    );
  } catch (err) {
    addNotificationToApp('Failed to update journeys', 'error');
  }
};

/**
 * create badge
 *
 * @param badge to be created
 *
 */
export const createBadge = async (
  badge: Badge,
): Promise<string | undefined> => {
  try {
    const apiData = await getGraphQlData(createBadgeMutation, {
      input: badge,
    });

    return apiData.data.createBadge.id;
  } catch (err) {
    addNotificationToApp('Failed to create badge', 'error');
  }
};

/**
 * list badges
 *
 * @param filter to filter badges
 */
export const listBadges = async (
  filters: ModelBadgeFilterInput,
): Promise<Badge[] | undefined> => {
  try {
    const apiData = await getGraphQlData(listBadgesQuery, {
      filter: {
        ...filters,
      },
      limit: QUERY_LIMIT,
    });

    return apiData.data.listBadges.items;
  } catch (err) {
    addNotificationToApp('Failed to get badges', 'error');
  }
};

/**
 * get badge
 *
 * @param id of the badge
 *
 */
export const getBadge = async (id: string): Promise<Badge | undefined> => {
  try {
    const res = await getGraphQlData(getBadgeQuery, {
      id,
    });

    return res.data.getBadge;
  } catch (err) {
    addNotificationToApp('Failed to get badge', 'error');
  }
};

/**
 * create user badge
 *
 * @param badge to be created
 *
 */
export const createUserBadge = async (
  badge: UserBadge,
): Promise<string | undefined> => {
  try {
    const apiData = await getGraphQlData(createUserBadgeMutation, {
      input: badge,
    });

    return apiData.data.createUserBadge.id;
  } catch (err) {
    addNotificationToApp('Failed to create badge', 'error');
  }
};

/**
 * start playbook
 *
 * @param playbookId id of the journey to start
 * @param assigneeEmail playbook assignee email
 */
export const startPlaybook = async (
  playbookId: string,
  assigneeEmail: string,
): Promise<void> => {
  try {
    await putApiData('organization', `${API_BASE_URL}/playbook/start`, {
      playbookId,
      assigneeEmail,
    });
  } catch (err) {
    addNotificationToApp('Failed to trigger events', 'error');
  }
};

/**
 * delete action from collaborators
 *
 * @param deletedActionId Id of the action to be deleted
 * @param templateOrPlaybookId id of the template if template event otherwise playbook id
 * @param filter filter journey collaborators
 * @param isPlaybook true if playbook action event, false otherwise
 */
export const deleteActionFromCollaborator = async (
  deletedActionId: string,
  templateOrPlaybookId: string,
  filter: ModelJourneyCollaboratorFilterInput,
  isPlaybook = true,
): Promise<void> => {
  try {
    let collaborators;
    if (isPlaybook) {
      const res = await getGraphQlData(listJourneyCollaboratorByJourneyId, {
        filter,
        journeyID: templateOrPlaybookId,
        limit: QUERY_LIMIT,
      });
      collaborators = res.data.listJourneyCollaboratorByJourneyId
        .items as JourneyCollaborator[];
    } else {
      const res = await getGraphQlData(
        listJourneyCollaboratorByKinfolkTemplateId,
        {
          filter,
          kinfolkTemplateID: templateOrPlaybookId,
          limit: QUERY_LIMIT,
        },
      );
      collaborators = res.data.listJourneyCollaboratorByKinfolkTemplateId
        .items as JourneyCollaborator[];
    }

    await Promise.all(
      collaborators.map((collaborator) => {
        const newActionList = collaborator.actionID?.filter(
          (actionId) => actionId !== deletedActionId,
        );
        return getGraphQlData(updateJourneyCollaboratorData, {
          input: {
            id: collaborator.id,
            actionID:
              newActionList && newActionList.length ? newActionList : null,
          },
        });
      }),
    );
  } catch (err) {
    addNotificationToApp('Failed to delete action from collaborators', 'error');
  }
};

/**
 * delete meeting from collaborators
 *
 * @param deletedMeetingId Id of the meeting to be deleted
 * @param filter filter journey collaborators
 */
export const deleteMeetingFromCollaborator = async (
  deletedMeetingId: string,
  filter: ModelJourneyCollaboratorFilterInput,
): Promise<void> => {
  try {
    const apiData = await getGraphQlData(listJourneyCollaborators, {
      filter,
      limit: QUERY_LIMIT,
    });

    const collaborators = apiData.data.listJourneyCollaborators
      .items as JourneyCollaborator[];
    await Promise.all(
      collaborators.map((collaborator) => {
        const newMeetingList = collaborator.meetingID?.filter(
          (metingId) => metingId !== deletedMeetingId,
        );
        return getGraphQlData(updateJourneyCollaboratorData, {
          input: {
            id: collaborator.id,
            meetingID:
              newMeetingList && newMeetingList.length ? newMeetingList : null,
          },
        });
      }),
    );
  } catch (err) {
    addNotificationToApp(
      'Failed to delete meeting from collaborators',
      'error',
    );
  }
};

/**
 * create new cognito user and delete old one
 *
 * @param newEmail new email of the user
 * @param previousEmail previousEmail of the user
 */
export const createNewCognitoUserAndDeleteOldOne = async (
  newEmail: string,
  previousEmail?: string | null,
): Promise<void> => {
  try {
    await postApiData('organization', `${API_BASE_URL}/aws/cognito/user`, {
      newEmail,
      previousEmail,
    });
  } catch (err) {
    addNotificationToApp('Failed to update user', 'error');
  }
};

/**
 * list user badges
 *
 * @param ids playbook ids
 */
export const listUserBadges = async (
  ids: string[],
): Promise<({ badgeURL: string; name: string } | null)[] | undefined> => {
  try {
    const playbooksFilter = {
      or: ids.map((id) => ({
        id: { eq: id },
      })),
    };
    const playbooks = await fetchJourneys(playbooksFilter);
    if (playbooks && playbooks.length) {
      const badgesFilter: ModelBadgeFilterInput = {
        or: playbooks.map((playbook) => ({
          id: { eq: playbook.badgeID },
        })),
      };

      const badges = await listBadges(badgesFilter);
      if (badges) {
        return playbooks
          .map((playbook) => {
            const matchedBadge = badges.find(
              (badge) => badge.id === playbook.badgeID,
            );
            if (matchedBadge) {
              return {
                badgeURL: matchedBadge.iconUrl,
                name: playbook.customBadgeName ?? playbook.name,
              };
            }

            return null;
          })
          .filter((badge) => !!badge);
      }
    }
  } catch (e) {
    console.log(e);
    addNotificationToApp('Failed to get user badges', 'error');
  }
};

/**
 * get playbook
 *
 * @param playbookId playbook id
 */
export const getPlaybook = async (id: string): Promise<Journey | undefined> => {
  try {
    const res = await getGraphQlData(getJourney, {
      id,
    });

    return res.data.getJourney;
  } catch (e) {
    console.error(e);
    addNotificationToApp('Failed to get runbook', 'error');
  }
};

/**
 * get playbook badge
 *
 * @param playbookId playbook id
 */
export const getPlaybookBadge = async (
  playbookId: string,
): Promise<Badge | undefined> => {
  try {
    const playbook = await getPlaybook(playbookId);

    if (playbook && playbook.badgeID) {
      const badge = await getBadge(playbook.badgeID);
      return {
        ...badge,
        name: playbook.customBadgeName ?? playbook.name,
      } as Badge;
    }
  } catch (e) {
    console.error(e);
    addNotificationToApp('Failed to get runbook badge', 'error');
  }
};

/**
 * create playbook assignee badges
 *
 * @param playbooksId id of the playbook
 */
export const createPlaybookAssigneeBadges = async (playbookId: string) => {
  try {
    await postApiData('organization', `${API_BASE_URL}/user/playbook/badge`, {
      playbookId,
    });
  } catch (e) {
    console.error(e);
    addNotificationToApp('Failed to create runbook assignee badges', 'error');
  }
};

/**
 * remove playbook assignee badges
 *
 * @param playbooksId id of the playbook
 */
export const removePlaybookAssigneeBadges = async (playbookId: string) => {
  try {
    await deleteApiData(
      'organization',
      `${API_BASE_URL}/user/playbook/badge/${playbookId}`,
      {},
    );
  } catch (e) {
    console.error(e);
    addNotificationToApp('Failed to remove runbook assignee badges', 'error');
  }
};

/**
 * Creates anonymous user and assigns playbook
 *
 * @param organizationId organization id
 * @param anonymousUserEmail anonymous user email
 * @param parentPlaybookId parent playbook id which is assigned
 */
export const createAnonymousUserAndAssignPlaybook = async (
  organizationId: string,
  anonymousUserEmail: string,
  parentPlaybookId: string,
): Promise<string | undefined> => {
  try {
    const res = await postApiData(
      'organization',
      `${API_BASE_URL}/journey/anonymous-user`,
      {
        organizationId,
        anonymousUserEmail,
        parentPlaybookId,
      },
    );

    return res.id;
  } catch (err) {
    console.error(err);
    addNotificationToApp('Failed to assign runbook', 'error');
  }
};

/**
 * activate/deactivate playbook workflows like actions, messages and meetings
 * based on playbook status
 *
 * @param playbookId playbook id
 * @param  playbookStatus playbook status
 * @param organizationId organization id
 * @param playbookStartDate playbook start date
 */
export const updatePlaybookWorkflows = async (
  playbookId: string,
  playbookStatus: JourneyStatus,
  organizationId: string,
  playbookStartDate?: string | null,
): Promise<string | undefined> => {
  try {
    const res = await putApiData(
      'organization',
      `${API_BASE_URL}/journey/workflows`,
      {
        playbookId,
        playbookStatus,
        organizationId,
        playbookStartDate,
      },
    );

    return res.id;
  } catch (err) {
    console.error(err);
    addNotificationToApp('Failed to update runbook events', 'error');
  }
};

/**
 * send action notification
 *
 * @param playbookId playbook id
 * @param organizationId organization id
 * @param actionId action id
 * @param isReminder true if event reminder, false otherwise
 */
export const sendActionNotification = async (
  playbookId: string,
  organizationId: string,
  actionId: string,
  isReminder: boolean = false,
): Promise<boolean | undefined> => {
  try {
    await postApiData('organization', `${API_BASE_URL}/notification/action`, {
      playbookId,
      organizationId,
      actionId,
      isReminder,
    });
    return true;
  } catch (error) {
    addNotificationToApp('Failed to send action notification', 'error');
  }
};

/**
 * send form notification
 *
 * @param playbookId playbook id
 * @param organizationId organization id
 * @param actionId action id
 * @param isReminder true if event reminder, false otherwise
 */
export const sendFormNotification = async (
  playbookId: string,
  organizationId: string,
  actionId: string,
  isReminder?: boolean,
): Promise<boolean | undefined> => {
  try {
    await postApiData(
      'organization',
      `${API_BASE_URL}/notification/send-form`,
      {
        playbookId,
        organizationId,
        actionId,
        isReminder,
      },
    );
    return true;
  } catch (error) {
    addNotificationToApp('Failed to send form notification', 'error');
  }
};

/**
 * send message notification
 *
 * @param playbookId playbook id
 * @param organizationId organization id
 * @param messageId message id
 */
export const sendMessageNotification = async (
  playbookId: string,
  organizationId: string,
  messageId: string,
): Promise<boolean | undefined> => {
  try {
    await postApiData('organization', `${API_BASE_URL}/notification/message`, {
      playbookId,
      organizationId,
      messageId,
    });
    return true;
  } catch (error) {
    addNotificationToApp('Failed to send message', 'error');
  }
};

/**
 * test event notification
 *
 * @param playbookId playbook id
 * @param userId user id
 */
export const testEventNotifications = async (
  playbookId: string,
  userId: string,
): Promise<void> => {
  try {
    await postApiData('organization', `${API_BASE_URL}/notification/test`, {
      playbookId,
      userId,
    });
    addNotificationToApp('Messages sent', 'success');
  } catch (error) {
    addNotificationToApp('Failed to send messages', 'error');
  }
};

/**
 * activate child playbooks
 *
 * @param parentPlaybookId parent playbook id
 */
export const activateChildPlaybooks = async (
  parentPlaybookId: string,
): Promise<void> => {
  try {
    await putApiData(
      'organization',
      `${API_BASE_URL}/journey/child-playbooks/activate`,
      { parentPlaybookId },
    );
    addNotificationToApp('Runbooks activated successfully', 'success');
  } catch (err) {
    addNotificationToApp('Failed to activate runbooks', 'error');
  }
};

/**
 * pause playbooks
 *
 * @param playbookId playbook id
 */
export const pausePlaybooks = async (playbookId: string): Promise<void> => {
  try {
    await putApiData(
      'organization',
      `${API_BASE_URL}/journey/child-playbooks/pause`,
      { playbookId },
    );
    addNotificationToApp('Runbook paused', 'success');
  } catch (err) {
    addNotificationToApp('Failed to pause runbook', 'error');
  }
};

/**
 * send playbook assign email
 *
 * @param playbookId playbook id
 */
export const sendPlaybookAssignEmail = async (
  playbookId: string,
): Promise<void> => {
  try {
    await postApiData('organization', `${API_BASE_URL}/email/playbook-assign`, {
      playbookId,
    });
  } catch (err) {
    addNotificationToApp('Failed to send runbook assign email', 'error');
  }
};

/**
 * update sendgrid contact list
 *
 * @param email email to update
 * @param listId id of the list to update email from
 * @param action add or delete
 */
export const updateSendgridContactList = async (
  email: string,
  listId: string,
  action: 'add' | 'delete',
): Promise<void> => {
  try {
    if (action === 'delete') {
      await deleteApiData(
        'organization',
        `${API_BASE_URL}/email/marketing/contacts`,
        { email, listId },
      );
      return;
    }
    await putApiData(
      'organization',
      `${API_BASE_URL}/email/marketing/contacts`,
      { email, listId },
    );
  } catch (err) {}
};

/**
 * list playbook events
 *
 * @param playbookId id of the playbook
 */
export const listPlaybookEvents = async (
  playbookId: string,
): Promise<Event[] | undefined> => {
  try {
    const res = await getGraphQlData(listEventsWithRemindersByJourneyId, {
      filter: {
        archived: { eq: false },
      },
      journeyID: playbookId,
      limit: QUERY_LIMIT,
    });

    return res.data.listEventsByJourneyId.items;
  } catch (err) {
    addNotificationToApp('Failed to list events', 'error');
  }
};

/**
 * list kinfolk template events
 *
 * @param templateId id of the template
 */
export const listKinfolkTemplateEvents = async (
  templateId: string,
): Promise<Event[] | undefined> => {
  try {
    const res = await getGraphQlData(
      listEventsWithRemindersByKinfolkTemplateId,
      {
        filter: {
          archived: { eq: false },
        },
        kinfolkTemplateID: templateId,
        limit: QUERY_LIMIT,
      },
    );

    return res.data.listEventsByKinfolkTemplateId.items;
  } catch (err) {
    addNotificationToApp('Failed to list template events', 'error');
  }
};

/**
 * create playbook event
 *
 * @param event event to be created
 */
export const createPlaybookEvent = async (
  event: Event,
): Promise<string | undefined> => {
  try {
    const res = await getGraphQlData(createEvent, {
      input: event,
    });

    return res.data.createEvent.id;
  } catch (err) {
    addNotificationToApp('Failed to create event', 'error');
  }
};

/**
 * update playbook event
 *
 * @param event event to be updated
 */
export const updatePlaybookEvent = async (
  event: Event,
): Promise<string | undefined> => {
  try {
    const res = await getGraphQlData(updateEvent, {
      input: event,
    });

    return res.data.updateEvent.id;
  } catch (err) {
    addNotificationToApp('Failed to update event', 'error');
  }
};

/**
 * delete playbook event
 *
 * @param id event id
 */
export const deletePlaybookEvent = async (id: string): Promise<void> => {
  try {
    await getGraphQlData(deleteEvent, {
      input: { id },
    });
  } catch (err) {
    addNotificationToApp('Failed to delete event', 'error');
  }
};

/**
 * create playbook event reminder
 *
 * @param event event reminder to be created
 */
export const createPlaybookEventReminder = async (
  event: EventReminder,
): Promise<string | undefined> => {
  try {
    const res = await getGraphQlData(createEventReminder, {
      input: event,
    });

    return res.data.createEventReminder.id;
  } catch (err) {
    addNotificationToApp('Failed to create event reminder', 'error');
  }
};

/**
 * update playbook event reminder
 *
 * @param event event reminder to be updated
 */
export const updatePlaybookEventReminder = async (
  event: EventReminder,
): Promise<string | undefined> => {
  try {
    const res = await getGraphQlData(updateEventReminder, {
      input: event,
    });

    return res.data.updateEventReminder.id;
  } catch (err) {
    addNotificationToApp('Failed to update event reminder', 'error');
  }
};

/**
 * delete playbook event reminder
 *
 * @param id event reminder id to be deleted
 */
export const deletePlaybookEventReminder = async (
  id: string,
): Promise<void> => {
  try {
    await getGraphQlData(deleteEventReminder, {
      input: { id },
    });
  } catch (err) {
    addNotificationToApp('Failed to delete event reminder', 'error');
  }
};

/**
 * update playbook start date and re-calculate events due dates
 *
 * @param id playbook id
 * @param date anchor date
 */
export const updatePlaybookStartDate = async (
  id: string,
  date: string,
): Promise<void> => {
  try {
    date = new Date(date).toISOString().split('T')[0];
    await putApiData('organization', `${API_BASE_URL}/playbook/date`, {
      id,
      date,
    });
  } catch (err) {
    addNotificationToApp('Failed to update anchor date', 'error');
  }
};

/**
 * download product usage stats
 */
export const downloadProductUsageStats = async (): Promise<void> => {
  try {
    const text = await getApiData(
      'organization',
      `${API_BASE_URL}/product/stats`,
      'text',
    );
    const fileName = `kinfolk-usage-stats-${formateDate(new Date(), true).split(' ').join('-')}.csv`;
    downloadCSV(fileName, text);
  } catch (err) {
    addNotificationToApp('Failed to download product usage stats', 'error');
  }
};

export const downloadFormsReport = async (orgId: string): Promise<void> => {
  try {
    const text = await getApiData(
      'organization',
      `${API_BASE_URL}/${orgId}/forms/report`,
      'text',
    );
    const fileName = `form-report-${orgId}-${formateDate(new Date(), true).split(' ').join('-')}.csv`;
    downloadCSV(fileName, text);
  } catch (err) {
    addNotificationToApp('Failed to export forms report', 'error');
  }
};

/**
 * get playbook pre-activation checklist
 *
 * @param id playbook id
 */
export const getPlaybookPreActivationChecklist = async (
  id: string,
): Promise<PlaybookPreActivationChecklist | undefined> => {
  try {
    const checklist = await getApiData(
      'organization',
      `${API_BASE_URL}/playbook/pre-activation/checklist/${id}`,
    );
    return checklist;
  } catch (err) {
    addNotificationToApp('Failed to fetch checklist', 'error');
  }
};

/**
 * list user digest
 *
 * @param filter filter digests
 */
export const listUserDigest = async (
  filter: ModelDigestFilterInput,
): Promise<Digest[] | undefined> => {
  try {
    const res = await getApiData(
      'organization',
      `${API_BASE_URL}/digest/${JSON.stringify(filter)}`,
    );
    return res.digests;
  } catch (err) {
    addNotificationToApp('Failed to fetch digests', 'error');
  }
};

/**
 * create user digest
 *
 * @param digest created digest
 */
export const createUserDigest = async (
  digest: Digest,
): Promise<string | undefined> => {
  try {
    const res = await postApiData('organization', `${API_BASE_URL}/digest`, {
      ...digest,
    });
    return res.id;
  } catch (err) {
    addNotificationToApp('Failed to create digest', 'error');
  }
};

/**
 * update user digest
 *
 * @param digest update digest
 */
export const updateUserDigest = async (digest: Digest): Promise<void> => {
  try {
    await putApiData('organization', `${API_BASE_URL}/digest`, { ...digest });
  } catch (err) {
    addNotificationToApp('Failed to update digest', 'error');
  }
};

/**
 * delete user digest
 *
 * @param id digest id
 */
export const deleteUserDigest = async (id: string): Promise<void> => {
  try {
    await deleteApiData('organization', `${API_BASE_URL}/digest/${id}`, {});
  } catch (err) {
    addNotificationToApp('Failed to delete digest', 'error');
  }
};

/**
 * get all forms of the organization
 *
 * @param orgId organization id
 */
export const listOrgForms = async (
  orgId: string,
): Promise<Form[] | undefined> => {
  try {
    const res = await getApiData(
      'organization',
      `${API_BASE_URL}/${orgId}/forms`,
    );
    return res.data;
  } catch (err) {
    addNotificationToApp('Failed to list org forms', 'error');
  }
};

/**
 * get form by id
 *
 * @param orgId organization id
 * @param id form id
 */
export const getFormById = async (
  orgId: string,
  id: string,
): Promise<Form | undefined> => {
  try {
    const res = await getApiData(
      'organization',
      `${API_BASE_URL}/${orgId}/forms/${id}`,
    );
    return res.data;
  } catch (err) {
    addNotificationToApp('Failed to get form', 'error');
  }
};

/**
 * get form by slug
 *
 * @param slug form slug
 */
export const getFormBySlug = async (
  slug: string,
): Promise<Form | undefined> => {
  try {
    const res = await getApiData(
      'organization',
      `${API_BASE_URL}/forms/static/${slug}`,
    );
    return res.data;
  } catch (err) {
    addNotificationToApp('Failed to get form', 'error');
  }
};

/**
 * update form
 *
 * @param orgId organization id
 * @param form form to be updated
 */
export const updateForm = async (orgId: string, form: Form): Promise<void> => {
  try {
    await postApiData(
      'organization',
      `${API_BASE_URL}/${orgId}/forms/${form.id}`,
      {
        name: form.name,
        dataStoreId: form.dataStoreId,
        linkType: FormLinkType.Runbook,
        elements: form.elements,
        triggers: form.triggers ?? {},
      },
    );
  } catch (err) {
    addNotificationToApp('Failed to update form', 'error');
  }
};

/**
 * submit form by slug
 *
 * @param slug submitted form slug
 * @param payload form inputs data
 */
export const submitFormBySlug = async (
  slug: string,
  payload: Record<
    string,
    undefined | string | boolean | number | Record<string, unknown>
  >,
): Promise<boolean | undefined> => {
  try {
    await postApiData('organization', `${API_BASE_URL}/forms/static/${slug}`, {
      inputs: payload,
    });

    return true;
  } catch (err) {
    addNotificationToApp('Failed to submit form', 'error');
  }
};

/**
 * create form instance
 *
 * @param orgId organization id
 * @param formId form id
 * @param runbookId runbook id
 * @param userId assignee id
 */
export const createFormInstance = async (
  orgId: string,
  formId: string,
  runbookId: string,
  userId: string,
): Promise<string | undefined> => {
  try {
    const res = await postApiData(
      'organization',
      `${API_BASE_URL}/${orgId}/forms/instances`,
      {
        formId,
        runbookId,
        userId,
      },
    );

    return res.data.slug;
  } catch (err) {
    addNotificationToApp('Failed to create form instance', 'error');
  }
};

/*
 * search playbook by name
 *
 * @param orgId organization id
 * @param name playbook name
 */
export const searchPlaybookByName = async (
  orgId: string,
  name: string,
): Promise<Journey[] | undefined> => {
  try {
    const playbooks = await getApiData(
      'organization',
      `${API_BASE_URL}/${orgId}/journey/search?name=${name}`,
    );
    return playbooks;
  } catch (err) {
    addNotificationToApp('Failed to find runbook', 'error');
  }
};

export const getOrgDataStores = async (
  orgId: string,
): Promise<DataStore[] | undefined> => {
  try {
    const res = await getApiData(
      'organization',
      `${API_BASE_URL}/${orgId}/data-stores/`,
    );
    return res.data;
  } catch (err) {
    addNotificationToApp('Failed to get organization data stores', 'error');
  }
};

export const createForm = async (
  orgId: string,
  name: string,
  dataStoreId: string,
): Promise<string | undefined> => {
  try {
    const res = await postApiData(
      'organization',
      `${API_BASE_URL}/${orgId}/forms`,
      {
        name,
        dataStoreId,
        linkType: FormLinkType.Runbook,
        elements: [
          {
            element: FormElementType.INPUT,
            input: FormInputType.STRING,
            type: DataValueType.STRING,
            label: 'Title',
            isOptional: true,
          },
        ],
      },
    );

    return res.data.id;
  } catch (err) {
    addNotificationToApp('Failed to create form', 'error');
  }
};

export const getUserDetailsByIds = async (
  ids: string[],
): Promise<User[] | undefined> => {
  try {
    const res = await postApiData(
      'organization',
      `${API_BASE_URL}/user/detail`,
      {
        userIds: ids,
      },
    );

    return res.data;
  } catch (err) {
    addNotificationToApp('Failed to get users', 'error');
  }
};

export const getOrgUserCustomFields = async (
  orgId: string,
): Promise<OrgUserCustomFields | undefined> => {
  try {
    const res = await getApiData(
      'organization',
      `${API_BASE_URL}/${orgId}/users/custom-fields`,
    );
    return res.data;
  } catch (err) {
    addNotificationToApp('Failed to get organization custom fields', 'error');
  }
};

export const getUserById = async (
  orgId: string,
  userId: string,
): Promise<UserData | undefined> => {
  try {
    const res = await getApiData(
      'organization',
      `${API_BASE_URL}/${orgId}/users/${userId}`,
    );
    return res.data;
  } catch (err) {
    addNotificationToApp('Failed to get user', 'error');
  }
};

export const updateUser = async (
  orgId: string,
  data: UserUpdateRequest,
): Promise<string | undefined> => {
  try {
    const res = await postApiData(
      'organization',
      `${API_BASE_URL}/${orgId}/users/${data.updateParams.id}`,
      data,
    );
    return res.data.user.id;
  } catch (err) {
    addNotificationToApp('Failed to update user', 'error');
  }
};

export const getDynamicTags = async (
  orgId: string,
): Promise<DynamicFieldResponse | undefined> => {
  try {
    const res = await getApiData(
      'organization',
      `${API_BASE_URL}/${orgId}/data/dynamic-tags`,
    );
    return res.data;
  } catch (err) {
    addNotificationToApp('Failed to get dynamic fields', 'error');
  }
};

export const substituteDynamicTags = async (
  request: SubstituteDynamicFieldsRequest,
): Promise<SubstituteDynamicFieldsResponse | undefined> => {
  try {
    const res = await postApiData(
      'organization',
      `${API_BASE_URL}/${request.orgId}/data/resolve-text`,
      request.data,
    );
    return res.data;
  } catch (err) {
    console.error('Failed to substitute dynamic tags values', 'error');
  }
};

export const getPreSignedURL = async (
  request: PresignedURLRequest,
): Promise<{ preSignedUrl: string } | undefined> => {
  try {
    const res = await postApiData(
      'organization',
      `${API_BASE_URL}/aws/generate-presigned-url`,
      request,
    );
    return res.data;
  } catch (err) {
    addNotificationToApp('Failed to upload file', 'error');
  }
};
