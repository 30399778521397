import './MeetingStatusPill.scss';
import { MeetingStatus } from '@API';

interface MeetingStatusPillProps {
  status: MeetingStatus;
}
const MeetingStatusPills = (props: MeetingStatusPillProps) => {
  const { status } = props;
  switch (status) {
    case MeetingStatus.DRAFT:
      return <div className="meeting-status-pill__pending">Draft</div>;
    case MeetingStatus.SCHEDULED:
      return <div className="meeting-status-pill__booked">Booked</div>;
    case MeetingStatus.NOT_AVAILABLE:
      return (
        <div className="meeting-status-pill__not-available">
          No availability
        </div>
      );
    default:
      return <div>-</div>;
  }
};

export default MeetingStatusPills;
