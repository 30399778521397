import { useState, useEffect, useCallback } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { getOrganizationUsersImages } from '@api/apis';
import { useNavigate } from 'react-router-dom';

export const useOnboarding = () => {
  const [currentNumber, setCurrentNumber] = useState(0);
  const {
    user,
    allOrganisationInterests,
    allOrganisationShareInfo,
    setUserIsFirstLogin,
  } = useUserContext();
  const [suggestOptionsInterests, setSuggestOptionsInterests] = useState(
    allOrganisationInterests,
  );
  const [onboardingImagesRow, setOnboardingImagesRow] = useState<string[]>([]);
  const [suggestOptionsLearnKnowledge, setSuggestOptionsLearnKnowledge] =
    useState(allOrganisationShareInfo);
  const [userProfilePhoto, setUserProfilePhoto] = useState(user.photoUrl);
  const navigateTo = useNavigate();

  const initializeOnboardingPage = useCallback(async () => {
    if (user.isFirstLogin) {
      setUserIsFirstLogin(false);
    }
    const profileImages = await getOrganizationUsersImages(
      user.userOrganizationId,
    );
    let profileImagesAWS: string[] = [];
    profileImages?.forEach((image) => {
      profileImagesAWS.push(image.photoUrl);
    });
    setOnboardingImagesRow(profileImagesAWS);
  }, [setUserIsFirstLogin, user.isFirstLogin, user.userOrganizationId]);

  const handleUpdateUserPhoto = (url: string) => {
    setUserProfilePhoto(url);
  };

  const handleClick = () => {
    navigateTo('/');
    return;
  };

  const handleBack = () => {
    setCurrentNumber(currentNumber - 1);
  };

  const createProgressBar = (number: number) => {
    var elements = [];
    for (let i = 0; i <= number; i++) {
      elements.push(
        <ProgressBar
          key={i}
          variant="warning"
          now={25}
          className={i !== 3 ? 'me-1' : ''}
        />,
      );
    }
    return elements;
  };

  useEffect(() => {
    if (user.id) {
      initializeOnboardingPage();
      setUserProfilePhoto(user.photoUrl);
    }
  }, [user, initializeOnboardingPage]);

  return {
    user,
    currentNumber,
    allOrganisationInterests,
    allOrganisationShareInfo,
    suggestOptionsInterests,
    onboardingImagesRow,
    userProfilePhoto,
    suggestOptionsLearnKnowledge,
    setSuggestOptionsInterests,
    handleClick,
    createProgressBar,
    setSuggestOptionsLearnKnowledge,
    handleBack,
    handleUpdateUserPhoto,
  };
};
