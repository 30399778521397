import { Row, Col, Card } from 'react-bootstrap';
import TextArea from '@Shared/Components/TextArea/TextArea';
import { type User } from '@API';

const About = (props: { user: User | undefined }) => {
  const { user } = props;

  return (
    <div className="about-wrapper">
      <Card>
        <Card.Body>
          <Row>
            <Col xs="9">
              <h4 className="fs-20-semi-bold-4C5564">
                About {user?.firstName}
              </h4>
            </Col>
          </Row>
          <Row>
            <TextArea
              id={'aboutMe'}
              type={'text'}
              placeholder={''}
              width={''}
              defaultValue={user?.aboutMe ?? ''}
              additionalClasses={'fs-16-626A77 textarea-readonly'}
              fieldName={'About me'}
            ></TextArea>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};

export default About;
