import { Card } from 'react-bootstrap';
import { Draggable } from 'react-beautiful-dnd';
import './MultipleChoiceQuestion.scss';
import ContentEditable from 'react-contenteditable';
import { type MultipleChoiceQuestionBlock } from '@API';
import { pasteAsPlainText } from '@Shared/utils/utils';
import { CrossButton } from '../../CrossButton/CrossButton';
import { EditableContent } from '../../EditableContent/EditableContent';
import { InfoModal } from '../../InfoModal/InfoModal';
import { TextEditor } from '../../TextEditor/TextEditor';
import { DragButton } from '../../DragButton/DragButton';
import { useMultipleChoiceQuestionBlock } from './useMultipleChoiceQuestion';

interface MultipleChoiceQuestionBlockProps {
  index: number;
  block: MultipleChoiceQuestionBlock;
  handleUpdateMilestoneBlocks: Function;
  isEditable: boolean;
  setShowLoader: Function;
  setShowSaved: Function;
  isAssignee?: boolean;
  handleDeleteBlock: Function;
  setIsSaving?: Function;
  isBusy: boolean;
  isParentPlaybook: boolean;
}

export const MultipleChoiceQuestionsBlock = (
  props: MultipleChoiceQuestionBlockProps,
) => {
  const {
    title,
    description,
    options,
    answer,
    showConfirmationModal,
    setShowConfirmationModal,
    setTitle,
    setDescription,
    addNewOption,
    handleAnswer,
    handleOptionsTextChange,
    removeBlock,
    removeOption,
  } = useMultipleChoiceQuestionBlock(
    props.block,
    props.handleUpdateMilestoneBlocks,
    props.handleDeleteBlock,
    props.setShowLoader,
    props.setShowSaved,
    props.isParentPlaybook,
    props.isEditable,
    props.isAssignee,
    props.setIsSaving,
  );
  const { id } = props.block;

  return (
    <Draggable
      key={props.block.id}
      draggableId={props.block.id}
      index={props.index}
      isDragDisabled={props.isBusy || !props.isEditable}
    >
      {(provided, snapshot) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <Card
            className={`border-radius-10 mt-3 pb-2 multiple-choice-questions-block-wrapper content-block ${
              snapshot.isDragging ? 'block-dragging-active' : ''
            }`}
          >
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div className="w-100">
                  <div className="fs-20-semi-bold-4C5564 d-flex">
                    {props.isEditable && (
                      <span
                        className="drag-button me-2"
                        {...provided.dragHandleProps}
                      >
                        <DragButton />
                      </span>
                    )}
                    <EditableContent
                      id={`${id}-title`}
                      value={title}
                      setValue={setTitle}
                      isEditable={props.isEditable}
                    />
                  </div>
                </div>
                {props.isEditable && (
                  <CrossButton
                    isDisabled={props.isBusy}
                    handleClick={() => setShowConfirmationModal(true)}
                  />
                )}
              </div>
              {!props.isEditable && description === '' ? null : (
                <div className="mt-3">
                  <TextEditor
                    id={id}
                    value={description}
                    setValue={setDescription}
                    isEditable={props.isEditable}
                  />
                </div>
              )}
              <div className="d-flex flex-wrap mt-3">
                {!props.isAssignee
                  ? options.map((option, i) => (
                      <ContentEditable
                        disabled={!props.isEditable}
                        key={i}
                        html={option}
                        data-column="item"
                        onPaste={pasteAsPlainText}
                        className={`content-editable cursor-pointer ${
                          answer.length && answer[0] === option
                            ? 'selected-pill'
                            : 'pill-button'
                        }`}
                        onChange={(e) =>
                          handleOptionsTextChange(e.target.value, i)
                        }
                      />
                    ))
                  : options.map((option, i) => (
                      <button
                        key={i}
                        className={
                          answer.length && answer[0] === option
                            ? 'selected-pill'
                            : 'pill-button'
                        }
                        onClick={() => handleAnswer(i)}
                      >
                        {option}
                      </button>
                    ))}
                {props.isEditable && (
                  <div className="d-flex mt-1">
                    <svg
                      className="cursor-pointer"
                      onClick={addNewOption}
                      width="43"
                      height="43"
                      viewBox="0 0 43 43"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="42"
                        height="42"
                        rx="20.5"
                        fill="white"
                        stroke="#494C95"
                      />
                      <path
                        d="M21.5 13C21.2853 13 21.0794 13.0853 20.9276 13.2371C20.7758 13.3889 20.6905 13.5948 20.6905 13.8095V20.6905H13.8095C13.5948 20.6905 13.3889 20.7758 13.2371 20.9276C13.0853 21.0794 13 21.2853 13 21.5C13 21.7147 13.0853 21.9206 13.2371 22.0724C13.3889 22.2242 13.5948 22.3095 13.8095 22.3095H20.6905V29.1905C20.6905 29.4052 20.7758 29.6111 20.9276 29.7629C21.0794 29.9147 21.2853 30 21.5 30C21.7147 30 21.9206 29.9147 22.0724 29.7629C22.2242 29.6111 22.3095 29.4052 22.3095 29.1905V22.3095H29.1905C29.4052 22.3095 29.6111 22.2242 29.7629 22.0724C29.9147 21.9206 30 21.7147 30 21.5C30 21.2853 29.9147 21.0794 29.7629 20.9276C29.6111 20.7758 29.4052 20.6905 29.1905 20.6905H22.3095V13.8095C22.3095 13.5948 22.2242 13.3889 22.0724 13.2371C21.9206 13.0853 21.7147 13 21.5 13V13Z"
                        fill="#494C95"
                      />
                    </svg>

                    <svg
                      className="cursor-pointer mx-2"
                      onClick={removeOption}
                      width="43"
                      height="43"
                      viewBox="0 0 43 43"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="42"
                        height="42"
                        rx="20.5"
                        fill="white"
                        stroke="#494C95"
                      />
                      <rect
                        x="12.8789"
                        y="20.8503"
                        width="17"
                        height="1.26119"
                        rx="0.630597"
                        fill="#494C95"
                      />
                      <rect
                        x="12.8789"
                        y="20.8503"
                        width="17"
                        height="1.26119"
                        rx="0.630597"
                        fill="#494C95"
                      />
                    </svg>
                  </div>
                )}
              </div>
            </Card.Body>

            <InfoModal
              showModal={showConfirmationModal}
              handleShowModal={setShowConfirmationModal}
              description={
                <div>Are you sure you want to delete this content block?</div>
              }
              title="Delete content block"
              onClickConfirmButton={removeBlock}
              isDeleteButton={true}
            />
          </Card>
        </div>
      )}
    </Draggable>
  );
};
