/* eslint-disable jsx-a11y/anchor-is-valid */
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { memo } from 'react';
import { NavLink } from 'react-router-dom';
import { UserType } from '@base/API';

export const NavbarFooter = memo(() => {
  const { user } = useUserContext();

  return (
    <div className="position-absolute bottom-0 w-100 p-2">
      <div className="navbar__user py-2 px-1">
        {user.type === UserType.CANDIDATE ? null : (
          <NavLink to="/profile" state={null} className="nav-link">
            <img
              alt="Avatar"
              src={user!.photoUrl}
              className="rounded-circle object-fit-cover"
              width="31"
              height="31"
            ></img>{' '}
            <span className="navbar__user-name ps-2">
              {user!.firstName} {user!.lastName}
            </span>
          </NavLink>
        )}
      </div>
    </div>
  );
});
