import { type ModalProps } from '@Shared/utils/utils';
import {
  type BuilderType,
  type Journey,
  type JourneyStatus,
  type PlaybookMode,
  type Meeting,
  type Milestone,
  type User,
  type TemplateStatus,
} from '@API';
import { type IEvent } from './playbookHome.model';

export interface JourneyHeaderProps {
  journeyName: string;
  isAssignee: boolean;
  isAdmin: boolean;
  isSaving: boolean;
  isCollaborator: boolean;
  isEditable: boolean;
  isParentJourney: boolean;
  showLoader: boolean;
  showSaved: boolean;
  isContentCustomizationAllowed: boolean;
  journeyStatus?: JourneyStatus;
  journeyMode?: PlaybookMode;
  onBack: Function;
  viewJourney: Function;
  exitPreview: Function;
  copyPlaybookLink: () => void;
  setShowEditConfirmationModal: (value: boolean) => void;
  setShowSettingModal: (value: boolean) => void;
}

export interface MessageImageDetail {
  src: string;
  fileName: string;
  fileType: string;
  organizationId: string;
  playbookId: string;
  id: string;
}

export interface BlockImageDetail {
  src: string;
  fileName: string;
  fileType: string;
  blockId: string;
  blockType: string;
  milestoneID: string;
  organizationId: string;
  journeyId: string;
}

export interface Collaborator {
  id: string;
  role: string;
  isLoading: boolean;
  updatedInChild: boolean;
  assignee: {
    id: string | null | undefined;
    firstName: string | null | undefined;
    lastName: string | null | undefined;
    email: string | null;
  };
}

export interface CopyPlaybookModalProps extends ModalProps {
  journey: Journey;
  handleUpdateJourneys: Function;
}

export interface AddMeetingModalProps extends ModalProps {
  handleSaveMeeting: Function;
  handleUpdateMeeting: Function;
  selectedMeeting: JourneyMeeting;
  isParentJourney: boolean;
  isTemplate?: boolean;
  journeyAssignee: User | null;
}

export interface JourneyMeeting extends Meeting {
  attendeePhoto: string;
  attendeeName: string;
  attendeeEmail: string | null;
  date: string;
}

export interface AttendeeProfile {
  attendeePhoto: string;
  attendeeName: string;
}

export interface AddMessageModalProps extends ModalProps {
  playbookStatus: JourneyStatus | TemplateStatus;
  builderId: string;
  isParentJourney: boolean;
  messageEvent: IEvent;
  assigneeEmails: string[];
  assigneeHireDate?: string | null;
  journeyStartDate?: string | null;
  assigneeSlackId?: string;
  assigneeId?: string;
  onHideModal: Function;
  handleSave: Function;
  handleUpdate: Function;
  builderType: BuilderType;
  isTemplate?: boolean;
  isEditingAllowed: boolean;
}

export interface ICreateUpdateAction extends ModalProps {
  builderId: string;
  runbookAssignee?: User;
  actionEvent: IEvent;
  isParentJourney: boolean;
  refreshEvents: () => void;
  isTemplate?: boolean;
  playbookStatus: JourneyStatus | TemplateStatus;
  playbookType: BuilderType;
  journeyStartDate?: string | null;
  isEditingAllowed: boolean;
}

export interface IPublishJourneyModal extends ModalProps {
  onJourneyUpdate?: Function;
  journey: Journey;
}

export interface JourneyMeetingsProps {
  isParentJourney: boolean;
  isEditable: boolean;
  isTemplate?: boolean;
  journeyStartDate?: string | null;
  journeyType: BuilderType;
}

export interface MilestoneProps {
  milestones: Milestone[];
  selectedMilestoneIndex: number;
  setSelectedMilestoneIndex: Function;
  handleMilestoneTextChange: Function;
  addNewMilestone: Function;
  removeMilestone: Function;
  handleReorderMilestones: Function;
  isEditable: boolean;
  progress?: number;
  isBusy: boolean;
  assignee?: User;
  isParentPlaybook?: boolean;
}

export enum ASSIGNING_ROLE {
  USER = 'USER',
  ROLE = 'ROLE',
}

export interface IStartPlaybookModal extends ModalProps {
  onStartPlaybookModal: Function;
}
