/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import './DropDownField.scss';

export const DropDownFieldSimple = (props: any) => {
  const ref = useRef<any>();

  function handleOnChange(newSelected: any) {
    if (props.onChangePronoun) {
      props.onChangePronoun(newSelected);
    }
  }

  return (
    <div className="dropdown-wrapper">
      <div className="dropdown-search row rounded-pill">
        <div className="dropdown-field">
          <Typeahead
            key={props.propsKeyName}
            id={props.propsKeyName}
            ref={ref}
            onChange={handleOnChange}
            options={props.options}
            placeholder={props.placeholder}
            selected={props.alreadySelected}
            defaultInputValue=""
            inputProps={{
              style: {
                border: 'none',
              },
            }}
          />
          <div className="svg-position">
            <button
              className="btn btn-sm btn-rounded me-3 dropdown-button"
              type="button"
              onClick={() => ref.current?.toggleMenu()}
            >
              <svg
                className="mt-2 me-3"
                id="Chevron_Down"
                data-name="Chevron Down"
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="4.75"
                viewBox="0 0 9 4.75"
              >
                <path
                  id="Shape"
                  d="M.842.135,4.5,3.565,8.158.135a.5.5,0,1,1,.684.73l-4,3.75a.5.5,0,0,1-.684,0l-4-3.75A.5.5,0,1,1,.842.135Z"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const DropDownField = (props: any) => {
  const ref = useRef<any>();

  function handleOnInputChange(newSelected: any) {
    if (newSelected.length) {
      props.onChangeProficiency([newSelected], props.propsKey);
    }
  }

  function handleOnChange(newSelected: any) {
    props.onChangeProficiency(newSelected, props.propsKey);
  }

  return (
    <div className="dropdown-wrapper">
      <div className="dropdown-search row rounded-pill">
        <div className="dropdown-field">
          <Typeahead
            key={props.propsKeyName}
            id={props.propsKeyName}
            ref={ref}
            onChange={handleOnChange}
            onInputChange={handleOnInputChange}
            options={props.options}
            placeholder={props.placeholder}
            selected={props.selectedProficiency}
            defaultInputValue=""
            inputProps={{
              style: {
                border: 'none',
              },
            }}
          />
          <div className="svg-position">
            <button
              className="btn btn-sm btn-rounded me-3 dropdown-button"
              type="button"
              onClick={() => ref.current?.toggleMenu()}
            >
              <svg
                className="mt-2 me-3"
                id="Chevron_Down"
                data-name="Chevron Down"
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="4.75"
                viewBox="0 0 9 4.75"
              >
                <path
                  id="Shape"
                  d="M.842.135,4.5,3.565,8.158.135a.5.5,0,1,1,.684.73l-4,3.75a.5.5,0,0,1-.684,0l-4-3.75A.5.5,0,1,1,.842.135Z"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ToggleDropDownSimple = (props: {
  items: string[];
  selectedItem: string;
  disabled?: boolean;
  className?: string;
  onSelect: (item: string) => void;
}) => {
  const { items, selectedItem, disabled, className, onSelect } = props;

  return (
    <CommonDropDownComponent
      disabled={disabled}
      selectedItem={selectedItem}
      items={items}
      handleOnInputChange={onSelect}
      className={clsx(className, 'btn toggle-dropdown-button')}
    ></CommonDropDownComponent>
  );
};

export const UserTypeDropDown = (props: any) => {
  const [selectedItem, setSelectedItem] = useState(
    props.items[props.firstItemIndex],
  );

  useEffect(() => {
    setSelectedItem(props.items[props.firstItemIndex]);
  }, [props.items, props.firstItemIndex]);

  function handleOnInputChange(newSelected: any) {
    if (newSelected) {
      if (props.userInfo) {
        props.onItemChange(newSelected, props.userInfo.id);
      } else {
        props.onItemChange(newSelected);
      }
      setSelectedItem(newSelected);
    }
  }

  return (
    <CommonDropDownComponent
      disabled={props.disabled}
      selectedItem={selectedItem}
      items={props.items}
      handleOnInputChange={handleOnInputChange}
      className={
        'btn user-type-dropdown-button fs-14-semi-bold-25272a w-100 text-nowrap'
      }
    ></CommonDropDownComponent>
  );
};

export const CommonDropDownComponent = (props: any) => (
  <div className="dropdown-wrapper dropdown">
    <button
      disabled={props.disabled}
      className={props.className}
      type="button"
      id="toggleDropDownButtonId"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <div className="d-flex justify-content-between align-items-center">
        <div>{props.selectedItem}</div>
        <svg
          className="ms-2"
          id="Chevron_Down"
          data-name="Chevron Down"
          xmlns="http://www.w3.org/2000/svg"
          width="9"
          height="4.75"
          viewBox="0 0 9 4.75"
        >
          <path
            id="Shape"
            d="M.842.135,4.5,3.565,8.158.135a.5.5,0,1,1,.684.73l-4,3.75a.5.5,0,0,1-.684,0l-4-3.75A.5.5,0,1,1,.842.135Z"
          />
        </svg>
      </div>
    </button>
    <ul
      className="dropdown-menu toggle-dropdown-menu"
      aria-labelledby="toggleDropDownButtonId"
    >
      {props.items.map((item: any, index: number) => (
        <li key={index}>
          <a
            className={`dropdown-item ${props.selectedItem === item ? 'active' : ''}`}
            onClick={() => {
              props.handleOnInputChange(item);
            }}
          >
            {item}
          </a>
        </li>
      ))}
    </ul>
  </div>
);
