import { Modal } from 'react-bootstrap';
import { type ModalProps } from '@Shared/utils/utils';
import { memo } from 'react';
import {
  CreatePlaybookStepOne,
  CreatePlaybookStepTwo,
  Button,
} from '@Shared/DS';
import { useCreatePlaybookModal } from './useCreatePlaybookModal';
import './CreatePlaybookModal.scss';

export const CreatePlaybookModal = memo((props: ModalProps) => {
  const {
    playbookName,
    isSubmitted,
    showLoader,
    stepNumber,
    currentStep,
    startDateOption,
    playbookStartDateOptions,
    playbookStartDate,
    setStepNumber,
    setPlaybookName,
    handleHideModal,
    handleCreatePlaybook,
    setPlaybookStartDate,
    setStartDateOption,
  } = useCreatePlaybookModal(props.handleShowModal);

  return (
    <Modal
      className="create-journey-modal-wrapper"
      size="lg"
      fullscreen={'md-down'}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      show={props.showModal}
      onHide={handleHideModal}
    >
      <Modal.Header className="pb-0">
        <div className="step">{`Step ${stepNumber} of 2`}</div>
        <button
          type="button"
          className="btn-close modal-close-btn"
          data-bs-dismiss="modal"
          onClick={handleHideModal}
          aria-label="Close"
        ></button>
      </Modal.Header>
      <Modal.Body>
        <h1 className="heading my-1">{currentStep?.title}</h1>
        <p className="description mb-4">{currentStep?.description}</p>

        {stepNumber === 1 && (
          <CreatePlaybookStepOne
            playbookName={playbookName}
            setPlaybookName={setPlaybookName}
            isSubmitted={isSubmitted}
          />
        )}

        {/* {stepNumber === 2 && <CreatePlaybookStepTwo playbookMode={playbookMode} setPlaybookMode={setPlaybookMode} />} */}
        {stepNumber === 2 && (
          <CreatePlaybookStepTwo
            playbookStartDateOptions={playbookStartDateOptions}
            startDateOption={startDateOption}
            setStartDateOption={setStartDateOption}
            isSubmitted={isSubmitted}
            playbookStartDate={playbookStartDate}
            showStartDateOptionError={
              isSubmitted && startDateOption === 'Select'
            }
            setPlaybookStartDate={setPlaybookStartDate}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-end">
          <Button
            buttonStyle="outline"
            text="Back"
            disabled={stepNumber === 1}
            onClick={() => setStepNumber((prev) => prev - 1)}
          />
          <Button
            text={stepNumber < 2 ? 'Next' : 'Create runbook'}
            showLoading={showLoader}
            additionalClasses="ms-2"
            disabled={showLoader || (stepNumber === 1 && !playbookName)}
            onClick={() =>
              stepNumber < 2
                ? setStepNumber((prev) => prev + 1)
                : handleCreatePlaybook()
            }
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
});
