import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { type ElementsDropdownItem } from '../AddElementDropdown.types';
import './AddElementDropdownItem.scss';

interface AddElementDropdownItemProps {
  eventKey: string;
  item: ElementsDropdownItem;
}

export const AddElementDropdownItem = ({
  eventKey,
  item,
}: AddElementDropdownItemProps) => (
  <Dropdown.Item className="add-element-dropdown-item" eventKey={eventKey}>
    <div className="add-element-item-icon">{item.icon}</div>
    <span className="add-element-item-name">{item.label}</span>
    <span className="add-element-item-description">{item.description}</span>
  </Dropdown.Item>
);
