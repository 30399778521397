import { Card } from 'react-bootstrap';
import './ResetPassword.scss';
import { TextField } from '@Shared/DS/Molecules';
import { Button } from '@Shared/DS';
import { useResetPassword } from './useResetPassword';

export const ResetPassword = () => {
  const {
    userEmail,
    verificationCode,
    newPassword,
    confirmPassword,
    isSubmitted,
    setUserEmail,
    setVerificationCode,
    setNewPassword,
    setConfirmPassword,
    handleNewPassword,
  } = useResetPassword();

  return (
    <div className="reset-password-wrapper text-center">
      <img
        className="mt-5"
        src={require('../../Assets/Images/kinfolk-icon-dark.png')}
        alt="kinfolk icon"
      />
      <div className="mt-4 fs-24-25272a">Account Verification</div>
      <div className="mt-3">
        Enter the verification code we just sent on your email address and
        confirm new password.
      </div>
      <div className="row justify-content-center mx-2">
        <Card className="mt-3 card-border col-12 col-md-4 pb-5">
          <Card.Body>
            <form
              id="reset-password-form"
              onSubmit={(e) => handleNewPassword(e)}
            >
              <label
                className="fs-14-semi-bold col-12 text-start mt-4"
                htmlFor="email"
              >
                Email address
              </label>
              <TextField
                id={'email'}
                type="email"
                defaultValue={userEmail}
                setValue={setUserEmail}
                fieldName="Email"
                width=""
                placeholder="Enter your work email address"
                additionalClasses={` 
            ${!userEmail && userEmail.length === 0 && isSubmitted ? 'is-invalid' : ''}`}
              />
              <label
                className="fs-14-semi-bold col-12 text-start mt-4"
                htmlFor="verification"
              >
                Verification code
              </label>
              <TextField
                id={'verification'}
                type="text"
                defaultValue={verificationCode}
                setValue={setVerificationCode}
                fieldName="Verification code"
                width=""
                placeholder="Enter your verification code"
                additionalClasses={` 
            ${!verificationCode && verificationCode.length === 0 && isSubmitted ? 'is-invalid' : ''}`}
              />
              <label
                className="fs-14-semi-bold col-12 text-start mt-4"
                htmlFor="password"
              >
                Password
              </label>
              <TextField
                id={'password'}
                type="password"
                defaultValue={newPassword}
                setValue={setNewPassword}
                fieldName="Password"
                width=""
                placeholder="Enter your new password"
                additionalClasses={` 
            ${!newPassword && newPassword.length === 0 && isSubmitted ? 'is-invalid' : ''}`}
              />
              <label
                className="fs-14-semi-bold col-12 text-start mt-4"
                htmlFor="confirmPassword"
              >
                Confirm password
              </label>
              <TextField
                id={'confirmPassword'}
                type="password"
                defaultValue={confirmPassword}
                setValue={setConfirmPassword}
                fieldName="Password"
                width=""
                placeholder="Confirm new password"
                additionalClasses={` 
            ${!confirmPassword && confirmPassword.length === 0 && isSubmitted ? 'is-invalid' : ''}`}
              />
            </form>
          </Card.Body>
        </Card>
      </div>
      <Button
        type="submit"
        form="reset-password-form"
        buttonStyle="primary"
        additionalClasses="fs-16-medium-bold mt-4"
        text="Submit"
      />
    </div>
  );
};
