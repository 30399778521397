import { useEffect, useState, type MouseEvent } from 'react';
import { useParams } from 'react-router-dom';
import {
  type Meeting,
  type User,
  MeetingStatus,
  type AssigneeRole,
} from '@API';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import {
  searchUser,
  addNewMeeting,
  updateMeeting,
  updateMeetingAndRoleAssignee,
  createCollaborator,
  deleteMeetingFromCollaborator,
} from '@api/apis';
import { addNotificationToApp } from '@Shared/utils/utils';
import {
  ASSIGNING_ROLE,
  type AddMeetingModalProps,
  type JourneyMeeting,
} from '@base/models/journeyLibrary.model';
import { Analytics } from '@base/analytics/analytics';
import { sureString } from '@Shared/utils/sure';

const withinDaysList = Array.from({ length: 181 }, (_, i) =>
  i === 0 ? 'Select' : `Day ${i}`,
);

export const useAddMeetingModal = (props: AddMeetingModalProps) => {
  const {
    selectedMeeting,
    handleSaveMeeting,
    handleUpdateMeeting,
    journeyAssignee,
    handleShowModal,
    isParentJourney,
  } = props;
  const [meetingTitle, setMeetingTitle] = useState('');
  const [meetingDescription, setMeetingDescription] = useState('');
  const [meetingDuration, setMeetingDuration] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [selectedWithinStartDay, setSelectedWithinStartDay] = useState(
    withinDaysList[0],
  );
  const [selectedWithinEndDay, setSelectedWithinEndDay] = useState(
    withinDaysList[0],
  );
  const [isLoadingAssignee, setIsLoadingAssignee] = useState(false);
  const { user } = useUserContext();
  const [assignees, setAssignees] = useState<User[]>([]);
  const { id: builderId } = useParams();
  const [selectedAttendee, setSelectedAttendee] = useState<User>();
  const [selectedRole, setSelectedRole] = useState<
    AssigneeRole | null | undefined
  >(null);
  const [assigningRole, setAssigningRole] = useState(ASSIGNING_ROLE.ROLE);

  const searchAssignee = async (query: string) => {
    setIsLoadingAssignee(true);
    const users = await searchUser(user.userOrganizationId, query);
    if (users) {
      setAssignees([...users]);
    }
    setIsLoadingAssignee(false);
  };

  const handleSelectedAssignee = (users: any[]) => {
    if (users && users.length > 0) {
      setSelectedAttendee(users[0]);
      setSelectedRole(null);
    } else {
      if (selectedMeeting) {
        setSelectedRole(selectedMeeting.assigneeRole);
      }
      setSelectedAttendee(undefined);
    }
  };

  const handleAssigningRole = (
    e: MouseEvent<HTMLButtonElement>,
    role: ASSIGNING_ROLE,
  ) => {
    e.preventDefault();
    // If user changes tab, check if he has nay selected role if he has, then remove any
    // selected assignee else if he has any selected assignee remove the selected role as
    // user can select only role or assignee at a time
    if (selectedRole) {
      setSelectedAttendee(undefined);
    } else if (selectedAttendee) {
      setSelectedRole(null);
    }
    setAssigningRole(role);
  };

  const handleWithinStartDay = (item: string) => {
    setSelectedWithinStartDay(item);
  };

  const handleWithinEndDay = (item: string) => {
    setSelectedWithinEndDay(item);
  };

  const handleAddMeeting = async () => {
    setShowLoader(true);
    setIsSubmitted(true);

    if (
      meetingDuration.length &&
      meetingTitle.length &&
      selectedWithinStartDay &&
      selectedWithinEndDay &&
      selectedWithinStartDay !== withinDaysList[0] &&
      selectedWithinEndDay !== withinDaysList[0]
    ) {
      let id;
      let meeting = {
        title: meetingTitle,
        description: meetingDescription,
        duration: Math.abs(parseInt(meetingDuration)).toString(),
        startTime: selectedWithinStartDay.split(' ')[1],
        endTime: selectedWithinEndDay.split(' ')[1],
        attendeesEmail:
          selectedAttendee && selectedAttendee.email
            ? [selectedAttendee.email]
            : [],
        status: MeetingStatus.DRAFT,
        assigneeRole: selectedRole || null,
        organizedByUserID: (journeyAssignee && journeyAssignee.id) || null,
      } as Meeting;

      if (props.isTemplate) {
        meeting = { ...meeting, kinfolkTemplateID: builderId };
      } else {
        meeting = { ...meeting, journeyID: builderId };
      }

      if (selectedMeeting.id) {
        let status = selectedMeeting.status;
        // If meeting status is NOT_AVAILABLE and user update meeting's duration or startTime
        // or endTime then meeting status would change to DRAFT
        if (
          selectedMeeting.status === MeetingStatus.NOT_AVAILABLE &&
          (meeting.duration !== selectedMeeting.duration ||
            meeting.startTime !== selectedMeeting.startTime ||
            meeting.endTime !== selectedMeeting.endTime)
        ) {
          status = MeetingStatus.DRAFT;
        }

        if (selectedRole && selectedMeeting.assigneeRole !== selectedRole) {
          let sideEffects: any = [
            updateMeetingAndRoleAssignee({
              ...meeting,
              id: selectedMeeting.id,
              status,
              updatedInChild: !isParentJourney,
            }),
          ];

          // check if collaborator role of the meeting has been changed, we need to remove meeting from the previous collaborator role
          if (
            selectedMeeting.assigneeRole &&
            selectedMeeting.assigneeRole !== selectedRole
          ) {
            sideEffects = [
              ...sideEffects,
              deleteMeetingFromCollaborator(selectedMeeting.id, {
                journeyID: { eq: selectedMeeting.journeyID },
                assigneeRole: { eq: selectedMeeting.assigneeRole },
              }),
            ];
          }

          [id] = await Promise.all(sideEffects);
        } else {
          id = await updateMeeting({
            ...meeting,
            id: selectedMeeting.id,
            status,
            updatedInChild: !isParentJourney,
          });
        }

        if (id) {
          if (selectedRole && builderId) {
            await createCollaborator({
              meetingId: id,
              builderId,
              role: selectedRole,
              isTemplate: props.isTemplate || false,
            });
          }
          handleUpdateMeeting({ ...meeting, id: selectedMeeting.id, status });
          if (status !== selectedMeeting.status) {
            addNotificationToApp(
              'Meeting updated, ready to be rescheduled',
              'info',
            );
          }
        }
      } else {
        id = await addNewMeeting(meeting);
        if (selectedRole && builderId) {
          const collaborator = await createCollaborator({
            meetingId: id,
            builderId,
            role: selectedRole,
            isTemplate: props.isTemplate || false,
          });
          if (collaborator) {
            if (collaborator.assigneeId) {
              await updateMeeting({
                ...meeting,
                id: id,
                attendeesEmail: collaborator.email ? [collaborator.email] : [],
                updatedInChild: !isParentJourney,
              } as Meeting);
            }

            handleSaveMeeting({
              ...meeting,
              id: id,
              attendeeEmail: collaborator.email,
              attendeeName: collaborator.name,
              status: MeetingStatus.DRAFT,
            } as JourneyMeeting);
          }
        }
        if (id) {
          handleSaveMeeting({ ...meeting, id });
        }

        Analytics.playbookMeetingAdded(sureString(meeting.journeyID));
      }

      if (id) {
        handleHideModal();
      }
    }
    setShowLoader(false);
  };

  const handleHideModal = () => {
    setMeetingTitle('');
    setMeetingDuration('');
    setMeetingDescription('');
    setSelectedWithinStartDay(withinDaysList[0]);
    setSelectedWithinEndDay(withinDaysList[0]);
    setSelectedAttendee(undefined);
    handleShowModal(false);
    setIsSubmitted(false);
    setSelectedRole(null);
    setAssigningRole(ASSIGNING_ROLE.ROLE);
  };

  useEffect(() => {
    if (selectedMeeting.id) {
      setMeetingTitle(selectedMeeting.title);
      setMeetingDescription(selectedMeeting.description ?? '');
      setMeetingDuration(selectedMeeting.duration);
      const startTimeIndex = withinDaysList.findIndex(
        (item) => item === `Day ${selectedMeeting.startTime}`,
      );
      const endTimeIndex = withinDaysList.findIndex(
        (item) => item === `Day ${selectedMeeting.endTime}`,
      );
      setSelectedWithinStartDay(withinDaysList[startTimeIndex]);
      setSelectedWithinEndDay(withinDaysList[endTimeIndex]);
      setSelectedRole(selectedMeeting.assigneeRole);
      setSelectedAttendee({
        firstName: selectedMeeting.attendeeName.split(' ')[0],
        lastName: selectedMeeting.attendeeName.split(' ')[1] || '',
        email: selectedMeeting.attendeeEmail,
      } as User);
      if (selectedMeeting.assigneeRole) {
        setAssigningRole(ASSIGNING_ROLE.ROLE);
      } else {
        setAssigningRole(ASSIGNING_ROLE.USER);
      }
    }
  }, [selectedMeeting]);

  return {
    meetingTitle,
    showLoader,
    assignees,
    selectedWithinEndDay,
    selectedWithinStartDay,
    meetingDuration,
    withinDaysList,
    meetingDescription,
    isSubmitted,
    isLoadingAssignee,
    selectedAttendee,
    assigningRole,
    selectedRole,
    setSelectedRole,
    searchAssignee,
    handleSelectedAssignee,
    setMeetingDescription,
    setMeetingDuration,
    setMeetingTitle,
    handleWithinStartDay,
    handleWithinEndDay,
    handleHideModal,
    handleAddMeeting,
    handleAssigningRole,
  };
};
