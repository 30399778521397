import { useUserContext } from '@base/Context/UserContext/UserContext';
import { useMemo } from 'react';
import { z } from 'zod';

const featureFlagsSchema = z.object({
  FORMS: z.boolean().optional(),
});

export const useFeatureFlags = () => {
  const { user } = useUserContext();
  const features = user.Organization?.features;

  return useMemo(() => {
    if (!features) {
      return {};
    }

    try {
      return featureFlagsSchema.parse(JSON.parse(features));
    } catch (error) {
      return {};
    }
  }, [features]);
};
