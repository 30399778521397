import { Button, FadeLoader } from '@Shared/DS';
import { RolePill } from '@Shared/Components/RolePill/RolePill';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { DeleteButton } from '@Shared/Components/DeleteButton/DeleteButton';
import { useCollaborator } from './useCollaborator';

import './Collaborator.scss';

export const Collaborators = ({
  isParentJourney,
}: {
  isParentJourney?: boolean;
}) => {
  const {
    journeyCollaborators,
    assignees,
    isBusy,
    isLoading,
    openMenu,
    setOpenMenu,
    searchAssignee,
    handleSelectedAssignee,
    handleSaveAssignee,
    removeCollaborator,
    onHideModal,
  } = useCollaborator(isParentJourney);

  return (
    <div className="dropdown collaborators d-inline-flex px-0">
      <Button
        buttonStyle="outline"
        icon="bi bi-people dropdown-toggle"
        type="button"
        text="Assign role"
        title="Assign collaborator roles"
        onClick={() => setOpenMenu(true)}
      />

      <div className={`dropdown-menu ${openMenu ? 'show' : ''}`}>
        <form className="pt-3">
          {isLoading ? (
            <div className="d-flex justify-content-center loader-styling">
              <FadeLoader />
            </div>
          ) : (
            <div className="px-3">
              <div className="row">
                <div className="col-4">
                  <label className="text-start" htmlFor="role">
                    Role
                  </label>
                </div>
                <div className="col-8 d-flex justify-content-between align-items-center">
                  <label className="text-start" htmlFor="assignee">
                    Name/Email
                  </label>
                </div>
              </div>

              {journeyCollaborators.map((collaborator, index) => (
                <div
                  key={index}
                  className="row mb-2 collaborators align-items-center"
                >
                  <div className="col-4">
                    <RolePill
                      className="role-pill"
                      role={collaborator.role}
                      size="big"
                    />
                  </div>
                  <div className="col-8 d-flex align-items-center">
                    <AsyncTypeahead
                      id="assignee"
                      className="me-2"
                      selected={
                        collaborator.assignee.id &&
                        ((collaborator.assignee.firstName &&
                          collaborator.assignee.lastName) ||
                          collaborator.assignee.email)
                          ? [{ ...collaborator.assignee }]
                          : []
                      }
                      isLoading={collaborator.isLoading}
                      onSearch={(query) => searchAssignee(query, index)}
                      minLength={3}
                      labelKey={(option: any) =>
                        `${option.firstName ?? ''} ${option.lastName ?? ''}<${option.email}>`
                      }
                      onChange={(res) => handleSelectedAssignee(res, index)}
                      options={assignees}
                      placeholder="Search teammate"
                      inputProps={{
                        style: {
                          borderColor: 'lightgray',
                          borderRadius: '30px',
                          padding: '8px 20px',
                        },
                      }}
                      renderMenuItemChildren={(option: any) =>
                        option.firstName || option.lastName ? (
                          <span>{`${option.firstName ?? ''} ${option.lastName ?? ''}<${option.email}>`}</span>
                        ) : (
                          <span>{`${option.email}`}</span>
                        )
                      }
                    />

                    <DeleteButton
                      handleOnClick={() => removeCollaborator(index)}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className="dropdown-divider"></div>
          <div className="d-flex justify-content-end px-3">
            <Button
              buttonStyle="outline"
              text="Cancel"
              disabled={isBusy}
              onClick={onHideModal}
            />
            <Button
              additionalClasses="ms-2"
              text="Save"
              showLoading={isBusy || isLoading}
              disabled={isBusy || isLoading}
              onClick={handleSaveAssignee}
            />
          </div>
        </form>
      </div>
    </div>
  );
};
