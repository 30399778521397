import { Row, Col } from 'react-bootstrap';
import PersonalInfo from '@Shared/Components/PersonalInfo/PersonalInfo';
import './Profile.scss';
import { Button, UserBadges } from '@Shared/DS';
import { openExternalUrl } from '@Shared/utils/utils';
import About from './Components/About/About';
import Interests from './Components/Interests/Interests';
import LearnMore from './Components/LearnMore/LearnMore';
import Happy from './Components/Happy/Happy';
import WorkPreferences from './Components/WorkPreferences/WorkPreferences';
import WorkStyle from './Components/WorkStyle/WorkStyle';
import { useProfile } from './useProfile';

export const Profile = () => {
  const { user, badges } = useProfile();

  return (
    <div className="profile-page-wrapper d-flex">
      <div className="container p-0">
        <div className="row no-pad">
          <div className="col-md-12 col-lg-12">
            <div className="profile-page-container d-md-block pt-4 px-3">
              {!user?.Organization?.hasSubscription ? (
                <div className="profile__locked">
                  <span className="profile__locked-text">
                    Employee profiles are available on the paid plan. Contact us
                    to see them in action.
                    <br />
                    <Button
                      buttonStyle="outline"
                      text="Book a call"
                      icon="bi-calendar-check"
                      additionalClasses="mt-2"
                      onClick={() =>
                        openExternalUrl('https://calendly.com/kinfolkhq/jeet')
                      }
                    />
                  </span>
                </div>
              ) : (
                <>
                  {' '}
                  <div className="container">
                    <Row>
                      <Col md="12">
                        {user.id !== undefined && <PersonalInfo />}
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <About />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <UserBadges badges={badges ?? []} />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <Interests />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <LearnMore />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <Happy />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <WorkPreferences />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <WorkStyle />
                        {/* <div ref={workSkillsRef}>
                      <ShareInfoCard />
                      <ExtraDetails />
                      <Languages />
                    </div> */}
                      </Col>
                    </Row>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
