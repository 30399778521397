import { useParams } from 'react-router-dom';
import { Form as FormComponent } from '@Shared/Components/Form';

export const Form = () => {
  const { id: idParam, slug: slugParam } = useParams();

  return (
    <FormComponent
      canSwitchEditAndPreview
      formId={idParam}
      formSlug={slugParam}
    />
  );
};
