import { type ReactNode } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { FadeLoader } from '@Shared/DS';

export default function Loader(props: {
  active: boolean;
  styling?: 'relative' | 'absolute';
  children?: ReactNode | ReactNode[];
}) {
  return (
    <LoadingOverlay
      active={props.active}
      // @ts-ignore
      styles={{
        overlay: (base) => ({
          ...base,
          background: 'transparent',
        }),
      }}
      spinner={
        <FadeLoader
          cssOverride={{
            position: props.styling === 'relative' ? 'relative' : 'absolute',
            marginTop: props.styling === 'relative' ? '50%' : '45vh',
            marginLeft: '50%',
          }}
        />
      }
      className="loaderHeight"
    >
      {props.children}
    </LoadingOverlay>
  );
}
