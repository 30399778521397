interface DeleteIconProps {
  handleOnClick: Function;
}
export const DeleteButton = (props: DeleteIconProps) => {
  const { handleOnClick } = props;

  return (
    <svg
      onClick={() => handleOnClick()}
      className="modal-close-btn cursor-pointer"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.445 16H3.555C2.575 16 1.777 15.4 1.777 14.667V4H0.89C0.4 4 0 3.7 0 3.334C0 2.968 0.4 2.667 0.89 2.667H15.11C15.601 2.667 16 2.967 16 3.334C16 3.701 15.6 4 15.11 4H14.222V14.667C14.222 15.4 13.425 16 12.445 16ZM3.555 4V14.667H12.445V4H3.555ZM11.555 1.334H4.445C3.954 1.334 3.555 1.034 3.555 0.668C3.555 0.302 3.954 0 4.445 0H11.555C12.046 0 12.445 0.3 12.445 0.667C12.445 1.034 12.046 1.333 11.555 1.333V1.334Z"
        fill="#D1D4DB"
      />
    </svg>
  );
};
