import { TextField, ToggleSwitch, Button } from '@Shared/DS';
import { Form } from 'react-bootstrap';
import { userTypeMap } from '@base/models/common.model';
import { UserType } from '@base/API';
import styles from './NewHire.module.scss';
import { useNewHire } from './useNewHire';

interface NewHireEmployeeProps {
  handleShowModal: Function;
}

export const NewHire = (props: NewHireEmployeeProps) => {
  const {
    inviteParams,
    isSubmitted,
    isLoading,
    customFields,
    data,
    handleCustomFieldChange,
    handleInputChange,
    checkEmailAlreadyExist,
    handleSubmit,
  } = useNewHire(props.handleShowModal);

  return (
    <div className="new-hire-tab">
      <p className={styles.heading}>
        New hires will be emailed Kinfolk login details on their start date. If
        you don't need them to log in, turn off 'Send welcome email'. You can
        always send a sign up link later.
      </p>
      <Form onSubmit={(e) => handleSubmit(e)}>
        <div className="row mt-2">
          <Form.Group className="col-12 col-lg-6">
            <label
              className="fs-14-semi-bold text-start form-label"
              htmlFor="firstName"
            >
              First name
            </label>
            <TextField
              id="firstName"
              type="text"
              defaultValue={inviteParams.firstName}
              setValue={(value: string) =>
                handleInputChange(value, 'firstName')
              }
              fieldName="First name"
              required
              placeholder="First name"
              additionalClasses={`text-capitalize ${
                !inviteParams.firstName &&
                inviteParams.firstName?.length === 0 &&
                isSubmitted
                  ? 'is-invalid'
                  : ''
              }`}
            />
          </Form.Group>

          <Form.Group className="col-12 col-lg-6 px-2 mt-1 mt-lg-0">
            <label
              className="fs-14-semi-bold text-start form-label"
              htmlFor="lastName"
            >
              Last name
            </label>
            <TextField
              id="lastName"
              type="text"
              defaultValue={inviteParams.lastName}
              setValue={(value: string) => handleInputChange(value, 'lastName')}
              fieldName="Last name"
              required
              placeholder="Last name"
              additionalClasses={`text-capitalize ${
                !inviteParams.lastName &&
                inviteParams.lastName?.length === 0 &&
                isSubmitted
                  ? 'is-invalid'
                  : ''
              }`}
            />
          </Form.Group>

          <Form.Group className="col-12 col-lg-6 px-2 mt-1">
            <label
              className="fs-14-semi-bold text-start form-label"
              htmlFor="workEmail"
            >
              Work email
            </label>
            <TextField
              id="workEmail"
              type="email"
              defaultValue={inviteParams.email}
              setValue={(value: string) => handleInputChange(value, 'email')}
              fieldName="Email"
              required
              placeholder="Email address is their username"
              errorMessage={
                checkEmailAlreadyExist(inviteParams.email)
                  ? `${inviteParams.email} already exists`
                  : undefined
              }
              additionalClasses={`text-lowercase ${
                (inviteParams.email.length === 0 ||
                  checkEmailAlreadyExist(inviteParams.email)) &&
                isSubmitted
                  ? 'is-invalid'
                  : ''
              }`}
            />
          </Form.Group>

          <Form.Group className="col-12 col-lg-6 px-2 mt-1">
            <label
              className="fs-14-semi-bold text-start form-label"
              htmlFor="personalEmail"
            >
              Personal email (Optional)
            </label>
            <TextField
              id="personalEmail"
              type="email"
              defaultValue={inviteParams.personalEmail ?? ''}
              setValue={(value: string) =>
                handleInputChange(value, 'personalEmail')
              }
              fieldName="Email"
              errorMessage={
                inviteParams.personalEmail &&
                checkEmailAlreadyExist(inviteParams.personalEmail)
                  ? `${inviteParams.personalEmail} already exists`
                  : undefined
              }
              placeholder="For scheduling messages before day 1"
              additionalClasses={`text-lowercase ${
                inviteParams.personalEmail &&
                checkEmailAlreadyExist(inviteParams.personalEmail) &&
                isSubmitted
                  ? 'is-invalid'
                  : ''
              }`}
            />
          </Form.Group>

          <Form.Group className="col-12 col-lg-6 px-2 mt-1">
            <label
              className="fs-14-semi-bold text-start form-label"
              htmlFor="startDate"
            >
              Start date
            </label>
            <TextField
              id={'startDate'}
              type="date"
              required
              defaultValue={inviteParams.startDate}
              setValue={(value: string) =>
                handleInputChange(value, 'startDate')
              }
              fieldName="Date"
              placeholder=""
              errorMessage="is invalid"
              additionalClasses={`
                  ${!inviteParams.startDate && inviteParams.startDate?.length === 0 && isSubmitted ? 'is-invalid' : ''}`}
            />
          </Form.Group>

          <Form.Group className="col-12 col-lg-6 px-2 mt-1">
            <label
              className="fs-14-semi-bold text-start form-label"
              htmlFor="jobTitle"
            >
              Job title (Optional)
            </label>
            <TextField
              id="jobTitle"
              type="text"
              defaultValue={inviteParams.jobTitle ?? ''}
              setValue={(value: string) => handleInputChange(value, 'jobTitle')}
              fieldName="Job title"
              placeholder="Job title"
            />
          </Form.Group>

          <Form.Group className="col-12 col-lg-6 px-2 mt-1">
            <label
              className="fs-14-semi-bold text-start form-label"
              htmlFor="phone"
            >
              Phone (Optional)
            </label>
            <TextField
              id="phone"
              type="text"
              defaultValue={inviteParams.phone ?? ''}
              setValue={(value: string) => handleInputChange(value, 'phone')}
              fieldName="Phone"
              placeholder="Phone"
            />
          </Form.Group>

          <Form.Group className="col-12 col-lg-6 px-2 mt-1">
            <label
              className="fs-14-semi-bold text-start form-label"
              htmlFor="userRole"
            >
              Role
            </label>
            <Form.Select
              aria-label="form select"
              required
              className={`form-select-wrapper ${styles.option}`}
              value={inviteParams.type}
              onChange={(e) => handleInputChange(e.target.value, 'type')}
            >
              <option value="" hidden className={styles.option}>
                Select role
              </option>
              <option
                key={userTypeMap[UserType.COMPANY_ADMIN]}
                value={UserType.COMPANY_ADMIN}
              >
                {userTypeMap[UserType.COMPANY_ADMIN]}
              </option>
              <option
                key={userTypeMap[UserType.COMPANY_MEMBER]}
                value={UserType.COMPANY_MEMBER}
              >
                {userTypeMap[UserType.COMPANY_MEMBER]}
              </option>
            </Form.Select>
          </Form.Group>

          {data?.dataStore.columns.map((column) => (
            <Form.Group className="col-12 col-lg-6 px-2 mt-1" key={column.id}>
              <label
                className="fs-14-semi-bold text-start form-label"
                htmlFor={column.name}
              >
                {column.name}
              </label>
              <Form.Select
                aria-label="form select"
                className={`form-select-wrapper ${styles.option}`}
                value={customFields[column.refId]?.label ?? ''}
                onChange={(e) => handleCustomFieldChange(e, column.refId)}
              >
                <option value="" hidden className={styles.option}>
                  Select {column.name}
                </option>
                {column.items.map((item) => (
                  <option key={item.id} value={item.label}>
                    {item.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          ))}
        </div>

        <Form.Group className="d-flex gap-3 align-items-center col-12 mt-2">
          <label
            className="fs-14-semi-bold text-start form-label mt-2"
            htmlFor="send-welcome-email"
          >
            Send welcome email
          </label>
          <ToggleSwitch
            initialValue={inviteParams.sendWelcomeEmail}
            setValue={() =>
              handleInputChange(
                !inviteParams.sendWelcomeEmail,
                'sendWelcomeEmail',
              )
            }
          />
        </Form.Group>

        <hr className={`${styles.hr} mt-4`} />
        <div className="d-flex justify-content-end">
          <Button
            buttonStyle="outline"
            text="Cancel"
            onClick={() => {
              props.handleShowModal(false);
            }}
          />
          <Button
            disabled={isLoading}
            showLoading={isLoading}
            text="Invite"
            additionalClasses="ms-2"
            type="submit"
          />
        </div>
      </Form>
    </div>
  );
};
