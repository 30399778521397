import { useCallback, useEffect, useState } from 'react';
import { type Journey, JourneyStatus } from '@API';
import { listPublishedChildJourneys, updateJourney } from '@api/apis';
import { useQuery } from '@tanstack/react-query';

export const usePlaybookCard = (
  journey: Journey,
  handleArchiveJourney: Function,
) => {
  const [showCopyJourneyModal, setShowCopyJourneyModal] = useState(false);
  const [showModalToConfirmArchive, setShowModalToConfirmArchive] =
    useState(false);
  const [totalPlaybooks, setTotalPlaybooks] = useState(0);
  const [completedPlaybooks, setCompletedPlaybooks] = useState(0);
  const [childPlaybooks, setChildPlaybooks] = useState<Journey[]>([]);

  const populateJourneyCount = async (): Promise<Journey[]> => {
    const assignedJourneys = await listPublishedChildJourneys(journey.id);
    if (assignedJourneys) {
      return assignedJourneys;
    }

    return [] as Journey[];
  };

  const { data } = useQuery({
    queryKey: [journey.id],
    queryFn: populateJourneyCount,
    enabled: !!journey.id,
  });

  useEffect(() => {
    if (data) {
      setChildPlaybooks(data);
      setTotalPlaybooks(data.length);
      setCompletedPlaybooks(
        data?.filter((journey) => journey.status === JourneyStatus.COMPLETED)
          .length,
      );
    }
  }, [data]);

  const archiveJourney = useCallback(async () => {
    setShowModalToConfirmArchive(false);
    await Promise.all(
      [...childPlaybooks, journey].map(({ id }) =>
        updateJourney({ id, isArchived: true } as Journey),
      ),
    );
    handleArchiveJourney(journey.id);
  }, [childPlaybooks, journey, handleArchiveJourney]);

  return {
    totalPlaybooks,
    completedPlaybooks,
    showCopyJourneyModal,
    showModalToConfirmArchive,
    setShowModalToConfirmArchive,
    archiveJourney,
    setShowCopyJourneyModal,
  };
};
