import { useState } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { type ModalProps } from '@Shared/utils/utils';
import TextArea from '@Shared/Components/TextArea/TextArea';
import './EditHappyModal.scss';
import { Button } from '@Shared/DS';

const EditHappyModal = (props: ModalProps) => {
  const { user, setUserHappyMost } = useUserContext();
  const [happyText, setHappyText] = useState(user.mostHappy ?? '');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = () => {
    setUserHappyMost(happyText);
    setIsSubmitted(true);
    props.handleShowModal(false);
  };
  return (
    <>
      <Modal
        size="lg"
        fullscreen={'md-down'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        show={props.showModal}
        onHide={() => props.handleShowModal(false)}
        className="edit-happy-wrapper"
      >
        <Modal.Header>
          <Modal.Title className="fs-24-semi-bold-4C5564">
            Edit About
          </Modal.Title>
          <button
            type="button"
            className="btn-close modal-close-btn"
            data-bs-dismiss="modal"
            onClick={() => props.handleShowModal(false)}
            aria-label="Close"
          ></button>
        </Modal.Header>
        <Modal.Body>
          <Row className="">
            <Col>
              <label>I'm most happy when...</label>
              <TextArea
                id={'firstName'}
                type={'text'}
                placeholder={''}
                width={''}
                setValue={setHappyText}
                defaultValue={happyText}
                additionalClasses={
                  !happyText && happyText.length === 0 && isSubmitted
                    ? 'is-invalid'
                    : ''
                }
                fieldName={'First Name'}
              ></TextArea>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            text="Cancel"
            buttonStyle="outline"
            onClick={() => props.handleShowModal(false)}
          />
          <Button text="Save" onClick={() => handleSubmit()} />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditHappyModal;
