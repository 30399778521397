import { addNotificationToApp } from '@Shared/utils/utils';
import { type User } from '@base/API';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { Analytics } from '@base/analytics/analytics';
import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useCallbackUrl = () => {
  const { user } = useUserContext();
  const location = useLocation();
  const navigate = useNavigate();

  const handleCallbackUrl = useCallback(
    (user: User) => {
      const { slackAccessToken, outlookTenantId, msTeamsTenantId } =
        user?.Organization || {};
      const isApplicationConnected =
        slackAccessToken || outlookTenantId || msTeamsTenantId;
      if (!isApplicationConnected) {
        return;
      }

      const urlParams = new URLSearchParams(location.search);
      const isSuccessful = urlParams.get('success') === 'true';
      if (isSuccessful) {
        addNotificationToApp('Application connected successfully', 'success');
        navigate('/settings');

        if (urlParams.get('app') === 'slack') {
          Analytics.slackIntegrationAdded(user.userOrganizationId);
        } else if (urlParams.get('app') === 'outlook') {
          Analytics.outlookIntegrationAdded(user.userOrganizationId);
        } else if (urlParams.get('app') === 'msteams') {
          Analytics.msTeamsIntegrationAdded(user.userOrganizationId);
        }
      }
    },
    [location.search, navigate],
  );

  useEffect(() => {
    handleCallbackUrl(user);
  }, [user, location, navigate, handleCallbackUrl]);
};
