import { Row, Col, Card } from 'react-bootstrap';
import EditPersonalInfoModal from './PersonalInfoModal/EditPersonalInfoModel';
import './PersonalInfo.scss';
import { usePersonalInfo } from './usePersonalInfo';

const PersonalInfo = () => {
  const { showModal, userData, startDate, setShowModal } = usePersonalInfo();

  return (
    <div className="personal-info-wrapper">
      <Card>
        <Card.Body>
          <Row>
            <Col xs="12">
              {userData!.photoUrl && (
                <img
                  src={userData!.photoUrl}
                  className="mb-3 profile-image"
                  alt="Profile"
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col xs="12" lg="12" className="d-flex">
              <h2 className="name">
                <>
                  {userData!.firstName + ' ' + userData?.lastName}
                  {/* <span className="personal-details-pronouns">({pronoun})</span> */}
                  <span className="text-start edit-btn mt-3 d-lg-inline">
                    <svg
                      className="hover-shadow"
                      onClick={() => setShowModal(true)}
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="45"
                      height="45"
                      viewBox="0 0 58 58"
                    >
                      <defs>
                        <filter
                          id="Ellipse_45"
                          x="0"
                          y="0"
                          width="58"
                          height="58"
                          filterUnits="userSpaceOnUse"
                        >
                          <feGaussianBlur stdDeviation="3" result="blur" />
                          <feFlood floodColor="#111828" floodOpacity="0.078" />
                          <feComposite operator="in" in2="blur" />
                          <feComposite in="SourceGraphic" />
                        </filter>
                      </defs>
                      <g
                        id="Button_-_Navigation_-_Edit"
                        data-name="Button - Navigation - Edit"
                        transform="translate(9 6)"
                      >
                        <g
                          transform="matrix(1, 0, 0, 1, -9, -6)"
                          filter="url(#Ellipse_45)"
                        >
                          <circle
                            id="Ellipse_45-2"
                            data-name="Ellipse 45"
                            cx="20"
                            cy="20"
                            r="20"
                            transform="translate(9 6)"
                            fill="#fff"
                          />
                        </g>
                        <g
                          id="Pencil_2"
                          data-name="Pencil 2"
                          transform="translate(13 11)"
                        >
                          <path
                            id="Shape"
                            d="M11.225,16H3.543c-.505,0-.9,0-1.237-.027a2.5,2.5,0,0,1-.963-.241A2.446,2.446,0,0,1,.268,14.656a2.492,2.492,0,0,1-.24-.963C0,13.36,0,12.961,0,12.456V4.774c0-.505,0-.9.028-1.237a2.492,2.492,0,0,1,.24-.963A2.446,2.446,0,0,1,1.344,1.5a2.491,2.491,0,0,1,.963-.24c.333-.028.732-.028,1.237-.028h6.3L8.616,2.461H3.569c-.542,0-.891,0-1.162.023a1.378,1.378,0,0,0-.5.111,1.232,1.232,0,0,0-.538.538,1.378,1.378,0,0,0-.111.5c-.022.271-.023.62-.023,1.162v7.631c0,.543,0,.892.023,1.163a1.38,1.38,0,0,0,.111.5,1.223,1.223,0,0,0,.538.537,1.287,1.287,0,0,0,.5.111c.277.023.629.023,1.162.023H11.2c.533,0,.885,0,1.163-.023a1.356,1.356,0,0,0,.5-.111A1.231,1.231,0,0,0,13.4,14.1a1.38,1.38,0,0,0,.111-.5c.023-.277.023-.629.023-1.163V7.385l1.231-1.231v6.3c0,.505,0,.9-.027,1.237a2.5,2.5,0,0,1-.241.963,2.446,2.446,0,0,1-1.076,1.076,2.5,2.5,0,0,1-.963.241C12.129,16,11.73,16,11.225,16ZM5.539,11.077a.615.615,0,0,1-.551-.89L7.4,5.352a1.238,1.238,0,0,1,.23-.32L12.488.18a.614.614,0,0,1,.87,0L15.82,2.642a.616.616,0,0,1,0,.871L10.967,8.364a1.193,1.193,0,0,1-.32.23L5.813,11.012A.615.615,0,0,1,5.539,11.077Zm7.385-9.592h0L8.506,5.9,7.228,8.458l.313.313L10.1,7.495l4.417-4.418L12.923,1.485Z"
                          />
                        </g>
                      </g>
                    </svg>
                  </span>
                </>
              </h2>
            </Col>
          </Row>
          <Row>
            <Col lg="10" md="6" sm="5">
              <div className="personal-details">
                {userData?.jobTitle ? (
                  <span className="personal-details-jobtitle">
                    {userData?.jobTitle}
                  </span>
                ) : (
                  ''
                )}
                {userData?.email ? (
                  <span className="personal-details-email">
                    {userData?.email}
                  </span>
                ) : (
                  ''
                )}
                {userData?.WorkLocation?.city ? (
                  <span className="personal-details-worklocation">
                    {userData?.WorkLocation?.city}
                  </span>
                ) : (
                  ''
                )}
                {userData?.team ? (
                  <span className="personal-details-team">
                    {userData?.team}
                  </span>
                ) : (
                  ''
                )}
                {userData.startDate ? (
                  <span className="personal-details-startdate">
                    Joined&nbsp;
                    {startDate.getUTCDate() +
                      ` ${startDate.toLocaleDateString('en-US', {
                        month: 'long',
                      })} ` +
                      startDate.getFullYear()}
                  </span>
                ) : (
                  ''
                )}
              </div>
            </Col>
            {/* <Col lg="4" md="6" sm="7">
              <Button variant="button-primary">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M14.8721 2.05128C14.8721 0.918469 13.9536 0 12.8208 0C11.688 0 10.7695 0.918469 10.7695 2.05128C10.7695 2.69431 10.7695 7.04627 10.7695 7.69231C10.7695 8.82512 11.688 9.74359 12.8208 9.74359C13.9536 9.74359 14.8721 8.82512 14.8721 7.69231C14.8721 7.04627 14.8721 2.69431 14.8721 2.05128Z"
                    fill="white"
                  />
                  <path
                    d="M19.999 7.69191C19.999 8.82472 19.0806 9.74319 17.9478 9.74319C17.3348 9.74319 15.8965 9.74319 15.8965 9.74319C15.8965 9.74319 15.8965 8.21174 15.8965 7.69191C15.8965 6.55909 16.815 5.64062 17.9478 5.64062C19.0806 5.64062 19.999 6.55909 19.999 7.69191Z"
                    fill="white"
                  />
                  <path
                    d="M2.05128 5.12793C0.918469 5.12793 0 6.0464 0 7.17921C0 8.31202 0.918469 9.23049 2.05128 9.23049C2.69431 9.23049 7.04627 9.23049 7.69231 9.23049C8.82512 9.23049 9.74359 8.31202 9.74359 7.17921C9.74359 6.0464 8.82512 5.12793 7.69231 5.12793C7.04627 5.12793 2.69431 5.12793 2.05128 5.12793Z"
                    fill="white"
                  />
                  <path
                    d="M7.69191 0C8.82472 0 9.74319 0.918469 9.74319 2.05128C9.74319 2.66426 9.74319 4.10256 9.74319 4.10256C9.74319 4.10256 8.21174 4.10256 7.69191 4.10256C6.55909 4.10256 5.64062 3.18409 5.64062 2.05128C5.64062 0.918469 6.55909 0 7.69191 0Z"
                    fill="white"
                  />
                  <path
                    d="M5.12891 17.9518C5.12891 19.0836 6.04738 20.0001 7.18019 20.0001C8.313 20.0001 9.23147 19.0836 9.23147 17.9518C9.23147 17.3098 9.23147 12.9638 9.23147 12.3188C9.23147 11.188 8.313 10.2705 7.18019 10.2705C6.04738 10.2705 5.12891 11.188 5.12891 12.3188C5.12891 12.9638 5.12891 17.3098 5.12891 17.9518Z"
                    fill="white"
                  />
                  <path
                    d="M0 12.3188C0 11.188 0.918469 10.2705 2.05128 10.2705C2.66426 10.2705 4.10256 10.2705 4.10256 10.2705C4.10256 10.2705 4.10256 11.8 4.10256 12.3188C4.10256 13.4506 3.18409 14.3671 2.05128 14.3671C0.918469 14.3671 0 13.4506 0 12.3188Z"
                    fill="white"
                  />
                  <path
                    d="M17.9482 14.8721C19.081 14.8721 19.9994 13.9536 19.9994 12.8208C19.9994 11.688 19.081 10.7695 17.9482 10.7695C17.3051 10.7695 12.9532 10.7695 12.3071 10.7695C11.1743 10.7695 10.2559 11.688 10.2559 12.8208C10.2559 13.9536 11.1743 14.8721 12.3071 14.8721C12.9522 14.8721 17.3051 14.8721 17.9482 14.8721Z"
                    fill="white"
                  />
                  <path
                    d="M12.3071 20C11.1743 20 10.2559 19.0816 10.2559 17.9487C10.2559 17.3358 10.2559 15.8975 10.2559 15.8975C10.2559 15.8975 11.7873 15.8975 12.3071 15.8975C13.44 15.8975 14.3584 16.8159 14.3584 17.9487C14.3584 19.0816 13.44 20 12.3071 20Z"
                    fill="white"
                  />
                </svg>
                Mesage me on Slack
              </Button>
            </Col> */}
          </Row>
        </Card.Body>
      </Card>
      {showModal && (
        <EditPersonalInfoModal
          handleShowModal={setShowModal}
          showModal={showModal}
        />
      )}
    </div>
  );
};

export default PersonalInfo;
