import clsx from 'clsx';
import styles from './SwitchButton.module.scss';

export const SwitchButton = ({
  textOn,
  textOnIcon,
  textOff,
  textOffIcon,
  selected,
  className,
  variant = 'primary',
  handleToggle,
}: {
  textOn: string;
  textOnIcon?: string;
  textOff: string;
  textOffIcon?: string;
  selected: string;
  className?: string;
  variant?: 'primary' | 'secondary';
  handleToggle: (value: string) => void;
}) => (
  <div className={clsx(styles.switchContainer, styles[variant], className)}>
    <button
      type="button"
      className={clsx(styles.switchButton, {
        [styles.active]: selected === textOn,
      })}
      onClick={() => handleToggle(textOn)}
    >
      {textOnIcon && <i className={`${textOnIcon} me-2`}></i>}
      {textOn}
    </button>
    <button
      type="button"
      className={clsx(styles.switchButton, {
        [styles.active]: selected === textOff,
      })}
      onClick={() => handleToggle(textOff)}
    >
      {textOffIcon && <i className={`${textOffIcon} me-2`}></i>}
      {textOff}
    </button>
  </div>
);
