import { type MouseEvent, type ReactNode, useCallback, useMemo } from 'react';
import EyeIcon from '@Assets/Images/icon-eye.svg';
import { type JourneyHeaderProps } from '@models/journeyLibrary.model';
import { DropdownHamburger } from '@Shared/DS';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { BsArrowLeftShort } from 'react-icons/bs';
import { PlaybookMode } from '@base/API';

interface ActionItem {
  title: ReactNode;
  showLoader?: boolean;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  buttonClassName?: string;
  tooltip?: string;
}

export const JourneyHeader = (props: JourneyHeaderProps) => {
  const {
    journeyName,
    isAssignee,
    isAdmin,
    isParentJourney,
    isEditable,
    showLoader,
    showSaved,
    onBack,
    viewJourney,
    exitPreview,
    journeyMode,
    isContentCustomizationAllowed,
    copyPlaybookLink,
    setShowEditConfirmationModal,
    setShowSettingModal,
  } = props;

  const prepareActionItems = useCallback(
    ({ isDesktop }: { isDesktop?: boolean }) => {
      const actionItems: ActionItem[] = [];

      if (isParentJourney) {
        actionItems.push({
          title: 'Settings',
          tooltip: 'Microsite Settings',
          onClick: (e) => {
            e.currentTarget.blur();
            setShowSettingModal(true);
          },
          buttonClassName: isDesktop ? 'btn px-3 ms-2 text-nowrap' : '',
        });
      }

      if (
        isEditable &&
        !isParentJourney &&
        !isContentCustomizationAllowed &&
        isAdmin &&
        journeyMode === PlaybookMode.IMMERSIVE
      ) {
        actionItems.push({
          title: 'Edit',
          tooltip: 'Click to edit runbook',
          onClick: (e) => {
            e.currentTarget.blur();
            setShowEditConfirmationModal(true);
          },
          buttonClassName: isDesktop ? 'btn px-3 ms-2 text-nowrap' : '',
        });
      }

      if (isEditable && !isParentJourney) {
        actionItems.push({
          title: 'Share link',
          tooltip:
            'Copy secure link. Accessible only with the assignee email address',
          onClick: (e) => {
            e.currentTarget.blur();
            copyPlaybookLink();
          },
          buttonClassName: isDesktop ? 'btn px-2 ms-2 text-nowrap' : '',
        });
      }

      if (!isAssignee && isEditable) {
        actionItems.push({
          title: isDesktop ? <img src={EyeIcon} alt="view" /> : 'Preview',
          tooltip: 'View as user',
          onClick: () => viewJourney(),
          buttonClassName: isDesktop ? 'btn me-2 px-2 py-1 text-nowrap' : '',
        });
      }

      if (!isAssignee && !isEditable && isAdmin) {
        actionItems.push({
          title: 'Exit preview',
          tooltip: 'Back to builder',
          onClick: () => exitPreview(),
          buttonClassName: isDesktop ? 'btn me-2 px-2 py-1 text-nowrap' : '',
        });
      }

      return actionItems;
    },
    [
      isParentJourney,
      isEditable,
      isContentCustomizationAllowed,
      isAdmin,
      journeyMode,
      isAssignee,
      setShowSettingModal,
      setShowEditConfirmationModal,
      copyPlaybookLink,
      viewJourney,
      exitPreview,
    ],
  );

  const desktopActionsItems = useMemo(
    () => prepareActionItems({ isDesktop: true }),
    [prepareActionItems],
  );
  const mobileActionsItems = useMemo(
    () => prepareActionItems({ isDesktop: false }),
    [prepareActionItems],
  );

  return (
    <div className="builder-header-wrapper sticky-lg-top pt-2">
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          {localStorage.getItem('isLoggedIn') === 'true' && (
            <div className="home-button d-flex">
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip
                    id="header-backhome-btn"
                    style={{ position: 'fixed' }}
                  >
                    Back to the calendar
                  </Tooltip>
                }
              >
                <button title="Back home" onClick={() => onBack()}>
                  <BsArrowLeftShort />
                  <span>Back</span>
                </button>
              </OverlayTrigger>
            </div>
          )}

          <div className="name d-flex align-items-center ms-1">
            {journeyName ?? ''}
          </div>
        </div>

        <div className="d-flex justify-content-end">
          <div className="save-loader d-flex align-items-center me-2">
            {showLoader && (
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            )}

            {showSaved && (
              <div className="d-flex align-items-center">
                <svg
                  width="12"
                  height="11"
                  viewBox="0 0 12 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.914 1.91954L5.22712 10.6212C5.16083 10.7226 5.07331 10.8084 4.97062 10.8726C4.86793 10.9369 4.75251 10.978 4.63234 10.9933C4.51217 11.0086 4.39012 10.9976 4.27462 10.9611C4.15912 10.9246 4.05292 10.8634 3.96337 10.7818L0.273877 7.43638C0.109667 7.28709 0.0114904 7.07867 0.00094595 6.85699C-0.00959847 6.6353 0.0683526 6.41851 0.217651 6.2543C0.366949 6.09009 0.575364 5.99191 0.797048 5.98137C1.01873 5.97082 1.23553 6.04878 1.39974 6.19807L4.36097 8.88487L9.511 1.00386C9.63243 0.817816 9.82279 0.687627 10.0402 0.641935C10.2576 0.596242 10.4843 0.638789 10.6703 0.760216C10.8564 0.881643 10.9866 1.072 11.0323 1.28942C11.078 1.50683 11.0354 1.7335 10.914 1.91954Z"
                    fill="#2EB67D"
                  />
                </svg>
                <span className="ms-1 fs-14-2EB67D">Saved!</span>
              </div>
            )}
          </div>

          <div className="d-block d-md-none">
            <DropdownHamburger items={mobileActionsItems} />
          </div>

          <div className="d-none d-md-block">
            {desktopActionsItems.map(
              ({ title, showLoader, onClick, tooltip }, index) => (
                <OverlayTrigger
                  key={index}
                  placement="bottom"
                  overlay={
                    <Tooltip
                      id={`header-button-${index}`}
                      style={{ position: 'fixed' }}
                    >
                      {tooltip}
                    </Tooltip>
                  }
                >
                  <button
                    key={index}
                    className="btn px-2 ms-2 text-nowrap"
                    disabled={showLoader}
                    onClick={onClick}
                  >
                    {showLoader ? 'Loading...' : title}
                  </button>
                </OverlayTrigger>
              ),
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
