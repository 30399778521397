import { Dropdown } from 'react-bootstrap';
import { type ReactNode } from 'react';
import { type EventType } from '@base/models/common.model';
import { dropdownItems } from './AddEventDropdown.const';
import { AddEventDropdownToggle } from './AddEventDropdownToggle';

interface AddEventDropdownProps {
  buttonLabel: ReactNode;
  className?: string;
  onSelect: (type: EventType) => void;
}

export const AddEventDropdown = ({
  buttonLabel,
  className,
  onSelect,
}: AddEventDropdownProps) => {
  const handleSelect = (eventKey: string | null) => {
    if (!eventKey) {
      return;
    }

    onSelect(eventKey as EventType);
  };

  return (
    <Dropdown className="add-event-dropdown" onSelect={handleSelect}>
      <AddEventDropdownToggle label={buttonLabel} />

      <Dropdown.Menu>
        {dropdownItems.map((item) => (
          <Dropdown.Item key={item.label} eventKey={item.eventKey}>
            {item.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
