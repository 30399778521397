import { Alert, Button, ToggleSwitch } from '@Shared/DS';
import { type ModalProps } from '@Shared/utils/utils';
import { type Journey } from '@base/API';
import { Modal } from 'react-bootstrap';
import { usePlaybookActivationModal } from './usePlaybookActivationModal';
import './PlaybookActivationModal.scss';

interface IPlaybookActivationModal extends ModalProps {
  playbook: Journey;
}

export const PlaybookActivationModal = ({
  showModal,
  handleShowModal,
  playbook,
}: IPlaybookActivationModal) => {
  const {
    isBusy,
    preActivationChecklist,
    isCheckListRunning,
    keepMicrosite,
    setKeepMicrosite,
    activatePlaybook,
  } = usePlaybookActivationModal(playbook, showModal, handleShowModal);

  return (
    <Modal
      fullscreen={'md-down'}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="activate-playbook"
      backdrop="static"
      show={showModal}
      onHide={() => handleShowModal(false)}
    >
      <Modal.Header>
        <Modal.Title>Ready to launch? 🚀</Modal.Title>
        <button
          type="button"
          className="btn-close modal-close-btn"
          data-bs-dismiss="modal"
          onClick={() => handleShowModal(false)}
          aria-label="Close"
        ></button>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="d-flex align-items-center justify-content-between mt-3 pe-5">
            <div>Do you want to keep the microsite?</div>
            <ToggleSwitch
              initialValue={keepMicrosite}
              setValue={() => setKeepMicrosite(!keepMicrosite)}
            />
          </div>

          <div className="mt-3">
            {isCheckListRunning ? (
              <Alert
                type="info"
                text="Please wait until pre-activation checklist is complete."
                icon="bi bi-info-circle-fill"
              />
            ) : preActivationChecklist.some((item) => item.error) ? (
              <Alert
                type="warning"
                text="Looks like some events may be in the past, or failed. Double check the events in the calendar."
                icon="bi bi-exclamation-triangle text-warning"
              />
            ) : (
              <Alert
                type="success"
                text="Well done! All checks are passed. 🥳"
                icon="bi bi-check-circle-fill text-success"
              />
            )}
          </div>
          <div className="mx-2">
            {preActivationChecklist.map((item) => (
              <div className="d-flex align-items-center mt-2" key={item.text}>
                {item.isLoading ? (
                  <div
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : !!item.error ? (
                  <>
                    <i className="bi bi-exclamation-triangle text-warning me-2"></i>
                  </>
                ) : (
                  <i className="bi bi-check-circle-fill text-success me-2"></i>
                )}

                {item.text}
              </div>
            ))}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex w-100 gap-2 justify-content-end">
          <Button
            buttonStyle="outline"
            text="Cancel"
            onClick={() => handleShowModal(false)}
          />
          <Button
            text="Activate"
            showLoading={isBusy}
            disabled={isCheckListRunning || isBusy}
            onClick={activatePlaybook}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};
