import { Col, Modal, Row } from 'react-bootstrap';
import { type ReactNode, memo } from 'react';
import { type ModalProps } from '@Shared/utils/utils';
import { Button } from '@Shared/DS';

interface IInfoModal extends ModalProps {
  title: string;
  description: string | ReactNode | ReactNode[];
  onClickConfirmButton: Function;
  isDeleteButton?: boolean;
  isLoading?: boolean;
  size?: 'sm' | 'md' | 'lg' | 'xl';
}

export const InfoModal = memo((props: IInfoModal) => (
  <Modal
    size={props.size === 'md' ? undefined : (props.size ?? 'lg')}
    fullscreen={'md-down'}
    aria-labelledby="contained-modal-title-vcenter"
    centered
    backdrop="static"
    show={props.showModal}
    onHide={() => props.handleShowModal(false)}
  >
    <Modal.Header>
      <Modal.Title>
        <div>{props.title}</div>
      </Modal.Title>
      <button
        type="button"
        className="btn-close modal-close-btn"
        data-bs-dismiss="modal"
        onClick={() => props.handleShowModal(false)}
        aria-label="Close"
      ></button>
    </Modal.Header>
    <Modal.Body>
      <Row>
        <Col>
          <div className="fs-15-mixed">{props.description}</div>
        </Col>
      </Row>
    </Modal.Body>
    <Modal.Footer>
      <div className="d-flex justify-content-end">
        <Button
          buttonStyle="outline"
          text="Cancel"
          onClick={() => props.handleShowModal(false)}
        />
        {props.isDeleteButton ? (
          <button
            className="btn btn-button-delete mx-3"
            disabled={props.isLoading}
            onClick={() => props.onClickConfirmButton()}
          >
            {props.isLoading ? 'Deleting...' : 'Delete'}
          </button>
        ) : (
          <Button
            additionalClasses="ms-2"
            text="Confirm"
            disabled={props.isLoading}
            showLoading={props.isLoading}
            onClick={() => props.onClickConfirmButton()}
          />
        )}
      </div>
    </Modal.Footer>
  </Modal>
));
