import { type Channel } from '@base/API';
import { type EventType } from '@base/models/common.model';
import mixpanel from 'mixpanel-browser';

export class Analytics {
  private static _instance?: Analytics;

  static init(params: {
    token: string;
    trackPageView: boolean;
    debug: boolean;
  }) {
    mixpanel.init(params.token, {
      track_pageview: params.trackPageView,
      debug: params.debug,
    });
    this._instance = new Analytics();
  }

  static user(params: {
    userId: string;
    organizationId: string;
    email: string;
    name: string;
  }) {
    try {
      mixpanel.identify(params.userId);
      mixpanel.people.set({ ...params, $email: params.email });
    } catch (e: any) {
      console.error(e.message);
    }
  }

  static get instance() {
    if (!this._instance) {
      throw new Error('Analytics should be inited at first');
    }

    return this._instance;
  }

  private track(eventName: string, params: Object) {
    try {
      mixpanel.track(eventName, params);
    } catch (e: any) {
      console.error(e.message);
    }
  }

  static clientInit(loadTimeSec: number) {
    this.instance.track('client_init', { loadTimeSec });
  }

  static clientError(error: Error) {
    this.instance.track('client_error', { error });
  }

  static organizationCreated(name: string, adminEmail: string) {
    this.instance.track('organization_created', { name, adminEmail });
  }

  static playbookCreated(
    type: string,
    createdFromTemplate: boolean,
    templateId?: string,
  ) {
    this.instance.track('playbook_created', { type, createdFromTemplate });
  }

  static playbookActivated(id: string) {
    this.instance.track('playbook_activated', { id });
  }

  static playbookPaused(id: string) {
    this.instance.track('playbook_paused', { id });
  }

  static playbookStarted(id: string) {
    this.instance.track('playbook_started', { id });
  }

  static playbookUserAssigned(playbookId: string) {
    this.instance.track('playbook_user_assigned', { playbookId });
  }

  static playbookCompleted(id: string) {
    this.instance.track('playbook_completed', { id });
  }

  static userInvited(organizationId: string, existingUser: boolean) {
    this.instance.track('user_invited', { organizationId, existingUser });
  }

  static googleIntegrationAdded(organizationId: string) {
    this.instance.track('google_integration_added', { organizationId });
  }

  static slackIntegrationAdded(organizationId: string) {
    this.instance.track('slack_integration_added', { organizationId });
  }

  static outlookIntegrationAdded(organizationId: string) {
    this.instance.track('outlook_integration_added', { organizationId });
  }

  static msTeamsIntegrationAdded(organizationId: string) {
    this.instance.track('teams_integration_added', { organizationId });
  }

  static playbookMeetingAdded(playbookId: string) {
    this.instance.track('playbook_meeting_added', { playbookId });
  }

  static playbookEventAdded(type: EventType, channel: Channel) {
    this.instance.track('playbook_event_added', { type, channel });
  }

  static playbookMicrositeBlockAdded(type: string) {
    this.instance.track('playbook_microsite_block_added', { type });
  }
}
