import { useEffect, useState } from 'react';
import { type DropResult } from 'react-beautiful-dnd';
import { type Milestone } from '@API';
import { reorderBlocks, updateBlockOrderNo } from '@api/apis';
import { handleReordering, sortBlocksByOrderNumber } from './helper';

export const useBlocksLoop = (
  milestones: Milestone[],
  selectedMilestoneIndex: number,
  setMilestones: Function,
  setIsBusy: Function,
  isParentPlaybook: boolean,
) => {
  const [blocks, setBlocks] = useState<any>([]);

  const handleOnDragEnd = async (result: DropResult) => {
    if (
      result.destination &&
      result.source.index !== result.destination.index
    ) {
      setIsBusy(true);
      milestones[selectedMilestoneIndex] = handleReordering(
        result.source.index,
        result.destination.index,
        milestones[selectedMilestoneIndex],
        blocks[result.source.index],
      );
      setMilestones([...milestones]);

      await Promise.all([
        updateBlockOrderNo(
          blocks[result.source.index].id,
          result.destination.index + 1,
          blocks[result.source.index].type,
          !isParentPlaybook,
        ),
        reorderBlocks(
          milestones[selectedMilestoneIndex],
          blocks[result.source.index].id,
          result.source.index,
          result.destination.index,
        ),
      ]);
      setIsBusy(false);
    }
  };

  useEffect(() => {
    const sortedBlocks = sortBlocksByOrderNumber(
      milestones[selectedMilestoneIndex],
    );
    setBlocks([...sortedBlocks]);
  }, [milestones, selectedMilestoneIndex]);

  return {
    blocks,
    handleOnDragEnd,
  };
};
