import { type JourneyStatus } from '@API';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import './JourneyAssigneeCard.scss';

interface IJourneyAssigneeCard {
  progress?: number;
  journeyStatus?: JourneyStatus;
}

export const JourneyAssigneeCard = (props: IJourneyAssigneeCard) => {
  const { journeyAssignee } = useUserContext();

  return journeyAssignee ? (
    <div className="d-flex justify-content-center align-items-center journey-assignee">
      <img
        alt={journeyAssignee.firstName ?? 'user name'}
        src={journeyAssignee.photoUrl}
        className="rounded-circle me-2"
        width="35"
        height="35"
      ></img>{' '}
      <div className="journey-assignee-name me-2">{`${journeyAssignee.firstName} ${journeyAssignee.lastName}`}</div>
    </div>
  ) : null;
};
