import {
  type Event,
  type Journey,
  type User,
  type KinfolkTemplate,
} from '@base/API';

export interface IPlaybookCalendarSchedules {
  weekLabel: string;
  days: {
    day: string;
    daysFrom: number;
    isStartDay?: boolean;
    events: IEvent[];
  }[];
}

export interface IEvent extends Event {
  isActionReminder?: boolean;
  eventId?: string;
  userName?: string;
  userSlackId?: string;
}

export interface IPlaybookHome {
  playbook: Journey;
  assignee?: User;
}
export interface ITemplateHome {
  template: KinfolkTemplate;
  isEditingAllowed: boolean;
}

export enum TabKeys {
  CALENDAR = 'Calendar',
  PARTICIPANTS = 'Assignees',
  MEETINGS = 'Meetings',
  MICROSITE = 'Microsite',
}
