export const sureString = (
  value: string | null | undefined,
  defaultValue?: string,
  errorMessage?: string,
): string => {
  if (!value) {
    if (defaultValue !== undefined) {
      return defaultValue;
    }

    throw new Error(errorMessage ?? 'Value is null or undefined');
  }

  return value;
};
