import { addNotificationToApp, isWorkingEmail } from '@Shared/utils/utils';
import { signUp, signInWithRedirect } from 'aws-amplify/auth';
import { type FormEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const useSignup = () => {
  const [userEmail, setUserEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleGoogleSignUp = () => {
    signInWithRedirect({ provider: 'Google' });
  };

  const handleSignUpWithEmailAndPassword = async (
    e: FormEvent<HTMLFormElement>,
  ) => {
    try {
      e.preventDefault();
      setIsSubmitted(true);
      if (userEmail.length > 0 && password.length > 7) {
        if (!isWorkingEmail(userEmail)) {
          addNotificationToApp(
            'Please use your work email to sign up.',
            'error',
          );
          return;
        }

        setIsLoading(true);
        await signUp({
          username: userEmail,
          password: password,
          options: {
            userAttributes: { email: userEmail },
            autoSignIn: true,
          },
        });

        setIsLoading(false);
        navigate('/account-verification', { state: { email: userEmail } });
      }
      setIsLoading(false);
    } catch (err: any) {
      console.error(err);
      addNotificationToApp(err.message, 'error');
      setIsLoading(false);
    }
  };

  const handleLogin = () => {
    navigate('/login');
  };

  return {
    userEmail,
    password,
    isSubmitted,
    isLoading,
    setUserEmail,
    setPassword,
    handleSignUpWithEmailAndPassword,
    handleLogin,
    handleGoogleSignUp,
  };
};
