import { useNavigate } from 'react-router-dom';
import { Button, EmptyState } from '@Shared/DS';
import styles from './NoAutomations.module.scss';

interface NoAutomationsProps {
  formId: string;
}

export const NoAutomations = ({ formId }: NoAutomationsProps) => {
  const navigate = useNavigate();

  const editForm = () =>
    navigate(`/form/${formId}`, { state: { isInEditingMode: true } });

  return (
    <EmptyState
      className={styles.emptyState}
      title="This form has no automation yet"
      description={
        <p>
          If you'd like to reuse the collected data and streamline your
          processes, just edit the form to set up automations.
        </p>
      }
      button={
        <Button buttonStyle="outline" onClick={editForm} text="Edit form" />
      }
    />
  );
};
