import {
  calculateSendTimeDate,
  convertHtmlToChannelSpecificFormat,
  isInPast,
  updateMessageDescription,
} from '@Shared/utils/utils';
import {
  listPlaybookEvents,
  updatePlaybookEvent,
  updatePlaybookEventReminder,
} from '@api/apis';
import {
  type AssigneeRole,
  type Event,
  type EventReminder,
  EventStatus,
  JourneyStatus,
  type TemplateStatus,
  type User,
} from '@base/API';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { EventType } from '@base/models/common.model';
import { type IEvent } from '@base/models/playbookHome.model';
import { useCallback, useMemo } from 'react';

export const useEvents = () => {
  const { user } = useUserContext();

  const getEventStatus = useCallback(
    (
      currentStatus: EventStatus,
      isParentPlaybook: boolean,
      playbookStatus: JourneyStatus | TemplateStatus,
      date?: string,
    ) => {
      if (
        currentStatus === EventStatus.DONE ||
        currentStatus === EventStatus.SENT ||
        (date && currentStatus === EventStatus.OVERDUE && isInPast(date))
      ) {
        return currentStatus;
      }

      return playbookStatus === JourneyStatus.DRAFT || isParentPlaybook || !date
        ? EventStatus.DRAFT
        : isInPast(date)
          ? EventStatus.SKIPPED
          : EventStatus.SCHEDULED;
    },
    [],
  );

  const getDaysFrom = useCallback((selectedDaysFrom: string) => {
    if (selectedDaysFrom === 'Start day') {
      return 0;
    }

    if (selectedDaysFrom.includes('Before')) {
      return -+selectedDaysFrom.split(' ')[0];
    }

    return +selectedDaysFrom.split(' ')[2] - 1;
  }, []);

  const getSendTimeDate = useCallback(
    (
      selectedDaysFrom: string,
      playbookStartDate?: string | null,
      sendTime?: string,
    ) => {
      if (!playbookStartDate) {
        return;
      }

      return calculateSendTimeDate(
        playbookStartDate,
        getDaysFrom(selectedDaysFrom),
        sendTime,
      );
    },
    [getDaysFrom],
  );

  const mapDaysFromToLabel = useCallback((daysFrom: number) => {
    if (daysFrom < 0) {
      return `${Math.abs(daysFrom)} Work Days Before Start`;
    } else if (daysFrom > 0) {
      return `Work Day ${daysFrom + 1}`;
    }

    return 'Start day';
  }, []);

  const getUserName = useCallback(
    (event: IEvent, role: string, user?: User): string => {
      if (user?.firstName && role.length) {
        return `${role} (${user.firstName} ${user.lastName ?? ''})`;
      } else if (user?.firstName) {
        return `${user.firstName} ${user.lastName ?? ''}`;
      } else if (user?.email && role.length) {
        return `${role} (${user.email})`;
      } else if (user?.email) {
        return `${user.email}`;
      } else if (event.emailTo && event.type !== EventType.MESSAGE) {
        return `${event.emailTo}`;
      } else if (role.length && role !== 'Assignee' && !user?.id) {
        return `${role}`;
      } else if (event.type === EventType.MESSAGE) {
        return 'Assignee';
      }

      return 'Unassigned';
    },
    [],
  );

  const uploadEventDescription = useCallback(
    async (
      event: Event,
      text: string,
      eventId: string,
      builderId: string,
      isParentPlaybook: boolean,
    ): Promise<string> => {
      const message = await updateMessageDescription(
        text,
        user.Organization.id,
        builderId,
        eventId,
      );
      event.message = convertHtmlToChannelSpecificFormat(
        message,
        event.channel,
      );
      if (event.message) {
        event.message = event.message.replaceAll('<p><br></p>', '').trim();

        const id = await updatePlaybookEvent({
          id: eventId,
          message: event.message,
          updatedInChild: !isParentPlaybook,
        } as Event);
        if (id) {
          return event.message;
        } else {
          return text;
        }
      }

      return text;
    },
    [user?.Organization?.id],
  );

  const updatePlaybookEventsSendTimeDate = async (
    journeyId: string,
    startDate: string,
  ) => {
    const events = await listPlaybookEvents(journeyId);
    let sideEffects: any = [];

    if (!events) {
      return;
    }

    events.forEach((event) => {
      sideEffects = [
        ...sideEffects,
        updatePlaybookEvent({
          id: event.id,
          sendTimeDate: calculateSendTimeDate(
            startDate,
            event.daysFrom,
            event.sendTime?.toString(),
          ),
        } as Event),
      ];

      const reminders = event.reminders?.items;
      if (!reminders) {
        return;
      }

      reminders.forEach((reminder) => {
        if (!reminder) {
          return;
        }

        sideEffects = [
          ...sideEffects,
          updatePlaybookEventReminder({
            id: reminder?.id,
            sendTimeDate: calculateSendTimeDate(
              startDate,
              event.daysFrom,
              event.sendTime?.toString(),
            ),
          } as EventReminder),
        ];
      });
    });

    await Promise.all(sideEffects);
  };

  const getAssigneeRole = (role?: string | null) => {
    if (role === 'Assignee' || role === 'Assignee personal email') {
      return null;
    }

    if (role && role.length) {
      return role.replaceAll(' ', '').toUpperCase() as AssigneeRole;
    }

    return null;
  };

  const daysFromOptions = useMemo(() => {
    const result = [];
    for (let i = 65; i > 0; i--) {
      result.push(`${i} Work Days Before Start`);
    }

    result.push('Start day');

    for (let i = 2; i <= 365; i++) {
      result.push(`Work Day ${i}`);
    }
    return result;
  }, []);

  return {
    getEventStatus,
    getDaysFrom,
    getSendTimeDate,
    mapDaysFromToLabel,
    uploadEventDescription,
    updatePlaybookEventsSendTimeDate,
    getUserName,
    getAssigneeRole,
    daysFromOptions,
  };
};
