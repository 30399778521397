import { Form } from '@Shared/Components/Form';
import { parseFormUrl } from './EmbeddedForm.utils';

interface EmbeddedFormProps {
  formUrl: string;
}

export const EmbeddedForm = ({ formUrl }: EmbeddedFormProps) => {
  const { id, slug } = parseFormUrl(formUrl);

  return <Form isEmbedded formId={id} formSlug={slug} />;
};
