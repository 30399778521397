import clsx from 'clsx';
import { Offcanvas, Row, Tab, Tabs } from 'react-bootstrap';
import { useTabs } from '@Hooks/useTabs';
import { Channel } from '@API';
import { type AddMessageModalProps } from '@base/models/journeyLibrary.model';
import styles from './AddMessageModal.module.scss';
import { EmailTab } from './EmailTab/EmailTab';
import { MessengerTab } from './MessengerTab/MessengerTab';

export const AddMessageModal = (props: AddMessageModalProps) => {
  const { key, setKey } = useTabs(
    props.messageEvent.id === undefined ||
      props.messageEvent.channel === Channel.EMAIL
      ? 'emailMessage'
      : 'slackMessage',
  );

  return (
    <Offcanvas
      className={clsx(styles.addMessageModalWrapper, 'tabs-wrapper')}
      backdrop="static"
      placement="end"
      show={props.showModal}
      onHide={() => props.onHideModal()}
    >
      <Offcanvas.Body>
        <Row>
          <button
            type="button"
            className={clsx(styles.btnClose, 'btn-close ms-auto me-3')}
            aria-label="Close"
            onClick={() => props.onHideModal()}
          ></button>
          <Tabs
            id="messageTabs"
            activeKey={key}
            onSelect={(k) => setKey(k || '')}
            className="mb-3 ps-3"
          >
            <Tab
              disabled={
                props.messageEvent.id !== undefined &&
                props.messageEvent.channel === Channel.SLACK
              }
              eventKey="emailMessage"
              title="Email"
            >
              <EmailTab {...props} />
            </Tab>
            <Tab
              disabled={
                props.messageEvent.id !== undefined &&
                props.messageEvent.channel === Channel.EMAIL
              }
              eventKey="slackMessage"
              title="Messenger"
            >
              <MessengerTab {...props} />
            </Tab>
          </Tabs>
        </Row>
      </Offcanvas.Body>
    </Offcanvas>
  );
};
