import { ToggleSwitch } from '@Shared/DS';
import './NotificationSettings.scss';
import { useNotificationSettings } from './useNotificationSettings';

export const NotificationSettings = () => {
  const { issueNotificationDigest, isBusy, saveIssueNotificationSettings } =
    useNotificationSettings();

  return (
    <div className="notification-settings mt-5">
      <div className="row justify-content-center">
        <div className="col-12 col-md-4">
          <div>
            <h1 className="fs-20-semi-bold">Issue Notifications</h1>
            <p>
              Stay informed with daily email alerts for any anomalies in your
              runbooks.
            </p>
          </div>
        </div>

        <div className="col-12 col-md-8">
          <div className="settings-list-container py-md-3 px-md-4">
            <div className="controllers-container">
              <div className="d-flex gap-5 align-items-center">
                <label>Receive issue notifications</label>
                <ToggleSwitch
                  disabled={isBusy}
                  initialValue={!!issueNotificationDigest.isSubscribed}
                  setValue={() =>
                    saveIssueNotificationSettings(
                      !issueNotificationDigest.isSubscribed,
                    )
                  }
                />
              </div>

              {/* <div className="d-flex justify-content-between align-items-center mt-3">
                <label>Frequency</label>

                <ToggleDropDownSimple
                  items={['Hourly', 'Daily', 'Weekly', 'Monthly']}
                  selectedItem={'Hourly'}
                  disabled={true}
                  onSelect={(value) => setIssueNotificationDigest({ ...issueNotificationDigest, frequency: value as Frequency })}
                />
              </div>

              <div className="d-flex justify-content-between align-items-center mt-3">
                <label>Channel</label>

                <ToggleDropDownSimple
                  disabled={true}
                  items={['Email', 'Slack', 'Microsoft Teams']}
                  selectedItem={sureString(issueNotificationDigest.channel, '')}
                  onSelect={(value) => setIssueNotificationDigest({ ...issueNotificationDigest, channel: value.toUpperCase() as Channel })}
                />
              </div> */}

              {/* <div className="d-flex justify-content-end">
                <Button
                  additionalClasses="mt-3"
                  disabled={isBusy}
                  showLoading={isBusy}
                  text={'Save'}
                  onClick={saveIssueNotificationSettings}
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
