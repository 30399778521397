import { useState, memo } from 'react';
import { Navbar } from '@Shared/DS';
import { BsArrowBarLeft, BsArrowBarRight } from 'react-icons/bs';
import { NavFloatingMenu } from '../../Molecules';

import './Sidebar.scss';

export const Sidebar = memo(() => {
  const [collapsed, setCollapsed] = useState(false);
  const toggleSidebar = () => setCollapsed(!collapsed);

  return (
    <div className={`sidebar ${collapsed ? 'collapsed' : ''}`}>
      <NavFloatingMenu />
      <Navbar />
      <button
        className="toggle-button position-fixed bottom-0"
        onClick={toggleSidebar}
      >
        {collapsed ? <BsArrowBarRight /> : <BsArrowBarLeft />}
      </button>
    </div>
  );
});
