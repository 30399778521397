import { Row, Col, Card } from 'react-bootstrap';
import { type User } from '@API';

const Happy = (props: { user: User | undefined }) => {
  const { user } = props;

  return (
    <div className="happy-wrapper">
      <Card>
        <Card.Body>
          <Row>
            <Col xs="10">
              <h4 className="fs-20-semi-bold-4C5564">I'm most happy when...</h4>
            </Col>
          </Row>
          <p className="ms-2 fs-16-626A77">{user?.mostHappy}</p>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Happy;
