import clsx from 'clsx';
import { type Automation } from '../ActivityPanel.types';
import { statusToIcon, statusToClassName } from './AutomationStatus.const';
import styles from './AutomationStatus.module.scss';

interface AutomationStatusProps {
  status: Automation['status'];
}

export const AutomationStatus = ({ status }: AutomationStatusProps) => {
  const Icon = statusToIcon[status];
  const className = statusToClassName[status];

  return (
    <span className={clsx(styles.status, className)}>
      <Icon className={styles.icon} />
    </span>
  );
};
