import { Col, Modal, Row } from 'react-bootstrap';
import { TextField } from '@Shared/DS/Molecules';
import { type GoogleCalendarKeysModalProps } from '@base/models/settings.model';
import { Button } from '@Shared/DS';
import { useAddCalendarKeysModal } from './useAddCalendarKeysModal';

export const AddCalendarKeysModal = (props: GoogleCalendarKeysModalProps) => {
  const {
    isSubmitted,
    isSaving,
    clientEmail,
    privateKey,
    setClientEmail,
    setPrivateKey,
    addGoogleCalendarKeys,
  } = useAddCalendarKeysModal(props);

  return (
    <Modal
      size="lg"
      fullscreen={'md-down'}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      show={props.showModal}
      onHide={() => props.handleShowModal(false)}
    >
      <Modal.Header>
        <Modal.Title className="fs-24-25272a">{'Calendar API key'}</Modal.Title>
        <button
          type="button"
          className="btn-close modal-close-btn"
          data-bs-dismiss="modal"
          onClick={() => props.handleShowModal(false)}
          aria-label="Close"
        ></button>
      </Modal.Header>

      <Modal.Body>
        <span className="fs-16-25272a">
          Paste the content of the JSON key and click save.
        </span>
        <Row>
          <Col>
            <form>
              <label
                className="fs-15-medium-bold-4c5564 text-start mt-2"
                htmlFor={'clientEmail'}
              >
                Add Client Email
              </label>
              <TextField
                id={'clientEmail'}
                type="text"
                defaultValue={clientEmail}
                setValue={setClientEmail}
                fieldName="Client Email"
                placeholder="Integration email address"
                additionalClasses={`
              ${!clientEmail && clientEmail.length === 0 && isSubmitted ? 'is-invalid' : ''}`}
              />

              <label
                className="fs-15-medium-bold-4c5564 text-start mt-4"
                htmlFor={'privateKey'}
              >
                Add Private Key
              </label>
              <TextField
                id={'privateKey'}
                type="password"
                defaultValue={privateKey}
                setValue={setPrivateKey}
                fieldName="Private Key"
                placeholder="Paste Private API key"
                additionalClasses={`
              ${!privateKey && privateKey.length === 0 && isSubmitted ? 'is-invalid' : ''}`}
              />
            </form>
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <div className="d-flex justify-content-end">
          <Button
            buttonStyle="outline"
            text="Cancel"
            onClick={() => props.handleShowModal(false)}
          />
          <Button
            text="Save"
            disabled={isSaving}
            showLoading={isSaving}
            additionalClasses="ms-2"
            onClick={() => addGoogleCalendarKeys()}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};
