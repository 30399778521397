import { getDynamicTags } from '@api/apis';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { Keys } from '@base/keys/queryKeys';
import { useQuery } from '@tanstack/react-query';

export const useDynamicTags = () => {
  const { user } = useUserContext();

  const { data: dynamicTags, isLoading } = useQuery({
    queryKey: Keys.getOrgDynamicTags(user.userOrganizationId),
    queryFn: async () => {
      const tags = await getDynamicTags(user.userOrganizationId);
      if (!tags) {
        return [];
      }

      return Object.entries(tags)
        .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
        .map(([key, value]) => `${value.name} {{${key}}}`);
    },
    staleTime: 600000,
    enabled: !!user.userOrganizationId,
  });

  return {
    dynamicTags,
    isLoading,
  };
};
