import { type Badge } from '@base/API';

export const useSelectBadgeDropdown = () => {
  const sortBadges = (firstBadge: Badge, secondBadge: Badge): number => {
    const firstBadgeName = firstBadge.name ?? '';
    const secondBadgeName = secondBadge.name ?? '';

    if (firstBadgeName && secondBadgeName) {
      return (
        parseInt(firstBadgeName.split(' ')[1]) -
        parseInt(secondBadgeName.split(' ')[1])
      );
    }

    return 0;
  };

  return {
    sortBadges,
  };
};
