import { memo } from 'react';

export default memo(function ProfilePhotoAWS(props: any) {
  return (
    <img
      key={props.propsKey}
      alt="latest update card"
      src={props.photoUrl}
      className="rounded-circle settings-card-image"
    ></img>
  );
});
