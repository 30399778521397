import type { ISvgIcon } from './types';

export const TemplatesIcon = ({
  className,
  width = 20,
  height = 20,
}: ISvgIcon) => (
  <svg
    className={className}
    fill="none"
    height={height}
    viewBox="0 0 20 20"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <rect width="20" height="20" fill="url(#pattern2)" />
    <defs>
      <pattern
        id="pattern2"
        patternContentUnits="objectBoundingBox"
        width="1"
        height="1"
      >
        <use xlinkHref="#image0_576_901" transform="scale(0.01)" />
      </pattern>
      <image
        id="image0_576_901"
        width="100"
        height="100"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAABmJLR0QA/wD/AP+gvaeTAAACiUlEQVR4nO3dMW7TUBzH8d9zyliQEBtlqZSgsPQUSEhsDIgT5AA5RRd2eoSeoByBkalSzNiWqQtlTfoYUgSFgu2nv6Nf6u9nq2Ren/qV/WzHMRIAAAAAbFZq2mA8nr5WVR1J+ekmJnSPnaesWV2fnvxvo6pxmCp9IEaIvZx01LRRcxBpL2AyWHvWtEGbINigna7/4MvitHHdwS/j5y9yl+3ZQ8wQxAxBzBDEDEHMEMQMQcx0vg7pel6NbthDzBDEDEHMcC+rZ9zL2nIEMUMQMwQxQxAznc+y/mXAT6e0epqkrbg9ZLhPp7R6mqStyEPWkJ9OaXyapC3WEDNha8if7vsVfV93vdlDzBDEDEHM9LaG8MliGfYQM5FBzgPH2jZnUQOFBUlZMw0zytm10ixqsLA15OZeTtgV61CxhpghiBmCmAm9DtmfTsejlQ6V0ktJu5Fj/+Z7kk6Ul/O6ri+c51EibA/Zn07Ho+v0SSm9UX9/BEnazdLbnHY+TyaTvz5/cZlHqbAgo5UOJT2OGq+FJ9fpwXvXeZSKW0PWh4eNStIr23kUilxDbh0e+vo85PY9svzIeB5FOMsyQxAzBDFDEDMEMUMQMwQxQxAzBDFDEDMEMUMQMwQxQxAzBDFDEDMEMUMQMwQxQxAzBDFDEDMEMUMQMwQxQxAzBDET+WzvlaSHP3/YzPfU0zfjeRSJ+xau9DFqrLbyHb/TZR6l4g5ZeTmXdBk2XrPLkZZz23kUCgtS1/VFpdVBVjrW+rDRl6usdFxpdbBYLL66zqNU6HcMbyb2LnLMbZ5HCc6yzBDEDEHMEMQMQczwf1CZYQ8x0ybIEF9K1pfGN881Bhnwm+Kihb55DgAAAACi/ABTgKbvIBk13QAAAABJRU5ErkJggg=="
      />
    </defs>
  </svg>
);
