import clsx from 'clsx';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface Bar {
  percentage: number;
  bg: 'bg-info' | 'bg-success' | 'bg-danger' | 'bg-warning';
  title?: string;
  label?: string;
}

interface ProgressBarProps {
  bars: Bar[];
  height?: string;
  additionalClasses?: string;
}

export const ProgressBar = (props: ProgressBarProps) => (
  <div className={`progress ${clsx(props.additionalClasses)}`}>
    {props.bars.map((bar, index) => (
      <OverlayTrigger
        key={index}
        placement={'bottom'}
        overlay={
          bar.title ? (
            <Tooltip id={`tooltip-${bar.title}`} style={{ position: 'fixed' }}>
              {bar.title}
            </Tooltip>
          ) : (
            <></>
          )
        }
      >
        <div
          className={`progress-bar ${bar.bg}`}
          role="progressbar"
          style={{ width: `${bar.percentage}%` }}
        >
          {bar.label ?? ''}
        </div>
      </OverlayTrigger>
    ))}
  </div>
);
