import {
  createPlaybookAssigneeBadges,
  getBadge,
  listBadges,
  listChildJourneys,
  removePlaybookAssigneeBadges,
  updateJourney,
} from '@api/apis';
import {
  type Badge,
  BuilderType,
  type Journey,
  JourneyStatus,
  type ModelBadgeFilterInput,
  PlaybookMode,
} from '@base/API';
import { type IPlaybookSettingsModal } from '@base/models/journeyAssignees.model';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Keys } from '@base/keys/queryKeys';
import { useEvents } from '@base/Hooks/useEvents';

export const useJourneySettingsModal = ({
  parentPlaybook,
  showModal,
  handleShowModal,
  updateParentPlaybook,
}: IPlaybookSettingsModal) => {
  const [startDate, setStartDate] = useState('');
  const [isBusy, setIsBusy] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showMicrosite, setShowMicrosite] = useState(true);
  const [showCustomBadgeNameField, setShowCustomBadgeNameField] =
    useState(false);
  const [customBadgeName, setCustomBadgeName] = useState('');
  const [selectedBadge, setSelectedBadge] = useState({} as Badge);
  const [lockChapters, setLockChapters] = useState(false);
  const { id } = useParams();
  const { updatePlaybookEventsSendTimeDate } = useEvents();

  const { data: badges } = useQuery({
    queryKey: [Keys.getKinfolkBadges()],
    queryFn: () => listBadges({} as ModelBadgeFilterInput),
  });

  const { data: childPlaybooks } = useQuery({
    queryKey: Keys.getChildPlaybooks(id),
    queryFn: () => {
      if (!id) {
        return [];
      }

      return listChildJourneys(id);
    },
  });

  const getBadgeQueryKey = useMemo(
    () => [...Keys.getPlaybookBadge(parentPlaybook?.id), parentPlaybook],
    [parentPlaybook],
  );

  const { data: journeyBadge } = useQuery({
    queryKey: getBadgeQueryKey,
    queryFn: () => getBadge(parentPlaybook.badgeID ?? ''),
    enabled: !!parentPlaybook.badgeID,
  });

  const handleSave = async () => {
    if (!childPlaybooks) {
      return;
    }

    setIsSubmitted(true);
    setIsBusy(true);
    const journeys = [
      ...childPlaybooks.filter(
        (journey) => journey.status !== JourneyStatus.COMPLETED,
      ),
      parentPlaybook,
    ];
    if (
      parentPlaybook.badgeID !== selectedBadge.id ||
      parentPlaybook.customBadgeName !== customBadgeName ||
      lockChapters !== parentPlaybook.lockChapters ||
      showMicrosite !== (parentPlaybook.mode === PlaybookMode.IMMERSIVE)
    ) {
      const mode = showMicrosite
        ? PlaybookMode.IMMERSIVE
        : PlaybookMode.WORKFLOW;
      updateJourney({
        id: parentPlaybook.id,
        badgeID: selectedBadge.id ?? null,
        lockChapters,
        mode,
        customBadgeName: customBadgeName.length > 0 ? customBadgeName : null,
      } as Journey);

      // Check if badge is removed then delete all the rewarded badges of the assignees
      // otherwise reward badges to the assignees who has completed this journey
      if (parentPlaybook.badgeID && !selectedBadge.id) {
        removePlaybookAssigneeBadges(parentPlaybook.id);
      } else if (!parentPlaybook.badgeID && selectedBadge.id) {
        createPlaybookAssigneeBadges(parentPlaybook.id);
      }

      updateParentPlaybook({
        ...parentPlaybook,
        badgeID: selectedBadge.id,
        lockChapters,
        customBadgeName: customBadgeName.length > 0 ? customBadgeName : null,
      });
      onHideModal();
    }

    await handlePlaybookUpdates(journeys);

    setIsBusy(false);
  };

  const onHideModal = () => {
    handleShowModal(false);
    setIsSubmitted(false);
    setCustomBadgeName('');
    setShowCustomBadgeNameField(false);
  };

  const handlePlaybookUpdates = async (playbooks: Journey[]) => {
    // set it to true if runbook type is other than INITIATIVE or date is defined.
    const isNotInitiativeOrHasStartDate =
      parentPlaybook.type !== BuilderType.INITIATIVE || startDate.length > 0;

    const isStartDateChanged =
      startDate.length > 0 && startDate !== parentPlaybook.startDate;
    const isLockChaptersChanged =
      lockChapters !== !!parentPlaybook.lockChapters;
    const isPlaybookModeChanged =
      showMicrosite !== (parentPlaybook.mode === PlaybookMode.IMMERSIVE);

    if (
      isNotInitiativeOrHasStartDate &&
      (isStartDateChanged || isLockChaptersChanged || isPlaybookModeChanged)
    ) {
      await updatePlaybooks(playbooks, isStartDateChanged);

      onHideModal();
    }
  };

  const removeBadge = () => {
    setSelectedBadge({} as Badge);
  };

  const removeCustomBadgeName = () => {
    setShowCustomBadgeNameField(false);
    setCustomBadgeName('');
  };

  const updatePlaybooks = async (
    playbooks: Journey[],
    isStartDateChanged: boolean,
  ) => {
    let request = {
      lockChapters,
      mode: showMicrosite ? PlaybookMode.IMMERSIVE : PlaybookMode.WORKFLOW,
    } as Journey;

    if (isStartDateChanged) {
      request['startDate'] = new Date(startDate).toISOString().split('T')[0];
    }

    await Promise.all(
      playbooks.map((playbook) => {
        let sideEffects: any = [
          updateJourney({
            ...request,
            id: playbook.id,
          }),
        ];

        if (isStartDateChanged) {
          sideEffects = [
            ...sideEffects,
            updatePlaybookEventsSendTimeDate(playbook.id, startDate),
          ];
        }

        return sideEffects;
      }),
    );
  };

  useEffect(() => {
    if (parentPlaybook && showModal) {
      parentPlaybook.startDate && setStartDate(parentPlaybook.startDate);
      setShowMicrosite(parentPlaybook.mode === PlaybookMode.IMMERSIVE);
      setLockChapters(!!parentPlaybook.lockChapters);
      if (
        parentPlaybook.customBadgeName &&
        parentPlaybook.customBadgeName.length > 0
      ) {
        setShowCustomBadgeNameField(true);
        setCustomBadgeName(parentPlaybook.customBadgeName);
      }
    }
  }, [parentPlaybook, showModal]);

  useEffect(() => {
    if (journeyBadge) {
      setSelectedBadge(journeyBadge);
    } else {
      setSelectedBadge({} as Badge);
    }
  }, [journeyBadge]);

  return {
    isBusy,
    isSubmitted,
    badges,
    childPlaybooks,
    startDate,
    setStartDate,
    selectedBadge,
    setSelectedBadge,
    showCustomBadgeNameField,
    setShowCustomBadgeNameField,
    customBadgeName,
    setCustomBadgeName,
    lockChapters,
    setLockChapters,
    showMicrosite,
    setShowMicrosite,
    handleSave,
    onHideModal,
    removeCustomBadgeName,
    removeBadge,
  };
};
