import { type ReactNode } from 'react';
import { Dropdown } from 'react-bootstrap';
import './SelectDropdown.scss';

interface ISelectDropdown {
  id?: string;
  children: ReactNode | ReactNode[];
}

export const SelectDropdown = ({ id, children }: ISelectDropdown) => (
  <Dropdown id={id} className="select-dropdown" aria-label="select dropdown">
    {children}
  </Dropdown>
);
