import { useState, useEffect } from 'react';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import {
  BUCKET_USERIMAGES_URL,
  downloadAWSPhoto,
  addNotificationToApp,
} from '@Shared/utils/utils';
import { uploadData } from '@aws-amplify/storage';
import { getUserData } from '@api/apis';
import { type User } from '@base/API';

export const useEditPersonalInfoModal = (
  handleClick: Function,
  isOnboarding: boolean,
  handleUpdateUserPhoto?: Function,
) => {
  const {
    user,
    setUserProfilePhoto,
    setUserWorkLocation,
    setUserPersonalInfo,
  } = useUserContext();
  const [firstName, setFirstName] = useState(user.firstName || '');
  const [lastName, setLastName] = useState(user.lastName || '');
  const [workLocation, setWorkLocation] = useState(
    user.WorkLocation?.city || '',
  );
  const [isSubmitted] = useState(false);
  const [jobTitle, setJobTitle] = useState(user.jobTitle || '');
  const [email, setEmail] = useState(user.email || '');
  const [team, setTeam] = useState(user.team ?? '');
  const [profileImageUrl, setProfileImageUrl] = useState(user.photoUrl || '');
  const [uploadedImageFile, setUploadedImageFile] = useState<
    File | undefined
  >();
  const [isUploading, setIsUploading] = useState(false);

  const fileSelectedHandler = async (event: any) => {
    if (event.target.files.length > 0) {
      const newImage = event.target.files[0];
      setUploadedImageFile(newImage);
      const objectUrl = URL.createObjectURL(newImage);
      setProfileImageUrl(objectUrl);
    }
  };

  const handleSaveModal = async () => {
    try {
      if (uploadedImageFile) {
        setIsUploading(true);
        const result = await uploadData({
          key: `${BUCKET_USERIMAGES_URL}${uploadedImageFile?.name.replace(' ', '')}`,
          data: uploadedImageFile,
          options: {
            contentType: uploadedImageFile?.type,
          },
        }).result;

        const url = result.key;
        let fetchedUrl = await downloadAWSPhoto(url);
        setProfileImageUrl(fetchedUrl ?? '');
        setUserProfilePhoto(fetchedUrl ?? '');
        if (handleUpdateUserPhoto) {
          handleUpdateUserPhoto(fetchedUrl);
        }
        setIsUploading(false);
        setUploadedImageFile(undefined);
      }

      if (
        workLocation !== '' &&
        workLocation !== user.WorkLocation?.city &&
        user.WorkLocation
      ) {
        setUserWorkLocation({ ...user.WorkLocation, city: workLocation });
      }
      if (
        (firstName !== '' && firstName !== user.firstName) ||
        (lastName !== '' && lastName !== user.lastName) ||
        jobTitle ||
        user.team !== team
      ) {
        setUserPersonalInfo({
          ...user,
          firstName: firstName,
          lastName: lastName,
          jobTitle: jobTitle,
          team: team,
        } as User);
      }
      if (!isOnboarding) {
        addNotificationToApp(
          'Personal information updated successfully',
          'success',
        );
      }
      if (isOnboarding) {
        handleClick();
      } else {
        handleClick(false);
      }
    } catch (e) {
      console.log('Error while uploading image: ', e);
    }
  };

  const getProfileData = async () => {
    const userData = await getUserData();
    if (userData?.length) {
      setWorkLocation(userData[0].WorkLocation?.city ?? '');
      setTeam(userData[0].team ?? '');
      setJobTitle(userData[0].jobTitle ?? '');
      setEmail(userData[0].email);
    }
  };

  useEffect(() => {
    getProfileData();
  }, []);

  return {
    firstName,
    lastName,
    email,
    workLocation,
    isSubmitted,
    jobTitle,
    team,
    profileImageUrl,
    isUploading,
    uploadedImageFile,
    setFirstName,
    setLastName,
    setEmail,
    setWorkLocation,
    setJobTitle,
    setTeam,
    setProfileImageUrl,
    fileSelectedHandler,
    handleSaveModal,
  };
};
