export const parseFormUrl = (url: string) => {
  const urlParts = url.split('/');
  const lastParam = urlParts[urlParts.length - 1];

  if (url.includes('/forms/static/')) {
    return { slug: lastParam };
  }

  return { id: lastParam };
};
