import { useUserContext } from '@base/Context/UserContext/UserContext';
import { signIn, confirmSignIn } from 'aws-amplify/auth';
import { useState } from 'react';
import { addNotificationToApp } from '@Shared/utils/utils';

export const useResetPasswordModal = (
  email: string,
  handleShowModal: Function,
) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const { getProfileData } = useUserContext();

  const resetPassword = async () => {
    setIsSubmitted(true);
    if (newPassword.length >= 8 && newPassword === confirmPassword) {
      setIsBusy(true);
      const { isSignedIn } = await confirmSignIn({
        challengeResponse: newPassword,
      });

      if (!isSignedIn) {
        await signIn({ username: email, password: newPassword });
      }
      if (isSignedIn) {
        addNotificationToApp(
          'Your password has been reset, and you are now logged in.',
          'success',
        );
        await getProfileData();
      }
      setIsBusy(false);
      setIsSubmitted(false);
      handleShowModal(false);
    }
  };

  return {
    isSubmitted,
    isBusy,
    newPassword,
    setNewPassword,
    confirmPassword,
    setConfirmPassword,
    resetPassword,
  };
};
