import { createContext, useContext } from 'react';
import {
  type Interest,
  type ShareInfo,
  type User,
  type Pronoun,
  type ModelUserShareInfoConnection,
  type ModelUserInterestConnection,
  type ModelLearnMoreInfoConnection,
  type Location,
  type PreboardingUser,
  type Journey,
} from '@API';
import { type CustomPlaybookCollaborator } from '@base/models/common.model';

export type UserContextType = {
  user: User;
  allOrganisationInterests: Interest[];
  allOrganisationShareInfo: ShareInfo[];
  collaborators: CustomPlaybookCollaborator[];
  journeyAssignee: User | null;
  journeyPreboardingUser: PreboardingUser | null;
  isParentJourneyChanged: boolean;
  getProfileData: () => Promise<void>;
  setAllOrganisationInterests: (Interests: Interest[]) => void;
  setAllOrganisationShareInfo: (ShareInfo: ShareInfo[]) => void;
  setUserAbout: (about: string) => void;
  setUserIsFirstLogin: (isFirstLogin: boolean) => void;
  setUserIsHomeModelViewed: (isHomeModelViewed: boolean) => void;
  setUserInterests: (Interests: ModelUserInterestConnection) => void;
  setUserShareInfoOn: (ShareInfoOn: ModelUserShareInfoConnection) => void;
  setUserLearnMoreOn: (LearnMoreOn: ModelLearnMoreInfoConnection) => void;
  setUserExtraDetails: (pronoun: Pronoun, socialMediaUrl: string) => void;
  setUserHappyMost: (mostHappy: string) => void;
  setUserWorkStyle: (
    whatIDoAtWork: string,
    myFocusTimeIs: string,
    giveReceivingFeedback: string,
    digestionInformation: string,
    inMyElement: string,
  ) => void;
  setUserWorkPreferences: (
    giveMeAllTheContext: number,
    messageTimingPreferene: number,
    syncxVsAsync: number,
    talkTogetherVsAlone: number,
    recognitionPrivateVsPrivate: number,
  ) => void;
  playbook: Journey;
  setUserProfilePhoto: (photoUrl: string) => void;
  setUserWorkLocation: (workLocation: Location) => void;
  setUserPersonalInfo: (user: User) => void;
  setCollaborators: (collaborators: CustomPlaybookCollaborator[]) => void;
  setJourneyAssignee: (assignee: User | null) => void;
  setJourneyPreboardingUser: (preboardingUser: PreboardingUser | null) => void;
  setIsParentJourneyChanged: (isParentJourneyChanged: boolean) => void;
  setUser: (user: User) => void;
  setPlaybook: (playbook: Journey) => void;
};

export const UserContext = createContext<UserContextType>({
  user: {} as User,
  allOrganisationInterests: [] as Interest[],
  allOrganisationShareInfo: [] as ShareInfo[],
  collaborators: [] as CustomPlaybookCollaborator[],
  journeyAssignee: null,
  journeyPreboardingUser: null,
  isParentJourneyChanged: false,
  playbook: {} as Journey,
  setAllOrganisationInterests: (Interests: Interest[]) => {},
  setAllOrganisationShareInfo: (ShareInfo: ShareInfo[]) => {},
  setUserAbout: Function,
  getProfileData: async (): Promise<void> => {},
  setUserIsFirstLogin: (isFirstLogin: boolean) => {},
  setUserIsHomeModelViewed: (isHomeModelViewed: boolean) => {},
  setUserInterests: (Interests: ModelUserInterestConnection) => {},
  setUserShareInfoOn: (ShareInfoOn: ModelUserShareInfoConnection) => {},
  setUserLearnMoreOn: (LearnMoreOn: ModelLearnMoreInfoConnection) => {},
  setUserExtraDetails: Function,
  setUserHappyMost: Function,
  setUserWorkStyle: Function,
  setUserWorkPreferences: (
    giveMeAllTheContext: number,
    messageTimingPreferene: number,
    syncxVsAsync: number,
    talkTogetherVsAlone: number,
    recognitionPrivateVsPrivate: number,
  ) => {},
  setUserProfilePhoto: Function,
  setUserWorkLocation: (workLocation: Location) => {},
  setUserPersonalInfo: (user: User) => {},
  setCollaborators: (collaborators: CustomPlaybookCollaborator[]) => {},
  setJourneyAssignee: (assignee: User | null) => {},
  setJourneyPreboardingUser: (preboardingUser: PreboardingUser | null) => {},
  setIsParentJourneyChanged: (isParentJourneyChanged: boolean) => {},
  setUser: (user: User) => {},
  setPlaybook: (playbook: Journey) => {},
});

export const useUserContext = () => useContext(UserContext);
