import { useQuery } from '@tanstack/react-query';
import { Keys } from '@base/keys/queryKeys';
import { useUserContext } from '@Context/UserContext/UserContext';
import { listOrgForms } from '@api/apis';
import { formEvents as tmpFormEvents } from './ActivityPanel.const';
import { type FormEvent } from './ActivityPanel.types';

export const useActivityPanel = () => {
  const { user } = useUserContext();

  const { data: formEvents = [], isLoading: areFormEventsLoading } = useQuery({
    queryKey: Keys.getFormEvents(user.userOrganizationId),
    queryFn: () =>
      new Promise<FormEvent[]>((resolve) =>
        setTimeout(() => resolve(tmpFormEvents), 750),
      ),
    enabled: !!user.userOrganizationId,
  });

  const { data: forms = [], isLoading: areFormsLoading } = useQuery({
    queryKey: Keys.getOrgForms(user.userOrganizationId),
    queryFn: () => listOrgForms(user.userOrganizationId),
    enabled: !!user.userOrganizationId,
  });

  return {
    formEvents,
    isLoading: areFormEventsLoading || areFormsLoading,
    hasForms: forms.length > 0,
    hasFormEvents: formEvents.length > 0,
  };
};
