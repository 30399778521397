import { Buffer } from 'buffer';
import { type HTMLInputTypeAttribute, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getUrl, uploadData } from '@aws-amplify/storage';
import TurndownService from 'turndown';
import {
  BlockType,
  Channel,
  type EmbeddedContentBlock,
  type FreeTextQuestionBlock,
  type Journey,
  type Milestone,
  type MultipleChoiceQuestionBlock,
  type NoteBlock,
  type StandardContentBlock,
  type TaskBlock,
  type User,
  UserType,
} from '@API';
import {
  addEmbeddedContentBlock,
  addFreeTextQuestionBlock,
  addMultipleChoiceQuestionBlock,
  addNoteBlock,
  addStandardContentBlock,
  addTaskBlock,
  updateMilestone,
  updateMilestoneOrder,
} from '@api/apis';
import freeEmailDomains from 'free-email-domains';
import {
  type BlockImageDetail,
  type MessageImageDetail,
} from '@base/models/journeyLibrary.model';
import { toast, type TypeOptions } from 'react-toastify';

/*====Constants====*/
export const BREAKPOINTS_DESKTOP_1280 = {
  mobile: 0,
  tablet: 768,
  desktop: 1280,
};
export const BREAKPOINTS_DESKTOP_992 = { mobile: 0, tablet: 765, desktop: 992 };
export const BUCKET_BASE_URL = 'https://public/';
export const BUCKET_USERIMAGES_URL = 'userimages/';
export const BUCKET_PUBLIC_URL = 'public/';
export const INVALID_URL_MESSAGE = "Variable 'url' has an invalid value.";
export const TIME_OUT_MESSAGE = 'Endpoint request timed out';
export const SLACK_REDIRECT_DEEP_LINK =
  'https://slack.com/app_redirect?channel=';
export const S3_USER_UPLOADS_BASE_URL =
  'https://user-uploads-nexus-7291.s3.eu-west-1.amazonaws.com/';
export const FORM_UPLOADS_BUCKET = 'user-uploads-nexus-7291';
export const FIRST_KINFOLK_LOGIN = 'firstKinfolkLogin';
export const FIRST_KINFOLK_PROFILE_PAGE_FETCH = 'firstKinfolkProfilePageFetch';
export const QUERY_LIMIT = 900000000;
export const PREBOARDING_FORM_URL = 'https://form.kinfolkhq.com/';
export const IMAGE_LOADER_BASE64 =
  'data:image/gif;base64,R0lGODlhAAEAAfT/AP////f39+/v7+bm5t7e3tbW1s7OzsXFxb29vbW1ta2traWlpZycnJSUlIyMjISEhHt7e3Nzc2tra2NjY1paWlJSUkpKSkJCQjo6OjExMSkpKSEhIRkZGQgICAAAABAQECH/C05FVFNDQVBFMi4wAwEAAAAh/hFDcmVhdGVkIHdpdGggR0lNUAAh+QQFBwAgACwAAAAAAAEAAQAF/yAgjmRpnmiqrmzrvnAsz3Rt33iu73zv/8CgcEgsGo/IpHLJbDqf0Kh0Sq1ar9isdsvter/gsHhMLpvP6LR6zW673/C4fE6v2+/4vH7P7/v/gIGCg4SFhoeIiYqLjI2Oj5CRkpOUlZaXmJmam5ydnp+goaKjpKWmp6ipqqusra6vsLGys7S1tre4ubq7vL2+v8DBwsPExcbHyMnKy8zNzs/Q0dLT1NXW19jZ2tvc3d7f4OHi4+Tl5ufo6err7O3u7/Dx8vP09fb3+Pn6+/z9/v8AXQQYMCBAQB0BDihQcMDgwRsFFjBgsKDAwxsIJk5EcFEFwQEpEmhkkCDFgZMBB/8UWEkAhUiNJU8ogADhwQGABQzoNCDgxMuJMUsUkEC0Js6dBiya+EnyBASiRB8AHIDUAMgSTIOOSEB0AlEFAANUNeBw60itAAREgCohQs+pVVuSyFpiQVeiaP3lRFoWAN0RA9hKgHBRQFWlIv6KcHBXAuKDBKq+9Xt2hAHBDvR97EtCbFWzMEc89Ur0qokCDhwsaKdyZ8ETkZFe/ctVAunVJgQ4uIDhggTc6mLvLGCaxFiDAq4O6KmWbQTOARZYyJABQ2/C64QjLTBZBFWdBDiXGMCANMcRARBQwECd+nV2AfaODU9iZXcVBR5IBSyBfXv3FjyWjgDyjWWaeCsMJIL/AA1c8F97GFBgADytjZXUfTAEoEAF/v2HgQULILjOQBYmZQMFD1J3gQMYwhOAdjqJ6MIADkIogVz4EOgaDgy4V8F5/HyXgwAXWMCAjPcE0OIMCRTX0ZMmKCnAlFRWOSUPC2Sp5ZZZJsCTORWWqJOAN2zQwZlopnmmBg8syU2YYpq4wwUe1GnnnR508EEGwIUDp5hk2kAnnnjqySc5OsY5Jg+DEmqnnhfk9Y0ABBRoYaA1bMDBppx2ysEGFkgK5aik1kCpDgQ4uU98MeLgGXf8vIgUjjVoRx8+30lmg2EG2pPoWAUguYKlw7mZjqwl3koDspcaW06uwJa1HAzJoQfj/6yslQgrejqpisJ3ff1albflXGuVCdqR6x2241lIKzowKsvtdt59RJAIlorHbFLvnkPpmCJa+patCx6GAqsFyDvglSjwuhOOBIsAo5sBVKzPcSNEDIBnSHUErQG0agxAvA9xvFMJIgOAcUATo8zuCA6DF5DJcpKQMgD5AgSjeDfT3K8+Bf5888j0/rPSTggOzfFKYa20Lbovl0DgSsLaU7GMQ4twdakkQKsu17nt9TTYLFBKgLNkp6322my37fbbcMct99x012333XjnrffefPft99+ABy744IQXbvjhiCeu+OKMN+7445BHLvnklFdu+eWYZ6755px37vnnoIcu+ijopJdu+umop6766qy37vrrsMcu++y012777bjnrvvuvPfu++/';
export const HIDE_NAVBAR_FOR_URLS = [
  '/login',
  '/verify-email',
  '/reset-password',
  '/sign-up',
  '/account-verification',
  '/new-organization',
  '/templates/',
  '/journey/',
  '/onboarding',
];
export const MULTIPLE_CHOICE_QUESTION_LIMIT = 10;
export const API_BASE_URL = '/api/v1';
export const INVITED_ADMINS_SENDGRID_LIST_ID =
  'af68d497-0cdc-4b99-9a1f-7351f687a042';

/*===ENUMS===*/
export enum PRONOUNS {
  HE_HIM = 'He/Him',
  SHE_HER = 'She/Her',
  THEY_THEM = 'They/Them',
}

export enum BuilderTabs {
  JOURNEY = 'Content',
  MESSAGES = 'Messages',
  ACTIONS = 'Actions',
  MEETINGS = 'Meetings',
}

export enum AuthErrors {
  NEW_PASSWORD_REQUIRED = 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED',
  USER_NOT_CONFIRMED = 'User is not confirmed.',
  RESET_PASSWORD_REQUIRED = 'Password reset required for the user',
}

//===INTERFACES===//
export interface UrlMetadata {
  url: string;
  description: string;
  title: string;
  image: { url: string };
}

export interface ModalProps {
  showModal: boolean;
  handleShowModal: (value: boolean) => void;
}

export interface EditableContentProps {
  id?: string;
  value: string;
  setValue: Function;
  className?: string;
  isEditable: boolean;
  height?: string;
  isMessageTextEditor?: boolean;
  placeholder?: string;
  theme?: string;
  onBlur?: () => void;
}

export interface EditorProps {
  id: string;
  value: string;
  setValue: Function;
  className?: string;
  isEditable: boolean;
  height?: string;
  isMessageTextEditor?: boolean;
  placeholder?: string;
  theme?: string;
  onBlur?: () => void;
}

export interface TextFieldProps {
  id: string;
  type: HTMLInputTypeAttribute;
  placeholder: string;
  width?: string;
  height?: number;
  setValue?: Function;
  onBlur?: () => void;
  defaultValue?: string | number;
  additionalClasses?: string;
  fieldName?: string;
  disabled?: boolean;
  name?: string;
  errorMessage?: string;
  borderRadius?: number;
  required?: boolean;
  maxLength?: number;
}

export interface AssignJourneyModalProps {
  showModal: boolean;
  handleShowModal: Function;
  playbook: Journey;
}

export interface IResetPasswordModal extends ModalProps {
  email: string;
}

/*====Functions====*/
export const addNotificationToApp = (message: string, type: TypeOptions) => {
  toast(message, {
    type: type,
  });
};

export const downloadAWSPhoto = async (photoUrl: string) => {
  try {
    const key = photoUrl.split(BUCKET_PUBLIC_URL).pop();
    if (key) {
      const { url } = await getUrl({
        key,
        options: {
          accessLevel: 'guest',
          validateObjectExistence: true,
        },
      });

      return url.toString().split('?')[0];
    }

    return photoUrl;
  } catch (e: any) {
    addNotificationToApp(e.message, 'error');
  }
};

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [pathname]);

  return null;
}

export const checkUserRole = (user: User) => {
  if (
    (user.id && user.type === UserType.SUPER_ADMIN) ||
    (user.id && !user.homeLocation && user.type === UserType.COMPANY_ADMIN)
  ) {
    return true;
  } else return false;
};

export const toTitleCase = (phrase: string) =>
  phrase
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

// type is any because two different objects are received from Typeahead's new interest/skill and stored user's interest/skill
export function filterInterestsAndSkills(newSelected: any) {
  if (newSelected.interest) return newSelected.interest.name;
  else if (newSelected.shareInfo) return newSelected.shareInfo.name;
  else return newSelected.name;
}

// type is any because two different objects are received from Typeahead's new interest/skill and stored user's interest/skill
export function filterInterestsAndSkillsByComparison(
  firstItem: any,
  secondItem: any,
) {
  let isPresent = false;
  if (firstItem.interest) {
    if (firstItem.interest.name === secondItem.name) isPresent = true;
  } else if (firstItem.shareInfo) {
    if (firstItem.shareInfo.name === secondItem.name) isPresent = true;
  } else if (firstItem.name === secondItem.name) {
    isPresent = true;
  }
  return isPresent;
}

/**
 * remove html from the pasted content
 *
 * @param event Event
 */
export const pasteAsPlainText = (event: any) => {
  event.preventDefault();

  const text = event.clipboardData.getData('text/plain');
  document.execCommand('insertHTML', false, text);
};

/**
 * handle milestone update
 *
 * @param milestone Milestone
 */
export const handleMilestoneUpdate = async (milestone: Milestone) => {
  try {
    await updateMilestone(milestone);
  } catch (e) {}
};

/**
 * get order number of newly created block
 *
 * @param milestones Milestones
 * @param selectedMilestoneIndex number
 */
export const getBlockOrderNumber = (
  milestones: Milestone[],
  selectedMilestoneIndex: number,
): number => {
  let count = 0;
  count +=
    milestones[selectedMilestoneIndex].StandardContentBlocks?.items.length || 0;
  count += milestones[selectedMilestoneIndex].TaskBlocks?.items.length || 0;
  count +=
    milestones[selectedMilestoneIndex].EmbeddedContentBlocks?.items.length || 0;
  count +=
    milestones[selectedMilestoneIndex].MultipleChoiceQuestionBlocks?.items
      .length || 0;
  count +=
    milestones[selectedMilestoneIndex].FreeTextQuestionBlocks?.items.length ||
    0;
  count += milestones[selectedMilestoneIndex].NoteBlocks?.items.length || 0;

  return count;
};

/**
 * compare milestones
 *
 * @param a first milestone
 * @param b second milestone
 */
export const compareMilestones = (a: any, b: any): number => {
  if (a.orderNo < b.orderNo) {
    return -1;
  }
  if (a.orderNo > b.orderNo) {
    return 1;
  }
  return 0;
};

/**
 * handle new block to be added
 *
 * @param blockType type of block to be added
 * @param milestones milestone in which block is to be added
 * @param selectedMilestoneIndex current milestone index
 */
export const handleNewBlock = async (
  blockType: string,
  milestones: Milestone[],
  selectedMilestoneIndex: number,
): Promise<Milestone[] | undefined> => {
  try {
    const blockOrderNumber = getBlockOrderNumber(
      milestones,
      selectedMilestoneIndex,
    );

    switch (blockType) {
      case BlockType.StandardContentBlock:
        const standardContentBlockId = await addStandardContentBlock({
          milestoneID: milestones[selectedMilestoneIndex].id,
          title: 'Title',
          description: '',
          orderNo: blockOrderNumber,
          type: BlockType.StandardContentBlock,
        } as StandardContentBlock);
        if (standardContentBlockId) {
          milestones[selectedMilestoneIndex].StandardContentBlocks?.items?.push(
            {
              id: standardContentBlockId,
              type: BlockType.StandardContentBlock,
              milestoneID: milestones[selectedMilestoneIndex].id,
              title: 'Title',
              description: '',
              orderNo: blockOrderNumber + 1,
              isCompleted: false,
            } as StandardContentBlock,
          );
        }
        break;
      case BlockType.TaskBlock:
        const taskBlockId = await addTaskBlock({
          milestoneID: milestones[selectedMilestoneIndex].id,
          title: 'Title',
          description: '',
          orderNo: blockOrderNumber,
          type: BlockType.TaskBlock,
        } as TaskBlock);
        if (taskBlockId) {
          milestones[selectedMilestoneIndex].TaskBlocks?.items.push({
            type: BlockType.TaskBlock,
            id: taskBlockId,
            milestoneID: milestones[selectedMilestoneIndex].id,
            title: 'Task block',
            description: '',
            orderNo: blockOrderNumber + 1,
            isCompleted: false,
          } as TaskBlock);
        }
        break;
      case BlockType.NoteBlock:
        const noteBlockId = await addNoteBlock({
          milestoneID: milestones[selectedMilestoneIndex].id,
          title: 'Title',
          description: '',
          type: BlockType.NoteBlock,
          text: '',
          orderNo: blockOrderNumber,
        } as NoteBlock);
        if (noteBlockId) {
          milestones[selectedMilestoneIndex].NoteBlocks?.items?.push({
            id: noteBlockId,
            type: BlockType.NoteBlock,
            milestoneID: milestones[selectedMilestoneIndex].id,
            title: 'Note block',
            description: '',
            text: '',
            orderNo: blockOrderNumber + 1,
          } as NoteBlock);
        }
        break;
      case BlockType.FreeTextQuestionBlock:
        const freeTextQuestionBlockId = await addFreeTextQuestionBlock({
          milestoneID: milestones[selectedMilestoneIndex].id,
          title: 'Title',
          description: '',
          text: '',
          type: BlockType.FreeTextQuestionBlock,
          orderNo: blockOrderNumber,
        } as FreeTextQuestionBlock);
        if (freeTextQuestionBlockId) {
          milestones[selectedMilestoneIndex].FreeTextQuestionBlocks?.items.push(
            {
              id: freeTextQuestionBlockId,
              type: BlockType.FreeTextQuestionBlock,
              milestoneID: milestones[selectedMilestoneIndex].id,
              title: 'Free text question',
              description: '',
              text: '',
              orderNo: blockOrderNumber + 1,
            } as FreeTextQuestionBlock,
          );
        }
        break;
      case BlockType.MultipleChoiceQuestionBlock:
        const multipleChoiceQuestionBlockId =
          await addMultipleChoiceQuestionBlock({
            milestoneID: milestones[selectedMilestoneIndex].id,
            title: 'Title',
            description: '',
            type: BlockType.MultipleChoiceQuestionBlock,
            orderNo: blockOrderNumber,
          } as MultipleChoiceQuestionBlock);
        if (multipleChoiceQuestionBlockId) {
          milestones[
            selectedMilestoneIndex
          ].MultipleChoiceQuestionBlocks?.items.push({
            id: multipleChoiceQuestionBlockId,
            type: BlockType.MultipleChoiceQuestionBlock,
            milestoneID: milestones[selectedMilestoneIndex].id,
            title: 'Single select question',
            description: '',
            orderNo: blockOrderNumber + 1,
            answer: [],
          } as unknown as MultipleChoiceQuestionBlock);
        }
        break;
      default:
        break;
    }

    return milestones;
  } catch (e) {}
};

/**
 * handle embedded content block to be added
 *
 * @param url url of embedded resource
 * @param title title of embedded resource
 * @param description description of embedded resource
 * @param milestones milestone in which block is to be added
 * @param selectedMilestoneIndex current milestone index
 * @returns
 */
export const handleAddEmbeddedContentBlock = async (
  url: string,
  milestones: Milestone[],
  selectedMilestoneIndex: number,
  isFormLinked: boolean,
): Promise<Milestone[]> => {
  const blockOrderNumber = getBlockOrderNumber(
    milestones,
    selectedMilestoneIndex,
  );
  const embeddedContentId = await addEmbeddedContentBlock({
    milestoneID: milestones[selectedMilestoneIndex].id,
    title: 'Title',
    description: '',
    resourceLink: url,
    type: BlockType.EmbeddedContentBlock,
    orderNo: blockOrderNumber,
    isFormLinked,
  } as EmbeddedContentBlock);
  if (embeddedContentId) {
    milestones[selectedMilestoneIndex].EmbeddedContentBlocks?.items.push({
      id: embeddedContentId,
      type: BlockType.EmbeddedContentBlock,
      milestoneID: milestones[selectedMilestoneIndex].id,
      title: 'Title',
      description: '',
      orderNo: blockOrderNumber + 1,
      resourceLink: url,
      isFormLinked,
    } as EmbeddedContentBlock);
  }

  return milestones;
};

/**
 * handle blocks update
 *
 * @param milestones Milestone []
 * @param selectedMilestoneIndex number
 * @param block block to be updated
 */
export const handleBlocksUpdate = (
  milestones: Milestone[],
  selectedMilestoneIndex: number,
  block: any,
): Milestone[] => {
  switch (block.type) {
    case BlockType.StandardContentBlock:
      if (milestones[selectedMilestoneIndex].StandardContentBlocks) {
        const index = milestones[
          selectedMilestoneIndex
        ].StandardContentBlocks?.items.findIndex(
          (item) => item?.id === block.id,
        );
        if (index !== undefined) {
          //@ts-ignore
          milestones[selectedMilestoneIndex].StandardContentBlocks.items[
            index
          ] = block;
        }
      }
      break;
    case BlockType.TaskBlock:
      if (milestones[selectedMilestoneIndex].TaskBlocks) {
        const index = milestones[
          selectedMilestoneIndex
        ].TaskBlocks?.items.findIndex((item) => item?.id === block.id);
        if (index !== undefined) {
          //@ts-ignore
          milestones[selectedMilestoneIndex].TaskBlocks.items[index] = block;
        }
      }
      break;
    case BlockType.NoteBlock:
      if (milestones[selectedMilestoneIndex].NoteBlocks) {
        const index = milestones[
          selectedMilestoneIndex
        ].NoteBlocks?.items.findIndex((item) => item?.id === block.id);
        if (index !== undefined) {
          //@ts-ignore
          milestones[selectedMilestoneIndex].NoteBlocks.items[index] = block;
        }
      }
      break;
    case BlockType.FreeTextQuestionBlock:
      if (milestones[selectedMilestoneIndex].FreeTextQuestionBlocks) {
        const index = milestones[
          selectedMilestoneIndex
        ].FreeTextQuestionBlocks?.items.findIndex(
          (item) => item?.id === block.id,
        );
        if (index !== undefined) {
          //@ts-ignore
          milestones[selectedMilestoneIndex].FreeTextQuestionBlocks.items[
            index
          ] = block;
        }
      }
      break;
    case BlockType.MultipleChoiceQuestionBlock:
      if (milestones[selectedMilestoneIndex].MultipleChoiceQuestionBlocks) {
        const index = milestones[
          selectedMilestoneIndex
        ].MultipleChoiceQuestionBlocks?.items.findIndex(
          (item) => item?.id === block.id,
        );
        if (index !== undefined) {
          //@ts-ignore
          milestones[selectedMilestoneIndex].MultipleChoiceQuestionBlocks.items[
            index
          ] = block;
        }
      }
      break;
    case BlockType.EmbeddedContentBlock:
      if (milestones[selectedMilestoneIndex].EmbeddedContentBlocks) {
        const index = milestones[
          selectedMilestoneIndex
        ].EmbeddedContentBlocks?.items.findIndex(
          (item) => item?.id === block.id,
        );
        if (index !== undefined) {
          //@ts-ignore
          milestones[selectedMilestoneIndex].EmbeddedContentBlocks.items[
            index
          ] = block;
        }
      }
      break;
  }
  return milestones;
};

/**
 * calculate journey progress
 *
 * @param milestones journey milestones
 */
export const calculateProgress = (milestones: Milestone[]): number => {
  const totalMilestones = milestones.length;
  const completedMilestones = milestones.filter(
    (milestone) => milestone.isArchived || milestone.isCompleted,
  ).length;

  if (totalMilestones) {
    return Math.ceil((completedMilestones / totalMilestones) * 100);
  }
  return 0;
};

/**
 * is valid date
 *
 *
 * @param date string
 */
export const isValidDate = (date: string) => {
  // Date format: YYYY-MM-DD
  var datePattern = /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;

  // Check if the date string format is a match
  var matchArray = date.match(datePattern);
  if (matchArray == null) {
    return false;
  }

  // Remove any non digit characters
  var dateString = date.replace(/\D/g, '');

  // Parse integer values from the date string
  var year = parseInt(dateString.substr(0, 4));
  var month = parseInt(dateString.substr(4, 2));
  var day = parseInt(dateString.substr(6, 2));

  // Define the number of days per month
  var daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Leap years
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
    daysInMonth[1] = 29;
  }

  if (month < 1 || month > 12 || day < 1 || day > daysInMonth[month - 1]) {
    return false;
  }

  return true;
};

export const isValidEmail = (email: string): boolean => {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailPattern.test(email);
};
/**
 * isWorkingEmail
 *
 * @param email string
 */
export const isWorkingEmail = (email: string): boolean => {
  const domain = email.replace(/.*@/, '');
  const blockedDomains = [
    'dadohr.com',
    'enboarder.com',
    'pynhq.com',
    'introist.com',
    'trainual.com',
    'changeengine.com',
    'ujji.io',
    'donut.com',
  ];

  return !freeEmailDomains.includes(domain) && !blockedDomains.includes(domain);
};

/**
 * format date (e.g 15th April)
 *
 * @param date Date
 */
export const formateDate = (
  date: Date,
  includeDay = false,
  includeYear: Boolean = true,
) => {
  const day = date.getUTCDate();
  const weekDay = date.getUTCDay();
  const month = date.toLocaleString('default', { month: 'short' });
  const year = date.toLocaleString('default', { year: 'numeric' });
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const suffixes = {
    1: 'st',
    2: 'nd',
    3: 'rd',
    11: 'th',
    12: 'th',
    13: 'th',
  };

  let daySuffix = 'th';
  if (day in suffixes) {
    //@ts-ignore
    daySuffix = suffixes[day];
  } else {
    const lastDigit = day % 10;
    if (lastDigit in suffixes) {
      //@ts-ignore
      daySuffix = suffixes[lastDigit];
    }
  }

  const formattedDate = `${includeDay ? days[weekDay] + ' ' : ''}${day}${daySuffix} ${month} ${includeYear ? year : ''}`;
  return formattedDate;
};

/**
 * handle delete blocks
 *
 * @param milestone Milestone
 * @param block id string
 * @param type blockType
 * @param orderNo number
 */
export const handleDeleteBlocks = (
  milestone: Milestone,
  id: string,
  type: BlockType,
  orderNo: number,
): Milestone => {
  if (type === BlockType.StandardContentBlock) {
    const index = milestone.StandardContentBlocks?.items.findIndex(
      (block) => block?.id === id,
    );
    if (index !== undefined && index > -1) {
      milestone.StandardContentBlocks?.items.splice(index, 1);
    }
  } else if (type === BlockType.EmbeddedContentBlock) {
    const index = milestone.EmbeddedContentBlocks?.items.findIndex(
      (block) => block?.id === id,
    );
    if (index !== undefined && index > -1) {
      milestone.EmbeddedContentBlocks?.items.splice(index, 1);
    }
  } else if (type === BlockType.FreeTextQuestionBlock) {
    const index = milestone.FreeTextQuestionBlocks?.items.findIndex(
      (block) => block?.id === id,
    );
    if (index !== undefined && index > -1) {
      milestone.FreeTextQuestionBlocks?.items.splice(index, 1);
    }
  } else if (type === BlockType.MultipleChoiceQuestionBlock) {
    const index = milestone.MultipleChoiceQuestionBlocks?.items.findIndex(
      (block) => block?.id === id,
    );
    if (index !== undefined && index > -1) {
      milestone.MultipleChoiceQuestionBlocks?.items.splice(index, 1);
    }
  } else if (type === BlockType.TaskBlock) {
    const index = milestone.TaskBlocks?.items.findIndex(
      (block) => block?.id === id,
    );
    if (index !== undefined && index > -1) {
      milestone.TaskBlocks?.items.splice(index, 1);
    }
  } else if (type === BlockType.NoteBlock) {
    const index = milestone.NoteBlocks?.items.findIndex(
      (block) => block?.id === id,
    );
    if (index !== undefined && index > -1) {
      milestone.NoteBlocks?.items.splice(index, 1);
    }
  }

  const updatedMilestone = updateBlocksOrderNo(milestone, orderNo);
  return updatedMilestone;
};

/**
 * update block Description
 *
 * @param blockType string
 * @param blockId string
 * @param milestoneID string
 * @param organizationId string
 */
export const updateBlockDescription = async (
  description: string,
  blockId: string,
  blockType: string,
  milestoneID: string,
  organizationId: string,
  journeyId: string,
): Promise<string> => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(description, 'text/html');
  const imgElements = doc.querySelectorAll('img');

  for (const img of Array.from(imgElements)) {
    if (img && !img.getAttribute('src')!.startsWith('https')) {
      const src = img.getAttribute('src');
      if (src) {
        let base64FileContent = src
          .toString()
          .replace(/^data:image\/\w+;base64,/, '');
        let buffer = Buffer.from(base64FileContent, 'base64');
        let fileSizeInByte = buffer.length;

        if (fileSizeInByte <= 4000000) {
          if (src && !src.includes('https')) {
            const fileName = getBase64FileName(src);
            const fileType = src.split(';')[0].split('/')[1];
            await uploadImageToBucket({
              src,
              blockId,
              blockType,
              fileName,
              fileType,
              milestoneID,
              organizationId,
              journeyId,
            }).then((fetchedUrl) => {
              img.setAttribute('src', fetchedUrl);
            });
          }
        } else {
          img.remove();
          addNotificationToApp(
            'Your image file must be smaller than 4MB',
            'error',
          );
        }
      }
    }
  }
  return doc.body.innerHTML;
};

/**
 * uploadImageToBucket
 *
 * @param blockImage BlockImageDetail
 */
export const uploadImageToBucket = async (
  blockImage: BlockImageDetail,
): Promise<string> => {
  let base64Data: any;
  if (blockImage.src) {
    base64Data = blockImage.src.replace(/^data:image\/\w+;base64,/, '');
  }
  const buffer = Buffer.from(base64Data, 'base64');
  const { key } = await uploadData({
    key: `organization/${blockImage.organizationId}/journey/${blockImage.journeyId}/milestones/${blockImage.milestoneID + '/'}${
      blockImage.blockType + '/'
    }${blockImage.blockId + '/' + blockImage.fileName}`,
    data: buffer,
    options: {
      contentType: `image/${blockImage.fileType}`,
    },
  }).result;

  let fetchedUrl = await downloadAWSPhoto(key);
  return fetchedUrl ?? '';
};

/**
 * update Message Description
 *
 * @param text description
 * @param organizationId organization id
 * @param playbookId playbook id
 * @param id id of the workflow
 */
export const updateMessageDescription = async (
  text: string,
  organizationId: string,
  playbookId: string,
  id: string,
): Promise<string> => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(text, 'text/html');
  const imgElements = doc.querySelectorAll('img');

  for (const img of Array.from(imgElements)) {
    if (img && !img.getAttribute('src')!.startsWith('https')) {
      const src = img.getAttribute('src');
      if (src) {
        let base64FileContent = src
          .toString()
          .replace(/^data:image\/\w+;base64,/, '');
        let buffer = Buffer.from(base64FileContent, 'base64');
        let fileSizeInByte = buffer.length;

        if (fileSizeInByte <= 4000000) {
          if (src && !src.includes('https')) {
            const fileName = getBase64FileName(src);
            const fileType = src.split(';')[0].split('/')[1];
            await uploadMessageImageToBucket({
              src,
              fileName,
              fileType,
              organizationId,
              playbookId,
              id,
            }).then((fetchedUrl) => {
              img.setAttribute('src', fetchedUrl);
            });
          }
        } else {
          img.remove();
          addNotificationToApp(
            'Your image file must be smaller than 4MB',
            'error',
          );
        }
      }
    }
  }
  return doc.body.innerHTML;
};

/**
 * uploadMessageImageToBucket
 *
 * @param messageImage MessageImageDetail
 */
export const uploadMessageImageToBucket = async (
  messageImage: MessageImageDetail,
): Promise<string> => {
  let base64Data: any;
  if (messageImage.src) {
    base64Data = messageImage.src.replace(/^data:image\/\w+;base64,/, '');
  }
  const buffer = Buffer.from(base64Data, 'base64');
  const { key } = await uploadData({
    key: `organization/${messageImage.organizationId}/journey/${messageImage.playbookId}/message/${
      messageImage.id + '/' + messageImage.fileName
    }`,
    data: buffer,
    options: {
      contentType: `image/${messageImage.fileType}`,
    },
  }).result;

  let fetchedUrl = await downloadAWSPhoto(key);
  return fetchedUrl ?? '';
};

/**
 * getBase64FileName
 * @param base64String string
 */
export const getBase64FileName = (base64String: string) => {
  const mimeType = base64String.match(
    /^data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/,
  )?.[1];
  if (!mimeType) {
    throw new Error('Invalid base64 string.');
  }
  const fileExtension = mimeType.split('/')[1];
  const timestamp = Date.now().toString();
  return `${timestamp}.${fileExtension}`;
};

/**
 * update blocks order no
 *
 * @param milestone Milestone
 * @param deletedBlockOrderNo
 */
const updateBlocksOrderNo = (
  milestone: Milestone,
  deletedBlockOrderNo: number,
): Milestone => {
  milestone.EmbeddedContentBlocks?.items.forEach((block, index) => {
    if (
      milestone.EmbeddedContentBlocks &&
      block?.orderNo &&
      block?.orderNo > deletedBlockOrderNo
    ) {
      milestone.EmbeddedContentBlocks.items[index] = {
        ...block,
        orderNo: block.orderNo - 1,
      };
    }
  });

  milestone.StandardContentBlocks?.items.forEach((block, index) => {
    if (
      milestone.StandardContentBlocks &&
      block?.orderNo &&
      block?.orderNo > deletedBlockOrderNo
    ) {
      milestone.StandardContentBlocks.items[index] = {
        ...block,
        orderNo: block.orderNo - 1,
      };
    }
  });

  milestone.TaskBlocks?.items.forEach((block, index) => {
    if (
      milestone.TaskBlocks &&
      block?.orderNo &&
      block?.orderNo > deletedBlockOrderNo
    ) {
      milestone.TaskBlocks.items[index] = {
        ...block,
        orderNo: block.orderNo - 1,
      };
    }
  });

  milestone.MultipleChoiceQuestionBlocks?.items.forEach((block, index) => {
    if (
      milestone.MultipleChoiceQuestionBlocks &&
      block?.orderNo &&
      block?.orderNo > deletedBlockOrderNo
    ) {
      milestone.MultipleChoiceQuestionBlocks.items[index] = {
        ...block,
        orderNo: block.orderNo - 1,
      };
    }
  });

  milestone.FreeTextQuestionBlocks?.items.forEach((block, index) => {
    if (
      milestone.FreeTextQuestionBlocks &&
      block?.orderNo &&
      block?.orderNo > deletedBlockOrderNo
    ) {
      milestone.FreeTextQuestionBlocks.items[index] = {
        ...block,
        orderNo: block.orderNo - 1,
      };
    }
  });

  milestone.NoteBlocks?.items.forEach((block, index) => {
    if (
      milestone.NoteBlocks &&
      block?.orderNo &&
      block?.orderNo > deletedBlockOrderNo
    ) {
      milestone.NoteBlocks.items[index] = {
        ...block,
        orderNo: block.orderNo - 1,
      };
    }
  });

  return milestone;
};

/**
 * update milestone's order number
 *
 * @param source number
 * @param destination number
 * @param draggedMilestone Milestone
 * @param milestones Milestone
 */
export const updateMilestoneOrderNumber = (
  source: number,
  destination: number,
  draggedMilestone: Milestone,
  milestones: Milestone[],
): Milestone[] => {
  const index = milestones.findIndex(
    (milestone) => milestone.id === draggedMilestone.id,
  );
  if (index >= 0) {
    milestones[index] = { ...draggedMilestone, orderNo: destination + 1 };
  }

  return milestones.map((milestone) => {
    if (
      source < destination &&
      milestone.id !== draggedMilestone.id &&
      milestone.orderNo > source + 1 &&
      milestone.orderNo <= destination + 1
    ) {
      return { ...milestone, orderNo: milestone.orderNo - 1 };
    } else if (
      source > destination &&
      milestone.id !== draggedMilestone.id &&
      milestone.orderNo >= destination + 1 &&
      milestone.orderNo <= source
    ) {
      return { ...milestone, orderNo: milestone.orderNo + 1 };
    }

    return milestone;
  });
};

/**
 * update milestone's order no after delete
 *
 * @param milestones milestone list
 * @param deletedMilestoneOrderNo deleted milestone order no
 */
export const updateMilestoneOrderNoAfterDelete = async (
  milestones: Milestone[],
  deletedMilestoneOrderNo: number,
) => {
  await Promise.all(
    milestones
      .filter((milestone) => milestone.orderNo > deletedMilestoneOrderNo)
      .map((milestone) =>
        updateMilestoneOrder(milestone.id, milestone.orderNo - 1),
      ),
  );
};

const getHrefFromNode = (node: HTMLElement) => {
  const href = node.getAttribute('href') ?? '';
  const hasProtocol = /^https?:\/\//i.test(href);

  if (!hasProtocol && href.includes('.com')) {
    return 'https://' + href;
  }

  return href;
};

const getImageDataFromNode = (node: HTMLImageElement) => {
  const alt = node.alt || 'image';
  const src = node.src ?? '';

  if (src.startsWith('data:') || src.startsWith('/')) {
    const base = document.createElement('base');
    base.href = node.ownerDocument.baseURI;

    return { alt, src: new URL(src, base.href).href };
  }

  return { alt, src };
};

const getBaseTurndownServcie = () => {
  const turndownService = new TurndownService();
  turndownService.escape = function (str: string) {
    return str;
  };

  turndownService.addRule('assigneerules', {
    filter: function (node: any) {
      return (
        node.isBlock &&
        node.nodeName === 'P' &&
        (node.innerHTML.includes('%%assignee_') ||
          node.innerHTML.includes('%%collaborator_'))
      );
    },
    replacement: function (content: string) {
      let replacedContent = content.replace(/\\/g, '');
      return replacedContent + '\n';
    },
  });

  turndownService.addRule('underline', {
    filter: 'u',
    replacement: function (content: string) {
      return '' + content + '';
    },
  });

  turndownService.addRule('code', {
    filter: ['code', 'pre'],
    replacement: function (content: string) {
      return '`' + content + '`';
    },
  });

  turndownService.addRule('blockquote', {
    filter: 'blockquote',
    replacement: function (content: string) {
      return '> ' + content.replace(/\n/g, '\n> ');
    },
  });

  turndownService.addRule('listitem', {
    filter: 'li',
    replacement: function (content: any, node: any) {
      let replacedContent = content.replace(/\\/g, '');
      return `${replacedContent.trim()}\n`;
    },
  });

  turndownService.addRule('newline', {
    filter: 'br',
    replacement: function (content: string) {
      return '\n';
    },
  });

  return turndownService;
};

/**
 * Converts HTML to Markdown-like syntax supported by Teams
 * https://learn.microsoft.com/en-us/microsoftteams/platform/task-modules-and-cards/cards/cards-format?tabs=connector-md%2Cdesktop%2Cconnector-html#format-cards-with-markdown
 */
const convertHtmlToTeams = (messageBody: string) => {
  const turndownService = getBaseTurndownServcie();

  turndownService.addRule('paragraph', {
    filter: 'p',
    replacement: function (content: string) {
      return `${content}\n\n`;
    },
  });

  turndownService.addRule('bold', {
    filter: ['b', 'strong'],
    replacement: function (content: string) {
      return '**' + content + '**';
    },
  });

  turndownService.addRule('italic', {
    filter: ['i', 'em'],
    replacement: function (content: string) {
      return '*' + content + '*';
    },
  });

  turndownService.addRule('strike', {
    filter: 'del',
    replacement: function (content: string) {
      return '~~' + content + '~~';
    },
  });

  turndownService.addRule('link', {
    filter: 'a',
    replacement: function (content, node) {
      if (!(node instanceof HTMLElement)) {
        return content;
      }

      const href = getHrefFromNode(node);

      return `[${content}](${href})`;
    },
  });

  turndownService.addRule('orderedlist', {
    filter: 'ol',
    replacement: function (content) {
      const listItems = content
        .trim()
        .split('\n')
        .map((item, index) => `${index + 1}. ${item}`)
        .join('\r');
      return `${listItems}\n\n`;
    },
  });

  turndownService.addRule('unorderedlist', {
    filter: 'ul',
    replacement: function (content) {
      const listItems = content
        .trim()
        .split('\n')
        .map((item) => `* ${item}`)
        .join('\r');
      return `${listItems}\n\n`;
    },
  });

  turndownService.addRule('image', {
    filter: 'img',
    replacement: function (content, node) {
      if (!(node instanceof HTMLImageElement)) {
        return '';
      }

      const { alt, src } = getImageDataFromNode(node);

      return `![${alt}](${src})`;
    },
  });

  return turndownService.turndown(messageBody);
};

/**
 * Converts HTML to Markdown-like syntax supported by Slack
 * https://api.slack.com/reference/surfaces/formatting#basic-formatting
 */
const convertHtmlToSlack = (messageBody: string) => {
  const turndownService = getBaseTurndownServcie();

  turndownService.addRule('paragraph', {
    filter: 'p',
    replacement: function (content: string) {
      return `${content}\n`;
    },
  });

  turndownService.addRule('bold', {
    filter: ['b', 'strong'],
    replacement: function (content) {
      return '*' + content + '*';
    },
  });

  turndownService.addRule('italic', {
    filter: ['i', 'em'],
    replacement: function (content) {
      return '_' + content + '_';
    },
  });

  turndownService.addRule('strike', {
    filter: 'del',
    replacement: function (content) {
      return '~' + content + '~';
    },
  });

  turndownService.addRule('link', {
    filter: 'a',
    replacement: function (content, node) {
      if (!(node instanceof HTMLElement)) {
        return content;
      }

      const href = getHrefFromNode(node);
      const title = node.title ? ' "' + node.title + '"' : '';

      return '<' + href + title + '|' + content + '>';
    },
  });

  turndownService.addRule('orderedlist', {
    filter: 'ol',
    replacement: function (content) {
      const listItems = content
        .trim()
        .split('\n')
        .map((item, index) => `${index + 1}. ${item}`)
        .join('\n');
      return `${listItems}\n`;
    },
  });

  turndownService.addRule('unorderedlist', {
    filter: 'ul',
    replacement: function (content) {
      const listItems = content
        .trim()
        .split('\n')
        .map((item) => `• ${item}`)
        .join('\n');
      return `${listItems}\n`;
    },
  });

  turndownService.addRule('image', {
    filter: 'img',
    replacement: function (content, node) {
      if (!(node instanceof HTMLImageElement)) {
        return '';
      }

      const { alt, src } = getImageDataFromNode(node);
      const title = node.title ? ' "' + node.title + '"' : '';

      return `![${alt}](${src}${title})`;
    },
  });

  return turndownService.turndown(messageBody);
};

/**
 * Converts Markdown-like syntax supported by Teams to HTML
 */
const convertTeamsToHtml = (teamsMarkdown: string) => {
  let html = teamsMarkdown
    .replace(/\*\*([^*]+)\*\*/g, '<strong>$1</strong>')
    .replace(/~~([^~]+)~~/g, '<del>$1</del>')
    .replace(/`([^`]+)`/g, '<code>$1</code>')
    .replace(/```([^`]+)```/g, '<pre>$1</pre>')
    .replace(
      /(^|[\n])(&gt;[\s\S]*?)([\n]|$)/g,
      '$1<blockquote>$2</blockquote>$3',
    )
    .replace(/!\[([^\]]*)\]\(([^)]+)\)/g, '<img alt="$1" src="$2">')
    .replace(/\[([^\]]+)\]\(([^)]+)\)/g, '<a href="$2">$1</a>')
    .replace(/^(\d+)\. (.*)$/gm, '<ol><li>$2</li></ol>')
    .replace(/^\*\s+(.*)$/gm, '<ul><li>$1</li></ul>')
    .replace(/\*([^*]+)\*/g, '<em>$1</em>')
    .replace(/<\/ol>\s?<ol>/g, '')
    .replace(/<\/ul>\s?<ul>/g, '')
    .replace(/<\/li>\s?<li>/g, '</li><li>')
    .replace(/%%assignee\\/, '%%assignee')
    .replace(/%%collaborator\\/, '%%collaborator')
    .replace(/%%(.*?)%%/g, '%%$1%%')
    .replace(/\n\n/g, '</p><p>')
    .replace(/\n/g, '</p><p>');

  // Add opening and closing <p> tags
  return `<p>${html.split('</p><p>').join('</p><p>')}</p>`;
};

/**
 * Converts Markdown-like syntax supported by Slack to HTML
 */
const convertSlackToHtml = (slackMarkdown: string) => {
  let html = slackMarkdown
    .replace(/<!here>/g, '<span class="mention">@here</span>')
    .replace(/<!channel>/g, '<span class="mention">@channel</span>')
    .replace(/<!([^>|]+)(?:\|([^>]+))?>/g, '<span class="mention">@$1</span>')
    // .replace(/<\s*(https?:\/\/[^|>]+)\|([^>]+)>/g, '<a href="$1" target="_blank">$2</a>')
    // .replace(/<\s*(https?:\/\/[^>]+)>/g, '<a href="$1" target="_blank">$1</a>')
    .replace(/\*([^*]+)\*/g, '<strong>$1</strong>')
    .replace(/~([^~]+)~/g, '<del>$1</del>')
    .replace(/`([^`]+)`/g, '<code>$1</code>')
    .replace(/```([^`]+)```/g, '<pre>$1</pre>')
    .replace(
      /(^|[\n])(&gt;[\s\S]*?)([\n]|$)/g,
      '$1<blockquote>$2</blockquote>$3',
    )
    .replace(/<([^|>]+)\|([^>]+)>/g, '<a href="$1">$2</a>')
    .replace(/<@([^>]+)>/g, '<span class="user-mention">$1</span>')
    .replace(
      /<#([^|>]+)\|?([^>]+)?>/g,
      '<span class="channel-mention">$2</span>',
    )
    .replace(/!\[([^\]]*)\]\(([^)]+)\)/g, '<img alt="$1" src="$2">');

  html = handleAssigneeRulesForHTML(html);
  html = html.replace(/^(\d+)\. (.*)$/gm, '<ol><li>$2</li></ol>');
  html = html.replace(/^•\s+(.*)$/gm, '<ul><li>$1</li></ul>');
  html = html.replace(/<\/ol>\s?<ol>/g, '');
  html = html.replace(/<\/ul>\s?<ul>/g, '');
  html = html.replace(/<\/li>\s?<li>/g, '</li><li>');
  html = html.replace(/%%assignee\\/, '%%assignee');
  html = html.replace(/%%collaborator\\/, '%%collaborator');
  html = html.replace(/%%(.*?)%%/g, '%%$1%%');
  // html = html.replace(/\n/g, '<br>');
  html = html.replace(/\n/g, '</p><p>');

  // Add opening and closing <p> tags
  html = `<p>${html.split('</p><p>').join('</p><p>')}</p>`;

  return html;
};

/**
 * Converts Markdown-like syntax supported by Teams to plain text
 */
const convertTeamsToPlainText = (teamsMarkdown: string) =>
  teamsMarkdown
    .replace(/\*\*([^*]+)\*\*/g, '$1')
    .replace(/\*([^*]+)\*/g, '$1')
    .replace(/~~([^~]+)~~/g, '$1')
    .replace(/`([^`]+)`/g, '$1')
    .replace(/```([^`]+)```/g, '$1')
    .replace(/(^|\n)>[^\n]*\n/g, '$1')
    .replace(/\[([^\]]+)\]\(([^)]+)\)/g, '$2 ($1)')
    .replace(/!\[(.*?)\]\((.*?)\)/g, '*image*')
    .replace(/%%%%/g, '%% %%');

/**
 * Converts Markdown-like syntax supported by Slack to plain text
 */
const convertSlackToPlainText = (slackMarkdown: string) => {
  const text = handleAssigneeRulesForHTML(slackMarkdown, 'text');
  return (
    text
      .replace(/\*([^*]+)\*/g, '$1')
      .replace(/~([^~]+)~/g, '$1')
      .replace(/`([^`]+)`/g, '$1')
      .replace(/```([^`]+)```/g, '$1')
      // .replace(/(^|\n)>[\s\S]*?(\n|$)/g, '')
      .replace(/(^|\n)>[^\n]*\n/g, '$1')
      .replace(/<([^|>]+)\|([^>]+)>/g, '$2 ($1)')
      .replace(/<@([^>]+)>/g, '@$1')
      .replace(/<#([^|>]+)\|?([^>]+)?>/g, '#$2')
      .replace(/!\[(.*?)\]\((.*?)\)/g, '*image*')
      // .replace(/\n/g, ' ')
      .replace(/%%%%/g, '%% %%')
  );
};

export const convertHtmlToChannelSpecificFormat = (
  html: string,
  channel: Channel,
) => {
  switch (channel) {
    case Channel.TEAMS:
      return convertHtmlToTeams(html);
    case Channel.SLACK:
      return convertHtmlToSlack(html);
    default:
      return html;
  }
};

export const convertChannelSpecificFormatToHtml = (
  text: string,
  channel: Channel,
) => {
  switch (channel) {
    case Channel.TEAMS:
      return convertTeamsToHtml(text);
    case Channel.SLACK:
      return convertSlackToHtml(text);
    default:
      return text;
  }
};

export const convertChannelSpecificFormatToPlainText = (
  text: string,
  channel: Channel,
) => {
  switch (channel) {
    case Channel.TEAMS:
      return convertTeamsToPlainText(text);
    case Channel.SLACK:
      return convertSlackToPlainText(text);
    default:
      return text;
  }
};

/**
 * Helper function to handle assignee rules for html in slack markdown conversion function
 *
 * @param html string
 * @param contentReturnType string
 */
const handleAssigneeRulesForHTML = (
  html: string,
  contentReturnType: string = 'html',
) => {
  let contentReturnElement: string =
    contentReturnType === 'html' ? '<em>$1</em>' : '$1';
  let convertedHtml = html;
  const matches = html.match(/%(.*?)%/g);
  if (matches) {
    matches.forEach((match: any) => {
      const replaced = match.replace(/_/g, '\\_');
      convertedHtml = convertedHtml.replace(match, replaced);
    });
  }

  /*
   * Slack considers _ as italic, since we are using _ in dynamic variables
   * This logic will check if there is %% %% between two underscores it will be a dymanic string
   */
  let duplicates = convertedHtml.match(/_(.*?)_/g);
  duplicates?.forEach((match: any) => {
    if (
      !match.includes('%%') &&
      !match.includes('%% %%') &&
      !match.includes('%%%%') &&
      !match.includes('{{') &&
      !match.includes('{{ }}') &&
      !match.includes('{{}}')
    ) {
      convertedHtml = convertedHtml.replace(/_(.*?)_/g, contentReturnElement);
    }
  });

  return convertedHtml;
};

/**
 * scroll to top
 */
export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
};

/**
 * checks if date time epoch is in past
 *
 * @param date to check
 */
export const isInPast = (date: string): boolean =>
  parseInt(date) < Math.floor(Date.now() / 1000);

/**
 * Check milestone status.
 *
 * @param milestone Milestone to be checked.
 * @return True if the milestone is completed, false otherwise.
 */
export const checkMilestoneStatus = (milestone: Milestone): boolean => {
  // Check if each block has been completed
  const hasCompletedFreeTextQuestionBlocks =
    milestone.FreeTextQuestionBlocks?.items.every(
      (block) => block?.isCompleted,
    );
  const hasCompletedTaskBlocks = milestone.TaskBlocks?.items.every(
    (block) => block?.isCompleted,
  );
  const hasCompletedMultipleChoiceQuestionBlocks =
    milestone.MultipleChoiceQuestionBlocks?.items.every(
      (block) => block?.isCompleted,
    );

  // The milestone is considered completed if all blocks are completed in their respective categories
  return !!(
    hasCompletedFreeTextQuestionBlocks &&
    hasCompletedTaskBlocks &&
    hasCompletedMultipleChoiceQuestionBlocks
  );
};

/**
 * add z-index to element
 * @param event event
 * @param querySelector selector to add z index
 */
export const addZIndexToQuerySelector = (
  event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>,
  querySelector: string,
) => {
  const button = event.target as HTMLButtonElement;
  const cardElements = document.querySelectorAll(querySelector);
  cardElements.forEach((element) => {
    element.classList.remove('card-z-1');
  });
  button.closest(querySelector)?.classList.add('card-z-1');
};

export const openExternalUrl = (url: string) => {
  window.open(url, '_blank');
};

/**
 * calculate send time date
 *
 * @param startDate playbook start date
 * @param daysFrom days from start date
 * @param sendTime send time
 */
export const calculateSendTimeDate = (
  startDate: string,
  daysFrom: number,
  sendTime: string = '8:00',
): string => {
  const startDateInMilliSeconds = new Date(startDate).getTime();
  const milliSecondsInOneDay = 24 * 60 * 60 * 1000;
  const daysFromInMilliSeconds = daysFrom * milliSecondsInOneDay;
  const weekendsCount = countWeekends(new Date(startDate), daysFrom + 1); // Add one day to count start day as well
  let totalInMilliSeconds = startDateInMilliSeconds + daysFromInMilliSeconds;

  if (daysFrom > 0) {
    totalInMilliSeconds += weekendsCount * milliSecondsInOneDay;
  } else {
    totalInMilliSeconds -= weekendsCount * milliSecondsInOneDay;
  }

  let resultingDate = new Date(totalInMilliSeconds);

  // Check if the resulting date is a weekend (Saturday or Sunday)
  if (resultingDate.getUTCDay() === 0) {
    if (daysFrom < 0) {
      totalInMilliSeconds -= 2 * milliSecondsInOneDay; // Remove two days to make it Friday
    } else {
      totalInMilliSeconds += milliSecondsInOneDay; // Add one day to make it Monday
    }
  } else if (resultingDate.getUTCDay() === 6) {
    if (daysFrom < 0) {
      totalInMilliSeconds -= milliSecondsInOneDay; // Remove one day to make it Friday
    } else {
      totalInMilliSeconds += 2 * milliSecondsInOneDay; // Add one day to make it Monday
    }
  }

  if (!sendTime) {
    return (totalInMilliSeconds / 1000).toString();
  }

  const sendTimeInMilliSeconds = +sendTime.split(':')[0] * 60 * 60 * 1000;
  const finalDateInMilliSeconds = totalInMilliSeconds + sendTimeInMilliSeconds;
  return (finalDateInMilliSeconds / 1000).toString();
};

/**
 * count weekends between start date and working days
 *
 * @param startDate playbook start date
 * @param daysFrom days from start date
 */
export const countWeekends = (startDate: Date, workingDays: number): number => {
  let currentDate = new Date(startDate);
  let weekendsCount = 0;

  if (workingDays > 0) {
    while (workingDays > 0) {
      const dayOfWeek = currentDate.getDay();
      if (dayOfWeek === 0 || dayOfWeek === 6) {
        weekendsCount++;
      }

      currentDate.setDate(currentDate.getDate() + 1);

      if (dayOfWeek !== 6 && dayOfWeek !== 0) {
        workingDays--;
      }
    }
  } else if (workingDays < 0) {
    while (workingDays < 0) {
      const dayOfWeek = currentDate.getDay();
      if (dayOfWeek === 0 || dayOfWeek === 6) {
        weekendsCount++;
      }

      currentDate.setDate(currentDate.getDate() - 1);

      if (dayOfWeek !== 6 && dayOfWeek !== 0) {
        workingDays++;
      }
    }
  }

  return weekendsCount;
};

/**
 * download csv file
 */
export const downloadCSV = (fileName: string, text: string) => {
  var element = document.createElement('a');
  element.setAttribute(
    'href',
    'data:text/csv;charset=utf-8,' + encodeURIComponent(text),
  );
  element.setAttribute('download', fileName);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

export const getRandomPlaybookImage = () => {
  const imageBaseURL = 'https://kin-assets.s3.eu-west-1.amazonaws.com/covers/';
  const placeholderImages = Array.from(
    { length: 9 },
    (_, i) => `${imageBaseURL}pb-${i + 1}.png`,
  );
  const randomIndex = Math.floor(Math.random() * placeholderImages.length);
  const playbookImage = placeholderImages[randomIndex];
  return playbookImage;
};
