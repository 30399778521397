import { resendSignUpCode, resetPassword } from 'aws-amplify/auth';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { addNotificationToApp } from '@Shared/utils/utils';

export const useEmailVerification = () => {
  const location = useLocation();
  const { isForgotPassword, email }: any = location.state;
  const [currentUserEmail, setCurrentUserEmail] = useState(email);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleForgotPassword = async () => {
    try {
      setIsSubmitted(true);
      if (currentUserEmail !== '') {
        setIsLoading(true);
        await resetPassword({ username: currentUserEmail });
        setIsLoading(false);
        navigate('/reset-password');
      }
    } catch (e: any) {
      setIsLoading(false);
      addNotificationToApp(
        'You may already have an account with us, please try and login with Google.',
        'error',
      );
      navigate('/login');
    }
  };

  const handleNext = async () => {
    try {
      setIsLoading(true);
      await resendSignUpCode({ username: currentUserEmail });
      setIsLoading(false);
      navigate('/account-verification', { state: { email: currentUserEmail } });
    } catch (err) {
      setIsLoading(false);
      addNotificationToApp('Failed to send verification code', 'error');
    }
  };

  return {
    currentUserEmail,
    isSubmitted,
    isForgotPassword,
    isLoading,
    setCurrentUserEmail,
    handleForgotPassword,
    handleNext,
  };
};
