import { useEffect, useState } from 'react';
import { type SecretKeyManager } from '@API';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { addNewSecretKeyManager, updateSecretKeyManagerData } from '@api/apis';
import { type GoogleCalendarKeysModalProps } from '@base/models/settings.model';
import { Analytics } from '@base/analytics/analytics';

export const useAddCalendarKeysModal = (
  props: GoogleCalendarKeysModalProps,
) => {
  const { handleShowModal, secretKeyManager, setSecretKeyCreatedDate } = props;
  const { user } = useUserContext();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [clientEmail, setClientEmail] = useState('');
  const [privateKey, setPrivateKey] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const addGoogleCalendarKeys = async () => {
    setIsSubmitted(true);
    if (clientEmail === '' || privateKey === '') {
      return;
    }

    setIsSaving(true);
    if (secretKeyManager && secretKeyManager.id) {
      await updateGoogleCalendarKeys(secretKeyManager.id);
    } else {
      const createSecretKeys = await addNewSecretKeyManager(user, {
        clientEmail,
        privateKey,
      } as SecretKeyManager);

      if (createSecretKeys) {
        setSecretKeyCreatedDate(createSecretKeys.createdAt);
      }

      Analytics.googleIntegrationAdded(user.userOrganizationId);
    }

    setIsSaving(false);
    setIsSubmitted(false);
    handleShowModal(false);
  };

  const updateGoogleCalendarKeys = async (id: string) => {
    const updatedSecretKeys = await updateSecretKeyManagerData({
      id,
      clientEmail,
      privateKey,
    } as SecretKeyManager);

    if (updatedSecretKeys) {
      handleShowModal(false);
      setSecretKeyCreatedDate(updatedSecretKeys.createdAt);
    }
  };

  useEffect(() => {
    if (secretKeyManager) {
      setClientEmail(secretKeyManager.clientEmail);
      setPrivateKey(secretKeyManager.privateKey ?? '');
    }
  }, [secretKeyManager]);

  return {
    isSubmitted,
    clientEmail,
    privateKey,
    isSaving,
    setClientEmail,
    setPrivateKey,
    addGoogleCalendarKeys,
    updateGoogleCalendarKeys,
  };
};
