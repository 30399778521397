import { Form, InputGroup, Modal } from 'react-bootstrap';
import { type IPlaybookSettingsModal } from '@base/models/journeyAssignees.model';
import { SelectBadgeDropdown, TextField, ToggleSwitch } from '@Shared/DS';
import { BuilderType } from '@base/API';
import { memo } from 'react';
import { Button } from '@Shared/DS/Atoms';
import { useJourneySettingsModal } from './usePlaybookSettingsModal';
import './PlaybookSettingsModal.scss';

export const PlaybookSettingsModal = memo((props: IPlaybookSettingsModal) => {
  const { showModal, parentPlaybook } = props;
  const {
    isBusy,
    isSubmitted,
    badges,
    startDate,
    setStartDate,
    selectedBadge,
    setSelectedBadge,
    showCustomBadgeNameField,
    setShowCustomBadgeNameField,
    customBadgeName,
    setCustomBadgeName,
    lockChapters,
    setLockChapters,
    showMicrosite,
    setShowMicrosite,
    handleSave,
    onHideModal,
    removeCustomBadgeName,
    removeBadge,
  } = useJourneySettingsModal(props);

  return (
    <Modal
      className="create-journey-modal-wrapper assign-journey-modal-wrapper journey-settings-modal-wrapper"
      size="lg"
      backdrop="static"
      fullscreen={'md-down'}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={showModal}
      onHide={onHideModal}
    >
      <Modal.Header>
        <Modal.Title>
          <div className="fs-24-semi-bold-25272A">
            Runbook microsite settings
          </div>
        </Modal.Title>
        <button
          type="button"
          className="btn-close modal-close-btn"
          data-bs-dismiss="modal"
          onClick={onHideModal}
          aria-label="Close"
        ></button>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          {parentPlaybook.type === BuilderType.INITIATIVE && (
            <div className="col-12">
              <label
                className="fs-14-semi-bold text-start"
                htmlFor="journeyStartDate"
              >
                Start date <span className="required-field-sign">*</span>
              </label>
              <div className="d-flex flex-wrap mb-3">
                <div className="col-12 col-lg-8 label-sub-heading">
                  Runbook start date. Messages, actions and meetings will be
                  scheduled based on this date.
                </div>
                <div className="col-12 col-lg-4">
                  <TextField
                    id={'journeyStartDate'}
                    type="date"
                    defaultValue={startDate}
                    setValue={(value: string) => setStartDate(value)}
                    fieldName="Date"
                    placeholder="Select start date"
                    additionalClasses={
                      !startDate && !startDate.length && isSubmitted
                        ? 'is-invalid'
                        : ''
                    }
                  />
                </div>
              </div>
            </div>
          )}

          <div className="col-12 d-flex flex-wrap align-items-center justify-content-between mb-3">
            <div className="col-12 col-lg-8 label-sub-heading">
              <label className="fs-14-semi-bold text-start">Badge</label>
              <div className="label-sub-heading">
                You can award users who complete the runbook with a badge.
              </div>
            </div>
            {badges && (
              <div className="col-12 col-lg-4">
                {!showCustomBadgeNameField && (
                  <div className="d-flex align-items-center">
                    <div className="flex-grow-1">
                      <SelectBadgeDropdown
                        badges={badges}
                        selectedBadge={selectedBadge}
                        selectBadgeHandler={setSelectedBadge}
                      />
                    </div>
                    {selectedBadge.id && (
                      <>
                        <i
                          title="Click to edit the badge name"
                          className="bi bi-pencil ms-1 cursor-pointer"
                          onClick={() => setShowCustomBadgeNameField(true)}
                        ></i>
                        <i
                          title="Click to remove badge"
                          className="bi bi-x ms-1 cursor-pointer"
                          onClick={removeBadge}
                        ></i>
                      </>
                    )}
                  </div>
                )}

                {showCustomBadgeNameField && selectedBadge.iconUrl && (
                  <div className="d-flex align-items-center">
                    <InputGroup className="flex-grow-1 mt-2">
                      <InputGroup.Text>
                        <img
                          src={selectedBadge.iconUrl}
                          alt="badge-icon"
                          width={27}
                          height={28}
                        />
                      </InputGroup.Text>

                      <Form.Control
                        className="badge-name-field"
                        defaultValue={customBadgeName}
                        onChange={(e) => setCustomBadgeName(e.target.value)}
                      />
                    </InputGroup>
                    <i
                      className="bi bi-x ms-1 cursor-pointer"
                      onClick={removeCustomBadgeName}
                    ></i>
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="col-12 d-flex align-items-center justify-content-between mb-3">
            <div>
              <label className="fs-14-semi-bold text-start">
                Lock chapters
              </label>
              <div className="label-sub-heading">
                Allow users to progress when all tasks are complete.
              </div>
            </div>
            <ToggleSwitch
              initialValue={lockChapters}
              setValue={(value: boolean) => setLockChapters(!lockChapters)}
            />
          </div>

          <div className="col-12 d-flex align-items-center justify-content-between mb-3">
            <div>
              <label className="fs-14-semi-bold text-start">
                Show microsite
              </label>
              <div className="label-sub-heading">
                The microsite will be shown to users assigned to this runbook.
              </div>
            </div>
            <ToggleSwitch
              initialValue={showMicrosite}
              setValue={() => setShowMicrosite(!showMicrosite)}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-end">
          <Button buttonStyle="outline" text="Cancel" onClick={onHideModal} />
          <Button
            disabled={isBusy}
            showLoading={isBusy}
            title="Save runbook settings"
            text="Save"
            additionalClasses="ms-2"
            onClick={handleSave}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
});
