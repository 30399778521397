import { Card } from 'react-bootstrap';
import { TextField } from '@Shared/DS/Molecules';
import './CreateOrganization.scss';
import { Button } from '@Shared/DS';
import { useCreateOrganization } from './useCreateOrganization';

export const CreateOrganization = () => {
  const {
    organizationName,
    isSubmitted,
    setOrganizationName,
    handleCreateCompany,
    isLoading,
  } = useCreateOrganization();

  return (
    <div className="new-organization-wrapper text-center">
      <img
        className="mt-5"
        src={require('../../Assets/Images/kinfolk-icon-dark.png')}
        alt="kinfolk icon"
      />
      <div className="mt-4 fs-24-25272a">Create a new Organization</div>
      <div className="mt-3">
        With Kinfolk you give new hires the clarity, confidence, and connections
        to be their best.
      </div>
      <div className="row justify-content-center mx-2">
        <Card className="mt-3 card-border col-12 col-md-4 pb-5">
          <Card.Body>
            <label className="fs-14-semi-bold col-12 text-start mt-4">
              Company Name
            </label>
            <TextField
              id={'organization'}
              type="text"
              defaultValue={organizationName}
              setValue={setOrganizationName}
              fieldName="Organization name"
              width=""
              placeholder="Enter your organization name"
              additionalClasses={` 
            ${!organizationName && organizationName.length === 0 && isSubmitted ? 'is-invalid' : ''}`}
            />
          </Card.Body>
        </Card>
      </div>
      <Button
        text="Continue"
        type="submit"
        additionalClasses="btn btn-button-primary fs-16-medium-bold py-3 mt-4 px-4"
        disabled={isLoading}
        showLoading={isLoading}
        onClick={handleCreateCompany}
        form="account-verification-form"
      />
    </div>
  );
};
