import { Row, Col } from 'react-bootstrap';
import { useEditPersonalInfo } from '@Hooks/useEditPersonalInfo';
import { type PersonalInfoProps } from '@base/models/profile.model';
import { TextField } from '../../DS/Molecules/TextField/TextField';
import './EditPersonalInfo.scss';

export default function EditPersonalInfo(props: PersonalInfoProps) {
  const { hiddenFileInput, handleClick } = useEditPersonalInfo();
  const {
    firstName,
    lastName,
    email,
    workLocation,
    isSubmitted,
    jobTitle,
    team,
    photoUrl,
    setFirstName,
    setLastName,
    setEmail,
    setWorkLocation,
    setJobTitle,
    setTeam,
  } = props;

  return (
    <div className="edit-personal-info-wrapper">
      <div
        className={`align-items-center d-flex ${props.isOnboarding ? 'justify-content-center' : 'justify-content-start'} `}
      >
        <input
          type="file"
          className="mt-2 d-none"
          onChange={(e) => props.fileSelectedHandler(e)}
          accept="image/*"
          ref={hiddenFileInput}
        ></input>
        {photoUrl && photoUrl !== '' && (
          <img
            src={photoUrl}
            className="rounded-circle edit-profile-image"
            alt="Avatar"
          />
        )}
        <button
          className="btn btn-button-primary text-center fs-14 mx-4"
          onClick={(e) => {
            handleClick(e);
          }}
        >
          Upload photo
        </button>
      </div>

      <Row className="d-flex justify-content-center mt-4">
        <Col xs="12" lg={`${props.isOnboarding ? '4' : '6'}`}>
          <label
            className="d-flex justify-content-start m-1"
            htmlFor="firstName"
          >
            First name
          </label>
          <TextField
            id={'firstName'}
            type={'text'}
            placeholder={'Enter your first name'}
            width={''}
            setValue={setFirstName}
            defaultValue={firstName}
            additionalClasses={
              !firstName && firstName.length === 0 && isSubmitted
                ? 'is-invalid'
                : ''
            }
            fieldName={'First Name'}
          ></TextField>
        </Col>
        <Col xs="12" lg={`${props.isOnboarding ? '4' : '6'}`}>
          <label
            className="d-flex justify-content-start m-1"
            htmlFor="lastName"
          >
            Last name
          </label>
          <TextField
            id={'lastName'}
            type={'text'}
            placeholder={'Enter your last name'}
            width={''}
            setValue={setLastName}
            defaultValue={lastName}
            additionalClasses={
              !lastName && lastName.length === 0 && isSubmitted
                ? 'is-invalid'
                : ''
            }
            fieldName={'Last Name'}
          ></TextField>
        </Col>
      </Row>

      <Row className="d-flex justify-content-center mt-3">
        <Col xs="12" lg={`${props.isOnboarding ? '8' : '6'}`}>
          <label className="d-flex justify-content-start m-1" htmlFor="email">
            Email
          </label>
          <TextField
            disabled
            id={'email'}
            type={'text'}
            placeholder={'Enter your work email'}
            width={''}
            setValue={setEmail}
            defaultValue={email}
            additionalClasses={
              !email && email.length === 0 && isSubmitted ? 'is-invalid' : ''
            }
            fieldName={'Email'}
          ></TextField>
        </Col>
        {!props.isOnboarding && (
          <Col xs="12" lg="6">
            <label
              className="d-flex justify-content-start m-1"
              htmlFor="workLocation"
            >
              Work location
            </label>
            <TextField
              id={'workLocation'}
              type={'text'}
              placeholder={'Enter your work location'}
              width={''}
              setValue={setWorkLocation}
              defaultValue={workLocation}
              additionalClasses={
                !workLocation && workLocation.length === 0 && isSubmitted
                  ? 'is-invalid'
                  : ''
              }
              fieldName={'Work Location'}
            ></TextField>
          </Col>
        )}
      </Row>

      {!props.isOnboarding && (
        <Row className="d-flex justify-content-start mt-3">
          <Col xs="12" lg="6" className="mt-1">
            <label
              className="d-flex justify-content-start m-1"
              htmlFor="jobTitle"
            >
              Job title
            </label>
            <TextField
              id={'jobTitle'}
              type={'text'}
              placeholder={'Enter your job title'}
              width={''}
              setValue={setJobTitle}
              defaultValue={jobTitle}
              additionalClasses={
                !jobTitle && jobTitle.length === 0 && isSubmitted
                  ? 'is-invalid'
                  : ''
              }
              fieldName={'Job Title'}
            ></TextField>
          </Col>
          <Col xs="12" lg="6" className="mt-1">
            <label className="d-flex justify-content-start m-1" htmlFor="team">
              Team
            </label>
            <TextField
              id={'team'}
              type={'text'}
              placeholder={'Enter your team name'}
              setValue={setTeam}
              defaultValue={team}
              additionalClasses={
                !team && team.length === 0 && isSubmitted ? 'is-invalid' : ''
              }
              fieldName={'Team'}
            ></TextField>
          </Col>
        </Row>
      )}
    </div>
  );
}
