import { useEffect, useState } from 'react';
import { AssigneeRole, Channel, type Event, EventStatus } from '@API';
import { createPlaybookEvent, updatePlaybookEvent } from '@api/apis';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { type AddMessageModalProps } from '@base/models/journeyLibrary.model';
import { type IEvent } from '@base/models/playbookHome.model';
import { useEvents } from '@base/Hooks/useEvents';
import { Analytics } from '@base/analytics/analytics';
import { EventType } from '@base/models/common.model';

export const useEmailTab = (params: AddMessageModalProps) => {
  const {
    messageEvent,
    playbookStatus,
    builderId,
    handleSave,
    handleUpdate,
    isParentJourney,
    assigneeEmails,
    onHideModal,
    assigneeHireDate,
    assigneeId,
    journeyStartDate: playbookStartDate,
    isTemplate,
    isEditingAllowed,
  } = params;
  const [subject, setSubject] = useState('');
  const [messageBody, setMessageBody] = useState('');
  const [time, setTime] = useState('09:00');
  const times = Array.from({ length: 24 }, (_, i) =>
    i < 9 ? `0${i + 1}:00` : `${i + 1}:00`,
  );
  const [isSubmitted, setSubmitted] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState<string | null>(
    (assigneeEmails.length && assigneeEmails[0]) || null,
  );
  const [isSaving, setIsSaving] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const { setIsParentJourneyChanged } = useUserContext();
  const [isEditable, setIsEditable] = useState(true);
  const [cc, setCc] = useState<any[]>([]);
  const [showCCField, setShowCCField] = useState(false);
  const [selectedDaysFrom, setSelectedDaysFrom] = useState('Start day');
  const parentJourneyEmailOptions = [
    '%%assignee-email%%',
    '%%assignee-personalemail%%',
  ];
  const {
    getDaysFrom,
    getEventStatus,
    getSendTimeDate,
    mapDaysFromToLabel,
    uploadEventDescription,
    daysFromOptions,
  } = useEvents();

  const createNewMessage = async () => {
    setShowLoader(true);
    setSubmitted(true);
    if (subject.length && messageBody.length) {
      setIsSaving(true);

      let messageEventRequest = {
        title: subject,
        userId: assigneeId,
        emailTo: selectedEmail ?? parentJourneyEmailOptions[0],
        cc: cc.map(
          (user) => user.email ?? user.role?.toUpperCase() ?? user.label,
        ),
        type: EventType.MESSAGE,
        daysFrom: getDaysFrom(selectedDaysFrom),
        sendTime: time,
        status: getEventStatus(
          messageEvent.status ?? EventStatus.DRAFT,
          isParentJourney,
          playbookStatus,
          getSendTimeDate(
            selectedDaysFrom,
            playbookStartDate ?? assigneeHireDate,
            time,
          ),
        ),
        sendTimeDate: getSendTimeDate(
          selectedDaysFrom,
          playbookStartDate ?? assigneeHireDate,
          time,
        ),
        channel: Channel.EMAIL,
      } as Event;

      if (isTemplate) {
        messageEventRequest = {
          ...messageEventRequest,
          kinfolkTemplateID: builderId,
        };
      } else {
        messageEventRequest = {
          ...messageEventRequest,
          journeyID: builderId,
        };
      }

      if (isParentJourney) {
        setIsParentJourneyChanged(true);
      }

      let id;
      if (messageEvent.id) {
        id = await updatePlaybookEvent({
          ...messageEventRequest,
          id: messageEvent.id,
          updatedInChild: !isParentJourney,
        } as Event);

        if (id) {
          messageEventRequest.message = await uploadEventDescription(
            messageEventRequest,
            messageBody,
            id,
            builderId,
            isParentJourney,
          );
          handleUpdate({ ...messageEventRequest, id: id });
        }
      } else {
        id = await createPlaybookEvent({ ...messageEventRequest } as Event);
        if (id) {
          messageEventRequest.message = await uploadEventDescription(
            messageEventRequest,
            messageBody,
            id,
            builderId,
            isParentJourney,
          );
          handleSave({ ...messageEventRequest, id: id });

          Analytics.playbookEventAdded(
            messageEventRequest.type as EventType,
            messageEventRequest.channel,
          );
        }
      }
      if (id) {
        handleHideModal();
      } else {
        setIsSaving(false);
      }
    }
    setShowLoader(false);
  };

  const handleHideModal = () => {
    onHideModal();
    setSubmitted(false);
    setIsSaving(false);
    setSubject('');
    setMessageBody('');
    setSelectedEmail(null);
  };

  const setEmailCC = async (event: IEvent) => {
    if (event.cc && event.cc.length) {
      setCc(
        event.cc.map((item) => {
          if (
            Object.values(AssigneeRole).includes(
              item?.toUpperCase() as AssigneeRole,
            )
          ) {
            return {
              role: item,
            };
          }

          return {
            email: item,
          };
        }),
      );

      setShowCCField(true);
    } else {
      setCc([]);
    }
  };

  useEffect(() => {
    setSubmitted(false);
    if (messageEvent.id) {
      setSubject(messageEvent.title ?? '');
      setMessageBody(messageEvent.message || '');
      setSelectedEmail(
        messageEvent.emailTo ||
          (assigneeEmails.length && assigneeEmails[0]) ||
          null,
      );
      setIsEditable(
        messageEvent.status !== EventStatus.SENT && !!isEditingAllowed,
      );
      setEmailCC(messageEvent);
      setTime(messageEvent.sendTime ?? '09:00');
      setSelectedDaysFrom(mapDaysFromToLabel(messageEvent.daysFrom));
    } else if (messageEvent.daysFrom) {
      setSelectedDaysFrom(mapDaysFromToLabel(messageEvent.daysFrom));
    }
  }, [assigneeEmails, isEditingAllowed, mapDaysFromToLabel, messageEvent]);

  useEffect(() => {
    if (messageEvent.message) setMessageBody(messageEvent.message);
  }, [messageEvent.message]);

  return {
    showLoader,
    subject,
    messageBody,
    isSubmitted,
    isSaving,
    selectedEmail,
    isEditable,
    cc,
    parentJourneyEmailOptions,
    time,
    times,
    showCCField,
    daysFromOptions,
    selectedDaysFrom,
    setSelectedDaysFrom,
    setShowCCField,
    setTime,
    setCc,
    setSelectedEmail,
    setSubject,
    setMessageBody,
    createNewMessage,
    handleHideModal,
  };
};
