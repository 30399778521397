/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createShareInfo = /* GraphQL */ `mutation CreateShareInfo(
  $input: CreateShareInfoInput!
  $condition: ModelShareInfoConditionInput
) {
  createShareInfo(input: $input, condition: $condition) {
    id
    name
    organizationID
    shareInfoOnUsers {
      nextToken
      __typename
    }
    learnInfoOnUsers {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateShareInfoMutationVariables,
  APITypes.CreateShareInfoMutation
>;
export const updateShareInfo = /* GraphQL */ `mutation UpdateShareInfo(
  $input: UpdateShareInfoInput!
  $condition: ModelShareInfoConditionInput
) {
  updateShareInfo(input: $input, condition: $condition) {
    id
    name
    organizationID
    shareInfoOnUsers {
      nextToken
      __typename
    }
    learnInfoOnUsers {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateShareInfoMutationVariables,
  APITypes.UpdateShareInfoMutation
>;
export const deleteShareInfo = /* GraphQL */ `mutation DeleteShareInfo(
  $input: DeleteShareInfoInput!
  $condition: ModelShareInfoConditionInput
) {
  deleteShareInfo(input: $input, condition: $condition) {
    id
    name
    organizationID
    shareInfoOnUsers {
      nextToken
      __typename
    }
    learnInfoOnUsers {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteShareInfoMutationVariables,
  APITypes.DeleteShareInfoMutation
>;
export const createWorkStyle = /* GraphQL */ `mutation CreateWorkStyle(
  $input: CreateWorkStyleInput!
  $condition: ModelWorkStyleConditionInput
) {
  createWorkStyle(input: $input, condition: $condition) {
    id
    whatIDoAtWork
    myFocusTimeIs
    giveReceivingFeedback
    digestionInformation
    inMyElement
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateWorkStyleMutationVariables,
  APITypes.CreateWorkStyleMutation
>;
export const updateWorkStyle = /* GraphQL */ `mutation UpdateWorkStyle(
  $input: UpdateWorkStyleInput!
  $condition: ModelWorkStyleConditionInput
) {
  updateWorkStyle(input: $input, condition: $condition) {
    id
    whatIDoAtWork
    myFocusTimeIs
    giveReceivingFeedback
    digestionInformation
    inMyElement
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateWorkStyleMutationVariables,
  APITypes.UpdateWorkStyleMutation
>;
export const deleteWorkStyle = /* GraphQL */ `mutation DeleteWorkStyle(
  $input: DeleteWorkStyleInput!
  $condition: ModelWorkStyleConditionInput
) {
  deleteWorkStyle(input: $input, condition: $condition) {
    id
    whatIDoAtWork
    myFocusTimeIs
    giveReceivingFeedback
    digestionInformation
    inMyElement
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteWorkStyleMutationVariables,
  APITypes.DeleteWorkStyleMutation
>;
export const createWorkPreference = /* GraphQL */ `mutation CreateWorkPreference(
  $input: CreateWorkPreferenceInput!
  $condition: ModelWorkPreferenceConditionInput
) {
  createWorkPreference(input: $input, condition: $condition) {
    id
    giveMeAllTheContext
    messageTimingPreferene
    syncxVsAsync
    talkTogetherVsAlone
    recognitionPrivateVsPrivate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateWorkPreferenceMutationVariables,
  APITypes.CreateWorkPreferenceMutation
>;
export const updateWorkPreference = /* GraphQL */ `mutation UpdateWorkPreference(
  $input: UpdateWorkPreferenceInput!
  $condition: ModelWorkPreferenceConditionInput
) {
  updateWorkPreference(input: $input, condition: $condition) {
    id
    giveMeAllTheContext
    messageTimingPreferene
    syncxVsAsync
    talkTogetherVsAlone
    recognitionPrivateVsPrivate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateWorkPreferenceMutationVariables,
  APITypes.UpdateWorkPreferenceMutation
>;
export const deleteWorkPreference = /* GraphQL */ `mutation DeleteWorkPreference(
  $input: DeleteWorkPreferenceInput!
  $condition: ModelWorkPreferenceConditionInput
) {
  deleteWorkPreference(input: $input, condition: $condition) {
    id
    giveMeAllTheContext
    messageTimingPreferene
    syncxVsAsync
    talkTogetherVsAlone
    recognitionPrivateVsPrivate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteWorkPreferenceMutationVariables,
  APITypes.DeleteWorkPreferenceMutation
>;
export const createInterest = /* GraphQL */ `mutation CreateInterest(
  $input: CreateInterestInput!
  $condition: ModelInterestConditionInput
) {
  createInterest(input: $input, condition: $condition) {
    id
    name
    organizationID
    users {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateInterestMutationVariables,
  APITypes.CreateInterestMutation
>;
export const updateInterest = /* GraphQL */ `mutation UpdateInterest(
  $input: UpdateInterestInput!
  $condition: ModelInterestConditionInput
) {
  updateInterest(input: $input, condition: $condition) {
    id
    name
    organizationID
    users {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateInterestMutationVariables,
  APITypes.UpdateInterestMutation
>;
export const deleteInterest = /* GraphQL */ `mutation DeleteInterest(
  $input: DeleteInterestInput!
  $condition: ModelInterestConditionInput
) {
  deleteInterest(input: $input, condition: $condition) {
    id
    name
    organizationID
    users {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteInterestMutationVariables,
  APITypes.DeleteInterestMutation
>;
export const createLocation = /* GraphQL */ `mutation CreateLocation(
  $input: CreateLocationInput!
  $condition: ModelLocationConditionInput
) {
  createLocation(input: $input, condition: $condition) {
    id
    name
    streetAddress
    city
    state
    zipCode
    country
    locationType
    organizationID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateLocationMutationVariables,
  APITypes.CreateLocationMutation
>;
export const updateLocation = /* GraphQL */ `mutation UpdateLocation(
  $input: UpdateLocationInput!
  $condition: ModelLocationConditionInput
) {
  updateLocation(input: $input, condition: $condition) {
    id
    name
    streetAddress
    city
    state
    zipCode
    country
    locationType
    organizationID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateLocationMutationVariables,
  APITypes.UpdateLocationMutation
>;
export const deleteLocation = /* GraphQL */ `mutation DeleteLocation(
  $input: DeleteLocationInput!
  $condition: ModelLocationConditionInput
) {
  deleteLocation(input: $input, condition: $condition) {
    id
    name
    streetAddress
    city
    state
    zipCode
    country
    locationType
    organizationID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteLocationMutationVariables,
  APITypes.DeleteLocationMutation
>;
export const createOrganization = /* GraphQL */ `mutation CreateOrganization(
  $input: CreateOrganizationInput!
  $condition: ModelOrganizationConditionInput
) {
  createOrganization(input: $input, condition: $condition) {
    id
    name
    slackAccessToken
    mergeCompanyToken
    atsToken
    atsAccountId
    hrisToken
    hrisAccountId
    hrisIntegratedAt
    slackUserAccessToken
    outlookTenantId
    msTeamsTenantId
    Locations {
      nextToken
      __typename
    }
    Interests {
      nextToken
      __typename
    }
    ShareInfos {
      nextToken
      __typename
    }
    status
    activeUntil
    hasSubscription
    preboarding
    Journeys {
      nextToken
      __typename
    }
    features
    globalDataStores
    settings
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOrganizationMutationVariables,
  APITypes.CreateOrganizationMutation
>;
export const updateOrganization = /* GraphQL */ `mutation UpdateOrganization(
  $input: UpdateOrganizationInput!
  $condition: ModelOrganizationConditionInput
) {
  updateOrganization(input: $input, condition: $condition) {
    id
    name
    slackAccessToken
    mergeCompanyToken
    atsToken
    atsAccountId
    hrisToken
    hrisAccountId
    hrisIntegratedAt
    slackUserAccessToken
    outlookTenantId
    msTeamsTenantId
    Locations {
      nextToken
      __typename
    }
    Interests {
      nextToken
      __typename
    }
    ShareInfos {
      nextToken
      __typename
    }
    status
    activeUntil
    hasSubscription
    preboarding
    Journeys {
      nextToken
      __typename
    }
    features
    globalDataStores
    settings
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOrganizationMutationVariables,
  APITypes.UpdateOrganizationMutation
>;
export const deleteOrganization = /* GraphQL */ `mutation DeleteOrganization(
  $input: DeleteOrganizationInput!
  $condition: ModelOrganizationConditionInput
) {
  deleteOrganization(input: $input, condition: $condition) {
    id
    name
    slackAccessToken
    mergeCompanyToken
    atsToken
    atsAccountId
    hrisToken
    hrisAccountId
    hrisIntegratedAt
    slackUserAccessToken
    outlookTenantId
    msTeamsTenantId
    Locations {
      nextToken
      __typename
    }
    Interests {
      nextToken
      __typename
    }
    ShareInfos {
      nextToken
      __typename
    }
    status
    activeUntil
    hasSubscription
    preboarding
    Journeys {
      nextToken
      __typename
    }
    features
    globalDataStores
    settings
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOrganizationMutationVariables,
  APITypes.DeleteOrganizationMutation
>;
export const createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    id
    offset
    firstName
    email
    phone
    sendWelcomeEmail
    personalEmail
    jobTitle
    birthday
    startDate
    homeLocation
    team
    photoUrl
    pronoun
    aboutMe
    mostHappy
    socialMediaUrl
    slackUserId
    isFirstLogin
    isHomeModelViewed
    isProfileModelViewed
    type
    isActive
    Organization {
      id
      name
      slackAccessToken
      mergeCompanyToken
      atsToken
      atsAccountId
      hrisToken
      hrisAccountId
      hrisIntegratedAt
      slackUserAccessToken
      outlookTenantId
      msTeamsTenantId
      status
      activeUntil
      hasSubscription
      preboarding
      features
      globalDataStores
      settings
      createdAt
      updatedAt
      __typename
    }
    Interests {
      nextToken
      __typename
    }
    WorkPreference {
      id
      giveMeAllTheContext
      messageTimingPreferene
      syncxVsAsync
      talkTogetherVsAlone
      recognitionPrivateVsPrivate
      createdAt
      updatedAt
      __typename
    }
    WorkStyle {
      id
      whatIDoAtWork
      myFocusTimeIs
      giveReceivingFeedback
      digestionInformation
      inMyElement
      createdAt
      updatedAt
      __typename
    }
    lastName
    ShareInfoOn {
      nextToken
      __typename
    }
    LearnMoreOn {
      nextToken
      __typename
    }
    WorkLocation {
      id
      name
      streetAddress
      city
      state
      zipCode
      country
      locationType
      organizationID
      createdAt
      updatedAt
      __typename
    }
    Journeys {
      nextToken
      __typename
    }
    Meetings {
      nextToken
      __typename
    }
    PreboardingUsers {
      nextToken
      __typename
    }
    PreboardingUserRelations {
      nextToken
      __typename
    }
    UserBadges {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    userOrganizationId
    userWorkPreferenceId
    userWorkStyleId
    userWorkLocationId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    id
    offset
    firstName
    email
    phone
    sendWelcomeEmail
    personalEmail
    jobTitle
    birthday
    startDate
    homeLocation
    team
    photoUrl
    pronoun
    aboutMe
    mostHappy
    socialMediaUrl
    slackUserId
    isFirstLogin
    isHomeModelViewed
    isProfileModelViewed
    type
    isActive
    Organization {
      id
      name
      slackAccessToken
      mergeCompanyToken
      atsToken
      atsAccountId
      hrisToken
      hrisAccountId
      hrisIntegratedAt
      slackUserAccessToken
      outlookTenantId
      msTeamsTenantId
      status
      activeUntil
      hasSubscription
      preboarding
      features
      globalDataStores
      settings
      createdAt
      updatedAt
      __typename
    }
    Interests {
      nextToken
      __typename
    }
    WorkPreference {
      id
      giveMeAllTheContext
      messageTimingPreferene
      syncxVsAsync
      talkTogetherVsAlone
      recognitionPrivateVsPrivate
      createdAt
      updatedAt
      __typename
    }
    WorkStyle {
      id
      whatIDoAtWork
      myFocusTimeIs
      giveReceivingFeedback
      digestionInformation
      inMyElement
      createdAt
      updatedAt
      __typename
    }
    lastName
    ShareInfoOn {
      nextToken
      __typename
    }
    LearnMoreOn {
      nextToken
      __typename
    }
    WorkLocation {
      id
      name
      streetAddress
      city
      state
      zipCode
      country
      locationType
      organizationID
      createdAt
      updatedAt
      __typename
    }
    Journeys {
      nextToken
      __typename
    }
    Meetings {
      nextToken
      __typename
    }
    PreboardingUsers {
      nextToken
      __typename
    }
    PreboardingUserRelations {
      nextToken
      __typename
    }
    UserBadges {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    userOrganizationId
    userWorkPreferenceId
    userWorkStyleId
    userWorkLocationId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    id
    offset
    firstName
    email
    phone
    sendWelcomeEmail
    personalEmail
    jobTitle
    birthday
    startDate
    homeLocation
    team
    photoUrl
    pronoun
    aboutMe
    mostHappy
    socialMediaUrl
    slackUserId
    isFirstLogin
    isHomeModelViewed
    isProfileModelViewed
    type
    isActive
    Organization {
      id
      name
      slackAccessToken
      mergeCompanyToken
      atsToken
      atsAccountId
      hrisToken
      hrisAccountId
      hrisIntegratedAt
      slackUserAccessToken
      outlookTenantId
      msTeamsTenantId
      status
      activeUntil
      hasSubscription
      preboarding
      features
      globalDataStores
      settings
      createdAt
      updatedAt
      __typename
    }
    Interests {
      nextToken
      __typename
    }
    WorkPreference {
      id
      giveMeAllTheContext
      messageTimingPreferene
      syncxVsAsync
      talkTogetherVsAlone
      recognitionPrivateVsPrivate
      createdAt
      updatedAt
      __typename
    }
    WorkStyle {
      id
      whatIDoAtWork
      myFocusTimeIs
      giveReceivingFeedback
      digestionInformation
      inMyElement
      createdAt
      updatedAt
      __typename
    }
    lastName
    ShareInfoOn {
      nextToken
      __typename
    }
    LearnMoreOn {
      nextToken
      __typename
    }
    WorkLocation {
      id
      name
      streetAddress
      city
      state
      zipCode
      country
      locationType
      organizationID
      createdAt
      updatedAt
      __typename
    }
    Journeys {
      nextToken
      __typename
    }
    Meetings {
      nextToken
      __typename
    }
    PreboardingUsers {
      nextToken
      __typename
    }
    PreboardingUserRelations {
      nextToken
      __typename
    }
    UserBadges {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    userOrganizationId
    userWorkPreferenceId
    userWorkStyleId
    userWorkLocationId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const createJourney = /* GraphQL */ `mutation CreateJourney(
  $input: CreateJourneyInput!
  $condition: ModelJourneyConditionInput
) {
  createJourney(input: $input, condition: $condition) {
    id
    name
    createdByUserID
    assignedUserID
    mode
    startDate
    journeyProgress
    parentJourneyID
    collaborator
    status
    badgeID
    customBadgeName
    lockChapters
    type
    publicLink
    eventStartedAt
    userStartedAt
    userCompletedAt
    isArchived
    preboarding
    organizationID
    Milestone {
      nextToken
      __typename
    }
    Meetings {
      nextToken
      __typename
    }
    JourneyCollaborator {
      nextToken
      __typename
    }
    events {
      nextToken
      __typename
    }
    image
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateJourneyMutationVariables,
  APITypes.CreateJourneyMutation
>;
export const updateJourney = /* GraphQL */ `mutation UpdateJourney(
  $input: UpdateJourneyInput!
  $condition: ModelJourneyConditionInput
) {
  updateJourney(input: $input, condition: $condition) {
    id
    name
    createdByUserID
    assignedUserID
    mode
    startDate
    journeyProgress
    parentJourneyID
    collaborator
    status
    badgeID
    customBadgeName
    lockChapters
    type
    publicLink
    eventStartedAt
    userStartedAt
    userCompletedAt
    isArchived
    preboarding
    organizationID
    Milestone {
      nextToken
      __typename
    }
    Meetings {
      nextToken
      __typename
    }
    JourneyCollaborator {
      nextToken
      __typename
    }
    events {
      nextToken
      __typename
    }
    image
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateJourneyMutationVariables,
  APITypes.UpdateJourneyMutation
>;
export const deleteJourney = /* GraphQL */ `mutation DeleteJourney(
  $input: DeleteJourneyInput!
  $condition: ModelJourneyConditionInput
) {
  deleteJourney(input: $input, condition: $condition) {
    id
    name
    createdByUserID
    assignedUserID
    mode
    startDate
    journeyProgress
    parentJourneyID
    collaborator
    status
    badgeID
    customBadgeName
    lockChapters
    type
    publicLink
    eventStartedAt
    userStartedAt
    userCompletedAt
    isArchived
    preboarding
    organizationID
    Milestone {
      nextToken
      __typename
    }
    Meetings {
      nextToken
      __typename
    }
    JourneyCollaborator {
      nextToken
      __typename
    }
    events {
      nextToken
      __typename
    }
    image
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteJourneyMutationVariables,
  APITypes.DeleteJourneyMutation
>;
export const createBadge = /* GraphQL */ `mutation CreateBadge(
  $input: CreateBadgeInput!
  $condition: ModelBadgeConditionInput
) {
  createBadge(input: $input, condition: $condition) {
    id
    name
    iconUrl
    isArchived
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBadgeMutationVariables,
  APITypes.CreateBadgeMutation
>;
export const updateBadge = /* GraphQL */ `mutation UpdateBadge(
  $input: UpdateBadgeInput!
  $condition: ModelBadgeConditionInput
) {
  updateBadge(input: $input, condition: $condition) {
    id
    name
    iconUrl
    isArchived
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBadgeMutationVariables,
  APITypes.UpdateBadgeMutation
>;
export const deleteBadge = /* GraphQL */ `mutation DeleteBadge(
  $input: DeleteBadgeInput!
  $condition: ModelBadgeConditionInput
) {
  deleteBadge(input: $input, condition: $condition) {
    id
    name
    iconUrl
    isArchived
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBadgeMutationVariables,
  APITypes.DeleteBadgeMutation
>;
export const createUserBadge = /* GraphQL */ `mutation CreateUserBadge(
  $input: CreateUserBadgeInput!
  $condition: ModelUserBadgeConditionInput
) {
  createUserBadge(input: $input, condition: $condition) {
    id
    playbookId
    userID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserBadgeMutationVariables,
  APITypes.CreateUserBadgeMutation
>;
export const updateUserBadge = /* GraphQL */ `mutation UpdateUserBadge(
  $input: UpdateUserBadgeInput!
  $condition: ModelUserBadgeConditionInput
) {
  updateUserBadge(input: $input, condition: $condition) {
    id
    playbookId
    userID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserBadgeMutationVariables,
  APITypes.UpdateUserBadgeMutation
>;
export const deleteUserBadge = /* GraphQL */ `mutation DeleteUserBadge(
  $input: DeleteUserBadgeInput!
  $condition: ModelUserBadgeConditionInput
) {
  deleteUserBadge(input: $input, condition: $condition) {
    id
    playbookId
    userID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserBadgeMutationVariables,
  APITypes.DeleteUserBadgeMutation
>;
export const createJourneyCollaborator = /* GraphQL */ `mutation CreateJourneyCollaborator(
  $input: CreateJourneyCollaboratorInput!
  $condition: ModelJourneyCollaboratorConditionInput
) {
  createJourneyCollaborator(input: $input, condition: $condition) {
    id
    assigneeRole
    journeyAccess
    assignedUserID
    actionID
    meetingID
    updatedInChild
    journeyID
    kinfolkTemplateID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateJourneyCollaboratorMutationVariables,
  APITypes.CreateJourneyCollaboratorMutation
>;
export const updateJourneyCollaborator = /* GraphQL */ `mutation UpdateJourneyCollaborator(
  $input: UpdateJourneyCollaboratorInput!
  $condition: ModelJourneyCollaboratorConditionInput
) {
  updateJourneyCollaborator(input: $input, condition: $condition) {
    id
    assigneeRole
    journeyAccess
    assignedUserID
    actionID
    meetingID
    updatedInChild
    journeyID
    kinfolkTemplateID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateJourneyCollaboratorMutationVariables,
  APITypes.UpdateJourneyCollaboratorMutation
>;
export const deleteJourneyCollaborator = /* GraphQL */ `mutation DeleteJourneyCollaborator(
  $input: DeleteJourneyCollaboratorInput!
  $condition: ModelJourneyCollaboratorConditionInput
) {
  deleteJourneyCollaborator(input: $input, condition: $condition) {
    id
    assigneeRole
    journeyAccess
    assignedUserID
    actionID
    meetingID
    updatedInChild
    journeyID
    kinfolkTemplateID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteJourneyCollaboratorMutationVariables,
  APITypes.DeleteJourneyCollaboratorMutation
>;
export const createKinfolkTemplate = /* GraphQL */ `mutation CreateKinfolkTemplate(
  $input: CreateKinfolkTemplateInput!
  $condition: ModelKinfolkTemplateConditionInput
) {
  createKinfolkTemplate(input: $input, condition: $condition) {
    id
    name
    description
    image
    isArchived
    status
    orderNo
    type
    mode
    isPublic
    Milestone {
      nextToken
      __typename
    }
    Meetings {
      nextToken
      __typename
    }
    JourneyCollaborator {
      nextToken
      __typename
    }
    events {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateKinfolkTemplateMutationVariables,
  APITypes.CreateKinfolkTemplateMutation
>;
export const updateKinfolkTemplate = /* GraphQL */ `mutation UpdateKinfolkTemplate(
  $input: UpdateKinfolkTemplateInput!
  $condition: ModelKinfolkTemplateConditionInput
) {
  updateKinfolkTemplate(input: $input, condition: $condition) {
    id
    name
    description
    image
    isArchived
    status
    orderNo
    type
    mode
    isPublic
    Milestone {
      nextToken
      __typename
    }
    Meetings {
      nextToken
      __typename
    }
    JourneyCollaborator {
      nextToken
      __typename
    }
    events {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateKinfolkTemplateMutationVariables,
  APITypes.UpdateKinfolkTemplateMutation
>;
export const deleteKinfolkTemplate = /* GraphQL */ `mutation DeleteKinfolkTemplate(
  $input: DeleteKinfolkTemplateInput!
  $condition: ModelKinfolkTemplateConditionInput
) {
  deleteKinfolkTemplate(input: $input, condition: $condition) {
    id
    name
    description
    image
    isArchived
    status
    orderNo
    type
    mode
    isPublic
    Milestone {
      nextToken
      __typename
    }
    Meetings {
      nextToken
      __typename
    }
    JourneyCollaborator {
      nextToken
      __typename
    }
    events {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteKinfolkTemplateMutationVariables,
  APITypes.DeleteKinfolkTemplateMutation
>;
export const createMilestone = /* GraphQL */ `mutation CreateMilestone(
  $input: CreateMilestoneInput!
  $condition: ModelMilestoneConditionInput
) {
  createMilestone(input: $input, condition: $condition) {
    id
    name
    kinfolkTemplateID
    isCompleted
    isArchived
    journeyID
    orderNo
    parentMilestoneID
    EmbeddedContentBlocks {
      nextToken
      __typename
    }
    FreeTextQuestionBlocks {
      nextToken
      __typename
    }
    NoteBlocks {
      nextToken
      __typename
    }
    TaskBlocks {
      nextToken
      __typename
    }
    StandardContentBlocks {
      nextToken
      __typename
    }
    MultipleChoiceQuestionBlocks {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMilestoneMutationVariables,
  APITypes.CreateMilestoneMutation
>;
export const updateMilestone = /* GraphQL */ `mutation UpdateMilestone(
  $input: UpdateMilestoneInput!
  $condition: ModelMilestoneConditionInput
) {
  updateMilestone(input: $input, condition: $condition) {
    id
    name
    kinfolkTemplateID
    isCompleted
    isArchived
    journeyID
    orderNo
    parentMilestoneID
    EmbeddedContentBlocks {
      nextToken
      __typename
    }
    FreeTextQuestionBlocks {
      nextToken
      __typename
    }
    NoteBlocks {
      nextToken
      __typename
    }
    TaskBlocks {
      nextToken
      __typename
    }
    StandardContentBlocks {
      nextToken
      __typename
    }
    MultipleChoiceQuestionBlocks {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMilestoneMutationVariables,
  APITypes.UpdateMilestoneMutation
>;
export const deleteMilestone = /* GraphQL */ `mutation DeleteMilestone(
  $input: DeleteMilestoneInput!
  $condition: ModelMilestoneConditionInput
) {
  deleteMilestone(input: $input, condition: $condition) {
    id
    name
    kinfolkTemplateID
    isCompleted
    isArchived
    journeyID
    orderNo
    parentMilestoneID
    EmbeddedContentBlocks {
      nextToken
      __typename
    }
    FreeTextQuestionBlocks {
      nextToken
      __typename
    }
    NoteBlocks {
      nextToken
      __typename
    }
    TaskBlocks {
      nextToken
      __typename
    }
    StandardContentBlocks {
      nextToken
      __typename
    }
    MultipleChoiceQuestionBlocks {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMilestoneMutationVariables,
  APITypes.DeleteMilestoneMutation
>;
export const createEmbeddedContentBlock = /* GraphQL */ `mutation CreateEmbeddedContentBlock(
  $input: CreateEmbeddedContentBlockInput!
  $condition: ModelEmbeddedContentBlockConditionInput
) {
  createEmbeddedContentBlock(input: $input, condition: $condition) {
    id
    type
    milestoneID
    title
    description
    resourceLink
    isFormLinked
    orderNo
    parentEmbeddedContentBlockID
    isCompleted
    isArchived
    updatedInChild
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateEmbeddedContentBlockMutationVariables,
  APITypes.CreateEmbeddedContentBlockMutation
>;
export const updateEmbeddedContentBlock = /* GraphQL */ `mutation UpdateEmbeddedContentBlock(
  $input: UpdateEmbeddedContentBlockInput!
  $condition: ModelEmbeddedContentBlockConditionInput
) {
  updateEmbeddedContentBlock(input: $input, condition: $condition) {
    id
    type
    milestoneID
    title
    description
    resourceLink
    isFormLinked
    orderNo
    parentEmbeddedContentBlockID
    isCompleted
    isArchived
    updatedInChild
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateEmbeddedContentBlockMutationVariables,
  APITypes.UpdateEmbeddedContentBlockMutation
>;
export const deleteEmbeddedContentBlock = /* GraphQL */ `mutation DeleteEmbeddedContentBlock(
  $input: DeleteEmbeddedContentBlockInput!
  $condition: ModelEmbeddedContentBlockConditionInput
) {
  deleteEmbeddedContentBlock(input: $input, condition: $condition) {
    id
    type
    milestoneID
    title
    description
    resourceLink
    isFormLinked
    orderNo
    parentEmbeddedContentBlockID
    isCompleted
    isArchived
    updatedInChild
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteEmbeddedContentBlockMutationVariables,
  APITypes.DeleteEmbeddedContentBlockMutation
>;
export const createFreeTextQuestionBlock = /* GraphQL */ `mutation CreateFreeTextQuestionBlock(
  $input: CreateFreeTextQuestionBlockInput!
  $condition: ModelFreeTextQuestionBlockConditionInput
) {
  createFreeTextQuestionBlock(input: $input, condition: $condition) {
    id
    type
    milestoneID
    title
    description
    text
    orderNo
    parentFreeTextQuestionBlockID
    isCompleted
    isArchived
    updatedInChild
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFreeTextQuestionBlockMutationVariables,
  APITypes.CreateFreeTextQuestionBlockMutation
>;
export const updateFreeTextQuestionBlock = /* GraphQL */ `mutation UpdateFreeTextQuestionBlock(
  $input: UpdateFreeTextQuestionBlockInput!
  $condition: ModelFreeTextQuestionBlockConditionInput
) {
  updateFreeTextQuestionBlock(input: $input, condition: $condition) {
    id
    type
    milestoneID
    title
    description
    text
    orderNo
    parentFreeTextQuestionBlockID
    isCompleted
    isArchived
    updatedInChild
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFreeTextQuestionBlockMutationVariables,
  APITypes.UpdateFreeTextQuestionBlockMutation
>;
export const deleteFreeTextQuestionBlock = /* GraphQL */ `mutation DeleteFreeTextQuestionBlock(
  $input: DeleteFreeTextQuestionBlockInput!
  $condition: ModelFreeTextQuestionBlockConditionInput
) {
  deleteFreeTextQuestionBlock(input: $input, condition: $condition) {
    id
    type
    milestoneID
    title
    description
    text
    orderNo
    parentFreeTextQuestionBlockID
    isCompleted
    isArchived
    updatedInChild
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFreeTextQuestionBlockMutationVariables,
  APITypes.DeleteFreeTextQuestionBlockMutation
>;
export const createNoteBlock = /* GraphQL */ `mutation CreateNoteBlock(
  $input: CreateNoteBlockInput!
  $condition: ModelNoteBlockConditionInput
) {
  createNoteBlock(input: $input, condition: $condition) {
    id
    type
    milestoneID
    title
    description
    text
    orderNo
    parentNoteBlockID
    isCompleted
    isPrivate
    isArchived
    updatedInChild
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateNoteBlockMutationVariables,
  APITypes.CreateNoteBlockMutation
>;
export const updateNoteBlock = /* GraphQL */ `mutation UpdateNoteBlock(
  $input: UpdateNoteBlockInput!
  $condition: ModelNoteBlockConditionInput
) {
  updateNoteBlock(input: $input, condition: $condition) {
    id
    type
    milestoneID
    title
    description
    text
    orderNo
    parentNoteBlockID
    isCompleted
    isPrivate
    isArchived
    updatedInChild
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateNoteBlockMutationVariables,
  APITypes.UpdateNoteBlockMutation
>;
export const deleteNoteBlock = /* GraphQL */ `mutation DeleteNoteBlock(
  $input: DeleteNoteBlockInput!
  $condition: ModelNoteBlockConditionInput
) {
  deleteNoteBlock(input: $input, condition: $condition) {
    id
    type
    milestoneID
    title
    description
    text
    orderNo
    parentNoteBlockID
    isCompleted
    isPrivate
    isArchived
    updatedInChild
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteNoteBlockMutationVariables,
  APITypes.DeleteNoteBlockMutation
>;
export const createTaskBlock = /* GraphQL */ `mutation CreateTaskBlock(
  $input: CreateTaskBlockInput!
  $condition: ModelTaskBlockConditionInput
) {
  createTaskBlock(input: $input, condition: $condition) {
    id
    type
    milestoneID
    title
    description
    status
    orderNo
    parentTaskBlockID
    isCompleted
    isArchived
    updatedInChild
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTaskBlockMutationVariables,
  APITypes.CreateTaskBlockMutation
>;
export const updateTaskBlock = /* GraphQL */ `mutation UpdateTaskBlock(
  $input: UpdateTaskBlockInput!
  $condition: ModelTaskBlockConditionInput
) {
  updateTaskBlock(input: $input, condition: $condition) {
    id
    type
    milestoneID
    title
    description
    status
    orderNo
    parentTaskBlockID
    isCompleted
    isArchived
    updatedInChild
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTaskBlockMutationVariables,
  APITypes.UpdateTaskBlockMutation
>;
export const deleteTaskBlock = /* GraphQL */ `mutation DeleteTaskBlock(
  $input: DeleteTaskBlockInput!
  $condition: ModelTaskBlockConditionInput
) {
  deleteTaskBlock(input: $input, condition: $condition) {
    id
    type
    milestoneID
    title
    description
    status
    orderNo
    parentTaskBlockID
    isCompleted
    isArchived
    updatedInChild
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTaskBlockMutationVariables,
  APITypes.DeleteTaskBlockMutation
>;
export const createStandardContentBlock = /* GraphQL */ `mutation CreateStandardContentBlock(
  $input: CreateStandardContentBlockInput!
  $condition: ModelStandardContentBlockConditionInput
) {
  createStandardContentBlock(input: $input, condition: $condition) {
    id
    type
    milestoneID
    title
    description
    orderNo
    parentStandardContentBlockID
    isCompleted
    isArchived
    updatedInChild
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateStandardContentBlockMutationVariables,
  APITypes.CreateStandardContentBlockMutation
>;
export const updateStandardContentBlock = /* GraphQL */ `mutation UpdateStandardContentBlock(
  $input: UpdateStandardContentBlockInput!
  $condition: ModelStandardContentBlockConditionInput
) {
  updateStandardContentBlock(input: $input, condition: $condition) {
    id
    type
    milestoneID
    title
    description
    orderNo
    parentStandardContentBlockID
    isCompleted
    isArchived
    updatedInChild
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateStandardContentBlockMutationVariables,
  APITypes.UpdateStandardContentBlockMutation
>;
export const deleteStandardContentBlock = /* GraphQL */ `mutation DeleteStandardContentBlock(
  $input: DeleteStandardContentBlockInput!
  $condition: ModelStandardContentBlockConditionInput
) {
  deleteStandardContentBlock(input: $input, condition: $condition) {
    id
    type
    milestoneID
    title
    description
    orderNo
    parentStandardContentBlockID
    isCompleted
    isArchived
    updatedInChild
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteStandardContentBlockMutationVariables,
  APITypes.DeleteStandardContentBlockMutation
>;
export const createMultipleChoiceQuestionBlock = /* GraphQL */ `mutation CreateMultipleChoiceQuestionBlock(
  $input: CreateMultipleChoiceQuestionBlockInput!
  $condition: ModelMultipleChoiceQuestionBlockConditionInput
) {
  createMultipleChoiceQuestionBlock(input: $input, condition: $condition) {
    id
    type
    milestoneID
    title
    description
    option
    answer
    orderNo
    parentMultipleChoiceQuestionBlockID
    isCompleted
    isRequired
    isArchived
    updatedInChild
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMultipleChoiceQuestionBlockMutationVariables,
  APITypes.CreateMultipleChoiceQuestionBlockMutation
>;
export const updateMultipleChoiceQuestionBlock = /* GraphQL */ `mutation UpdateMultipleChoiceQuestionBlock(
  $input: UpdateMultipleChoiceQuestionBlockInput!
  $condition: ModelMultipleChoiceQuestionBlockConditionInput
) {
  updateMultipleChoiceQuestionBlock(input: $input, condition: $condition) {
    id
    type
    milestoneID
    title
    description
    option
    answer
    orderNo
    parentMultipleChoiceQuestionBlockID
    isCompleted
    isRequired
    isArchived
    updatedInChild
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMultipleChoiceQuestionBlockMutationVariables,
  APITypes.UpdateMultipleChoiceQuestionBlockMutation
>;
export const deleteMultipleChoiceQuestionBlock = /* GraphQL */ `mutation DeleteMultipleChoiceQuestionBlock(
  $input: DeleteMultipleChoiceQuestionBlockInput!
  $condition: ModelMultipleChoiceQuestionBlockConditionInput
) {
  deleteMultipleChoiceQuestionBlock(input: $input, condition: $condition) {
    id
    type
    milestoneID
    title
    description
    option
    answer
    orderNo
    parentMultipleChoiceQuestionBlockID
    isCompleted
    isRequired
    isArchived
    updatedInChild
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMultipleChoiceQuestionBlockMutationVariables,
  APITypes.DeleteMultipleChoiceQuestionBlockMutation
>;
export const createEvent = /* GraphQL */ `mutation CreateEvent(
  $input: CreateEventInput!
  $condition: ModelEventConditionInput
) {
  createEvent(input: $input, condition: $condition) {
    id
    userId
    formId
    formInstanceId
    daysFrom
    daysFromDueDate
    type
    role
    emailTo
    title
    message
    sendTime
    sendTimeDate
    dueDate
    status
    channelId
    parentId
    channel
    cc
    archived
    updatedInChild
    journeyID
    kinfolkTemplateID
    reminders {
      nextToken
      __typename
    }
    meta
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateEventMutationVariables,
  APITypes.CreateEventMutation
>;
export const updateEvent = /* GraphQL */ `mutation UpdateEvent(
  $input: UpdateEventInput!
  $condition: ModelEventConditionInput
) {
  updateEvent(input: $input, condition: $condition) {
    id
    userId
    formId
    formInstanceId
    daysFrom
    daysFromDueDate
    type
    role
    emailTo
    title
    message
    sendTime
    sendTimeDate
    dueDate
    status
    channelId
    parentId
    channel
    cc
    archived
    updatedInChild
    journeyID
    kinfolkTemplateID
    reminders {
      nextToken
      __typename
    }
    meta
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateEventMutationVariables,
  APITypes.UpdateEventMutation
>;
export const deleteEvent = /* GraphQL */ `mutation DeleteEvent(
  $input: DeleteEventInput!
  $condition: ModelEventConditionInput
) {
  deleteEvent(input: $input, condition: $condition) {
    id
    userId
    formId
    formInstanceId
    daysFrom
    daysFromDueDate
    type
    role
    emailTo
    title
    message
    sendTime
    sendTimeDate
    dueDate
    status
    channelId
    parentId
    channel
    cc
    archived
    updatedInChild
    journeyID
    kinfolkTemplateID
    reminders {
      nextToken
      __typename
    }
    meta
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteEventMutationVariables,
  APITypes.DeleteEventMutation
>;
export const createEventReminder = /* GraphQL */ `mutation CreateEventReminder(
  $input: CreateEventReminderInput!
  $condition: ModelEventReminderConditionInput
) {
  createEventReminder(input: $input, condition: $condition) {
    id
    eventId
    sendTimeDate
    daysFrom
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateEventReminderMutationVariables,
  APITypes.CreateEventReminderMutation
>;
export const updateEventReminder = /* GraphQL */ `mutation UpdateEventReminder(
  $input: UpdateEventReminderInput!
  $condition: ModelEventReminderConditionInput
) {
  updateEventReminder(input: $input, condition: $condition) {
    id
    eventId
    sendTimeDate
    daysFrom
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateEventReminderMutationVariables,
  APITypes.UpdateEventReminderMutation
>;
export const deleteEventReminder = /* GraphQL */ `mutation DeleteEventReminder(
  $input: DeleteEventReminderInput!
  $condition: ModelEventReminderConditionInput
) {
  deleteEventReminder(input: $input, condition: $condition) {
    id
    eventId
    sendTimeDate
    daysFrom
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteEventReminderMutationVariables,
  APITypes.DeleteEventReminderMutation
>;
export const createMeeting = /* GraphQL */ `mutation CreateMeeting(
  $input: CreateMeetingInput!
  $condition: ModelMeetingConditionInput
) {
  createMeeting(input: $input, condition: $condition) {
    id
    kinfolkTemplateID
    journeyID
    organizedByUserID
    status
    title
    description
    attendeesEmail
    startTime
    endTime
    parentMeetingID
    updatedInChild
    eventDate
    duration
    link
    assigneeRole
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMeetingMutationVariables,
  APITypes.CreateMeetingMutation
>;
export const updateMeeting = /* GraphQL */ `mutation UpdateMeeting(
  $input: UpdateMeetingInput!
  $condition: ModelMeetingConditionInput
) {
  updateMeeting(input: $input, condition: $condition) {
    id
    kinfolkTemplateID
    journeyID
    organizedByUserID
    status
    title
    description
    attendeesEmail
    startTime
    endTime
    parentMeetingID
    updatedInChild
    eventDate
    duration
    link
    assigneeRole
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMeetingMutationVariables,
  APITypes.UpdateMeetingMutation
>;
export const deleteMeeting = /* GraphQL */ `mutation DeleteMeeting(
  $input: DeleteMeetingInput!
  $condition: ModelMeetingConditionInput
) {
  deleteMeeting(input: $input, condition: $condition) {
    id
    kinfolkTemplateID
    journeyID
    organizedByUserID
    status
    title
    description
    attendeesEmail
    startTime
    endTime
    parentMeetingID
    updatedInChild
    eventDate
    duration
    link
    assigneeRole
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMeetingMutationVariables,
  APITypes.DeleteMeetingMutation
>;
export const createSecretKeyManager = /* GraphQL */ `mutation CreateSecretKeyManager(
  $input: CreateSecretKeyManagerInput!
  $condition: ModelSecretKeyManagerConditionInput
) {
  createSecretKeyManager(input: $input, condition: $condition) {
    id
    organizationID
    clientEmail
    privateKey
    adminEmail
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSecretKeyManagerMutationVariables,
  APITypes.CreateSecretKeyManagerMutation
>;
export const updateSecretKeyManager = /* GraphQL */ `mutation UpdateSecretKeyManager(
  $input: UpdateSecretKeyManagerInput!
  $condition: ModelSecretKeyManagerConditionInput
) {
  updateSecretKeyManager(input: $input, condition: $condition) {
    id
    organizationID
    clientEmail
    privateKey
    adminEmail
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSecretKeyManagerMutationVariables,
  APITypes.UpdateSecretKeyManagerMutation
>;
export const deleteSecretKeyManager = /* GraphQL */ `mutation DeleteSecretKeyManager(
  $input: DeleteSecretKeyManagerInput!
  $condition: ModelSecretKeyManagerConditionInput
) {
  deleteSecretKeyManager(input: $input, condition: $condition) {
    id
    organizationID
    clientEmail
    privateKey
    adminEmail
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSecretKeyManagerMutationVariables,
  APITypes.DeleteSecretKeyManagerMutation
>;
export const createPreboardingUser = /* GraphQL */ `mutation CreatePreboardingUser(
  $input: CreatePreboardingUserInput!
  $condition: ModelPreboardingUserConditionInput
) {
  createPreboardingUser(input: $input, condition: $condition) {
    id
    userId
    isSubmitted
    consent
    journeyId
    personalEmail
    photoUrl
    bankName
    bankBranch
    bankSortCode
    bankAccountNumber
    bankAccountName
    buildingSocietyRoll
    street1
    street2
    city
    state
    zip
    country
    birthday
    gender
    title
    nationality
    mobileNumber
    niNumber
    laptopPreference
    secondMonitor
    corporateCard
    corporatePhone
    healthCare
    dental
    optical
    jobDeclaration
    expatDeclaration
    studentLoanRepayment
    studentLoanType
    pensionScheme
    pensionContribution
    passportNumber
    passportIssueDate
    passportExpiryDate
    passportIssuingCountry
    confirmation
    pensionSchemeOpt
    emergencyContactName
    emergencyContactRelationship
    emergencyContactPhone
    emergencyContactEmailAddress
    emergencyContactStreet1
    emergencyContactStreet2
    emergencyContactCity
    emergencyContactState
    emergencyContactZip
    emergencyContactCountry
    PreboardingUserRelations {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePreboardingUserMutationVariables,
  APITypes.CreatePreboardingUserMutation
>;
export const updatePreboardingUser = /* GraphQL */ `mutation UpdatePreboardingUser(
  $input: UpdatePreboardingUserInput!
  $condition: ModelPreboardingUserConditionInput
) {
  updatePreboardingUser(input: $input, condition: $condition) {
    id
    userId
    isSubmitted
    consent
    journeyId
    personalEmail
    photoUrl
    bankName
    bankBranch
    bankSortCode
    bankAccountNumber
    bankAccountName
    buildingSocietyRoll
    street1
    street2
    city
    state
    zip
    country
    birthday
    gender
    title
    nationality
    mobileNumber
    niNumber
    laptopPreference
    secondMonitor
    corporateCard
    corporatePhone
    healthCare
    dental
    optical
    jobDeclaration
    expatDeclaration
    studentLoanRepayment
    studentLoanType
    pensionScheme
    pensionContribution
    passportNumber
    passportIssueDate
    passportExpiryDate
    passportIssuingCountry
    confirmation
    pensionSchemeOpt
    emergencyContactName
    emergencyContactRelationship
    emergencyContactPhone
    emergencyContactEmailAddress
    emergencyContactStreet1
    emergencyContactStreet2
    emergencyContactCity
    emergencyContactState
    emergencyContactZip
    emergencyContactCountry
    PreboardingUserRelations {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePreboardingUserMutationVariables,
  APITypes.UpdatePreboardingUserMutation
>;
export const deletePreboardingUser = /* GraphQL */ `mutation DeletePreboardingUser(
  $input: DeletePreboardingUserInput!
  $condition: ModelPreboardingUserConditionInput
) {
  deletePreboardingUser(input: $input, condition: $condition) {
    id
    userId
    isSubmitted
    consent
    journeyId
    personalEmail
    photoUrl
    bankName
    bankBranch
    bankSortCode
    bankAccountNumber
    bankAccountName
    buildingSocietyRoll
    street1
    street2
    city
    state
    zip
    country
    birthday
    gender
    title
    nationality
    mobileNumber
    niNumber
    laptopPreference
    secondMonitor
    corporateCard
    corporatePhone
    healthCare
    dental
    optical
    jobDeclaration
    expatDeclaration
    studentLoanRepayment
    studentLoanType
    pensionScheme
    pensionContribution
    passportNumber
    passportIssueDate
    passportExpiryDate
    passportIssuingCountry
    confirmation
    pensionSchemeOpt
    emergencyContactName
    emergencyContactRelationship
    emergencyContactPhone
    emergencyContactEmailAddress
    emergencyContactStreet1
    emergencyContactStreet2
    emergencyContactCity
    emergencyContactState
    emergencyContactZip
    emergencyContactCountry
    PreboardingUserRelations {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePreboardingUserMutationVariables,
  APITypes.DeletePreboardingUserMutation
>;
export const createPreboardingUserRelation = /* GraphQL */ `mutation CreatePreboardingUserRelation(
  $input: CreatePreboardingUserRelationInput!
  $condition: ModelPreboardingUserRelationConditionInput
) {
  createPreboardingUserRelation(input: $input, condition: $condition) {
    id
    firstName
    lastName
    relationshipToMember
    birthday
    userId
    formId
    healthCare
    dental
    optical
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePreboardingUserRelationMutationVariables,
  APITypes.CreatePreboardingUserRelationMutation
>;
export const updatePreboardingUserRelation = /* GraphQL */ `mutation UpdatePreboardingUserRelation(
  $input: UpdatePreboardingUserRelationInput!
  $condition: ModelPreboardingUserRelationConditionInput
) {
  updatePreboardingUserRelation(input: $input, condition: $condition) {
    id
    firstName
    lastName
    relationshipToMember
    birthday
    userId
    formId
    healthCare
    dental
    optical
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePreboardingUserRelationMutationVariables,
  APITypes.UpdatePreboardingUserRelationMutation
>;
export const deletePreboardingUserRelation = /* GraphQL */ `mutation DeletePreboardingUserRelation(
  $input: DeletePreboardingUserRelationInput!
  $condition: ModelPreboardingUserRelationConditionInput
) {
  deletePreboardingUserRelation(input: $input, condition: $condition) {
    id
    firstName
    lastName
    relationshipToMember
    birthday
    userId
    formId
    healthCare
    dental
    optical
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePreboardingUserRelationMutationVariables,
  APITypes.DeletePreboardingUserRelationMutation
>;
export const createDigest = /* GraphQL */ `mutation CreateDigest(
  $input: CreateDigestInput!
  $condition: ModelDigestConditionInput
) {
  createDigest(input: $input, condition: $condition) {
    id
    type
    organizationId
    userId
    frequency
    channel
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDigestMutationVariables,
  APITypes.CreateDigestMutation
>;
export const updateDigest = /* GraphQL */ `mutation UpdateDigest(
  $input: UpdateDigestInput!
  $condition: ModelDigestConditionInput
) {
  updateDigest(input: $input, condition: $condition) {
    id
    type
    organizationId
    userId
    frequency
    channel
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDigestMutationVariables,
  APITypes.UpdateDigestMutation
>;
export const deleteDigest = /* GraphQL */ `mutation DeleteDigest(
  $input: DeleteDigestInput!
  $condition: ModelDigestConditionInput
) {
  deleteDigest(input: $input, condition: $condition) {
    id
    type
    organizationId
    userId
    frequency
    channel
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDigestMutationVariables,
  APITypes.DeleteDigestMutation
>;
export const createGqlMessage = /* GraphQL */ `mutation CreateGqlMessage(
  $input: CreateGqlMessageInput!
  $condition: ModelGqlMessageConditionInput
) {
  createGqlMessage(input: $input, condition: $condition) {
    id
    type
    organizationId
    recipientId
    payload
    response
    status
    callbackId
    deliveryInfo
    respondedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateGqlMessageMutationVariables,
  APITypes.CreateGqlMessageMutation
>;
export const updateGqlMessage = /* GraphQL */ `mutation UpdateGqlMessage(
  $input: UpdateGqlMessageInput!
  $condition: ModelGqlMessageConditionInput
) {
  updateGqlMessage(input: $input, condition: $condition) {
    id
    type
    organizationId
    recipientId
    payload
    response
    status
    callbackId
    deliveryInfo
    respondedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateGqlMessageMutationVariables,
  APITypes.UpdateGqlMessageMutation
>;
export const deleteGqlMessage = /* GraphQL */ `mutation DeleteGqlMessage(
  $input: DeleteGqlMessageInput!
  $condition: ModelGqlMessageConditionInput
) {
  deleteGqlMessage(input: $input, condition: $condition) {
    id
    type
    organizationId
    recipientId
    payload
    response
    status
    callbackId
    deliveryInfo
    respondedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteGqlMessageMutationVariables,
  APITypes.DeleteGqlMessageMutation
>;
export const createGqlMsTeamsUserReference = /* GraphQL */ `mutation CreateGqlMsTeamsUserReference(
  $input: CreateGqlMsTeamsUserReferenceInput!
  $condition: ModelGqlMsTeamsUserReferenceConditionInput
) {
  createGqlMsTeamsUserReference(input: $input, condition: $condition) {
    tenantId
    userEmail
    teamsUserId
    conversation
    userId
    organizationId
    hasUndeliveredMessages
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateGqlMsTeamsUserReferenceMutationVariables,
  APITypes.CreateGqlMsTeamsUserReferenceMutation
>;
export const updateGqlMsTeamsUserReference = /* GraphQL */ `mutation UpdateGqlMsTeamsUserReference(
  $input: UpdateGqlMsTeamsUserReferenceInput!
  $condition: ModelGqlMsTeamsUserReferenceConditionInput
) {
  updateGqlMsTeamsUserReference(input: $input, condition: $condition) {
    tenantId
    userEmail
    teamsUserId
    conversation
    userId
    organizationId
    hasUndeliveredMessages
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateGqlMsTeamsUserReferenceMutationVariables,
  APITypes.UpdateGqlMsTeamsUserReferenceMutation
>;
export const deleteGqlMsTeamsUserReference = /* GraphQL */ `mutation DeleteGqlMsTeamsUserReference(
  $input: DeleteGqlMsTeamsUserReferenceInput!
  $condition: ModelGqlMsTeamsUserReferenceConditionInput
) {
  deleteGqlMsTeamsUserReference(input: $input, condition: $condition) {
    tenantId
    userEmail
    teamsUserId
    conversation
    userId
    organizationId
    hasUndeliveredMessages
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteGqlMsTeamsUserReferenceMutationVariables,
  APITypes.DeleteGqlMsTeamsUserReferenceMutation
>;
export const createGqlDataStore = /* GraphQL */ `mutation CreateGqlDataStore(
  $input: CreateGqlDataStoreInput!
  $condition: ModelGqlDataStoreConditionInput
) {
  createGqlDataStore(input: $input, condition: $condition) {
    id
    organizationId
    name
    data
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateGqlDataStoreMutationVariables,
  APITypes.CreateGqlDataStoreMutation
>;
export const updateGqlDataStore = /* GraphQL */ `mutation UpdateGqlDataStore(
  $input: UpdateGqlDataStoreInput!
  $condition: ModelGqlDataStoreConditionInput
) {
  updateGqlDataStore(input: $input, condition: $condition) {
    id
    organizationId
    name
    data
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateGqlDataStoreMutationVariables,
  APITypes.UpdateGqlDataStoreMutation
>;
export const deleteGqlDataStore = /* GraphQL */ `mutation DeleteGqlDataStore(
  $input: DeleteGqlDataStoreInput!
  $condition: ModelGqlDataStoreConditionInput
) {
  deleteGqlDataStore(input: $input, condition: $condition) {
    id
    organizationId
    name
    data
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteGqlDataStoreMutationVariables,
  APITypes.DeleteGqlDataStoreMutation
>;
export const createGqlDataStoreRow = /* GraphQL */ `mutation CreateGqlDataStoreRow(
  $input: CreateGqlDataStoreRowInput!
  $condition: ModelGqlDataStoreRowConditionInput
) {
  createGqlDataStoreRow(input: $input, condition: $condition) {
    id
    dataStoreId
    data
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateGqlDataStoreRowMutationVariables,
  APITypes.CreateGqlDataStoreRowMutation
>;
export const updateGqlDataStoreRow = /* GraphQL */ `mutation UpdateGqlDataStoreRow(
  $input: UpdateGqlDataStoreRowInput!
  $condition: ModelGqlDataStoreRowConditionInput
) {
  updateGqlDataStoreRow(input: $input, condition: $condition) {
    id
    dataStoreId
    data
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateGqlDataStoreRowMutationVariables,
  APITypes.UpdateGqlDataStoreRowMutation
>;
export const deleteGqlDataStoreRow = /* GraphQL */ `mutation DeleteGqlDataStoreRow(
  $input: DeleteGqlDataStoreRowInput!
  $condition: ModelGqlDataStoreRowConditionInput
) {
  deleteGqlDataStoreRow(input: $input, condition: $condition) {
    id
    dataStoreId
    data
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteGqlDataStoreRowMutationVariables,
  APITypes.DeleteGqlDataStoreRowMutation
>;
export const createGqlForm = /* GraphQL */ `mutation CreateGqlForm(
  $input: CreateGqlFormInput!
  $condition: ModelGqlFormConditionInput
) {
  createGqlForm(input: $input, condition: $condition) {
    id
    organizationId
    name
    dataStoreId
    data
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateGqlFormMutationVariables,
  APITypes.CreateGqlFormMutation
>;
export const updateGqlForm = /* GraphQL */ `mutation UpdateGqlForm(
  $input: UpdateGqlFormInput!
  $condition: ModelGqlFormConditionInput
) {
  updateGqlForm(input: $input, condition: $condition) {
    id
    organizationId
    name
    dataStoreId
    data
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateGqlFormMutationVariables,
  APITypes.UpdateGqlFormMutation
>;
export const deleteGqlForm = /* GraphQL */ `mutation DeleteGqlForm(
  $input: DeleteGqlFormInput!
  $condition: ModelGqlFormConditionInput
) {
  deleteGqlForm(input: $input, condition: $condition) {
    id
    organizationId
    name
    dataStoreId
    data
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteGqlFormMutationVariables,
  APITypes.DeleteGqlFormMutation
>;
export const createGqlFormInstance = /* GraphQL */ `mutation CreateGqlFormInstance(
  $input: CreateGqlFormInstanceInput!
  $condition: ModelGqlFormInstanceConditionInput
) {
  createGqlFormInstance(input: $input, condition: $condition) {
    id
    organizationId
    formId
    assignedTo
    link
    slug
    data
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateGqlFormInstanceMutationVariables,
  APITypes.CreateGqlFormInstanceMutation
>;
export const updateGqlFormInstance = /* GraphQL */ `mutation UpdateGqlFormInstance(
  $input: UpdateGqlFormInstanceInput!
  $condition: ModelGqlFormInstanceConditionInput
) {
  updateGqlFormInstance(input: $input, condition: $condition) {
    id
    organizationId
    formId
    assignedTo
    link
    slug
    data
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateGqlFormInstanceMutationVariables,
  APITypes.UpdateGqlFormInstanceMutation
>;
export const deleteGqlFormInstance = /* GraphQL */ `mutation DeleteGqlFormInstance(
  $input: DeleteGqlFormInstanceInput!
  $condition: ModelGqlFormInstanceConditionInput
) {
  deleteGqlFormInstance(input: $input, condition: $condition) {
    id
    organizationId
    formId
    assignedTo
    link
    slug
    data
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteGqlFormInstanceMutationVariables,
  APITypes.DeleteGqlFormInstanceMutation
>;
export const createUserShareInfo = /* GraphQL */ `mutation CreateUserShareInfo(
  $input: CreateUserShareInfoInput!
  $condition: ModelUserShareInfoConditionInput
) {
  createUserShareInfo(input: $input, condition: $condition) {
    id
    shareInfoID
    userID
    shareInfo {
      id
      name
      organizationID
      createdAt
      updatedAt
      __typename
    }
    user {
      id
      offset
      firstName
      email
      phone
      sendWelcomeEmail
      personalEmail
      jobTitle
      birthday
      startDate
      homeLocation
      team
      photoUrl
      pronoun
      aboutMe
      mostHappy
      socialMediaUrl
      slackUserId
      isFirstLogin
      isHomeModelViewed
      isProfileModelViewed
      type
      isActive
      lastName
      createdAt
      updatedAt
      userOrganizationId
      userWorkPreferenceId
      userWorkStyleId
      userWorkLocationId
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserShareInfoMutationVariables,
  APITypes.CreateUserShareInfoMutation
>;
export const updateUserShareInfo = /* GraphQL */ `mutation UpdateUserShareInfo(
  $input: UpdateUserShareInfoInput!
  $condition: ModelUserShareInfoConditionInput
) {
  updateUserShareInfo(input: $input, condition: $condition) {
    id
    shareInfoID
    userID
    shareInfo {
      id
      name
      organizationID
      createdAt
      updatedAt
      __typename
    }
    user {
      id
      offset
      firstName
      email
      phone
      sendWelcomeEmail
      personalEmail
      jobTitle
      birthday
      startDate
      homeLocation
      team
      photoUrl
      pronoun
      aboutMe
      mostHappy
      socialMediaUrl
      slackUserId
      isFirstLogin
      isHomeModelViewed
      isProfileModelViewed
      type
      isActive
      lastName
      createdAt
      updatedAt
      userOrganizationId
      userWorkPreferenceId
      userWorkStyleId
      userWorkLocationId
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserShareInfoMutationVariables,
  APITypes.UpdateUserShareInfoMutation
>;
export const deleteUserShareInfo = /* GraphQL */ `mutation DeleteUserShareInfo(
  $input: DeleteUserShareInfoInput!
  $condition: ModelUserShareInfoConditionInput
) {
  deleteUserShareInfo(input: $input, condition: $condition) {
    id
    shareInfoID
    userID
    shareInfo {
      id
      name
      organizationID
      createdAt
      updatedAt
      __typename
    }
    user {
      id
      offset
      firstName
      email
      phone
      sendWelcomeEmail
      personalEmail
      jobTitle
      birthday
      startDate
      homeLocation
      team
      photoUrl
      pronoun
      aboutMe
      mostHappy
      socialMediaUrl
      slackUserId
      isFirstLogin
      isHomeModelViewed
      isProfileModelViewed
      type
      isActive
      lastName
      createdAt
      updatedAt
      userOrganizationId
      userWorkPreferenceId
      userWorkStyleId
      userWorkLocationId
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserShareInfoMutationVariables,
  APITypes.DeleteUserShareInfoMutation
>;
export const createLearnMoreInfo = /* GraphQL */ `mutation CreateLearnMoreInfo(
  $input: CreateLearnMoreInfoInput!
  $condition: ModelLearnMoreInfoConditionInput
) {
  createLearnMoreInfo(input: $input, condition: $condition) {
    id
    shareInfoID
    userID
    shareInfo {
      id
      name
      organizationID
      createdAt
      updatedAt
      __typename
    }
    user {
      id
      offset
      firstName
      email
      phone
      sendWelcomeEmail
      personalEmail
      jobTitle
      birthday
      startDate
      homeLocation
      team
      photoUrl
      pronoun
      aboutMe
      mostHappy
      socialMediaUrl
      slackUserId
      isFirstLogin
      isHomeModelViewed
      isProfileModelViewed
      type
      isActive
      lastName
      createdAt
      updatedAt
      userOrganizationId
      userWorkPreferenceId
      userWorkStyleId
      userWorkLocationId
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateLearnMoreInfoMutationVariables,
  APITypes.CreateLearnMoreInfoMutation
>;
export const updateLearnMoreInfo = /* GraphQL */ `mutation UpdateLearnMoreInfo(
  $input: UpdateLearnMoreInfoInput!
  $condition: ModelLearnMoreInfoConditionInput
) {
  updateLearnMoreInfo(input: $input, condition: $condition) {
    id
    shareInfoID
    userID
    shareInfo {
      id
      name
      organizationID
      createdAt
      updatedAt
      __typename
    }
    user {
      id
      offset
      firstName
      email
      phone
      sendWelcomeEmail
      personalEmail
      jobTitle
      birthday
      startDate
      homeLocation
      team
      photoUrl
      pronoun
      aboutMe
      mostHappy
      socialMediaUrl
      slackUserId
      isFirstLogin
      isHomeModelViewed
      isProfileModelViewed
      type
      isActive
      lastName
      createdAt
      updatedAt
      userOrganizationId
      userWorkPreferenceId
      userWorkStyleId
      userWorkLocationId
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateLearnMoreInfoMutationVariables,
  APITypes.UpdateLearnMoreInfoMutation
>;
export const deleteLearnMoreInfo = /* GraphQL */ `mutation DeleteLearnMoreInfo(
  $input: DeleteLearnMoreInfoInput!
  $condition: ModelLearnMoreInfoConditionInput
) {
  deleteLearnMoreInfo(input: $input, condition: $condition) {
    id
    shareInfoID
    userID
    shareInfo {
      id
      name
      organizationID
      createdAt
      updatedAt
      __typename
    }
    user {
      id
      offset
      firstName
      email
      phone
      sendWelcomeEmail
      personalEmail
      jobTitle
      birthday
      startDate
      homeLocation
      team
      photoUrl
      pronoun
      aboutMe
      mostHappy
      socialMediaUrl
      slackUserId
      isFirstLogin
      isHomeModelViewed
      isProfileModelViewed
      type
      isActive
      lastName
      createdAt
      updatedAt
      userOrganizationId
      userWorkPreferenceId
      userWorkStyleId
      userWorkLocationId
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteLearnMoreInfoMutationVariables,
  APITypes.DeleteLearnMoreInfoMutation
>;
export const createUserInterest = /* GraphQL */ `mutation CreateUserInterest(
  $input: CreateUserInterestInput!
  $condition: ModelUserInterestConditionInput
) {
  createUserInterest(input: $input, condition: $condition) {
    id
    interestID
    userID
    interest {
      id
      name
      organizationID
      createdAt
      updatedAt
      __typename
    }
    user {
      id
      offset
      firstName
      email
      phone
      sendWelcomeEmail
      personalEmail
      jobTitle
      birthday
      startDate
      homeLocation
      team
      photoUrl
      pronoun
      aboutMe
      mostHappy
      socialMediaUrl
      slackUserId
      isFirstLogin
      isHomeModelViewed
      isProfileModelViewed
      type
      isActive
      lastName
      createdAt
      updatedAt
      userOrganizationId
      userWorkPreferenceId
      userWorkStyleId
      userWorkLocationId
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserInterestMutationVariables,
  APITypes.CreateUserInterestMutation
>;
export const updateUserInterest = /* GraphQL */ `mutation UpdateUserInterest(
  $input: UpdateUserInterestInput!
  $condition: ModelUserInterestConditionInput
) {
  updateUserInterest(input: $input, condition: $condition) {
    id
    interestID
    userID
    interest {
      id
      name
      organizationID
      createdAt
      updatedAt
      __typename
    }
    user {
      id
      offset
      firstName
      email
      phone
      sendWelcomeEmail
      personalEmail
      jobTitle
      birthday
      startDate
      homeLocation
      team
      photoUrl
      pronoun
      aboutMe
      mostHappy
      socialMediaUrl
      slackUserId
      isFirstLogin
      isHomeModelViewed
      isProfileModelViewed
      type
      isActive
      lastName
      createdAt
      updatedAt
      userOrganizationId
      userWorkPreferenceId
      userWorkStyleId
      userWorkLocationId
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserInterestMutationVariables,
  APITypes.UpdateUserInterestMutation
>;
export const deleteUserInterest = /* GraphQL */ `mutation DeleteUserInterest(
  $input: DeleteUserInterestInput!
  $condition: ModelUserInterestConditionInput
) {
  deleteUserInterest(input: $input, condition: $condition) {
    id
    interestID
    userID
    interest {
      id
      name
      organizationID
      createdAt
      updatedAt
      __typename
    }
    user {
      id
      offset
      firstName
      email
      phone
      sendWelcomeEmail
      personalEmail
      jobTitle
      birthday
      startDate
      homeLocation
      team
      photoUrl
      pronoun
      aboutMe
      mostHappy
      socialMediaUrl
      slackUserId
      isFirstLogin
      isHomeModelViewed
      isProfileModelViewed
      type
      isActive
      lastName
      createdAt
      updatedAt
      userOrganizationId
      userWorkPreferenceId
      userWorkStyleId
      userWorkLocationId
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserInterestMutationVariables,
  APITypes.DeleteUserInterestMutation
>;
