import Slider from 'rc-slider';
import './Slider.scss';
import { type CustomSliderProps } from '@base/models/profile.model';

const CustomSlider = (props: CustomSliderProps) => (
  <div className="custom-slider-wrapper">
    <Slider
      className="mt-2"
      disabled={props.disabled}
      dots
      step={25}
      value={props.value}
      onChange={props.onChange}
      defaultValue={0}
      railStyle={{ background: props.disabled ? '#949499' : '' }}
    />
  </div>
);

export default CustomSlider;
