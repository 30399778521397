import { Modal } from 'react-bootstrap';
import { TextField } from '@Shared/DS/Molecules';
import './CreateTemplateModal.scss';
import { memo } from 'react';
import { type ModalProps } from '@Shared/utils/utils';
import {
  ToggleSwitch,
  CreateTemplateStepOne,
  CreateTemplateStepTwo,
  Button,
} from '@Shared/DS';
import TextArea from '@Shared/Components/TextArea/TextArea';
import { useCreateTemplateModal } from './useCreateTemplateModal';

export const CreateTemplateModal = memo((props: ModalProps) => {
  const {
    templateName,
    isSubmitted,
    showLoader,
    selectedTemplateType,
    description,
    isPublic,
    fileInputRef,
    banner,
    stepNumber,
    currentStep,
    playbookMode,
    setPlaybookMode,
    setStepNumber,
    selectFile,
    setSelectedTemplateType,
    setIsPublic,
    setDescription,
    setTemplateName,
    handleCreateTemplate,
    handleHideModal,
    chooseBanner,
  } = useCreateTemplateModal(props.handleShowModal);

  return (
    <Modal
      size="lg"
      className="template-modal-wrapper"
      fullscreen={'md-down'}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      show={props.showModal}
      onHide={handleHideModal}
    >
      <Modal.Header>
        <div className="step">{`Step ${stepNumber} of 3`}</div>
        <button
          type="button"
          className="btn-close modal-close-btn"
          data-bs-dismiss="modal"
          onClick={handleHideModal}
          aria-label="Close"
        ></button>
      </Modal.Header>
      <Modal.Body>
        <h1 className="heading my-1">{currentStep?.title}</h1>
        <p className="description mb-4">{currentStep?.description}</p>

        {stepNumber === 1 && (
          <CreateTemplateStepOne
            selectedTemplateType={selectedTemplateType}
            setSelectedTemplateType={setSelectedTemplateType}
          />
        )}

        {stepNumber === 2 && (
          <CreateTemplateStepTwo
            playbookMode={playbookMode}
            setPlaybookMode={setPlaybookMode}
          />
        )}

        {stepNumber === 3 && (
          <>
            <div className="image-container text-center">
              <input
                type="file"
                className="mt-2 d-none"
                onChange={(e) => selectFile(e)}
                accept="image/*"
                ref={fileInputRef}
              ></input>
              {banner ? (
                <img src={banner.url} alt="banner" />
              ) : (
                <i className="bi bi-image"></i>
              )}
              <div className="title cursor-pointer" onClick={chooseBanner}>
                Upload template banner
              </div>
            </div>
            {isSubmitted && !banner && (
              <div className="fs-14-bb242b">Banner is required</div>
            )}

            <label className="fs-14-semi-bold col-12 text-start mt-3">
              Name
            </label>
            <TextField
              id={'name'}
              type="text"
              defaultValue={templateName}
              setValue={setTemplateName}
              fieldName="Template name"
              width=""
              placeholder="Enter template name"
              additionalClasses={` 
    ${!templateName && templateName.length === 0 && isSubmitted ? 'is-invalid' : ''}`}
            />

            <label className="fs-14-semi-bold col-12 text-start mt-3">
              Description
            </label>
            <TextArea
              id={'description'}
              type="text"
              defaultValue={description}
              setValue={setDescription}
              fieldName="Description"
              placeholder="Add a description"
            />
            {isSubmitted && !description.length && (
              <div className="fs-14-bb242b">Description shouldn't be empty</div>
            )}

            <div className="col-12 mt-3 d-flex justify-content-between align-items-center">
              <div>
                <label className="fs-14-semi-bold col-12 text-start">
                  Make it public
                </label>
                <div className="public-sub-heading">
                  Template will be visible for all organizations.
                </div>
              </div>
              <ToggleSwitch
                initialValue={isPublic}
                setValue={(value: boolean) => setIsPublic(value)}
              />
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-end">
          <Button
            buttonStyle="outline"
            text="Back"
            disabled={stepNumber === 1}
            onClick={() => setStepNumber((prev) => prev - 1)}
          />
          <Button
            text={stepNumber < 3 ? 'Next' : 'Create template'}
            showLoading={showLoader}
            additionalClasses="ms-2"
            disabled={
              showLoader ||
              (stepNumber === 1 && !selectedTemplateType) ||
              (stepNumber === 2 && !playbookMode)
            }
            onClick={() =>
              stepNumber < 3
                ? setStepNumber((prev) => prev + 1)
                : handleCreateTemplate()
            }
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
});
