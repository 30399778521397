import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import './EmbeddedContentModal.scss';
import ArticleIcon from '@Assets/Images/article-icon.png';
import VideoIcon from '@Assets/Images/video-icon.png';
import DocumentIcon from '@Assets/Images/document-icon.png';
import { ResourceUrlModal } from './ResourceUrlModal/ResourceUrlModal';

interface EmbeddedContentModalProps {
  showModal: boolean;
  handleShowModal: Function;
  addEmbeddedContentBlock: Function;
  assignedUserId?: string | null;
}

export const EmbeddedContentModal = (props: EmbeddedContentModalProps) => {
  const embeddedContentTypes = [
    {
      type: 'Document',
      icon: DocumentIcon,
    },
    {
      type: 'Form',
      icon: ArticleIcon,
    },
    {
      type: 'Media',
      icon: VideoIcon,
    },
    {
      type: 'Website',
      icon: ArticleIcon,
    },
  ];

  interface State {
    showVideoUrlModal: boolean;
    embedType: string;
  }
  const [state, setState] = useState<State>({
    showVideoUrlModal: false,
    embedType: 'Article',
  });

  const handleShowModal = (embedType: string) => {
    props.handleShowModal(false);
    setState({ ...state, showVideoUrlModal: true, embedType });
  };

  return (
    <>
      <Modal
        size="lg"
        fullscreen={'md-down'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        show={props.showModal}
        onHide={() => props.handleShowModal(false)}
        className="embedded-content-modal-wrapper"
      >
        <Modal.Header className="px-5">
          <Modal.Title className="fs-24-semi-bold-4C5564">
            Add embedded content
          </Modal.Title>
          <button
            type="button"
            className="btn-close modal-close-btn"
            data-bs-dismiss="modal"
            onClick={() => props.handleShowModal(false)}
            aria-label="Close"
          ></button>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-between px-4">
            {embeddedContentTypes.map((embeddedContentType, index) => (
              <div
                key={index}
                className="d-flex mt-3 justify-content-center align-items-center embedded-content-card cursor-pointer"
                onClick={() => handleShowModal(embeddedContentType.type)}
              >
                <div className="text-center">
                  {embeddedContentType.type === 'Form' ? (
                    <i className="bi bi-ui-checks-grid"></i>
                  ) : (
                    <img src={embeddedContentType.icon} alt="icon"></img>
                  )}
                  <div className="mt-1">{embeddedContentType.type}</div>
                </div>
              </div>
            ))}
          </div>
        </Modal.Body>
      </Modal>
      <ResourceUrlModal
        title={'Add embedded resource'}
        actionType={'Add'}
        showModal={state.showVideoUrlModal}
        handleShowModal={(show: boolean) =>
          setState({ ...state, showVideoUrlModal: show, embedType: 'Article' })
        }
        addEmbeddedContentBlock={props.addEmbeddedContentBlock}
        embedType={state.embedType}
        assignedUserId={props.assignedUserId}
      />
    </>
  );
};
