import { type ChangeEvent, type FormEvent, useEffect, useState } from 'react';
import { type User, type UserType } from '@API';
import {
  createNewCognitoUserAndDeleteOldOne,
  getOrgUserCustomFields,
  getUserById,
  recalculateStartDate,
  updateUser,
} from '@api/apis';
import { type Id, toast } from 'react-toastify';
import { useQuery } from '@tanstack/react-query';
import { Keys } from '@base/keys/queryKeys';
import {
  type CustomFieldColumn,
  type CustomFields,
  type UserUpdateRequest,
} from '@base/models/common.model';
import { sanitizeString } from '@Shared/utils/string';
import { emptyState } from './UpdateMemberModaL.const';

interface State {
  firstName: string;
  lastName: string;
  phone?: string;
  email: string;
  personalEmail?: string;
  jobTitle?: string;
  startDate?: string;
  customFields: CustomFields;
  type: UserType;
  isSaving: boolean;
  isSubmitted: boolean;
}

export const useUpdateMemberModal = (
  userId: string,
  orgId: string,
  onSave: () => void,
) => {
  const [state, setState] = useState<State>(emptyState);

  const { data: userData, isLoading } = useQuery({
    queryKey: Keys.getUser(userId),
    queryFn: () => getUserById(orgId, userId),
    enabled: !!(userId && orgId),
  });

  const { data: customFieldsData } = useQuery({
    queryKey: Keys.getOrgUserCustomFields(orgId),
    queryFn: () => getOrgUserCustomFields(orgId),
    staleTime: 600000,
    enabled: !!orgId,
  });

  const handleInputChange = (
    value: State[keyof State],
    fieldName: keyof State,
  ) => {
    setState({
      ...state,
      [fieldName]: value,
    });
  };

  const handleCustomFieldChange = (
    e: ChangeEvent<HTMLSelectElement>,
    fieldRefId: string,
  ) => {
    if (!customFieldsData) return;

    const selectedItem = customFieldsData.dataStore.columns
      .find((field) => field.refId === fieldRefId)
      ?.items.find((item) => item.label === e.target.value);

    if (selectedItem) {
      setState({
        ...state,
        customFields: {
          ...state.customFields,
          [fieldRefId]: {
            setItemId: selectedItem.id,
            label: selectedItem.label,
          },
        },
      });
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setState({ ...state, isSubmitted: true, isSaving: true });
    const request: UserUpdateRequest = {
      updateParams: {
        firstName: state.firstName,
        lastName: state.lastName,
        email: state.email,
        id: userId,
        phone: sanitizeString(state.phone),
        personalEmail: sanitizeString(state.personalEmail),
        startDate: sanitizeString(state.startDate),
        jobTitle: sanitizeString(state.jobTitle),
        type: state.type,
      } as User,
      customFields: Object.fromEntries(
        Object.entries(state.customFields).map(([key, value]) => [
          key,
          { setItemId: value.setItemId },
        ]),
      ),
    };

    const id = await updateUser(orgId, request);
    if (id) {
      setState({ ...state, isSaving: false });
      onSave();
    }

    let sideEffects: any = [];
    let toastId: Id;
    if (userData) {
      const { user } = userData;
      if (user.email !== state.email && id) {
        sideEffects = [
          ...sideEffects,
          createNewCognitoUserAndDeleteOldOne(state.email, user.email),
        ];
      }

      if (
        user.startDate &&
        user.startDate !== state.startDate &&
        state.startDate &&
        id
      ) {
        sideEffects = [
          ...sideEffects,
          recalculateStartDate(
            user.id,
            new Date(state.startDate).toISOString().split('T')[0],
          ),
        ];
        toastId = toast.loading('Please wait...');
      }
    }

    Promise.all(sideEffects).then(() => {
      toast.update(toastId, {
        render: 'Hooray! user details updated.',
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });
    });
  };

  const mapCustomFields = (
    customFields: CustomFields,
    columns: CustomFieldColumn[],
  ): CustomFields => {
    let fields = {};
    columns.forEach((column) => {
      const itemId = customFields[column.id]?.setItemId;
      const item = column.items.find((item) => item.id === itemId);
      if (item) {
        fields = {
          ...fields,
          [column.refId]: { ...item, setItemId: item?.id },
        };
      }
    });

    return fields;
  };

  useEffect(() => {
    if (userData && customFieldsData) {
      const { user, customFields } = userData;
      const { columns } = customFieldsData.dataStore;
      if (!user || !customFields) {
        return;
      }

      setState({
        firstName: user.firstName ?? '',
        lastName: user.lastName ?? '',
        email: user.email,
        type: user.type,
        phone: user.phone ?? undefined,
        personalEmail: user.personalEmail ?? undefined,
        startDate: user.startDate?.split('T')[0] ?? undefined,
        jobTitle: user.jobTitle ?? undefined,
        isSaving: false,
        isSubmitted: false,
        customFields: mapCustomFields(customFields, columns),
      });
    }
  }, [userData, customFieldsData]);

  return {
    state,
    customFieldsData,
    isLoading,
    handleInputChange,
    handleCustomFieldChange,
    handleSubmit,
  };
};
