import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  BlockType,
  type KinfolkTemplate,
  type Milestone,
  TemplateStatus,
  UserType,
} from '@API';
import {
  deleteJourneyMilestone,
  deleteMilestoneBlocks,
  fetchKinfolkTemplate,
  updateBlocksOrderNo,
  updateKinfolkTemplate,
  updateMilestoneOrder,
} from '@api/apis';
import {
  addNotificationToApp,
  compareMilestones,
  handleAddEmbeddedContentBlock,
  handleBlocksUpdate,
  handleDeleteBlocks,
  handleMilestoneUpdate,
  handleNewBlock,
  updateMilestoneOrderNoAfterDelete,
  updateMilestoneOrderNumber,
  BuilderTabs,
} from '@Shared/utils/utils';
import { useNonInitialEffect } from '@Hooks/useNonInitialEffect';
import { useQueryClient } from '@tanstack/react-query';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { handleGetMilestones, handleNewMilestone } from './helper';

export const useTemplateBuilder = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showBlockBuilder, setShowBlockBuilder] = useState(false);
  const { id } = useParams();
  const [currentTemplate, setCurrentTemplate] = useState({} as KinfolkTemplate);
  const [selectedMilestoneIndex, setSelectedMilestoneIndex] = useState(0);
  const [milestones, setMilestones] = useState<Milestone[]>([]);
  const [showEmbeddedContentModal, setShowEmbeddedContentModal] =
    useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showSaved, setShowSaved] = useState(false);
  const [isTemplateNameChanged, setIsTemplateNameChanged] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [selectedTab, setSelectedTab] = useState(BuilderTabs.JOURNEY);
  const [showCollaboratorModal, setShowCollaboratorModal] = useState(false);
  const [isTemplateLoading, setIsTemplateLoading] = useState(false);

  const { user } = useUserContext();
  const queryClient = useQueryClient();
  const cachedTemplates = queryClient.getQueryData<KinfolkTemplate[]>([
    'allOrganizationTemplates',
  ]);

  const addNewBlock = async (blockType: string) => {
    setShowBlockBuilder(false);
    if (blockType === BlockType.EmbeddedContentBlock) {
      setShowEmbeddedContentModal(true);
    } else {
      const updatedMilestone = await handleNewBlock(
        blockType,
        milestones,
        selectedMilestoneIndex,
      );
      if (updatedMilestone) {
        setMilestones([...updatedMilestone]);
      }
    }
  };

  const handleTemplateName = async (templateName: string) => {
    setIsTemplateNameChanged(true);
    currentTemplate.name = templateName;
    setCurrentTemplate(currentTemplate);
    if (cachedTemplates) {
      const index = cachedTemplates.findIndex((template) => template.id === id);
      if (index >= 0) {
        cachedTemplates[index].name = templateName;
        queryClient.setQueryData(['allOrganizationTemplates'], cachedTemplates);
      }
    }
  };

  const addNewMilestone = async () => {
    const updateMilestone = await handleNewMilestone(milestones, id || '');
    if (updateMilestone) {
      setMilestones([...updateMilestone]);
      setSelectedMilestoneIndex(updateMilestone.length - 1);
    }
  };

  const addEmbeddedContentBlock = async (url: string, isFormLinked = false) => {
    const updateMilestone = await handleAddEmbeddedContentBlock(
      url,
      milestones,
      selectedMilestoneIndex,
      isFormLinked,
    );
    setMilestones([...updateMilestone]);
  };

  const handleMilestoneTextChange = async (text: string) => {
    milestones[selectedMilestoneIndex].name = text;
    setMilestones([...milestones]);
    setShowLoader(true);
    await handleMilestoneUpdate(milestones[selectedMilestoneIndex]);
    setShowLoader(false);
    setShowSaved(true);
  };

  const removeMilestone = async () => {
    if (!milestones.length) {
      return;
    }

    setShowLoader(true);
    const [id] = await Promise.all([
      deleteJourneyMilestone(milestones[selectedMilestoneIndex].id),
      updateMilestoneOrderNoAfterDelete(
        milestones,
        milestones[selectedMilestoneIndex].orderNo,
      ),
    ]);

    if (id) {
      const updatedMilestone = milestones.filter(
        (milestone) => milestone.id !== id,
      );
      if (selectedMilestoneIndex === 0) {
        setSelectedMilestoneIndex(selectedMilestoneIndex);
      } else {
        setSelectedMilestoneIndex(selectedMilestoneIndex - 1);
      }
      setMilestones([...updatedMilestone]);
      deleteMilestoneBlocks(id);
      addNotificationToApp('Milestone deleted successfully', 'success');
    }

    setShowLoader(false);
    setShowSaved(true);
  };

  const handleBack = () => {
    navigate(`/template/${id}`);
  };

  const handlePublishTemplate = async () => {
    if (id) {
      const templateId = await updateKinfolkTemplate({
        ...currentTemplate,
        status: TemplateStatus.PUBLISHED,
      });
      if (templateId) {
        if (cachedTemplates) {
          const index = cachedTemplates.findIndex(
            (template) => template.id === templateId,
          );
          if (index >= 0) {
            cachedTemplates[index].status = TemplateStatus.PUBLISHED;
            queryClient.setQueryData(
              ['allOrganizationTemplates'],
              cachedTemplates,
            );
          }
        }
        addNotificationToApp('Template published successfully', 'success');
        navigate('/templates');
      }
    }
  };

  const handleUpdateMilestoneBlocks = (block: any) => {
    const updatedMilestone = handleBlocksUpdate(
      milestones,
      selectedMilestoneIndex,
      block,
    );
    setMilestones([...updatedMilestone]);
  };

  const updateDeletedBlock = async (
    id: string,
    type: BlockType,
    orderNo: number,
  ) => {
    const updatedMilestone = handleDeleteBlocks(
      milestones[selectedMilestoneIndex],
      id,
      type,
      orderNo,
    );
    milestones[selectedMilestoneIndex] = updatedMilestone;
    setMilestones([...milestones]);
    setIsBusy(true);
    await updateBlocksOrderNo(updatedMilestone, orderNo);
    setIsBusy(false);
  };

  const handleDraftTemplate = async () => {
    if (id) {
      setShowLoader(true);
      const templateId = await updateKinfolkTemplate({
        ...currentTemplate,
        status: TemplateStatus.DRAFT,
      });
      setShowLoader(false);
      setShowSaved(true);
      if (templateId) {
        if (cachedTemplates) {
          const index = cachedTemplates.findIndex(
            (template) => template.id === templateId,
          );
          if (index >= 0) {
            cachedTemplates[index].status = TemplateStatus.DRAFT;
            queryClient.setQueryData(
              ['allOrganizationTemplates'],
              cachedTemplates,
            );
          }
        }
      }
      setShowEditModal(false);
    }
  };

  const getTemplate = useCallback(
    async (id: string, organizationId: string) => {
      setIsTemplateLoading(true);
      const [template, allMilestones] = await Promise.all([
        fetchKinfolkTemplate(id),
        handleGetMilestones(milestones, id),
      ]);
      if (template && allMilestones) {
        setMilestones([...allMilestones].sort(compareMilestones));
        setCurrentTemplate(template);
      } else {
        navigate('/');
      }
      setIsTemplateLoading(false);
    },
    [milestones, navigate],
  );

  const viewTemplate = () => {
    navigate(`/template/${id}/microsite/preview`);
  };

  const exitPreview = () => {
    navigate(`/template/${id}/microsite`);
  };

  const handleReorderMilestones = async (
    source: number,
    destination: number,
    draggedMilestone: Milestone,
  ) => {
    const updatedMilestones = updateMilestoneOrderNumber(
      source,
      destination,
      draggedMilestone,
      milestones,
    );
    setMilestones([...updatedMilestones].sort(compareMilestones));
    let milestonesToBeUpdated;
    if (source < destination) {
      milestonesToBeUpdated = updatedMilestones.slice(source, destination + 1);
    } else {
      milestonesToBeUpdated = updatedMilestones.slice(destination, source + 1);
    }

    setIsBusy(true);
    await Promise.all(
      milestonesToBeUpdated.map((milestone) =>
        updateMilestoneOrder(milestone.id, milestone.orderNo),
      ),
    );
    setIsBusy(false);
  };

  useNonInitialEffect(() => {
    const timeId = setTimeout(() => {
      setShowSaved(false);
    }, 3000);

    return () => {
      clearTimeout(timeId);
    };
  }, [showSaved]);

  useNonInitialEffect(() => {
    if (isTemplateNameChanged) {
      const timeOut = setTimeout(async () => {
        setShowLoader(true);
        if (id) {
          await updateKinfolkTemplate({
            ...currentTemplate,
            name: currentTemplate.name,
          });
          setShowLoader(false);
          setShowSaved(true);
          setIsTemplateNameChanged(false);
        }
      }, 2000);
      return () => clearTimeout(timeOut);
    }
  }, [currentTemplate.name]);

  useEffect(() => {
    id && user.userOrganizationId && getTemplate(id, user.userOrganizationId);
  }, [id, user, getTemplate]);

  useEffect(() => {
    setIsEditable(
      user.type === UserType.SUPER_ADMIN &&
        !location.pathname.includes('/preview'),
    );
  }, [user, location]);

  return {
    user,
    isTemplateLoading,
    currentTemplate,
    isEditable,
    showBlockBuilder,
    milestones,
    selectedMilestoneIndex,
    showEmbeddedContentModal,
    showEditModal,
    showLoader,
    showSaved,
    isBusy,
    selectedTab,
    id,
    showCollaboratorModal,
    setSelectedTab,
    setIsBusy,
    setShowBlockBuilder,
    setSelectedMilestoneIndex,
    addNewBlock,
    handleMilestoneTextChange,
    addNewMilestone,
    removeMilestone,
    handleBack,
    handlePublishTemplate,
    handleUpdateMilestoneBlocks,
    setShowEmbeddedContentModal,
    addEmbeddedContentBlock,
    setShowEditModal,
    handleDraftTemplate,
    setShowLoader,
    setShowSaved,
    updateDeletedBlock,
    handleTemplateName,
    setMilestones,
    handleReorderMilestones,
    setShowCollaboratorModal,
    viewTemplate,
    exitPreview,
  };
};
