import './OnboardingButton.scss';

export default function OnboardingButton(props: any) {
  return (
    <div className="onboarding-button-wrapper">
      <button
        onClick={() => {
          if (props.isProfile) {
            props.handleShowModal(false);
          } else {
            props.handleClick && props.handleClick();
          }
          if (props.handleSelectedCheck) {
            props.handleSelected();
          }
        }}
        disabled={props.isDisabled}
        className={`btn rounded-button fs-16-medium-bold ${props.styling} ${props.theme} ${props.width}`}
      >
        {props.text}
        {props.icon && (
          <span className="ps-2">
            <svg
              id="Caret_Right"
              data-name="Caret Right"
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="12"
              viewBox="0 0 3.9 9"
              fill="white"
            >
              <path
                id="Shape"
                d="M.768.132l3,3a.45.45,0,0,1,0,.636l-3,3a.45.45,0,1,1-.636-.636L2.814,3.45.132.768A.45.45,0,0,1,.768.132Z"
              />
            </svg>
          </span>
        )}
      </button>
    </div>
  );
}
