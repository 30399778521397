import React, { type ReactNode } from 'react';
import { ChevronDown, PlusLg } from 'react-bootstrap-icons';
import Dropdown from 'react-bootstrap/Dropdown';
import styles from './AddElementDropdownToggle.module.scss';

interface AddElementDropdownToggleProps {
  label: ReactNode;
}

export const AddElementDropdownToggle = ({
  label,
}: AddElementDropdownToggleProps) => (
  <Dropdown.Toggle className={styles.dropdownToggle} variant="primary">
    <PlusLg className={styles.plusIcon} />
    {label}
    <ChevronDown className={styles.chevronIcon} />
  </Dropdown.Toggle>
);
