import type { ISvgIcon } from './types';

export const PeopleIcon = ({
  className,
  width = '20',
  height = '20',
}: ISvgIcon) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <rect width="20" height="20" fill="url(#pattern3)" />
    <defs>
      <pattern
        id="pattern3"
        patternContentUnits="objectBoundingBox"
        width="1"
        height="1"
      >
        <use xlinkHref="#image0_576_893" transform="scale(0.015625)" />
      </pattern>
      <image
        id="image0_576_893"
        width="64"
        height="64"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABmJLR0QA/wD/AP+gvaeTAAAEPElEQVR4nO2aS2wWVRSAv9L2L2VjiwmCJYRo5REjO3nG2gVKiKgxWh4pNkgCBMQFMYYFYQcLI1gBjRtXJLIgKO5caNSFCxeK4dEQXkFbJDwDSqAUaMfFOZP5O0zvzPxz5/4tzJdM7p/ec+8593HmnnumUFBQUFDw+FJTJb31wEvAbGAccBr4BRiokj1O2QBcAbzQcw3YTPUWxQlfEgz4d6Ab2AX8Wvb3r6pmXc5sQAZ4C3gzon4pcFNl1jq0ywm1wAVkcG8Z5DpU5jyPmCu8gAzsbIxcDfC3ys7K2yiQN7ALntTyrxg5DzijvyfnZ06Aqwm4o+XEBLLNoTaPBNOQ1b2cQNY/IltytcgxNcB/yMCeMshNUpmbONqdrlzAA47q7wUGufla/gEM5WqR4moCAL7VcpVBpkPL73O2pSq0ILH+A2BuRP3zwD3gPjDFoV1O+RhxhyvACsTnG4HXgF6t21k163KmBLyLrHD4IuQ/D4CVQF2VbMyNduTKO9LAw88JzC/LMUUXwaqfAjYBzwLjy2TGA63A+wQTdRdxkzFNO8HgP0USIXGUgM+0zQCwMDfrcqaExPUesLuC9t3a9iTJJm7U0UkwgFIF7RsQl/EYo67wHWL8xgx9bNY+DlmxyDH/IMa3ZujjOe2j14pFEeSZdbmLbGNbfTVa6msYed4FvgYGLfQzCByw0E8kWXdALbAIiePrgXPAz+SXzKgF2oA5SLR4BvgB6M9Jn5FOoI+Ho7h/ga3Y311LkJxiWN9V4D3LumLZUWbAMWAP8AnwE3KP94CD2JuETuSe4CGR4l7ke8JvZXZss6QrlrcJQtWuiPo2grTWhxb0tSIu5QHbETcoZzUSbQ4SJFRy5Zgas84gs5hge1YSBJXzufa13yCzU2W+yagrlimq6DrxV9ajKpv1Vtej/cwzyDytMjdI+WJP66N+fr8P8UkTp7XMmt9vKtM5EheRk6CJlPeGtBOQJr/vG571iPJ1NhtkGpAr9X3iF2YYaSfgMrLVJidoO03LCyl1ROkEc55wKrL1L5Iym5x2Am4jcXk9MMMg1wg8g6zIuZQ6wvifymYaZGaHZBNTyTn9p5aLDDIvIi/JHrK7gP89ISqTTKjuSEZdiehC3OBHg8w+7GV4/RvhNUY+Uo+rzBIL+mJ5guAz16sR9dMRVxnCvG3TcET1bYmoe0PrLuEwm/wRQdy/BjmHG4BXCJKaX1jUt0z7vAd8gHxkmQAsR85+D1hvUZ+REhKb++Fp1DOEuIqNFakFXkdWeCR9/cA7OMgdvky6/H4P2bK68wgiShf6jFSa3x+gsqRmB3LhqkTfygr0GbGR3zcdm2EWahtX+mI7dpnfr1PZUfM9wWZ+f3kC+RUW9Vn5nnAYt/n9Q471xeL/g6Or/L6fZ3SiL0nywHV+v5/hb/pc9SW5DLnO7x9wrK+goKCgoKCgoOCx5H/FGFyqBkD1xwAAAABJRU5ErkJggg=="
      />
    </defs>
  </svg>
);
