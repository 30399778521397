import { useState, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { type Organization, OrganizationStatus } from '@API';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { addNotificationToApp } from '@Shared/utils/utils';
import {
  editOrganizationFromAdmin,
  importOrganizationMembers,
  removeOrganizationMsTeamsTenantId,
  removeOrganizationSlackTokens,
} from '@api/apis';
import { onOrganizationByIdWithBasicData } from '@graphql/customSubscriptions';
import { ApiClient } from '@base/config/amplify.config';

export const useIntegrationEditInfo = (organization: Organization) => {
  const { user } = useUserContext();
  const [slackConnected, setSlackConnected] = useState(false);
  const [msTeamsConnected, setMsTeamsConnected] = useState(false);
  const [mergeAccountToken, setMergeAccountToken] = useState(
    organization.mergeCompanyToken ?? '',
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [isImporting, setIsImporting] = useState(false);
  const [mergeAlreadyUpdated, setMergeAlreadyUpdated] = useState(false);
  const [mergeAlreadyImported, setMergeAlreadyImported] = useState(
    user.userWorkLocationId ? true : false,
  );
  const [status, setStatus] = useState(OrganizationStatus.INITIAL);
  const queryClient = useQueryClient();

  const editOrganization = async () => {
    setIsLoading(true);
    let editOrgResponse = await editOrganizationFromAdmin(
      organization.id,
      organization.name,
      mergeAccountToken,
    );
    if (editOrgResponse) {
      setMergeAlreadyUpdated(true);
      const queryData = queryClient.getQueryData<Organization>([
        'getOrganization',
      ]);
      if (queryData) {
        queryClient.setQueryData(['getOrganization'], {
          ...queryData,
          name: organization.name,
          mergeCompanyToken: mergeAccountToken,
        });
      }
    }
    setIsLoading(false);
  };

  const importOrganization = async () => {
    setIsImporting(true);
    await importOrganizationMembers(organization.id);
  };

  const handleRemoveSlack = async () => {
    await removeOrganizationSlackTokens(organization.id);
    setSlackConnected(false);
  };

  const handleRemoveMsTeams = async () => {
    await removeOrganizationMsTeamsTenantId(organization.id);
    setMsTeamsConnected(false);
  };

  useEffect(() => {
    (
      ApiClient.graphql({
        query: onOrganizationByIdWithBasicData,
        variables: {
          id: user.userOrganizationId,
        },
      }) as any
    ).subscribe({
      next: ({ provider, value }: { provider: any; value: any }) => {
        if (
          value &&
          value.data.onOrganizationById.status === OrganizationStatus.COMPLETED
        ) {
          setIsImporting(false);
          setStatus(value.data.onOrganizationById.status);
        }
      },
      error: (error: any) => {
        console.warn(error);
      },
    });
    if (mergeAccountToken && mergeAccountToken.length > 0)
      setMergeAlreadyUpdated(true);
  }, [mergeAccountToken, user.userOrganizationId]);

  useEffect(() => {
    if (!isImporting && status === OrganizationStatus.COMPLETED) {
      addNotificationToApp(
        'Successfully Imported Users, Please refresh page!',
        'success',
      );
      setMergeAlreadyImported(true);
    }
  }, [isImporting, status]);

  useEffect(() => {
    setIsEmpty(mergeAccountToken?.length === 0);
  }, [mergeAccountToken]);

  useEffect(() => {
    setSlackConnected(!!user?.Organization?.slackAccessToken);
    setMsTeamsConnected(!!user?.Organization?.msTeamsTenantId);
  }, [user]);

  return {
    slackConnected,
    msTeamsConnected,
    isEmpty,
    isImporting,
    isLoading,
    mergeAlreadyImported,
    mergeAlreadyUpdated,
    mergeAccountToken,
    editOrganization,
    importOrganization,
    handleRemoveSlack,
    handleRemoveMsTeams,
    setMergeAccountToken,
  };
};
