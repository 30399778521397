import { useEffect, useState } from 'react';

export const useToggleSwitch = (initialValue: boolean) => {
  const [toggleValue, setToggleValue] = useState(initialValue);

  useEffect(() => {
    setToggleValue(initialValue);
  }, [initialValue]);

  return { toggleValue, setToggleValue };
};
