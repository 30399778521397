import { useEffect, useRef, useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import './PillsTypeAhead.scss';
import {
  createInterestData,
  createShareInfoData,
  createUserInterestData,
  createUserLearnMoreInfoData,
  createUserShareInfoData,
  deleteUserInterestData,
  deleteUserLearnMoreInfoData,
  deleteUserShareInfoData,
} from '@api/apis';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import {
  addNotificationToApp,
  filterInterestsAndSkills,
  filterInterestsAndSkillsByComparison,
  toTitleCase,
} from '@Shared/utils/utils';
import OnboardingButton from '../OnboardingButton/OnboardingButton';
import SuggestPills from '../SuggestPills/SuggestPills';

export default function PillsTypeAhead(this: any, props: any) {
  const {
    user,
    allOrganisationInterests,
    allOrganisationShareInfo,
    setAllOrganisationInterests,
    setAllOrganisationShareInfo,
    setUserInterests,
    setUserShareInfoOn,
    setUserLearnMoreOn,
  } = useUserContext();

  const [selected, setSelected] = useState<any[]>(
    props.alreadySelectedOptions.items,
  );

  const allOrganizationOptions = [
    ...allOrganisationInterests,
    ...allOrganisationShareInfo,
  ];

  const [suggestedOptions, setSuggestedOptions] = useState<any[]>([
    ...allOrganisationInterests,
    ...allOrganisationShareInfo,
  ]);
  const typeahead = useRef<any>(null);
  const [alreadySelectedInterestsSnapshot] = useState([
    ...user.Interests!.items,
  ]);
  const [alreadySelectedShareInfoSnapshot] = useState([
    ...user.ShareInfoOn!.items,
  ]);
  const [alreadySelectedLearnMoreSnapshot] = useState([
    ...user.LearnMoreOn!.items,
  ]);

  function handleSelected() {
    //If it is not the profile page component then we don't show the selected
    //ones inside typeahead so make the array empty
    if (!props.isProfilePage) {
      setSelected([]);
    }
    setSuggestedOptions([...props.suggestOptions]);

    //If it is not the profile page component then we don't show the selected
    //ones inside typeahead so clear the typeahead so that nothing is already selected
    if (!props.isProfilePage) {
      typeahead.current?.clear();
    }

    //If we are on the onboarding page number 1 where we add interests
    if (props.currentNumber === 1) {
      //Get the new interests added
      let addedInterests = selected.filter(
        (a) => !alreadySelectedInterestsSnapshot.some((b) => a.id === b?.id),
      );

      //Loop through each of the newly added interest and find if the newly added
      //interest is already inside organization interest table, if it is not then
      //first create inside organization table and then link that interest with the
      //user interest table by creating into it, else just create it inside user interest table
      addedInterests.forEach(async (addedInterest) => {
        if (
          !allOrganisationInterests.find(
            (interest) => interest.id === addedInterest.id,
          )
        ) {
          const newOptionResponse = await createInterestData(
            user,
            toTitleCase(addedInterest.name),
          );
          if (newOptionResponse) {
            await createUserInterestData(newOptionResponse.id, user.id);
          }
        } else {
          await createUserInterestData(addedInterest.id, user.id);
        }
      });

      //If user deselects the already selected interest then loop through each selected interest
      //and find the deleted one and if found then delete it
      let removedInterests = alreadySelectedInterestsSnapshot.filter(
        (a) => !selected.some((b) => a?.id === b.id),
      );
      removedInterests.forEach(async (removedInterest) => {
        if (removedInterest) {
          await deleteUserInterestData(removedInterest.id);
        }
      });
      setUserInterests({
        items: selected,
        __typename: 'ModelUserInterestConnection',
      });
    }
    //If we are on the second onboarding page where we add learn more info
    else if (props.currentNumber === 2) {
      //Get the new learn more options added
      let addedLearnMoreOns = selected.filter(
        (a) => !alreadySelectedLearnMoreSnapshot.some((b) => a.id === b?.id),
      );

      //Loop through each of the newly added learn more option and find if the newly added
      //learn more option is already inside organization learn more table, if it is not then
      //first create inside organization table and then link that option with the
      //user learn more table by creating into it, else just create it inside user learn more table
      addedLearnMoreOns.forEach(async (addedLearnMoreOn) => {
        if (
          !allOrganisationShareInfo.find(
            (shareInfo) => shareInfo.id === addedLearnMoreOn.id,
          )
        ) {
          const newOptionResponse = await createShareInfoData(
            user,
            toTitleCase(addedLearnMoreOn.name),
          );
          if (newOptionResponse) {
            await createUserLearnMoreInfoData(newOptionResponse.id, user.id);
          }
        } else {
          await createUserLearnMoreInfoData(addedLearnMoreOn.id, user.id);
        }
      });

      //If user deselects the already selected learn more option then loop through each selected learn more option
      //and find the deleted one and if found then delete it
      let removedLearnMoreOns = alreadySelectedLearnMoreSnapshot.filter(
        (a) => !selected.some((b) => a?.id === b.id),
      );
      removedLearnMoreOns.forEach(async (removedLearnMoreOn) => {
        if (removedLearnMoreOn) {
          await deleteUserLearnMoreInfoData(removedLearnMoreOn?.id);
        }
      });
      setUserLearnMoreOn({
        items: selected,
        __typename: 'ModelLearnMoreInfoConnection',
      });
    } else if (props.currentNumber === 3) {
      let addedShareInfos = selected.filter(
        (a) => !alreadySelectedShareInfoSnapshot.some((b) => a.id === b!.id),
      );
      addedShareInfos.forEach(async (addedShareInfo) => {
        await createUserShareInfoData(addedShareInfo.id, user.id);
      });
      let removedShareInfos = alreadySelectedShareInfoSnapshot.filter(
        (a) => !selected.some((b) => a!.id === b.id),
      );
      removedShareInfos.forEach(async (removedShareInfo) => {
        await deleteUserShareInfoData(removedShareInfo!.id);
      });
      setUserShareInfoOn({
        items: selected,
        __typename: 'ModelUserShareInfoConnection',
      });
    }
  }

  function checkDuplicateOption(newSelected: any) {
    for (var i = 0; i < allOrganizationOptions.length; i++) {
      let filteredIntrest;
      if (newSelected.interest) {
        filteredIntrest = newSelected.interest.name.toLowerCase();
      } else {
        filteredIntrest = newSelected.label.toLowerCase();
      }
      if (allOrganizationOptions[i].name.toLowerCase() === filteredIntrest) {
        addNotificationToApp('Selected option already present', 'error');
        if (props.isProfilePage) {
          setSelected(selected.filter((item) => item !== newSelected));
        }
        return true;
      }
    }
    return false;
  }

  useEffect(() => {
    const newSuggestOptions = suggestedOptions.filter(
      (suggestion: any) =>
        !selected.some(
          (select) => suggestion.name === filterInterestsAndSkills(select),
        ),
    );

    // Filter out the unique suggestions, i.e. ShareInfo and Interest both have same suggestion
    const uniqueOptions = Object.values(
      newSuggestOptions.reduce((acc, obj) => ({ ...acc, [obj.name]: obj }), {}),
    );

    setSuggestedOptions([...uniqueOptions]);
  }, [selected, suggestedOptions]);

  return (
    <div className="pills-typeahead-ahead">
      {!props.isProfilePage && (
        <SuggestPills
          suggestOptions={selected}
          setSuggestOptions={setSuggestedOptions}
          suggestions={suggestedOptions}
          selected={selected}
          setSelected={setSelected}
          isRemove={true}
          currentNumber={props.currentNumber}
        />
      )}

      <Typeahead
        ref={typeahead}
        allowNew
        multiple
        id="typeahead"
        onChange={async (newSelected: any[]) => {
          let isFound = false;
          let selectedOption: any;
          /* check if it is profile page or not, if not then the selected option would be
             on the first index otherwise it should be the last element of the newSelected array */
          if (!props.isProfilePage) {
            selectedOption = newSelected[0];
          } else if (newSelected.length >= selected.length) {
            selectedOption = newSelected[newSelected.length - 1];
          }

          if (props.isProfilePage && newSelected.length < selected.length) {
            isFound = false;
          } else {
            for (var i = 0; i < suggestedOptions.length; i++) {
              if (
                suggestedOptions[i] === selectedOption ||
                filterInterestsAndSkills(suggestedOptions[i]) ===
                  selectedOption.name
              ) {
                suggestedOptions.splice(i, 1);
                setSuggestedOptions([...suggestedOptions]);
                isFound = true;
                break;
              }
            }
          }

          if (!props.isProfilePage) typeahead.current?.clear();
          if (!isFound) {
            if (props.currentNumber === 1) {
              let isNewOptionPresent = false;
              let NewOption = newSelected.find(
                (option) => option.customOption !== undefined,
              );
              if (NewOption !== undefined) {
                isNewOptionPresent = true;
              }
              if (isNewOptionPresent) {
                if (!checkDuplicateOption(NewOption)) {
                  const newOptionResponse = await createInterestData(
                    user,
                    !props.isProfilePage
                      ? toTitleCase(selectedOption.label)
                      : toTitleCase(NewOption.label),
                  );
                  if (newOptionResponse) {
                    setSelected([newOptionResponse, ...selected]);
                    setAllOrganisationInterests([
                      newOptionResponse,
                      ...allOrganisationInterests,
                    ]);
                  }
                }
              } else {
                setSelected([...newSelected]);
              }
            } else {
              let isNewOptionPresent = false;
              let NewOption = newSelected.find(
                (option) => option.customOption !== undefined,
              );
              if (NewOption !== undefined) {
                isNewOptionPresent = true;
              }
              if (isNewOptionPresent) {
                if (!checkDuplicateOption(NewOption)) {
                  const newOptionResponse = await createShareInfoData(
                    user,
                    !props.isProfilePage
                      ? toTitleCase(selectedOption.label)
                      : toTitleCase(NewOption.label),
                  );

                  if (newOptionResponse) {
                    setSelected([newOptionResponse, ...selected]);
                    setAllOrganisationShareInfo([
                      newOptionResponse,
                      ...allOrganisationShareInfo,
                    ]);
                  }
                }
              } else {
                setSelected([...newSelected]);
              }
            }
          } else {
            let isAlreadyPresent = false;
            for (let j = 0; j < selected.length; j++) {
              isAlreadyPresent = filterInterestsAndSkillsByComparison(
                selectedOption,
                selected[j],
              );
              if (isAlreadyPresent) break;
            }
            if (!isAlreadyPresent) {
              setSelected([selectedOption, ...selected]);
            } else {
              typeahead.current?.clear();
            }
          }

          for (var j = 0; j < suggestedOptions.length; j++) {
            if (suggestedOptions[j] === selectedOption) {
              suggestedOptions.splice(j, 1);
              setSuggestedOptions(suggestedOptions);
              break;
            }
          }
        }}
        labelKey={(option: any) =>
          `${
            option.name !== undefined
              ? option.name || ''
              : option.interest !== undefined
                ? option.interest.name || ''
                : option.shareInfo.name || ''
          }`
        }
        options={suggestedOptions}
        placeholder="Start typing or pick from the list below"
        newSelectionPrefix="Add a new Interest: "
        selected={props.isProfilePage ? selected : []}
      />
      <SuggestPills
        suggestOptions={suggestedOptions}
        setSuggestOptions={setSuggestedOptions}
        selected={selected}
        setSelected={setSelected}
        isRemove={false}
        currentNumber={props.currentNumber}
      />

      <div
        className={
          props.isProfilePage
            ? 'd-flex justify-content-end'
            : 'col-12 d-flex justify-content-center mt-5'
        }
      >
        {props.isProfilePage && (
          <div
            className="btn remove-pill-button"
            onClick={() => props.handleShowModal(false)}
          >
            Discard
          </div>
        )}
        <OnboardingButton
          text={props.isProfilePage ? 'Save' : 'Next'}
          icon={true}
          styling="px-4 py-2"
          theme={`view-profile-button-wrapper ${props.isProfilePage && 'ms-2'}`}
          handleSelected={handleSelected}
          handleShowModal={props.handleShowModal}
          handleClick={props.handleClick}
          handleSelectedCheck={true}
          isProfile={props.isProfilePage}
        />
      </div>
      {!props.isProfilePage && (
        <p className="fs-15 text-center mt-3">
          You can come back to these later in your profile.
        </p>
      )}
    </div>
  );
}
