import { PanelLoadingState } from '@Shared/DS';
import { FormsList } from '../FormsList';
import { NoFormEvents } from '../NoFormEvents';
import { NoForms } from '../NoForms';
import { useActivityPanel } from '../useActivityPanel';

interface ActivityPanelContentProps {
  openCreateUpdateFormEventPanel: () => void;
}

export const ActivityPanelContent = ({
  openCreateUpdateFormEventPanel,
}: ActivityPanelContentProps) => {
  const { formEvents, isLoading, hasForms, hasFormEvents } = useActivityPanel();

  if (isLoading) {
    return <PanelLoadingState />;
  }

  if (!hasFormEvents) {
    return hasForms ? (
      <NoFormEvents
        openCreateUpdateFormEventPanel={openCreateUpdateFormEventPanel}
      />
    ) : (
      <NoForms />
    );
  }

  return <FormsList formEvents={formEvents} />;
};
