import { useState } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import TextArea from '@Shared/Components/TextArea/TextArea';
import './WorkStyleModal.scss';
import { Button } from '@Shared/DS';

const WorkStyleModal = (props: any) => {
  const { user, setUserWorkStyle } = useUserContext();
  const [whatIDo, setWhatIDo] = useState(user.WorkStyle.whatIDoAtWork ?? '');
  const [myFocusTime, setMyFocusTime] = useState(
    user.WorkStyle.myFocusTimeIs ?? '',
  );
  const [receiveFeedback, setReceiveFeedback] = useState(
    user.WorkStyle.giveReceivingFeedback ?? '',
  );
  const [receiveInformation, setReceiveInformation] = useState(
    user.WorkStyle.digestionInformation ?? '',
  );
  const [elementAtWork, setElementAtWork] = useState(
    user.WorkStyle.inMyElement ?? '',
  );

  const handleSubmit = () => {
    setUserWorkStyle(
      whatIDo,
      myFocusTime,
      receiveFeedback,
      receiveInformation,
      elementAtWork,
    );
    props.handleShowModal(false);
  };

  return (
    <>
      <Modal
        size="lg"
        fullscreen={'md-down'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        show={props.showModal}
        onHide={() => props.handleShowModal(false)}
      >
        <Modal.Header>
          <Modal.Title className="fs-24-semi-bold-4C5564">
            Edit Work preferences
          </Modal.Title>
          <button
            type="button"
            className="btn-close modal-close-btn"
            data-bs-dismiss="modal"
            onClick={() => props.handleShowModal(false)}
            aria-label="Close"
          ></button>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <label>What I do at work(ignoring my job title)</label>
              <TextArea
                id={'firstName'}
                type={'text'}
                placeholder={''}
                width={''}
                setValue={setWhatIDo}
                defaultValue={whatIDo}
                additionalClasses={''}
                fieldName={'What I do at work'}
              ></TextArea>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <label>My focus time is</label>
              <TextArea
                id={'focusTime'}
                type={'text'}
                placeholder={''}
                width={''}
                setValue={setMyFocusTime}
                defaultValue={myFocusTime}
                additionalClasses={''}
                fieldName={'My focus time is'}
              ></TextArea>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <label>How I like to give and receive feedback</label>
              <TextArea
                id={'focusTime'}
                type={'text'}
                placeholder={''}
                width={''}
                setValue={setReceiveFeedback}
                defaultValue={receiveFeedback}
                additionalClasses={''}
                fieldName={'How I like to give and receive feedback'}
              ></TextArea>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <label>The format I like to receive information</label>
              <TextArea
                id={'focusTime'}
                type={'text'}
                placeholder={''}
                width={''}
                setValue={setReceiveInformation}
                defaultValue={receiveInformation}
                additionalClasses={''}
                fieldName={'The format i like to receive information'}
              ></TextArea>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <label>When I am in my element at work</label>
              <TextArea
                id={'focusTime'}
                type={'text'}
                placeholder={''}
                width={''}
                setValue={setElementAtWork}
                defaultValue={elementAtWork}
                additionalClasses={''}
                fieldName={'When I am in my element at work'}
              ></TextArea>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            text="Cancel"
            buttonStyle="outline"
            onClick={() => props.handleShowModal(false)}
          />
          <Button text="Save" onClick={() => handleSubmit()} />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default WorkStyleModal;
