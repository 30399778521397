import { type Form } from '@base/models/form.model';
import { useNavigate } from 'react-router-dom';

export const useFormActionDropdown = (form: Form) => {
  const navigate = useNavigate();
  const formUrl = `/form/${form.id}`;

  const editForm = () => {
    navigate(formUrl, { state: { isInEditingMode: true } });
  };

  const previewForm = () => {
    navigate(formUrl);
  };

  return {
    editForm,
    previewForm,
  };
};
