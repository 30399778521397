import { Form } from 'react-bootstrap';
import './FormSelect.scss';

interface IFormSelect {
  id: string;
  options: { key: string; value: string }[];
  disabled?: boolean;
  className?: string;
  required?: boolean;
  errorMessage?: string;
  selected?: string;
  setSelected(selected: string): void;
}

export const FormSelect = (props: IFormSelect) => {
  const {
    id,
    options,
    disabled,
    className,
    errorMessage,
    selected,
    setSelected,
  } = props;

  return (
    <>
      <Form.Select
        id={id}
        aria-label="form select"
        className={`form-select-wrapper ${className ?? ''}`}
        disabled={disabled}
        value={selected}
        required={props.required}
        onChange={(value) => setSelected(value.target.value)}
      >
        <option hidden>Select</option>
        {options.map((option, i) => (
          <option key={option.key} value={option.value}>
            {option.key}
          </option>
        ))}
      </Form.Select>

      {className?.includes('is-invalid') && (
        <div className="invalid-feedback text-start">{errorMessage}</div>
      )}
    </>
  );
};
