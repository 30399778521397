import { type FC, type ReactNode } from 'react';
import { Modal as BaseModal } from 'react-bootstrap';

interface ModalProps {
  preventClickOutside?: boolean;
  title?: ReactNode;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  isOpen: boolean;
  children: ReactNode;
  footer?: ReactNode;
  className?: string;
  onClose: () => void;
}

export const Modal: FC<ModalProps> = ({
  title,
  size = 'md',
  isOpen,
  onClose,
  children,
  footer,
  className,
  preventClickOutside = false,
}) => (
  <BaseModal
    backdrop={preventClickOutside ? 'static' : true}
    centered
    className={className}
    show={isOpen}
    onHide={onClose}
    size={size === 'md' ? undefined : size}
    fullscreen="lg-down"
    scrollable
  >
    <BaseModal.Header closeButton>
      {title && <BaseModal.Title>{title}</BaseModal.Title>}
    </BaseModal.Header>
    <BaseModal.Body>{children}</BaseModal.Body>
    {footer && <BaseModal.Footer>{footer}</BaseModal.Footer>}
  </BaseModal>
);
