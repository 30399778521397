export enum FormInputType {
  STRING = 'string',
  TEXTAREA = 'textarea',
  DATE = 'date',
  NUMBER = 'number',
  RANGE = 'range',
  RANGE_RADIOBUTTONS = 'range-radiobuttons',
  DROPDOWN = 'dropdown',
  FILE = 'file',

  // Boolean inputs
  CHECKBOX = 'checkbox',
  BOOL_DROPDOWN = 'bool-dropdown',
  BOOL_RADIOBUTTONS = 'bool-radiobuttons',

  // Multi-choice inputs
  CHECKBOXES = 'checkboxes',
  RADIOBUTTONS = 'radiobuttons',
  SEARCHBAR = 'searchbar',
}

export enum FormElementType {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  SEPARATOR = 'separator',
  TEXT = 'text',
  IMAGE = 'image',
  INPUT = 'input',
}

export enum DataRefType {
  USER = 'user',
  FORM = 'form',
  RUNBOOK = 'runbook',
  RUNBOOK_ROLE = 'runbookRole',
  DATA_STORE = 'dataStore',
}

export enum DataValueType {
  STRING = 'string',
  EMAIL = 'email',
  URL = 'url',
  DATE = 'date',
  INTEGER = 'integer',
  DECIMAL = 'decimal',
  BOOLEAN = 'boolean',
  FILE = 'file',
  CONFIRMATION = 'confirmation',
}

export type DataType = DataRefType | DataValueType;

export type FormFormattingHeaderElement = {
  element: FormElementType.H1 | FormElementType.H2 | FormElementType.H3;
  text: string;
};
export type FormFormattingSeparatorElement = {
  element: FormElementType.SEPARATOR;
};
export type FormFormattingTextElement = {
  element: FormElementType.TEXT;
  text: string;
  format: 'markdown';
};
export type FormFormattingImageElement = {
  element: FormElementType.IMAGE;
  url: string;
  alt: string;
};
export type FormFormattingElement =
  | FormFormattingHeaderElement
  | FormFormattingSeparatorElement
  | FormFormattingTextElement
  | FormFormattingImageElement;

export type FormInputElementType = {
  element: FormElementType.INPUT;
  type: DataType;
  input: FormInputType;
  /**
   * RefID for corresponding column in data store
   */
  columnRefId?: string;
  columnId?: string;
  label: string;
  isOptional?: boolean;
  isHidden?: boolean;
  isReadonly?: boolean;
  isMultiselect?: boolean;
  value?: string;
  description?: string;
  placeholder?: string;
  limit?: number;
  min?: number;
  max?: number;
  rangeLabels?: Record<number, string>;
  choices?: DataValue[];
  /** file-related filter, embedded for now */
  formats?: string[];
};

export type FormElement = FormFormattingElement | FormInputElementType;

// TODO: Generalize
export type FormTriggers = { onSubmit?: TriggerAction[] };

export enum FormLinkType {
  Runbook = 'runbook',
}

export interface Form {
  id: string;
  organizationId: string;
  name: string;
  link: { type: FormLinkType; id: string };
  linkType: FormLinkType;
  dataStoreId: string;
  elements: FormElement[];
  triggers: FormTriggers;
  submission?: Object;
}

export type DataValueMap = {
  [DataRefType.USER]: { userId: string; email: string };
  [DataRefType.FORM]: { formId: string; name?: string };
  [DataRefType.RUNBOOK]: { runbookId: string };
  [DataRefType.RUNBOOK_ROLE]: { runbookRoleId: string };
  [DataRefType.DATA_STORE]: { dataStoreId: string };
  [DataValueType.STRING]: string;
  [DataValueType.EMAIL]: string;
  [DataValueType.URL]: string;
  [DataValueType.DATE]: Date;
  [DataValueType.INTEGER]: number;
  [DataValueType.DECIMAL]: number;
  [DataValueType.BOOLEAN]: boolean;
  [DataValueType.FILE]: { fileId: string; url: string; fileName: string };
  [DataValueType.CONFIRMATION]: {
    userId: string;
    date: Date;
    confirmed: boolean;
  };
};

export type DataValue = DataValueMap[keyof DataValueMap];

export interface TriggerAction {
  id: string;
  action: TriggerActionType;
  params: TriggerActionParams;
}

export enum TriggerActionType {
  SEND_FORM = 'SEND_FORM',
  SEND_MESSAGE = 'SEND_MESSAGE',
  SEND_EMAIL = 'SEND_EMAIL',
  SEND_ACTION = 'SEND_ACTION',
  SET_RUNBOOK_ROLE = 'SET_RUNBOOK_ROLE',
}

export type TriggerActionParamValue = DataValue | Array<DataValue> | undefined;
export type TriggerActionParams = Record<string, TriggerActionParamValue>;

export type CreateFormTriggerParam = {
  action: TriggerActionType;
  params: Record<string, TriggerActionParamValue>;
};

export type DataStore = {
  id: string;
  name: string;
  organizationId: string;
  columns: {
    id: string;
    refId: string;
    type: string;
    name: string;
    source: string;
    referencedByForms: Array<string>;
  }[];
};
