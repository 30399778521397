import { GoKebabHorizontal } from 'react-icons/go';
import { EventStatusPill } from '@Shared/DS';
import { InfoModal } from '@Shared/Components/InfoModal/InfoModal';
import { type IEvent } from '@base/models/playbookHome.model';
import { type JourneyStatus } from '@base/API';
import { RolePill } from '@Shared/Components/RolePill/RolePill';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { EventType } from '@base/models/common.model';
import { CustomDropdown } from '../CustomDropdown';
import { useMessageActionCard } from './useMessageActionCard';
import './MessageActionCard.scss';

interface IMessageActionCard {
  event: IEvent;
  isParentPlaybook: boolean;
  assigneeSlackId?: string;
  isEditingAllowed?: boolean;
  editEvent: (id: string, type: EventType) => void;
  deleteEvent: (
    id: string,
    type: EventType,
    isActionReminder?: boolean,
  ) => void;
  isDeleting?: boolean;
  playbookStatus?: JourneyStatus;
}

export const MessageActionCard = (props: IMessageActionCard) => {
  const {
    event,
    isDeleting,
    assigneeSlackId,
    isParentPlaybook,
    isEditingAllowed,
    playbookStatus,
    editEvent,
    deleteEvent,
  } = props;
  const { states, dropdownItems, icon, warning, setStates } =
    useMessageActionCard(
      event,
      isParentPlaybook,
      editEvent,
      assigneeSlackId,
      playbookStatus,
    );

  const sliceAfterThreeLineBreaks = (text: string | null | undefined) => {
    if (!text) return '';

    let lineBreakCount = 0;
    let i = 0;
    while (i < text.length && lineBreakCount < 1) {
      if (text.charAt(i) === '\n') {
        lineBreakCount++;
        if (lineBreakCount === 1) {
          return text.substring(0, i);
        }
      }
      i++;
    }
    return text;
  };

  const slicedEventMessage = sliceAfterThreeLineBreaks(event.message);

  return (
    <div className="message-action-card">
      <div className="card mt-2 p-2">
        <div className="card__title d-flex justify-content-between">
          <span
            className="align-baseline cursor-pointer"
            onClick={() => editEvent(event.id, event.type as EventType)}
          >
            <i className={`me-1 ${icon}`}></i>
            <RolePill
              className="role-pill"
              role={`${event.userName} ${event.isActionReminder ? ' (reminder)' : ''}`}
            />
          </span>

          {isEditingAllowed && (
            <CustomDropdown
              className="options"
              Icon={GoKebabHorizontal}
              items={dropdownItems}
            />
          )}
        </div>

        <div
          className="cursor-pointer"
          onClick={() => editEvent(event.id, event.type as EventType)}
        >
          <textarea
            readOnly
            value={event.title || slicedEventMessage.slice(0, 70)}
            className="textarea-readonly card__description mt-1"
          />
          <div className="d-flex justify-content-between my-1">
            {!isParentPlaybook && (
              <div className="d-flex">
                <EventStatusPill status={event.status} className="py-1" />
                {warning && (
                  <OverlayTrigger
                    placement={'bottom'}
                    overlay={
                      <Tooltip style={{ position: 'fixed' }}>{warning}</Tooltip>
                    }
                  >
                    <i className="bi bi-exclamation-triangle ms-1 text-warning"></i>
                  </OverlayTrigger>
                )}
              </div>
            )}
            <div className="card__time ms-auto">{event.sendTime}</div>
          </div>
        </div>
      </div>

      <InfoModal
        title={`Delete ${event.type === EventType.ACTION ? 'action' : 'message'}`}
        description={
          <div>
            Are you sure you want to delete this{' '}
            {event.type === EventType.ACTION ? 'action' : 'message'}?
          </div>
        }
        showModal={states.showConfirmationModal}
        onClickConfirmButton={() =>
          deleteEvent(event.id, event.type as EventType, event.isActionReminder)
        }
        handleShowModal={(showConfirmationModal: boolean) =>
          setStates((prevStates) => ({ ...prevStates, showConfirmationModal }))
        }
        isDeleteButton={true}
        isLoading={isDeleting}
      ></InfoModal>
    </div>
  );
};
