import { addNotificationToApp } from '@Shared/utils/utils';
import {
  createActionCollaborator,
  createJourneyFromTemplate,
  fetchKinfolkTemplate,
  getJourneyCollaborators,
  updateKinfolkTemplate,
} from '@api/apis';
import { type KinfolkTemplate, UserType } from '@base/API';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { Keys } from '@base/keys/queryKeys';
import { useQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const useTemplateHome = () => {
  const { id } = useParams();
  const [states, setStates] = useState({
    templateName: '',
    isEditingAllowed: false,
    isBusy: false,
  });
  const { setCollaborators, user } = useUserContext();
  const navigate = useNavigate();

  const {
    data: template,
    isLoading,
    isFetched,
  } = useQuery({
    queryKey: Keys.getTemplate(id),
    queryFn: () => {
      if (!id) {
        return;
      }
      return fetchKinfolkTemplate(id);
    },
    enabled: !!id,
  });

  const { data: collaborators } = useQuery({
    queryKey: Keys.getPlaybookCollaborators(id),
    queryFn: () => {
      if (!id) {
        return [];
      }

      return getJourneyCollaborators(id, false);
    },
    enabled: !!id,
  });

  const updateTemplate = async (updatedTemplate: KinfolkTemplate) => {
    if (
      template &&
      (updatedTemplate.name !== template.name ||
        updatedTemplate.isPublic !== template.isPublic)
    ) {
      await updateKinfolkTemplate({
        id,
        name: updatedTemplate.name,
        isPublic: updatedTemplate.isPublic,
      } as KinfolkTemplate);
    }
  };

  const navigateTo = (path: string) => {
    navigate(path);
  };

  const createPlaybookFromTemplate = async () => {
    if (!id) {
      return;
    }

    setStates((states) => ({ ...states, isBusy: true }));
    const playbookId = await createJourneyFromTemplate(
      user.userOrganizationId,
      id,
      user.id,
    );
    setStates((states) => ({ ...states, isBusy: false }));

    if (playbookId) {
      //assign collaborators to the runbook action events
      createActionCollaborator([playbookId]);
      navigate(`/runbook/${playbookId}`);
    }
  };

  const handleBack = useCallback(() => {
    navigate('/runbooks');
  }, [navigate]);

  useEffect(() => {
    if (isFetched && !template) {
      addNotificationToApp('Template not found', 'error');
      return;
    }

    setStates((states) => ({ ...states, templateName: template?.name ?? '' }));
  }, [isFetched, template]);

  useEffect(() => {
    setCollaborators(collaborators ?? []);
  }, [collaborators, setCollaborators]);

  useEffect(() => {
    setStates((states) => ({
      ...states,
      isEditingAllowed: user?.type === UserType.SUPER_ADMIN,
    }));
  }, [user]);

  return {
    template,
    isLoading,
    states,
    setStates,
    handleBack,
    navigateTo,
    updateTemplate,
    createPlaybookFromTemplate,
  };
};
