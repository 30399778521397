import { type ElementsDropdownGroup } from '@Shared/DS';
import {
  AlignLeft,
  Calendar,
  CheckCircle,
  Divider,
  GitCommit,
  H1,
  H2,
  H3,
  Justify,
  Numbers,
  Search,
  ShortText,
  Upload,
} from '@Shared/Icons';
import {
  DataRefType,
  DataValueType,
  FormElementType,
  FormInputType,
} from '@models/form.model';

export const elementGroups: ElementsDropdownGroup[] = [
  {
    label: 'Add content',
    items: [
      {
        element: {
          element: FormElementType.H1,
          text: '',
        },
        label: 'Heading 1',
        description: 'Main heading',
        icon: <H1 />,
      },
      {
        element: {
          element: FormElementType.H2,
          text: '',
        },
        label: 'Heading 2',
        description: 'Section heading',
        icon: <H2 />,
      },
      {
        element: {
          element: FormElementType.H3,
          text: '',
        },
        label: 'Heading 3',
        description: 'Subsection heading',
        icon: <H3 />,
      },
      {
        element: {
          element: FormElementType.TEXT,
          text: '',
          format: 'markdown',
        },
        label: 'Paragraph',
        description: 'Main content text',
        icon: <Justify />,
      },
      {
        element: {
          element: FormElementType.SEPARATOR,
        },
        label: 'Divider',
        description: 'Visually separate blocks of content',
        icon: <Divider />,
      },
    ],
  },
  {
    label: 'Add question',
    items: [
      {
        element: {
          element: FormElementType.INPUT,
          type: DataValueType.STRING,
          input: FormInputType.STRING,
          label: '',
        },
        label: 'Short answer',
        description: 'For brief responses',
        icon: <ShortText />,
      },
      {
        element: {
          element: FormElementType.INPUT,
          type: DataValueType.STRING,
          input: FormInputType.TEXTAREA,
          label: '',
        },
        label: 'Long answer',
        description: 'For elaborated responses',
        icon: <AlignLeft />,
      },
      {
        element: {
          element: FormElementType.INPUT,
          type: DataValueType.INTEGER,
          input: FormInputType.NUMBER,
          label: '',
        },
        label: 'Numbers',
        description: 'For numerical responses',
        icon: <Numbers />,
      },
      {
        element: {
          element: FormElementType.INPUT,
          type: DataValueType.DATE,
          input: FormInputType.STRING,
          label: '',
        },
        label: 'Date',
        description: 'For picking a specific day in the calendar',
        icon: <Calendar />,
      },
      {
        element: {
          element: FormElementType.INPUT,
          type: DataValueType.STRING,
          input: FormInputType.RADIOBUTTONS,
          choices: ['Option 1'],
          label: '',
        },
        label: 'Radio buttons',
        description: 'Select only one option',
        icon: <CheckCircle />,
      },
      {
        element: {
          element: FormElementType.INPUT,
          type: DataRefType.USER,
          input: FormInputType.SEARCHBAR,
          label: '',
        },
        label: 'Search user',
        description: 'Select a Kinfolk user',
        icon: <Search />,
      },
      // {
      //   element: {
      //     element: FormElementType.INPUT,
      //     type: DataValueType.STRING,
      //     input: FormInputType.CHECKBOXES,
      //     choices: ['Option 1'],
      //     label: '',
      //   },
      //   label: 'Checkboxes',
      //   description: 'Select many options',
      //   icon: <CheckSquare />,
      // },
      {
        element: {
          element: FormElementType.INPUT,
          type: DataValueType.INTEGER,
          input: FormInputType.RANGE_RADIOBUTTONS,
          min: 1,
          max: 3,
          label: '',
          rangeLabels: {
            1: 'Poor',
            3: 'Great',
          },
        },
        label: 'Linear scale',
        description: 'Rate on a scale',
        icon: <GitCommit />,
      },
      {
        element: {
          element: FormElementType.INPUT,
          type: DataValueType.FILE,
          input: FormInputType.FILE,
          label: '',
        },
        label: 'File upload',
        description: 'Add a file',
        icon: <Upload />,
      },
    ],
  },
];
