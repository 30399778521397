import Form from 'react-bootstrap/Form';
import styles from './ToggleSwitch.module.scss';
import { useToggleSwitch } from './useToggleSwitch';

export const ToggleSwitch = ({
  initialValue,
  setValue,
  disabled,
}: {
  initialValue: boolean;
  setValue: Function;
  disabled?: boolean;
}) => {
  const { toggleValue, setToggleValue } = useToggleSwitch(initialValue);

  return (
    <Form className={styles.toggleSwitch}>
      <Form.Check
        type="switch"
        disabled={disabled}
        id="custom-switch"
        checked={toggleValue}
        onChange={() => {
          setToggleValue(!toggleValue);
          setValue(!toggleValue);
        }}
      />
    </Form>
  );
};
