import { TemplateCard } from '@Shared/DS';
import Loader from '@Shared/Components/Loader/Loader';
import { BsArrowRight } from 'react-icons/bs';
import { UserType } from '@base/API';
import { useHome } from './useHome';
import { HomePlaybookCard } from './Components/HomePlaybookCard/HomePlaybookCard';
import './Home.scss';

export const Home = () => {
  const {
    user,
    userAssignedJourneys,
    isFetching,
    userSuggestedJourneys,
    kinfolkTemplates,
    openPlaybookLibrary,
    openAssignedJourney,
  } = useHome();

  return (
    <div className="container home-page-wrapper p-5">
      {isFetching ? (
        <Loader active={isFetching} />
      ) : (
        <>
          <div className="row">
            <div className="col d-flex justify-content-start">
              <h1>Hello{user.firstName ? ` ${user.firstName}!` : ''}</h1>
            </div>
          </div>
          <div className="row">
            {userAssignedJourneys?.length ? (
              <>
                <p className="fs-16-25272a mb-3">
                  Your{' '}
                  {userAssignedJourneys?.length ? (
                    <span>
                      {userAssignedJourneys.length > 1 ? 'runbooks' : 'runbook'}
                    </span>
                  ) : (
                    'runbooks'
                  )}{' '}
                  to guide you through the moments that matter.
                </p>
                {userAssignedJourneys?.map((journey) => (
                  <div
                    key={journey.id}
                    onClick={() => openAssignedJourney(journey.id)}
                    className="col-12 col-md-6 col-lg-4 "
                  >
                    <HomePlaybookCard
                      isCollaboratorJourneyCard={false}
                      playbook={journey}
                    />
                  </div>
                ))}
              </>
            ) : (
              <div className="fs-16-25272a">
                Nothing to see here yet. Ask your People team or manager which
                runbooks might help you progress in your role.
              </div>
            )}
          </div>

          {user?.type === UserType.COMPANY_ADMIN ||
          user?.type === UserType.SUPER_ADMIN ? (
            <>
              {kinfolkTemplates?.length ? (
                <>
                  <div className="row mt-3 align-items-end">
                    <div className="col-auto me-auto">
                      <h2 className="fs-22-medium-bold-25272A mt-3">
                        Kinfolk templates
                      </h2>
                    </div>
                    <div className="col-auto">
                      <button
                        type="button"
                        className="btn btn-link link-underline-light"
                        onClick={openPlaybookLibrary}
                      >
                        All templates <BsArrowRight />
                      </button>
                    </div>
                  </div>

                  <div className="row">
                    {kinfolkTemplates
                      ?.filter(
                        (template) => typeof template.orderNo === 'number',
                      )
                      .sort((a, b) => {
                        const orderNoA = a.orderNo || 0;
                        const orderNoB = b.orderNo || 0;
                        return orderNoA - orderNoB;
                      })
                      .slice(0, 3)
                      .map((template) => (
                        <TemplateCard
                          key={template.id}
                          template={template}
                          isJourneyLibrary={true}
                        />
                      ))}
                  </div>
                </>
              ) : null}
            </>
          ) : null}

          {userSuggestedJourneys.length ? (
            <div className="row mt-3">
              <div className="col-12 fs-22-medium-bold-25272A mt-3">
                Suggested{' '}
                {userSuggestedJourneys?.length ? (
                  <span>
                    {userSuggestedJourneys.length > 1 ? 'runbooks' : 'runbook'}
                  </span>
                ) : null}
              </div>
              <p className="fs-16-25272a">
                Tailored recommendations to help you be your best.
              </p>
              {userSuggestedJourneys.map((suggestedJourney) => (
                <div
                  key={suggestedJourney.id}
                  className="col-12 col-md-6 col-lg-4"
                >
                  <HomePlaybookCard
                    isCollaboratorJourneyCard={false}
                    playbook={suggestedJourney}
                  />
                </div>
              ))}
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};
