import {
  type SortingState,
  getSortedRowModel,
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
} from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { ProgressBar } from '@Shared/Components/ProgressBar/ProgressBar';
import { formateDate } from '@Shared/utils/utils';
import { type IJourneyPeople } from '@base/models/journeyAssignees.model';
import {
  BuilderType,
  EventStatus,
  JourneyStatus,
  PlaybookMode,
} from '@base/API';
import { PlaybookActionDropdown, StatusPill } from '@Shared/DS';
import { sureString } from '@Shared/utils/sure';

export const useParticipantTable = (childJourneys: IJourneyPeople[]) => {
  const [data, setData] = useState<IJourneyPeople[]>([]);
  const [sorting, setSorting] = useState<SortingState>([]);

  const columnHelper = createColumnHelper<IJourneyPeople>();

  const getEventProgress = (
    playbook: IJourneyPeople,
  ): {
    percentage: number;
    bg: 'bg-info' | 'bg-success' | 'bg-danger' | 'bg-warning';
    title?: string;
  }[] => {
    if (
      !playbook.events ||
      (playbook.type === BuilderType.SELFSERVE && !playbook.userStartedAt)
    ) {
      return [];
    }

    const events = playbook.events.items;
    const sentOrDoneEventsCount = events.filter(
      (event) =>
        event?.status === EventStatus.SENT ||
        event?.status === EventStatus.DONE,
    ).length;
    const failedOrSkippedEventsCount = events.filter(
      (event) =>
        event?.status === EventStatus.FAILED ||
        event?.status === EventStatus.SKIPPED ||
        event?.status === EventStatus.OVERDUE,
    ).length;

    return [
      {
        percentage:
          (failedOrSkippedEventsCount * 100) / playbook.events.items.length,
        bg: 'bg-danger',
        title: 'Skipped/Failed',
      },
      {
        percentage:
          (sentOrDoneEventsCount * 100) / playbook.events.items.length,
        bg: 'bg-success',
        title: 'Sent/Done',
      },
    ];
  };

  const columns = [
    columnHelper.accessor((row: IJourneyPeople) => row, {
      id: 'assigneeProfilePhoto',
      cell: (info) => {
        const profilePhoto = `${info.getValue().assigneeProfilePhoto}`;
        const name = `${info.getValue().assigneeName}`;

        return (
          <div className="d-flex align-items-center">
            <div>
              <img
                src={profilePhoto}
                className="rounded-circle meeting-circular-image"
                alt="user-img"
              />
            </div>
            <div className="ms-2">{name}</div>
          </div>
        );
      },
      sortingFn: (rowA: any, rowB: any, columnId: any) =>
        rowA.getValue(columnId).assigneeName?.toLowerCase() <
        rowB.getValue(columnId).assigneeName?.toLowerCase()
          ? -1
          : 1,
      header: () => <div className="fs-12-semi-bold-4C5564">Employee</div>,
      size: 0,
      minSize: 0,
    }),
    columnHelper.accessor((row) => row, {
      id: 'startDate',
      cell: (info) => {
        const startDate =
          info.getValue().eventStartedAt ?? info.getValue().userStartedAt;
        if (!startDate) {
          return <span>-</span>;
        }

        return <div>{formateDate(new Date(startDate))}</div>;
      },
      sortingFn: (rowA: any, rowB: any, columnId: any) =>
        rowA.getValue(columnId).startDate < rowB.getValue(columnId).startDate
          ? -1
          : 1,
      header: () => (
        <span className="fs-12-semi-bold-4C5564">
          {childJourneys.length &&
          childJourneys[0].mode === PlaybookMode.WORKFLOW
            ? 'Activation date'
            : 'Start date'}
        </span>
      ),
      size: 0,
      minSize: 0,
    }),
    columnHelper.accessor((row) => row, {
      id: 'eventProgress',
      enableSorting: false,
      cell: (info) => <ProgressBar bars={getEventProgress(info.getValue())} />,

      header: () => (
        <span className="fs-12-semi-bold-4C5564">Event progress</span>
      ),
      size: 0,
      minSize: 0,
    }),
    columnHelper.accessor((row) => row, {
      id: 'micrositeActivity',
      cell: (info) =>
        info.getValue().mode === PlaybookMode.IMMERSIVE ? (
          <span>{info.getValue().journeyProgress.toString()}%</span>
        ) : (
          <span>N/A</span>
        ),
      sortingFn: (rowA: any, rowB: any, columnId: any) =>
        rowA.getValue(columnId).journeyProgress <
        rowB.getValue(columnId).journeyProgress
          ? -1
          : 1,
      header: () => (
        <span className="fs-12-semi-bold-4C5564">Microsite activity</span>
      ),
      size: 0,
      minSize: 0,
    }),
    columnHelper.accessor((row) => row, {
      id: 'status',
      cell: (info) => (
        <div className="d-flex align-items-center justify-content-between">
          {info.getValue().status === JourneyStatus.DRAFT ? (
            <StatusPill variant="notStarted">Inactive</StatusPill>
          ) : info.getValue().status === JourneyStatus.NOT_STARTED ||
            info.getValue().status === JourneyStatus.IN_PROGRESS ||
            info.getValue().status === JourneyStatus.COMPLETED ? (
            <StatusPill variant="completed">Active</StatusPill>
          ) : null}
          <PlaybookActionDropdown
            className="actions-icon"
            playbook={info.getValue()}
            assigneeName={sureString(info.getValue().assigneeName, '')}
          />
        </div>
      ),

      header: () => <span className="fs-12-semi-bold-4C5564">Status</span>,
      size: 0,
      minSize: 0,
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    autoResetPageIndex: true,
    autoResetAll: true,
  });

  useEffect(() => {
    setData([...childJourneys]);
  }, [childJourneys]);

  return {
    table,
    columns,
    data,
  };
};
