import {
  Collaborators,
  CreateUpdateAction,
  DraggableEvents,
  Filter,
} from '@Shared/DS';
import { type ITemplateHome } from '@base/models/playbookHome.model';
import { AddMessageModal } from '@Shared/Components/AddMessageModal/AddMessageModal';
import { useTemplateCalendar } from './useTemplateCalendar';

import './TemplateCalendar.scss';

export const TemplateCalendar = ({
  template,
  isEditingAllowed,
}: ITemplateHome) => {
  const {
    id,
    states,
    filterItems,
    filteredCalendarEvents,
    setSelectedFilter,
    setStates,
    refreshCalendarEvents,
    editEvent,
    deleteEvent,
    createEvent,
    handleDragEnd,
  } = useTemplateCalendar();
  return (
    <div className="template-calendar px-2">
      <div className="d-flex align-items-center justify-content-between mt-4">
        {isEditingAllowed && (
          <div className="ms-auto">
            <Collaborators isParentJourney />
          </div>
        )}
      </div>

      <div className="mt-1 mb-3">
        <Filter
          items={filterItems}
          active={states.selectedFilter}
          onSelect={setSelectedFilter}
        />
      </div>

      <DraggableEvents
        events={filteredCalendarEvents}
        isParentPlaybook={true}
        handleDragEnd={handleDragEnd}
        showDeletingLoader={states.showDeletingLoader}
        createEvent={createEvent}
        editEvent={editEvent}
        deleteEvent={deleteEvent}
        isEditingAllowed={isEditingAllowed}
        getDateFromWeekDay={() => null}
      />

      <CreateUpdateAction
        showModal={states.showCreateUpdateActionPanel}
        handleShowModal={(value: boolean) =>
          setStates((prevStates) => ({
            ...prevStates,
            showCreateUpdateActionPanel: value,
          }))
        }
        builderId={id ?? ''}
        refreshEvents={refreshCalendarEvents}
        actionEvent={states.selectedEvent}
        isParentJourney={true}
        isTemplate={true}
        playbookType={template.type}
        playbookStatus={template.status}
        isEditingAllowed={isEditingAllowed}
      />

      <AddMessageModal
        showModal={states.showCreateUpdateMessagePanel}
        handleShowModal={(value: boolean) =>
          setStates((prevStates) => ({
            ...prevStates,
            showCreateUpdateMessagePanel: value,
          }))
        }
        handleSave={refreshCalendarEvents}
        builderType={template.type}
        handleUpdate={refreshCalendarEvents}
        playbookStatus={template.status}
        builderId={id ?? ''}
        isParentJourney={true}
        messageEvent={states.selectedEvent}
        assigneeEmails={[]}
        onHideModal={() =>
          setStates((prevStates) => ({
            ...prevStates,
            showCreateUpdateMessagePanel: false,
          }))
        }
        isTemplate={true}
        isEditingAllowed={isEditingAllowed}
      />
    </div>
  );
};
