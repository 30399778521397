import clsx from 'clsx';
import styles from './PlusButton.module.scss';

interface IPlusButton {
  text: string;
  onClick: () => void;
  className?: string;
  type?: 'reset' | 'submit' | 'button';
}

export const PlusButton = (props: IPlusButton) => {
  const { text, onClick, className, type } = props;

  return (
    <button
      type={type}
      className={clsx('btn', styles.plusButton, className)}
      onClick={onClick}
    >
      <i className="bi bi-plus me-1"></i>
      {text}
    </button>
  );
};
