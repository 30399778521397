import { UserType } from '@base/API';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { useNavigate } from 'react-router-dom';

export const useCompleteJourneyModal = () => {
  const navigate = useNavigate();
  const { user, getProfileData } = useUserContext();

  const navigateTo = (path: string) => {
    navigate(path);
    getProfileData();
  };

  const showCloseButton = () =>
    user.type !== UserType.ANONYMOUS && user.type !== UserType.CANDIDATE;

  return {
    showCloseButton,
    navigateTo,
  };
};
