import React, { memo, type ReactNode } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { type Placement } from 'react-bootstrap/esm/types';

import './Button.scss';

interface IButtonProps {
  id?: string;
  text: string | React.ReactNode;
  title?: string;
  onClick?: Function;
  icon?: string | ReactNode;
  disabled?: boolean;
  showLoading?: boolean;
  dataBSToggle?: string;
  tooltipPlacement?: Placement;
  additionalClasses?: string;
  type?: 'submit' | 'reset' | 'button';
  buttonStyle?:
    | 'primary'
    | 'outline'
    | 'purple'
    | 'rounded-dashed'
    | 'tertiary';
  form?: string;
}

export const Button = memo((props: IButtonProps) => {
  const {
    id,
    text,
    title,
    onClick,
    icon,
    type = 'button',
    disabled,
    showLoading,
    dataBSToggle,
    tooltipPlacement = 'top',
    additionalClasses,
    buttonStyle = 'primary',
    form,
  } = props;

  const buttonClass = `btn btn-button btn-button-${buttonStyle ?? 'primary'} ${additionalClasses || ''}`;

  return (
    <OverlayTrigger
      placement={tooltipPlacement}
      overlay={
        title ? (
          <Tooltip id={`tooltip-${id}`} style={{ position: 'fixed' }}>
            {title}
          </Tooltip>
        ) : (
          <></>
        )
      }
    >
      <button
        className={buttonClass}
        type={type}
        disabled={disabled}
        onClick={() => onClick && onClick()}
        data-bs-toggle={dataBSToggle}
        form={form}
      >
        {showLoading ? (
          <>
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>{' '}
            Loading...
          </>
        ) : (
          <>
            {typeof icon === 'string' ? <i className={`${icon}`}></i> : icon}
            {text}
          </>
        )}
      </button>
    </OverlayTrigger>
  );
});
