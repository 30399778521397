import {
  DragDropContext,
  Draggable,
  type DropResult,
  Droppable,
} from 'react-beautiful-dnd';
import { MdAdd } from 'react-icons/md';
import { type IPlaybookCalendarSchedules } from '@base/models/playbookHome.model';
import { type JourneyStatus, type User } from '@base/API';
import { EventType } from '@base/models/common.model';
import { useFeatureFlags } from '@Hooks/useFeatureFlags';
import { MessageActionCard } from '../MessageActionCard';
import { CustomDropdown } from '../CustomDropdown';
import { handleDraggableItemFocus } from './DraggableEvents.utils';
import './DraggableEvents.scss';

interface IDraggableEvents {
  events: IPlaybookCalendarSchedules[];
  isParentPlaybook: boolean;
  assignee?: User;
  showDeletingLoader: boolean;
  isEditingAllowed?: boolean;
  playbookStatus?: JourneyStatus;
  getDateFromWeekDay: (week: string, index: number) => string | null;
  handleDragEnd: (result: DropResult) => void;
  createEvent: (type: EventType, daysFrom?: number) => void;
  editEvent: (id: string, type: EventType) => void;
  deleteEvent: (id: string, type: EventType) => void;
}

export const DraggableEvents = (props: IDraggableEvents) => {
  const {
    events,
    isParentPlaybook,
    assignee,
    showDeletingLoader,
    isEditingAllowed,
    playbookStatus,
    getDateFromWeekDay,
    handleDragEnd,
    createEvent,
    editEvent,
    deleteEvent,
  } = props;
  const featureFlags = useFeatureFlags();

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      {events.map((item, weekIndex) => (
        <div className="draggable-events row mb-3 gap-2 g-0" key={weekIndex}>
          <div className="week-heading">{item.weekLabel}</div>
          {item.days.map(({ day, daysFrom, events, isStartDay }, index) => (
            <Droppable
              droppableId={daysFrom.toString()}
              key={index}
              isDropDisabled={!isEditingAllowed}
            >
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className={`col p-2 ${isStartDay ? 'start-day' : ''} ${snapshot.isDraggingOver ? 'dragging-over-column' : ''}`}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="week-heading">
                      {getDateFromWeekDay(item.weekLabel, index) ?? day}
                      {isStartDay ? ' (Start day 🌟)' : ''}
                    </h5>

                    <h5 className="workday-heading">
                      {daysFrom < 0
                        ? `${Math.abs(daysFrom)} work days before`
                        : `Work day ${daysFrom + 1}`}
                    </h5>

                    {isEditingAllowed && (
                      <CustomDropdown
                        Icon={MdAdd}
                        items={[
                          {
                            title: 'New assignee message',
                            onClick: () =>
                              createEvent(EventType.MESSAGE, daysFrom),
                          },
                          {
                            title: 'New collaborator action',
                            onClick: () =>
                              createEvent(EventType.ACTION, daysFrom),
                          },
                          {
                            title: 'Send a form',
                            onClick: () =>
                              createEvent(EventType.FORM, daysFrom),
                          },
                        ].filter(
                          (option) =>
                            featureFlags.FORMS ||
                            option.title !== 'Send collaborator a form',
                        )}
                      />
                    )}
                  </div>
                  {events.map((event, index) => (
                    <Draggable
                      key={event.id}
                      draggableId={event.id}
                      index={index}
                      isDragDisabled={!isEditingAllowed}
                      disableInteractiveElementBlocking={true}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          onClick={handleDraggableItemFocus}
                        >
                          <MessageActionCard
                            event={event}
                            editEvent={editEvent}
                            deleteEvent={deleteEvent}
                            playbookStatus={playbookStatus}
                            assigneeSlackId={assignee?.slackUserId?.toString()}
                            isParentPlaybook={isParentPlaybook}
                            isDeleting={showDeletingLoader}
                            isEditingAllowed={isEditingAllowed}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ))}
        </div>
      ))}
    </DragDropContext>
  );
};
