import { CreateFormButton, EmptyState } from '@Shared/DS';
import styles from './NoForms.module.scss';

export const NoForms = () => (
  <EmptyState
    button={<CreateFormButton />}
    description={
      <>
        <p>It looks like you haven't set up any forms or automations yet.</p>
        <ul className={styles.list}>
          <li>
            <b>Create a form</b> to start collecting valuable employee
            information,
          </li>
          <li>
            <b>Set up automations</b> to instantly use that data, send messages,
            trigger actions, and streamline your workflows.
          </li>
        </ul>
      </>
    }
    title="No activity to see here!"
  />
);
