import { CreateFormButton } from '@Shared/DS';
import './Forms.scss';
import { flexRender } from '@tanstack/react-table';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import { useForms } from './useForms';

export const Forms = () => {
  const { table, columns, data, isFetching } = useForms();
  const navigate = useNavigate();

  return (
    <div className="forms-container tab-view-container container">
      <div className="d-flex w-100 justify-content-between">
        <h2>Forms</h2>
        <CreateFormButton text="Create new form" icon="bi bi-plus" />
      </div>

      <table className="kin-table table mt-5">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  className="px-4"
                  key={header.id}
                  style={{
                    width:
                      header.getSize() !== 0 ? header.getSize() : undefined,
                  }}
                >
                  {header.isPlaceholder ? null : (
                    <span
                      {...{
                        className: header.column.getCanSort()
                          ? 'post-sorting-icon cursor-pointer select-none d-flex'
                          : '',
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                      {{
                        asc: <CaretUpFill />,
                        desc: <CaretDownFill />,
                      }[header.column.getIsSorted() as string] ?? (
                        <CaretUpFill className="initial-sorting-icon" />
                      )}
                    </span>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {data?.length ? (
            table.getRowModel().rows.map((row) => (
              <tr
                key={row.id}
                className="cursor-pointer form-row"
                onClick={() => navigate(`/form/${row.original.id}`)}
              >
                {row.getVisibleCells().map((cell) => (
                  <td
                    className="px-4 align-middle"
                    key={cell.id}
                    style={{
                      width:
                        cell.column.getSize() !== 0
                          ? cell.column.getSize()
                          : undefined,
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))
          ) : isFetching ? (
            <tr>
              <td className="text-center" colSpan={columns.length}>
                <div className="spinner-border" role="status">
                  <span className="visually-hidden text-center">
                    Loading...
                  </span>
                </div>
              </td>
            </tr>
          ) : (
            <tr>
              <td className="text-center" colSpan={columns.length}>
                No form has been created.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
