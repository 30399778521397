import { type ReactNode } from 'react';
import './WarningBanner.scss';
import WarningIcon from '@Assets/Images/warning-icon.svg';

export const WarningBanner = (props: {
  message: string | ReactNode | ReactNode[];
}) => {
  const { message } = props;

  return (
    <div className="warning-banner d-flex align-items-start">
      <img src={WarningIcon} alt="warning" />
      <div className="ms-3">{message}</div>
    </div>
  );
};
