import { Button, ParticipantTable } from '@Shared/DS';
import { AssignJourneyModal } from '@Shared/Components/AssignJourneyModal/AssignJourneyModal';
import { type Journey } from '@base/API';
import { usePlaybookParticipants } from './usePlaybookParticipants';

export const PlaybookParticipants = ({ playbook }: { playbook: Journey }) => {
  const { childPlaybooks, isLoading, states, setStates } =
    usePlaybookParticipants();

  return (
    <div className="mt-4">
      <Button
        buttonStyle="outline"
        additionalClasses="mb-3 ms-auto d-flex"
        text="Assign users"
        icon="bi bi-person-plus"
        onClick={() =>
          setStates((prevStates) => ({
            ...prevStates,
            showPlaybookAssignModal: true,
          }))
        }
      />
      <ParticipantTable
        childJourneys={childPlaybooks ?? []}
        showLoader={isLoading}
      />

      <AssignJourneyModal
        showModal={states.showPlaybookAssignModal}
        handleShowModal={(showPlaybookAssignModal: boolean) =>
          setStates((prevStates) => ({
            ...prevStates,
            showPlaybookAssignModal,
          }))
        }
        playbook={playbook}
      />
    </div>
  );
};
