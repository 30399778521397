import { listUserBadges } from '@api/apis';
import { type User } from '@base/API';
import { useQuery } from '@tanstack/react-query';

export const useUserBadges = (user: User) => {
  const { data: badges, isLoading } = useQuery({
    queryKey: [`listUserBadges-${user.id}`],
    queryFn: async () => {
      if (user.UserBadges && user.UserBadges.items.length) {
        return (
          (await listUserBadges(
            user.UserBadges.items.map((badge) => badge?.playbookId ?? ''),
          )) ?? []
        );
      }

      return [];
    },

    enabled: !!user.id,
  });

  return { badges, isLoading };
};
