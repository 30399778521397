import { Tab, Tabs } from 'react-bootstrap';
import { type IPlaybookHome, TabKeys } from '@base/models/playbookHome.model';
import { Meetings } from '@Shared/Components/Meetings/Meetings';
import { BuilderType } from '@base/API';
import { PlaybookParticipants } from '../PlaybookParticipants';
import { PlaybookCalendar } from '../PlaybookCalendar';
import { usePlaybookHomeTabs } from './usePlaybookHomeTabs';
import './PlaybookHomeTabs.scss';

export const PlaybookHomeTabs = (props: IPlaybookHome) => {
  const { key, setKey, navigateTo } = usePlaybookHomeTabs();
  const { playbook } = props;

  return (
    <Tabs
      id="playbookHomeTabs"
      className="playbook-tabs mt-2"
      activeKey={key}
      onSelect={(k) => {
        setKey(k ?? TabKeys.CALENDAR);
        if (!k || k === TabKeys.CALENDAR) {
          navigateTo(`/runbook/${playbook.id}`);
        } else if (k === TabKeys.MICROSITE) {
          navigateTo(`/runbook/${playbook.id}/microsite`);
        } else {
          navigateTo(`/runbook/${playbook.id}/${k.toLowerCase()}`);
        }
      }}
    >
      <Tab eventKey={TabKeys.CALENDAR} title={TabKeys.CALENDAR}>
        <PlaybookCalendar {...props} />
      </Tab>
      <Tab eventKey={TabKeys.MICROSITE} title={TabKeys.MICROSITE}></Tab>
      <Tab eventKey={TabKeys.MEETINGS} title={TabKeys.MEETINGS}>
        <div className="mt-5">
          <Meetings
            isEditable={true}
            journeyType={playbook.type}
            journeyStartDate={
              playbook.type === BuilderType.INITIATIVE && playbook.startDate
                ? playbook.startDate
                : null
            }
            isParentJourney={!playbook.parentJourneyID}
          ></Meetings>
        </div>
      </Tab>
      {!playbook.parentJourneyID && (
        <Tab eventKey={TabKeys.PARTICIPANTS} title={TabKeys.PARTICIPANTS}>
          <PlaybookParticipants playbook={playbook} />
        </Tab>
      )}
    </Tabs>
  );
};
