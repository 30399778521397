import { PlusButton } from '@Shared/DS/Atoms';
import { type AssigneeRole } from '@base/API';

interface IAddRolesList {
  className?: string;
  roles: string[];
  onSelectRole: (role: AssigneeRole) => void;
}

export const AddRolesList = (props: IAddRolesList) => {
  const { roles, className, onSelectRole } = props;

  return (
    <div className={`text-nowrap ${className ?? ''}`}>
      {roles.map((role) => (
        <PlusButton
          key={role}
          text={role}
          type="button"
          onClick={() => onSelectRole(role as AssigneeRole)}
        />
      ))}
    </div>
  );
};
