import './FormSubmitted.scss';

export const FormSubmitted = () => (
  <div className="form-submitted row text-center justify-content-center">
    <h4 className="col-12">Your form is complete 🙌</h4>
    <p className="col-12">You're all caught up</p>
    <img
      src={
        'https://kin-assets.s3.eu-west-1.amazonaws.com/playbook-transparent-img.png'
      }
      alt=""
    />
  </div>
);
