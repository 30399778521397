import { useQuery, useQueryClient } from '@tanstack/react-query';
import { type FormEvent, useState } from 'react';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { type User } from '@base/API';
import { Analytics } from '@base/analytics/analytics';
import { type InviteNewEmployeeParams } from '@base/models/settings.model';
import { getOrgUserCustomFields, inviteNewEmployee } from '@api/apis';
import { Keys } from '@base/keys/queryKeys';
import { SettingsPageQueryKey } from '../../../queries';
import { emptyParams } from './NewHire.const';

export const useNewHire = (handleShowModal: Function) => {
  const [inviteParams, setInviteParams] =
    useState<InviteNewEmployeeParams>(emptyParams);
  const [customFields, setCustomFields] = useState<
    Record<string, { setItemId: string; label: string }>
  >({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useUserContext();
  const queryClient = useQueryClient();

  const handleInputChange = (
    value: InviteNewEmployeeParams[keyof InviteNewEmployeeParams],
    fieldName: keyof InviteNewEmployeeParams,
  ) => {
    let processedValue = value;

    if (
      value &&
      typeof value === 'string' &&
      (fieldName === 'email' || fieldName === 'personalEmail')
    ) {
      processedValue = value.toLowerCase();
    } else {
      processedValue = value;
    }

    setInviteParams((prevData) => ({
      ...prevData,
      [fieldName]: processedValue,
    }));
  };

  const { data } = useQuery({
    queryKey: Keys.getOrgUserCustomFields(user.userOrganizationId),
    queryFn: () => getOrgUserCustomFields(user.userOrganizationId),
    staleTime: 600000,
    enabled: !!user.userOrganizationId,
  });

  const handleCustomFieldChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    fieldRefId: string,
  ) => {
    if (!data) return;

    const selectedItem = data.dataStore.columns
      .find((field) => field.refId === fieldRefId)
      ?.items.find((item) => item.label === e.target.value);

    if (selectedItem) {
      setCustomFields({
        ...customFields,
        [fieldRefId]: { setItemId: selectedItem.id, label: selectedItem.label },
      });
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitted(true);
    setIsLoading(true);
    if (validateEmails()) {
      const submissionData = {
        inviteParams,
        customFields: Object.fromEntries(
          Object.entries(customFields).map(([key, value]) => [
            key,
            { setItemId: value.setItemId },
          ]),
        ),
      };

      await inviteNewEmployee(user.userOrganizationId, submissionData);
      queryClient.invalidateQueries({
        queryKey: [SettingsPageQueryKey.listOrganizationUsers],
      });
      setIsSubmitted(false);
      setInviteParams(emptyParams);
      handleShowModal(false);
      setIsLoading(false);

      Analytics.userInvited(user.userOrganizationId, false);
    }
    setIsLoading(false);
  };

  const validateEmails = () =>
    !checkEmailAlreadyExist(inviteParams.email) &&
    !checkEmailAlreadyExist(inviteParams.personalEmail ?? '');

  const checkEmailAlreadyExist = (email: string) => {
    const users = queryClient.getQueryData([
      SettingsPageQueryKey.listOrganizationUsers,
    ]) as User[];
    return (
      users &&
      users.some((user) => user.email === email || user.personalEmail === email)
    );
  };

  return {
    inviteParams,
    isSubmitted,
    isLoading,
    data,
    customFields,
    handleCustomFieldChange,
    handleInputChange,
    checkEmailAlreadyExist,
    handleSubmit,
  };
};
