import { type Milestone } from '@API';

export const sortBlocksByOrderNumber = (milestone: Milestone): any[] => {
  if (
    milestone?.StandardContentBlocks &&
    milestone?.FreeTextQuestionBlocks &&
    milestone?.TaskBlocks &&
    milestone?.NoteBlocks &&
    milestone?.MultipleChoiceQuestionBlocks &&
    milestone?.EmbeddedContentBlocks
  ) {
    return [
      ...milestone?.StandardContentBlocks?.items,
      ...milestone?.FreeTextQuestionBlocks?.items,
      ...milestone?.TaskBlocks?.items,
      ...milestone?.NoteBlocks?.items,
      ...milestone?.MultipleChoiceQuestionBlocks?.items,
      ...milestone?.EmbeddedContentBlocks.items,
    ].sort(compareBlocks);
  }

  return [];
};

/**
 * handle reordering of the blocks
 *
 * @param source source of the block
 * @param destination destination of the block
 * @param currentMilestone current milestone
 * @param droppedItem item to be dropped
 */
export const handleReordering = (
  source: number,
  destination: number,
  currentMilestone: Milestone,
  droppedItem: any,
): Milestone => {
  if (currentMilestone.EmbeddedContentBlocks) {
    const index = currentMilestone.EmbeddedContentBlocks.items.findIndex(
      (item) => item?.id === droppedItem.id,
    );
    if (index >= 0) {
      currentMilestone.EmbeddedContentBlocks.items[index] = {
        ...droppedItem,
        orderNo: destination + 1,
      };
    }

    currentMilestone.EmbeddedContentBlocks.items.forEach((block, index) => {
      if (
        source < destination &&
        block &&
        currentMilestone.EmbeddedContentBlocks &&
        block.id !== droppedItem.id &&
        block.orderNo > source + 1 &&
        block.orderNo <= destination + 1
      ) {
        currentMilestone.EmbeddedContentBlocks.items[index] = {
          ...block,
          orderNo: block.orderNo - 1,
        };
      } else if (
        source > destination &&
        block &&
        currentMilestone.EmbeddedContentBlocks &&
        block.id !== droppedItem.id &&
        block.orderNo >= destination + 1 &&
        block.orderNo <= source
      ) {
        currentMilestone.EmbeddedContentBlocks.items[index] = {
          ...block,
          orderNo: block.orderNo + 1,
        };
      }
    });
  }

  if (currentMilestone.FreeTextQuestionBlocks) {
    const index = currentMilestone.FreeTextQuestionBlocks.items.findIndex(
      (item) => item?.id === droppedItem.id,
    );
    if (index >= 0) {
      currentMilestone.FreeTextQuestionBlocks.items[index] = {
        ...droppedItem,
        orderNo: destination + 1,
      };
    }

    currentMilestone.FreeTextQuestionBlocks.items.forEach((block, index) => {
      if (
        source < destination &&
        block &&
        currentMilestone.FreeTextQuestionBlocks &&
        block.id !== droppedItem.id &&
        block.orderNo > source + 1 &&
        block.orderNo <= destination + 1
      ) {
        currentMilestone.FreeTextQuestionBlocks.items[index] = {
          ...block,
          orderNo: block.orderNo - 1,
        };
      } else if (
        source > destination &&
        block &&
        currentMilestone.FreeTextQuestionBlocks &&
        block.id !== droppedItem.id &&
        block.orderNo >= destination + 1 &&
        block.orderNo <= source
      ) {
        currentMilestone.FreeTextQuestionBlocks.items[index] = {
          ...block,
          orderNo: block.orderNo + 1,
        };
      }
    });
  }

  if (currentMilestone.StandardContentBlocks) {
    const index = currentMilestone.StandardContentBlocks.items.findIndex(
      (item) => item?.id === droppedItem.id,
    );
    if (index >= 0) {
      currentMilestone.StandardContentBlocks.items[index] = {
        ...droppedItem,
        orderNo: destination + 1,
      };
    }

    currentMilestone.StandardContentBlocks.items.forEach((block, index) => {
      if (
        source < destination &&
        block &&
        currentMilestone.StandardContentBlocks &&
        block.id !== droppedItem.id &&
        block.orderNo > source + 1 &&
        block.orderNo <= destination + 1
      ) {
        currentMilestone.StandardContentBlocks.items[index] = {
          ...block,
          orderNo: block.orderNo - 1,
        };
      } else if (
        source > destination &&
        block &&
        currentMilestone.StandardContentBlocks &&
        block.id !== droppedItem.id &&
        block.orderNo >= destination + 1 &&
        block.orderNo <= source
      ) {
        currentMilestone.StandardContentBlocks.items[index] = {
          ...block,
          orderNo: block.orderNo + 1,
        };
      }
    });
  }

  if (currentMilestone.TaskBlocks) {
    const index = currentMilestone.TaskBlocks.items.findIndex(
      (item) => item?.id === droppedItem.id,
    );
    if (index >= 0) {
      currentMilestone.TaskBlocks.items[index] = {
        ...droppedItem,
        orderNo: destination + 1,
      };
    }

    currentMilestone.TaskBlocks.items.forEach((block, index) => {
      if (
        source < destination &&
        block &&
        currentMilestone.TaskBlocks &&
        block.id !== droppedItem.id &&
        block.orderNo > source + 1 &&
        block.orderNo <= destination + 1
      ) {
        currentMilestone.TaskBlocks.items[index] = {
          ...block,
          orderNo: block.orderNo - 1,
        };
      } else if (
        source > destination &&
        block &&
        currentMilestone.TaskBlocks &&
        block.id !== droppedItem.id &&
        block.orderNo >= destination + 1 &&
        block.orderNo <= source
      ) {
        currentMilestone.TaskBlocks.items[index] = {
          ...block,
          orderNo: block.orderNo + 1,
        };
      }
    });
  }

  if (currentMilestone.NoteBlocks) {
    const index = currentMilestone.NoteBlocks.items.findIndex(
      (item) => item?.id === droppedItem.id,
    );
    if (index >= 0) {
      currentMilestone.NoteBlocks.items[index] = {
        ...droppedItem,
        orderNo: destination + 1,
      };
    }

    currentMilestone.NoteBlocks.items.forEach((block, index) => {
      if (
        source < destination &&
        block &&
        currentMilestone.NoteBlocks &&
        block.id !== droppedItem.id &&
        block.orderNo > source + 1 &&
        block.orderNo <= destination + 1
      ) {
        currentMilestone.NoteBlocks.items[index] = {
          ...block,
          orderNo: block.orderNo - 1,
        };
      } else if (
        source > destination &&
        block &&
        currentMilestone.NoteBlocks &&
        block.id !== droppedItem.id &&
        block.orderNo >= destination + 1 &&
        block.orderNo <= source
      ) {
        currentMilestone.NoteBlocks.items[index] = {
          ...block,
          orderNo: block.orderNo + 1,
        };
      }
    });
  }

  if (currentMilestone.MultipleChoiceQuestionBlocks) {
    const index = currentMilestone.MultipleChoiceQuestionBlocks.items.findIndex(
      (item) => item?.id === droppedItem.id,
    );
    if (index >= 0) {
      currentMilestone.MultipleChoiceQuestionBlocks.items[index] = {
        ...droppedItem,
        orderNo: destination + 1,
      };
    }

    currentMilestone.MultipleChoiceQuestionBlocks.items.forEach(
      (block, index) => {
        if (
          source < destination &&
          block &&
          currentMilestone.MultipleChoiceQuestionBlocks &&
          block.id !== droppedItem.id &&
          block.orderNo > source + 1 &&
          block.orderNo <= destination + 1
        ) {
          currentMilestone.MultipleChoiceQuestionBlocks.items[index] = {
            ...block,
            orderNo: block.orderNo - 1,
          };
        } else if (
          source > destination &&
          block &&
          currentMilestone.MultipleChoiceQuestionBlocks &&
          block.id !== droppedItem.id &&
          block.orderNo >= destination + 1 &&
          block.orderNo <= source
        ) {
          currentMilestone.MultipleChoiceQuestionBlocks.items[index] = {
            ...block,
            orderNo: block.orderNo + 1,
          };
        }
      },
    );
  }

  return currentMilestone;
};

const compareBlocks = (a: any, b: any) => {
  if (a.orderNo < b.orderNo) {
    return -1;
  }
  if (a.orderNo > b.orderNo) {
    return 1;
  }
  return 0;
};
