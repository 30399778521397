import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { getApiData } from '@api/apis';
import { useScript } from '../useScript';

const KEY = process.env.REACT_APP_IFRAMELY_API_KEY;

const getHTMLToEmbed = (res: any) => {
  if (res.rel.includes('player')) {
    return res.links.player[0].html;
  }
  if (res.html) {
    return res.html;
  }
  return null;
};

export const useIframelyEmbed = (
  updatedUrl: string,
  iframeLoaded: Boolean,
  setIframeLoaded: Function,
) => {
  useScript('https://cdn.iframe.ly/embed.js?key=' + KEY);

  const [useIframely, setUseIframely] = useState<boolean>(true);
  const [showError, setShowError] = useState<boolean>(false);
  const [html, setHtml] = useState({
    __html: '<div />',
  });

  const embedIframely = useCallback(() => {
    let apiUrl = `https://cdn.iframe.ly/api/iframely?url=${encodeURIComponent(updatedUrl)}&key=${KEY}&omit_css=1&iframe=1`;
    axios(apiUrl).then(
      (res: any) => {
        let data = res.data;
        setIframeLoaded(true);
        if (!data.error) {
          let htmlToEmbed = getHTMLToEmbed(data);
          if (htmlToEmbed) {
            setHtml({ __html: htmlToEmbed });
          } else {
            setShowError(true);
          }
        } else {
          setShowError(true);
        }
      },
      () => {
        setIframeLoaded(true);
        setShowError(true);
      },
    );
  }, [setIframeLoaded, updatedUrl]);

  const verifyIfUrlIsEmbeddable = useCallback(async () => {
    getApiData(
      'organization',
      `/api/v1/verify-embedded-url/${encodeURIComponent(updatedUrl)}`,
    )
      .then((response) => {
        if (response && !response.data.status) {
          setUseIframely(true);
          embedIframely();
        } else {
          setIframeLoaded(true);
          setUseIframely(false);
        }
      })
      .catch(() => {
        setIframeLoaded(true);
        setUseIframely(false);
      });
  }, [setIframeLoaded, updatedUrl, embedIframely]);

  useEffect(() => {
    if (updatedUrl) {
      verifyIfUrlIsEmbeddable();
    }
  }, [updatedUrl, verifyIfUrlIsEmbeddable]);

  return {
    useIframely,
    html,
    showError,
  };
};
