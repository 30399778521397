import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { type StandardContentBlock } from '@API';
import { deleteBlock, updateStandardContentBlock } from '@api/apis';
import { useNonInitialEffect } from '@Hooks/useNonInitialEffect';
import { updateBlockDescription } from '@Shared/utils/utils';
import { useUserContext } from '@base/Context/UserContext/UserContext';

export const useStandardContentBlock = (
  block: StandardContentBlock,
  handleUpdateMilestoneBlocks: Function,
  handleDeleteBlock: Function,
  setShowLoader: Function,
  setShowSaved: Function,
  isParentPlaybook: boolean,
  isEditable: boolean,
  setIsSaving?: Function,
) => {
  const [title, setTitle] = useState(block.title);
  const [description, setDescription] = useState(block.description ?? '');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { user } = useUserContext();
  const { id } = useParams();

  const handleUpdateBlockDescription = async () => {
    if (isEditable && id && block.description !== description) {
      const updateDescriptionImages = await updateBlockDescription(
        description ?? '',
        block.id,
        block.type,
        block.milestoneID,
        user.userOrganizationId,
        id,
      );

      setShowLoader(true);
      await updateStandardContentBlock({
        ...block,
        description: updateDescriptionImages,
        updatedInChild: !isParentPlaybook,
      });
      handleUpdateMilestoneBlocks({
        ...block,
        description: updateDescriptionImages,
      });
      setDescription(updateDescriptionImages);
    }
    setShowLoader(false);
    setShowSaved(true);
  };

  const handleUpdateStandardContentBlock = async () => {
    if (block.title !== title) {
      setShowLoader(true);
      await updateStandardContentBlock({
        ...block,
        title,
        updatedInChild: !isParentPlaybook,
      });
      handleUpdateMilestoneBlocks({
        ...block,
        title,
      });
      setShowLoader(false);
      setShowSaved(true);
    }
  };

  const removeBlock = async () => {
    let id;
    if (isParentPlaybook) {
      id = await deleteBlock(block.id, block.type);
    } else {
      id = await updateStandardContentBlock({
        ...block,
        isArchived: true,
      });
    }

    if (id) {
      handleDeleteBlock(block.id, block.type, block.orderNo);
      setShowConfirmationModal(false);
    }
  };

  useEffect(() => {
    setTitle(block.title);
    setDescription(block.description ?? '');
  }, [block.description, block.title]);

  useNonInitialEffect(() => {
    if (block.title !== title) {
      setIsSaving?.(true);
    } else {
      setIsSaving?.(false);
    }
    const timeOut = setTimeout(() => {
      handleUpdateStandardContentBlock();
    }, 1000);

    return () => clearTimeout(timeOut);
  }, [title]);

  useNonInitialEffect(() => {
    if (id && block.description !== description) {
      setIsSaving?.(true);
    } else {
      setIsSaving?.(false);
    }
    const timeOut = setTimeout(() => {
      handleUpdateBlockDescription();
    }, 1000);

    return () => clearTimeout(timeOut);
  }, [description]);

  return {
    title,
    description,
    showConfirmationModal,
    setShowConfirmationModal,
    setTitle,
    setDescription,
    removeBlock,
  };
};
