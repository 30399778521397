import {
  filterInterestsAndSkills,
  filterInterestsAndSkillsByComparison,
} from '@Shared/utils/utils';
import './SuggestPills.scss';

const SuggestPills = (props: any) => {
  const addRemovedPill = (removedSuggestion: any) => {
    props.setSuggestOptions([...props.suggestions, removedSuggestion]);
  };

  const removeSelectedPill = (index: number) => {
    props.setSuggestOptions([
      ...props.suggestOptions.slice(0, index),
      ...props.suggestOptions.slice(index + 1, props.suggestOptions.length),
    ]);
  };
  return (
    <div className="my-3 suggest-pills-wrapper d-flex flex-wrap pills-position">
      {props.suggestOptions?.map((suggest: any, i: number) => (
        <div
          key={i}
          className={
            props.isRemove
              ? 'rounded-pill suggest-pill-remove fs-18 px-3 py-1 m-1'
              : 'rounded-pill suggest-pill-plus fs-18 px-3 py-1 m-1'
          }
          onClick={
            !props.isRemove
              ? () => {
                  let isAlreadyPresent = false;
                  for (let j = 0; j < props.selected.length; j++) {
                    isAlreadyPresent = filterInterestsAndSkillsByComparison(
                      suggest,
                      props.selected[j],
                    );
                    if (isAlreadyPresent) break;
                  }
                  if (!isAlreadyPresent) {
                    props.setSelected([suggest, ...props.selected]);
                    removeSelectedPill(i);
                    isAlreadyPresent = true;
                  }
                }
              : () => {
                  let index;
                  props.selected.map((item: any, i: number) =>
                    item === suggest ? (index = i) : i,
                  );
                  let isPresent = false;
                  const removedItem = props.selected.splice(index, 1)[0];
                  for (let j = 0; j < props.suggestions.length; j++) {
                    isPresent = filterInterestsAndSkillsByComparison(
                      removedItem,
                      props.suggestions[j],
                    );
                    if (isPresent) break;
                  }
                  if (!isPresent) addRemovedPill(removedItem);
                  props.setSelected([...props.selected]);
                }
          }
        >
          <span>
            {!props.isRemove && <i className="bi bi-plus me-1"></i>}
            {filterInterestsAndSkills(suggest)}
            {props.isRemove && <i className="bi bi-x ms-1"></i>}
          </span>
        </div>
      ))}
    </div>
  );
};

export default SuggestPills;
