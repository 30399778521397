import { addNotificationToApp } from '@Shared/utils/utils';
import {
  assignJourney,
  createActionCollaborator,
  getUserBasicData,
} from '@api/apis';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { Keys } from '@base/keys/queryKeys';
import { useQuery } from '@tanstack/react-query';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const useHomePlaybookCard = (assignedUserId?: string) => {
  const [showStartJourneyButton, setShowStartJourneyButton] = useState<
    'd-none' | 'd-block'
  >('d-none');
  const [isAssigningJourney, setIsAssigningJourney] = useState(false);
  const navigate = useNavigate();
  const { user } = useUserContext();

  const startPlaybook = useCallback(
    async (journeyId: string) => {
      setIsAssigningJourney(true);
      addNotificationToApp("We're creating a runbook just for you", 'default');

      const assignedJourneyId = await assignJourney(journeyId, [user], user.id);
      if (assignedJourneyId && assignedJourneyId.length) {
        createActionCollaborator(assignedJourneyId);
        navigate(`/runbook/${assignedJourneyId[0]}/mircosite`);
      }

      setIsAssigningJourney(false);
    },
    [navigate, user],
  );

  const { data: assignee } = useQuery({
    queryKey: Keys.getUser(assignedUserId),
    queryFn: () => {
      if (!assignedUserId) {
        return;
      }

      return getUserBasicData(assignedUserId);
    },
    enabled: !!assignedUserId,
  });

  return {
    isAssigningJourney,
    showStartJourneyButton,
    assignee,
    setShowStartJourneyButton,
    startPlaybook,
  };
};
