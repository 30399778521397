import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ExclamationTriangle } from 'react-bootstrap-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  deleteMeeting,
  deleteMeetingFromCollaborator,
  getFilteredUser,
  getJourneyData,
  getMeetings,
  getUserBasicData,
  scheduleJourneyMeetings,
  substituteDynamicTags,
  updateMeeting,
} from '@api/apis';
import { BuilderType, type Meeting, MeetingStatus, type User } from '@API';
import { addNotificationToApp } from '@Shared/utils/utils';
import {
  type SortingState,
  getSortedRowModel,
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import MeetingStatusPills from '@Shared/Components/MeetingStatusPills/MeetingStatusPill';
import TextArea from '@Shared/Components/TextArea/TextArea';
import {
  type JourneyMeeting,
  type JourneyMeetingsProps,
} from '@base/models/journeyLibrary.model';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { CustomDropdown } from '@Shared/DS';
import { Keys } from '@base/keys/queryKeys';
import { type SubstituteDynamicFieldsRequest } from '@api/api.model';
import { useDynamicTagsContext } from '@base/Context/DynamicTagsContext';
import { sureString } from '@Shared/utils/sure';

export const useMeetings = (params: JourneyMeetingsProps) => {
  const {
    isEditable,
    isTemplate,
    journeyStartDate,
    journeyType,
    isParentJourney,
  } = params;
  const { id: builderId } = useParams();
  const [showAddMeetingModal, setShowAddMeetingModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [activeSubscription, setActiveSubscription] = useState(false);
  const [isBookMeetingButtonDisabled, setIsBookMeetingButtonDisabled] =
    useState(false);
  const [data, setData] = useState<JourneyMeeting[]>([]);
  const [pendingMeetingsCount, setPendingMeetingsCount] = useState(0);
  const [assigneeStartDate, setAssigneeStartDate] = useState('');
  const [assignee, setAssignee] = useState<User | null>(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [deletingMeeting, setDeletingMeeting] = useState<JourneyMeeting | null>(
    null,
  );
  const [selectedMeeting, setSelectedMeeting] = useState({} as JourneyMeeting);
  const [sorting, setSorting] = useState<SortingState>([]);
  const { user, isParentJourneyChanged, setIsParentJourneyChanged } =
    useUserContext();
  const { setTagsValues, substituteValues } = useDynamicTagsContext();
  const queryClient = useQueryClient();

  const populateJourneyMeetings = async () => {
    if (!builderId) return;

    let meetings = await getMeetings(builderId, isTemplate || false);
    if (!meetings) return;

    if (!isTemplate && !isParentJourney) {
      handleDynamicTags(meetings, builderId);
    }

    const promises = meetings.map(
      async (meeting) => await populateJourneyUserInformation(meeting),
    );
    if (promises && promises.length === 0) {
      setIsBookMeetingButtonDisabled(true);
    }
    await Promise.all(promises).then((journeyMeetings) => {
      setData(sortMeetings([...journeyMeetings]));
    });

    return data;
  };

  const handleDynamicTags = async (
    meetings: Meeting[],
    runbookId: string,
  ): Promise<void> => {
    const texts = meetings.reduce(
      (acc, meeting) => {
        if (meeting.description) {
          acc[meeting.id] = meeting.description;
        }
        if (meeting.title) {
          acc[`${meeting.id}-title`] = meeting.title;
        }

        return acc;
      },
      {} as { [key: string]: string },
    );

    const request: SubstituteDynamicFieldsRequest = {
      orgId: user.userOrganizationId,
      data: {
        context: {
          runbook: runbookId, // This may be varied later (e.g. if we have forms attached to users or other objects,  not runbooks), but for now it’s runbook only
        },
        texts,
      },
    };
    const res = await substituteDynamicTags(request);
    if (res) {
      setTagsValues(res.texts);
    }
  };

  // query to get meetings
  const { isFetching } = useQuery({
    queryKey: Keys.getPlaybookMeetings(builderId),
    queryFn: populateJourneyMeetings,
    enabled: !!builderId,
  });

  const sortMeetings = (meetings: JourneyMeeting[]) =>
    meetings.sort((a, b) => {
      if (
        (a.attendeeEmail && a.date !== '-') ||
        (b.attendeeEmail && b.date !== '-')
      ) {
        let firstSequenceDate = new Date(a.date),
          secondSequenceDate = new Date(b.date);
        if (firstSequenceDate < secondSequenceDate) {
          return -1;
        }
        if (firstSequenceDate > secondSequenceDate) {
          return 1;
        }
        return 0;
      } else {
        let firstSequenceTitle = a.title.toLowerCase(),
          secondSequenceTitle = b.title.toLowerCase();
        if (firstSequenceTitle < secondSequenceTitle) {
          return -1;
        }
        if (firstSequenceTitle > secondSequenceTitle) {
          return 1;
        }
        return 0;
      }
    });

  const populateJourneyAssigneeData = useCallback(
    async (journeyId: string, organizationId: string) => {
      if (isTemplate) return;

      const childJourney = await getJourneyData(journeyId, organizationId);
      if (!childJourney || !childJourney.assignedUserID) return;

      const childJourneyUser = await getUserBasicData(
        childJourney.assignedUserID,
      );
      if (
        childJourneyUser &&
        childJourneyUser.startDate &&
        childJourneyUser.email
      ) {
        setAssigneeStartDate(childJourneyUser.startDate);
        setAssignee(childJourneyUser);
      }
    },
    [isTemplate],
  );

  const onHandleBookMeetings = async (): Promise<void> => {
    if (builderId) {
      setShowInfoModal(false);
      const scheduleMeeting = data
        .filter(
          (meeting) =>
            meeting.status !== MeetingStatus.SCHEDULED && meeting.attendeeEmail,
        )
        .map(
          (meeting) =>
            ({
              id: meeting.id,
              journeyID: meeting.journeyID,
              kinfolkTemplateID: meeting.kinfolkTemplateID,
              organizedByUserID: (assignee && assignee.id) ?? '',
              title: meeting.title,
              description: meeting.description,
              status: meeting.status,
              attendeesEmail: meeting.attendeeEmail
                ? [meeting.attendeeEmail]
                : [],
              duration: meeting.duration,
              parentMeetingID: meeting.parentMeetingID,
              updatedInChild: meeting.updatedInChild,
              startTime: meeting.startTime
                ? (+meeting.startTime - 1).toString()
                : meeting.startTime,
              endTime: meeting.endTime
                ? (+meeting.endTime - 1).toString()
                : meeting.endTime,
            }) as Meeting,
        );

      if (scheduleMeeting.length === 0) {
        addNotificationToApp(
          'No meetings to be scheduled, create one and try again.',
          'error',
        );
      }
      if (assigneeStartDate === '') {
        addNotificationToApp('Assignee start date is missing', 'error');
        return;
      }

      if (scheduleMeeting.length) {
        setIsBookMeetingButtonDisabled(true);
        // use journey start date for the self-serve and initiative plays types and user start date for the onboarding plays
        if (
          (journeyType === BuilderType.SELFSERVE ||
            journeyType === BuilderType.INITIATIVE) &&
          journeyStartDate
        ) {
          await scheduleJourneyMeetings(
            scheduleMeeting,
            journeyStartDate,
            user.userOrganizationId,
            user.Organization.outlookTenantId,
          );
        } else if (
          journeyType !== BuilderType.SELFSERVE &&
          journeyType !== BuilderType.INITIATIVE
        ) {
          await scheduleJourneyMeetings(
            scheduleMeeting,
            assigneeStartDate,
            user.userOrganizationId,
            user.Organization.outlookTenantId,
          );
        }
        setTimeout(async () => {
          refreshMeetings();
          setIsBookMeetingButtonDisabled(false);
          setPendingMeetingsCount(
            data.filter(
              (meeting) =>
                meeting.status !== MeetingStatus.SCHEDULED &&
                meeting.attendeeEmail,
            ).length,
          );
        }, 30000);
      }
    }
  };

  const populateJourneyUserInformation = async (
    data: Meeting,
  ): Promise<JourneyMeeting> => {
    let userDataByEmail;
    if (
      data.attendeesEmail &&
      data.attendeesEmail.length &&
      data.attendeesEmail[0]
    ) {
      userDataByEmail = await getFilteredUser({
        email: { eq: data.attendeesEmail[0] },
      });
    }
    let meetingDate: string;
    if (data.startTime && data.startTime.length > 3) {
      meetingDate = new Date(data.startTime).toDateString();
    } else {
      meetingDate = '-';
    }

    return {
      id: data.id,
      journeyID: data.journeyID || null,
      attendeePhoto: userDataByEmail?.photoUrl || '',
      attendeeName:
        userDataByEmail?.firstName && userDataByEmail.lastName
          ? `${userDataByEmail.firstName} ${userDataByEmail.lastName}`
          : userDataByEmail?.firstName || '',
      title: data.title,
      description: data.description || '',
      date: meetingDate,
      duration: data.duration,
      status: data.status,
      attendeeEmail: userDataByEmail?.email || '',
      startTime: data.startTime || '',
      endTime: data.endTime || '',
      assigneeRole: data.assigneeRole,
      parentMeetingID: data.parentMeetingID,
      updatedInChild: data.updatedInChild,
      kinfolkTemplateID: data.kinfolkTemplateID || null,
    } as JourneyMeeting;
  };

  const handleSaveMeeting = async (meetingData: Meeting) => {
    if (meetingData) {
      setIsBookMeetingButtonDisabled(false);
      refreshMeetings();
      handleParentJourneyChanged();
    }
  };

  const handleShowAddMeetingModal = (value: boolean) => {
    setShowAddMeetingModal(value);
    setSelectedMeeting({} as JourneyMeeting);
  };

  const isInPast = (date: string | null): boolean => {
    try {
      if (!date || date.length <= 3) {
        return false;
      }
      const startDate = new Date(journeyStartDate ?? assigneeStartDate);
      const meetingDate = new Date(date);
      return !!(meetingDate < startDate);
    } catch (e) {
      return false;
    }
  };

  const columnHelper = createColumnHelper<JourneyMeeting>();

  const columns = [
    columnHelper.accessor((row: JourneyMeeting) => row, {
      enableSorting: true,
      id: 'photo',
      sortingFn: (rowA: any, rowB: any, columnId) =>
        rowA
          .getValue(columnId)
          .attendeeName.toLowerCase()
          .localeCompare(rowB.getValue(columnId).attendeeName.toLowerCase()),
      cell: (info) => (
        <div className="d-flex align-items-center">
          <div>
            {info.getValue().attendeePhoto &&
              info.getValue().attendeePhoto !== '' && (
                <img
                  alt="attendee"
                  src={info.getValue().attendeePhoto}
                  className="rounded-circle meeting-circular-image"
                />
              )}
          </div>
          <div className="ms-2 attendee-profile-name">
            {info.getValue().assigneeRole && info.getValue().attendeeEmail ? (
              `${info.getValue().attendeeName} (${info.getValue().assigneeRole})`
            ) : info.getValue().assigneeRole ? (
              <strong>{`${info.getValue().assigneeRole} (unassigned)`}</strong>
            ) : (
              info.getValue().attendeeName
            )}
          </div>
        </div>
      ),
      header: () => (
        <span
          className="fs-12-semi-bold-4C5564"
          style={{ whiteSpace: 'nowrap' }}
        >
          Meeting with
        </span>
      ),
      size: 0,
      minSize: 0,
    }),
    columnHelper.accessor(
      (row) => ({
        id: row.id,
        title: row.title,
      }),
      {
        id: 'title',
        cell: (info) => {
          const title = isParentJourney
            ? info.getValue().title
            : (substituteValues(`${info.getValue().id}-title`) ??
              info.getValue().title);
          const showWarning = isInPast(info.row.original.startTime ?? null);
          return (
            <div className="row">
              <div className="col-12 meeting-attributes">
                <span>{title}</span>
                {showWarning && (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip>
                        Assignee start date is ahead of the meeting date,
                        consider rebooking
                      </Tooltip>
                    }
                  >
                    <ExclamationTriangle
                      className="ms-2 meeting-warning-icon"
                      size={18}
                    />
                  </OverlayTrigger>
                )}
              </div>
            </div>
          );
        },
        header: () => <span className="fs-12-semi-bold-4C5564">Title</span>,
        size: 200,
        minSize: 200,
      },
    ),
    columnHelper.accessor(
      (row) => ({ id: row.id, description: row.description }),
      {
        id: 'description',
        cell: (info) => {
          const description = isParentJourney
            ? sureString(info.getValue().description, '')
            : (substituteValues(info.getValue().id) ??
              info.getValue().description);
          return (
            <TextArea
              id={'meeting-description'}
              type={'text'}
              placeholder={''}
              width={''}
              defaultValue={description}
              additionalClasses={'meeting-attributes textarea-readonly'}
              fieldName={'Meeting description'}
              disabled
              borderRadius={0}
            ></TextArea>
          );
        },
        header: () => (
          <span className="fs-12-semi-bold-4C5564">Description</span>
        ),
        size: 500,
        minSize: 500,
      },
    ),
    columnHelper.accessor((row) => row.date, {
      id: 'when',
      sortingFn: (rowA: any, rowB: any, columnId) => {
        if (
          rowA.getValue(columnId) === '-' &&
          rowB.getValue(columnId) === '-'
        ) {
          return 0;
        }
        if (rowA.getValue(columnId) === '-') {
          return 1;
        }
        if (rowB.getValue(columnId) === '-') {
          return -1;
        }

        const dateA = new Date(rowA.getValue(columnId));
        const dateB = new Date(rowB.getValue(columnId));
        return dateA.getTime() - dateB.getTime();
      },
      cell: (info) => (
        <div className="row">
          <div className="meeting-attributes">
            <span>{info.getValue().toString()}</span>
          </div>
        </div>
      ),
      header: () => <span className="fs-12-semi-bold-4C5564">When</span>,
      size: 0,
      minSize: 0,
    }),
    columnHelper.accessor((row) => row.duration, {
      id: 'duration',
      cell: (info) => (
        <div className="row">
          <div className="col-7 meeting-attributes">
            <span>{info.getValue().toString()}</span>
          </div>
        </div>
      ),
      header: () => <span className="fs-12-semi-bold-4C5564">Duration</span>,
      size: 0,
      minSize: 0,
    }),
    columnHelper.accessor((row) => row, {
      id: 'status',
      cell: (info) => (
        <div className="d-flex">
          <MeetingStatusPills status={info.getValue().status} />
          {!info.getValue().attendeeEmail && (
            <OverlayTrigger
              placement={'bottom'}
              overlay={<Tooltip>{'Meeting is missing attendee.'}</Tooltip>}
            >
              <i className="bi bi-exclamation-triangle ms-1 pt-1 text-warning"></i>
            </OverlayTrigger>
          )}
        </div>
      ),
      header: () => <span className="fs-12-semi-bold-4C5564">Status</span>,
      size: 0,
      minSize: 0,
    }),
    columnHelper.accessor((row) => row, {
      enableSorting: false,
      id: 'delete',
      cell: (info) =>
        isEditable && (
          <CustomDropdown
            className="edit-btn"
            items={[
              {
                title: 'Edit',
                onClick: () => {
                  setSelectedMeeting({ ...info.getValue() });
                  setShowAddMeetingModal(true);
                },
              },
              {
                title: 'Delete',
                onClick: () => {
                  setDeletingMeeting(info.getValue());
                  setShowConfirmationModal(true);
                },
              },
            ]}
          />
        ),

      header: () => {},
      size: 50,
      minSize: 50,
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  });

  const handleDeleteMeeting = async () => {
    if (deletingMeeting) {
      const [id] = await Promise.all([
        isParentJourney || !deletingMeeting.parentMeetingID
          ? await deleteMeeting(deletingMeeting.id)
          : updateMeeting({
              ...deletingMeeting,
              status: MeetingStatus.ARCHIVED,
              updatedInChild: true,
            } as Meeting),
        deleteMeetingFromCollaborator(deletingMeeting.id, {
          journeyID: { eq: deletingMeeting.journeyID },
          assigneeRole: { eq: deletingMeeting.assigneeRole },
        }),
      ]);
      if (id) {
        const updatedData = data.filter((item) => item.id !== id);
        setData([...updatedData]);
        setDeletingMeeting(null);
        setSelectedMeeting({} as JourneyMeeting);
        handleParentJourneyChanged();
      }
    }
    refreshMeetings();
    setShowConfirmationModal(false);
  };

  const handleUpdateMeeting = async (meetingData: Meeting) => {
    const updatedMeetingIndex = data.findIndex(
      (object) => object.id === meetingData.id,
    );
    if (updatedMeetingIndex >= 0) {
      setData([...data]);
      refreshMeetings();
      setShowAddMeetingModal(false);
      setSelectedMeeting({} as JourneyMeeting);
      handleParentJourneyChanged();
    }
  };

  const onClickBookMeeting = () => {
    setPendingMeetingsCount(
      data.filter(
        (meeting) =>
          meeting.status !== MeetingStatus.SCHEDULED && meeting.attendeeEmail,
      ).length,
    );
    setShowInfoModal(true);
  };

  const refreshMeetings = () => {
    queryClient.invalidateQueries({
      queryKey: Keys.getPlaybookMeetings(builderId),
    });
  };

  const handleParentJourneyChanged = () => {
    if (isParentJourney && !isParentJourneyChanged) {
      setIsParentJourneyChanged(true);
    }
  };

  useEffect(() => {
    setActiveSubscription(!!user?.Organization?.hasSubscription);
  }, [user?.Organization?.hasSubscription]);

  useEffect(() => {
    if (builderId && user.userOrganizationId) {
      populateJourneyAssigneeData(builderId, user.userOrganizationId);
    }
  }, [builderId, user, populateJourneyAssigneeData]);

  return {
    data,
    pendingMeetingsCount,
    isBookMeetingButtonDisabled,
    showInfoModal,
    showAddMeetingModal,
    table,
    assignee,
    columns,
    showConfirmationModal,
    selectedMeeting,
    isFetching,
    activeSubscription,
    handleShowAddMeetingModal,
    handleUpdateMeeting,
    onClickBookMeeting,
    setShowAddMeetingModal,
    setShowInfoModal,
    onHandleBookMeetings,
    handleSaveMeeting,
    setShowConfirmationModal,
    handleDeleteMeeting,
  };
};
