import { addNotificationToApp } from '@Shared/utils/utils';
import { createForm, getOrgDataStores } from '@api/apis';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { Keys } from '@base/keys/queryKeys';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const formName = 'Untitled form';

export const useCreateForm = () => {
  const queryClient = useQueryClient();
  const { user } = useUserContext();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const { data: orgDataStores, isLoading: isOrgDataStoresLoading } = useQuery({
    queryKey: Keys.getOrgDataStores(user.userOrganizationId),
    queryFn: () => getOrgDataStores(user.userOrganizationId),
    enabled: !!user.userOrganizationId,
  });

  const handleCreateForm = async () => {
    if (!orgDataStores || !orgDataStores.length) {
      addNotificationToApp('Oops! data store not found.', 'error');
      return;
    }
    setIsLoading(true);
    const formId = await createForm(
      user.userOrganizationId,
      formName,
      orgDataStores[0].id,
    );
    await queryClient.invalidateQueries({
      queryKey: Keys.getOrgForms(user.userOrganizationId),
    });
    setIsLoading(false);
    navigate(`/form/${formId}`, { state: { isInEditingMode: true } });
  };

  return {
    isLoading: isLoading || isOrgDataStoresLoading,
    handleCreateForm,
  };
};
