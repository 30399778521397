export const onOrganizationByIdWithBasicData = /* GraphQL */ `
  subscription onOrganizationByIdWithBasicData($id: ID!) {
    onOrganizationById(id: $id) {
      id
      name
      slackAccessToken
      mergeCompanyToken
      status
    }
  }
`;
