import { Modal } from '@Shared/DS';
import { Form } from '../Form';
import styles from './FormPreviewModal.module.scss';

interface FormPreviewModalProps {
  formId: string;
  isOpen: boolean;
  onClose: () => void;
}

export const FormPreviewModal = ({
  isOpen,
  formId,
  onClose,
}: FormPreviewModalProps) => (
  <Modal
    size="xl"
    className={styles.formPreviewModal}
    isOpen={isOpen}
    onClose={onClose}
  >
    <Form canGoBackToForms={false} formId={formId} />
  </Modal>
);
