import { type Form, type FormTriggers } from '@models/form.model';
import { updateForm } from '@api/apis';
import { useMessengerChannel } from '@Hooks/useMessengerChannel';
import { convertHtmlToChannelSpecificFormat } from '@Shared/utils/utils';
import { useCallback } from 'react';

export const useUpdateForm = () => {
  const channel = useMessengerChannel();

  return useCallback(
    (orgId: string, form: Form) => {
      const onSubmitTriggers = form.triggers?.onSubmit?.map((trigger) =>
        trigger.action === 'SEND_MESSAGE'
          ? {
              ...trigger,
              params: {
                ...trigger.params,
                message: convertHtmlToChannelSpecificFormat(
                  trigger.params.message?.toString() ?? '',
                  channel,
                ),
              },
            }
          : trigger,
      );

      const triggers: FormTriggers = form.triggers
        ? { ...form.triggers, onSubmit: onSubmitTriggers }
        : {};

      return updateForm(orgId, { ...form, triggers });
    },
    [channel],
  );
};
