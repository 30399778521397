import { SelectDropdown } from '@Shared/DS';
import { type Badge } from '@base/API';
import { Dropdown } from 'react-bootstrap';
import { useSelectBadgeDropdown } from './useSelectBadgeDropdown';

interface ISelectBadgeDropdown {
  badges: Badge[];
  selectedBadge: Badge;
  selectBadgeHandler: (badge: Badge) => void;
}
export const SelectBadgeDropdown = ({
  badges,
  selectedBadge,
  selectBadgeHandler,
}: ISelectBadgeDropdown) => {
  const { sortBadges } = useSelectBadgeDropdown();

  return (
    <SelectDropdown>
      <Dropdown.Toggle className="col-12" id="custom-select">
        {selectedBadge.id ? (
          <>
            <img
              src={selectedBadge.iconUrl}
              alt={selectedBadge.name ?? `badge`}
              className="me-2"
              width={27}
              height={28}
            />
            {selectedBadge.name ?? ''}
          </>
        ) : (
          'Select badge'
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu className="col-12">
        {badges.sort(sortBadges).map((badge, index) => (
          <Dropdown.Item
            key={badge.id}
            eventKey={badge.id}
            onClick={() => selectBadgeHandler({ ...badge })}
          >
            <img
              src={badge.iconUrl}
              alt={badge.name ?? `badge - ${index}`}
              className="me-4"
              width={27}
              height={28}
            />{' '}
            {badge.name ?? ''}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </SelectDropdown>
  );
};
