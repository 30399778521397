import clsx from 'clsx';
import { AutomationStatus } from '../AutomationStatus';
import { type Automation } from '../ActivityPanel.types';
import styles from './AutomationsListItem.module.scss';

interface AutomationsListItemProps {
  automation: Automation;
  isBlocked: boolean;
}

export const AutomationsListItem = ({
  automation,
  isBlocked,
}: AutomationsListItemProps) => (
  <li
    className={clsx(styles.automationWrapper, { [styles.blocked]: isBlocked })}
  >
    <AutomationStatus status={automation.status} />
    <div className={styles.automation}>{automation.action}</div>
  </li>
);
