import { Icon, type IconProps } from './Icon';

export const Divider = (props: IconProps) => (
  <Icon {...props}>
    <path
      d="M16.668 1.66797H3.33464C2.41416 1.66797 1.66797 2.41416 1.66797 3.33464V6.66797C1.66797 7.58844 2.41416 8.33463 3.33464 8.33463H16.668C17.5884 8.33463 18.3346 7.58844 18.3346 6.66797V3.33464C18.3346 2.41416 17.5884 1.66797 16.668 1.66797Z"
      strokeWidth="1.25"
    />
    <path
      d="M16.668 11.668H3.33464C2.41416 11.668 1.66797 12.4142 1.66797 13.3346V16.668C1.66797 17.5884 2.41416 18.3346 3.33464 18.3346H16.668C17.5884 18.3346 18.3346 17.5884 18.3346 16.668V13.3346C18.3346 12.4142 17.5884 11.668 16.668 11.668Z"
      strokeWidth="1.25"
    />
  </Icon>
);
