import { confirmResetPassword } from 'aws-amplify/auth';
import { type FormEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { addNotificationToApp } from '@Shared/utils/utils';

export const useResetPassword = () => {
  const [userEmail, setUserEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const navigate = useNavigate();

  const handleNewPassword = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitted(true);
    if (
      userEmail !== '' &&
      verificationCode !== '' &&
      newPassword !== '' &&
      confirmPassword !== '' &&
      newPassword === confirmPassword
    ) {
      try {
        await confirmResetPassword({
          username: userEmail,
          confirmationCode: verificationCode,
          newPassword: newPassword,
        });
        navigate('/login');
      } catch (e) {
        addNotificationToApp('failed to reset password', 'error');
      }
    }
  };

  return {
    userEmail,
    verificationCode,
    newPassword,
    confirmPassword,
    isSubmitted,
    setUserEmail,
    setVerificationCode,
    setNewPassword,
    setConfirmPassword,
    handleNewPassword,
  };
};
