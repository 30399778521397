import React, { Fragment, type ReactNode } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { type FormElement } from '@models/form.model';
import { AddElementDropdownItem } from './AddElementDropdownItem';
import { AddElementDropdownToggle } from './AddElementDropdownToggle';
import { type ElementsDropdownGroup } from './AddElementDropdown.types';
import styles from './AddElementDropdown.module.scss';

interface AddElementDropdownProps {
  buttonLabel: ReactNode;
  className?: string;
  elementGroups: ElementsDropdownGroup[];
  onSelect: (element: FormElement) => void;
}

export const AddElementDropdown = ({
  buttonLabel,
  className,
  elementGroups,
  onSelect,
}: AddElementDropdownProps) => {
  const handleSelect = (eventKey: string | null) => {
    if (!eventKey) {
      return;
    }

    const [index1, index2] = eventKey.split('-');
    const groupIndex = parseInt(index1, 10);
    const elementIndex = parseInt(index2, 10);

    const element = elementGroups[groupIndex]?.items[elementIndex]?.element;

    if (element) {
      onSelect(element);
    }
  };

  return (
    <Dropdown className={className} onSelect={handleSelect}>
      <AddElementDropdownToggle label={buttonLabel} />
      <Dropdown.Menu className={styles.menu}>
        {elementGroups.map((group, groupIndex) => (
          <Fragment key={group.label}>
            <Dropdown.Header className={styles.header}>
              {group.label}
            </Dropdown.Header>
            {group.items.map((item, itemIndex) => (
              <AddElementDropdownItem
                eventKey={`${groupIndex}-${itemIndex}`}
                item={item}
                key={item.label}
              />
            ))}
          </Fragment>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
