import './Login.scss';
import { TextField } from '@Shared/DS/Molecules';
import { Link } from 'react-router-dom';
import { ResetPasswordModal } from '@Shared/DS/Organism/ResetPasswordModal/ResetPasswordModal';
import { Button, SocialProviderButton } from '@Shared/DS';
import { useLogin } from './useLogin';

export const Login = () => {
  const {
    userEmail,
    userPassword,
    isSubmitted,
    isLoading,
    showResetPasswordModal,
    setShowResetPasswordModal,
    setUserEmail,
    setUserPassword,
    handleGoogleLogin,
    handleLoginWithEmailAndPassword,
  } = useLogin();

  return (
    <div className="login-page-wrapper">
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col-md-auto">
            <div className="text-center">
              <img
                className="logo"
                src={require('../../Assets/Images/kinfolk_logo.png')}
                alt="Kinfolk logo"
              />
              <div className="heading pt-4">Welcome back 👋</div>
            </div>

            <div className="login-form">
              <SocialProviderButton
                className="mt-2 w-100"
                text="Sign in with Google"
                provider="google"
                onClick={handleGoogleLogin}
              />
              {/* <SocialProviderButton className="mt-2 w-100" text="Login with Facebook" provider="facebook" onClick={handleFacebookLogin} /> */}

              <div className="hr-text my-4">
                <span className="fs-10-25272a">Or</span>
              </div>

              <form onSubmit={(e) => handleLoginWithEmailAndPassword(e)}>
                <label className="text-start" htmlFor="email">
                  Email address
                </label>
                <TextField
                  id={'email'}
                  type="email"
                  defaultValue={userEmail}
                  setValue={setUserEmail}
                  fieldName="Email"
                  width=""
                  placeholder="Enter your work email address"
                  additionalClasses={`input-field
              ${!userEmail && userEmail.length === 0 && isSubmitted ? 'is-invalid' : ''}`}
                />

                <label
                  className="fs-14-semi-bold text-start mt-2"
                  htmlFor="password"
                >
                  Password
                </label>
                <TextField
                  id={'password'}
                  type="password"
                  defaultValue={userPassword}
                  setValue={setUserPassword}
                  fieldName="Password"
                  width=""
                  placeholder="Enter a password"
                  additionalClasses={`input-field
              ${!userPassword && userPassword.length === 0 && isSubmitted ? 'is-invalid' : ''}`}
                />

                <Button
                  text="Login"
                  showLoading={isLoading}
                  type="submit"
                  additionalClasses="mt-3 w-100"
                  disabled={isLoading}
                />
              </form>

              <div className="d-flex justify-content-center support-links mt-3">
                <Link
                  className="login-text-color"
                  to="/verify-email"
                  state={{ isForgotPassword: true, email: '' }}
                >
                  Forgot password
                </Link>
                or
                <Link className="login-text-color" to="/sign-up">
                  Sign up
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ResetPasswordModal
        showModal={showResetPasswordModal}
        handleShowModal={setShowResetPasswordModal}
        email={userEmail}
      />
    </div>
  );
};
