import { addNotificationToApp } from '@Shared/utils/utils';
import {
  updateJourney,
  updatePlaybookStartDate,
  updatePlaybookWorkflows,
} from '@api/apis';
import { type Journey, JourneyStatus } from '@base/API';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { Analytics } from '@base/analytics/analytics';
import { Keys } from '@base/keys/queryKeys';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export const usePlaybookActionDropdown = (playbook: Journey) => {
  const [states, setStates] = useState({
    showConfirmationModal: false,
    showPlaybookActivationModal: false,
    showPlaybookPauseModal: false,
    showAnchorDateModal: false,
  });
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { user } = useUserContext();
  const { id } = useParams();

  const viewPlaybook = () => {
    navigate(`/runbook/${playbook.id}`);
  };

  const archivePlaybook = async () => {
    setStates((prevStates) => ({
      ...prevStates,
      showConfirmationModal: false,
    }));
    await updateJourney({ id: playbook.id, isArchived: true } as Journey);

    addNotificationToApp('Runbook archived successfully', 'success');
    // invalidate query to fetch runbooks with updated status
    queryClient.invalidateQueries({ queryKey: Keys.getChildPlaybooks(id) });
  };

  const pausePlaybook = async () => {
    setStates((prevStates) => ({
      ...prevStates,
      showPlaybookPauseModal: false,
    }));
    const toastId = toast.loading("We're pausing the runbook.");
    const updatedPlaybookId = await updateJourney({
      id: playbook.id,
      status: JourneyStatus.DRAFT,
    } as Journey);

    if (updatedPlaybookId) {
      await updatePlaybookWorkflows(
        playbook.id,
        JourneyStatus.DRAFT,
        user.userOrganizationId,
        playbook.startDate,
      );

      if (id) {
        toast.update(toastId, {
          render: 'Runbook paused successfully.',
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        });
        // invalidate query to fetch runbooks with updated status
        queryClient.invalidateQueries({ queryKey: Keys.getChildPlaybooks(id) });
        Analytics.playbookPaused(playbook.id);
      }
    }
  };

  const changePlaybookAnchorDate = async (date: string) => {
    if (!date) {
      addNotificationToApp('Please specify anchor date.', 'error');
      return;
    }

    setStates((prevState) => ({ ...prevState, showAnchorDateModal: false }));
    const toastId = toast.loading('Updating runbook anchor date');
    await updatePlaybookStartDate(playbook.id, date);
    toast.update(toastId, {
      render: 'Anchor date updated successfully',
      isLoading: false,
      type: 'success',
      autoClose: 2000,
    });
    queryClient.invalidateQueries({ queryKey: Keys.getChildPlaybooks(id) });
  };

  return {
    states,
    setStates,
    viewPlaybook,
    archivePlaybook,
    pausePlaybook,
    changePlaybookAnchorDate,
  };
};
