import { type ElementType } from 'react';
import { Check, Clock, Slash } from '@Shared/Icons';
import { type Automation } from '../ActivityPanel.types';
import styles from './AutomationStatus.module.scss';

export const statusToIcon: Record<Automation['status'], ElementType> = {
  NEW: Clock,
  DONE: Check,
  FAILED: Slash,
};

export const statusToClassName: Record<Automation['status'], string> = {
  NEW: styles.pending,
  DONE: styles.success,
  FAILED: styles.failed,
};
