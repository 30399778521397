import { Pill } from '@Shared/DS';
import { type FormEvent } from '../ActivityPanel.types';
import { statusToPills } from './FormStatus.const';
import styles from './FormStatus.module.scss';

interface FormStatusProps {
  status: FormEvent['status'];
}

export const FormStatus = ({ status }: FormStatusProps) => (
  <div className={styles.statuses}>
    {statusToPills[status].map((props) => (
      <Pill {...props} key={props.children} />
    ))}
  </div>
);
