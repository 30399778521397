import { sureString } from '@Shared/utils/sure';
import {
  getOrgDataStores,
  getUserDetailsByIds,
  listOrgForms,
  searchPlaybookByName,
} from '@api/apis';
import { type Journey, type User } from '@base/API';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { Keys } from '@base/keys/queryKeys';
import {
  type Form,
  type TriggerAction,
  type TriggerActionParamValue,
  TriggerActionType,
} from '@base/models/form.model';
import { useQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useUpdateForm } from '@Hooks/useUpdateForm';
import {
  removeEmailsFromTriggers,
  removeNameFromFormTriggers,
} from './TriggerAction.utils';

interface State {
  showNotificationOptions: boolean;
  triggers: TriggerAction[];
  playbookOptions: Journey[];
}

export const useTriggerAction = (isEditable: boolean, form?: Form) => {
  const [state, setState] = useState<State>({
    showNotificationOptions: false,
    triggers: form?.triggers.onSubmit ?? [],
    playbookOptions: [],
  });
  const { user } = useUserContext();
  const updateForm = useUpdateForm();

  const updateState = (name: keyof State, value: State[keyof State]) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const updateTriggerAction = (
    id: string,
    field: keyof TriggerAction,
    value: TriggerAction[keyof TriggerAction],
  ) => {
    setState((prevState) => {
      const index = prevState.triggers.findIndex(
        (trigger) => trigger.id === id,
      );

      if (index < 0) {
        throw new Error('Trigger action not found.');
      }

      const updatedActions = [
        ...prevState.triggers.slice(0, index),
        { ...prevState.triggers[index], [field]: value },
        ...prevState.triggers.slice(index + 1),
      ];

      return { ...prevState, triggers: updatedActions };
    });
  };

  const addTriggerAction = (type: TriggerActionType) => {
    if (!form) {
      return;
    }

    let request = {
      id: 'action-' + Date.now(),
      action: type,
      params: {},
    };

    if (type === TriggerActionType.SET_RUNBOOK_ROLE) {
      request.params = {
        runbook: { isDataLink: true, string: 'Runbook: THIS' },
      };
    } else if (type === TriggerActionType.SEND_ACTION) {
      request.params = { actionName: '', actionDescription: '' };
    } else if (type === TriggerActionType.SEND_FORM) {
      request.params = { messageTitle: '', messageDescription: '' };
    } else if (type === TriggerActionType.SEND_EMAIL) {
      request.params = { subject: '', message: '', cc: [] };
    } else if (type === TriggerActionType.SEND_MESSAGE) {
      request.params = { subject: '', message: '' };
    } else {
      throw new Error(`Unexpected trigger type ${type}`);
    }

    state.triggers.push(request);
    setState({ ...state, showNotificationOptions: false });
    form.triggers.onSubmit = state.triggers;
  };

  const deleteTriggerAction = (id: string) => {
    if (!form) {
      return;
    }

    const filteredActions = state.triggers.filter(
      (trigger) => trigger.id !== id,
    );
    setState({ ...state, triggers: filteredActions });
    form.triggers.onSubmit = filteredActions;
  };

  const searchPlaybook = async (name: string) => {
    const playbooks = await searchPlaybookByName(user.userOrganizationId, name);
    setState({ ...state, playbookOptions: playbooks ?? [] });
  };

  const selectReceiver = (trigger: TriggerAction, users: any[]) => {
    updateTriggerAction(trigger.id, 'params', {
      ...trigger.params,
      receiver: (users[0]?.customOption
        ? { email: users[0].label }
        : users[0]?.id && users[0]?.email
          ? {
              userId: users[0]?.id,
              email: users[0]?.email,
            }
          : users[0]) as TriggerActionParamValue,
    });
  };

  const selectCC = (trigger: TriggerAction, users: any[]) => {
    updateTriggerAction(trigger.id, 'params', {
      ...trigger.params,
      cc: users.map((user) => {
        if (user.id && user.email) {
          return { userId: user.id, email: user.email };
        }

        return user;
      }),
    });
  };

  const populateUsersEmail = async (triggers: TriggerAction[]) => {
    const userIds = new Set<string>();

    triggers.forEach((trigger) => {
      const receiver = trigger.params['receiver'];
      if (
        typeof receiver === 'object' &&
        receiver !== null &&
        'userId' in receiver
      ) {
        userIds.add((receiver as { userId: string }).userId);
      }

      const cc = trigger.params['cc'];
      if (Array.isArray(cc)) {
        cc.forEach((ccItem) => {
          if (
            typeof ccItem === 'object' &&
            ccItem !== null &&
            'userId' in ccItem
          ) {
            userIds.add(ccItem.userId);
          }
        });
      }
    });

    let userDetails: User[] | undefined = [];
    if (userIds.size !== 0) {
      userDetails = await getUserDetailsByIds(Array.from(userIds));
    }

    const userIdToEmailMap: { [key: string]: string } = {};
    userDetails?.forEach((user) => {
      userIdToEmailMap[user.id] = user.email;
    });

    const updatedTriggers = triggers.map((trigger) => {
      const receiver = trigger.params['receiver'];
      const cc = trigger.params['cc'];

      if (
        typeof receiver === 'object' &&
        receiver !== null &&
        'userId' in receiver
      ) {
        const userId = (receiver as { userId: string }).userId;
        trigger.params['receiver'] = {
          ...receiver,
          email: userIdToEmailMap[userId],
        };
      } else if (typeof receiver === 'string') {
        trigger.params['receiver'] = {
          email: trigger.params['receiver'],
        } as TriggerActionParamValue;
      }

      if (Array.isArray(cc)) {
        trigger.params['cc'] = cc.map((ccItem) => {
          if (
            typeof ccItem === 'object' &&
            ccItem !== null &&
            'userId' in ccItem
          ) {
            const userId = ccItem.userId;
            return {
              ...ccItem,
              email: userIdToEmailMap[userId],
            };
          }
          return ccItem;
        });
      }

      return trigger;
    });

    setState((prevState) => ({ ...prevState, triggers: updatedTriggers }));
  };

  const populateNameIntoFormTriggers = useCallback(
    (triggers: TriggerAction[], forms: Form[]) => {
      const formIds = new Set();

      triggers.forEach((trigger) => {
        if (trigger.action === TriggerActionType.SEND_FORM) {
          let { form } = trigger.params;
          if (form && typeof form === 'object' && 'formId' in form) {
            formIds.add(form.formId);
          }
        }
      });

      const formIdToNameMap: { [key: string]: string } = {};
      forms.forEach((form) => {
        formIdToNameMap[form.id] = form.name;
      });

      const updatedTriggers = triggers.map((trigger) => {
        if (trigger.action !== TriggerActionType.SEND_FORM) {
          return trigger;
        }

        let { form } = trigger.params;
        if (form && typeof form === 'object' && 'formId' in form) {
          const formId = form.formId;
          form = { ...form, name: formIdToNameMap[formId] };
          trigger.params['form'] = form;

          return trigger;
        }

        return trigger;
      });

      setState((currentState) => ({
        ...currentState,
        triggers: updatedTriggers,
      }));
    },
    [],
  );

  const { data: orgForms } = useQuery({
    queryKey: Keys.getOrgForms(user.userOrganizationId),
    queryFn: () => listOrgForms(sureString(user.userOrganizationId)),
    enabled: !!user.userOrganizationId,
  });

  const { data: orgDataStores } = useQuery({
    queryKey: Keys.getOrgDataStores(user.userOrganizationId),
    queryFn: () => getOrgDataStores(user.userOrganizationId),
    enabled: !!user.userOrganizationId,
  });

  const dataStoreFormColumns = useMemo(() => {
    if (!orgDataStores || !form) {
      return [];
    }

    const result: { isDataLink: boolean; id: string; string: string }[] = [];

    orgDataStores.forEach((datastore) => {
      datastore.columns.forEach((column) => {
        if (column.referencedByForms.includes(form.id)) {
          result.push({
            id: column.id,
            string: `DataStore: THIS / Column: "${column.name}"`,
            isDataLink: true,
          });
        }
      });
    });

    return result;
  }, [orgDataStores, form]);

  const updateFormTriggers = useCallback(() => {
    if (!form) {
      return;
    }

    form.triggers.onSubmit = removeNameFromFormTriggers(
      removeEmailsFromTriggers(state.triggers),
    );
    updateForm(user.userOrganizationId, form);
  }, [form, state.triggers, user.userOrganizationId, updateForm]);

  useEffect(() => {
    if (!isEditable) {
      return;
    }

    const timeout = setTimeout(() => updateFormTriggers(), 1000);
    return () => clearTimeout(timeout);
  }, [state.triggers, isEditable, updateFormTriggers]);

  useEffect(() => {
    if (!form?.triggers.onSubmit) {
      return;
    }

    populateUsersEmail(form.triggers.onSubmit);
  }, [form?.triggers]);

  useEffect(() => {
    if (form?.triggers.onSubmit && orgForms) {
      populateNameIntoFormTriggers(form.triggers.onSubmit, orgForms);
    }
  }, [form?.triggers, orgForms, populateNameIntoFormTriggers]);

  return {
    state,
    orgForms,
    dataStoreFormColumns,
    searchPlaybook,
    updateState,
    addTriggerAction,
    updateTriggerAction,
    deleteTriggerAction,
    updateFormTriggers,
    selectReceiver,
    selectCC,
  };
};
