import { useMemo } from 'react';
import { type JourneyStatus, type Milestone, type PlaybookMode } from '@API';
import { FloatingMenu } from '@Shared/DS';
import { InfoModal } from '../../../Components/InfoModal/InfoModal';
import { MilestoneDraggableList } from '../../Molecules';
import './Milestones.scss';
import { useMilestones } from './useMilestones';

interface MilestoneProps {
  milestones: Milestone[];
  selectedMilestoneIndex: number;
  setSelectedMilestoneIndex: Function;
  handleMilestoneTextChange: Function;
  addNewMilestone: Function;
  removeMilestone: Function;
  handleReorderMilestones: Function;
  isEditable: boolean;
  lockChapters?: boolean;
  progress?: number;
  isBusy: boolean;
  journeyStatus?: JourneyStatus;
  mode?: PlaybookMode;
  isParentPlaybook?: boolean;
  archiveUnarchiveChapter?: (archive: boolean) => void;
}

export const Milestones = (props: MilestoneProps) => {
  const {
    milestones,
    selectedMilestoneIndex,
    setSelectedMilestoneIndex,
    handleMilestoneTextChange,
    addNewMilestone,
    removeMilestone,
    isEditable,
    handleReorderMilestones,
    isBusy,
    isParentPlaybook,
    lockChapters,
    archiveUnarchiveChapter,
  } = props;

  const {
    milestoneNames,
    showConfirmationModal,
    handleDeleteMilestone,
    setShowConfirmationModal,
    handleActiveMilestone,
    handleShowConfirmationModal,
    onDragEnd,
    handleMilestoneName,
  } = useMilestones({
    milestones,
    setSelectedMilestoneIndex,
    removeMilestone,
    handleReorderMilestones,
    selectedMilestoneIndex: 0,
    handleMilestoneTextChange,
    addNewMilestone,
    isParentPlaybook,
    isEditable,
    isBusy: false,
  });

  const floatingMenuItems = useMemo(
    () =>
      milestones.map((milestone: Milestone, index: number) => ({
        label: milestone.name,
        isSelected: selectedMilestoneIndex === index,
      })),
    [milestones, selectedMilestoneIndex],
  );

  return (
    <>
      {!isEditable && (
        <div className="px-2">
          <FloatingMenu
            fullScreen
            onSelect={(_, itemIndex) => handleActiveMilestone(itemIndex)}
            title="Contents"
            ctaAction={
              isEditable
                ? {
                    label: '+ Add Section',
                    onClick: () => addNewMilestone(),
                  }
                : undefined
            }
            items={floatingMenuItems}
          />
        </div>
      )}
      {isEditable && (
        <div className="milestones-wrapper d-none d-lg-block px-2 col-lg-3 col-xl-2">
          <div className="min-vh-100 sticky-lg-top">
            <div className="milestones mt-3">
              <div className="heading px-2">Contents</div>
              <MilestoneDraggableList
                handleActiveMilestone={handleActiveMilestone}
                handleMilestoneTextChange={handleMilestoneTextChange}
                handleShowConfirmationModal={handleShowConfirmationModal}
                isBusy={isBusy}
                isEditable={isEditable}
                milestones={milestones}
                onDragEnd={onDragEnd}
                isParentPlaybook={isParentPlaybook}
                selectedMilestoneIndex={selectedMilestoneIndex}
                handleMilestoneName={handleMilestoneName}
                milestoneNames={milestoneNames}
                lockChapters={lockChapters}
                archiveUnarchiveChapter={archiveUnarchiveChapter}
              />

              {props.isEditable && (
                <div
                  className="add-section cursor-pointer ms-3 mt-3"
                  onClick={() => addNewMilestone()}
                >
                  + Add section
                </div>
              )}
            </div>
          </div>

          <InfoModal
            showModal={showConfirmationModal}
            handleShowModal={setShowConfirmationModal}
            description={
              <div>Are you sure you want to delete this milestone?</div>
            }
            title={`Delete ${milestones.length && milestones[selectedMilestoneIndex] && milestones[selectedMilestoneIndex].name} milestone`}
            onClickConfirmButton={handleDeleteMilestone}
            isDeleteButton={true}
          />
        </div>
      )}
    </>
  );
};
