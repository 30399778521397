import sanitizeHtml from 'sanitize-html';
import { MessageTextEditor } from '@Shared/Components/TextEditor/MessageTextEditor/MessageTextEditor';
import { ToggleSwitch } from '@Shared/DS';
import {
  type DataType,
  DataValueType,
  type FormElement,
  FormElementType,
  type FormFormattingHeaderElement,
  type FormFormattingImageElement,
  type FormFormattingTextElement,
  type FormInputElementType,
  FormInputType,
} from '@base/models/form.model';
import React, { type HTMLInputTypeAttribute, type ChangeEvent } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { Numbers } from '@Shared/Icons';
import { sureString } from '@Shared/utils/sure';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import { EditableContent } from '../EditableContent/EditableContent';
import { type InputElementChangeEvent, type UploadProgress } from './useForm';

// TODO: need some more work
export const renderFormElements = (
  elements: FormElement[],
  isEditable: boolean,
  users: any[],
  uploadsProgress: UploadProgress,
  handleInputChange: (
    event: InputElementChangeEvent,
    dataType: DataType,
  ) => void,
  handleLabelChange: (value: string, idx: number) => void,
  handleTextChange: (value: string, idx: number) => void,
  handleToggleRequired: (required: boolean, idx: number) => void,
  handleRadioButtonRange: (action: 'add' | 'remove', idx: number) => void,
  handleRadioButtonRangeLabels: (
    label: string,
    rangeIndex: number,
    idx: number,
  ) => void,
  handleOptionAdd: (idx: number) => void,
  handleOptionRemove: (idx: number, optionIndex: number) => void,
  handleOptionLabelChange: (
    value: string,
    elementIndex: number,
    labelIndex: number,
  ) => void,
  handleDeleteElement: (idx: number) => void,
  findUser: (query: string) => void,
  handleSearchBarInput: (columnId: string, value: string) => void,
) =>
  elements.map((element, index) => {
    switch (element.element) {
      case 'h1':
      case 'h2':
      case 'h3':
      case 'text':
      case 'separator':
      case 'image':
        return renderElement(
          element,
          index,
          isEditable,
          handleTextChange,
          handleDeleteElement,
        );
      case 'input':
        return renderInput(
          element,
          index,
          isEditable,
          users,
          uploadsProgress,
          findUser,
          handleInputChange,
          handleLabelChange,
          handleToggleRequired,
          handleRadioButtonRange,
          handleRadioButtonRangeLabels,
          handleOptionAdd,
          handleOptionRemove,
          handleOptionLabelChange,
          handleDeleteElement,
          handleSearchBarInput,
        );
      default:
        return null;
    }
  });

const renderElement = (
  element: FormElement,
  index: number,
  isEditable: boolean,
  handleTextChange: (value: string, idx: number) => void,
  handleDeleteElement: (idx: number) => void,
) => {
  switch (element.element) {
    case 'h1':
    case 'h2':
    case 'h3':
    case 'text':
      return renderTextElement(
        element,
        index,
        isEditable,
        handleTextChange,
        handleDeleteElement,
      );
    case 'separator':
      return renderSeparatorElement(index, isEditable, handleDeleteElement);
    case 'image':
      return renderImageElement(
        element,
        index,
        isEditable,
        handleDeleteElement,
      );
    default:
      return null;
  }
};

const renderTextElement = (
  element: FormFormattingTextElement | FormFormattingHeaderElement,
  index: number,
  isEditable: boolean,
  handleTextChange: (value: string, idx: number) => void,
  handleDeleteElement: (idx: number) => void,
) => {
  const Element = element.element === 'text' ? 'p' : element.element;

  return (
    <div className="element-container text-element-container">
      {Element === 'p' && isEditable ? (
        <MessageTextEditor
          allowImageUpload={false}
          value={element.text}
          setValue={(value) => handleTextChange(value, index)}
          isEditable={isEditable}
          placeholder="Start typing here..."
          containerClassName="mb-3 mt-3"
        />
      ) : (
        <Element
          key={index}
          className="content-editable mb-2"
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(element.text, {
              allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img']),
              allowedSchemesByTag: {
                img: ['data', 'http', 'https'],
              },
            }),
          }}
          contentEditable={isEditable}
          suppressContentEditableWarning
          onBlur={(event: React.FormEvent<HTMLElement>) => {
            handleTextChange((event.target as HTMLElement).innerText, index);
          }}
        />
      )}

      {isEditable && (
        <div className="d-flex justify-content-end actions">
          <i
            className="bi bi-trash cursor-pointer"
            onClick={() => handleDeleteElement(index)}
          ></i>
        </div>
      )}
    </div>
  );
};

const renderSeparatorElement = (
  index: number,
  isEditable: boolean,
  handleDeleteElement: (idx: number) => void,
) => (
  <div className="element-container separator-element-container">
    <hr />
    {isEditable && (
      <div className="d-flex justify-content-end actions">
        <i
          className="bi bi-trash cursor-pointer"
          onClick={() => handleDeleteElement(index)}
        ></i>
      </div>
    )}
  </div>
);

const renderImageElement = (
  element: FormFormattingImageElement,
  index: number,
  isEditable: boolean,
  handleDeleteElement: (idx: number) => void,
) => (
  <div className="element-container">
    {isEditable && (
      <div className="d-flex justify-content-end actions">
        <i
          className="bi bi-trash cursor-pointer"
          onClick={() => handleDeleteElement(index)}
        ></i>
      </div>
    )}
    <img
      key={index}
      src={element.url}
      alt={element.alt}
      className="mb-4"
      style={{ textAlign: 'center' }}
    />
  </div>
);

const renderRadioButtons = (
  element: FormInputElementType,
  elementIndex: number,
  isEditable: boolean,
  handleInputChange: (event: ChangeEvent<HTMLInputElement>) => void,
  handleOptionAdd: (idx: number) => void,
  handleOptionRemove: (idx: number, optionIndex: number) => void,
  handleOptionLabelChange: (
    value: string,
    elementIndex: number,
    labelIndex: number,
  ) => void,
) => {
  const name = `radio-element-${elementIndex}`;
  const choices = element.choices ?? [];

  return (
    <div key={element.columnId ?? element.columnRefId}>
      {choices.map((choice, idx) => (
        <div
          key={idx}
          className="element-option form-check d-flex align-items-center gap-3"
        >
          <input
            type="radio"
            id={`${name}-${idx}`}
            name={element.columnId ?? element.columnRefId ?? name}
            value={choice.toString()}
            className="form-check-input"
            required={!element.isOptional}
            onChange={handleInputChange}
          />
          <label
            className="form-check-label"
            htmlFor={isEditable ? undefined : `${name}-${idx}`}
          >
            <EditableContent
              className="form-label mt-2"
              value={choice.toString()}
              setValue={(value: string) =>
                handleOptionLabelChange(value, elementIndex, idx)
              }
              isEditable={isEditable}
            />
          </label>
          {isEditable && choices.length > 1 && (
            <i
              className="bi bi-trash cursor-pointer element-option-remove"
              onClick={() => handleOptionRemove(elementIndex, idx)}
            ></i>
          )}
        </div>
      ))}

      {isEditable && (
        <i
          className="bi bi-plus-circle mt-2 fs-4 cursor-pointer"
          onClick={() => handleOptionAdd(elementIndex)}
        ></i>
      )}
    </div>
  );
};

const renderRangeRadioButtons = (
  element: FormInputElementType,
  index: number,
  isEditable: boolean,
  handleInputChange: (event: ChangeEvent<HTMLInputElement>) => void,
  handleRadioButtonRange: (action: 'add' | 'remove', idx: number) => void,
  handleRadioButtonRangeLabels: (
    label: string,
    rangeIndex: number,
    idx: number,
  ) => void,
) => {
  const { min, max, rangeLabels, columnId, columnRefId, isOptional } = element;

  const renderLinearRadioButtons = () => {
    if (typeof min !== 'number' || typeof max !== 'number') {
      return null;
    }

    const buttons = [];
    for (let i = min; i <= max; i++) {
      buttons.push(
        <div className="d-inline-block">
          <div key={i} className="form-check form-check-inline text-center">
            <label
              className="form-check-label mb-2"
              htmlFor={`${columnId ?? columnRefId}-${i}`}
            >
              {i}
            </label>
            <br />
            <input
              type="radio"
              id={`${columnId ?? columnRefId}-${i}`}
              name={columnId ?? columnRefId}
              value={i.toString()}
              className="form-check-input position-static m-0"
              required={!isOptional}
              onChange={handleInputChange}
            />
          </div>
        </div>,
      );
    }
    return buttons;
  };

  if (typeof min !== 'number' || typeof max !== 'number' || !rangeLabels) {
    return null; // Or you could render an error message
  }

  return (
    <div className="d-flex justify-content-center align-items-top" key={index}>
      {rangeLabels && (
        <>
          <EditableContent
            className="radiorange-label text-center me-4"
            value={rangeLabels[min] ?? ''}
            setValue={(value: string) =>
              handleRadioButtonRangeLabels(value, min, index)
            }
            isEditable={isEditable}
          />
          {isEditable && (
            <i
              className="bi bi-dash-circle cursor-pointer fs-4 me-1 change-range-radio-button"
              onClick={() => handleRadioButtonRange('remove', index)}
            ></i>
          )}
        </>
      )}
      <div className="d-flex flex-wrap justify-content-evenly gap-3 pb-1">
        {renderLinearRadioButtons()}
      </div>

      {rangeLabels && (
        <>
          {isEditable && (
            <i
              className="bi bi-plus-circle fs-4 ms-1 cursor-pointer change-range-radio-button"
              onClick={() => handleRadioButtonRange('add', index)}
            ></i>
          )}
          <EditableContent
            className="radiorange-label text-center ms-4"
            value={rangeLabels[max] ?? ''}
            setValue={(value: string) =>
              handleRadioButtonRangeLabels(value, max, index)
            }
            isEditable={isEditable}
          />
        </>
      )}
    </div>
  );
};

const renderInput = (
  element: FormInputElementType,
  index: number,
  isEditable: boolean,
  users: any[],
  uploadsProgress: UploadProgress,
  findUser: (query: string) => void,
  handleInputChange: (
    event: InputElementChangeEvent,
    dataType: DataType,
  ) => void,
  handleLabelChange: (value: string, idx: number) => void,
  handleToggleRequired: (required: boolean, idx: number) => void,
  handleRadioButtonRange: (action: 'add' | 'remove', idx: number) => void,
  handleRadioButtonRangeLabels: (
    label: string,
    rangeIndex: number,
    idx: number,
  ) => void,
  handleOptionAdd: (idx: number) => void,
  handleOptionRemove: (idx: number, optionIndex: number) => void,
  handleOptionLabelChange: (
    value: string,
    elementIndex: number,
    labelIndex: number,
  ) => void,
  handleDeleteElement: (idx: number) => void,
  handleSearchBarInput: (columnId: string, value: string) => void,
) => {
  const handleChange = (event: InputElementChangeEvent) =>
    handleInputChange(event, element.type);

  return (
    <div key={index} className="mb-4 element-container input-element-container">
      <div className="d-flex justify-content-between align-items-center gap-3">
        <EditableContent
          className="form-label"
          value={element.label}
          setValue={(value: string) => handleLabelChange(value, index)}
          // onBlur={handleUpdateForm}
          isEditable={isEditable}
        />
        {!isEditable && !element.isOptional && (
          <span className="rounded-pill required-pill">Required</span>
        )}
        {isEditable && (
          <div className="d-flex align-items-center actions">
            <span className="text-muted req-label">Required</span>
            <ToggleSwitch
              initialValue={!element.isOptional}
              setValue={() => handleToggleRequired(!element.isOptional, index)}
            />
            <span className="mx-1">|</span>
            <i
              className="bi bi-trash cursor-pointer"
              onClick={() => handleDeleteElement(index)}
            ></i>
          </div>
        )}
      </div>
      {element.input === 'textarea'
        ? renderTextArea(element, isEditable, handleChange)
        : element.input === FormInputType.RADIOBUTTONS
          ? renderRadioButtons(
              element,
              index,
              isEditable,
              handleChange,
              handleOptionAdd,
              handleOptionRemove,
              handleOptionLabelChange,
            )
          : element.input === FormInputType.RANGE_RADIOBUTTONS
            ? renderRangeRadioButtons(
                element,
                index,
                isEditable,
                handleChange,
                handleRadioButtonRange,
                handleRadioButtonRangeLabels,
              )
            : element.input === FormInputType.BOOL_DROPDOWN
              ? renderBooleanDropdown(element, isEditable, handleChange)
              : element.choices && element.input === FormInputType.CHECKBOXES
                ? renderCheckboxes(
                    element,
                    index,
                    isEditable,
                    handleChange,
                    handleOptionAdd,
                    handleOptionRemove,
                    handleOptionLabelChange,
                  )
                : element.choices && element.input === 'dropdown'
                  ? renderDropdown(
                      element,
                      index,
                      isEditable,
                      handleChange,
                      handleOptionAdd,
                      handleOptionLabelChange,
                    )
                  : element.input === FormInputType.SEARCHBAR
                    ? renderSearchBar(
                        element,
                        index,
                        isEditable,
                        users,
                        findUser,
                        handleSearchBarInput,
                      )
                    : renderTextInput(
                        element,
                        isEditable,
                        uploadsProgress,
                        handleChange,
                      )}
    </div>
  );
};

const renderTextArea = (
  element: FormInputElementType,
  isEditable: boolean,
  handleInputChange: (event: ChangeEvent<HTMLTextAreaElement>) => void,
) => (
  <textarea
    key={element.columnId ?? element.columnRefId}
    id={element.columnId ?? element.columnRefId}
    name={element.columnId ?? element.columnRefId}
    maxLength={element.limit}
    className="w-100"
    placeholder={element.placeholder ?? ''}
    required={!element.isOptional}
    onChange={handleInputChange}
    rows={5}
  />
);

const renderSearchBar = (
  element: FormInputElementType,
  index: number,
  isEditable: boolean,
  users: any[],
  findUser: (query: string) => void,
  handleSearchBarInput: (columnId: string, value: string) => void,
) => (
  <AsyncTypeahead
    id="recipient"
    key={index}
    isLoading={false}
    onSearch={findUser}
    minLength={0}
    labelKey={(option: any) =>
      option.firstName
        ? `${option.firstName} ${option.lastName}`
        : (option.email ?? '')
    }
    options={users.filter((user) => !user.role)}
    onChange={(users: any[]) =>
      handleSearchBarInput(
        element.columnId ?? element.columnRefId ?? '',
        users[0]?.id,
      )
    }
    placeholder=""
    inputProps={{ style: { padding: '11px 8px' } }}
    renderMenuItemChildren={(option: any) => (
      <>
        <span>
          {option.firstName && option.firstName !== ''
            ? `${option.firstName}  ${option.lastName}<${option.email}>`
            : option.email}
        </span>
      </>
    )}
  />
);

const renderCheckboxes = (
  element: FormInputElementType,
  elementIndex: number,
  isEditable: boolean,
  handleInputChange: (event: ChangeEvent<HTMLInputElement>) => void,
  handleOptionAdd: (idx: number) => void,
  handleOptionRemove: (idx: number, optionIndex: number) => void,
  handleOptionLabelChange: (
    value: string,
    elementIndex: number,
    labelIndex: number,
  ) => void,
) => {
  const name = `radio-element-${elementIndex}`;
  const choices = element.choices ?? [];

  return (
    <>
      {choices.map((choice, idx) => (
        <div
          key={idx}
          className="element-option d-flex align-items-center gap-3"
        >
          <input
            type="checkbox"
            id={`${name}-${idx}`}
            name={element.columnId ?? element.columnRefId ?? name}
            value={choice.toString()}
            placeholder={element.placeholder ?? ''}
            required={!element.isOptional}
            onChange={handleInputChange}
          />
          <label
            className="form-check-label"
            htmlFor={isEditable ? undefined : `${name}-${idx}`}
          >
            <EditableContent
              className="form-label mt-2"
              value={choice.toString()}
              setValue={(value: string) =>
                handleOptionLabelChange(value, elementIndex, idx)
              }
              isEditable={isEditable}
            />
          </label>
          {isEditable && choices.length > 1 && (
            <i
              className="bi bi-trash cursor-pointer element-option-remove"
              onClick={() => handleOptionRemove(elementIndex, idx)}
            ></i>
          )}
        </div>
      ))}

      {isEditable && (
        <i
          className="bi bi-plus-circle mt-2 fs-4 cursor-pointer"
          onClick={() => handleOptionAdd(elementIndex)}
        ></i>
      )}
    </>
  );
};

const renderDropdown = (
  element: FormInputElementType,
  index: number,
  isEditable: boolean,
  handleInputChange: (event: ChangeEvent<HTMLSelectElement>) => void,
  handleOptionAdd: (idx: number) => void,
  handleOptionLabelChange: (
    value: string,
    elementIndex: number,
    labelIndex: number,
  ) => void,
) => (
  <>
    <select
      id={element.columnId ?? element.columnRefId}
      className="w-100"
      name={element.columnId ?? element.columnRefId}
      disabled={isEditable}
      onChange={handleInputChange}
    >
      <option value="">Select...</option>
      {element.choices?.map((choice, idx) =>
        typeof choice === 'string' ? (
          <option key={idx} value={choice}>
            {choice}
          </option>
        ) : (
          <option key={idx} value={choice.toString()}>
            {choice.toString()}
          </option>
        ),
      )}
    </select>

    {isEditable && (
      <>
        {element.choices?.map((choice, idx) => (
          <div className="d-flex align-items-center gap-1">
            <span>{idx + 1}.</span>
            <EditableContent
              className="form-label mt-2"
              value={choice.toString()}
              setValue={(value: string) =>
                handleOptionLabelChange(value, index, idx)
              }
              isEditable={isEditable}
            />
          </div>
        ))}

        <i
          className="bi bi-plus-circle mt-2 fs-4 cursor-pointer"
          onClick={() => handleOptionAdd(index)}
        ></i>
      </>
    )}
  </>
);

const renderBooleanDropdown = (
  element: FormInputElementType,
  isEditable: boolean,
  handleInputChange: (event: ChangeEvent<HTMLSelectElement>) => void,
) => (
  <select
    id={element.columnId ?? element.columnRefId}
    className="w-100"
    name={element.columnId ?? element.columnRefId}
    onChange={handleInputChange}
  >
    <option value="">Select...</option>
    <option value={'yes'}>Yes</option>
    <option value={'no'}>No</option>
  </select>
);

const dataTypeToInputType: { [K in DataType]?: HTMLInputTypeAttribute } = {
  [DataValueType.EMAIL]: 'email',
  [DataValueType.URL]: 'url',
  [DataValueType.DATE]: 'date',
  [DataValueType.INTEGER]: 'number',
  [DataValueType.DECIMAL]: 'number',
  [DataValueType.FILE]: 'file',
};

const dataTypeToPlaceholder: { [K in DataType]?: string } = {
  [DataValueType.INTEGER]: 'Enter a number',
  [DataValueType.DECIMAL]: 'Enter a number',
  [DataValueType.STRING]: 'Enter text',
};

const renderTextInput = (
  element: FormInputElementType,
  isEditable: boolean,
  uploadsProgress: UploadProgress,
  handleInputChange: (event: ChangeEvent<HTMLInputElement>) => void,
) => {
  const elementId = element.columnId ?? element.columnRefId;
  const uploadProgress = uploadsProgress[sureString(elementId, '')];
  const progressbarLabel = uploadProgress
    ? uploadProgress < 100
      ? `${uploadProgress}%`
      : 'Upload complete'
    : undefined;
  return (
    <>
      <div className="input-wrapper">
        {element.type === DataValueType.INTEGER && (
          <Numbers className="input-icon" />
        )}
        <input
          type={dataTypeToInputType[element.type] ?? 'text'}
          id={element.columnId ?? element.columnRefId}
          name={elementId}
          maxLength={element.limit}
          className="w-100"
          min={element.min}
          accept={
            dataTypeToInputType[element.type] === 'file' ? 'image/*' : undefined
          } // accept only images until we have a proper and more secure solution for uploading files
          max={element.max}
          placeholder={
            element.placeholder ?? dataTypeToPlaceholder[element.type] ?? ''
          }
          required={!element.isOptional}
          onChange={handleInputChange}
        />
      </div>
      {uploadProgress ? (
        <ProgressBar
          additionalClasses="mt-2"
          bars={[
            {
              percentage: uploadProgress,
              bg: 'bg-success',
              label: progressbarLabel,
            },
          ]}
        />
      ) : null}
    </>
  );
};

export const createNewElement = (element: FormElement): FormElement => {
  switch (element.element) {
    case FormElementType.INPUT:
      return {
        element: FormElementType.INPUT,
        input: element.input,
        type: element.type,
        min: element.min,
        max: element.max,
        rangeLabels: element.rangeLabels,
        choices: element.choices ?? [],
        label: 'Start typing your question...',
        isOptional: true,
        placeholder: element.placeholder,
      };
    case FormElementType.TEXT:
      return {
        element: FormElementType.TEXT,
        text: '',
        format: 'markdown',
      };
    case FormElementType.H1:
      return {
        element: FormElementType.H1,
        text: 'Heading 1',
      };
    case FormElementType.H2:
      return {
        element: FormElementType.H2,
        text: 'Heading 2',
      };
    case FormElementType.H3:
      return {
        element: FormElementType.H3,
        text: 'Heading 3',
      };
    case FormElementType.SEPARATOR:
      return {
        element: FormElementType.SEPARATOR,
      };
    case FormElementType.IMAGE:
      return {
        element: FormElementType.IMAGE,
        url: 'https://via.placeholder.com/150',
        alt: '',
      };
    default:
      throw new Error('Unsupported element type');
  }
};
