import { useEffect, useState } from 'react';

export const useLastOnboardingPage = (userProfilePhoto: string) => {
  const [userPhoto, setUserPhoto] = useState(userProfilePhoto);

  useEffect(() => {
    setUserPhoto(userProfilePhoto);
  }, [userProfilePhoto]);

  return {
    userPhoto,
  };
};
