import { Dropdown, Form } from 'react-bootstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import {
  ActionDueDate,
  AddRolesList,
  Button,
  PanelLoadingState,
  SelectDropdown,
  SwitchButton,
  TextField,
} from '@Shared/DS';
import { Channel } from '@API';
import { MessageTextEditor } from '@Shared/Components/TextEditor/MessageTextEditor/MessageTextEditor';
import { useSearch } from '@Hooks/useSearch';
import { useEvents } from '@Hooks/useEvents';
import { FormPreviewModal } from '@Shared/Components/FormPreviewModal';
import { type ICreateUpdateAction } from '@models/journeyLibrary.model';
import { NoForms } from '../NoForms';
import { useCreateUpdateFormEventPanel } from './useCreateUpdateFormEventPanel';

export const CreateUpdateFormEventPanelContent = (
  props: ICreateUpdateAction,
) => {
  const { handleShowModal } = props;
  const { users, isSearching, findUser } = useSearch();
  const { daysFromOptions } = useEvents();
  const {
    state,
    forms,
    areFormsLoading,
    setState,
    isEditable,
    handleSelectedAssignee,
    handleSelectRole,
    handleSubmit,
    handleAddReminder,
    handleRemoveReminder,
    user,
  } = useCreateUpdateFormEventPanel(props);

  if (areFormsLoading) {
    return <PanelLoadingState />;
  }

  if (forms?.length === 0) {
    return <NoForms />;
  }

  return (
    <form onSubmit={handleSubmit}>
      <Form.Group className="mb-4">
        <label className="text-start form-label" htmlFor="form-event">
          Pick a form<span className="required-field-sign">*</span>
        </label>
        <SelectDropdown id="form-event">
          <Dropdown.Toggle
            className={`col-12 ${!state.selectedForm?.id && state.isSubmitted ? 'is-invalid' : ''}`}
            id="custom-select"
            disabled={!isEditable}
          >
            {state.selectedForm?.name ?? 'Select form'}
          </Dropdown.Toggle>

          <Dropdown.Menu className="col-12">
            {forms?.map((form) => (
              <Dropdown.Item
                key={form.id}
                eventKey={form.id}
                onClick={() => setState({ ...state, selectedForm: form })}
              >
                {form.name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
          <div className="invalid-feedback text-start">
            Please select a form
          </div>
        </SelectDropdown>

        {state.selectedForm?.id && (
          <Button
            text={'Preview form'}
            icon="bi bi-eye"
            buttonStyle="outline"
            additionalClasses="mt-1"
            onClick={() => setState({ ...state, showPreviewFormModal: true })}
          />
        )}
      </Form.Group>

      <hr className="mb-4" />

      <Form.Group className="mb-4">
        <label className="text-start form-label" htmlFor="notification-channel">
          Notification channel
        </label>
        <SwitchButton
          textOn="Email"
          textOff={
            user.Organization?.slackAccessToken
              ? 'Slack'
              : user.Organization?.msTeamsTenantId
                ? 'Teams'
                : 'Messenger'
          }
          variant="secondary"
          selected={
            state.channel?.charAt(0) + state.channel?.slice(1).toLowerCase()
          }
          handleToggle={(channel) =>
            setState({ ...state, channel: channel.toUpperCase() as Channel })
          }
        />
      </Form.Group>

      <Form.Group className="mb-4">
        <label className="text-start form-label" htmlFor="title">
          Title<span className="required-field-sign">*</span>
        </label>
        <TextField
          id="title"
          type="text"
          defaultValue={state.title}
          setValue={(value: string) => setState({ ...state, title: value })}
          disabled={!isEditable}
          fieldName="Title"
          placeholder="What needs to be done"
          additionalClasses={`${!state.title?.length && state.isSubmitted ? 'is-invalid' : ''}`}
        />
      </Form.Group>

      <Form.Group className="mb-4">
        <label className="text-start form-label" htmlFor="description">
          Description
        </label>
        <MessageTextEditor
          value={state.description}
          setValue={(description) => setState({ ...state, description })}
          theme="snow"
          isEditable={isEditable}
          placeholder="Add a description"
          extraVerticalSpacing={state.channel !== Channel.EMAIL}
        />
      </Form.Group>
      <div className="search-user-input mb-2">
        <label className="text-start form-label" htmlFor="recipient">
          Recipient<span className="required-field-sign">*</span>
        </label>
        <AsyncTypeahead
          id="recipient"
          allowNew
          newSelectionPrefix="Add email: "
          isLoading={isSearching}
          selected={state.formAssignee ? [{ ...state.formAssignee }] : []}
          onSearch={findUser}
          disabled={!isEditable}
          minLength={0}
          labelKey={(option: any) =>
            option.name ??
            (option.firstName
              ? `${option.firstName} ${option.lastName}${option.role ? ` (${option.role})` : ''}`
              : (option.role ?? option.email ?? ''))
          }
          onChange={handleSelectedAssignee}
          options={users}
          placeholder="Start typing a name or email"
          inputProps={{
            style: {
              borderRadius: '10px',
              padding: '8px 20px',
            },
          }}
          renderMenuItemChildren={(option: any) => (
            <>
              <span>
                {option.firstName && option.firstName !== ''
                  ? `${option.firstName}  ${option.lastName}<${option.email}>`
                  : option.email
                    ? ` ${option.email}`
                    : `${option.role}`}
              </span>
            </>
          )}
        />

        {state.channel === Channel.EMAIL && (
          <span
            className="cc-option"
            onClick={() => setState({ ...state, showCCOption: true })}
          >
            Cc
          </span>
        )}
      </div>
      <AddRolesList
        className="d-flex flex-wrap gap-2 mb-4"
        roles={state.collaboratorRoles}
        onSelectRole={(role) => handleSelectRole(role)}
      />
      {!state.formAssignee && state.isSubmitted && (
        <div className="error-text text-start">Recipient is missing</div>
      )}

      {(state.showCCOption || state.formAssigneeCC?.length) &&
        state.channel === Channel.EMAIL && (
          <Form.Group className="mb-4">
            <label htmlFor="cc" className="form-label">
              Cc
            </label>
            <AsyncTypeahead
              multiple={true}
              id="cc-recipient"
              allowNew
              isLoading={isSearching}
              selected={state.formAssigneeCC ? state.formAssigneeCC : []}
              onSearch={findUser}
              minLength={0}
              disabled={!isEditable}
              placeholder="Start typing a name or email"
              newSelectionPrefix="Add email: "
              labelKey={(option: any) =>
                option.firstName
                  ? `${option.firstName} ${option.lastName}`
                  : option.email
                    ? `${option.email}`
                    : (option.role ?? option.label ?? '')
              }
              onChange={(users: any[]) =>
                setState({ ...state, formAssigneeCC: users })
              }
              options={users}
              renderMenuItemChildren={(option: any) => (
                <>
                  <span>
                    {option.firstName && option.firstName !== ''
                      ? `${option.firstName}  ${option.lastName}<${option.email}>`
                      : option.email
                        ? ` ${option.email}`
                        : `${option.role}`}
                  </span>
                </>
              )}
            />
          </Form.Group>
        )}

      <Form.Group className="mb-2">
        <ActionDueDate
          daysFromOptions={daysFromOptions}
          selectedDaysFrom={state.selectedDaysFrom}
          onSelectDay={(selectedDaysFrom) =>
            setState({ ...state, selectedDaysFrom })
          }
          reminders={state.reminders}
          setReminders={(callback: any) =>
            setState({ ...state, reminders: callback(state.reminders) })
          }
          onSelectReminderDay={handleAddReminder}
          onRemoveReminder={handleRemoveReminder}
        />
      </Form.Group>
      <Form.Text>Reminders are automatically sent on the due date</Form.Text>
      <div className="footer">
        <div className="d-flex gap-2 justify-content-end">
          <Button
            buttonStyle="outline"
            text="Cancel"
            onClick={() => handleShowModal(false)}
          />
          <Button
            showLoading={state.showSavingLoader}
            disabled={state.showSavingLoader}
            text="Submit"
            type="submit"
          />
        </div>
      </div>

      {state.selectedForm?.id && (
        <FormPreviewModal
          formId={state.selectedForm.id}
          isOpen={state.showPreviewFormModal}
          onClose={() =>
            setState((currentState) => ({
              ...currentState,
              showPreviewFormModal: false,
            }))
          }
        />
      )}
    </form>
  );
};
