import { Fragment, useMemo, useState } from 'react';
import { FormsListItem } from '../FormsListItem';
import { type FormEvent } from '../ActivityPanel.types';
import { findFailedFormEvent, toRootFormEvents } from './FormsList.utils';
import styles from './FormsList.module.scss';

interface FormsListProps {
  formEvents: FormEvent[];
}

export const FormsList = ({ formEvents }: FormsListProps) => {
  const rootFormEvents = useMemo(
    () => toRootFormEvents(formEvents),
    [formEvents],
  );

  const [expandedFormEventId, setExpandedFormEventId] = useState(
    () => findFailedFormEvent(rootFormEvents)?.id ?? null,
  );

  const toggleExpandedFormEvent = (id: string) =>
    setExpandedFormEventId((expandedId) => (expandedId === id ? null : id));

  return (
    <ul className={styles.formsList}>
      {rootFormEvents.map((formEvent) => (
        <Fragment key={formEvent.id}>
          <FormsListItem
            formEvent={formEvent}
            isExpanded={expandedFormEventId === formEvent.id}
            isLastForm={formEvent.childFormEvents.length === 0}
            onToggle={() => toggleExpandedFormEvent(formEvent.id)}
          />
          {formEvent.childFormEvents.map((childFormEvent, index) => (
            <FormsListItem
              formEvent={childFormEvent}
              isExpanded={expandedFormEventId === childFormEvent.id}
              isLastForm={index === formEvent.childFormEvents.length - 1}
              key={childFormEvent.id}
              onToggle={() => toggleExpandedFormEvent(childFormEvent.id)}
            />
          ))}
        </Fragment>
      ))}
    </ul>
  );
};
