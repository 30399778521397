import { useEffect, useState } from 'react';

export const useDateModal = (currentDate: string) => {
  const [state, setState] = useState({ date: '' });

  useEffect(() => {
    setState((state) => ({ ...state, date: currentDate }));
  }, [currentDate]);

  return {
    state,
    setState,
  };
};
