import clsx from 'clsx';
import { Modal, Row, Col } from 'react-bootstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { type AssignJourneyModalProps } from '@Shared/utils/utils';
import { Button, TextField } from '@Shared/DS';
import { BuilderType } from '@base/API';
import styles from './AssignJourneyModal.module.scss';
import { useAssignJourneyModal } from './useAssignJourneyModal';

export const AssignJourneyModal = (props: AssignJourneyModalProps) => {
  const {
    assignees,
    isLoadingAssignee,
    selectedAssignees,
    isSubmitted,
    isAssigning,
    startDate,
    playbook,
    setStartDate,
    searchAssignee,
    handleHideModal,
    handleSelectedAssignee,
    handleAssignJourney,
  } = useAssignJourneyModal(props);

  return (
    <Modal
      size="lg"
      fullscreen={'md-down'}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      show={props.showModal}
      onHide={handleHideModal}
    >
      <Modal.Header>
        <Modal.Title>
          <div className="fs-24-semi-bold-25272A">Assign runbook</div>
          <p className="fs-15-25272A">
            They won't be notified until you click Activate in the runbook.
          </p>
        </Modal.Title>
        <button
          type="button"
          className="btn-close modal-close-btn"
          data-bs-dismiss="modal"
          onClick={handleHideModal}
          aria-label="Close"
        ></button>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <form id="assign-journey-from" onSubmit={handleAssignJourney}>
              <label
                className="fs-14-semi-bold col-12 text-start"
                htmlFor={'assignUser'}
              >
                Assign users
              </label>
              <AsyncTypeahead
                id="assignUser"
                className={clsx({
                  [styles.invalidField]:
                    !selectedAssignees.length && isSubmitted,
                })}
                isLoading={isLoadingAssignee}
                onSearch={searchAssignee}
                minLength={3}
                multiple={true}
                labelKey={(option: any) =>
                  `${option.firstName ?? ''} ${option.lastName ?? ''}<${option.email}>`
                }
                onChange={handleSelectedAssignee}
                options={assignees}
                placeholder="Search on email or name"
                renderMenuItemChildren={(option: any) =>
                  option.firstName || option.lastName ? (
                    <span>{`${option.firstName ?? ''} ${option.lastName ?? ''}<${option.email}>${
                      option.disabled ? '(already assigned)' : ''
                    }`}</span>
                  ) : (
                    <span>{`${option.email}`}</span>
                  )
                }
              />
              {!selectedAssignees.length && isSubmitted && (
                <div className="fs-14-bb242b text-start">
                  At least one assignee is required
                </div>
              )}
              {playbook.type !== BuilderType.INITIATIVE ? (
                <>
                  <label className="mt-3" htmlFor="startDate">
                    Anchor date <i className="bi bi-question-circle-fill"></i>
                  </label>
                  <select
                    className="form-select"
                    aria-label="Disabled selected anchor date"
                    disabled
                  >
                    <option selected>
                      {playbook.type === BuilderType.SELFSERVE
                        ? 'When assignee first views microsite'
                        : playbook.type === BuilderType.ONBOARDING
                          ? 'On new hire start date'
                          : null}
                    </option>
                  </select>
                </>
              ) : (
                <>
                  <label className="mt-3" htmlFor="startDate">
                    <i className="bi bi-question-circle-fill"></i> Anchor date
                    <span className="required-sign">*</span>
                  </label>
                  <TextField
                    id={'startDate'}
                    type={'date'}
                    placeholder={'Enter start date'}
                    defaultValue={startDate}
                    setValue={setStartDate}
                    fieldName={'Anchor date'}
                    additionalClasses={clsx({
                      'is-invalid': !startDate && isSubmitted,
                    })}
                  ></TextField>
                </>
              )}
            </form>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-end">
          <Button
            buttonStyle="outline"
            text="Cancel"
            onClick={handleHideModal}
          />
          <Button
            text="Assign"
            additionalClasses="ms-2"
            disabled={isAssigning}
            showLoading={isAssigning}
            form="assign-journey-from"
            type="submit"
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};
