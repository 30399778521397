import { AssigneeRole, type User } from '@API';
import { type IEvent } from '@models/playbookHome.model';
import { sureString } from '@Shared/utils/sure';

export const formatAssigneeName = (
  name?: string,
  emailTo?: string | null,
  role?: string | null,
  runbookAssignee?: User,
) => {
  if (runbookAssignee && !role) {
    if (emailTo === 'Assignee' && name) {
      return `${name} (${emailTo})`;
    } else if (name && emailTo) {
      return `${name} <${emailTo}>`;
    }
  }

  return name;
};

const isAssigneeRole = (role: string) =>
  Object.values(AssigneeRole).includes(role.toUpperCase() as AssigneeRole);

export const getFormAssigneeCC = (event: IEvent) => {
  if (event.cc && event.cc.length) {
    return event.cc.map((item) => {
      if (isAssigneeRole(sureString(item, ''))) {
        return {
          role: item,
        };
      }

      return {
        email: item,
      };
    });
  }
};
