import { sureString } from '@Shared/utils/sure';
import {
  addNotificationToApp,
  isValidEmail,
  convertChannelSpecificFormatToHtml,
} from '@Shared/utils/utils';
import {
  createCollaborator,
  createPlaybookEvent,
  createPlaybookEventReminder,
  deleteActionFromCollaborator,
  deletePlaybookEventReminder,
  getUserBasicData,
  listOrgForms,
  updateEventAndRoleAssignee,
  updatePlaybookEvent,
  updatePlaybookEventReminder,
} from '@api/apis';
import {
  type AssigneeRole,
  Channel,
  EventStatus,
  type User,
  type Event,
  type EventReminder,
} from '@base/API';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { useEvents } from '@base/Hooks/useEvents';
import { Analytics } from '@base/analytics/analytics';
import { Keys } from '@base/keys/queryKeys';
import { EventType } from '@base/models/common.model';
import { type ICreateUpdateAction } from '@base/models/journeyLibrary.model';
import { type IEvent } from '@base/models/playbookHome.model';
import { useQuery } from '@tanstack/react-query';
import { type FormEvent, useCallback, useEffect, useState } from 'react';
import {
  formatAssigneeName,
  getFormAssigneeCC,
} from './CreateUpdateFormEventPanelContent.utils';

interface State {
  channel: Channel;
  selectedForm: { id: string; name: string };
  title: string;
  description: string;
  showCCOption: boolean;
  isSubmitted: boolean;
  showSavingLoader: boolean;
  collaboratorRoles: string[];
  selectedDaysFrom: string;
  selectedReminderDaysFrom: string;
  reminders: { id: string | null; daysFrom: string }[];
  formAssignee:
    | Partial<User & { role: string; name?: string; label?: string }>
    | undefined;
  formAssigneeCC: Partial<User & { role: string }>[] | undefined;
  assigneeRole?: AssigneeRole | null;
  showPreviewFormModal: boolean;
}

const roles = {
  MANAGER: 'Manager',
  HROPS: 'HR Ops',
  HRBP: 'HRBP',
  BUDDY: 'Buddy',
  IT: 'IT',
  ASSIGNEE: 'Assignee',
  ASSIGNEE_PERSONAL_EMAIL: 'Assignee personal email',
};

export const useCreateUpdateFormEventPanel = (params: ICreateUpdateAction) => {
  const {
    actionEvent: event,
    isEditingAllowed,
    builderId,
    isTemplate,
    isParentJourney,
    playbookStatus,
    runbookAssignee,
    journeyStartDate,
    handleShowModal,
    refreshEvents,
  } = params;

  const isEditable = event.status !== EventStatus.DONE && !!isEditingAllowed;

  const [state, setState] = useState({
    channel: Channel.EMAIL,
    collaboratorRoles: Object.values(roles),
    selectedDaysFrom: 'Select day',
    reminders: [] as { id: string | null; daysFrom: string }[],
    showPreviewFormModal: false,
  } as State);
  const { user } = useUserContext();

  const {
    getDaysFrom,
    getEventStatus,
    getSendTimeDate,
    mapDaysFromToLabel,
    getAssigneeRole,
    uploadEventDescription,
  } = useEvents();

  const { setIsParentJourneyChanged } = useUserContext();

  const { data: forms, isLoading: areFormsLoading } = useQuery({
    queryKey: Keys.getOrgForms(user.userOrganizationId),
    queryFn: () => listOrgForms(user.userOrganizationId),
    enabled: !!user.userOrganizationId,
  });

  const { data: playbookAssignee } = useQuery({
    queryKey: Keys.getUser(sureString(runbookAssignee?.id, '')),
    queryFn: () => getUserBasicData(sureString(runbookAssignee?.id, '')),
    enabled: !!runbookAssignee,
  });

  const handleSelectedAssignee = (users: any[]) => {
    if (users && users.length > 0) {
      const user = users[0];
      if (user.label && !isValidEmail(user.label)) {
        addNotificationToApp('Please enter a valid email', 'error');
        return;
      }
      setState({ ...state, formAssignee: users[0], assigneeRole: null });
    } else {
      setState({
        ...state,
        formAssignee: undefined,
        assigneeRole: state.assigneeRole,
      });
    }
  };

  const handleSelectRole = (selectedRole: string) => {
    let formAssignee: typeof state.formAssignee = { role: selectedRole };
    if (runbookAssignee && selectedRole === 'Assignee') {
      formAssignee = { ...formAssignee, ...runbookAssignee };
    }

    if (runbookAssignee && selectedRole === 'Assignee personal email') {
      if (!runbookAssignee.personalEmail) {
        addNotificationToApp(
          "Oops! Assignee doesn't have any personal email.",
          'warning',
        );
        return;
      }

      formAssignee = { ...formAssignee, email: runbookAssignee.personalEmail };
    }

    setState({
      ...state,
      collaboratorRoles: Object.values(roles).filter(
        (role) => role !== selectedRole,
      ),
      assigneeRole: getAssigneeRole(selectedRole),
      formAssignee,
    });
  };

  const handleAddReminder = (value: string, index: number) => {
    state.reminders[index]['daysFrom'] = value;
    setState({ ...state, reminders: [...state.reminders] });
  };

  const handleRemoveReminder = (index: number) => {
    state.reminders.splice(index, 1);
    setState({ ...state, reminders: [...state.reminders] });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setState({ ...state, isSubmitted: true });
    if (!isValidForm()) {
      return;
    }

    setState({ ...state, showSavingLoader: true });

    const request = getRequestData();

    if (event.id) {
      await updateFormEvent(request);
    } else {
      await createFormEvent(request);
    }

    setIsParentJourneyChanged(isParentJourney);
    setState({ ...state, showSavingLoader: false, isSubmitted: false });
    refreshEvents();
    handleShowModal(false);
  };

  const createFormEvent = async (request: Event) => {
    const createdEventId = await createPlaybookEvent({ ...request } as Event);

    if (!createdEventId) return;

    const { description, assigneeRole } = state;

    await Promise.all([
      uploadEventDescription(
        request,
        description,
        createdEventId,
        builderId,
        isParentJourney,
      ),
      handleReminders(createdEventId),
    ]);

    const role = getAssigneeRole(assigneeRole);
    if (!role) return;

    const collaborator = await createCollaborator({
      actionId: createdEventId,
      builderId,
      role,
      isTemplate: isTemplate || false,
    });

    if (collaborator && collaborator.assigneeId) {
      await updatePlaybookEvent({
        ...request,
        id: createdEventId,
        userId: collaborator.assigneeId,
        updatedInChild: !isParentJourney,
      } as Event);
    }

    Analytics.playbookEventAdded(request.type as EventType, request.channel);
  };

  const updateFormEvent = async (request: Event) => {
    const { formAssignee, assigneeRole } = state;
    const { role, id, journeyID, reminders } = event;
    const isCollaboratorRoleChanged = role !== state.assigneeRole;
    const assigneeRoleUpperCase = getAssigneeRole(role);

    let updatedEventId: string | undefined;

    if (formAssignee?.role && assigneeRole && isCollaboratorRoleChanged) {
      // Update event and role assignee
      const updateEventPromise = updateEventAndRoleAssignee({
        ...request,
        updatedInChild: !isParentJourney,
      });

      let sideEffects: Promise<any>[] = [updateEventPromise];

      // Handle deleting action from previous collaborator role
      if (reminders && journeyID) {
        sideEffects.push(
          deleteActionFromCollaborator(
            id,
            journeyID,
            { assigneeRole: { eq: assigneeRoleUpperCase } },
            !isTemplate,
          ),
        );
      }

      [updatedEventId] = await Promise.all(sideEffects);
    } else {
      updatedEventId = await updatePlaybookEvent({
        ...request,
        updatedInChild: !isParentJourney,
      });
    }

    if (updatedEventId) {
      let sideEffects: Promise<any>[] = [handleReminders(id)];

      const role = getAssigneeRole(assigneeRole);
      if (role) {
        sideEffects.push(
          createCollaborator({
            actionId: id,
            builderId,
            role,
            isTemplate: isTemplate || false,
          }),
        );
      }

      await Promise.all(sideEffects);
      await uploadEventDescription(
        request,
        state.description,
        id,
        builderId,
        isParentJourney,
      );
    }
  };

  const isValidForm = () =>
    state.selectedForm?.id &&
    state.formAssignee &&
    (!state.reminders.length ||
      state.reminders.every((reminder) => reminder.daysFrom));

  const getRequestData = () => {
    let request = {
      id: event.id,
      title: state.title,
      userId: getAssigneeData().id,
      emailTo: getAssigneeData().email,
      formId: state.selectedForm.id,
      cc: state.formAssigneeCC?.map(
        (user: any) => user.email ?? user.role?.toUpperCase() ?? user.label,
      ),
      type: EventType.FORM,
      daysFrom: getDaysFrom(state.selectedDaysFrom),
      sendTimeDate: getSendTimeDate(state.selectedDaysFrom, journeyStartDate),
      status: getEventStatus(
        event.status ?? EventStatus.DRAFT,
        isParentJourney,
        playbookStatus,
        getSendTimeDate(state.selectedDaysFrom, journeyStartDate),
      ),
      channel: state.channel,
      role: getAssigneeRole(state.assigneeRole),
    } as Event;

    if (isTemplate) {
      request.kinfolkTemplateID = builderId;
    } else {
      request.journeyID = builderId;
    }

    return request;
  };

  const getAssigneeData = () => {
    let id: string | null = null;
    let email: string | null = null;

    if (state.formAssignee?.label) {
      email = state.formAssignee.label;
    } else {
      id = state.formAssignee?.id ?? null;
      // TODO: save "Assignee" role in role property when its type is changed from enum to string
      email =
        state.formAssignee?.email ??
        (state.formAssignee?.role === 'Assignee' ||
        state.formAssignee?.role === 'Assignee personal email'
          ? state.formAssignee.role
          : null);
    }

    return { id, email };
  };

  const handleReminders = async (eventId: string) => {
    const sideEffects: Promise<any>[] = [];

    const reminders = getReminders(eventId);

    if (event.id && event.reminders) {
      // Find deleted reminders
      const deletedReminders = event.reminders.items.filter(
        (previousReminder) =>
          !reminders.find(
            (currentReminder) =>
              previousReminder && currentReminder.id === previousReminder.id,
          ),
      );

      // Delete reminders that are no longer present
      deletedReminders.forEach((reminder) => {
        if (reminder) {
          sideEffects.push(deletePlaybookEventReminder(reminder.id));
        }
      });
    }

    // Update existing reminders and create new ones
    reminders.forEach((reminder) => {
      if (reminder) {
        if (reminder.id) {
          sideEffects.push(updatePlaybookEventReminder(reminder));
        } else {
          sideEffects.push(
            createPlaybookEventReminder({
              daysFrom: reminder.daysFrom,
              status: reminder.status,
              sendTimeDate: reminder.sendTimeDate,
              eventId: reminder.eventId,
            } as EventReminder),
          );
        }
      }
    });

    await Promise.all(sideEffects);
  };

  const getReminders = (eventId: string): EventReminder[] =>
    state.reminders.map(
      (reminder) =>
        ({
          id: reminder.id,
          eventId,
          sendTimeDate: getSendTimeDate(
            reminder.daysFrom,
            journeyStartDate ?? playbookAssignee?.startDate,
          ),
          daysFrom: getDaysFrom(reminder.daysFrom),
          status: getEventStatus(
            EventStatus.DRAFT,
            isParentJourney,
            playbookStatus,
            getSendTimeDate(
              reminder.daysFrom,
              journeyStartDate ?? playbookAssignee?.startDate,
            ),
          ),
        }) as EventReminder,
    );

  const getActionReminders = useCallback(
    (
      reminders: (EventReminder | null)[] | undefined,
    ): { id: string | null; daysFrom: string }[] => {
      if (!reminders) {
        return [];
      }

      return reminders.map((reminder) => {
        if (reminder) {
          return {
            id: reminder.id,
            daysFrom: mapDaysFromToLabel(reminder.daysFrom),
          };
        }

        return { id: null, daysFrom: 'Start day' };
      });
    },
    [mapDaysFromToLabel],
  );

  const getRole = (event: IEvent) => {
    if (
      (event.emailTo === 'Assignee' ||
        event.emailTo === 'Assignee personal email') &&
      !event.role
    ) {
      return event.emailTo;
    }

    return event.role;
  };

  const getSelectedForm = useCallback(
    (formId: string) => {
      if (!forms) {
        return;
      }

      return forms.find((form) => form.id === formId);
    },
    [forms],
  );

  useEffect(() => {
    if (event.id && event.type === EventType.FORM) {
      setState(
        (state) =>
          ({
            ...state,
            title: sureString(event.title, ''),
            description: sureString(
              convertChannelSpecificFormatToHtml(
                event.message ?? '',
                event.channel,
              ),
              '',
            ),
            selectedDaysFrom: mapDaysFromToLabel(event.daysFrom),
            assigneeRole: event.role,
            channel: event.channel,
            collaboratorRoles: Object.values(roles).filter(
              (role) =>
                role.toLowerCase() !== event.role?.toLowerCase() &&
                role.toLowerCase() !== event.emailTo?.toLowerCase(),
            ),
            formAssigneeCC: getFormAssigneeCC(event),
            reminders: getActionReminders(event.reminders?.items),
            formAssignee: {
              id: event.userId,
              email: event.emailTo ?? '',
              role: getRole(event),
              name: formatAssigneeName(
                event.userName,
                event.emailTo,
                getRole(event),
                runbookAssignee,
              ),
            },
          }) as State,
      );
    } else if ('daysFrom' in event) {
      setState((state) => ({
        ...state,
        selectedDaysFrom: mapDaysFromToLabel(event.daysFrom),
      }));
    }
  }, [event, mapDaysFromToLabel, getActionReminders, runbookAssignee]);

  useEffect(() => {
    if (event.id && event.type === EventType.FORM && event.formId) {
      setState(
        (prevState) =>
          ({
            ...prevState,
            selectedForm: getSelectedForm(sureString(event.formId)),
          }) as State,
      );
    }
  }, [event, getSelectedForm]);

  return {
    user,
    state,
    forms,
    areFormsLoading,
    setState,
    isEditable,
    handleSelectedAssignee,
    roles,
    handleSelectRole,
    handleSubmit,
    handleAddReminder,
    handleRemoveReminder,
  };
};
