import { memo } from 'react';
import './ProfileCard.scss';
import { Link } from 'react-router-dom';
import { type ProfileCardProps } from '@models/people.model';

export const ProfileCard = memo((props: ProfileCardProps) => {
  const { firstName, lastName, team, userId, profilePicture } = props;

  return (
    <Link className="person-link" to={`../profile/${userId}`}>
      <div className="person-card">
        <div className="person-card--photo">
          <img
            title="Profile photo"
            alt={`${firstName} ${lastName}`}
            src={profilePicture}
          ></img>
        </div>
        <div className="person-card--name">
          {firstName} {lastName}
        </div>
        {team && <div className="person-card--team rounded-pill">{team}</div>}
      </div>
    </Link>
  );
});
