import { Route, Routes } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import ScrollToTop from '@Shared/utils/utils';
import { Home } from '@Pages/Home/Home';
import { Profile } from '@Pages/Profile/Profile';
import { Login } from '@Pages/Login/Login';
import { Onboarding } from '@Pages/Onboarding/Onboarding';
import Settings from '@Pages/Settings/Settings';
import UserProfile from '@Pages/UserProfile/UserProfile';
import { ResetPassword } from '@Pages/ForgotPassword/ResetPassword';
import { Signup } from '@Pages/Signup/Signup';
import { AccountVerification } from '@Pages/AccoutVerification/AccountVerification';
import { CreateOrganization } from '@Pages/CreateOrganization/CreateOrganization';
import { Templates } from '@Pages/Templates/Templates';
import { TemplateBuilder } from '@Pages/TemplateBuilder/TemplateBuilder';
import { JourneyBuilder } from '@Pages/JourneyBuilder/JourneyBuilder';
import { EmailVerification } from '@Pages/EmailVerification/EmailVerification';
import { Layout } from '@Shared/Components/Layout/Layout';
import { PlaybookHome } from '@Pages/PlaybookHome';
import { PreboardingLinkVerificationModal, TrialEnded } from '@Shared/DS';
import { People } from '@Pages/People/People';
import { TemplateHome } from '@Pages/TemplateHome/TemplateHome';
import { Playbooks } from '@Pages/Playbooks/Playbooks';
import { Form } from '@Pages/Form';
import { Forms } from '@Pages/Forms';
import { useApp } from './Hooks/useApp';
import Loader from './Shared/Components/Loader/Loader';
import { UserContext } from './Context/UserContext/UserContext';
import { queryClient } from './config/reactQuery.config';
import { DynamicTagsProvider } from './Context/DynamicTagsContext';

const App = () => {
  const {
    profileData,
    allOrganisationInterests,
    allOrganisationShareInfo,
    isLoading,
    journeyAssignee,
    journeyPreboardingUser,
    isParentJourneyChanged,
    isTrialEnded,
    playbook,
    setJourneyAssignee,
    collaborators,
    setCollaborators,
    setUserAbout,
    setUserIsFirstLogin,
    setUserIsHomeModelViewed,
    setAllOrganisationInterests,
    setAllOrganisationShareInfo,
    setUserInterests,
    setUserShareInfoOn,
    setUserLearnMoreOn,
    setUserExtraDetails,
    setUserHappyMost,
    setUserWorkStyle,
    setUserWorkPreferences,
    setUserProfilePhoto,
    getProfileData,
    setUserWorkLocation,
    setUserPersonalInfo,
    setJourneyPreboardingUser,
    setIsParentJourneyChanged,
    setProfileData,
    setPlaybook,
  } = useApp();

  return (
    <div className="App">
      <ScrollToTop />
      <UserContext.Provider
        value={{
          user: profileData,
          collaborators,
          journeyAssignee,
          journeyPreboardingUser,
          allOrganisationInterests,
          allOrganisationShareInfo,
          isParentJourneyChanged,
          playbook,
          getProfileData,
          setAllOrganisationInterests,
          setAllOrganisationShareInfo,
          setUserInterests,
          setUserShareInfoOn,
          setUserLearnMoreOn,
          setUserAbout,
          setUserIsFirstLogin,
          setUserIsHomeModelViewed,
          setUserExtraDetails,
          setUserHappyMost,
          setUserWorkStyle,
          setUserWorkPreferences,
          setUserProfilePhoto,
          setUserWorkLocation,
          setUserPersonalInfo,
          setCollaborators,
          setJourneyAssignee,
          setJourneyPreboardingUser,
          setIsParentJourneyChanged,
          setUser: setProfileData,
          setPlaybook,
        }}
      >
        <DynamicTagsProvider>
          {isTrialEnded ? (
            <TrialEnded />
          ) : (
            <Loader active={isLoading} styling="absolute">
              {!isLoading && (
                <>
                  <QueryClientProvider client={queryClient}>
                    <Routes>
                      <Route path="/" element={<Layout />}>
                        <Route index element={<Home />} />
                        <Route path="/runbooks" element={<Playbooks />} />
                        <Route path="/runbook/:id" element={<PlaybookHome />} />
                        <Route
                          path="/runbook/:id/assignees"
                          element={<PlaybookHome />}
                        />
                        <Route
                          path="/runbook/:id/meetings"
                          element={<PlaybookHome />}
                        />
                        <Route
                          path="/runbook/:id/event"
                          element={<PlaybookHome />}
                        />
                        <Route path="/templates" element={<Templates />} />
                        <Route
                          path="/template/:id"
                          element={<TemplateHome />}
                        />
                        <Route
                          path="/template/:id/preview"
                          element={<TemplateHome />}
                        />
                        <Route
                          path="/template/:id/meetings"
                          element={<TemplateHome />}
                        />
                        <Route
                          path="/template/:id/event"
                          element={<TemplateHome />}
                        />
                        <Route path="/people" element={<People />} />
                        <Route path="/forms" element={<Forms />} />
                        <Route path="/settings" element={<Settings />} />
                        <Route path="/profile" element={<Profile />} />
                        <Route
                          path="/profile/:userId"
                          element={<UserProfile />}
                        />
                      </Route>
                      <Route path="/onboarding" element={<Onboarding />} />
                      <Route path="/login" element={<Login />} />
                      <Route
                        path="/verify-email"
                        element={<EmailVerification />}
                      />
                      <Route
                        path="/reset-password"
                        element={<ResetPassword />}
                      />
                      <Route path="/sign-up" element={<Signup />} />
                      <Route
                        path="/new-organization"
                        element={<CreateOrganization />}
                      />
                      <Route
                        path="/account-verification"
                        element={<AccountVerification />}
                      />
                      <Route
                        path="/template/:id/microsite"
                        element={<TemplateBuilder />}
                      />
                      <Route
                        path="/template/:id/microsite/preview"
                        element={<TemplateBuilder />}
                      />
                      <Route
                        path="/runbook/:id/microsite"
                        element={<JourneyBuilder />}
                      />
                      <Route
                        path="/runbook/:id/microsite/preview"
                        element={<JourneyBuilder />}
                      />
                      <Route
                        path="/invite/:id"
                        element={<PreboardingLinkVerificationModal />}
                      />
                      <Route path="/form/:id" element={<Form />} />
                      <Route path="/forms/static/:slug" element={<Form />} />
                    </Routes>
                  </QueryClientProvider>
                </>
              )}
            </Loader>
          )}
        </DynamicTagsProvider>
      </UserContext.Provider>
    </div>
  );
};

export default App;
