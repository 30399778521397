import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import {
  type User,
  type Interest,
  type ShareInfo,
  type ModelUserInterestConnection,
  type ModelUserShareInfoConnection,
  type ModelLearnMoreInfoConnection,
  type Pronoun,
  type Location,
  type JourneyCollaborator,
  type PreboardingUser,
  UserType,
  type Journey,
} from '@API';
import {
  updateUseAbout,
  updateUserFirstLogin,
  updateUserHomeViewed,
  updateUserExtraDetails,
  updateMostHappy,
  updateUserWorkStyle,
  updateUserWorkPreferences,
  updateUserProfilePhoto,
  getUserData,
  ListInterestData,
  listShareInfosData,
  getInitialUserData,
  updateWorkLocation,
  updateUserData,
  updateUserStatus,
  isOrganizationUser,
  getCurrentUserEmail,
  createOrganizationUser,
  updateUserSlackId,
  createAnonymousUserAndAssignPlaybook,
  fetchJourneys,
} from '@api/apis';
import { addNotificationToApp, isWorkingEmail } from '@Shared/utils/utils';
import { getCurrentUser, signIn, signOut, signUp } from 'aws-amplify/auth';
import { useIntercom } from 'react-use-intercom';
import { Analytics } from '@base/analytics/analytics';

export const useApp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  const [profileData, setProfileData] = useState<User>({} as User);
  const [allOrganisationInterests, setallOrganisationInterests] = useState<
    Interest[]
  >([]);
  const [allOrganisationShareInfo, setallOrganisationShareInfo] = useState<
    ShareInfo[]
  >({} as ShareInfo[]);
  const [collaborators, setCollaborators] = useState(
    [] as JourneyCollaborator[],
  );
  const [journeyAssignee, setJourneyAssignee] = useState<User | null>(null);
  const [journeyPreboardingUser, setJourneyPreboardingUser] =
    useState<PreboardingUser | null>(null);
  const [isParentJourneyChanged, setIsParentJourneyChanged] = useState(false);
  const [isTrialEnded, setIsTrialEnded] = useState(false);
  const [playbook, setPlaybook] = useState({} as Journey);

  const [redirectUrl, setRedirectUrl] = useState<string | undefined>();

  const { boot } = useIntercom();

  /**
   * functions to mutate user data
   */

  const setUserAbout = async (about: string) => {
    try {
      await updateUseAbout(profileData, about);
      setProfileData({ ...profileData, aboutMe: about });
    } catch (e) {
      console.error('Error! while updating user about');
    }
  };

  const setUserIsFirstLogin = async (isFirstLogin: boolean) => {
    try {
      await updateUserFirstLogin(profileData, isFirstLogin);
      setProfileData({ ...profileData, isFirstLogin: isFirstLogin });
    } catch (e) {
      console.error('Error! while updating user isFirstLogin');
    }
  };

  const setUserIsHomeModelViewed = async (isHomeModelViewed: boolean) => {
    try {
      await updateUserHomeViewed(profileData, isHomeModelViewed);
      setProfileData({ ...profileData, isHomeModelViewed: isHomeModelViewed });
    } catch (e) {
      console.error('Error! while updating user isHomeModelViewed');
    }
  };

  const setAllOrganisationInterests = (InterestsList: Interest[]) => {
    setallOrganisationInterests(InterestsList);
  };

  const setAllOrganisationShareInfo = (ShareInfoList: ShareInfo[]) => {
    setallOrganisationShareInfo(ShareInfoList);
  };

  const setUserInterests = (InterestsList: ModelUserInterestConnection) => {
    setProfileData({ ...profileData, Interests: InterestsList });
  };

  const setUserShareInfoOn = (InterestsList: ModelUserShareInfoConnection) => {
    setProfileData({ ...profileData, ShareInfoOn: InterestsList });
  };

  const setUserLearnMoreOn = (InterestsList: ModelLearnMoreInfoConnection) => {
    setProfileData({ ...profileData, LearnMoreOn: InterestsList });
  };

  const setUserExtraDetails = async (
    pronoun: Pronoun,
    socialMediaUrl: string,
  ) => {
    try {
      await updateUserExtraDetails(profileData, pronoun, socialMediaUrl);
      setProfileData({ ...profileData, pronoun, socialMediaUrl });
    } catch (e) {
      console.error('Error! while updating user extra details');
    }
  };

  const setUserHappyMost = async (mostHappy: string) => {
    try {
      await updateMostHappy(profileData, mostHappy);
      setProfileData({ ...profileData, mostHappy });
    } catch (e) {
      console.error('Error! while updating user about');
    }
  };

  const setUserWorkStyle = async (
    whatIDoAtWork: string,
    myFocusTimeIs: string,
    giveReceivingFeedback: string,
    digestionInformation: string,
    inMyElement: string,
  ) => {
    try {
      const workStyle = {
        ...profileData.WorkStyle,
        whatIDoAtWork,
        myFocusTimeIs,
        giveReceivingFeedback,
        digestionInformation,
        inMyElement,
      };
      await updateUserWorkStyle(workStyle);
      setProfileData({ ...profileData, WorkStyle: workStyle });
    } catch (e) {
      console.error('Error! while updating user work style');
    }
  };

  const setUserWorkPreferences = async (
    giveMeAllTheContext: number,
    messageTimingPreferene: number,
    syncxVsAsync: number,
    talkTogetherVsAlone: number,
    recognitionPrivateVsPrivate: number,
  ) => {
    try {
      const workPreferences = {
        ...profileData.WorkPreference,
        giveMeAllTheContext,
        messageTimingPreferene,
        syncxVsAsync,
        talkTogetherVsAlone,
        recognitionPrivateVsPrivate,
      };
      await updateUserWorkPreferences(workPreferences);
      setProfileData({ ...profileData, WorkPreference: workPreferences });
    } catch (e) {
      console.error('Error! while updating user work preferences');
    }
  };

  const setUserProfilePhoto = async (photoUrl: string) => {
    try {
      await updateUserProfilePhoto(profileData, photoUrl);
      setProfileData({ ...profileData, photoUrl });
    } catch (e) {
      console.error('Error! while updating user profile photo');
    }
  };

  const setUserWorkLocation = async (workLocation: Location) => {
    await updateWorkLocation(workLocation.id, workLocation.city);
    setProfileData({ ...profileData, WorkLocation: workLocation });
  };

  const setUserPersonalInfo = async (user: User) => {
    await updateUserData(user);
    setProfileData(user);
  };

  const getProfileData = async () => {
    try {
      const userData = await getUserData();
      if (userData?.length) {
        const [user] = userData;

        Analytics.user({
          userId: user.id,
          organizationId: user.userOrganizationId,
          name: `${user.firstName ?? ''} ${user.lastName ?? ''}`,
          email: user.email,
        });

        setProfileData(user);
        /* check if user is not active and it's not the first login, that means user is not active */
        if (!user.isActive && !user.isFirstLogin) {
          signOut();
          localStorage.removeItem('isLoggedIn');
          navigate('/login');
          addNotificationToApp('Your account is inactive', 'error');
        } else if (
          (user.isFirstLogin === null || user.isFirstLogin) &&
          user.type !== UserType.CANDIDATE &&
          user.type !== UserType.ANONYMOUS
        ) {
          navigate('/onboarding');
        } else if (
          location.pathname === '/login' ||
          location.pathname === '/sign-up'
        ) {
          navigate(redirectUrl ?? '/');
        } else if (location.pathname.includes('invite-pub')) {
          redirectAnonymousUser(user);
        }

        /* update user status if user is not active */
        if (!user.isActive && user.isFirstLogin) {
          await updateUserStatus({ id: user.id, isActive: true } as User);
          updateUserSlackId(user.userOrganizationId, user.id, user.email);
        }

        /* check if user is candidate and first login is true then update first login to false */
        if (
          user.isFirstLogin &&
          (user.type === UserType.CANDIDATE || user.type === UserType.ANONYMOUS)
        ) {
          updateUserStatus({ id: user.id, isFirstLogin: false } as User);
        }

        const [interestData, shareInfoData] = await Promise.all([
          ListInterestData(user.Organization.id),
          listShareInfosData(user.Organization.id),
        ]);
        setAllOrganisationInterests(interestData ? interestData : []);
        setallOrganisationShareInfo(shareInfoData ? shareInfoData : []);
      } else if (userData?.length === 0) {
        await handleNewUser();
      } else {
        navigate('/login');
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const handleAnonymousUser = async () => {
    try {
      const randomEmail = `anonymous${Date.now()}@sink.sendgrid.net`;
      await signInAnonymousUser(randomEmail);

      const link = window.location.href;
      const playbooks = await fetchJourneys({ publicLink: { eq: link } });
      if (playbooks && playbooks.length) {
        const [playbook] = playbooks;
        const id = await createAnonymousUserAndAssignPlaybook(
          playbook.organizationID,
          randomEmail,
          playbook.id,
        );

        if (id) {
          await getProfileData();
          return;
        }
      }

      addNotificationToApp('Link not found.', 'error');
      navigate('/login');
    } catch (e) {
      console.error(e);
      addNotificationToApp(
        'Something went wrong. Please try again later',
        'error',
      );
      navigate('/login');
    }
  };

  const signInAnonymousUser = async (email: string) => {
    const password = uuidv4();

    await signUp({
      username: email,
      password,
      options: { userAttributes: { email } },
    });
    await signIn({ username: email, password });
  };

  const redirectAnonymousUser = async (user: User) => {
    const link = window.location.href;
    const playbooks = await fetchJourneys({ publicLink: { eq: link } });

    if (playbooks && playbooks.length) {
      const [playbook] = playbooks;

      if (user.type === UserType.ANONYMOUS) {
        navigate('/');
      } else if (
        user.type === UserType.COMPANY_ADMIN ||
        user.type === UserType.SUPER_ADMIN
      ) {
        navigate(`/runbook/${playbook.id}`);
      } else {
        addNotificationToApp('Link not found', 'error');
        navigate('/');
      }

      return;
    }

    // if link doesn't belong to any runbooks, means it is expired by admin
    navigate('/login');
    addNotificationToApp('Link not found.', 'error');
  };

  const handleNewUser = async () => {
    const email = await getCurrentUserEmail();
    if (email) {
      const user = await isOrganizationUser(email.replace(/.*@/, ''));
      if (user) {
        const isCreated = await createOrganizationUser(
          user.userOrganizationId,
          email,
        );
        if (isCreated) {
          getProfileData();
        }
      } else {
        navigate('/new-organization');
      }
    }
  };

  /**
   * AWS amplify login
   */
  useEffect(() => {
    setIsLoading(true);

    getCurrentUser()
      .then(async () => {
        const startTime = performance.now();
        // save log in state to local storage
        localStorage.setItem('isLoggedIn', 'true');
        const email = await getCurrentUserEmail();
        if (!email) {
          addNotificationToApp('Something went wrong', 'error');
          return;
        }

        const user = await getInitialUserData(email);
        const isAuthenticated = user?.length;
        if (isAuthenticated === 0) {
          if (!isWorkingEmail(email)) {
            addNotificationToApp(
              'Please use your work email to sign up',
              'error',
            );
            signOut();
            localStorage.removeItem('isLoggedIn');
          } else {
            setIsLoading(true);
            await handleNewUser();
          }
          setIsLoading(false);
        } else if (isAuthenticated) {
          getProfileData();
        } /* handle network errors */ else {
          navigate('/login');
          setIsLoading(false);
        }

        const endTime = performance.now();
        Analytics.clientInit(+((endTime - startTime) / 1000).toFixed(2));
      })
      .catch(async () => {
        if (location.pathname.includes('invite-pub')) {
          await handleAnonymousUser();
        } else if (
          location.pathname !== '/sign-up' &&
          !location.pathname.includes('microsite') &&
          !location.pathname.includes('invite') &&
          !location.pathname.includes('/forms/static')
        ) {
          setRedirectUrl(
            location.pathname !== '/login' ? location.pathname : '/',
          );
          navigate('/login');
        }

        setIsLoading(false);
      });
    // Cyclic dependencies need to be resolved to fix this
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (profileData && profileData.id) {
      boot({
        customAttributes: {
          User_role: profileData.type.split('_')[1] ?? profileData.type,
        },
        email: profileData.email,
      });
    }
  }, [boot, profileData]);

  useEffect(() => {
    const checkIfTrialHasEnded = () => {
      const now = Date.now();
      const trialEndTime = getTrialEndTimeInMilliseconds(
        Number(profileData?.Organization?.activeUntil),
      );
      const oneDayInMilliseconds = 86400000; // 24 * 60 * 60 * 1000
      const trialEndPlusOneDay = trialEndTime + oneDayInMilliseconds;

      return now > trialEndPlusOneDay;
    };

    const getTrialEndTimeInMilliseconds = (epochTimeInSeconds: Number) =>
      Number(epochTimeInSeconds) * 1000;

    if (profileData?.Organization?.hasSubscription) {
      setIsTrialEnded(false);
    } else {
      setIsTrialEnded(checkIfTrialHasEnded());
    }
  }, [profileData?.Organization]);

  return {
    profileData,
    allOrganisationInterests,
    allOrganisationShareInfo,
    isLoading,
    collaborators,
    journeyAssignee,
    journeyPreboardingUser,
    isParentJourneyChanged,
    isTrialEnded,
    playbook,
    setJourneyAssignee,
    setCollaborators,
    setUserAbout,
    setUserIsFirstLogin,
    setUserIsHomeModelViewed,
    setAllOrganisationInterests,
    setAllOrganisationShareInfo,
    setUserInterests,
    setUserShareInfoOn,
    setUserLearnMoreOn,
    setUserExtraDetails,
    setUserHappyMost,
    setUserWorkStyle,
    setUserWorkPreferences,
    setUserProfilePhoto,
    getProfileData,
    setUserWorkLocation,
    setUserPersonalInfo,
    setJourneyPreboardingUser,
    setIsParentJourneyChanged,
    setProfileData,
    setPlaybook,
  };
};
