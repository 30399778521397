import { useCallback } from 'react';
import './Templates.scss';
import { Container } from 'react-bootstrap';
import { Button } from '@Shared/DS';
import Loader from '@Shared/Components/Loader/Loader';
import { TemplateCard } from '../../Shared/DS/Molecules/TemplateCard';
import { CreateTemplateModal } from './Components/CreateTemplateModal/CreateTemplateModal';
import { useTemplatePage } from './useTemplate';

export const Templates = () => {
  const {
    showCreateTemplateModal,
    templates,
    isLoading,
    setShowCreateTemplateModal,
    handleCreateTemplate,
  } = useTemplatePage();

  return (
    <div className="templates-page-wrapper d-flex">
      <Container fluid className="p-5">
        {isLoading ? (
          <Loader active={isLoading} />
        ) : (
          <div className="row no-pad">
            <div className="col-md-12 col-lg-12">
              <div className="row justify-content-between">
                <div className="col-12 col-lg-8 heading">Runbooks</div>
                <div className="col-12 col-lg-4 d-lg-flex justify-content-lg-end">
                  <Button
                    text={
                      <>
                        <svg
                          width="13"
                          height="15"
                          viewBox="0 0 13 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <line
                            x1="6.97852"
                            y1="2.53022e-08"
                            x2="6.97852"
                            y2="15"
                            stroke="white"
                          />
                          <line
                            x1="12.9569"
                            y1="8"
                            x2="9.81907e-05"
                            y2="8"
                            stroke="white"
                          />
                        </svg>
                        <span>Create template</span>
                      </>
                    }
                    onClick={handleCreateTemplate}
                  />
                </div>
              </div>
              <div className="row">
                <div className="sub-heading mt-3">Kinfolk templates</div>

                {templates?.map((template) => (
                  <TemplateCard key={template.id} template={template} />
                ))}
              </div>
            </div>
          </div>
        )}
      </Container>

      <CreateTemplateModal
        showModal={showCreateTemplateModal}
        handleShowModal={useCallback(
          (value: boolean) => setShowCreateTemplateModal(value),
          [setShowCreateTemplateModal],
        )}
      />
    </div>
  );
};
