import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { type Journey, type KinfolkTemplate } from '@API';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { getKinfolkTemplates, fetchJourneys } from '@api/apis';
import { addNotificationToApp } from '@Shared/utils/utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export const usePlaybooksPage = () => {
  const [journeys, setJourneys] = useState<Journey[]>([]);
  const [kinfolkTemplates, setKinfolkTemplates] = useState<KinfolkTemplate[]>(
    [],
  );
  const [showCreatePlaybookModal, setShowCreatePlaybookModal] = useState(false);
  const { user } = useUserContext();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const getJourneysAndTemplates = async (): Promise<{
    journeys: Journey[];
    kinfolkTemplates: KinfolkTemplate[];
  }> => {
    const [orgJourneys, kinfolkTemplates] = await Promise.all([
      fetchJourneys({
        organizationID: { eq: user.userOrganizationId },
        isArchived: { eq: false },
        parentJourneyID: { attributeExists: false },
      }),
      getKinfolkTemplates({
        filter: {
          isPublic: { eq: true },
        },
      }),
    ]);
    if (orgJourneys && kinfolkTemplates) {
      return {
        journeys: orgJourneys.sort(sortJourneysAlphabetically),
        kinfolkTemplates: kinfolkTemplates.sort(sortTemplatesByOrderNo),
      };
    }

    return {
      journeys: [] as Journey[],
      kinfolkTemplates: [] as KinfolkTemplate[],
    };
  };
  const { data, isLoading } = useQuery({
    queryKey: ['allOrganizationJourneys'],
    queryFn: getJourneysAndTemplates,
    enabled: !!user.userOrganizationId,
  });

  const sortJourneysAlphabetically = (a: Journey, b: Journey) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  };
  const sortTemplatesByOrderNo = (a: KinfolkTemplate, b: KinfolkTemplate) => {
    if (a.orderNo && b.orderNo) {
      return a.orderNo - b.orderNo;
    }
    return 0;
  };

  const handleCreateJourney = () => {
    setShowCreatePlaybookModal(true);
  };

  const handleViewPlaybook = useCallback(
    (journey: Journey) => {
      navigate(`/runbook/${journey.id}`);
    },
    [navigate],
  );

  const handleUpdateJourneys = useCallback(
    (journey: Journey) => {
      queryClient.invalidateQueries({ queryKey: ['allOrganizationJourneys'] });
    },
    [queryClient],
  );

  const handleArchiveJourney = useCallback(
    async (journeyId: string) => {
      const updatedJourneys = journeys.filter(
        (journey) => journey.id !== journeyId,
      );
      queryClient.setQueriesData(
        { queryKey: ['allOrganizationJourneys'] },
        (previousData: any) => ({ ...previousData, journeys: updatedJourneys }),
      );
      addNotificationToApp('Runbook successfully archived', 'success');
    },
    [journeys, queryClient],
  );

  useEffect(() => {
    if (data) {
      setJourneys(data.journeys);
      setKinfolkTemplates(data.kinfolkTemplates);
    }
  }, [data]);

  return {
    isLoading,
    journeys,
    showCreatePlaybookModal,
    kinfolkTemplates,
    setShowCreatePlaybookModal,
    handleViewPlaybook,
    handleArchiveJourney,
    handleCreateJourney,
    handleUpdateJourneys,
  };
};
