import { Tab, Tabs } from 'react-bootstrap';
import './TemplateHomeTabs.scss';
import { type ITemplateHome, TabKeys } from '@base/models/playbookHome.model';
import { Meetings } from '@Shared/Components/Meetings/Meetings';
import { PlaybookMode } from '@base/API';
import { usePlaybookHomeTabs } from '../PlaybookHomeTabs/usePlaybookHomeTabs';
import { TemplateCalendar } from '../TemplateCalendar';

export const TemplateHomeTabs = (props: ITemplateHome) => {
  const { key, setKey, navigateTo } = usePlaybookHomeTabs();
  const { template, isEditingAllowed } = props;

  return (
    <Tabs
      id="playbookHomeTabs"
      className="template-tabs mt-2"
      activeKey={key}
      onSelect={(k) => {
        setKey(k ?? TabKeys.CALENDAR);
        if (!k || k === TabKeys.CALENDAR) {
          navigateTo(`/template/${template.id}`);
        } else if (k === TabKeys.MICROSITE) {
          navigateTo(`/template/${template.id}/microsite`);
        } else {
          navigateTo(`/template/${template.id}/${k.toLowerCase()}`);
        }
      }}
    >
      <Tab eventKey={TabKeys.CALENDAR} title={TabKeys.CALENDAR}>
        <TemplateCalendar {...props} />
      </Tab>
      {template.mode !== PlaybookMode.WORKFLOW && (
        <Tab eventKey={TabKeys.MICROSITE} title={TabKeys.MICROSITE}></Tab>
      )}
      <Tab eventKey={TabKeys.MEETINGS} title={TabKeys.MEETINGS}>
        <div className="mt-5">
          <Meetings
            isEditable={isEditingAllowed}
            journeyType={template.type}
            journeyStartDate={null}
            isParentJourney={true}
            isTemplate={true}
          ></Meetings>
        </div>
      </Tab>
    </Tabs>
  );
};
