import { type ModalProps } from '@Shared/utils/utils';
import { Modal } from 'react-bootstrap';
import CompleteIcon from '@Assets/Images/complete-icon.svg';
import './CompleteJourneyModal.scss';
import { Button } from '@Shared/DS';
import { type Badge } from '@base/API';
import { useCompleteJourneyModal } from './useCompleteJourneyModal';

interface ICompleteJourneyModal extends ModalProps {
  badge: Badge | null;
  journeyName: string;
}
export const CompleteJourneyModal = (props: ICompleteJourneyModal) => {
  const { showModal, handleShowModal, badge, journeyName } = props;
  const { showCloseButton, navigateTo } = useCompleteJourneyModal();

  return (
    <Modal
      className="complete-journey-modal"
      fullscreen={'md-down'}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      centered
      show={showModal}
      onHide={() => handleShowModal(false)}
    >
      <Modal.Header>
        <button
          type="button"
          className="btn-close modal-close-btn"
          data-bs-dismiss="modal"
          onClick={() => handleShowModal(false)}
          aria-label="Close"
        ></button>
      </Modal.Header>
      <Modal.Body>
        {badge ? (
          <div className="d-flex flex-column justify-content-center align-items-center">
            <img src={badge.iconUrl} alt="icon" />
            <div className="heading mt-2">Nicely done!</div>
            <div className="description mt-2">
              You've just earned a badge. Check it out on your profile.
            </div>
            {showCloseButton() && (
              <Button
                text="See my badges"
                additionalClasses="mt-3"
                onClick={() => navigateTo('/profile')}
              />
            )}
          </div>
        ) : (
          <div className="d-flex flex-column justify-content-center align-items-center">
            <img src={CompleteIcon} alt="icon" />
            <div className="heading mt-3">Nicely done!</div>
            <div className="description mt-2">
              Congrats for completing{' '}
              <strong>{journeyName?.replaceAll('&amp;', '&')}!</strong>
              <br />
              Onwards and upwards.
            </div>
            {showCloseButton() && (
              <Button
                text="Back to home"
                additionalClasses="mt-3"
                onClick={() => navigateTo('/')}
              />
            )}
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};
