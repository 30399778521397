import { Outlet } from 'react-router-dom';
import { Sidebar } from '@Shared/DS';

export const Layout = () => (
  <>
    <div className="layout">
      <Sidebar />
      <Outlet />
    </div>
  </>
);
