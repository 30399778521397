import { Link, useLocation } from 'react-router-dom';
import { memo, useMemo } from 'react';
import { FloatingMenu } from '@Shared/DS';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { UserType } from '@base/API';
import { NavbarLogo } from '../../Molecules';
import './NavFloatingMenu.scss';

const MenuFooter = () => {
  const { user } = useUserContext();

  return (
    <div className="profile-details-styling nav-floating-menu">
      {user.type === UserType.CANDIDATE ? null : (
        <Link
          to="/profile"
          state={null}
          style={{ textDecoration: 'none', color: 'black' }}
        >
          <div className="row pt-5">
            <div className="col-3 me-2">
              <img
                alt=""
                src={user!.photoUrl}
                className="profile-details-avatar"
                width="50"
                height="50"
              ></img>
            </div>
            <div className="col-8 text-start g-0">
              <div className="fs-20 first-name-styling pt-1">
                {user.firstName} {user.lastName}
              </div>
              <div className="fs-14">{user.email}</div>
            </div>
          </div>
        </Link>
      )}
    </div>
  );
};

export const NavFloatingMenu = memo(() => {
  const { pathname } = useLocation();
  const { user } = useUserContext();
  const isCompanyMember = useMemo(
    () => user?.type === UserType.COMPANY_MEMBER,
    [user],
  );
  const isAdmin = useMemo(() => user?.type === UserType.COMPANY_ADMIN, [user]);
  const isSuperAdmin = useMemo(
    () => user?.type === UserType.SUPER_ADMIN,
    [user],
  );
  const isCandidate = useMemo(() => user?.type === UserType.CANDIDATE, [user]);
  const menuItems = useMemo(
    () =>
      [
        {
          label: 'Home',
          url: '/',
        },
        {
          label: 'Runbooks',
          url: '/runbooks',
        },
        {
          label: 'People',
          url: '/people',
        },
        {
          label: 'Settings',
          url: '/settings',
        },
      ].filter((item) => {
        if (isCompanyMember || isCandidate) {
          return item.label === 'Home' || item.label === 'People';
        } else if (isAdmin || isSuperAdmin) {
          return true;
        } else {
          return false;
        }
      }),
    [isCompanyMember, isAdmin, isSuperAdmin, isCandidate],
  );

  const processedMenuItems = useMemo(
    () =>
      menuItems.map((item) => ({
        ...item,
        isSelected: pathname === item.url,
      })),
    [pathname, menuItems],
  );

  return (
    <FloatingMenu
      fullScreen
      title={<NavbarLogo />}
      items={processedMenuItems}
      footerComponent={<MenuFooter />}
    />
  );
});
