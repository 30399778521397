import { JourneyStatus } from '@API';
import { StatusPill } from '@Shared/DS';

interface JourneyStatusPillProps {
  status: JourneyStatus;
}

export const JourneyStatusPill = (props: JourneyStatusPillProps) => {
  const { status } = props;
  switch (status) {
    case JourneyStatus.COMPLETED:
      return <StatusPill variant="completed">Completed</StatusPill>;
    case JourneyStatus.NOT_STARTED:
      return <StatusPill variant="notStarted">Not started</StatusPill>;
    case JourneyStatus.IN_PROGRESS:
      return <StatusPill variant="inProgress">In progress</StatusPill>;
    case JourneyStatus.DRAFT:
      return <StatusPill variant="notStarted">In draft</StatusPill>;
    default:
      return <div></div>;
  }
};
