import { useState } from 'react';
import { Eye } from '@Shared/Icons';
import { Button } from '@Shared/DS';
import { FormPreviewModal } from '@Shared/Components/FormPreviewModal';

interface FormPreviewProps {
  className?: string;
  formId: string;
}

export const FormPreview = ({ className, formId }: FormPreviewProps) => {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  return (
    <>
      <Button
        additionalClasses={className}
        buttonStyle="outline"
        icon={<Eye />}
        onClick={() => setIsPreviewOpen(true)}
        text="Preview"
      />

      <FormPreviewModal
        formId={formId}
        isOpen={isPreviewOpen}
        onClose={() => setIsPreviewOpen(false)}
      />
    </>
  );
};
