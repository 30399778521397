import { BlockBuilder } from '@Shared/Components/BlockBuilder/BlockBuilder';
import { BlocksLoop } from '@Shared/Components/BlocksLoop/BlocksLoop';
import { EmbeddedContentModal } from '@Shared/Components/BlockTypes/EmbeddedContent/EmbeddedContentModal/EmbeddedContentModal';
import { EditAlertModal } from '@Shared/Components/EditAlertModal/EditAlertModal';
import {
  Milestones,
  JourneyAssigneeCard,
  PlaybookSettingsModal,
  Button,
} from '@Shared/DS';
import { AssignJourneyModal } from '@Shared/Components/AssignJourneyModal/AssignJourneyModal';
import './JourneyBuilder.scss';
import { scrollToTop } from '@Shared/utils/utils';
import Loader from '@Shared/Components/Loader/Loader';
import { JourneyStatus } from '@base/API';
import { JourneyHeader } from './Components/JourneyHeader/JourneyHeader';
import { useJourneyBuilder } from './useJourneyBuilder';
import { CompleteJourneyModal } from './Components/CompleteJourneyModal/CompleteJourneyModal';

export const JourneyBuilder = () => {
  const {
    currentJourney,
    isEditable,
    showBlockBuilder,
    selectedMilestoneIndex,
    showEmbeddedContentModal,
    showLoader,
    showSaved,
    journeyProgress,
    showAssignJourneyModal,
    isAssignee,
    isAdmin,
    isCollaborator,
    isBusy,
    isSaving,
    isJourneyLoading,
    journeyBadge,
    showCompleteJourneyModal,
    showEditConfirmationModal,
    isContentCustomizationAllowed,
    showSettingsModal,
    location,
    setShowSettingsModal,
    setShowEditConfirmationModal,
    filteredMilestones,
    setIsContentCustomizationAllowed,
    setShowCompleteJourneyModal,
    viewJourney,
    exitPreview,
    setIsBusy,
    setShowBlockBuilder,
    setSelectedMilestoneIndex,
    setShowLoader,
    setShowSaved,
    setShowAssignJourneyModal,
    setShowEmbeddedContentModal,
    setIsSaving,
    addNewBlock,
    handleMilestoneTextChange,
    addNewMilestone,
    removeMilestone,
    handleBack,
    handleUpdateMilestoneBlocks,
    addEmbeddedContentBlock,
    updateDeletedBlock,
    setFilteredMilestones,
    handleReorderMilestones,
    completeJourney,
    isNextChapterEnabled,
    archiveUnArchiveChapter,
    setCurrentJourney,
    checkAllMilestoneCompleted,
    copyPlaybookLink,
  } = useJourneyBuilder();

  return (
    <div className="container-fluid builder-view-wrapper">
      {isJourneyLoading ? (
        <Loader active={isJourneyLoading} />
      ) : (
        <>
          <div className="row">
            <JourneyAssigneeCard
              progress={journeyProgress}
              journeyStatus={currentJourney.status}
            />
            <Milestones
              milestones={filteredMilestones}
              selectedMilestoneIndex={selectedMilestoneIndex}
              setSelectedMilestoneIndex={setSelectedMilestoneIndex}
              handleMilestoneTextChange={handleMilestoneTextChange}
              addNewMilestone={addNewMilestone}
              removeMilestone={removeMilestone}
              isEditable={isEditable && isContentCustomizationAllowed}
              handleReorderMilestones={handleReorderMilestones}
              isBusy={isBusy}
              progress={journeyProgress}
              journeyStatus={currentJourney.status}
              mode={currentJourney.mode}
              lockChapters={!isNextChapterEnabled()}
              isParentPlaybook={!currentJourney.parentJourneyID}
              archiveUnarchiveChapter={archiveUnArchiveChapter}
            />
            <div
              className={
                isEditable && isContentCustomizationAllowed
                  ? `col-12 col-lg-9 col-xl-10`
                  : `col-12 col-lg-12 col-xl-12`
              }
            >
              <JourneyHeader
                journeyName={currentJourney.name}
                isAssignee={isAssignee}
                isAdmin={isAdmin}
                isCollaborator={isCollaborator}
                isParentJourney={!currentJourney.parentJourneyID}
                isSaving={isSaving}
                isEditable={isEditable}
                showLoader={showLoader}
                showSaved={showSaved}
                onBack={handleBack}
                viewJourney={viewJourney}
                exitPreview={exitPreview}
                journeyStatus={currentJourney.status}
                journeyMode={currentJourney.mode}
                setShowEditConfirmationModal={setShowEditConfirmationModal}
                isContentCustomizationAllowed={isContentCustomizationAllowed}
                setShowSettingModal={setShowSettingsModal}
                copyPlaybookLink={copyPlaybookLink}
              />
              <div className="container">
                <div className="row builder-content g-0 mb-5">
                  <div className="col-12">
                    <div className="builder-title">
                      {filteredMilestones.length &&
                        filteredMilestones[selectedMilestoneIndex]?.name}
                    </div>
                    <BlocksLoop
                      milestones={filteredMilestones}
                      isParentPlaybook={!currentJourney.parentJourneyID}
                      selectedMilestoneIndex={selectedMilestoneIndex}
                      isEditable={isEditable && isContentCustomizationAllowed}
                      handleUpdateMilestoneBlocks={handleUpdateMilestoneBlocks}
                      setShowLoader={setShowLoader}
                      setShowSaved={setShowSaved}
                      isAssignee={
                        isAssignee &&
                        (currentJourney.status === JourneyStatus.NOT_STARTED ||
                          currentJourney.status === JourneyStatus.COMPLETED)
                          ? false
                          : isAssignee
                      }
                      handleDeleteBlock={updateDeletedBlock}
                      isBusy={isBusy}
                      setIsBusy={setIsBusy}
                      setIsSaving={setIsSaving}
                      setMilestones={setFilteredMilestones}
                    />
                    <div
                      className={`col-12 next-milestone d-flex ${
                        selectedMilestoneIndex > 0
                          ? 'justify-content-between'
                          : 'justify-content-end'
                      } mt-4 me-4`}
                    >
                      {selectedMilestoneIndex > 0 && (
                        <button
                          className="btn border-0"
                          onClick={() => {
                            setSelectedMilestoneIndex(
                              selectedMilestoneIndex - 1,
                            );
                            scrollToTop();
                          }}
                        >
                          <i className="bi bi-chevron-left cursor-pointer"></i>{' '}
                          {filteredMilestones[selectedMilestoneIndex - 1]?.name}
                        </button>
                      )}

                      {selectedMilestoneIndex + 1 <
                        filteredMilestones.length && (
                        <button
                          disabled={!isNextChapterEnabled()}
                          className="btn border-0"
                          onClick={() => {
                            setSelectedMilestoneIndex(
                              selectedMilestoneIndex + 1,
                            );
                            scrollToTop();
                          }}
                        >
                          {filteredMilestones[selectedMilestoneIndex + 1]?.name}{' '}
                          <i className="bi bi-chevron-right cursor-pointer"></i>
                        </button>
                      )}
                    </div>

                    {isEditable && isContentCustomizationAllowed && (
                      <div className="col-12 my-4">
                        {!showBlockBuilder ? (
                          <Button
                            tooltipPlacement="top"
                            title="Add a new content block"
                            text="Add New Block"
                            onClick={() => setShowBlockBuilder(true)}
                          />
                        ) : (
                          <div className="col-12 cursor-pointer mt-4">
                            <BlockBuilder addNewBlock={addNewBlock} />
                          </div>
                        )}
                      </div>
                    )}

                    {/* Show complete button only to assignee */}
                    {isAssignee && checkAllMilestoneCompleted && (
                      <>
                        <div className="d-flex justify-content-center">
                          <Button
                            title={
                              currentJourney.status === JourneyStatus.COMPLETED
                                ? 'Runbook is complete'
                                : 'Complete runbook'
                            }
                            text={
                              currentJourney.status === JourneyStatus.COMPLETED
                                ? 'Runbook is complete'
                                : 'Click to finish'
                            }
                            additionalClasses="complete-milestone-btn flex-grow-1"
                            disabled={
                              currentJourney.status === JourneyStatus.COMPLETED
                            }
                            onClick={() => completeJourney()}
                          />
                        </div>

                        <div className="mt-2 text-center">
                          Congrats, you have reached the end.
                        </div>
                      </>
                    )}

                    {location.pathname.includes('/preview') &&
                      filteredMilestones.length ===
                        selectedMilestoneIndex + 1 && (
                        <div className="d-flex justify-content-center">
                          <Button
                            text={
                              filteredMilestones.length ===
                              selectedMilestoneIndex + 1
                                ? 'Click to finish'
                                : ''
                            }
                            additionalClasses="complete-milestone-btn flex-grow-1"
                            disabled={true}
                            onClick={() => {}}
                          />
                        </div>
                      )}
                  </div>
                </div>
              </div>

              <EmbeddedContentModal
                showModal={showEmbeddedContentModal}
                handleShowModal={setShowEmbeddedContentModal}
                addEmbeddedContentBlock={addEmbeddedContentBlock}
                assignedUserId={currentJourney.assignedUserID}
              />

              <AssignJourneyModal
                handleShowModal={setShowAssignJourneyModal}
                showModal={showAssignJourneyModal}
                playbook={currentJourney}
              />

              <CompleteJourneyModal
                showModal={showCompleteJourneyModal}
                handleShowModal={setShowCompleteJourneyModal}
                badge={journeyBadge}
                journeyName={currentJourney.name}
              />

              <EditAlertModal
                title="Edit runbook content"
                description="Are you sure you want to edit? Edited blocks will not be updated with new content from the main runbook."
                showModal={showEditConfirmationModal}
                handleShowModal={setShowEditConfirmationModal}
                handleProceed={() => {
                  setIsContentCustomizationAllowed(true);
                  setShowEditConfirmationModal(false);
                }}
                showLoader={false}
              />

              <PlaybookSettingsModal
                showModal={showSettingsModal}
                handleShowModal={setShowSettingsModal}
                parentPlaybook={currentJourney}
                updateParentPlaybook={(playbook) => setCurrentJourney(playbook)}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
