export const compareStrings = (a: string, b: string) => {
  const strA = a.toLowerCase();
  const strB = b.toLocaleLowerCase();

  if (strA < strB) {
    return -1;
  }

  if (strA > strB) {
    return 1;
  }

  return 0;
};
