import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { type TaskBlock } from '@API';
import { deleteBlock, updateTaskBlock } from '@api/apis';
import { useNonInitialEffect } from '@Hooks/useNonInitialEffect';
import { updateBlockDescription } from '@Shared/utils/utils';
import { useUserContext } from '@base/Context/UserContext/UserContext';

export const useTaskBlock = (
  block: TaskBlock,
  handleUpdateMilestoneBlocks: Function,
  handleDeleteBlock: Function,
  setShowLoader: Function,
  setShowSaved: Function,
  isParentPlaybook: boolean,
  isEditable: boolean,
  setIsSaving?: Function,
) => {
  const [title, setTitle] = useState(block.title);
  const [description, setDescription] = useState(block.description ?? '');
  const [taskStatus, setTaskStatus] = useState(block.status);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { user } = useUserContext();
  const { id } = useParams();

  const handleUpdateBlockDescription = async () => {
    if (isEditable && id && block.description !== description) {
      const updateDescriptionImages = await updateBlockDescription(
        description ?? '',
        block.id,
        block.type,
        block.milestoneID,
        user.userOrganizationId,
        id,
      );

      setShowLoader(true);
      await updateTaskBlock({
        description: updateDescriptionImages,
        id: block.id,
        updatedInChild: !isParentPlaybook,
      });
      handleUpdateMilestoneBlocks({
        ...block,
        id: block.id,
        description: updateDescriptionImages,
      });
      setDescription(updateDescriptionImages);
    }

    setShowLoader(false);
    setShowSaved(true);
  };

  const updateTaskTitle = async () => {
    if (block.title !== title || block.status !== taskStatus) {
      setShowLoader(true);
      await updateTaskBlock({
        id: block.id,
        title: title,
        updatedInChild: !isParentPlaybook,
      });
      handleUpdateMilestoneBlocks({
        ...block,
        id: block.id,
        title: title,
      });
      setShowLoader(false);
      setShowSaved(true);
    }
  };

  const handleCompleteTask = async (isCompleted: boolean) => {
    setTaskStatus(isCompleted);
    await updateTaskBlock({
      id: block.id,
      isCompleted: isCompleted,
      status: isCompleted,
    });

    handleUpdateMilestoneBlocks({
      ...block,
      isCompleted: isCompleted,
      status: isCompleted,
    });
  };

  const removeBlock = async () => {
    let id;
    if (isParentPlaybook) {
      id = await deleteBlock(block.id, block.type);
    } else {
      id = await updateTaskBlock({
        ...block,
        isArchived: true,
      });
    }

    if (id) {
      handleDeleteBlock(block.id, block.type, block.orderNo);
      setShowConfirmationModal(false);
    }
  };

  useEffect(() => {
    setTitle(block.title);
    setDescription(block.description ?? '');
    setTaskStatus(block.status);
  }, [block]);

  useNonInitialEffect(() => {
    if (block.title !== title || block.status !== taskStatus) {
      setIsSaving?.(true);
    } else {
      setIsSaving?.(false);
    }
    const timeOut = setTimeout(() => {
      updateTaskTitle();
    }, 1000);

    return () => clearTimeout(timeOut);
  }, [title]);

  useNonInitialEffect(() => {
    if (id && block.description !== description) {
      setIsSaving?.(true);
    } else {
      setIsSaving?.(false);
    }
    const timeOut = setTimeout(() => {
      handleUpdateBlockDescription();
    }, 1000);

    return () => clearTimeout(timeOut);
  }, [description]);

  return {
    title,
    taskStatus,
    description,
    showConfirmationModal,
    setShowConfirmationModal,
    setDescription,
    setTitle,
    handleCompleteTask,
    removeBlock,
  };
};
