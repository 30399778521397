import { Card } from 'react-bootstrap';
import './EmailVerification.scss';
import { TextField } from '@Shared/DS/Molecules';
import { Button } from '@Shared/DS';
import { useEmailVerification } from './useEmailVerification';

export const EmailVerification = () => {
  const {
    currentUserEmail,
    isSubmitted,
    isForgotPassword,
    isLoading,
    setCurrentUserEmail,
    handleForgotPassword,
    handleNext,
  } = useEmailVerification();

  return (
    <div className="page-wrapper email-verification-wrapper text-center">
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col-md-auto">
            <div className="text-center">
              <img
                className="logo"
                src={require('../../Assets/Images/kinfolk_logo.png')}
                alt="Kinfolk logo"
              />
            </div>
            <div className="mt-4 fs-24-25272a">
              {' '}
              {isForgotPassword
                ? 'Forgot your password'
                : 'Please confirm your email'}
            </div>
            {isForgotPassword && (
              <div className="mt-3">
                Enter your work email you use to login at kinfolkhq.com.
              </div>
            )}
            <div className="row justify-content-center mx-2">
              <Card className="mt-3 pb-5">
                <Card.Body>
                  <label
                    className="fs-14-semi-bold col-12 text-start mt-4"
                    htmlFor="email"
                  >
                    Email address
                  </label>
                  <TextField
                    id={'email'}
                    type="email"
                    defaultValue={currentUserEmail}
                    setValue={setCurrentUserEmail}
                    fieldName="Email"
                    width=""
                    disabled={!isForgotPassword}
                    placeholder="Enter your work email address"
                    additionalClasses={` 
            ${!currentUserEmail && currentUserEmail.length === 0 && isSubmitted ? 'is-invalid' : ''}`}
                  />
                </Card.Body>
              </Card>
            </div>
            <Button
              buttonStyle="primary"
              additionalClasses="fs-16-medium-bold mt-4"
              disabled={isLoading}
              onClick={isForgotPassword ? handleForgotPassword : handleNext}
              text={
                isLoading
                  ? 'Loading...'
                  : isForgotPassword
                    ? 'Request new password'
                    : 'Next'
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};
