import { Button, TextField } from '@Shared/DS';
import { Form } from 'react-bootstrap';
import { type Form as IForm, TriggerActionType } from '@base/models/form.model';
import './TriggerAction.scss';
import {
  AsyncTypeahead,
  Highlighter,
  Menu,
  MenuItem,
  Typeahead,
} from 'react-bootstrap-typeahead';
import { useSearch } from '@base/Hooks/useSearch';
import { MessageTextEditor } from '@Shared/Components/TextEditor/MessageTextEditor/MessageTextEditor';
import { useTriggerAction } from './useTriggerAction';

interface TriggerActionProps {
  className?: string;
  form?: IForm;
  isEditable: boolean;
}

export const TriggerAction = (props: TriggerActionProps) => {
  const { className, form, isEditable } = props;
  const {
    state,
    orgForms,
    dataStoreFormColumns,
    updateState,
    addTriggerAction,
    updateFormTriggers,
    updateTriggerAction,
    deleteTriggerAction,
    selectReceiver,
    selectCC,
  } = useTriggerAction(isEditable, form);
  const { users, findUser } = useSearch();

  return (
    <div className={`trigger-action ${className ?? ''}`}>
      <label className="support-text">YOUR FORM AUTOMATIONS</label>

      <div className={'container text-center'}>
        {!state.triggers.length && (
          <div className="mb-4">
            <h4>No automation yet!</h4>
            <p className="mt-2">
              Bring your form to life by automating tasks.
              <br />
              Automations are triggered on form submit.
            </p>
          </div>
        )}

        {state.triggers.map((trigger) =>
          trigger.action === TriggerActionType.SEND_EMAIL ? (
            <div key={trigger.id} className="mb-4">
              {/* send email component */}
              <div className="row justify-content-end">
                <div className="col-4">
                  <Button
                    additionalClasses="active"
                    buttonStyle="rounded-dashed"
                    text={'Send email to:'}
                  />
                </div>
                <div className="col-4 text-end">
                  {isEditable && (
                    <Button
                      buttonStyle="rounded-dashed"
                      text={''}
                      icon="bi bi-trash"
                      onClick={() => deleteTriggerAction(trigger.id)}
                    />
                  )}
                </div>
              </div>
              <div className="action">
                <i className="bi bi-diamond-fill"></i>

                <Form.Group className="mb-4">
                  <label
                    className="text-start d-block form-label"
                    htmlFor="recipient"
                  >
                    Recipient
                  </label>
                  <AsyncTypeahead
                    allowNew
                    id="recipient"
                    onBlur={updateFormTriggers}
                    onSearch={findUser}
                    minLength={0}
                    disabled={!isEditable}
                    isLoading={false}
                    onChange={(users: any[]) => selectReceiver(trigger, users)}
                    labelKey={(option: any) =>
                      option.runbookRoleId ??
                      option.dataStoreColumnValue ??
                      option.email ??
                      option.userId ??
                      option.string ??
                      ''
                    }
                    options={[
                      {
                        isDataLink: true,
                        string: 'Runbook: THIS / Role: MANAGER',
                        runbookRoleId: 'MANAGER',
                      },
                      {
                        isDataLink: true,
                        string: 'Runbook: THIS / Role: BUDDY',
                        runbookRoleId: 'BUDDY',
                      },
                      {
                        isDataLink: true,
                        string: 'Runbook: THIS / Role: HRBP',
                        runbookRoleId: 'HRBP',
                      },
                      {
                        isDataLink: true,
                        string: 'Runbook: THIS / Role: HROPS',
                        runbookRoleId: 'HROPS',
                      },
                      {
                        isDataLink: true,
                        string: 'Runbook: THIS / Role: IT',
                        runbookRoleId: 'IT',
                      },
                      {
                        isDataLink: true,
                        string:
                          'Runbook / Property: assignee / Property: personalEmail',
                      },
                      {
                        isDataLink: true,
                        string:
                          'Runbook / Property: assignee / Property: email',
                      },
                      ...dataStoreFormColumns,
                      ...users,
                    ]}
                    renderMenu={(
                      results,
                      {
                        newSelectionPrefix,
                        paginationText,
                        renderMenuItemChildren,
                        ...menuProps
                      },
                      state,
                    ) => (
                      <CustomMenu
                        results={results}
                        menuProps={menuProps}
                        state={state}
                      />
                    )}
                    placeholder="Start typing..."
                    newSelectionPrefix="Click to save: "
                    selected={
                      trigger.params['receiver']
                        ? [trigger.params['receiver'] as any]
                        : []
                    }
                  />
                </Form.Group>

                <Form.Group className="mb-4">
                  <label
                    className="text-start d-block form-label"
                    htmlFor="cc-recipient"
                  >
                    CC
                  </label>
                  <AsyncTypeahead
                    multiple
                    id="cc-recipient"
                    isLoading={false}
                    minLength={0}
                    disabled={!isEditable}
                    onSearch={findUser}
                    onBlur={updateFormTriggers}
                    onChange={(users: any[]) => selectCC(trigger, users)}
                    labelKey={(option: any) =>
                      option.runbookRoleId ??
                      option.dataStoreColumnValue ??
                      option.email ??
                      option.userId ??
                      option.string ??
                      ''
                    }
                    options={[
                      {
                        isDataLink: true,
                        string: 'Runbook: THIS / Role: MANAGER',
                        runbookRoleId: 'MANAGER',
                      },
                      {
                        isDataLink: true,
                        string: 'Runbook: THIS / Role: BUDDY',
                        runbookRoleId: 'BUDDY',
                      },
                      {
                        isDataLink: true,
                        string: 'Runbook: THIS / Role: HRBP',
                        runbookRoleId: 'HRBP',
                      },
                      {
                        isDataLink: true,
                        string: 'Runbook: THIS / Role: HROPS',
                        runbookRoleId: 'HROPS',
                      },
                      {
                        isDataLink: true,
                        string: 'Runbook: THIS / Role: IT',
                        runbookRoleId: 'IT',
                      },
                      {
                        isDataLink: true,
                        string:
                          'Runbook / Property: assignee / Property: personalEmail',
                      },
                      {
                        isDataLink: true,
                        string:
                          'Runbook / Property: assignee / Property: email',
                      },
                      ...dataStoreFormColumns,
                      ...users,
                    ]}
                    renderMenu={(results, menuProps, state) => (
                      <CustomMenu
                        results={results}
                        menuProps={menuProps}
                        state={state}
                      />
                    )}
                    placeholder="Start typing..."
                    newSelectionPrefix="Add email: "
                    selected={(trigger.params['cc'] as any[]) ?? []}
                  />
                </Form.Group>

                <hr className="mb-4" />

                <Form.Group className="mb-4">
                  <label className="text-start d-block form-label">Title</label>
                  <TextField
                    id="title"
                    type="text"
                    disabled={!isEditable}
                    onBlur={updateFormTriggers}
                    defaultValue={trigger.params.subject?.toString() ?? ''}
                    placeholder="Enter text"
                    setValue={(value: string) =>
                      updateTriggerAction(trigger.id, 'params', {
                        ...trigger.params,
                        subject: value,
                      })
                    }
                  />
                </Form.Group>

                <Form.Group className="mb-4">
                  <label className="text-start d-block form-label">
                    Description
                  </label>
                  <MessageTextEditor
                    value={trigger.params.message?.toString() ?? ''}
                    allowImageUpload={false}
                    setValue={(message) =>
                      updateTriggerAction(trigger.id, 'params', {
                        ...trigger.params,
                        message,
                      })
                    }
                    theme="snow"
                    isEditable={isEditable}
                    placeholder=""
                  />
                </Form.Group>
              </div>
            </div>
          ) : trigger.action === TriggerActionType.SEND_MESSAGE ? (
            <div key={trigger.id} className="mb-4">
              {/* send email component */}
              <div className="row justify-content-end">
                <div className="col-4">
                  <Button
                    additionalClasses="active"
                    buttonStyle="rounded-dashed"
                    text={'Send message to:'}
                  />
                </div>
                <div className="col-4 text-end">
                  {isEditable && (
                    <Button
                      buttonStyle="rounded-dashed"
                      text={''}
                      icon="bi bi-trash"
                      onClick={() => deleteTriggerAction(trigger.id)}
                    />
                  )}
                </div>
              </div>

              <div className="action">
                <i className="bi bi-diamond-fill"></i>

                <Form.Group className="mb-4">
                  <label
                    className="text-start d-block form-label"
                    htmlFor="recipient"
                  >
                    Recipient
                  </label>
                  <AsyncTypeahead
                    id="recipient"
                    isLoading={false}
                    onSearch={findUser}
                    disabled={!isEditable}
                    minLength={0}
                    onBlur={updateFormTriggers}
                    onChange={(users: any[]) => selectReceiver(trigger, users)}
                    labelKey={(option: any) =>
                      option.runbookRoleId ??
                      option.dataStoreColumnValue ??
                      option.email ??
                      option.userId ??
                      option.string ??
                      ''
                    }
                    options={[
                      {
                        isDataLink: true,
                        string: 'Runbook: THIS / Role: MANAGER',
                        runbookRoleId: 'MANAGER',
                      },
                      {
                        isDataLink: true,
                        string: 'Runbook: THIS / Role: BUDDY',
                        runbookRoleId: 'BUDDY',
                      },
                      {
                        isDataLink: true,
                        string: 'Runbook: THIS / Role: HRBP',
                        runbookRoleId: 'HRBP',
                      },
                      {
                        isDataLink: true,
                        string: 'Runbook: THIS / Role: HROPS',
                        runbookRoleId: 'HROPS',
                      },
                      {
                        isDataLink: true,
                        string: 'Runbook: THIS / Role: IT',
                        runbookRoleId: 'IT',
                      },
                      ...dataStoreFormColumns,
                      ...users,
                    ]}
                    renderMenu={(results, menuProps, state) => (
                      <CustomMenu
                        results={results}
                        menuProps={menuProps}
                        state={state}
                      />
                    )}
                    placeholder="Start typing..."
                    newSelectionPrefix="Add email: "
                    selected={
                      trigger.params['receiver']
                        ? [trigger.params['receiver'] as any]
                        : []
                    }
                  />
                </Form.Group>

                <hr className="mb-4" />

                <Form.Group className="mb-4">
                  <label className="text-start d-block form-label">Title</label>
                  <TextField
                    id="title"
                    type="text"
                    disabled={!isEditable}
                    onBlur={updateFormTriggers}
                    defaultValue={trigger.params.subject?.toString() ?? ''}
                    placeholder="Enter text"
                    setValue={(value: string) =>
                      updateTriggerAction(trigger.id, 'params', {
                        ...trigger.params,
                        subject: value,
                      })
                    }
                  />
                </Form.Group>

                <Form.Group className="mb-4">
                  <label className="text-start d-block form-label">
                    Description
                  </label>
                  <MessageTextEditor
                    value={trigger.params.message?.toString() ?? ''}
                    allowImageUpload={false}
                    setValue={(message) =>
                      updateTriggerAction(trigger.id, 'params', {
                        ...trigger.params,
                        message,
                      })
                    }
                    theme="snow"
                    isEditable={isEditable}
                    placeholder=""
                    extraVerticalSpacing
                  />
                </Form.Group>
              </div>
            </div>
          ) : trigger.action === TriggerActionType.SET_RUNBOOK_ROLE ? (
            <div key={trigger.id} className="mb-4">
              {/* send email component */}
              <div className="row justify-content-end">
                <div className="col-4">
                  <Button
                    additionalClasses="active"
                    buttonStyle="rounded-dashed"
                    text={'Set runbook role:'}
                  />
                </div>
                <div className="col-4 text-end">
                  {isEditable && (
                    <Button
                      buttonStyle="rounded-dashed"
                      text={''}
                      icon="bi bi-trash"
                      onClick={() => deleteTriggerAction(trigger.id)}
                    />
                  )}
                </div>
              </div>
              <div className="action">
                <i className="bi bi-diamond-fill"></i>

                <Form.Group className="mb-4">
                  <label
                    className="text-start d-block form-label"
                    htmlFor="assign"
                  >
                    Assign:
                  </label>
                  <AsyncTypeahead
                    id="assign"
                    onSearch={findUser}
                    disabled={!isEditable}
                    isLoading={false}
                    minLength={0}
                    onBlur={updateFormTriggers}
                    onChange={(users: any[]) => selectReceiver(trigger, users)}
                    labelKey={(option: any) =>
                      option.dataStoreColumnValue ??
                      option.email ??
                      option.userId ??
                      option.string ??
                      ''
                    }
                    options={[...dataStoreFormColumns, ...users]}
                    placeholder="Select user"
                    renderMenu={(results, menuProps, state) => (
                      <CustomMenu
                        results={results}
                        menuProps={menuProps}
                        state={state}
                      />
                    )}
                    newSelectionPrefix="Add email: "
                    selected={
                      trigger.params['receiver']
                        ? [trigger.params['receiver'] as any]
                        : []
                    }
                  />
                </Form.Group>

                <Form.Group className="mb-4">
                  <label className="text-start d-block form-label" htmlFor="as">
                    As:
                  </label>
                  <Typeahead
                    id="as"
                    onBlur={updateFormTriggers}
                    disabled={!isEditable}
                    onChange={(roles: any[]) => {
                      updateTriggerAction(trigger.id, 'params', {
                        ...trigger.params,
                        role: roles[0],
                      });
                    }}
                    labelKey={(option: any) =>
                      option.runbookRoleId ??
                      option.email ??
                      option.userId ??
                      option.string ??
                      ''
                    }
                    options={[
                      { runbookRoleId: 'MANAGER' },
                      { runbookRoleId: 'BUDDY' },
                      { runbookRoleId: 'HRBP' },
                      { runbookRoleId: 'HROPS' },
                      { runbookRoleId: 'IT' },
                    ]}
                    placeholder="Select collaborator role"
                    newSelectionPrefix="Add email: "
                    selected={
                      trigger.params['role']
                        ? [trigger.params['role'] as any]
                        : []
                    }
                  />
                </Form.Group>
              </div>
            </div>
          ) : trigger.action === TriggerActionType.SEND_FORM ? (
            <div key={trigger.id} className="mb-4">
              {/* send email component */}
              <div className="row justify-content-end">
                <div className="col-4">
                  <Button
                    additionalClasses="action-type-btn active"
                    buttonStyle="rounded-dashed"
                    text={'Send form:'}
                  />
                </div>
                <div className="col-4 text-end">
                  {isEditable && (
                    <Button
                      buttonStyle="rounded-dashed"
                      text={''}
                      icon="bi bi-trash"
                      onClick={() => deleteTriggerAction(trigger.id)}
                    />
                  )}
                </div>
              </div>
              <div className="action">
                <i className="bi bi-diamond-fill"></i>

                <Form.Group className="mb-4">
                  <label
                    className="text-start d-block form-label"
                    htmlFor="recipient"
                  >
                    Recipient
                  </label>
                  <AsyncTypeahead
                    id="recipient"
                    disabled={!isEditable}
                    isLoading={false}
                    onSearch={findUser}
                    minLength={0}
                    onBlur={updateFormTriggers}
                    onChange={(users: any[]) => selectReceiver(trigger, users)}
                    labelKey={(option: any) =>
                      option.runbookRoleId ??
                      option.dataStoreColumnValue ??
                      option.email ??
                      option.userId ??
                      option.string ??
                      ''
                    }
                    options={[
                      {
                        isDataLink: true,
                        string: 'Runbook: THIS / Role: MANAGER',
                        runbookRoleId: 'MANAGER',
                      },
                      {
                        isDataLink: true,
                        string: 'Runbook: THIS / Role: BUDDY',
                        runbookRoleId: 'BUDDY',
                      },
                      {
                        isDataLink: true,
                        string: 'Runbook: THIS / Role: HRBP',
                        runbookRoleId: 'HRBP',
                      },
                      {
                        isDataLink: true,
                        string: 'Runbook: THIS / Role: HROPS',
                        runbookRoleId: 'HROPS',
                      },
                      {
                        isDataLink: true,
                        string: 'Runbook: THIS / Role: IT',
                        runbookRoleId: 'IT',
                      },
                      ...dataStoreFormColumns,
                      ...users,
                    ]}
                    renderMenu={(results, menuProps, state) => (
                      <CustomMenu
                        results={results}
                        menuProps={menuProps}
                        state={state}
                      />
                    )}
                    placeholder="Start typing..."
                    newSelectionPrefix="Add email: "
                    selected={
                      trigger.params['receiver']
                        ? [trigger.params['receiver'] as any]
                        : []
                    }
                  />
                </Form.Group>

                <Form.Group className="mb-4">
                  <label
                    className="text-start d-block form-label"
                    htmlFor="form"
                  >
                    Select form
                  </label>
                  <Typeahead
                    id="form"
                    disabled={!isEditable}
                    onBlur={updateFormTriggers}
                    onChange={(users: any[]) => {
                      updateTriggerAction(trigger.id, 'params', {
                        ...trigger.params,
                        form: { formId: users[0]?.id, name: users[0]?.name },
                      });
                    }}
                    labelKey={(option: any) => option.name ?? ''}
                    options={orgForms ?? []}
                    placeholder="Select a form"
                    selected={
                      trigger.params['form']
                        ? [trigger.params['form'] as any]
                        : []
                    }
                  />
                </Form.Group>

                <hr className="mb-4" />

                <Form.Group className="mb-4">
                  <label className="text-start d-block form-label">Title</label>
                  <TextField
                    id="title"
                    type="text"
                    disabled={!isEditable}
                    onBlur={updateFormTriggers}
                    defaultValue={trigger.params.messageTitle?.toString() ?? ''}
                    placeholder="Enter text"
                    setValue={(value: string) =>
                      updateTriggerAction(trigger.id, 'params', {
                        ...trigger.params,
                        messageTitle: value,
                      })
                    }
                  />
                </Form.Group>

                <Form.Group className="mb-4">
                  <label className="text-start d-block form-label">
                    Description
                  </label>
                  <MessageTextEditor
                    value={trigger.params.messageDescription?.toString() ?? ''}
                    allowImageUpload={false}
                    setValue={(value) =>
                      updateTriggerAction(trigger.id, 'params', {
                        ...trigger.params,
                        messageDescription: value,
                      })
                    }
                    theme="snow"
                    isEditable={isEditable}
                    placeholder=""
                  />
                </Form.Group>
              </div>
            </div>
          ) : trigger.action === TriggerActionType.SEND_ACTION ? (
            <div key={trigger.id} className="mb-4">
              {/* send email component */}
              <div className="row justify-content-end">
                <div className="col-4">
                  <Button
                    additionalClasses="active"
                    buttonStyle="rounded-dashed"
                    text={'Send action to:'}
                  />
                </div>

                <div className="col-4 text-end">
                  {isEditable && (
                    <Button
                      buttonStyle="rounded-dashed"
                      text={''}
                      icon="bi bi-trash"
                      onClick={() => deleteTriggerAction(trigger.id)}
                    />
                  )}
                </div>
              </div>

              <div className="action">
                <i className="bi bi-diamond-fill"></i>

                <Form.Group className="mb-4">
                  <label
                    className="text-start d-block form-label"
                    htmlFor="recipient"
                  >
                    Recipient
                  </label>
                  <AsyncTypeahead
                    id="recipient"
                    isLoading={false}
                    minLength={0}
                    allowNew
                    onSearch={findUser}
                    disabled={!isEditable}
                    onBlur={updateFormTriggers}
                    onChange={(users: any[]) => selectReceiver(trigger, users)}
                    labelKey={(option: any) =>
                      option.runbookRoleId ??
                      option.dataStoreColumnValue ??
                      option.email ??
                      option.userId ??
                      option.string ??
                      ''
                    }
                    options={[
                      {
                        isDataLink: true,
                        string: 'Runbook: THIS / Role: MANAGER',
                        runbookRoleId: 'MANAGER',
                      },
                      {
                        isDataLink: true,
                        string: 'Runbook: THIS / Role: BUDDY',
                        runbookRoleId: 'BUDDY',
                      },
                      {
                        isDataLink: true,
                        string: 'Runbook: THIS / Role: HRBP',
                        runbookRoleId: 'HRBP',
                      },
                      {
                        isDataLink: true,
                        string: 'Runbook: THIS / Role: HROPS',
                        runbookRoleId: 'HROPS',
                      },
                      {
                        isDataLink: true,
                        string: 'Runbook: THIS / Role: IT',
                        runbookRoleId: 'IT',
                      },
                      {
                        isDataLink: true,
                        string:
                          'Runbook / Property: assignee / Property: personalEmail',
                      },
                      {
                        isDataLink: true,
                        string:
                          'Runbook / Property: assignee / Property: email',
                      },
                      ...dataStoreFormColumns,
                      ...users,
                    ]}
                    renderMenu={(results, menuProps, state) => (
                      <CustomMenu
                        results={results}
                        menuProps={menuProps}
                        state={state}
                      />
                    )}
                    placeholder="Start typing..."
                    newSelectionPrefix="Click to save: "
                    selected={
                      trigger.params['receiver']
                        ? [trigger.params['receiver'] as any]
                        : []
                    }
                  />
                </Form.Group>

                <hr className="mb-4" />

                <Form.Group className="mb-4">
                  <label className="text-start d-block form-label">Title</label>
                  <TextField
                    id="title"
                    type="text"
                    disabled={!isEditable}
                    onBlur={updateFormTriggers}
                    defaultValue={trigger.params.actionName?.toString() ?? ''}
                    placeholder="Enter text"
                    setValue={(value: string) =>
                      updateTriggerAction(trigger.id, 'params', {
                        ...trigger.params,
                        actionName: value,
                      })
                    }
                  />
                </Form.Group>

                <Form.Group className="mb-4">
                  <label className="text-start d-block form-label">
                    Description
                  </label>
                  <MessageTextEditor
                    value={trigger.params.actionDescription?.toString() ?? ''}
                    allowImageUpload={false}
                    setValue={(value) =>
                      updateTriggerAction(trigger.id, 'params', {
                        ...trigger.params,
                        actionDescription: value,
                      })
                    }
                    onBlur={updateFormTriggers}
                    theme="snow"
                    isEditable={isEditable}
                    placeholder=""
                  />
                </Form.Group>
              </div>
            </div>
          ) : null,
        )}

        {/* Show notification type component */}
        {isEditable && (
          <Button
            text="Add automation"
            buttonStyle="rounded-dashed"
            icon="bi bi-plus"
            onClick={() =>
              updateState(
                'showNotificationOptions',
                !state.showNotificationOptions,
              )
            }
          />
        )}
        <div className="d-flex justify-content-center">
          {state.showNotificationOptions && (
            <div className="action action-type">
              <i className="bi bi-diamond-fill"></i>
              <div className="d-flex flex-wrap gap-2 justify-content-center">
                <Button
                  text="Send email"
                  buttonStyle="rounded-dashed"
                  onClick={() => addTriggerAction(TriggerActionType.SEND_EMAIL)}
                />
                <Button
                  text="Send message"
                  buttonStyle="rounded-dashed"
                  onClick={() =>
                    addTriggerAction(TriggerActionType.SEND_MESSAGE)
                  }
                />
                <Button
                  text="Send form"
                  buttonStyle="rounded-dashed"
                  onClick={() => addTriggerAction(TriggerActionType.SEND_FORM)}
                />
                <Button
                  text="Send action"
                  buttonStyle="rounded-dashed"
                  onClick={() =>
                    addTriggerAction(TriggerActionType.SEND_ACTION)
                  }
                />
                <Button
                  text="Set runbook role"
                  buttonStyle="rounded-dashed"
                  onClick={() =>
                    addTriggerAction(TriggerActionType.SET_RUNBOOK_ROLE)
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const CustomMenu = ({
  results,
  menuProps,
  state,
}: {
  results: any[];
  menuProps: any;
  state: any;
}) => (
  <Menu {...menuProps}>
    {results.some((option) => !!option.runbookRoleId) && (
      <>
        <Menu.Header>COLLABORATORS</Menu.Header>
        {results
          .filter((option) => !!option.runbookRoleId)
          .map((option, index) => (
            <MenuItem
              key={option.runbookRoleId}
              option={option}
              position={index}
            >
              <Highlighter search={state.text}>
                {option.runbookRoleId}
              </Highlighter>
            </MenuItem>
          ))}

        <Menu.Divider />
      </>
    )}

    {results.some((option) => !!option.isDataLink && !option.runbookRoleId) && (
      <>
        <Menu.Header>DATASTORE</Menu.Header>
        {results
          .filter((option) => !!option.isDataLink && !option.runbookRoleId)
          .map((option, index) => (
            <MenuItem
              key={option.id}
              option={option}
              position={index + results.length}
            >
              <Highlighter search={state.text}>{option.string}</Highlighter>
            </MenuItem>
          ))}
      </>
    )}

    {results.some((option) => option.id && option.email) && (
      <>
        <Menu.Header>USERS</Menu.Header>
        {results
          .filter((option) => option.id && option.email)
          .map((option, index) => (
            <MenuItem
              key={option.id}
              option={option}
              position={index + results.length}
            >
              <Highlighter search={state.text}>
                {option.firstName
                  ? `${option.firstName} ${option.lastName ?? ''}<${option.email}>`
                  : option.email}
              </Highlighter>
            </MenuItem>
          ))}
      </>
    )}

    {results.some((option) => !!option.customOption) && (
      <>
        <Menu.Header>EXTERNAL USER</Menu.Header>
        {results
          .filter((option) => option.customOption)
          .map((option, index) => (
            <MenuItem
              key={option.label}
              option={option}
              position={index + results.length}
            >
              <Highlighter search={state.text}>{option.label}</Highlighter>
            </MenuItem>
          ))}
      </>
    )}
  </Menu>
);
