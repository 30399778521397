import React from 'react';
import { AddElementDropdown } from '@Shared/DS';
import { type FormElement } from '@models/form.model';
import { elementGroups } from './AddElement.const';

interface AddElementProps {
  onSelect: (element: FormElement) => void;
  className?: string;
}

export const AddElement: React.FC<AddElementProps> = ({
  className,
  onSelect,
}) => (
  <AddElementDropdown
    buttonLabel="Add something"
    className={className}
    elementGroups={elementGroups}
    onSelect={onSelect}
  />
);
