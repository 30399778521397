import { Form, Modal, type ModalProps } from 'react-bootstrap';
import { type User, type UserType } from '@API';
import { FormSelect, TextField, Button } from '@Shared/DS';
import { roles } from '@base/models/common.model';
import { useUpdateMemberModal } from './useUpdateMemberModal';
import styles from './UpdateMemberModal.module.scss';

interface UpdateMemberModalProps extends ModalProps {
  user: User;
  handleSave: () => void;
}
export const UpdateMemberModal = (props: UpdateMemberModalProps) => {
  const { showModal, user, handleShowModal, handleSave } = props;
  const {
    state,
    customFieldsData,
    isLoading,
    handleInputChange,
    handleCustomFieldChange,
    handleSubmit,
  } = useUpdateMemberModal(user.id, user.userOrganizationId, handleSave);

  return (
    <Modal
      size="lg"
      fullscreen="md-down"
      aria-labelledby="contained-modal-title-vcenter "
      centered
      backdrop="static"
      show={showModal}
      onHide={() => handleShowModal(false)}
      className={`${styles.modalContent}`}
    >
      <Modal.Header>
        <Modal.Title className="fs-24-semi-bold-4C5564">
          Update member
        </Modal.Title>
        <button
          type="button"
          className="btn-close modal-close-btn"
          data-bs-dismiss="modal"
          onClick={() => handleShowModal(false)}
          aria-label="Close"
        ></button>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <div className={styles.loader}>
            <div className={`spinner-border`} role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="row gx-0">
              <label htmlFor="firstName">First name</label>
              <TextField
                id={'firstName'}
                type={'text'}
                placeholder={'Enter first name'}
                required
                setValue={(value: string) =>
                  handleInputChange(value, 'firstName')
                }
                defaultValue={state.firstName}
                fieldName={'First Name'}
              ></TextField>

              <label className="mt-3" htmlFor="lastName">
                Last name
              </label>
              <TextField
                id={'lastName'}
                type={'text'}
                placeholder={'Enter last name'}
                required
                setValue={(value: string) =>
                  handleInputChange(value, 'lastName')
                }
                defaultValue={state.lastName}
                fieldName={'Last Name'}
              ></TextField>

              <label className="mt-3" htmlFor="workEmail">
                Work email
              </label>
              <TextField
                id="workEmail"
                type="email"
                placeholder="Enter work email address"
                required
                setValue={(value: string) => handleInputChange(value, 'email')}
                defaultValue={state.email}
                fieldName={'Work email'}
              ></TextField>

              <label className="mt-3" htmlFor="personalEmail">
                Personal email
              </label>
              <TextField
                id={'personalEmail'}
                type={'text'}
                placeholder={'Enter personal email'}
                setValue={(value: string) =>
                  handleInputChange(value, 'personalEmail')
                }
                defaultValue={state.personalEmail ?? ''}
                fieldName={'Email'}
              ></TextField>

              <label className="mt-3" htmlFor="jobTitle">
                Job title
              </label>
              <TextField
                id={'jobTitle'}
                type={'text'}
                placeholder={'Enter job title'}
                setValue={(value: string) =>
                  handleInputChange(value, 'jobTitle')
                }
                defaultValue={state.jobTitle ?? ''}
                fieldName={'Personal email'}
              ></TextField>

              <label className="mt-3" htmlFor="phone">
                Phone
              </label>
              <TextField
                id={'phone'}
                type={'text'}
                placeholder={'Enter phone number'}
                setValue={(value: string) => handleInputChange(value, 'phone')}
                defaultValue={state.phone ?? ''}
                fieldName={'Phone'}
              ></TextField>

              <label className="mt-3" htmlFor="startDate">
                Start date
              </label>
              <TextField
                id={'startDate'}
                type={'date'}
                placeholder={'Enter start date'}
                setValue={(value: string) =>
                  handleInputChange(value, 'startDate')
                }
                defaultValue={state.startDate ?? ''}
                fieldName={'Start date'}
              ></TextField>
            </div>

            <Form.Group>
              <label className="mt-3 form-label" htmlFor="userRole">
                Role
              </label>
              <FormSelect
                id="userRole"
                required
                selected={state.type}
                options={roles}
                setSelected={(value: UserType) =>
                  handleInputChange(value, 'type')
                }
              />
            </Form.Group>

            {customFieldsData?.dataStore.columns.map((column) => (
              <Form.Group className="mt-3" key={column.id}>
                <label className="form-label" htmlFor={column.name}>
                  {column.name}
                </label>
                <Form.Select
                  aria-label="form select"
                  className={`form-select-wrapper ${styles.option}`}
                  value={state.customFields[column.refId]?.label ?? ''}
                  onChange={(e) => handleCustomFieldChange(e, column.refId)}
                >
                  <option value="" hidden className={styles.option}>
                    Select {column.name}
                  </option>
                  {column.items.map((item) => (
                    <option key={item.id} value={item.label}>
                      {item.label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            ))}

            <Button
              text="Save"
              showLoading={state.isSaving}
              type="submit"
              additionalClasses="mt-3 w-100"
              disabled={state.isSaving}
            />
          </form>
        )}
      </Modal.Body>
    </Modal>
  );
};
