import { type PillProps } from '@Shared/DS';
import { type FormEvent } from '../ActivityPanel.types';

interface PillsData extends Required<Pick<PillProps, 'color' | 'colorType'>> {
  children: string;
}

const scheduledPillData: PillsData = {
  children: 'Scheduled',
  color: 'purple',
  colorType: 'light',
};

const sentPillData: PillsData = {
  children: 'Sent',
  color: 'brightGreen',
  colorType: 'light',
};

export const statusToPills: Record<FormEvent['status'], PillsData[]> = {
  DRAFT: [scheduledPillData],
  SCHEDULED: [scheduledPillData],
  SENT: [
    sentPillData,
    { children: 'Pending submission', color: 'gray', colorType: 'light' },
  ],
  OVERDUE: [
    sentPillData,
    { children: 'Overdue', color: 'orange', colorType: 'light' },
  ],
  DONE: [
    sentPillData,
    { children: 'Submitted', color: 'brightGreen', colorType: 'light' },
  ],
  FAILED: [
    { children: 'Submission failed', color: 'red', colorType: 'bright' },
  ],
  SKIPPED: [{ children: 'Skipped', color: 'gray', colorType: 'bright' }],
};
