import { Row, Col, Card } from 'react-bootstrap';
import { type User } from '@API';

const LearnMore = (props: { user: User | undefined }) => {
  const { user } = props;

  return (
    <div className="learn-more-wrapper">
      <Card>
        <Card.Body>
          <Row>
            <Col xs="9">
              <h4 className="fs-20-semi-bold-4C5564">I'm looking to learn</h4>
            </Col>
          </Row>
          {user?.LearnMoreOn?.items?.map((interest: any, i: number) => (
            <div key={i} className="pill-button fs-16-medium-bold-F3E8DA">
              {interest.shareInfo !== undefined
                ? interest.shareInfo.name
                : interest.name}
            </div>
          ))}
        </Card.Body>
      </Card>
    </div>
  );
};

export default LearnMore;
