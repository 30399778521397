import {
  getFormById,
  getFormBySlug as getFormBySlugApi,
  substituteDynamicTags,
} from '@api/apis';
import { useQuery } from '@tanstack/react-query';
import { Keys } from '@base/keys/queryKeys';
import { sureString } from '@Shared/utils/sure';
import { useUserContext } from '@Context/UserContext/UserContext';
import { convertChannelSpecificFormatToHtml } from '@Shared/utils/utils';
import { type Form, FormLinkType, type FormTriggers } from '@models/form.model';
import { useMessengerChannel } from '@Hooks/useMessengerChannel';
import { type Channel } from '@API';
import { useMemo } from 'react';
import type { SubstituteDynamicFieldsRequest } from '@api/api.model';

const convertFormTriggerMessagesToHtml = (form: Form, channel: Channel) => {
  const onSubmitTriggers = form.triggers?.onSubmit?.map((trigger) =>
    trigger.action === 'SEND_MESSAGE'
      ? {
          ...trigger,
          params: {
            ...trigger.params,
            message: convertChannelSpecificFormatToHtml(
              trigger.params.message?.toString() ?? '',
              channel,
            ),
          },
        }
      : trigger,
  );

  const triggers: FormTriggers = form.triggers
    ? { ...form.triggers, onSubmit: onSubmitTriggers }
    : {};

  return { ...form, triggers };
};

const getFormBySlug = async (formSlug?: string) => {
  if (!formSlug) {
    return;
  }

  const form = await getFormBySlugApi(formSlug);
  if (!form || form.link.type !== FormLinkType.Runbook) {
    return form;
  }

  let texts: { [key: string]: string } = {};
  form.elements.forEach((element, index) => {
    if ('text' in element && element.text) {
      texts[index] = element.text;
    } else if ('description' in element && element.description) {
      texts[index] = element.description;
    }
  });

  const request: SubstituteDynamicFieldsRequest = {
    orgId: form.organizationId,
    data: {
      context: {
        runbook: form.link.id, // This may be varied later (e.g. if we have forms attached to users or other objects,  not runbooks), but for now it’s runbook only
      },
      texts,
    },
  };
  const res = await substituteDynamicTags(request);
  if (res) {
    form.elements.forEach((element, index) => {
      if ('text' in element && element.text) {
        element.text = res.texts[index];
      } else if ('description' in element && element.description) {
        element.description = res.texts[index];
      }
    });
  }

  return form;
};

export const useGetFormById = (formId: string | undefined) => {
  const { user } = useUserContext();
  const channel = useMessengerChannel();

  const { data, ...queryResult } = useQuery({
    queryKey: Keys.getFormById(sureString(formId, '')),
    queryFn: () => getFormById(user.userOrganizationId, sureString(formId, '')),
    enabled: !!(formId && user.userOrganizationId),
  });

  const form = useMemo(
    () => (data ? convertFormTriggerMessagesToHtml(data, channel) : data),
    [data, channel],
  );

  return { form, ...queryResult };
};

export const useGetFormBySlug = (formSlug: string | undefined) => {
  const channel = useMessengerChannel();
  const { data, ...queryResult } = useQuery({
    queryKey: Keys.getFormBySlug(sureString(formSlug, '')),
    queryFn: () => getFormBySlug(formSlug),
    enabled: !!formSlug,
  });

  const form = useMemo(
    () => (data ? convertFormTriggerMessagesToHtml(data, channel) : data),
    [data, channel],
  );

  return { form, ...queryResult };
};
