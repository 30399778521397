import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { type AddMessageModalProps } from '@base/models/journeyLibrary.model';
import { FloatingLabelDropdown } from '@Shared/DS';
import { Button } from '@Shared/DS/Atoms';
import clsx from 'clsx';
import { MessageTextEditor } from '../../TextEditor/MessageTextEditor/MessageTextEditor';
import styles from './MessengerTab.module.scss';
import { useMessengerTab } from './useMessengerTab';

export const MessengerTab = (props: AddMessageModalProps) => {
  const {
    showLoader,
    onHideModal,
    messageBody,
    isSubmitted,
    isSaving,
    isEditable,
    time,
    times,
    daysFromOptions,
    selectedDaysFrom,
    setSelectedDaysFrom,
    setTime,
    setMessageBody,
    createNewMessage,
    user,
  } = useMessengerTab(props);

  return (
    <div className={clsx(styles.messengerTabWrapper, 'row')}>
      {!user.Organization?.slackAccessToken &&
        !user.Organization?.msTeamsTenantId && (
          <>
            <Alert
              variant="warning"
              className="d-flex align-items-center"
              role="alert"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-exclamation-triangle-fill warning-alert-icon me-2"
                viewBox="0 0 16 16"
              >
                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
              </svg>
              <span>
                To send Slack or Teams messages, install the app from
                <Link to="/settings" className="alert-link">
                  Settings
                </Link>
                .
              </span>
            </Alert>
          </>
        )}{' '}
      <div className="col-12">
        This will be sent as a direct message in{' '}
        {user.Organization?.slackAccessToken && 'Slack'}
        {user.Organization?.msTeamsTenantId && 'Teams'}
      </div>
      <div className="d-flex flex-wrap mt-sm-2 mt-3">
        <div className="flex-fill">
          <FloatingLabelDropdown
            label="Send timing"
            disabled={!isEditable}
            options={daysFromOptions}
            selected={selectedDaysFrom}
            setSelected={setSelectedDaysFrom}
          />
        </div>

        <div className="flex-fill mt-2 mt-lg-0 ms-lg-2">
          <FloatingLabelDropdown
            label="Time (UTC)"
            disabled={!isEditable}
            options={times}
            selected={time}
            setSelected={setTime}
          />
        </div>
      </div>
      <label
        className="fs-14-semi-bold-25272a text-start mt-4"
        htmlFor={'message'}
      >
        Message<span className="required-field-sign">*</span>
      </label>
      <MessageTextEditor
        value={messageBody}
        setValue={setMessageBody}
        theme="snow"
        isEditable={isEditable}
        placeholder="Write your message here"
        extraVerticalSpacing
      />
      {!messageBody.length && isSubmitted && (
        <div className="fs-14-bb242b text-start">
          Message shouldn't be empty
        </div>
      )}
      <div className="col-12">
        <div className={clsx(styles.saveBtn, 'mt-2')}>
          <Button
            buttonStyle="outline"
            additionalClasses="me-2"
            text="Cancel"
            onClick={onHideModal}
          />
          <Button
            showLoading={showLoader}
            text={`${
              isSaving ? (
                <>
                  <span role="status" aria-hidden="true">
                    Saving...
                  </span>
                </>
              ) : (
                'Save'
              )
            }`}
            disabled={!isEditable}
            type="submit"
            onClick={createNewMessage}
          />
        </div>
      </div>
    </div>
  );
};
