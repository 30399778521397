import { UserType, type JourneyCollaborator, type User } from '@base/API';

export const roles = [
  {
    key: 'Member',
    value: UserType.COMPANY_MEMBER,
  },
  {
    key: 'Admin',
    value: UserType.COMPANY_ADMIN,
  },
];

export interface CustomPlaybookCollaborator extends JourneyCollaborator {
  assignedUserName?: string;
  assignedUserEmail?: string;
}

export interface PlaybookPreActivationChecklist {
  unAssignedEvents: number;
  eventsInPast: number;
  meetingsMissingAttendee: number;
}

export enum EventType {
  ACTION = 'ACTION',
  MESSAGE = 'MESSAGE',
  FORM = 'FORM',
}

export enum Device {
  MOBILE = 'Mobile',
  DESKTOP = 'Desktop',
}

export const userTypeMap = {
  [UserType.SUPER_ADMIN]: 'Super Admin',
  [UserType.COMPANY_ADMIN]: 'Admin',
  [UserType.COMPANY_MEMBER]: 'Member',
  [UserType.ANONYMOUS]: 'Anonymous',
  [UserType.CANDIDATE]: 'Candidate',
};

export interface OrgUserCustomFields {
  dataStore: {
    id: string;
    organizationId: string;
    name: string;
    columns: CustomFieldColumn[];
    rows: Row[];
    rowSequence: number;
  };
}

export interface CustomFieldColumn {
  id: string;
  refId: string;
  type: string;
  name: string;
  referencedByForms: string[];
  items: Item[];
}

interface Item {
  id: string;
  label: string;
}

interface Row {
  refId: string;
  seqId: number;
  values: { [key: string]: SetItem };
}

interface SetItem {
  setItemId: string;
}

export interface CustomFields {
  [key: string]: { setItemId: string; label?: string };
}

export interface UserData {
  user: User;
  customFields: CustomFields;
}

export interface UserUpdateRequest {
  updateParams: User;
  customFields: CustomFields;
}
