import { ToggleDropDownSimple } from '@Shared/Components/DropDownField/DropDownField';
import { Button } from '@Shared/DS';

interface IActionDueDate {
  daysFromOptions: string[];
  selectedDaysFrom: string;
  reminders: { id: string | null; daysFrom: string }[];
  setReminders: Function;
  onSelectDay: (daysFrom: string) => void;
  onSelectReminderDay: (daysFrom: string, index: number) => void;
  onRemoveReminder: (index: number) => void;
}

export const ActionDueDate = ({
  daysFromOptions,
  selectedDaysFrom,
  reminders,
  setReminders,
  onSelectDay,
  onSelectReminderDay,
  onRemoveReminder,
}: IActionDueDate) => (
  <>
    <div className="row g-3 justify-content-between">
      <div className="col-6">
        <label className="text-start form-label" htmlFor="dueBy">
          Due by
          <span className="required-field-sign">*</span>
        </label>
        <ToggleDropDownSimple
          className="w-100"
          items={daysFromOptions}
          selectedItem={selectedDaysFrom}
          onSelect={onSelectDay}
        />
      </div>
      <div className="col-6">
        <label className="text-start form-label w-100" htmlFor="reminder">
          Second reminder
        </label>
        {!reminders.length ? (
          <Button
            buttonStyle="tertiary"
            icon="bi bi-plus"
            text="Add second reminder"
            onClick={() =>
              setReminders(
                (curr: { id: string | null; daysFrom: string }[]) => [
                  ...curr,
                  { id: null, daysFrom: 'Start day' },
                ],
              )
            }
          />
        ) : (
          reminders.map((reminder, index) => (
            <div className="d-flex align-items-center" key={index}>
              <ToggleDropDownSimple
                className="w-100"
                items={daysFromOptions}
                selectedItem={reminder.daysFrom}
                onSelect={(daysFrom) => onSelectReminderDay(daysFrom, index)}
              />
              <i
                title="Click to remove reminder"
                className="bi bi-x ms-1 cursor-pointer"
                onClick={() => onRemoveReminder(index)}
              ></i>
            </div>
          ))
        )}
      </div>
    </div>
    <div className="d-flex align-items-center gap-3">
      <div className="flex-fill"></div>
      <div className="flex-fill"></div>
    </div>
  </>
);
