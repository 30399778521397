import './Settings.scss';
import { Container } from 'react-bootstrap';
import useBreakpoint from 'use-breakpoint';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { UserType } from '@API';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { BREAKPOINTS_DESKTOP_1280 } from '@Shared/utils/utils';
import { useCallbackUrl } from '@base/Hooks/useCallbackUrl';
import { NotificationSettings } from '@Shared/DS';
import { GeneralTab } from './Components/GeneralTab/GeneralTab';
import { IntegrationsTab } from './Components/IntegrationsTab/IntegrationsTab';
import { MembersTab } from './Components/MembersTab/MembersTab';

export default function Settings() {
  const { breakpoint } = useBreakpoint(BREAKPOINTS_DESKTOP_1280, 'tablet');
  const { user } = useUserContext();
  const navigate = useNavigate();
  useCallbackUrl();

  useEffect(() => {
    if (user.id && user.type === UserType.COMPANY_MEMBER) {
      navigate('/');
    }
  }, [navigate, user.id, user.type]);

  return (
    <div className="settings-page-wrapper d-flex">
      <Container fluid className="p-0">
        <div className="row no-pad">
          <div className="col-md-12 col-lg-12">
            {user !== undefined && (
              <>
                <div className="settings-page-container p-md-2 p-lg-5">
                  <div className="container">
                    <div
                      className={
                        breakpoint === 'mobile'
                          ? 'fs-48-semi-bold text-start'
                          : 'fs-64-semi-bold text-start'
                      }
                    >
                      Settings
                    </div>
                    <nav className="nav-scroll-wrapper">
                      <div
                        className="nav nav-tabs nav-overflow-wrapper"
                        id="nav-tab"
                        role="tablist"
                      >
                        {user.type === UserType.SUPER_ADMIN && (
                          <button
                            className="nav-link fs-18"
                            id="nav-general-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-general"
                            type="button"
                            role="tab"
                            aria-controls="nav-general"
                            aria-selected="true"
                          >
                            General
                          </button>
                        )}
                        <button
                          className="nav-link active fs-18"
                          id="nav-integration-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-integration"
                          type="button"
                          role="tab"
                          aria-controls="nav-integration"
                          aria-selected="false"
                        >
                          Integrations
                        </button>
                        <button
                          className="nav-link fs-18"
                          id="nav-team-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-team"
                          type="button"
                          role="tab"
                          aria-controls="nav-team"
                          aria-selected="false"
                        >
                          Team Members
                        </button>
                        <button
                          className="nav-link fs-18"
                          id="nav-notification-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-notification"
                          type="button"
                          role="tab"
                          aria-controls="nav-notification"
                          aria-selected="false"
                        >
                          Notifications
                        </button>
                      </div>
                    </nav>
                    <div className="tab-content" id="nav-tabContent">
                      {user.type === UserType.SUPER_ADMIN && (
                        <div
                          className="tab-pane fade"
                          id="nav-general"
                          role="tabpanel"
                          aria-labelledby="nav-general-tab"
                        >
                          <GeneralTab />
                        </div>
                      )}
                      <div
                        className="tab-pane fade show active"
                        id="nav-integration"
                        role="tabpanel"
                        aria-labelledby="nav-integration-tab"
                      >
                        <IntegrationsTab />
                      </div>
                      <div
                        className="tab-pane fade"
                        id="nav-team"
                        role="tabpanel"
                        aria-labelledby="nav-team-tab"
                      >
                        <MembersTab />
                      </div>
                      <div
                        className="tab-pane fade"
                        id="nav-notification"
                        role="tabpanel"
                        aria-labelledby="nav-notification-tab"
                      >
                        <NotificationSettings />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
}
