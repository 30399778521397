import { sureString } from '@Shared/utils/sure';
import { createFormInstance, listOrgForms } from '@api/apis';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { Keys } from '@base/keys/queryKeys';
import { type Form } from '@base/models/form.model';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

export const useResourceUrlModal = (
  handleShowModal: Function,
  addEmbeddedContentBlock: Function,
  assignedUserID?: string | null,
) => {
  const [url, setUrl] = useState('');
  const [isSubmitted, setSubmitted] = useState(false);
  const { id } = useParams();
  const [showResourceModal, setShowResourceModal] = useState(false);
  const [selectedForm, setSelectedForm] = useState<Form>();
  const { user } = useUserContext();

  const handleSubmit = async () => {
    setSubmitted(true);
    if (!selectedForm && !url) {
      return;
    }

    if (selectedForm) {
      const currentURL = window.location.href;
      let link = `${new URL(currentURL).origin}`;
      if (!assignedUserID) {
        link += `/form/${selectedForm.id}`;
      } else {
        const slug = await createFormInstance(
          user.userOrganizationId,
          selectedForm.id,
          sureString(id),
          assignedUserID,
        );
        link += `/forms/static/${slug}`;
      }

      addEmbeddedContentBlock(link, true);
      setSelectedForm(undefined);
    }

    if (url !== '') {
      addEmbeddedContentBlock(url);
      setUrl('');
    }

    handleShowModal(false);
  };

  const { data: orgForms } = useQuery({
    queryKey: Keys.getOrgForms(user.userOrganizationId),
    queryFn: () => listOrgForms(sureString(user.userOrganizationId)),
    enabled: !!user.userOrganizationId,
  });

  return {
    url,
    isSubmitted,
    showResourceModal,
    selectedForm,
    setSelectedForm,
    setUrl,
    setShowResourceModal,
    setSubmitted,
    handleSubmit,
    orgForms,
  };
};
