import { flexRender } from '@tanstack/react-table';
import { CaretUpFill, CaretDownFill } from 'react-bootstrap-icons';
import { type JourneyMeetingsProps } from '@base/models/journeyLibrary.model';
import { Button } from '@Shared/DS';
import { openExternalUrl } from '@Shared/utils/utils';
import { AddMeetingModal } from '../AddMeetingModal/AddMeetingModal';
import { InfoModal } from '../InfoModal/InfoModal';
import { useMeetings } from './useMeetings';
import './Meetings.scss';

export const Meetings = (props: JourneyMeetingsProps) => {
  const { isEditable, isTemplate, isParentJourney } = props;
  const {
    data,
    table,
    columns,
    assignee,
    showInfoModal,
    isFetching,
    isBookMeetingButtonDisabled,
    pendingMeetingsCount,
    showAddMeetingModal,
    showConfirmationModal,
    selectedMeeting,
    setShowInfoModal,
    activeSubscription,
    handleUpdateMeeting,
    handleShowAddMeetingModal,
    setShowAddMeetingModal,
    handleSaveMeeting,
    onHandleBookMeetings,
    onClickBookMeeting,
    setShowConfirmationModal,
    handleDeleteMeeting,
  } = useMeetings(props);

  return (
    <div className="meetings-page">
      {!activeSubscription ? (
        <div className="meetings__locked">
          <span className="meetings__locked-text">
            Automated meeting scheduling is available on the paid plan. Contact
            us to get a demo.
            <br />
            <Button
              buttonStyle="outline"
              text="Book a call"
              icon="bi-calendar-check"
              additionalClasses="mt-2"
              onClick={() =>
                openExternalUrl('https://calendly.com/kinfolkhq/jeet')
              }
            />
          </span>
        </div>
      ) : (
        <>
          {isEditable && (
            <div className="table-header">
              <div className="d-flex">
                <div className="p-2 flex-grow-1">
                  <p className="fs-18-semi-bold-4c5564 table-header--title">
                    Meetings
                  </p>
                  <span className="table-header--description">
                    Kinfolk will automatically send meeting invites where
                    there's a free slot during working hours within the
                    specified days. Meetings for new hires will get booked from
                    day 1 onwards.{' '}
                    {!isParentJourney
                      ? 'Where there is no availability, try changing the days when the meeting should take place.'
                      : null}
                  </span>
                </div>
                {!isParentJourney && (
                  <div className="p-2">
                    <Button
                      buttonStyle="outline"
                      text="Book meetings"
                      icon="bi bi-magic"
                      onClick={onClickBookMeeting}
                      disabled={isBookMeetingButtonDisabled || isParentJourney}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
          <table className="col-12 kin-table table meeting-list-table">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      className="px-4"
                      key={header.id}
                      style={{
                        width:
                          header.getSize() !== 0 ? header.getSize() : undefined,
                      }}
                    >
                      {header.isPlaceholder ? null : (
                        <span
                          {...{
                            className: header.column.getCanSort()
                              ? 'post-sorting-icon cursor-pointer select-none d-flex'
                              : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                          {{
                            asc: <CaretUpFill />,
                            desc: <CaretDownFill />,
                          }[header.column.getIsSorted() as string] ?? (
                            <CaretUpFill className="initial-sorting-icon" />
                          )}
                        </span>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {isFetching && (
                <tr>
                  <td className="text-center" colSpan={columns.length}>
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden text-center">
                        Loading...
                      </span>
                    </div>
                  </td>
                </tr>
              )}

              {!isFetching && !data.length && (
                <tr>
                  <td className="text-center" colSpan={columns.length}>
                    No meeting has been created
                  </td>
                </tr>
              )}

              {!isFetching &&
                table.getRowModel().rows.map((row) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td
                        className="px-4"
                        key={cell.id}
                        style={{
                          width:
                            cell.column.getSize() !== 0
                              ? cell.column.getSize()
                              : undefined,
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>

            {isEditable && (
              <tfoot>
                <tr>
                  <td colSpan={columns.length}>
                    <Button
                      buttonStyle="outline"
                      icon="bi bi-plus"
                      text="Add new meeting"
                      onClick={() => handleShowAddMeetingModal(true)}
                    />
                  </td>
                </tr>
              </tfoot>
            )}
          </table>
        </>
      )}
      <AddMeetingModal
        showModal={showAddMeetingModal}
        handleShowModal={setShowAddMeetingModal}
        handleSaveMeeting={handleSaveMeeting}
        handleUpdateMeeting={handleUpdateMeeting}
        isTemplate={isTemplate}
        isParentJourney={isParentJourney}
        selectedMeeting={selectedMeeting}
        journeyAssignee={assignee}
      />
      <InfoModal
        title="Confirm booking meetings"
        description={
          <MeetingConfirmationModalBody
            pendingMeetingsCount={pendingMeetingsCount}
          />
        }
        showModal={showInfoModal}
        onClickConfirmButton={onHandleBookMeetings}
        handleShowModal={setShowInfoModal}
      ></InfoModal>
      <InfoModal
        title="Delete meeting"
        description={<div>Are you sure you want to delete this meeting?</div>}
        showModal={showConfirmationModal}
        onClickConfirmButton={handleDeleteMeeting}
        handleShowModal={setShowConfirmationModal}
        isDeleteButton={true}
      ></InfoModal>
    </div>
  );
};

interface MeetingConfirmationModalBodyProps {
  pendingMeetingsCount: number;
}
export const MeetingConfirmationModalBody = (
  props: MeetingConfirmationModalBodyProps,
) => {
  const { pendingMeetingsCount } = props;
  return (
    <div>
      {`We will try and book `}
      <strong>{pendingMeetingsCount}</strong>
      {` meetings.`}
    </div>
  );
};
