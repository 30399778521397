import './Onboarding.scss';
import { ProgressBar } from 'react-bootstrap';
import { BackButton } from '@Shared/Components/BackButton/BackButton';
import { useOnboarding } from './useOnboarding';
import FirstOnboardingPage from './Components/FirstOnboardingPage/FirstOnboardingPage';
import InterestOnboardingPage from './Components/InterestOnboardingPage/InterestsOnboardingPage';
import LastOnboardingPage from './Components/LastOnboardingPage/LastOnboardingPage';

export const Onboarding = () => {
  const {
    user,
    currentNumber,
    allOrganisationInterests,
    allOrganisationShareInfo,
    userProfilePhoto,
    setSuggestOptionsInterests,
    handleClick,
    createProgressBar,
    setSuggestOptionsLearnKnowledge,
    handleBack,
    handleUpdateUserPhoto,
  } = useOnboarding();

  return (
    <div className="onboarding-page-wrapper">
      <div className="flex-row onboarding-margin onboarding-height">
        <div className="pt-4 position-relative onboarding-container">
          <ProgressBar className="m-4">
            {createProgressBar(currentNumber)}
          </ProgressBar>
        </div>
        <div className="back-btn m-5">
          {currentNumber !== 0 && <BackButton handleClick={handleBack} />}
        </div>
        {currentNumber === 0 ? (
          <FirstOnboardingPage
            handleClick={handleClick}
            handleUpdateUserPhoto={handleUpdateUserPhoto}
          />
        ) : currentNumber === 1 ? (
          <InterestOnboardingPage
            key={currentNumber}
            handleClick={handleClick}
            description="Add any favourite activities or subjects you know a lot about. There might be teammates into the same things or looking to learn more about it."
            suggestOptions={allOrganisationInterests}
            setSuggestOptions={setSuggestOptionsInterests}
            options={allOrganisationInterests}
            currentNumber={currentNumber}
            alreadySelectedOptions={user.Interests}
          />
        ) : currentNumber === 2 ? (
          <InterestOnboardingPage
            key={currentNumber}
            handleClick={handleClick}
            description="Add some things you might like to learn more about—both in and out of work. Perhaps a teammate has this knowledge."
            suggestOptions={allOrganisationShareInfo}
            setSuggestOptions={setSuggestOptionsLearnKnowledge}
            options={allOrganisationShareInfo}
            currentNumber={currentNumber}
            alreadySelectedOptions={user.LearnMoreOn}
          />
        ) : (
          <LastOnboardingPage
            handleClick={handleClick}
            profilePicture={userProfilePhoto}
          />
        )}
      </div>
    </div>
  );
};
