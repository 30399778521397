import { createActionCollaborator, createJourneyFromTemplate } from '@api/apis';
import { type KinfolkTemplate, UserType } from '@base/API';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { Analytics } from '@base/analytics/analytics';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const useTemplateCard = (template: KinfolkTemplate) => {
  const [useTemplateButtonStyle, setUseTemplateButtonStyle] = useState<
    'd-none' | 'd-block'
  >('d-none');
  const [isBusy, setIsBusy] = useState(false);
  const { user } = useUserContext();
  const navigate = useNavigate();

  const createJourney = async (id: string) => {
    setIsBusy(true);
    const runbookId = await createJourneyFromTemplate(
      user.userOrganizationId,
      id,
      user.id,
    );
    setIsBusy(false);

    if (runbookId) {
      //assign collaborators to the journey's action if they have any
      createActionCollaborator([runbookId]);
      navigate(`/runbook/${runbookId}`);
      Analytics.playbookCreated(template.type, true, template.id);
    }
  };

  const handleViewTemplate = useCallback(
    (id: string) => {
      if (user.type === UserType.SUPER_ADMIN) {
        navigate(`/template/${id}`);
        return;
      }

      navigate(`/template/${id}/preview`);
    },
    [navigate, user.type],
  );

  return {
    isBusy,
    useTemplateButtonStyle,
    setUseTemplateButtonStyle,
    createJourney,
    handleViewTemplate,
  };
};
