import { Channel } from '@base/API';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';

interface INotificationChannel {
  selected: Channel;
  onSelect: (type: Channel) => void;
  showSlackError: boolean;
}

export const NotificationChannel = (props: INotificationChannel) => {
  const { selected, onSelect, showSlackError } = props;
  const { user } = useUserContext();

  return (
    <>
      <label className="text-start mt-4 " htmlFor={'notification-channel'}>
        Notification Channel
      </label>

      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="notification-channel"
          id="email-radio-button"
          checked={selected === Channel.EMAIL}
          value={Channel.EMAIL}
          onChange={(e) => onSelect(e.target.value as Channel)}
        />

        <label className="form-check-label" htmlFor="email-radio-button">
          Email
        </label>
      </div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="notification-channel"
          id="slack-radio-button"
          checked={selected === Channel.SLACK || selected === Channel.TEAMS}
          value={
            user?.Organization.msTeamsTenantId ? Channel.TEAMS : Channel.SLACK
          }
          onChange={(e) => onSelect(e.target.value as Channel)}
        />
        <label className="form-check-label" htmlFor="slack-radio-button">
          {user?.Organization.msTeamsTenantId ? 'Teams' : 'Slack'}
        </label>
      </div>

      {showSlackError && (
        <Alert
          variant="warning"
          className="d-flex align-items-center"
          role="alert"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-exclamation-triangle-fill warning-alert-icon me-2"
            viewBox="0 0 16 16"
          >
            <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
          </svg>
          <span>
            To send Slack and Teams messages, install the Kinfolk Slack app from
            the{' '}
            <Link to="/settings" className="alert-link">
              Settings
            </Link>
            .
          </span>
        </Alert>
      )}
    </>
  );
};
