import { useState } from 'react';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { getCurrentUserEmail, addOrganizationFromAdmin } from '@api/apis';
import { addNotificationToApp } from '@Shared/utils/utils';
import { Analytics } from '@base/analytics/analytics';

export const useCreateOrganization = () => {
  const [organizationName, setOrganizationName] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { getProfileData } = useUserContext();

  const handleCreateCompany = async () => {
    try {
      setIsLoading(true);
      setIsSubmitted(true);
      if (organizationName.length > 0) {
        const currentUserEmail = await getCurrentUserEmail();
        if (currentUserEmail) {
          const isAdded = await addOrganizationFromAdmin(
            currentUserEmail,
            organizationName,
          );
          if (isAdded) {
            getProfileData();
            Analytics.organizationCreated(organizationName, currentUserEmail);
          }
        }
      }
    } catch (e) {
      setIsLoading(false);
      addNotificationToApp('Failed to create organization', 'error');
    }

    setIsLoading(false);
  };

  return {
    organizationName,
    isSubmitted,
    isLoading,
    setOrganizationName,
    handleCreateCompany,
  };
};
