import { Modal } from 'react-bootstrap';
import { type ModalProps } from '@Shared/utils/utils';
import { useEditPersonalInfoModal } from '@Hooks/useEditPersonalInfoModal';
import { Button } from '@Shared/DS';
import EditPersonalInfo from '../../EditPersonalInfo/EditPersonalInfo';
import './EditPersonalInfoModal.scss';

const EditPersonalInfoModal = (props: ModalProps) => {
  const {
    firstName,
    lastName,
    email,
    workLocation,
    isSubmitted,
    jobTitle,
    team,
    profileImageUrl,
    isUploading,
    setFirstName,
    setLastName,
    setEmail,
    setWorkLocation,
    setJobTitle,
    setTeam,
    setProfileImageUrl,
    fileSelectedHandler,
    handleSaveModal,
  } = useEditPersonalInfoModal(props.handleShowModal, false);

  return (
    <Modal
      size="lg"
      backdrop="static"
      fullscreen="md-down"
      aria-labelledby="contained-modal-title-vcenter "
      centered
      show={props.showModal}
      onHide={() => props.handleShowModal(false)}
      className="edit-personal-info-wrapper"
    >
      <Modal.Header>
        <Modal.Title className="fs-24-semi-bold-4C5564">
          Edit Profile
        </Modal.Title>
        <button
          type="button"
          className="btn-close modal-close-btn"
          data-bs-dismiss="modal"
          onClick={() => props.handleShowModal(false)}
          aria-label="Close"
        ></button>
      </Modal.Header>
      <Modal.Body>
        <EditPersonalInfo
          photoUrl={profileImageUrl}
          setPhotoUrl={setProfileImageUrl}
          fileSelectedHandler={fileSelectedHandler}
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          workLocation={workLocation}
          setWorkLocation={setWorkLocation}
          jobTitle={jobTitle}
          setJobTitle={setJobTitle}
          email={email}
          setEmail={setEmail}
          team={team}
          setTeam={setTeam}
          isSubmitted={isSubmitted}
        />
      </Modal.Body>

      <Modal.Footer className="mt-3">
        <Button
          text="Cancel"
          buttonStyle="outline"
          onClick={() => props.handleShowModal(false)}
        />
        <Button
          disabled={isUploading}
          text={isUploading ? 'Uploading...' : 'Save'}
          onClick={() => handleSaveModal()}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default EditPersonalInfoModal;
