import { Button, TextField } from '@Shared/DS';
import { Modal } from 'react-bootstrap';
import { usePreboardingLinkVerificationModal } from './usePreboardingLinkVerificationModal';
import './PreboardingLinkVerificationModal.scss';

export const PreboardingLinkVerificationModal = () => {
  const { states, setStates, startPlaybook } =
    usePreboardingLinkVerificationModal();

  return (
    <Modal
      fullscreen={'md-down'}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
      className="verification-modal"
      show={states.showModal}
      onHide={() => setStates((prev) => ({ ...prev, showModal: false }))}
    >
      <Modal.Header>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h1>Enter your email address to continue</h1>
        <label className="form-label" htmlFor="email">
          Email
        </label>
        <TextField
          id="email"
          type="text"
          placeholder="please enter your email address"
          defaultValue={states.email}
          setValue={(value: string) =>
            setStates((prev) => ({ ...prev, email: value }))
          }
        />
        <div className="d-flex justify-content-center">
          <Button
            additionalClasses="my-3"
            showLoading={states.isSearching}
            text="Start now"
            onClick={startPlaybook}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};
