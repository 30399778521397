import { memo } from 'react';
import { Badge as BaseBadge } from 'react-bootstrap';
import clsx from 'clsx';
import styles from './Badge.module.scss';

export const Badge = memo(
  ({ badgeURL, badgeTitle }: { badgeURL: string; badgeTitle: string }) => (
    <BaseBadge className={clsx(styles.badge, 'd-flex align-items-center')}>
      <img src={badgeURL} alt={badgeTitle} width={60} height={60} />
    </BaseBadge>
  ),
);
