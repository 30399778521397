import { Pill, type PillProps } from '@Shared/DS';
import type { MouseEventHandler } from 'react';
import { toColor } from './RolePill.utils';

interface BaseRolePillProps {
  className?: string;
  role: string;
  size?: PillProps['size'];
}

interface StaticRolePillProps extends BaseRolePillProps {
  isActive?: never;
  onClick?: never;
}

interface ClickableRolePillProps extends BaseRolePillProps {
  onClick: MouseEventHandler<HTMLButtonElement>;
  isActive?: boolean;
}

type RolePillProps = StaticRolePillProps | ClickableRolePillProps;

export const RolePill = ({
  className,
  role,
  isActive,
  onClick,
  size,
}: RolePillProps) => {
  if (!role) {
    return null;
  }

  const commonProps = {
    className,
    color: toColor(role),
    size,
    children: role,
  };

  if (onClick) {
    return <Pill {...commonProps} onClick={onClick} isActive={isActive} />;
  }

  return <Pill {...commonProps} colorType="light" />;
};
