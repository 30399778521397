export const Keys = {
  getPlaybook: (id?: string) => ['playbook', id],
  getPlaybookCollaborators: (id?: string) => ['playbookCollaborators', id],
  getPlaybookPreboardingUser: (id?: string) => ['playbookPreboardingUser', id],
  getChildPlaybooks: (id?: string) => ['childPlaybooks', id],
  getPlaybookActions: (id?: string) => ['playbookActions', id],
  getPlaybookMessages: (id?: string) => ['playbookMessages', id],
  getPlaybookMeetings: (id?: string) => ['playbookMeetings', id],
  getEvents: (id?: string) => ['events', id],
  getUser: (id?: string) => ['user', id],
  getTemplate: (id?: string) => ['template', id],
  getKinfolkBadges: () => ['kinfolkBadges'],
  getPlaybookBadge: (id: string) => ['playbookBadge', id],
  getUserDigests: (id: string) => ['digests', id],
  getOrgForms: (orgId: string) => ['orgForms', orgId],
  getFormById: (id: string) => ['formById', id],
  getFormBySlug: (slug: string) => ['formBySlug', slug],
  getOrgDataStores: (orgId: string) => ['orgDataStores', orgId],
  getOrgUserCustomFields: (orgId: string) => ['orgUserCustomFields', orgId],
  getOrgDynamicTags: (orgId: string) => ['orgDynamicTags', orgId],
  getFormEvents: (orgId?: string) => ['formEvents', orgId],
};
