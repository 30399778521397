import { Button } from '@Shared/DS/Atoms';
import { type IResetPasswordModal } from '@Shared/utils/utils';
import { Modal } from 'react-bootstrap';
import './ResetPasswordModal.scss';
import { TextField } from '@Shared/DS/Molecules';
import { useResetPasswordModal } from './useResetPasswordModal';

export const ResetPasswordModal = (props: IResetPasswordModal) => {
  const { showModal, handleShowModal, email } = props;
  const {
    isSubmitted,
    isBusy,
    newPassword,
    setNewPassword,
    confirmPassword,
    setConfirmPassword,
    resetPassword,
  } = useResetPasswordModal(email, handleShowModal);

  return (
    <Modal
      className="reset-password-modal"
      size="lg"
      fullscreen={'md-down'}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      show={showModal}
      onHide={() => handleShowModal(false)}
    >
      <Modal.Header>
        <Modal.Title>Set new password</Modal.Title>
        <button
          type="button"
          className="btn-close modal-close-btn"
          data-bs-dismiss="modal"
          onClick={() => handleShowModal(false)}
          aria-label="Close"
        ></button>
      </Modal.Header>
      <Modal.Body>
        <div className="sub-title">
          You need to reset your password to log in.
          <br />
          Enter a new password, it must be at least 8 characters.
        </div>

        <label className="mt-3" htmlFor="new-password">
          New password
        </label>
        <TextField
          id="new-password"
          type="password"
          defaultValue={newPassword}
          setValue={setNewPassword}
          fieldName="New Password"
          placeholder="Enter your new password"
          errorMessage="must be at least 8 characters"
          additionalClasses={
            newPassword.length < 8 && isSubmitted ? 'is-invalid' : ''
          }
        />

        <label className="mt-2" htmlFor="confirm-new-password">
          Confirm new password
        </label>
        <TextField
          id="confirm-new-password"
          type="password"
          defaultValue={confirmPassword}
          setValue={setConfirmPassword}
          fieldName=""
          placeholder="Confirm your new password"
          errorMessage="New password and confirm password do not match"
          additionalClasses={
            newPassword !== confirmPassword && isSubmitted ? 'is-invalid' : ''
          }
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-end">
          <Button
            text="Reset password"
            showLoading={isBusy}
            disabled={isBusy}
            additionalClasses="ms-2"
            onClick={resetPassword}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};
