import { Col, Row } from 'react-bootstrap';
import clsx from 'clsx';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import {
  ASSIGNING_ROLE,
  type AddMeetingModalProps,
} from '@base/models/journeyLibrary.model';
import { AssigneeRole } from '@base/API';
import { Button } from '@Shared/DS/Atoms';
import { Modal } from '@Shared/DS/Organism';
import { ToggleDropDownSimple } from '../DropDownField/DropDownField';
import TextArea from '../TextArea/TextArea';
import { TextField } from '../../DS/Molecules/TextField/TextField';
import { RolePill } from '../RolePill/RolePill';
import styles from './AddMeetingModal.module.scss';
import { useAddMeetingModal } from './useAddMeetingModal';

export const AddMeetingModal = (props: AddMeetingModalProps) => {
  const {
    meetingTitle,
    showLoader,
    withinDaysList,
    assignees,
    meetingDuration,
    meetingDescription,
    isSubmitted,
    isLoadingAssignee,
    selectedWithinStartDay,
    selectedWithinEndDay,
    selectedAttendee,
    assigningRole,
    selectedRole,
    setSelectedRole,
    handleWithinStartDay,
    handleWithinEndDay,
    handleHideModal,
    setMeetingDuration,
    setMeetingDescription,
    handleAddMeeting,
    searchAssignee,
    handleSelectedAssignee,
    setMeetingTitle,
    handleAssigningRole,
  } = useAddMeetingModal(props);

  return (
    <Modal
      preventClickOutside
      size="lg"
      isOpen={props.showModal}
      onClose={handleHideModal}
      title={
        <>
          <div className="fs-24">Schedule meeting</div>
          <p className="fs-15-25272A py-1">
            We'll only book meetings between 9am and 5pm from Mondays to
            Fridays.
          </p>
        </>
      }
      footer={
        <>
          <Button
            buttonStyle="outline"
            text="Cancel"
            onClick={handleHideModal}
          />
          <Button
            showLoading={showLoader}
            text={
              props.selectedMeeting.id ? 'Update Meeting' : 'Create Meeting'
            }
            disabled={showLoader}
            type="submit"
            onClick={handleAddMeeting}
          />
        </>
      }
    >
      <Row>
        <Col>
          <form>
            <label
              className="fs-13-medium-bold-4c5564 text-start"
              htmlFor={'meetingTitle'}
            >
              Add Title<span className="required-field-sign">*</span>
            </label>
            <TextField
              id={'meetingTitle'}
              type="text"
              defaultValue={meetingTitle}
              setValue={setMeetingTitle}
              fieldName="Meeting title"
              placeholder="This will appear on the calendar event"
              additionalClasses={clsx({
                'is-invalid':
                  !meetingTitle && meetingTitle.length === 0 && isSubmitted,
              })}
            />

            <label
              className="fs-13-medium-bold-4c5564 text-start mt-4"
              htmlFor={'assigning-role'}
            >
              Assigning rule
            </label>
            <div
              id="assigning-role"
              className={clsx(
                styles.assigningRole,
                'd-flex justify-content-start',
              )}
            >
              <button
                className={clsx(
                  'btn btn-button-outline rounded-pill fs-16-medium-bold',
                  {
                    [styles.selected]: assigningRole === ASSIGNING_ROLE.ROLE,
                  },
                )}
                onClick={(e) => handleAssigningRole(e, ASSIGNING_ROLE.ROLE)}
              >
                Role
              </button>
              <button
                className={clsx(
                  'btn btn-button-outline rounded-pill fs-16-medium-bold ms-3',
                  {
                    [styles.selected]: assigningRole === ASSIGNING_ROLE.USER,
                  },
                )}
                onClick={(e) => handleAssigningRole(e, ASSIGNING_ROLE.USER)}
              >
                User
              </button>
            </div>

            {assigningRole === ASSIGNING_ROLE.USER ? (
              <>
                <label
                  className="fs-13-medium-bold-4c5564 text-start mt-4"
                  htmlFor={'meetingAttendee'}
                >
                  Add Attendee
                </label>
                <AsyncTypeahead
                  id="meetingAttendee"
                  selected={selectedAttendee ? [{ ...selectedAttendee }] : []}
                  isLoading={isLoadingAssignee}
                  onSearch={searchAssignee}
                  minLength={3}
                  labelKey={(option: any) =>
                    option.firstName
                      ? `${option.firstName} ${option.lastName}`
                      : ''
                  }
                  onChange={handleSelectedAssignee}
                  options={assignees}
                  placeholder="Search on name"
                  inputProps={{
                    style: {
                      borderColor: 'lightgray',
                      borderRadius: '30px',
                      padding: '8px 20px',
                    },
                  }}
                  renderMenuItemChildren={(option: any) => (
                    <>
                      <span>{`${option.firstName} ${option.lastName}`}</span>
                    </>
                  )}
                />
              </>
            ) : (
              <>
                <label
                  className="fs-13-medium-bold-4c5564 text-start mt-4"
                  htmlFor={'assign-role'}
                >
                  Assign role
                </label>
                <div id="assign-role" className="d-flex mt-2">
                  {Object.keys(AssigneeRole)
                    .filter((role) => role !== AssigneeRole.LEGAL)
                    .map((role) => (
                      <RolePill
                        key={role}
                        className="me-2"
                        onClick={() => setSelectedRole(role as AssigneeRole)}
                        isActive={role === selectedRole}
                        role={role}
                        size="big"
                      />
                    ))}
                </div>
              </>
            )}

            <label
              className="fs-13-medium-bold-4c5564 text-start mt-4"
              htmlFor={'when'}
            >
              When<span className="required-field-sign">*</span>
            </label>
            <div className="d-flex col-8 justify-content-between">
              <span
                className={clsx(
                  styles.withinDaysLabel,
                  'fs-14-semi-bold-4c5564 mr-1',
                )}
              >
                Within
              </span>
              <ToggleDropDownSimple
                items={withinDaysList}
                selectedItem={selectedWithinStartDay}
                onSelect={handleWithinStartDay}
              />
              <span
                className={clsx(
                  styles.withinDaysLabel,
                  'fs-14-semi-bold-4c5564 mx-1',
                )}
              >
                and
              </span>
              <ToggleDropDownSimple
                items={withinDaysList}
                selectedItem={selectedWithinEndDay}
                onSelect={handleWithinEndDay}
              />
              <span
                className={clsx(
                  styles.withinDaysLabel,
                  'fs-14-semi-bold-4c5564',
                )}
              >
                from
              </span>
              <button
                className="btn btn-button-outline fs-14-medium-bold-6b7380"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                disabled={true}
              >
                Start date
              </button>
            </div>
            {(selectedWithinStartDay === withinDaysList[0] ||
              selectedWithinEndDay === withinDaysList[0]) &&
              isSubmitted && (
                <div className="fs-14-bb242b text-start">
                  Please select schedule time correctly
                </div>
              )}

            <div className="row">
              <label
                className="fs-13-medium-bold-4c5564 text-start mt-4"
                htmlFor={'meetingDuration'}
              >
                Meeting Duration (in minutes)
                <span className="required-field-sign">*</span>
              </label>
              <div className="col-5">
                <TextField
                  id={'meetingDuration'}
                  type="number"
                  defaultValue={meetingDuration}
                  setValue={setMeetingDuration}
                  fieldName="Meeting duration"
                  placeholder="Choose a duration"
                  additionalClasses={`
              ${!meetingDuration && meetingDuration.length === 0 && isSubmitted ? 'is-invalid' : ''}`}
                />
              </div>
              <label
                className="fs-13-medium-bold-4c5564 text-start mt-4"
                htmlFor={'meetingDescription'}
              >
                Meeting Description
              </label>
              <span className="ml-1 mr-2 h-75">
                <TextArea
                  id={'meetingDescription'}
                  type="text"
                  defaultValue={meetingDescription}
                  setValue={setMeetingDescription}
                  fieldName="Meeting description"
                  placeholder="This will appear on the calendar invite"
                />
              </span>
            </div>
          </form>
        </Col>
      </Row>
    </Modal>
  );
};
