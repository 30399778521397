import { Modal } from 'react-bootstrap';
import PillsTypeAhead from '@Shared/Components/PillsTypeAhead/PillsTypeAhead';
import '../LearnMore/LearnMore.scss';
import { type LearnMoreModalProps } from '@base/models/profile.model';
const EditLearnMoreModal = (props: LearnMoreModalProps) => (
  <>
    <Modal
      size="lg"
      fullscreen={'md-down'}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      show={props.showModal}
      onHide={() => props.handleShowModal(false)}
      className="learn-more-wrapper"
    >
      <Modal.Header>
        <Modal.Title className="fs-24-semi-bold-4C5564">
          Edit Work Skills
        </Modal.Title>
        <button
          type="button"
          className="btn-close modal-close-btn"
          data-bs-dismiss="modal"
          onClick={() => props.handleShowModal(false)}
          aria-label="Close"
        ></button>
      </Modal.Header>
      <Modal.Body>
        <label className="ms-2">I'm looking to learn more on...</label>
        <PillsTypeAhead
          suggestOptions={props.suggestOptions}
          setSuggestOptions={props.setSuggestOptions}
          options={props.options}
          currentNumber={props.currentNumber}
          alreadySelectedOptions={props.alreadySelectedOptions}
          handleShowModal={props.handleShowModal}
          isProfilePage={true}
        />
      </Modal.Body>
    </Modal>
  </>
);

export default EditLearnMoreModal;
