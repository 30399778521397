import { convertChannelSpecificFormatToPlainText } from '@Shared/utils/utils';
import {
  getUserBasicData,
  deletePlaybookEvent,
  updatePlaybookEvent,
  deleteActionFromCollaborator,
  updatePlaybookEventReminder,
  listKinfolkTemplateEvents,
  deletePlaybookEventReminder,
} from '@api/apis';
import { type AssigneeRole, type Event, type EventReminder } from '@base/API';
import { useEvents } from '@base/Hooks/useEvents';
import { Keys } from '@base/keys/queryKeys';
import { EventType } from '@base/models/common.model';
import {
  type IPlaybookCalendarSchedules,
  type IEvent,
} from '@base/models/playbookHome.model';
import { useQueryClient, useQuery } from '@tanstack/react-query';
import { useState, useMemo, useCallback, useEffect } from 'react';
import { type DropResult } from 'react-beautiful-dnd';
import { useParams } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

export const useTemplateCalendar = () => {
  const filterItems = ['All', 'Assignee', 'HR Ops', 'HRBP', 'Manager', 'Buddy'];
  const { id } = useParams();
  const [states, setStates] = useState({
    calendarData: [] as IPlaybookCalendarSchedules[],
    selectedFilter: 'All',
    showCreateUpdateActionPanel: false,
    showCreateUpdateMessagePanel: false,
    showTestMessagesConfirmationModal: false,
    showDeletingLoader: false,
    selectedEvent: {} as IEvent,
  });
  const queryClient = useQueryClient();
  const { update: updateIntercom } = useIntercom();
  const { getUserName } = useEvents();
  const daysOfWeek = useMemo(
    () => [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ],
    [],
  );
  const weekDays = useMemo(
    () => ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
    [],
  );

  const assigneeRoles = {
    MANAGER: 'Manager',
    HRBP: 'HRBP',
    IT: 'IT',
    BUDDY: 'Buddy',
    HROPS: 'HR Ops',
    LEGAL: 'Legal',
  };

  const WEEK_LENGTH = 5;

  // fetch events
  const { data: events } = useQuery({
    queryKey: Keys.getEvents(id),
    queryFn: async () => {
      if (!id) {
        return [];
      }

      const events = await listKinfolkTemplateEvents(id);
      if (!events) {
        return [];
      }

      return Promise.all(
        events.map(async (event) => {
          const role = ((event.role && assigneeRoles[event.role]) ??
            '') as AssigneeRole;

          if (!event.userId || event.type === EventType.MESSAGE) {
            return {
              ...event,
              role:
                event.type === EventType.MESSAGE
                  ? ('Assignee' as AssigneeRole)
                  : role,
              userName: getUserName(event, role),
            };
          }

          const user = await getUserBasicData(event.userId);
          return {
            ...event,
            role,
            userName: getUserName(event, role, user),
          };
        }),
      );
    },
    enabled: !!id,
  });

  const getDaysFromStartDate = useCallback(
    (currentDay: string, currentWeek: string) => {
      const startDayIndex = 1;
      let currentWeekNumber = 0;

      const eventDayIndex = daysOfWeek.indexOf(currentDay);
      let daysDifference = eventDayIndex - startDayIndex;

      if (currentWeek.includes('before')) {
        currentWeekNumber = -getWeekNumber(currentWeek);
      } else {
        currentWeekNumber = getWeekNumber(currentWeek);
      }

      if (currentWeekNumber === 0) {
        return daysDifference;
      } else if (currentWeekNumber > 0) {
        daysDifference += currentWeekNumber * WEEK_LENGTH;
        return daysDifference;
      }

      // If the event day is before the start day
      daysDifference -= Math.abs(currentWeekNumber) * WEEK_LENGTH;
      return daysDifference;
    },
    [daysOfWeek],
  );

  const generateCalendarDefaultData =
    useCallback((): IPlaybookCalendarSchedules[] => {
      const weekLabels = [
        '4 week before start date',
        '3 week before start date',
        '2 week before start date',
        '1 week before start date',
        'First week',
        ...Array.from({ length: 26 }).map((_, index) => `Week ${index + 2}`),
      ];

      return Array.from({ length: 31 }).map((_, weekIndex) => ({
        weekLabel: weekLabels[weekIndex],
        days: Array.from({ length: WEEK_LENGTH }).map((_, dayIndex) => ({
          day: weekDays[dayIndex].charAt(0),
          isStartDay:
            weekLabels[weekIndex] === 'First week' ? dayIndex === 0 : false,
          events: [] as IEvent[],
          daysFrom: getDaysFromStartDate(
            weekDays[dayIndex],
            weekLabels[weekIndex],
          ),
        })),
      }));
    }, [getDaysFromStartDate, weekDays]);

  const compareEventTimings = (a: IEvent, b: IEvent) => {
    if (!a.sendTime || !b.sendTime) {
      return 0;
    }

    const aTiming = +a.sendTime.split(':')[0];
    const bTiming = +b.sendTime.split(':')[0];
    return aTiming - bTiming;
  };

  const getWeekNumber = (title: string) => {
    if (title.includes('First week')) return 0;
    const match = title.match(/\d+/);
    return match
      ? title.includes('before')
        ? parseInt(match[0])
        : parseInt(match[0]) - 1
      : Number.MAX_VALUE; // Week 2 is actually week 1 and so on
  };

  const customSort = useCallback(
    (a: IPlaybookCalendarSchedules, b: IPlaybookCalendarSchedules) => {
      const weekA = getWeekNumber(a.weekLabel);
      const weekB = getWeekNumber(b.weekLabel);
      const isBeforeA = a.weekLabel.includes('before');
      const isBeforeB = b.weekLabel.includes('before');
      const isFirstWeekA = a.weekLabel.includes('First week');
      const isFirstWeekB = b.weekLabel.includes('First week');

      // Sort "before" items before "after" items
      if (isBeforeA && isBeforeB) {
        return weekB - weekA; // Higher number of weeks come first
      } else if (isBeforeA && !isBeforeB) {
        return -1;
      } else if (!isBeforeA && isBeforeB) {
        return 1;
      }

      // Sort "First week" in between "before" and "after" items
      if (isFirstWeekA && isFirstWeekB) {
        return 0;
      } else if (isFirstWeekA) {
        return -1;
      } else if (isFirstWeekB) {
        return 1;
      }

      // Sort weeks in ascending order
      return weekA - weekB;
    },
    [],
  );

  const refreshCalendarEvents = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: Keys.getEvents(id) });
  }, [id, queryClient]);

  const editEvent = (eventId: string, type: EventType) => {
    const event = findEventById(eventId);

    if (!event) {
      return;
    }

    setStates((prevStates) => ({
      ...prevStates,
      selectedEvent: {
        ...event,
        role: event.role?.split(' ').join('').toUpperCase() as AssigneeRole,
      },
      showCreateUpdateActionPanel: type === EventType.ACTION,
      showCreateUpdateMessagePanel: type === EventType.MESSAGE,
    }));
  };

  const createEvent = (type: EventType, daysFrom?: number) => {
    setStates((prevStates) => ({
      ...prevStates,
      selectedEvent: { daysFrom } as IEvent,
      showCreateUpdateActionPanel: type === EventType.ACTION,
      showCreateUpdateMessagePanel: type === EventType.MESSAGE,
    }));
  };

  const findEventById = (eventId: string): Event | undefined => {
    if (!events) {
      return;
    }

    let event = events.find((event) => event.id === eventId);

    if (!event) {
      event = events.find((event) => {
        const reminders = event.reminders?.items || [];
        return reminders.some((reminder) => reminder?.id === eventId);
      });
    }

    return event;
  };

  const deleteEvent = async (
    id: string,
    type: EventType,
    isReminder?: boolean,
  ) => {
    if (!events) {
      return;
    }

    const event = findEventById(id);
    if (!event) {
      return;
    }

    setStates((prevStates) => ({ ...prevStates, showDeletingLoader: true }));

    if (isReminder) {
      await deletePlaybookEventReminder(id);
    } else {
      let sideEffects = [deletePlaybookEvent(event.id)];
      if (type === EventType.ACTION && event.role && event.kinfolkTemplateID) {
        sideEffects.push(
          deleteActionFromCollaborator(
            event.id,
            event.kinfolkTemplateID,
            {
              assigneeRole: {
                eq: event.role
                  ?.split(' ')
                  .join('')
                  .toUpperCase() as AssigneeRole,
              },
            },
            false,
          ),
        );
      }

      await Promise.all(sideEffects);
    }

    setStates((prevStates) => ({ ...prevStates, showDeletingLoader: false }));
    refreshCalendarEvents();
  };

  const getWeekLabelFromDays = useCallback((daysFrom: number) => {
    const startDayIndex = 1;

    if (
      daysFrom === 0 ||
      (daysFrom < 0 && startDayIndex > Math.abs(daysFrom)) ||
      (daysFrom > 0 && startDayIndex + daysFrom <= WEEK_LENGTH)
    ) {
      return 'First week';
    } else if (daysFrom < 0) {
      const daysToWeek = Math.ceil(
        (daysFrom - (WEEK_LENGTH - startDayIndex)) / WEEK_LENGTH,
      ); // (daysFrom - (days in week - remaining days of the week from daysFrom))/days in week
      return `${Math.abs(daysToWeek)} week before start date`;
    } else {
      const daysToWeek =
        Math.floor((daysFrom + startDayIndex - 1) / WEEK_LENGTH) + 1; // ((daysFrom + remaining days of the week from daysFrom - exclude start day)/days in week) + 1 to move weeks one ahead to change week 1 to week 2 and so on
      return `Week ${daysToWeek}`;
    }
  }, []);

  const addEvent = useCallback(
    (weekIndex: number, event: IEvent, data: IPlaybookCalendarSchedules[]) => {
      const eventDayIndex = data[weekIndex].days.findIndex(
        (day) => day.daysFrom === event.daysFrom,
      );
      if (eventDayIndex >= 0) {
        const newEvent = {
          ...event,
          sendTime: event.sendTime ?? '8:00',
          message: parseEventMessage(event),
          role: event.role,
        } as IEvent;
        data[weekIndex].days[eventDayIndex].events.push(newEvent);
      }

      return [...data];
    },
    [],
  );

  const checkStartDay = useCallback((weekLabel: string, day: string) => {
    if (weekLabel !== 'First week') {
      return false;
    }

    return day === 'Monday';
  }, []);

  const createWeekAndAddEvent = useCallback(
    (weekLabel: string, event: IEvent, data: IPlaybookCalendarSchedules[]) => {
      const days = [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday',
      ];
      data.push({
        weekLabel: weekLabel,
        days: Array.from({ length: WEEK_LENGTH }).map((_, dayIndex) => ({
          day: days[dayIndex].charAt(0),
          isStartDay: checkStartDay(weekLabel, days[dayIndex]),
          events: [],
          daysFrom: getDaysFromStartDate(days[dayIndex], weekLabel),
        })),
      });

      data = addEvent(data.length - 1, event, data);
      return [...data];
    },
    [addEvent, checkStartDay, getDaysFromStartDate],
  );

  const addEventToCalendarData = useCallback(
    (event: IEvent, data: IPlaybookCalendarSchedules[]) => {
      const weekLabel = getWeekLabelFromDays(event.daysFrom);
      const weekIndexAlreadyInCalendar = data.findIndex(
        (week) => week.weekLabel === weekLabel,
      );

      if (weekIndexAlreadyInCalendar >= 0) {
        data = addEvent(weekIndexAlreadyInCalendar, event, data);
      } else {
        data = createWeekAndAddEvent(weekLabel, event, data);
      }

      return data;
    },
    [addEvent, createWeekAndAddEvent, getWeekLabelFromDays],
  );

  const processPlaybookEvents = useCallback(
    (events: IEvent[], data: IPlaybookCalendarSchedules[]) => {
      events.forEach((event) => {
        data = addEventToCalendarData(event, data);

        const reminders = event.reminders;
        if (!reminders) {
          return;
        }

        reminders.items.forEach((reminder) => {
          if (!reminder) {
            return;
          }

          data = addEventToCalendarData(
            {
              ...event,
              id: reminder.id,
              daysFrom: reminder.daysFrom,
              isActionReminder: true,
            },
            data,
          );
        });
      });
      return data;
    },
    [addEventToCalendarData],
  );

  const updateEventSendTimeDate = useCallback(
    async (event: IEvent): Promise<void> => {
      let request = { id: event.id, daysFrom: event.daysFrom } as Event;

      if (event.isActionReminder) {
        await updatePlaybookEventReminder(request as unknown as EventReminder);
      } else {
        await updatePlaybookEvent({ ...request });
      }

      refreshCalendarEvents();
    },
    [refreshCalendarEvents],
  );

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      const { destination, source, draggableId } = result;

      if (destination && destination.droppableId !== source.droppableId) {
        const { droppableId } = destination;
        let draggedEvent: IEvent | undefined;

        setStates((currState) => {
          currState.calendarData.forEach((data, index) => {
            data.days.forEach((day) => {
              if (!draggedEvent) {
                draggedEvent = day.events.find(
                  (event) => event.id === draggableId,
                );
                if (draggedEvent) {
                  day.events = day.events.filter(
                    (event) => event.id !== draggableId,
                  );
                }
              }
            });
          });

          if (draggedEvent) {
            currState.calendarData.forEach((data, index) => {
              data.days.forEach((day) => {
                if (day.daysFrom.toString() === droppableId && draggedEvent) {
                  day.events.push({ ...draggedEvent, daysFrom: day.daysFrom });
                  updateEventSendTimeDate({
                    ...draggedEvent,
                    daysFrom: day.daysFrom,
                  });
                }
              });
            });
          }
          return {
            ...currState,
            calendarData: [...currState.calendarData],
          };
        });
      }
    },
    [updateEventSendTimeDate],
  );

  const parseEventMessage = (event: IEvent) => {
    if (!event.message) {
      return '';
    }

    const description = convertChannelSpecificFormatToPlainText(
      event.message,
      event.channel,
    );

    return description.replace(/(<([^>]+)>)/gi, '');
  };

  const defaultCalendarData = useMemo(() => {
    const data = generateCalendarDefaultData();
    return data.sort(customSort);
  }, [generateCalendarDefaultData, customSort]);

  useEffect(() => {
    if (!defaultCalendarData.length || !events) {
      return;
    }

    const data = [
      ...defaultCalendarData.map((data) => ({
        ...data,
        days: [
          ...data.days.map((day) => ({
            ...day,
            events: [],
          })),
        ],
      })),
    ];

    const calendarData = processPlaybookEvents(events, [...data]);
    setStates((currState) => ({
      ...currState,
      calendarData: [...calendarData.sort(customSort)],
    }));
  }, [customSort, events, defaultCalendarData, processPlaybookEvents]);

  const filteredCalendarEvents = useMemo(
    () =>
      states.calendarData.map((item) => ({
        ...item,
        days: item.days.map((day) => ({
          ...day,
          events: day.events
            .filter(
              (event) =>
                states.selectedFilter === 'All' ||
                states.selectedFilter.toLowerCase() ===
                  event.role?.toLowerCase(),
            )
            .sort(compareEventTimings),
        })),
      })),
    [states.calendarData, states.selectedFilter],
  );

  const setSelectedFilter = (filterItem: string) => {
    setStates((prevStates) => ({ ...prevStates, selectedFilter: filterItem }));
  };

  useEffect(() => {
    updateIntercom({
      hideDefaultLauncher:
        states.showCreateUpdateActionPanel ||
        states.showCreateUpdateMessagePanel,
    });
  }, [
    states.showCreateUpdateActionPanel,
    states.showCreateUpdateMessagePanel,
    updateIntercom,
  ]);

  return {
    id,
    states,
    filterItems,
    filteredCalendarEvents,
    setSelectedFilter,
    setStates,
    refreshCalendarEvents,
    editEvent,
    deleteEvent,
    createEvent,
    handleDragEnd,
  };
};
