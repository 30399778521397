import { RolePill } from '@Shared/Components/RolePill/RolePill';
import './Filter.scss';

export const Filter = ({
  items,
  active,
  onSelect,
}: {
  items: string[];
  active: string;
  onSelect: (item: string) => void;
}) => (
  <div className="filter d-flex gap-2">
    {items.map((item) => (
      <RolePill
        key={item}
        onClick={() => onSelect(item)}
        isActive={active === item}
        role={item}
        size="big"
      />
    ))}
  </div>
);
