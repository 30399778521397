import { type Journey } from '@base/API';
import { flexRender } from '@tanstack/react-table';
import { Card } from 'react-bootstrap';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import { useUserPlaybooksCard } from './useUserPlaybooksCard';
import './UserPlaybooksCard.scss';

interface IUserPlaybooksCard {
  playbooks: Journey[];
}
export const UserPlaybooksCard = ({ playbooks }: IUserPlaybooksCard) => {
  const { table, columns, handleViewJourney } = useUserPlaybooksCard(playbooks);
  return (
    <Card>
      <Card.Body>
        <div className="user-playbooks-card">
          <div className="heading">Assigned runbooks</div>
          <table className="kin-table table">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      style={{
                        width:
                          header.getSize() !== 0 ? header.getSize() : undefined,
                      }}
                    >
                      {header.isPlaceholder ? null : (
                        <span
                          {...{
                            className: header.column.getCanSort()
                              ? 'post-sorting-icon cursor-pointer select-none d-flex'
                              : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                          {{
                            asc: <CaretUpFill />,
                            desc: <CaretDownFill />,
                          }[header.column.getIsSorted() as string] ?? (
                            <CaretUpFill className="initial-sorting-icon" />
                          )}
                        </span>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {!playbooks.length && (
                <tr>
                  <td className="text-center" colSpan={columns.length}>
                    No playbook has been assigned yet
                  </td>
                </tr>
              )}

              {table.getRowModel().rows.map((row, i) => (
                <tr
                  className="cursor-pointer"
                  key={row.id}
                  onClick={() => {
                    handleViewJourney(parseInt(row.id));
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      className="align-middle"
                      key={cell.id}
                      style={{
                        width:
                          cell.column.getSize() !== 0
                            ? cell.column.getSize()
                            : undefined,
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Card.Body>
    </Card>
  );
};
