import { useState } from 'react';
import { Modal, Row, Col, Container } from 'react-bootstrap';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import CustomSlider from '@Shared/Components/CustomSlider/CustomSlider';
import './EditWorkPreferencesModal.scss';
import { Button } from '@Shared/DS';

const EditWorkPreferencesModal = (props: any) => {
  const { user, setUserWorkPreferences } = useUserContext();
  const [giveMeAllTheContext, setGiveMeAllTheContext] = useState(
    user.WorkPreference.giveMeAllTheContext,
  );
  const [messageTimingPreference, setMessageTimingPreference] = useState(
    user.WorkPreference.messageTimingPreferene,
  );
  const [syncxVsAsync, setSyncxVsAsync] = useState(
    user.WorkPreference.syncxVsAsync,
  );
  const [recognitionPrivateVsPrivate, setRecognitionPrivateVsPrivate] =
    useState(user.WorkPreference.recognitionPrivateVsPrivate);
  const [talkTogetherVsAlone, setTalkTogetherVsAlone] = useState(
    user.WorkPreference.talkTogetherVsAlone,
  );

  const handleSubmit = () => {
    setUserWorkPreferences(
      giveMeAllTheContext,
      messageTimingPreference,
      syncxVsAsync,
      talkTogetherVsAlone,
      recognitionPrivateVsPrivate,
    );
    props.handleShowModal(false);
  };

  return (
    <>
      <Modal
        size="lg"
        fullscreen={'md-down'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        show={props.showModal}
        onHide={() => props.handleShowModal(false)}
        className="edit-work-preferences-modal"
      >
        <Modal.Header>
          <Modal.Title className="fs-24-semi-bold-4C5564">
            Edit Work Preference
          </Modal.Title>
          <button
            type="button"
            className="btn-close modal-close-btn"
            data-bs-dismiss="modal"
            onClick={() => props.handleShowModal(false)}
            aria-label="Close"
          ></button>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Container>
              <div className="fs-14-medium-bold-25272a">
                How I prefer to receive information about work
              </div>
              <Row className="justify-content-between align-items-center fs-14-4c5564">
                <Col xs="3">
                  <div className="">Give me all the context</div>
                </Col>
                <Col xs="6">
                  <CustomSlider
                    value={giveMeAllTheContext}
                    onChange={setGiveMeAllTheContext}
                  />
                </Col>
                <Col xs="3" className="">
                  <div className="">Just the key info is fine</div>
                </Col>
              </Row>
            </Container>
          </Row>

          <Row className="mt-5">
            <Container>
              <div className="fs-14-medium-bold-25272a">When I can talk</div>
              <Row className="justify-content-between fs-14-4c5564">
                <Col xs="3">
                  <div className="">Message me any time</div>
                </Col>
                <Col xs="6">
                  <CustomSlider
                    value={messageTimingPreference}
                    onChange={setMessageTimingPreference}
                  />
                </Col>
                <Col xs="3" className="">
                  <div className="">Schedule a time to talk</div>
                </Col>
              </Row>
            </Container>
          </Row>

          <Row className="mt-5">
            <Container>
              <div className="fs-14-medium-bold-25272a">
                How I prefer to communicate
              </div>
              <Row className="justify-content-between fs-14-4c5564">
                <Col xs="3">
                  <div className="">In the moment</div>
                </Col>
                <Col xs="6">
                  <CustomSlider
                    value={syncxVsAsync}
                    onChange={setSyncxVsAsync}
                  />
                </Col>
                <Col xs="3" className="">
                  <div className="">Async</div>
                </Col>
              </Row>
            </Container>
          </Row>

          <Row className="mt-5">
            <Container>
              <div className="fs-14-medium-bold-25272a">
                How I like to be recognized for my work
              </div>
              <Row className="justify-content-between fs-14-4c5564">
                <Col xs="3">
                  <div className="">Public recognition</div>
                </Col>
                <Col xs="6">
                  <CustomSlider
                    value={recognitionPrivateVsPrivate}
                    onChange={setRecognitionPrivateVsPrivate}
                  />
                </Col>
                <Col xs="3" className="">
                  <div className="">Private recognition</div>
                </Col>
              </Row>
            </Container>
          </Row>

          <Row className="mt-5">
            <Container>
              <div className="fs-14-medium-bold-25272a">
                How I like to problem solve
              </div>
              <Row className="justify-content-between fs-14-4c5564">
                <Col xs="3">
                  <div className="">Talk it together</div>
                </Col>
                <Col xs="6">
                  <CustomSlider
                    value={talkTogetherVsAlone}
                    onChange={setTalkTogetherVsAlone}
                  />
                </Col>
                <Col xs="3" className="">
                  <div className="">Think about it alone first</div>
                </Col>
              </Row>
            </Container>
          </Row>
        </Modal.Body>
        <Modal.Footer className="mt-3">
          <Button
            text="Cancel"
            buttonStyle="outline"
            onClick={() => props.handleShowModal(false)}
          />
          <Button text="Save" onClick={() => handleSubmit()} />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditWorkPreferencesModal;
