import {
  FORM_UPLOADS_BUCKET,
  S3_USER_UPLOADS_BASE_URL,
  addNotificationToApp,
} from '@Shared/utils/utils';
import { getPreSignedURL, submitFormBySlug } from '@api/apis';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { Device } from '@base/models/common.model';
import {
  type DataType,
  DataValueType,
  type FormElement,
  type FormFormattingHeaderElement,
  type FormFormattingTextElement,
  type FormInputElementType,
} from '@base/models/form.model';
import {
  useState,
  type ChangeEvent,
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useUpdateForm } from '@Hooks/useUpdateForm';
import { useGetFormById, useGetFormBySlug } from '@Hooks/useGetForm';
import { createNewElement } from './helper';

export interface UploadProgress {
  [key: string]: number;
}
interface State {
  device: Device;
  isFormSubmitted: boolean;
  isFormSubmitting: boolean;
  isInEditingMode: boolean;
  formElements: FormElement[];
  formName: string | null;
  uploadProgress: UploadProgress;
}

export type InputElementChangeEvent = ChangeEvent<
  HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
>;

const toFormValue = (event: InputElementChangeEvent, dataType?: DataType) => {
  const { value } = event.target;

  if (dataType === DataValueType.INTEGER) {
    return parseInt(value, 10) || undefined;
  }

  return value;
};

const isHTMLInputElement = (
  event: InputElementChangeEvent,
): event is ChangeEvent<HTMLInputElement> =>
  event.target instanceof HTMLInputElement;

const INITIAL_UPLOAD_PROGRESS_PERCENT = 20;

export const useForm = (formId?: string, formSlug?: string) => {
  const { state: locationState } = useLocation();
  const [formData, setFormData] = useState<
    Record<
      string,
      undefined | string | number | boolean | Record<string, unknown>
    >
  >({});
  const { user } = useUserContext();
  const updateForm = useUpdateForm();

  const [state, setState] = useState<State>({
    device: Device.DESKTOP,
    isFormSubmitted: false,
    isFormSubmitting: false,
    isInEditingMode: locationState?.isInEditingMode ?? false,
    formElements: [],
    formName: null,
    uploadProgress: {},
  });

  const { form: formById, isLoading: isFormByIdLoading } =
    useGetFormById(formId);

  const { form: formBySlug, isLoading: isFormBySlugLoading } =
    useGetFormBySlug(formSlug);

  const handleFormNameChange = (formName: string) => {
    setState((currentState) => ({ ...currentState, formName }));
  };

  const handleInputChange = (
    event: InputElementChangeEvent,
    dataType: DataType,
  ) => {
    if (dataType === DataValueType.FILE && isHTMLInputElement(event)) {
      handleFileChange(event);
    } else {
      setFormData({
        ...formData,
        [event.target.name]: toFormValue(event, dataType),
      });
    }
  };

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      const { name, files } = event.target;
      const file = files?.[0];
      setState({
        ...state,
        uploadProgress: {
          ...state.uploadProgress,
          [name]: INITIAL_UPLOAD_PROGRESS_PERCENT,
        },
      });

      if (file && formBySlug) {
        const fileName = file.name.replace(/[\s?]+/g, '');
        const key = `forms/${formBySlug.id}/${fileName}`;
        const res = await getPreSignedURL({
          bucket: FORM_UPLOADS_BUCKET,
          key,
          fileType: file.type,
        });

        setState({
          ...state,
          uploadProgress: { ...state.uploadProgress, [name]: 50 },
        });

        if (res?.preSignedUrl) {
          axios
            .put(res.preSignedUrl, file, {
              headers: {
                'Content-Type': file.type,
              },
            })
            .then(() => {
              const url = `${S3_USER_UPLOADS_BASE_URL}${key}`;
              setFormData({
                ...formData,
                [name]: { fileId: key, url: url, fileName: fileName },
              });
              setState({
                ...state,
                uploadProgress: { ...state.uploadProgress, [name]: 100 },
              });
            });
        }
      }
    } catch (error) {
      addNotificationToApp('Oops! file failed to upload.', 'error');
    }
  };
  const handleLabelChange = (value: string, idx: number) => {
    const newElements = [...state.formElements];
    const inputElement = newElements[idx] as FormInputElementType;
    inputElement.label = value;
    setState({ ...state, formElements: newElements });
  };

  const handleTextChange = (value: string, idx: number) => {
    const newElements = [...state.formElements];
    const textElement = newElements[idx];
    if (
      textElement &&
      (textElement.element === 'text' || textElement.element.startsWith('h'))
    ) {
      (
        textElement as FormFormattingTextElement | FormFormattingHeaderElement
      ).text = value;
      setState({ ...state, formElements: newElements });
    }
  };

  // TODO: think about having only one function to handle input field updation
  const handleToggleRequired = (required: boolean, idx: number) => {
    const newElements = [...state.formElements];
    const inputElement = newElements[idx] as FormInputElementType;
    inputElement.isOptional = required;
    setState({ ...state, formElements: newElements });
  };

  const handleRadioButtonRange = (action: 'add' | 'remove', idx: number) => {
    const newElements = [...state.formElements];
    const inputElement = newElements[idx] as FormInputElementType;
    const max = inputElement.max;
    inputElement.max = max ? (action === 'add' ? max + 1 : max - 1) : 0;

    if (max && inputElement.rangeLabels) {
      inputElement.rangeLabels[inputElement.max] =
        inputElement.rangeLabels[max];
      delete inputElement.rangeLabels[max];
    }
    setState({ ...state, formElements: newElements });
  };

  const handleRadioButtonRangeLabels = (
    label: string,
    rangeIndex: number,
    idx: number,
  ) => {
    const newElements = [...state.formElements];
    const inputElement = newElements[idx] as FormInputElementType;

    if (inputElement.rangeLabels) {
      inputElement.rangeLabels[rangeIndex] = label;
    }
    setState({ ...state, formElements: newElements });
  };

  const handleOptionAdd = (elementIndex: number) => {
    const newElements = [...state.formElements];
    const inputElement = newElements[elementIndex] as FormInputElementType;
    const choices = inputElement.choices;
    inputElement.choices = choices
      ? [...choices, `Option ${choices.length + 1}`]
      : choices;
    setState({ ...state, formElements: newElements });
  };

  const handleOptionRemove = (elementIndex: number, optionIndex: number) => {
    const newElements = [...state.formElements];
    const inputElement = newElements[elementIndex] as FormInputElementType;
    const choices = inputElement.choices;
    inputElement.choices = choices
      ? [...choices.slice(0, optionIndex), ...choices.splice(optionIndex + 1)]
      : choices;
    setState({ ...state, formElements: newElements });
  };

  const handleOptionLabelChange = (
    value: string,
    elementIndex: number,
    labelIndex: number,
  ) => {
    const newElements = [...state.formElements];
    const inputElement = newElements[elementIndex] as FormInputElementType;
    const choices = inputElement.choices;
    if (choices) {
      choices[labelIndex] = value;
    }
    inputElement.choices = choices;
    setState({ ...state, formElements: newElements });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setState({ ...state, isFormSubmitting: true });
    if (!formSlug) {
      addNotificationToApp('Oops! something went wrong.', 'error');
      return;
    }

    const res = await submitFormBySlug(formSlug, formData);
    setState({ ...state, isFormSubmitted: !!res, isFormSubmitting: false });
  };

  const handleUpdateForm = useCallback(() => {
    const form = formById ?? formBySlug;
    if (!form) {
      return;
    }

    updateForm(user.userOrganizationId, {
      ...form,
      elements: state.formElements,
      name: state.formName ?? form.name,
    });
  }, [
    updateForm,
    formById,
    formBySlug,
    state.formElements,
    state.formName,
    user.userOrganizationId,
  ]);

  const handleAddElement = (element: FormElement) => {
    const newElement = createNewElement(element);
    setState({ ...state, formElements: [...state.formElements, newElement] });
  };

  const handleSearchBarInput = (columnId: string, value: string) => {
    setFormData({ ...formData, [columnId]: { userId: value } });
  };

  const handleDeleteElement = (idx: number) => {
    const updatedElements = [...state.formElements];
    updatedElements.splice(idx, 1);
    setState({ ...state, formElements: updatedElements });
  };

  const isMainForm = useMemo(
    () => !formBySlug && !isFormByIdLoading && !isFormBySlugLoading && formById,
    [formBySlug, isFormByIdLoading, isFormBySlugLoading, formById],
  );

  useEffect(() => {
    const form = formById ?? formBySlug;
    if (form) {
      setState((prevState) => ({ ...prevState, formElements: form.elements }));
    }
  }, [formById, formBySlug]);

  useEffect(() => {
    if (!state.isInEditingMode) {
      return;
    }

    const timeout = setTimeout(() => {
      handleUpdateForm();
    }, 1000);

    return () => clearTimeout(timeout);
  }, [
    state.formElements,
    state.isInEditingMode,
    state.formName,
    handleUpdateForm,
  ]);

  return {
    form: formById ?? formBySlug,
    isLoading: isFormByIdLoading || isFormBySlugLoading,
    state,
    isMainForm,
    handleFormNameChange,
    handleInputChange,
    handleLabelChange,
    handleTextChange,
    handleSubmit,
    handleAddElement,
    handleToggleRequired,
    handleRadioButtonRange,
    handleRadioButtonRangeLabels,
    handleOptionLabelChange,
    handleOptionAdd,
    handleOptionRemove,
    handleDeleteElement,
    setState,
    handleSearchBarInput,
  };
};
