import { useUserContext } from '@base/Context/UserContext/UserContext';
import { useUserBadges } from '@base/Hooks/useUserBadges';

export const useProfile = () => {
  const { user } = useUserContext();
  const { badges, isLoading } = useUserBadges(user);

  return {
    user,
    badges,
    isLoading,
  };
};
