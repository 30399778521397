import { memo } from 'react';
import { type User } from '@API';
import { ProfileCard } from '@Shared/Components/ProfileCard/ProfileCard';
import { TextField } from '@Shared/DS/Molecules';
import Loader from '@Shared/Components/Loader/Loader';
import { useSearchPeople } from './useSearchPeople';
import './SearchPeople.scss';

export const SearchPeople = memo(() => {
  const {
    splitUserArrays,
    isLoading,
    numberOfPages,
    onSearchInputChange,
    handleForwardPagination,
    handleBackwardPagination,
    handlePageShift,
  } = useSearchPeople();

  return (
    <Loader active={isLoading} styling="absolute">
      {!isLoading && (
        <div className="search-people-wrapper">
          <div className="container people-container">
            <div className="row justify-content-between">
              <div className="col-md-7 col-sm-6 col-lg-8">
                <h1>People</h1>
              </div>
            </div>

            <div className="navbar-typeahead-wrapper">
              <div className="d-flex flex-column ">
                <div className="ml-auto col-md-6">
                  <div className="navbar-search">
                    <TextField
                      id={'searchUser'}
                      type="text"
                      placeholder="Search by name, team or interest"
                      setValue={onSearchInputChange}
                    />
                  </div>
                </div>
              </div>
            </div>

            {splitUserArrays.map((user, index) => (
              <div className="row people-list mt-3" key={index}>
                {user.map((userInfo: User) => (
                  <div
                    key={`${index}-${userInfo.id}`}
                    className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-3"
                  >
                    <ProfileCard
                      firstName={userInfo.firstName ?? ''}
                      userId={userInfo.id}
                      lastName={userInfo.lastName ?? ''}
                      profilePicture={userInfo.photoUrl}
                      team={userInfo.team}
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div className="container mt-3">
            <div className="d-flex row justify-content-end">
              <ul className="pagination justify-content-end">
                <li>
                  <button
                    className="m-1 pagination-buttons"
                    onClick={handleBackwardPagination}
                  >
                    {'<'}
                  </button>
                </li>
                {Array.from({ length: numberOfPages }, (_, i) => i + 1).map(
                  (item, index) => (
                    <li key={index}>
                      <button
                        className="m-1 pagination-buttons"
                        onClick={() => handlePageShift(item)}
                      >
                        {item}
                      </button>
                    </li>
                  ),
                )}
                <li>
                  <button
                    className="m-1 pagination-buttons"
                    onClick={handleForwardPagination}
                  >
                    {'>'}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </Loader>
  );
});
