import { type TriggerAction, TriggerActionType } from '@models/form.model';

export const removeNameFromFormTriggers = (
  triggers: TriggerAction[],
): TriggerAction[] =>
  triggers.map((trigger) => {
    if (trigger.action !== TriggerActionType.SEND_FORM) {
      return trigger;
    }

    let { form, ...params } = trigger.params;
    if (form && typeof form === 'object' && 'name' in form) {
      form = { ...form, name: undefined };
      return {
        ...trigger,
        params: {
          ...params,
          form,
        },
      };
    }

    return trigger;
  });

export const removeEmailsFromTriggers = (
  triggers: TriggerAction[],
): TriggerAction[] =>
  triggers.map((trigger) => {
    const { receiver, cc, ...params } = trigger.params;

    let updatedReceiver;
    if (receiver && typeof receiver === 'object') {
      if ('email' in receiver && 'userId' in receiver) {
        updatedReceiver = { ...receiver, email: undefined };
      } else if ('email' in receiver) {
        updatedReceiver = receiver['email'];
      } else {
        updatedReceiver = receiver;
      }
    }

    const updatedCc = Array.isArray(cc)
      ? cc.map((ccItem) => {
          if (typeof ccItem === 'object' && 'email' in ccItem) {
            const { email, ...restCcItem } = ccItem;
            return restCcItem;
          }
          return ccItem;
        })
      : cc;

    return {
      ...trigger,
      params: {
        ...params,
        receiver: updatedReceiver,
        cc: updatedCc,
      },
    };
  }) as TriggerAction[];
