import { Row, Col } from 'react-bootstrap';
import './OrganizationInfo.scss';
import { FadeLoader } from '@Shared/DS';
import { memo } from 'react';
import { type OrganizationInfoProps } from '@models/settings.model';
import { useOrganizationInfo } from './useOrganizationInfo';

export const OrganizationInfo = memo((props: OrganizationInfoProps) => {
  const {
    organizationName,
    adminEmail,
    isOrgAdded,
    isLoading,
    setOrganizationName,
    setAdminEmail,
    addOrganization,
  } = useOrganizationInfo(props);

  return (
    <div className="organization-detail-wrapper">
      <Row className="mt-1 mb-4 justify-content-lg-center ms-lg-2">
        <Col xs="10" lg="5" className="g-0">
          <label className="text-start w-100 ps-2 fs-16">Admin Email</label>
          <input
            onChange={(e) => setAdminEmail(e.target.value)}
            className="text-start w-100 py-2 px-3"
            placeholder="Please Enter"
            value={adminEmail}
            disabled={isOrgAdded ? true : false}
          ></input>
        </Col>

        <Col xs="10" lg="5" className="g-0 mx-lg-2 mt-lg-0 mt-4">
          <label className="text-start w-100 ps-2 fs-16">
            Organization Name
          </label>
          <input
            onChange={(e) => setOrganizationName(e.target.value)}
            className="text-start w-100 py-2 px-3"
            placeholder="Please Enter"
            value={organizationName}
            disabled={isOrgAdded ? true : false}
          ></input>
        </Col>

        <Col xs="1">
          {isLoading && (
            <div className="loader-styling">
              <FadeLoader />
            </div>
          )}
          {!isOrgAdded && !isLoading && (
            <div className="delete-icon">
              <i
                className="bi bi-plus-circle"
                onClick={() => addOrganization(adminEmail, organizationName)}
              ></i>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
});
