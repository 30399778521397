import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { type SecretKeyManager } from '@API';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { getSecretKeyManagerData } from '@api/apis';

export const useCalendar = () => {
  const { user } = useUserContext();
  const [showAddKeysModal, setShowAddKeysModal] = useState(false);
  const [secretKeyCreatedDate, setSecretKeyCreatedDate] = useState('');
  const [isOutlookConnected, setIsOutlookConnected] = useState(false);

  const populateOrganizationSecrets = async (): Promise<
    SecretKeyManager | undefined
  > => {
    const secreteManagers = await getSecretKeyManagerData(
      user.userOrganizationId,
    );
    return secreteManagers;
  };
  const { data: secretManagerData } = useQuery({
    queryKey: ['getSecretKeyManagerData'],
    queryFn: populateOrganizationSecrets,
    enabled: !!user.userOrganizationId,
  });

  useEffect(() => {
    if (secretManagerData) {
      setSecretKeyCreatedDate(secretManagerData.createdAt);
    }
  }, [secretManagerData]);

  useEffect(() => {
    setIsOutlookConnected(!!user?.Organization?.outlookTenantId);
  }, [user]);

  return {
    secretManagerData,
    showAddKeysModal,
    secretKeyCreatedDate,
    isOutlookConnected,
    setShowAddKeysModal,
    setSecretKeyCreatedDate,
  };
};
