import './MembersTab.scss';
import { type User } from '@API';
import ProfilePhotoAWS from '@Shared/Components/ProfilePhotoAWS/ProfilePhotoAWS';
import { UserTypeDropDown } from '@Shared/Components/DropDownField/DropDownField';
import { DeleteButton } from '@Shared/Components/DeleteButton/DeleteButton';
import { InfoModal } from '@Shared/Components/InfoModal/InfoModal';
import { memo, useCallback } from 'react';
import { Button } from '@Shared/DS';
import { UpdateMemberModal } from '../UpdateMemberModal/UpdateMemberModal';
import { InviteMembersModal } from '../InviteMembersModal/InviteMembersModal';
import { useMembersTab } from './useMembersTab';

export const MembersTab = memo(() => {
  const {
    allOrganizationAdmins,
    allOrganizationUsers,
    showModal,
    showUpdateModal,
    selectedUser,
    isLoading,
    showConfirmationModal,
    deletedUserId,
    handleShowUserDetailModal,
    setShowModal,
    setShowUpdateModal,
    handleUpdateUserDetails,
    handleUpdateUserType,
    sendInvitation,
    handleUpdateUserStatus,
    setShowConfirmationModal,
    setDeletedUserId,
  } = useMembersTab();

  const MemberDetails = memo((props: any) => {
    const roles = {
      COMPANY_MEMBER: 'Member',
      COMPANY_ADMIN: 'Admin',
    };

    return (
      <div className="container-md-fluid container">
        {props.membersArray.map((userInfo: User, index: number) =>
          !userInfo.isActive && !userInfo.isFirstLogin ? null : (
            <div key={index}>
              <div className="row member-item">
                <div
                  className="col-lg-9 col-md-6 col-12 d-flex align-items-center"
                  onClick={() => handleShowUserDetailModal(userInfo)}
                >
                  <div className="col-lg-2 col-md-3 col-2">
                    <ProfilePhotoAWS
                      propsKey={index}
                      photoUrl={userInfo.photoUrl}
                    />
                  </div>
                  <div className="col-lg-7 col-md-7 col-10">
                    <p className="text-start fs-16-25272a-medium">
                      {userInfo.firstName && (
                        <span className="member-name me-2">
                          {userInfo.firstName} {userInfo.lastName}
                        </span>
                      )}
                    </p>
                    <p className="text-start fs-12">{userInfo.email}</p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-12 d-flex flex-nowrap mt-3 mt-md-0 align-items-center">
                  {!userInfo.isActive ? (
                    <div>
                      <span className="member-status--inactive">Inactive</span>
                    </div>
                  ) : userInfo.isFirstLogin &&
                    (userInfo.slackUserId === '' || !userInfo.slackUserId) ? (
                    <button
                      className="btn btn-button-primary invite-btn rounded-pill fs-16-medium-bold"
                      onClick={() => sendInvitation(userInfo)}
                    >
                      {isLoading ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>{' '}
                          Sending...
                        </>
                      ) : (
                        'Send invite'
                      )}
                    </button>
                  ) : (
                    <UserTypeDropDown
                      items={Object.values(roles)}
                      disabled={false}
                      firstItemIndex={Object.keys(roles).findIndex(
                        (roleType) => userInfo.type === roleType,
                      )}
                      userInfo={userInfo}
                      onItemChange={handleUpdateUserType}
                    />
                  )}

                  <div className="ms-3">
                    {userInfo.isActive ? (
                      <DeleteButton
                        handleOnClick={() => {
                          setShowConfirmationModal(true);
                          setDeletedUserId(userInfo.id);
                        }}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ),
        )}
      </div>
    );
  });

  return (
    <div className="members-tab-wrapper">
      <div className="row settings-details-styling">
        <div className="col-lg-4 col-md-12 col-sm-12 text-start mb-lg-0 mb-5 settings-list-styling">
          <div className="fs-20-responsive">
            <div className="fs-20-semi-bold-25272a">
              Team members in Kinfolk
            </div>
          </div>
          <div className="fs-16-responsive pt-2">
            Invite existing employees and new hires.
          </div>
          <Button
            title="Invite new and existing employees"
            text="Invite team member"
            onClick={() => setShowModal(true)}
            additionalClasses="mt-3"
          ></Button>
          <div className="fs-16-responsive pt-2 mt-5">
            <strong>Members</strong> have access to microsites that are shared
            with them from their Home page, plus the People directory and
            profiles.
          </div>

          <div className="fs-16-responsive pt-2">
            <strong>Admins</strong> can create, edit and archive runbooks, and
            have access to Templates, People, and Settings.
          </div>
        </div>
        <div className="col-lg-8 col-md-12 col-sm-12">
          <div className="settings-list-container">
            <MemberDetails membersArray={allOrganizationAdmins} />
            <MemberDetails membersArray={allOrganizationUsers} />
          </div>
        </div>
      </div>
      <InviteMembersModal
        showModal={showModal}
        handleShowModal={useCallback(
          (value: boolean) => setShowModal(value),
          [setShowModal],
        )}
      />

      <UpdateMemberModal
        user={selectedUser}
        showModal={showUpdateModal}
        handleShowModal={setShowUpdateModal}
        handleSave={handleUpdateUserDetails}
      />

      <InfoModal
        showModal={showConfirmationModal}
        handleShowModal={setShowConfirmationModal}
        description={<div>Are you sure you want to delete this user?</div>}
        title="Delete user"
        onClickConfirmButton={() => {
          handleUpdateUserStatus(deletedUserId);
        }}
        isDeleteButton={true}
      />
    </div>
  );
});
