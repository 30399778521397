import { getUserDetailsById, listAssignedJourneys } from '@api/apis';
import { type Journey, type User } from '@base/API';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { useUserBadges } from '@base/Hooks/useUserBadges';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const useUserProfile = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { userId } = useParams();
  const navigate = useNavigate();
  const { user: currentUser } = useUserContext();

  const [user, setUser] = useState<User>();
  const [playbooks, setPlaybooks] = useState<Journey[]>([]);
  const { badges } = useUserBadges(user ?? ({} as User));

  const getUser = useCallback(
    async (userId: string, organizationId: string) => {
      setIsLoading(true);
      const [user, playbooks] = await Promise.all([
        getUserDetailsById(userId, organizationId),
        listAssignedJourneys(userId),
      ]);

      if (user && playbooks) {
        setUser(user);
        setPlaybooks(playbooks);
        setIsLoading(false);
        return;
      }

      navigate('/');
    },
    [navigate],
  );

  useEffect(() => {
    userId &&
      currentUser.userOrganizationId &&
      getUser(userId, currentUser.userOrganizationId);
  }, [userId, currentUser, getUser]);

  const isAboutPresent = () => user?.aboutMe && user.aboutMe !== '';

  const isBadgesExists = () => (user?.UserBadges?.items.length ? true : false);

  const isInterestsPresent = () => {
    if (user?.Interests?.items?.length) {
      return true;
    }
    return false;
  };

  const isWorkPreferencesPresent = () => {
    if (
      user &&
      (user.WorkPreference.messageTimingPreferene >= 0 ||
        user.WorkPreference.giveMeAllTheContext >= 0 ||
        user.WorkPreference.recognitionPrivateVsPrivate >= 0 ||
        user.WorkPreference.syncxVsAsync >= 0 ||
        user.WorkPreference.talkTogetherVsAlone >= 0)
    ) {
      return true;
    }

    return false;
  };

  const isHappyPresent = () => user?.mostHappy && user.mostHappy !== '';

  const isLearnMorePresent = () => {
    if (user?.LearnMoreOn?.items?.length) {
      return true;
    }
    return false;
  };

  const isWorkStylePresent = () =>
    (user?.WorkStyle.digestionInformation &&
      user.WorkStyle.digestionInformation !== '') ||
    (user?.WorkStyle.giveReceivingFeedback &&
      user.WorkStyle.giveReceivingFeedback !== '') ||
    (user?.WorkStyle.inMyElement && user.WorkStyle.inMyElement !== '') ||
    (user?.WorkStyle.myFocusTimeIs && user.WorkStyle.myFocusTimeIs !== '') ||
    (user?.WorkStyle.whatIDoAtWork && user.WorkStyle.whatIDoAtWork !== '');

  return {
    isLoading,
    user,
    playbooks,
    badges,
    isAboutPresent,
    isBadgesExists,
    isHappyPresent,
    isWorkPreferencesPresent,
    isWorkStylePresent,
    isLearnMorePresent,
    isInterestsPresent,
  };
};
