import { type Milestone } from '@API';
import { pasteAsPlainText } from '@Shared/utils/utils';
import {
  DragDropContext,
  Draggable,
  Droppable,
  type DroppableProvided,
  type OnDragEndResponder,
} from 'react-beautiful-dnd';
import { RiEyeCloseFill } from 'react-icons/ri';
import { FaRegEye } from 'react-icons/fa';
import { DragButton } from '../../../Components/DragButton/DragButton';
import './MilestoneDraggableList.scss';

interface IMilestoneDraggableList {
  milestones: Milestone[];
  selectedMilestoneIndex: number;
  handleMilestoneTextChange: Function;
  handleMilestoneName: Function;
  handleActiveMilestone: Function;
  isEditable: boolean;
  isBusy: boolean;
  handleShowConfirmationModal: () => void;
  onDragEnd: OnDragEndResponder;
  milestoneNames: string[];
  lockChapters?: boolean;
  isParentPlaybook?: boolean;
  archiveUnarchiveChapter?: (archive: boolean) => void;
}

export const MilestoneDraggableList = (props: IMilestoneDraggableList) => {
  const {
    milestones,
    selectedMilestoneIndex,
    handleMilestoneTextChange,
    handleMilestoneName,
    isEditable,
    isBusy,
    handleActiveMilestone,
    handleShowConfirmationModal,
    onDragEnd,
    milestoneNames,
    lockChapters,
  } = props;

  return (
    <DragDropContext
      onDragStart={() =>
        handleMilestoneTextChange(
          milestoneNames && milestoneNames[selectedMilestoneIndex],
        )
      }
      onDragEnd={onDragEnd}
    >
      <Droppable droppableId="milestones" direction="vertical">
        {(provided: DroppableProvided) => (
          <ul
            className="milestones-nav mt-1 p-0"
            id="menu"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {milestones?.map((milestone: Milestone, index: number) => (
              <Draggable
                key={milestone.id}
                draggableId={milestone.id}
                index={index}
                isDragDisabled={!props.isEditable || isBusy}
              >
                {(provided, snapshot) => (
                  <div
                    className={`d-flex flex-grow-1 align-items-center mt-3 tab justify-content-between ${
                      selectedMilestoneIndex === index ? 'active' : ''
                    }`}
                  >
                    <div className="d-flex align-items-center">
                      {isEditable ? (
                        <span
                          className="drag-button me-1 mb-1"
                          {...provided.dragHandleProps}
                        >
                          <DragButton width="13" height="13" />
                        </span>
                      ) : (
                        <span
                          className={`me-2 ${lockChapters ? 'milestone__disabled' : 'cursor-pointer'} `}
                          onClick={() => handleActiveMilestone(index)}
                        >
                          {milestone.isCompleted ? (
                            <span className="milestone-status__completed">
                              <i className="bi bi-check"></i>
                            </span>
                          ) : selectedMilestoneIndex === index ? (
                            <span className="milestone-status__active"></span>
                          ) : (
                            <span className="milestone-status"></span>
                          )}
                        </span>
                      )}

                      <li
                        className={`${lockChapters ? 'milestone__disabled' : 'cursor-pointer'} ${
                          selectedMilestoneIndex === index &&
                          !milestone.isArchived
                            ? 'active'
                            : ''
                        } ${milestone.isArchived ? 'milestone__hidden' : ''}`}
                        onClick={() => handleActiveMilestone(index)}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                      >
                        {isEditable ? (
                          <input
                            value={
                              (milestoneNames.length &&
                                milestoneNames[index]) ??
                              ''
                            }
                            onPaste={pasteAsPlainText}
                            title={milestoneNames && milestoneNames[index]}
                            disabled={!isEditable || isBusy}
                            onBlur={() =>
                              handleMilestoneTextChange(
                                milestoneNames && milestoneNames[index],
                              )
                            }
                            className="p-0 cursor-pointer editable-content"
                            onChange={(e) =>
                              handleMilestoneName(e.target.value, index)
                            }
                          />
                        ) : (
                          <span>
                            {(milestoneNames.length && milestoneNames[index]) ??
                              ''}
                          </span>
                        )}
                      </li>
                    </div>

                    {props.isEditable &&
                      (!props.isParentPlaybook ? (
                        <div
                          className={`milestone__visibility cursor-pointer ${milestone.isArchived ? 'milestone__hidden' : ''}`}
                          onClick={() => {
                            if (!props.archiveUnarchiveChapter) {
                              return;
                            }
                            props.archiveUnarchiveChapter(
                              !milestone.isArchived,
                            );
                          }}
                        >
                          {milestone.isArchived ? (
                            <RiEyeCloseFill title="Show chapter" />
                          ) : (
                            <FaRegEye title="Hide chapter" />
                          )}
                        </div>
                      ) : (
                        <i
                          className="bi bi-trash delete-icon me-3 cursor-pointer"
                          onClick={handleShowConfirmationModal}
                        ></i>
                      ))}
                  </div>
                )}
              </Draggable>
            ))}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );
};
