import {
  type StandardContentBlock,
  type TaskBlock,
  type NoteBlock,
  type FreeTextQuestionBlock,
  type MultipleChoiceQuestionBlock,
  type Milestone,
  type EmbeddedContentBlock,
  type ModelEmbeddedContentBlockConnection,
  type ModelFreeTextQuestionBlockConnection,
  type ModelMultipleChoiceQuestionBlockConnection,
  type ModelNoteBlockConnection,
  type ModelStandardContentBlockConnection,
  type ModelTaskBlockConnection,
} from '../../API';
import { createNewMilestone, getJourneyMilestones } from '../../apis/apis';

export const handleNewMilestone = async (
  milestones: Milestone[],
  journeyId: string,
): Promise<Milestone[] | undefined> => {
  try {
    const milestoneId = await createNewMilestone({
      name: 'Title',
      journeyID: journeyId,
      orderNo: milestones.length,
    } as Milestone);
    if (milestoneId) {
      milestones.push({
        id: milestoneId,
        name: 'Title',
        journeyID: journeyId,
        orderNo: milestones.length + 1,
        EmbeddedContentBlocks: {
          items: [] as EmbeddedContentBlock[],
        } as ModelEmbeddedContentBlockConnection,
        FreeTextQuestionBlocks: {
          items: [] as FreeTextQuestionBlock[],
        } as ModelFreeTextQuestionBlockConnection,
        NoteBlocks: { items: [] as NoteBlock[] } as ModelNoteBlockConnection,
        TaskBlocks: { items: [] as TaskBlock[] } as ModelTaskBlockConnection,
        StandardContentBlocks: {
          items: [] as StandardContentBlock[],
        } as ModelStandardContentBlockConnection,
        MultipleChoiceQuestionBlocks: {
          items: [] as MultipleChoiceQuestionBlock[],
        } as ModelMultipleChoiceQuestionBlockConnection,
      } as Milestone);

      return milestones;
    }
  } catch (e) {}
};

export const handleGetMilestones = async (
  milestones: Milestone[],
  journeyId: string,
): Promise<Milestone[] | undefined> => {
  try {
    const journeyMilestones = await getJourneyMilestones(journeyId);
    if (journeyMilestones && !journeyMilestones.length) {
      const milestoneId = await createNewMilestone({
        name: 'Welcome',
        journeyID: journeyId || '',
        orderNo: milestones.length,
      } as Milestone);
      if (milestoneId) {
        milestones.push({
          id: milestoneId,
          name: 'Welcome',
          orderNo: milestones.length + 1,
          EmbeddedContentBlocks: {
            items: [] as EmbeddedContentBlock[],
          } as ModelEmbeddedContentBlockConnection,
          FreeTextQuestionBlocks: {
            items: [] as FreeTextQuestionBlock[],
          } as ModelFreeTextQuestionBlockConnection,
          NoteBlocks: {
            items: [] as NoteBlock[],
          } as ModelNoteBlockConnection,
          TaskBlocks: {
            items: [] as TaskBlock[],
          } as ModelTaskBlockConnection,
          StandardContentBlocks: {
            items: [] as StandardContentBlock[],
          } as ModelStandardContentBlockConnection,
          MultipleChoiceQuestionBlocks: {
            items: [] as MultipleChoiceQuestionBlock[],
          } as ModelMultipleChoiceQuestionBlockConnection,
          journeyID: journeyId || '',
        } as Milestone);

        return milestones;
      }
    } else if (journeyMilestones) {
      return journeyMilestones;
    }
  } catch (e) {}
};
