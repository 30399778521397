import { useCallback, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { type User, UserType } from '@API';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { getAllOrganizationUsers } from '@api/apis';

export const useSearchPeople = () => {
  const [allOrganizationUsers, setAllOrganizationUsers] = useState<User[]>([]);
  const { user } = useUserContext();
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [currentSlide, setCurrentSlide] = useState(1);
  const peoplesPerPage = 16;

  const paginateArray = useCallback(
    (pageIndex: number) => {
      let arr: any[] = [];
      for (let i = 0; i < filteredUsers.length; i++) {
        if (i === pageIndex) {
          const startIndex = (pageIndex - 1) * peoplesPerPage;
          const endIndex = startIndex + peoplesPerPage;
          arr.push(filteredUsers.slice(startIndex, endIndex));
        }
      }
      return arr;
    },
    [filteredUsers],
  );

  const [splitUserArrays, setSplitUserArrays] = useState(paginateArray(1));

  const handleBackwardPagination = () => {
    if (currentSlide > 1) {
      setCurrentSlide(currentSlide - 1);
      setSplitUserArrays(paginateArray(currentSlide - 1));
    }
  };
  const handleForwardPagination = () => {
    if (currentSlide < numberOfPages) {
      setCurrentSlide(currentSlide + 1);
      setSplitUserArrays(paginateArray(currentSlide + 1));
    }
  };

  const handlePageShift = (pageNumber: number) => {
    setCurrentSlide(pageNumber);
    setSplitUserArrays(paginateArray(pageNumber));
  };

  // Sort an array of users alphabetically by first name

  const sortUsers = (users: User[]) => {
    users.sort((a, b) => {
      if (a.firstName && b.firstName) {
        return a.firstName.localeCompare(b.firstName);
      }
      return 0;
    });
  };

  const getAllUsers = async (): Promise<User[]> => {
    const users = await getAllOrganizationUsers({
      userOrganizationId: { eq: user.userOrganizationId },
      isActive: { eq: true },
      and: [
        { type: { ne: UserType.CANDIDATE } },
        { type: { ne: UserType.ANONYMOUS } },
        { type: { ne: UserType.SUPER_ADMIN } },
      ],
    });
    if (users && users.length) {
      setNumberOfPages(Math.ceil(users.length / peoplesPerPage));
      sortUsers(users);
      return users;
    }

    return [] as User[];
  };
  const { data, isLoading } = useQuery({
    queryKey: ['allOrganizationUsers'],
    queryFn: getAllUsers,
    enabled: !!user.userOrganizationId,
  });

  const onSearchInputChange = async (searchQuery: string) => {
    const users = allOrganizationUsers.filter(
      (user) =>
        user.firstName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.lastName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.team?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.Interests?.items.some((interest) =>
          interest?.interest.name
            .toLowerCase()
            .includes(searchQuery.toLowerCase()),
        ),
    );

    if (users) {
      setSplitUserArrays([users]);
    }
  };

  useEffect(() => {
    if (data) {
      setAllOrganizationUsers(data);
      setFilteredUsers(data);
    }
  }, [data]);

  useEffect(() => {
    setSplitUserArrays(paginateArray(1));
  }, [filteredUsers, paginateArray]);

  return {
    allOrganizationUsers,
    splitUserArrays,
    isLoading,
    numberOfPages,
    onSearchInputChange,
    handleForwardPagination,
    handleBackwardPagination,
    handlePageShift,
  };
};
