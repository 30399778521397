import { useState, useEffect } from 'react';

export const useIsInIframe = () => {
  const [isInIframe, setIsInIframe] = useState(false);

  useEffect(() => {
    function checkIfInIframe() {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    }

    setIsInIframe(checkIfInIframe());
  }, []);

  return isInIframe;
};
