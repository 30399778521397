import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { type FreeTextQuestionBlock } from '@API';
import {
  deleteBlock,
  updateFreeTextQuestionBlock as updateFreeTextQuestionBlockApi,
} from '@api/apis';
import { useNonInitialEffect } from '@Hooks/useNonInitialEffect';
import { updateBlockDescription } from '@Shared/utils/utils';
import { useUserContext } from '@base/Context/UserContext/UserContext';

export const useFreeTextQuestionBlock = (
  block: FreeTextQuestionBlock,
  handleUpdateMilestoneBlocks: Function,
  handleDeleteBlock: Function,
  setShowLoader: Function,
  setShowSaved: Function,
  isParentPlaybook: boolean,
  isEditable: boolean,
  setIsSaving?: Function,
) => {
  const [title, setTitle] = useState(block.title);
  const [description, setDescription] = useState(block.description ?? '');
  const [answer, setAnswer] = useState(block.text ?? '');
  const [isCompleted, setIsCompleted] = useState(block.isCompleted);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { user } = useUserContext();
  const { id } = useParams();

  const handleUpdateBlockDescription = async () => {
    if (isEditable && id && description !== block.description) {
      const updateDescriptionImages = await updateBlockDescription(
        description,
        block.id,
        block.type,
        block.milestoneID,
        user.userOrganizationId,
        id,
      );
      setShowLoader(true);
      await updateFreeTextQuestionBlockApi({
        ...block,
        description: updateDescriptionImages,
        title: title,
        text: answer,
        updatedInChild: !isParentPlaybook,
      });
      setDescription(updateDescriptionImages);
      handleUpdateMilestoneBlocks({
        ...block,
        description: updateDescriptionImages,
      });
    }
    setShowLoader(false);
    setShowSaved(true);
  };

  const updateFreeTextQuestionBlock = async () => {
    if (block.title !== title || block.text !== answer) {
      handleUpdateMilestoneBlocks({
        ...block,
        title: title,
        text: answer,
      });
      setShowLoader(true);
      await updateFreeTextQuestionBlockApi({
        ...block,
        title: title,
        text: answer,
        updatedInChild: !isParentPlaybook,
      });
      setShowLoader(false);
      setShowSaved(true);
    }
  };

  const removeBlock = async () => {
    let id;
    if (isParentPlaybook) {
      id = await deleteBlock(block.id, block.type);
    } else {
      id = await updateFreeTextQuestionBlockApi({
        ...block,
        isArchived: true,
      });
    }

    if (id) {
      handleDeleteBlock(block.id, block.type, block.orderNo);
      setShowConfirmationModal(false);
    }
  };

  const handleComplete = async () => {
    const id = await updateFreeTextQuestionBlockApi({
      ...block,
      isCompleted: !isCompleted,
    } as FreeTextQuestionBlock);
    if (id) {
      setIsCompleted(!isCompleted);
      handleUpdateMilestoneBlocks({
        ...block,
        isCompleted: !isCompleted,
      });
    }
  };

  useEffect(() => {
    setTitle(block.title);
    setDescription(block.description ?? '');
    setAnswer(block.text ?? '');
    setIsCompleted(block.isCompleted);
  }, [block]);

  useNonInitialEffect(() => {
    if (block.title !== title || block.text !== answer) {
      setIsSaving?.(true);
    } else {
      setIsSaving?.(false);
    }
    const timeOut = setTimeout(() => {
      updateFreeTextQuestionBlock();
    }, 1000);

    return () => clearTimeout(timeOut);
  }, [title, answer]);

  useNonInitialEffect(() => {
    if (id && description !== block.description) {
      setIsSaving?.(true);
    } else {
      setIsSaving?.(false);
    }
    const timeOut = setTimeout(() => {
      handleUpdateBlockDescription();
    }, 1000);

    return () => clearTimeout(timeOut);
  }, [description]);

  return {
    title,
    description,
    answer,
    showConfirmationModal,
    isCompleted,
    handleComplete,
    setShowConfirmationModal,
    setDescription,
    setTitle,
    setAnswer,
    removeBlock,
  };
};
