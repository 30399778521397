import { Button, TemplateHomeTabs, ToggleSwitch } from '@Shared/DS';
import { BsChevronRight } from 'react-icons/bs';
import { Container } from 'react-bootstrap';
import Loader from '@Shared/Components/Loader/Loader';
import { pasteAsPlainText } from '@Shared/utils/utils';
import { useTemplateHome } from './useTemplateHome';
import './TemplateHome.scss';

export const TemplateHome = () => {
  const {
    states,
    template,
    isLoading,
    setStates,
    handleBack,
    navigateTo,
    updateTemplate,
    createPlaybookFromTemplate,
  } = useTemplateHome();

  return (
    <Container fluid className="template-home p-4">
      {isLoading || !template ? (
        <Loader active={isLoading || !template} />
      ) : (
        <>
          <div className="d-flex justify-content-between align-items-center playbook__breadcrumbs">
            <div className="text-start">
              <button
                className="btn btn-link link-underline-light"
                onClick={() => handleBack()}
              >
                All Templates
              </button>
              <BsChevronRight />
              <>
                <button
                  className="btn btn-link link-underline-light cursor-pointer"
                  onClick={() => navigateTo(`/template/${template.id}`)}
                >
                  <input
                    value={states.templateName}
                    onPaste={pasteAsPlainText}
                    disabled={!states.isEditingAllowed}
                    title="Click to Edit name"
                    onBlur={(e) =>
                      updateTemplate({ ...template, name: e.target.value })
                    }
                    className="playbook__name"
                    onChange={(e) =>
                      setStates((states) => ({
                        ...states,
                        templateName: e.target.value,
                      }))
                    }
                  />
                </button>
              </>
            </div>

            <div className="d-flex gap-2">
              {states.isEditingAllowed && (
                <div className="d-flex align-items-center gap-1">
                  <ToggleSwitch
                    initialValue={template.isPublic ?? false}
                    setValue={() =>
                      updateTemplate({
                        ...template,
                        isPublic: !template.isPublic,
                      })
                    }
                  />
                  <span className="fw-normal">Public</span>
                </div>
              )}

              <Button
                buttonStyle="outline"
                text="Use template"
                showLoading={states.isBusy}
                disabled={states.isBusy}
                onClick={createPlaybookFromTemplate}
              />
            </div>
          </div>

          <TemplateHomeTabs
            template={template}
            isEditingAllowed={states.isEditingAllowed}
          />
        </>
      )}
    </Container>
  );
};
