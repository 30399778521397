import { Card } from 'react-bootstrap';
import { Draggable } from 'react-beautiful-dnd';
import { type TaskBlock } from '@API';
import { CrossButton } from '../../CrossButton/CrossButton';
import { EditableContent } from '../../EditableContent/EditableContent';
import { InfoModal } from '../../InfoModal/InfoModal';
import { TextEditor } from '../../TextEditor/TextEditor';
import { DragButton } from '../../DragButton/DragButton';
import { useTaskBlock } from './useTask';
import './Task.scss';

interface TaskBlockProps {
  index: number;
  block: TaskBlock;
  isEditable: boolean;
  isAssignee?: boolean;
  handleUpdateMilestoneBlocks: Function;
  setShowLoader: Function;
  setShowSaved: Function;
  handleDeleteBlock: Function;
  setIsSaving?: Function;
  isBusy: boolean;
  isParentPlaybook: boolean;
}

export const TaskBlocks = (props: TaskBlockProps) => {
  const {
    title,
    description,
    showConfirmationModal,
    taskStatus,
    setShowConfirmationModal,
    setTitle,
    setDescription,
    removeBlock,
    handleCompleteTask,
  } = useTaskBlock(
    props.block,
    props.handleUpdateMilestoneBlocks,
    props.handleDeleteBlock,
    props.setShowLoader,
    props.setShowSaved,
    props.isParentPlaybook,
    props.isEditable,
    props.setIsSaving,
  );

  const { id } = props.block;

  return (
    <Draggable
      key={props.block.id}
      draggableId={props.block.id}
      index={props.index}
      isDragDisabled={props.isBusy || !props.isEditable}
    >
      {(provided, snapshot) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <Card
            className={`task-block-wrapper border-radius-10 mt-3 pb-2 content-block ${snapshot.isDragging ? 'block-dragging-active' : ''}`}
          >
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div className="w-100">
                  <div className="d-flex">
                    {props.isEditable && (
                      <span
                        className="drag-button me-2"
                        {...provided.dragHandleProps}
                      >
                        <DragButton />
                      </span>
                    )}
                    <input
                      disabled={!props.isAssignee}
                      checked={
                        taskStatus === null || !taskStatus ? false : true
                      }
                      className="form-check-input checkbox"
                      type="checkbox"
                      onChange={(value) => {
                        handleCompleteTask(value.target.checked);
                      }}
                    />
                    <div className="fs-20-semi-bold-4C5564 ms-2 w-100">
                      <EditableContent
                        id={`${id}-title`}
                        value={title}
                        setValue={setTitle}
                        isEditable={props.isEditable}
                      />
                    </div>
                  </div>
                </div>
                {props.isEditable && (
                  <CrossButton
                    isDisabled={props.isBusy}
                    handleClick={() => setShowConfirmationModal(true)}
                  />
                )}
              </div>
              {!props.isEditable && description === '' ? null : (
                <div className="row">
                  <div className="col-12 mt-2">
                    <TextEditor
                      id={id}
                      value={description}
                      setValue={setDescription}
                      isEditable={props.isEditable}
                    />
                  </div>
                </div>
              )}
            </Card.Body>

            <InfoModal
              showModal={showConfirmationModal}
              handleShowModal={setShowConfirmationModal}
              description={
                <div>Are you sure you want to delete this content block?</div>
              }
              title="Delete content block"
              onClickConfirmButton={removeBlock}
              isDeleteButton={true}
            />
          </Card>
        </div>
      )}
    </Draggable>
  );
};
