import type { PillProps } from '@Shared/DS';
import { AssigneeRole } from '@API';

export const toColor = (role: string): PillProps['color'] => {
  if (role.includes('All')) {
    return 'purple';
  }

  if (role.includes('Assignee')) {
    return 'navy';
  }

  if (role.includes(AssigneeRole.IT)) {
    return 'red';
  }

  if (role.includes(AssigneeRole.HROPS) || role.includes('HR Ops')) {
    return 'brightGreen';
  }

  if (
    role.includes(AssigneeRole.HRBP) ||
    role.toLowerCase().includes(AssigneeRole.LEGAL.toLowerCase())
  ) {
    return 'gray';
  }

  if (role.toLowerCase().includes(AssigneeRole.MANAGER.toLowerCase())) {
    return 'pink';
  }

  if (role.toLowerCase().includes(AssigneeRole.BUDDY.toLowerCase())) {
    return 'babyBlue';
  }

  return 'concrete';
};
