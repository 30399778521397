import './LastOnboardingPage.scss';
import OnboardingButton from '@Shared/Components/OnboardingButton/OnboardingButton';
import { Link } from 'react-router-dom';
import { useLastOnboardingPage } from './useLastOnboardingPage';

export default function LastOnboardingPage(props: any) {
  const { userPhoto } = useLastOnboardingPage(props.profilePicture);
  return (
    <div className="last-onboarding-page-wrapper mt-5">
      <div className="pb-4">
        <h1 className="text-center">Thanks for sharing!</h1>
        <div className="images-container">
          <div className="d-flex justify-content-center mt-3">
            <img
              src={userPhoto}
              alt="onbaording profile"
              className="circular-image m-4"
            ></img>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <p className="col-8 text-center fs-22-medium-bold-384152">
            You can also add how you like to work and communicate to your
            profile, so your teammates can get to know you better. How about it?
          </p>
        </div>
        <div className="container mt-5">
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <Link to="/profile" className="col-6 mt-2">
                <OnboardingButton
                  text="Sure, let's see my profile"
                  icon={true}
                  styling="px-4 py-2"
                  width={'w-100'}
                  handleClick={props.handleClick}
                  theme="view-profile-button-wrapper"
                />
              </Link>
            </div>
            <div className="col-12 d-flex justify-content-center">
              <Link
                to="/"
                className="col-lg-6 col-md-6 col-sm-12 mt-3 text-center"
              >
                No thanks, I'll do it later
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
