import { addNotificationToApp, isInPast } from '@Shared/utils/utils';
import {
  sendActionNotification,
  sendFormNotification,
  sendMessageNotification,
  updatePlaybookEvent,
  updatePlaybookEventReminder,
} from '@api/apis';
import {
  type Event,
  type EventReminder,
  EventStatus,
  JourneyStatus,
} from '@base/API';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { Keys } from '@base/keys/queryKeys';
import { EventType } from '@base/models/common.model';
import { type IEvent } from '@base/models/playbookHome.model';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export const useMessageActionCard = (
  event: IEvent,
  isParentPlaybook: boolean,
  editEvent: (id: string, type: EventType) => void,
  assigneeSlackId?: string,
  playbookStatus?: JourneyStatus,
) => {
  const [states, setStates] = useState({ showConfirmationModal: false });
  const { user } = useUserContext();
  const { id: playbookId } = useParams();
  const queryClient = useQueryClient();
  const { id } = event;

  const updateEventAndReminderStatus = useCallback(
    async (event: Event, status: EventStatus) => {
      if (!event.reminders) {
        return;
      }

      const [reminder] = event.reminders.items;
      if (!reminder) {
        throw new Error(`Reminder is not set for event ${id}`);
      }

      await Promise.all([
        updatePlaybookEvent({ id: reminder.eventId, status } as Event),
        updatePlaybookEventReminder({
          id: reminder.id,
          status,
        } as EventReminder),
      ]);
    },
    [id],
  );

  const dropdownItems = useMemo(() => {
    const handleEditClick = () => editEvent(id, event.type as EventType);

    const handleSendNowClick = async () => {
      if (!playbookId) {
        return;
      }

      const toastId = toast.loading('Please wait...');
      let isSent;
      if (event.type === EventType.ACTION) {
        isSent = await sendActionNotification(
          playbookId,
          user.userOrganizationId,
          id,
          event.isActionReminder,
        );
      } else if (event.type === EventType.MESSAGE) {
        isSent = await sendMessageNotification(
          playbookId,
          user.userOrganizationId,
          id,
        );
      } else {
        const eventId = event.isActionReminder ? event.eventId : id;
        if (eventId) {
          isSent = await sendFormNotification(
            playbookId,
            user.userOrganizationId,
            eventId,
            event.isActionReminder,
          );
        } else {
          addNotificationToApp('Oops! something went wrong', 'error');
        }
      }

      queryClient.invalidateQueries({ queryKey: Keys.getEvents(playbookId) });
      toast.update(toastId, {
        render: isSent
          ? 'Notification sent successfully.'
          : 'Oops! something went wrong.',
        type: isSent ? 'success' : 'error',
        isLoading: false,
        autoClose: 3000,
      });
    };

    const handleMarkAsDoneClick = async () => {
      if (!event.isActionReminder) {
        const reminders = event.reminders?.items;
        if (!reminders || !reminders.length) {
          await updatePlaybookEvent({ id, status: EventStatus.DONE } as Event);
        } else {
          await updateEventAndReminderStatus(event, EventStatus.DONE);
        }
      } else {
        await updateEventAndReminderStatus(event, EventStatus.DONE);
      }

      queryClient.invalidateQueries({ queryKey: Keys.getEvents(playbookId) });
    };

    const handleDeleteClick = () =>
      setStates((prevStates) => ({
        ...prevStates,
        showConfirmationModal: true,
      }));

    let items: any = [
      {
        title: 'Edit',
        onClick: handleEditClick,
      },
      {
        title: 'Send now',
        onClick: handleSendNowClick,
      },
      {
        title: 'Mark as done',
        onClick: handleMarkAsDoneClick,
        disabled: event.status === EventStatus.DONE,
      },
      {
        title: 'Delete',
        onClick: handleDeleteClick,
      },
    ].filter((item) => {
      if (!isParentPlaybook) {
        // Hide send now button if child runbook is in draft
        if (
          playbookStatus === JourneyStatus.DRAFT &&
          item.title === 'Send now'
        ) {
          return false;
        }

        // Show all the options if event type is action
        if (event.type === EventType.ACTION) {
          return true;
        }

        // Hide mark as done button for message events
        if (item.title !== 'Mark as done') {
          return true;
        }
      }

      // Hide below options in parent runbook
      if (item.title === 'Mark as done' || item.title === 'Send now') {
        return false;
      }

      return true;
    });

    return items;
  }, [
    editEvent,
    event,
    id,
    isParentPlaybook,
    playbookId,
    playbookStatus,
    queryClient,
    updateEventAndReminderStatus,
    user.userOrganizationId,
  ]);

  const icon = useMemo(() => {
    if (event.type === EventType.ACTION || event.type === EventType.FORM) {
      return 'bi bi-check-circle';
    }

    return 'bi bi-megaphone';
  }, [event.type]);

  const warning = useMemo(() => {
    if (
      isParentPlaybook ||
      event.status === EventStatus.SENT ||
      event.status === EventStatus.DONE
    ) {
      return;
    }

    if (!event.userId && !event.emailTo) {
      return 'Event is missing an assignee';
    }
    if (event.status === EventStatus.OVERDUE) {
      return 'Event is overdue';
    }
    if (event.sendTimeDate && isInPast(event.sendTimeDate)) {
      return 'Event is in the past';
    }
  }, [isParentPlaybook, event]);

  return {
    dropdownItems,
    icon,
    states,
    warning,
    setStates,
  };
};
