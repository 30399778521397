import { JourneyBuilderKey } from '@Pages/JourneyBuilder/queries';
import { compareStrings } from '@Shared/utils/compare';
import {
  getJourneyCollaborators,
  searchUser,
  updateJourneyCollaboratorAssignee,
  updateCollaboratorAssigneeInEvents,
} from '@api/apis';
import { type User, AssigneeRole } from '@base/API';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { Keys } from '@base/keys/queryKeys';
import { type CustomPlaybookCollaborator } from '@base/models/common.model';
import { type Collaborator } from '@base/models/journeyLibrary.model';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

export const useCollaborator = (isParentJourney?: boolean) => {
  const { id } = useParams();
  const location = useLocation();
  const { user, setCollaborators } = useUserContext();
  const [journeyCollaborators, setJourneyCollaborators] = useState<
    Collaborator[]
  >([]);
  const [assignees, setAssignees] = useState<User[]>([]);
  const [isBusy, setIsBusy] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const queryClient = useQueryClient();

  const fetchJourneyCollaborators = useCallback(
    async (id: string) => {
      setIsLoading(true);
      const collaborators = await getJourneyCollaborators(
        id,
        location.pathname.includes('/template'),
      );
      if (collaborators && collaborators.length) {
        const filteredCollaborators = collaborators
          .map((collaborator) => ({
            id: collaborator.id,
            role: collaborator.assigneeRole,
            isLoading: false,
            updatedInChild: collaborator.updatedInChild ?? false,
            assignee: {
              id: collaborator.assignedUserID,
              firstName: collaborator.assignedUserName
                ? collaborator.assignedUserName.split(' ')[0]
                : '',
              lastName: collaborator.assignedUserName
                ? collaborator.assignedUserName.split(' ')[1]
                : '',
              email: collaborator.assignedUserEmail || null,
            },
          }))
          .filter((collaborator) => collaborator.role !== AssigneeRole.LEGAL);

        setJourneyCollaborators(
          filteredCollaborators.sort((a, b) => compareStrings(a.role, b.role)),
        );
        setIsLoading(false);
      }
    },
    [location.pathname],
  );

  const searchAssignee = async (query: string, index: number) => {
    journeyCollaborators[index].isLoading = true;
    setJourneyCollaborators([...journeyCollaborators]);
    const users = await searchUser(user.userOrganizationId, query);
    if (users) {
      setAssignees(
        users.filter(
          (user) =>
            !journeyCollaborators.some(
              (collaborator) => collaborator.assignee.email === user.email,
            ),
        ),
      );
    }

    journeyCollaborators[index].isLoading = false;
    setJourneyCollaborators([...journeyCollaborators]);
  };

  const handleSelectedAssignee = (users: any[], index: number) => {
    if (users && users.length > 0) {
      journeyCollaborators[index].assignee.id = users[0].id;
      journeyCollaborators[index].assignee.firstName = users[0].firstName;
      journeyCollaborators[index].assignee.lastName = users[0].lastName;
      journeyCollaborators[index].assignee.email = users[0].email;
      journeyCollaborators[index].updatedInChild = !isParentJourney;
      setJourneyCollaborators([...journeyCollaborators]);
    } else {
      journeyCollaborators[index].assignee.id = null;
      journeyCollaborators[index].assignee.firstName = '';
      journeyCollaborators[index].assignee.lastName = '';
      journeyCollaborators[index].assignee.email = null;
      journeyCollaborators[index].updatedInChild = !isParentJourney;

      setJourneyCollaborators([...journeyCollaborators]);
    }
  };

  const removeCollaborator = (index: number) => {
    journeyCollaborators[index].assignee.id = null;
    journeyCollaborators[index].assignee.firstName = '';
    journeyCollaborators[index].assignee.lastName = '';
    journeyCollaborators[index].assignee.email = null;
    journeyCollaborators[index].updatedInChild = !isParentJourney;

    setJourneyCollaborators([...journeyCollaborators]);
  };

  const handleSaveAssignee = async () => {
    setIsBusy(true);
    await Promise.all(
      journeyCollaborators.map((collaborator) =>
        updateJourneyCollaboratorAssignee(
          collaborator.id,
          collaborator.assignee.id || null,
          collaborator.updatedInChild,
        ),
      ),
    );

    // update collaborator's context
    setCollaborators([
      ...journeyCollaborators.map(
        (collaborator) =>
          ({
            assigneeRole: collaborator.role,
            assignedUserName: collaborator.assignee.firstName
              ? `${collaborator.assignee.firstName} ${collaborator.assignee.lastName}`
              : null,
            assignedUserEmail: collaborator.assignee.email,
          }) as CustomPlaybookCollaborator,
      ),
    ]);

    if (id) {
      //assign collaborators to actions
      await updateCollaboratorAssigneeInEvents(
        id,
        location.pathname.includes('/template'),
      );
      queryClient.invalidateQueries({
        queryKey: [JourneyBuilderKey.listJourneyActions],
      });
      queryClient.invalidateQueries({
        queryKey: [JourneyBuilderKey.listJourneyMeetings],
      });
      queryClient.invalidateQueries({ queryKey: Keys.getEvents(id) });
    }

    onHideModal();
  };

  const onHideModal = () => {
    setOpenMenu(false);
    setJourneyCollaborators([]);
    setAssignees([]);
    setIsBusy(false);
  };

  useEffect(() => {
    if (id && openMenu) {
      fetchJourneyCollaborators(id);
    }
  }, [fetchJourneyCollaborators, id, openMenu]);

  return {
    journeyCollaborators,
    assignees,
    isBusy,
    isLoading,
    openMenu,
    setOpenMenu,
    searchAssignee,
    handleSelectedAssignee,
    handleSaveAssignee,
    removeCollaborator,
    onHideModal,
  };
};
