import { type Journey } from '@API';
import './HomePlaybookCard.scss';
import { memo } from 'react';
import { Button } from '@Shared/DS';
import { format } from 'date-fns';
import { useHomePlaybookCard } from './useHomePlaybookCard';

interface HomePlaybookCardProps {
  playbook: Journey;
  isCollaboratorJourneyCard: boolean;
}

export const HomePlaybookCard = memo((props: HomePlaybookCardProps) => {
  const { playbook } = props;
  const { showStartJourneyButton, isAssigningJourney, startPlaybook } =
    useHomePlaybookCard(playbook.assignedUserID?.toString());

  return (
    <div className="home-card-wrapper mb-3 cursor-pointer">
      <div className="home-pc d-flex flex-column justify-content-between mb-2">
        <div className="home-pc__image cursor-pointer mb-2">
          {playbook.image ? (
            <img
              className="object-fit-contain"
              src={playbook.image}
              title={playbook.name}
              alt={playbook.name}
            />
          ) : (
            <img
              className="object-fit-contain"
              src="https://kin-assets.s3.eu-west-1.amazonaws.com/pbh-1.png"
              alt={playbook.name}
            />
          )}
        </div>
        <div className="home-pc__title">
          {playbook.name?.replaceAll('&amp;', '&')}
        </div>
        <div className="home-pc__support">
          {playbook.parentJourneyID ? (
            <div className="d-flex justify-content-between align-items-center">
              {playbook.userCompletedAt && (
                <span>
                  Completed {format(playbook.userCompletedAt, 'do MMM yyyy')}
                </span>
              )}

              {!playbook.userCompletedAt && playbook.userStartedAt && (
                <span>
                  Started {format(playbook.userStartedAt, 'do MMM yyyy')}
                </span>
              )}

              {!playbook.userCompletedAt && !playbook.userStartedAt && (
                <span>Not started</span>
              )}
            </div>
          ) : (
            <>
              {showStartJourneyButton === 'd-none' && !isAssigningJourney && (
                <div className="d-flex mt-1">
                  <span>Not started</span>
                </div>
              )}
              <div className="d-grid gap-2">
                <Button
                  additionalClasses={`mt-2 ${!isAssigningJourney ? showStartJourneyButton : ''}`}
                  text="Get started"
                  disabled={isAssigningJourney}
                  showLoading={isAssigningJourney}
                  onClick={() => startPlaybook(playbook.id)}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
});
