import { FcGoogle } from 'react-icons/fc';
import { BiLogoFacebookCircle } from 'react-icons/bi';

import './SocialProviderButton.scss';

interface ISocialProviderButton {
  text: string;
  provider: 'google' | 'facebook';
  className?: string;
  onClick: () => void;
}

export const SocialProviderButton: React.FC<ISocialProviderButton> = (
  props,
) => {
  const providersIcons = {
    facebook: <BiLogoFacebookCircle size="25" className="me-2" />,
    google: <FcGoogle size="33" className="me-2" />,
  };

  return (
    <button
      className={`btn social-provider-button ${props.className ?? ''}`}
      onClick={props.onClick}
    >
      {providersIcons[props.provider]}
      {props.text}
    </button>
  );
};
