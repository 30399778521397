import { Modal, Row, Col } from 'react-bootstrap';
import { TextField } from '@Shared/DS/Molecules';
import { memo } from 'react';
import { type CopyPlaybookModalProps } from '@base/models/journeyLibrary.model';
import { Button } from '@Shared/DS';
import { useCopyPlaybookModal } from './useCopyPlaybookModal';
import './CopyPlaybookModal.scss';

export const CopyPlaybookModal = memo((props: CopyPlaybookModalProps) => {
  const {
    journeyName,
    showLoader,
    isSubmitted,
    setJourneyName,
    handleHideModal,
    copyJourney,
  } = useCopyPlaybookModal(
    props.handleShowModal,
    props.journey,
    props.handleUpdateJourneys,
  );

  return (
    <Modal
      className="copy-journey-modal-wrapper"
      size="lg"
      fullscreen={'md-down'}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      show={props.showModal}
      onHide={handleHideModal}
    >
      <Modal.Header>
        <Modal.Title>
          <div className="fs-24-medium-bold-25272a">
            Copy runbook '{props.journey?.name}'
          </div>
          <div className="fs-15-25272A">
            Name your runbook to create a copy.
          </div>
        </Modal.Title>
        <button
          type="button"
          className="btn-close modal-close-btn"
          data-bs-dismiss="modal"
          onClick={handleHideModal}
          aria-label="Close"
        ></button>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <form id="copy-journey-from">
              <label
                className="fs-14-semi-bold col-12 text-start"
                htmlFor={'journeyName'}
              >
                Name<span className="required-sign">*</span>
              </label>
              <TextField
                id={'journeyName'}
                type="text"
                defaultValue={journeyName}
                setValue={setJourneyName}
                fieldName="Runbook name"
                width=""
                placeholder="e.g New Manager Training"
                additionalClasses={`
              ${!journeyName && journeyName?.length === 0 && isSubmitted ? 'is-invalid' : ''}`}
              />
            </form>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-end">
          <Button
            buttonStyle="outline"
            text="Cancel"
            onClick={() => props.handleShowModal(false)}
          />
          <Button
            text="Copy"
            additionalClasses="ms-2"
            disabled={showLoader}
            onClick={copyJourney}
            showLoading={showLoader}
            form="copy-journey-from"
            type="submit"
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
});
