import { Button, PlaybookHomeTabs } from '@Shared/DS';
import { BsChevronRight, BsQuestionCircle } from 'react-icons/bs';
import { Container } from 'react-bootstrap';
import Loader from '@Shared/Components/Loader/Loader';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { InfoModal } from '@Shared/Components/InfoModal/InfoModal';
import { EditAlertModal } from '@Shared/Components/EditAlertModal/EditAlertModal';
import { pasteAsPlainText } from '@Shared/utils/utils';
import { JourneyStatus } from '@base/API';
import { usePlaybookHome } from './usePlaybookHome';
import './PlaybookHome.scss';

export const PlaybookHome = () => {
  const {
    states,
    playbook,
    assignee,
    isLoading,
    isParentJourneyChanged,
    setStates,
    activatePlaybook,
    propagatePlaybooks,
    handleBack,
    navigateTo,
    updatePlaybookName,
    pausePlaybook,
  } = usePlaybookHome();

  return (
    <Container fluid className="playbook-home p-4">
      {isLoading || !playbook ? (
        <Loader active={isLoading || !playbook} />
      ) : (
        <>
          <div className="d-flex justify-content-between align-items-center playbook__breadcrumbs">
            <div className="text-start">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <button
                className="btn btn-link link-underline-light"
                onClick={() => handleBack(!isParentJourneyChanged)}
              >
                All runbooks
              </button>
              <BsChevronRight />
              {playbook.parentJourneyID && (
                <>
                  <button
                    className="btn btn-link link-underline-light cursor-pointer"
                    onClick={() =>
                      navigateTo(`/runbook/${playbook.parentJourneyID}`)
                    }
                  >
                    {playbook.name}
                  </button>
                  <BsChevronRight />
                  <button
                    className="btn btn-link link-underline-light cursor-pointer"
                    onClick={() => navigateTo(`/runbook/${playbook.id}`)}
                  >
                    {assignee?.firstName} {assignee?.lastName}
                  </button>
                </>
              )}
              {!playbook.parentJourneyID && (
                <>
                  <button
                    className="btn btn-link link-underline-light cursor-pointer"
                    onClick={() => navigateTo(`/runbook/${playbook.id}`)}
                  >
                    <input
                      value={states.playbookName}
                      onPaste={pasteAsPlainText}
                      title="Click to Edit name"
                      disabled={!!playbook.parentJourneyID}
                      onBlur={(e) =>
                        updatePlaybookName(playbook.id, e.target.value)
                      }
                      className="playbook__name"
                      onChange={(e) =>
                        setStates((states) => ({
                          ...states,
                          playbookName: e.target.value,
                        }))
                      }
                    />
                  </button>
                </>
              )}
            </div>
            <div>
              {isParentJourneyChanged && (
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id="tooltTipChangesDone">
                      You have made changes that are not yet visible for
                      assigned users, click push changes{' '}
                    </Tooltip>
                  }
                >
                  <span
                    className="badge--secondary me-3"
                    data-bs-toggle="tooltip"
                  >
                    <BsQuestionCircle />
                    Unpublished changes
                  </span>
                </OverlayTrigger>
              )}
              {!playbook.parentJourneyID && (
                <Button
                  buttonStyle="outline"
                  title="Update runbook for all assigned users"
                  text="Push changes"
                  icon="bi-arrow-repeat"
                  tooltipPlacement="bottom"
                  additionalClasses="me-2"
                  onClick={() =>
                    setStates((prevState) => ({
                      ...prevState,
                      showPlaybookChangesModal: true,
                    }))
                  }
                />
              )}
              <Button
                buttonStyle="outline"
                tooltipPlacement="bottom"
                title={
                  playbook.status === JourneyStatus.DRAFT
                    ? !playbook.parentJourneyID
                      ? 'Activate runbook for all assigned users'
                      : 'Activate'
                    : !playbook.parentJourneyID
                      ? 'Pause runbook for all assigned users'
                      : 'Pause'
                }
                text={
                  playbook.status === JourneyStatus.DRAFT
                    ? !playbook.parentJourneyID
                      ? 'Activate runbooks'
                      : 'Activate'
                    : !playbook.parentJourneyID
                      ? 'Pause runbooks'
                      : 'Pause'
                }
                icon={
                  playbook.status === JourneyStatus.DRAFT
                    ? 'bi-play-circle'
                    : 'bi-pause-circle'
                }
                onClick={() => {
                  if (playbook.status === JourneyStatus.DRAFT) {
                    setStates((prevState) => ({
                      ...prevState,
                      showActivateConfirmationModal: true,
                    }));
                  } else {
                    setStates((prevState) => ({
                      ...prevState,
                      showPauseConfirmationModal: true,
                    }));
                  }
                }}
              />
            </div>
          </div>

          <PlaybookHomeTabs playbook={playbook} assignee={assignee} />

          <InfoModal
            showModal={states.showActivateConfirmationModal}
            handleShowModal={(showActivateConfirmationModal: boolean) =>
              setStates((prevState) => ({
                ...prevState,
                showActivateConfirmationModal,
              }))
            }
            onClickConfirmButton={activatePlaybook}
            title={
              !playbook.parentJourneyID
                ? 'Activate runbooks'
                : 'Activate runbook'
            }
            description={
              !playbook.parentJourneyID
                ? 'This will activate all workflows in all runbooks that have not yet been started. Do you want to continue?'
                : `This will activate the workflows in ${assignee?.firstName}'s runbook. Do you want to continue?`
            }
          />

          <InfoModal
            showModal={states.showPauseConfirmationModal}
            handleShowModal={(showPauseConfirmationModal: boolean) =>
              setStates((prevState) => ({
                ...prevState,
                showPauseConfirmationModal,
              }))
            }
            onClickConfirmButton={pausePlaybook}
            title={
              !playbook.parentJourneyID ? 'Pause runbooks' : 'Pause runbook'
            }
            description={
              !playbook.parentJourneyID
                ? "By pausing the runbook, all scheduled messages and action reminders will be paused. Assignees will not have access to the playbook until it's activated again. Do you want to continue?"
                : `This will pause the workflows in ${assignee?.firstName}'s runbook. Do you want to continue?`
            }
          />

          <EditAlertModal
            title="Update runbook"
            description={
              states.isGoingBackWithChanges
                ? "Looks like you've made updates that are not reflected for employees using this runbook. Would you like to give them the latest version?"
                : 'This will update the runbook for all employees who are in progress or yet to start.'
            }
            showModal={states.showPlaybookChangesModal}
            handleShowModal={propagatePlaybooks}
            handleProceed={() => propagatePlaybooks(true)}
            showLoader={false}
            primaryButtonText="Yes, update"
            secondaryButtonText="No, don't update"
          />
        </>
      )}
    </Container>
  );
};
