import EyeIcon from '@Assets/Images/icon-eye.svg';
import { type TemplateHeaderProps } from '@models/templateLibrary.model';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { UserType } from '@base/API';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BsArrowLeftShort } from 'react-icons/bs';

export const TemplateHeader = (props: TemplateHeaderProps) => {
  const {
    templateName,
    showLoader,
    showSaved,
    onBack,
    isEditable,
    onViewTemplate,
    exitPreview,
  } = props;

  const { user } = useUserContext();

  return (
    <div className="builder-header-wrapper sticky-top p-2">
      <div className="col-12 d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <div className="home-button d-flex">
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="header-backhome-btn" style={{ position: 'fixed' }}>
                  Back to the calendar
                </Tooltip>
              }
            >
              <button title="Back home" onClick={() => onBack()}>
                <BsArrowLeftShort />
                <span>Back</span>
              </button>
            </OverlayTrigger>
          </div>
          <div className="name d-flex align-items-center ms-1">
            {templateName ?? ''}
          </div>
        </div>

        <div className="d-flex justify-content-end">
          {showLoader && (
            <div className="spinner-border mx-2" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          )}

          <div className="save-loader">
            {showSaved && (
              <div className="d-flex align-items-center">
                <svg
                  width="12"
                  height="11"
                  viewBox="0 0 12 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.914 1.91954L5.22712 10.6212C5.16083 10.7226 5.07331 10.8084 4.97062 10.8726C4.86793 10.9369 4.75251 10.978 4.63234 10.9933C4.51217 11.0086 4.39012 10.9976 4.27462 10.9611C4.15912 10.9246 4.05292 10.8634 3.96337 10.7818L0.273877 7.43638C0.109667 7.28709 0.0114904 7.07867 0.00094595 6.85699C-0.00959847 6.6353 0.0683526 6.41851 0.217651 6.2543C0.366949 6.09009 0.575364 5.99191 0.797048 5.98137C1.01873 5.97082 1.23553 6.04878 1.39974 6.19807L4.36097 8.88487L9.511 1.00386C9.63243 0.817816 9.82279 0.687627 10.0402 0.641935C10.2576 0.596242 10.4843 0.638789 10.6703 0.760216C10.8564 0.881643 10.9866 1.072 11.0323 1.28942C11.078 1.50683 11.0354 1.7335 10.914 1.91954Z"
                    fill="#2EB67D"
                  />
                </svg>
                <span className="ms-1 fs-14-2EB67D">Saved!</span>
              </div>
            )}
          </div>

          {user.type === UserType.SUPER_ADMIN && (
            <>
              {isEditable ? (
                <button
                  title="Preview"
                  className="btn me-2 px-3 text-nowrap"
                  onClick={() => onViewTemplate()}
                >
                  <img src={EyeIcon} alt="view" />
                </button>
              ) : (
                <button
                  className="btn me-2 px-3 text-nowrap"
                  onClick={() => exitPreview()}
                >
                  Exit preview
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
