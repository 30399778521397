import { listOrgForms } from '@api/apis';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { Keys } from '@base/keys/queryKeys';
import { type Form } from '@base/models/form.model';
import { FormActionDropdown } from '@Shared/DS';
import { useQuery } from '@tanstack/react-query';
import {
  type SortingState,
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useState } from 'react';

const formNameColId = 'form-name';

export const useForms = () => {
  const [sorting, setSorting] = useState<SortingState>([
    { id: formNameColId, desc: false },
  ]);
  const { user } = useUserContext();

  const { data, isFetching } = useQuery({
    queryKey: Keys.getOrgForms(user.userOrganizationId),
    queryFn: () => listOrgForms(user.userOrganizationId),
    enabled: !!user.userOrganizationId,
  });

  const columnHelper = createColumnHelper<Form>();

  const columns = [
    columnHelper.accessor((row) => row.name, {
      id: formNameColId,
      cell: (info) => <h6 className="text-nowrap">{info.getValue()}</h6>,
      header: () => <span className="text-nowrap">Form name</span>,
      size: 35,
      minSize: 0,
      sortingFn: 'text',
    }),
    columnHelper.accessor((row) => row, {
      id: 'access-form',
      cell: (info) => (
        <FormActionDropdown
          className="actions-icon text-end"
          form={info.getValue()}
        />
      ),
      header: () => <span></span>,
      size: 0,
      minSize: 0,
      enableSorting: false,
    }),
  ];

  const table = useReactTable({
    data: data ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    autoResetPageIndex: true,
    autoResetAll: true,
  });

  return {
    data,
    table,
    columns,
    isFetching,
  };
};
