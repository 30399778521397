import { InfoModal } from '@Shared/Components/InfoModal/InfoModal';
import { BuilderType, type Journey, JourneyStatus } from '@base/API';
import { CustomDropdown, DateModal, PlaybookActivationModal } from '@Shared/DS';
import { EditAlertModal } from '@Shared/Components/EditAlertModal/EditAlertModal';
import { useMemo } from 'react';
import { sureString } from '@Shared/utils/sure';
import { usePlaybookActionDropdown } from './usePlaybookActionDropdown';

interface IPlaybookActionDropdown {
  playbook: Journey;
  assigneeName: string;
  className?: string;
}

export const PlaybookActionDropdown = ({
  playbook,
  className,
  assigneeName,
}: IPlaybookActionDropdown) => {
  const {
    states,
    setStates,
    viewPlaybook,
    archivePlaybook,
    pausePlaybook,
    changePlaybookAnchorDate,
  } = usePlaybookActionDropdown(playbook);

  const items = useMemo(
    () =>
      [
        { title: 'Edit', onClick: viewPlaybook },
        {
          title: 'Set anchor date',
          onClick: () =>
            setStates((prevState) => ({
              ...prevState,
              showAnchorDateModal: true,
            })),
        },
        {
          title: playbook.status === JourneyStatus.DRAFT ? 'Activate' : 'Pause',
          onClick: () =>
            setStates((prevState) => ({
              ...prevState,
              showPlaybookActivationModal:
                playbook.status === JourneyStatus.DRAFT,
              showPlaybookPauseModal: playbook.status !== JourneyStatus.DRAFT,
            })),
        },
        {
          title: 'Archive',
          onClick: () =>
            setStates((prevState) => ({
              ...prevState,
              showConfirmationModal: true,
            })),
        },
      ].filter(
        (item) =>
          playbook.type === BuilderType.INITIATIVE ||
          item.title !== 'Set anchor date',
      ),
    [playbook.status, playbook.type, setStates, viewPlaybook],
  );

  return (
    <div className={className} onClick={(event) => event.stopPropagation()}>
      <CustomDropdown items={items} />

      <InfoModal
        title={`Archive "${playbook.name}"`}
        description="Are you sure you want to archive this runbook?"
        size="md"
        showModal={states.showConfirmationModal}
        onClickConfirmButton={archivePlaybook}
        handleShowModal={(value: boolean) =>
          setStates((prevState) => ({
            ...prevState,
            showConfirmationModal: value,
          }))
        }
      ></InfoModal>

      <PlaybookActivationModal
        showModal={states.showPlaybookActivationModal}
        handleShowModal={(value: boolean) =>
          setStates((prevState) => ({
            ...prevState,
            showPlaybookActivationModal: value,
          }))
        }
        playbook={playbook}
      />

      <EditAlertModal
        title="Pause workflow"
        description="By pausing the runbook, all scheduled messages and action reminders will be paused. Assignees will not have access to the playbook until it's activated again."
        showModal={states.showPlaybookPauseModal}
        handleShowModal={(value: boolean) =>
          setStates((prevState) => ({
            ...prevState,
            showPlaybookPauseModal: value,
          }))
        }
        handleProceed={pausePlaybook}
        showLoader={false}
      />

      <DateModal
        title={`${playbook.name} - ${assigneeName}`}
        currentDate={sureString(playbook.startDate)}
        saveDate={changePlaybookAnchorDate}
        showModal={states.showAnchorDateModal}
        handleShowModal={(value: boolean) =>
          setStates((prevState) => ({
            ...prevState,
            showAnchorDateModal: value,
          }))
        }
      />
    </div>
  );
};
