import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AssigneeRole,
  BuilderType,
  type JourneyCollaborator,
  PlaybookMode,
} from '@API';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { createJourneyCollaborator, createNewJourney } from '@api/apis';
import { Analytics } from '@base/analytics/analytics';
import { getRandomPlaybookImage } from '@Shared/utils/utils';

export const useCreatePlaybookModal = (handleShowModal: Function) => {
  const [playbookName, setPlaybookName] = useState('');
  const navigate = useNavigate();
  const { user } = useUserContext();
  const [showLoader, setShowLoader] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [playbookMode, setPlaybookMode] = useState<PlaybookMode | null>(null);
  const [playbookStartDate, setPlaybookStartDate] = useState<string | null>(
    null,
  );
  const [stepNumber, setStepNumber] = useState(1);
  const [startDateOption, setStartDateOption] = useState<string>('Select');
  const playbookStartDateOptions = [
    'When assignee first views microsite',
    'On new hire start date',
    'Custom date (can be changed later)',
  ];

  const handleCreatePlaybook = async () => {
    setIsSubmitted(true);
    setShowLoader(true);
    if (!playbookName.length || startDateOption === 'Select') {
      setShowLoader(false);
      return;
    }

    const id = await createNewJourney(
      playbookName,
      user.id,
      user.userOrganizationId,
      playbookStartDate,
      getPlaybookType(startDateOption),
      PlaybookMode.IMMERSIVE,
      getRandomPlaybookImage(),
    );

    if (id) {
      Promise.all(
        Object.keys(AssigneeRole).map((role) =>
          createJourneyCollaborator({
            assigneeRole: role,
            journeyID: id,
          } as JourneyCollaborator),
        ),
      );
      navigate(`/runbook/${id}`);
    }
    setShowLoader(false);
    Analytics.playbookCreated(getPlaybookType(startDateOption), false);
  };

  const getPlaybookType = (selectedStartDateOption: string) => {
    if (selectedStartDateOption === playbookStartDateOptions[0]) {
      return BuilderType.SELFSERVE;
    } else if (selectedStartDateOption === playbookStartDateOptions[1]) {
      return BuilderType.ONBOARDING;
    }

    return BuilderType.INITIATIVE;
  };

  const handleHideModal = () => {
    setIsSubmitted(false);
    handleShowModal(false);
    setPlaybookName('');
    setStepNumber(1);
    setPlaybookMode(null);
    setPlaybookStartDate(null);
    setStartDateOption('Select');
  };

  const currentStep = useMemo(() => {
    if (stepNumber === 1) {
      return {
        title: 'Name your runbook',
        description:
          'Visible to admins and assignees on their homepage. The name can be changed later',
      };
    } else if (stepNumber === 2) {
      return {
        title: 'Start date',
        description:
          'Scheduled messages, actions and meetings will anchor to this date.',
      };
    }
  }, [stepNumber]);

  return {
    playbookName,
    isSubmitted,
    showLoader,
    startDateOption,
    user,
    playbookMode,
    stepNumber,
    currentStep,
    playbookStartDateOptions,
    playbookStartDate,
    setPlaybookMode,
    setStepNumber,
    setStartDateOption,
    setPlaybookName,
    handleHideModal,
    handleCreatePlaybook,
    setPlaybookStartDate,
  };
};
