import { useEffect, useState } from 'react';
import { BuilderType, type Journey, type User } from '@API';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { assignJourney, createActionCollaborator, searchUser } from '@api/apis';
import { useNavigate } from 'react-router-dom';
import {
  type AssignJourneyModalProps,
  addNotificationToApp,
} from '@Shared/utils/utils';
import { useQueryClient } from '@tanstack/react-query';
import { Keys } from '@base/keys/queryKeys';
import { Analytics } from '@base/analytics/analytics';

export const useAssignJourneyModal = ({
  handleShowModal,
  playbook,
}: AssignJourneyModalProps) => {
  const [assignees, setAssignees] = useState<
    (Partial<User> & { disabled?: boolean })[]
  >([]);
  const [selectedAssignees, setSelectedAssignees] = useState<User[]>([]);
  const [startDate, setStartDate] = useState<string | undefined>();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoadingAssignee, setIsLoadingAssignee] = useState(false);
  const [isAssigning, setIsAssigning] = useState(false);
  const { user } = useUserContext();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleHideModal = () => {
    handleShowModal(false);
    setIsSubmitted(false);
    setAssignees([]);
    setSelectedAssignees([]);
  };

  const searchAssignee = async (query: string) => {
    setIsLoadingAssignee(true);
    const users = await searchUser(user.userOrganizationId, query);
    if (users) {
      setAssignees(disableAlreadyAssignedAssignee(users));
    }
    setIsLoadingAssignee(false);
  };

  const handleSelectedAssignee = (users: any[]) => {
    setSelectedAssignees(users);
  };

  const handleAssignJourney = async (e: any) => {
    e.preventDefault();
    setIsSubmitted(true);
    if (!selectedAssignees.length) {
      return;
    }

    if (playbook.type === BuilderType.INITIATIVE && !startDate) {
      return;
    }

    if (
      playbook.type !== BuilderType.INITIATIVE &&
      playbook.type !== BuilderType.SELFSERVE &&
      !startDate &&
      !selectedAssignees.some((assignee) => assignee.startDate)
    ) {
      addNotificationToApp(
        'Please make sure all of your assignees have a start date',
        'error',
      );
      return;
    }

    setIsAssigning(true);

    const ids = await assignJourney(
      playbook.id,
      selectedAssignees,
      user.id,
      startDate,
    );
    if (selectedAssignees.length > 1) {
      addNotificationToApp(
        `${selectedAssignees.length} users assigned successfully`,
        'success',
      );
    }

    setIsAssigning(false);
    handleHideModal();
    if (ids) {
      //assign collaborators to the journey's action if they have any
      createActionCollaborator(ids);
      navigate(`/runbook/${playbook.id}/assignees`);

      // invalidate child journeys list so that it can fetch fresh data
      queryClient.invalidateQueries({
        queryKey: Keys.getChildPlaybooks(playbook.id),
      });
      Analytics.playbookUserAssigned(playbook.id);
    }
  };

  const disableAlreadyAssignedAssignee = (
    users: (Partial<User> & { disabled?: boolean })[],
  ) => {
    const playbooks = queryClient.getQueryData(
      Keys.getChildPlaybooks(playbook.id),
    ) as Journey[];
    if (playbooks) {
      return users.map((user) => ({
        ...user,
        disabled: playbooks.some(
          (playbook) => playbook.assignedUserID === user.id,
        ),
      }));
    }

    return users;
  };

  useEffect(() => {
    setStartDate(playbook.startDate?.toString());
  }, [playbook.startDate]);

  return {
    assignees,
    isLoadingAssignee,
    selectedAssignees,
    isSubmitted,
    isAssigning,
    startDate,
    playbook,
    setStartDate,
    searchAssignee,
    handleHideModal,
    handleSelectedAssignee,
    handleAssignJourney,
  };
};
