import { useQueryClient } from '@tanstack/react-query';
import { type FormEvent, useState } from 'react';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { type User, UserType } from '@base/API';
import { Analytics } from '@base/analytics/analytics';
import { inviteExistingEmployee } from '@api/apis';
import { SettingsPageQueryKey } from '../../../queries';

interface State {
  email: string;
  role: UserType;
  sendWelcomeEmail: boolean;
}

export const useExistingEmployee = (handleShowModal: Function) => {
  const [state, setState] = useState<State>({
    email: '',
    role: UserType.COMPANY_MEMBER,
    sendWelcomeEmail: false,
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useUserContext();
  const queryClient = useQueryClient();

  const handleChange = (value: State[keyof State], fieldName: keyof State) => {
    const processedValue =
      fieldName === 'email' && typeof value === 'string'
        ? value.toLowerCase()
        : value;
    setState({ ...state, [fieldName]: processedValue });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitted(true);
    setIsLoading(true);

    if (state.email !== '' && !checkEmailAlreadyExist()) {
      await inviteExistingEmployee(user.userOrganizationId, {
        inviteParams: {
          email: state.email,
          type: state.role,
          sendWelcomeEmail: state.sendWelcomeEmail,
        },
        customFields: {},
      });
      queryClient.invalidateQueries({
        queryKey: [SettingsPageQueryKey.listOrganizationUsers],
      });
      setIsSubmitted(false);
      setState({
        email: '',
        role: UserType.COMPANY_MEMBER,
        sendWelcomeEmail: false,
      });
      setIsLoading(false);
      handleShowModal(false);

      Analytics.userInvited(user.userOrganizationId, true);
    }

    setIsLoading(false);
  };

  const checkEmailAlreadyExist = () => {
    const users = queryClient.getQueryData([
      SettingsPageQueryKey.listOrganizationUsers,
    ]) as User[];
    const existingEmails = users.some((user) => user.email === state.email);
    return existingEmails;
  };

  return {
    state,
    isSubmitted,
    isLoading,
    handleChange,
    handleSubmit,
    checkEmailAlreadyExist,
  };
};
