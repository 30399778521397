import { type TextFieldProps } from '@Shared/utils/utils';
import { useEffect, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import './TextArea.scss';

const TextArea = (props: TextFieldProps) => {
  const [areaHeight, setAreaHeight] = useState<number | undefined>(200);

  useEffect(() => {
    if (props.height) {
      setAreaHeight(props.height);
    }
  }, [props.height]);

  return (
    <>
      <TextareaAutosize
        id={props.id}
        onChange={(e) =>
          props.setValue ? props.setValue(e.target.value) : null
        }
        className={`text-area-wrapper form-control ${props.additionalClasses}`}
        style={{
          width: `${props.width}`,
          height: areaHeight,
          borderRadius: props.borderRadius ?? 8,
        }}
        placeholder={props.placeholder}
        value={props.defaultValue}
        disabled={props.disabled}
        onBlur={props.onBlur}
        maxLength={props.maxLength}
        minRows={2}
      />

      {props.additionalClasses?.includes('is-invalid') && (
        <div className="invalid-feedback text-start">
          {`${props.fieldName || ''} ${props.errorMessage || "shouldn't be empty"}`}{' '}
        </div>
      )}
    </>
  );
};

export default TextArea;
