import { useEffect, useState } from 'react';

export const useTabs = (selectedKey: string) => {
  const [key, setKey] = useState(selectedKey);

  useEffect(() => {
    setKey(selectedKey);
  }, [selectedKey]);

  return {
    key,
    setKey,
  };
};
