import { Amplify } from 'aws-amplify';
import { generateClient } from 'aws-amplify/api';
import amplifyconfig from '../amplifyconfiguration.json';

// configure amplify
Amplify.configure(amplifyconfig);

// generate api client
const ApiClient = generateClient();

export { ApiClient };
