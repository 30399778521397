import {
  ActionDueDate,
  AddRolesList,
  NotificationChannel,
  TextField,
} from '@Shared/DS/Molecules';
import { Form, Offcanvas } from 'react-bootstrap';
import './CreateUpdateAction.scss';
import { type ICreateUpdateAction } from '@base/models/journeyLibrary.model';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { Button } from '@Shared/DS/Atoms';
import { useSearch } from '@base/Hooks/useSearch';
import { Channel } from '@base/API';
import { MessageTextEditor } from '@Shared/Components/TextEditor/MessageTextEditor/MessageTextEditor';
import { useCreateUpdateAction } from './useCreateUpdateAction';

export const CreateUpdateAction = (props: ICreateUpdateAction) => {
  const { actionEvent, showModal } = props;
  const {
    title,
    description,
    assignees,
    isSubmitted,
    isLoading,
    reminders,
    showSlackError,
    showLoader,
    selectedAssignee,
    roles,
    showCCField,
    cc,
    channel,
    daysFromOptions,
    selectedDaysFrom,
    isEditable,
    handleChannelType,
    handleSubmit,
    setCc,
    setShowCCField,
    onSelectRole,
    setTitle,
    setDescription,
    searchAssignee,
    handleSelectedAssignee,
    onHideModal,
    setReminders,
    handleReminder,
    removeReminder,
    setSelectedDaysFrom,
  } = useCreateUpdateAction(props);
  const { users, isSearching, findUser } = useSearch();

  return (
    <Offcanvas
      className="create-update-action-wrapper"
      backdrop="static"
      placement="end"
      show={showModal}
      onHide={() => onHideModal()}
    >
      <Offcanvas.Header closeButton className="px-3">
        <Offcanvas.Title>
          {actionEvent.id ? 'Update Action' : 'Create Action'}
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="px-3">
        <form onSubmit={(e) => handleSubmit(e)}>
          <Form.Group className="mb-4">
            <NotificationChannel
              selected={channel}
              showSlackError={showSlackError}
              onSelect={handleChannelType}
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <label className="text-start form-label" htmlFor="title">
              Title<span className="required-field-sign">*</span>
            </label>
            <TextField
              id="title"
              type="text"
              defaultValue={title}
              setValue={setTitle}
              disabled={!isEditable}
              fieldName="Title"
              placeholder="What needs to be done"
              additionalClasses={`${title.length === 0 && isSubmitted ? 'is-invalid' : ''}`}
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <label className="text-start form-label" htmlFor="description">
              Description
            </label>
            <MessageTextEditor
              value={description}
              setValue={setDescription}
              theme="snow"
              isEditable={isEditable}
              placeholder="Add a description"
              extraVerticalSpacing={channel !== Channel.EMAIL}
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <div className="search-user-input">
              <label className="text-start form-label" htmlFor="assignee">
                Assignee<span className="required-field-sign">*</span>
              </label>
              <AsyncTypeahead
                id="assignee"
                allowNew
                className={
                  !selectedAssignee && isSubmitted ? 'invalid-field' : ''
                }
                isLoading={isLoading}
                selected={selectedAssignee ? [{ ...selectedAssignee }] : []}
                onSearch={searchAssignee}
                disabled={!isEditable}
                minLength={3}
                newSelectionPrefix="Add email: "
                labelKey={(option: any) =>
                  option.name ??
                  (option.firstName
                    ? `${option.firstName} ${option.lastName}${option.role ? ` (${option.role})` : ''}`
                    : (option.role ?? option.email ?? option.label ?? ''))
                }
                onChange={handleSelectedAssignee}
                options={assignees}
                placeholder="Start typing a name or email"
                inputProps={{
                  style: {
                    borderRadius: '10px',
                    padding: '8px 20px',
                  },
                }}
                renderMenuItemChildren={(option: any) => (
                  <>
                    <span>
                      {option.firstName && option.firstName !== ''
                        ? `${option.firstName}  ${option.lastName}<${option.email}>`
                        : option.email
                          ? ` ${option.email}`
                          : `${option.role}`}
                    </span>
                  </>
                )}
              />

              {channel === Channel.EMAIL && (
                <span
                  className="cc-option"
                  onClick={() => setShowCCField(true)}
                >
                  Cc
                </span>
              )}
            </div>
            <AddRolesList
              className="d-flex flex-wrap gap-2 mb-2"
              roles={roles}
              onSelectRole={onSelectRole}
            />
            {!selectedAssignee && isSubmitted && (
              <div className="error-text text-start">Assignee is required</div>
            )}

            {showCCField && channel === Channel.EMAIL && (
              <Form.Group className="mb-2">
                <label htmlFor="cc" className="form-label">
                  Cc
                </label>
                <AsyncTypeahead
                  multiple={true}
                  id="cc-recipient"
                  isLoading={isSearching}
                  selected={cc}
                  allowNew
                  onSearch={findUser}
                  minLength={2}
                  disabled={!isEditable}
                  placeholder="Start typing a name or email"
                  newSelectionPrefix="Add email: "
                  labelKey={(option: any) =>
                    option.firstName
                      ? `${option.firstName} ${option.lastName}`
                      : option.email
                        ? `${option.email}`
                        : (option.role ?? option.label ?? '')
                  }
                  onChange={(users: any[]) => setCc(users)}
                  options={users}
                  renderMenuItemChildren={(option: any) => (
                    <>
                      <span>
                        {option.firstName && option.firstName !== ''
                          ? `${option.firstName}  ${option.lastName}<${option.email}>`
                          : option.email
                            ? ` ${option.email}`
                            : `${option.role}`}
                      </span>
                    </>
                  )}
                />
              </Form.Group>
            )}
          </Form.Group>

          <Form.Group className="mb-2">
            <ActionDueDate
              daysFromOptions={daysFromOptions}
              selectedDaysFrom={selectedDaysFrom}
              onSelectDay={setSelectedDaysFrom}
              reminders={reminders}
              setReminders={setReminders}
              onSelectReminderDay={handleReminder}
              onRemoveReminder={removeReminder}
            />
          </Form.Group>
          <Form.Text>
            Reminders are automatically sent on the due date
          </Form.Text>

          <div className="footer">
            <div className="d-flex gap-2 justify-content-end">
              <Button
                buttonStyle="outline"
                text="Cancel"
                onClick={onHideModal}
              />
              <Button
                showLoading={showLoader}
                text="Submit"
                disabled={showLoader || !isEditable}
                type="submit"
                onClick={() => {}}
              />
            </div>
          </div>
        </form>
      </Offcanvas.Body>
    </Offcanvas>
  );
};
