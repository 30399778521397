import './GeneralTab.scss';
import useBreakpoint from 'use-breakpoint';
import { Row, Col } from 'react-bootstrap';
import { memo } from 'react';
import { BREAKPOINTS_DESKTOP_1280 } from '@Shared/utils/utils';
import { Button } from '@Shared/DS';
import { OrganizationInfo } from '../OrganizationInfo/OrganizationInfo';
import { useGeneralTab } from './useGeneralTab';

export const GeneralTab = memo(() => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS_DESKTOP_1280, 'tablet');
  const {
    adminWithOrganization,
    addOrganization,
    downloadUsageReport,
    downloadFormsReport,
    isFormReportDownloading,
    isDownloading,
  } = useGeneralTab();

  const OrganizationDetails = memo(() => (
    <div className="ps-lg-4 px-md-2">
      <div className="row g-0 align-items-center">
        <Row className="my-3">
          <Col className="text-center fs-16-medium-bold">
            <span
              className="add-organisation-pill rounded-pill p-2"
              onClick={addOrganization}
            >
              <i className="bi bi-plus-circle"></i>
              &emsp;Add new organization
            </span>
          </Col>
        </Row>
        {adminWithOrganization.map((organization: any, i: any) => (
          <OrganizationInfo
            key={i}
            adminEmail={organization.email}
            orgName={organization.name}
            alreadyPresent={organization.name !== '' ? true : false}
          />
        ))}
      </div>
    </div>
  ));

  return (
    <div className="members-tab-wrapper">
      <div className="row settings-details-styling">
        <div className="col-lg-4 col-md-12 col-sm-12 text-start mb-lg-0 mb-5 settings-list-styling">
          <div className="fs-20-semi-bold">Organizations</div>
          <p className="fs-16 py-2">Download product usage report.</p>

          <div className="fs-20-semi-bold mt-4">Forms</div>
          <p className="fs-16 py-2">Download forms report.</p>

          <p className="fs-16 pt-4">
            Create a new organisation and the admin user.
          </p>
        </div>
        <div
          className={
            breakpoint === 'mobile'
              ? 'col-lg-8 col-md-12 col-sm-12'
              : 'col-lg-8 col-md-12 col-sm-12 ps-5'
          }
        >
          <Button
            text={'Download'}
            icon="bi bi-file-earmark-arrow-down-fill"
            showLoading={isDownloading}
            additionalClasses="my-4 d-block"
            buttonStyle="outline"
            onClick={downloadUsageReport}
          />
          <Button
            text={'Download'}
            icon="bi bi-file-earmark-arrow-down-fill"
            showLoading={isFormReportDownloading}
            additionalClasses="my-4 d-block"
            buttonStyle="outline"
            onClick={downloadFormsReport}
          />
          <div className="settings-list-container">
            <OrganizationDetails />
          </div>
        </div>
      </div>
    </div>
  );
});
