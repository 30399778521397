import './Signup.scss';
import { TextField } from '@Shared/DS/Molecules';
import { Button, SocialProviderButton } from '@Shared/DS';
import { useSignup } from './useSignup';

export const Signup = () => {
  const {
    userEmail,
    password,
    isSubmitted,
    isLoading,
    setUserEmail,
    setPassword,
    handleSignUpWithEmailAndPassword,
    handleLogin,
    handleGoogleSignUp,
  } = useSignup();

  return (
    <div className="create-account-wrapper">
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col-md-auto">
            <div className="text-center">
              <img
                className="logo"
                src={require('../../Assets/Images/kinfolk_logo.png')}
                alt="Kinfolk logo"
              />
              <div className="heading">Sign up 🚀</div>
              <p className="fs-16-semi-bold mt-1">
                For speedy access, login with Google.
              </p>
            </div>
            <div className="login-form">
              <SocialProviderButton
                className="mt-2 w-100"
                text="Sign up with Google"
                provider="google"
                onClick={handleGoogleSignUp}
              />
              {/* <SocialProviderButton className="mt-2 w-100" text="Login with Facebook" provider="facebook" onClick={handleFacebookLogin} /> */}

              <div className="hr-text my-4">
                <span className="fs-10-25272a">Or</span>
              </div>

              <form onSubmit={(e) => handleSignUpWithEmailAndPassword(e)}>
                <label
                  className="fs-14-semi-bold col-12 text-start mt-2"
                  htmlFor="email"
                >
                  Email address
                </label>
                <TextField
                  id={'email'}
                  type="email"
                  defaultValue={userEmail}
                  setValue={setUserEmail}
                  fieldName="Email"
                  placeholder="Enter your work email address"
                  additionalClasses={`
                ${!userEmail && userEmail.length === 0 && isSubmitted ? 'is-invalid' : ''}`}
                />

                <label
                  className="fs-14-semi-bold col-12 text-start mt-4"
                  htmlFor="password"
                >
                  Password
                </label>
                <TextField
                  id={'password'}
                  type="password"
                  defaultValue={password}
                  setValue={setPassword}
                  fieldName="Password"
                  placeholder="At least 8 characters"
                  additionalClasses={`
                    ${(password === '' || password.length < 8) && isSubmitted ? 'is-invalid' : ''}`}
                />

                <Button
                  text="Signup"
                  showLoading={isLoading}
                  type="submit"
                  additionalClasses="mt-3 w-100"
                  disabled={isLoading}
                />
              </form>
              <div className="mt-3 fs-12">
                By creating an account you accept our{' '}
                <a
                  className="login-text-color"
                  href="https://www.kinfolkhq.com/terms"
                  rel="noreferrer"
                  target="_blank"
                >
                  Terms & Conditions
                </a>{' '}
                and acknowledge you have read our{' '}
                <a
                  className="login-text-color"
                  href="https://www.kinfolkhq.com/privacy"
                  rel="noreferrer"
                  target="_blank"
                >
                  Privacy Policy
                </a>
              </div>
              <p className="fs-14 mt-4">
                Already have an account?{' '}
                <span
                  className="login-text-color fw-bold cursor-pointer"
                  onClick={handleLogin}
                >
                  Login
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
