import { Buffer } from 'buffer';
import ReactQuill, { Quill } from 'react-quill';
import BlotFormatter from 'quill-blot-formatter';
import { useEffect, useMemo, useRef, useState } from 'react';
import { type EditorProps, IMAGE_LOADER_BASE64 } from '@Shared/utils/utils';
import EditorToolbar from '@Shared/Components/TextEditor/QuillToolbar';
import clsx from 'clsx';
import { useDynamicTagsContext } from '@base/Context/DynamicTagsContext';
import './TextEditor.scss';

Quill.register('modules/blotFormatter', BlotFormatter);

/* allow image alignment styles */
const ImageFormatAttributesList = ['alt', 'height', 'width', 'style'];

const BaseImageFormat = Quill.import('formats/image');
class ImageFormat extends BaseImageFormat {
  static formats(domNode: any) {
    return ImageFormatAttributesList.reduce(function (formats: any, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }
  format(name: any, value: any) {
    if (ImageFormatAttributesList.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}

Quill.register(ImageFormat, true);

export const TextEditor = (props: EditorProps) => {
  const [description, setDescription] = useState(props.value);
  const [isImageLoading, setImageLoading] = useState(false);
  const { substituteValues } = useDynamicTagsContext();
  const quillRef = useRef<any>(null);
  const id = useMemo(
    () => `toolbar-${Math.random().toString(36).substring(2, 9)}`,
    [],
  );
  const value = props.isEditable
    ? description
    : (substituteValues(props.id) ?? description);

  const updateDescription = (value: string) => {
    if (!isImageLoading) {
      if (!value.toString().includes(IMAGE_LOADER_BASE64)) {
        /* If we remove all the text from the rich text editor,
           then it returns the following html by default  */
        props.setValue(value === '<p><br></p>' ? '' : value);
      }
    }
    const parser = new DOMParser();
    const doc = parser.parseFromString(value, 'text/html');
    const imgElements = doc.querySelectorAll('img');

    imgElements.forEach((img) => {
      if (!img.getAttribute('src')!.startsWith('https')) {
        const src = img!.getAttribute('src');
        if (src && !src.includes('https')) {
          let base64FileContent = src!
            .toString()
            .replace(/^data:image\/\w+;base64,/, '');
          let buffer = Buffer.from(base64FileContent, 'base64');
          let fileSizeInByte = buffer.length;
          if (fileSizeInByte <= 4000000) {
            img.setAttribute(
              'src',
              require('../../../Assets/Animations/image_loader.gif'),
            );
          } else {
            img.setAttribute('src', '');
          }
          setImageLoading(true);
        }
      }
    });
    setDescription(doc.body.innerHTML);
  };

  const replaceDescriptionImages = (sourceHtml: string, targetHtml: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(targetHtml, 'text/html');
    const imgElements = doc.querySelectorAll('img');

    imgElements.forEach((img) => {
      if (!img.getAttribute('src')!.startsWith('https')) {
        const src = img!.getAttribute('src');
        if (src === '') {
          img.remove();
        }
      }
    });
    targetHtml = doc.body.innerHTML;
    const sourceDoc = new DOMParser().parseFromString(sourceHtml, 'text/html');
    const targetDoc = new DOMParser().parseFromString(targetHtml, 'text/html');
    const sourceImages = sourceDoc.querySelectorAll('img');
    const targetImages = targetDoc.querySelectorAll('img');
    targetImages.forEach((targetImage, i) => {
      const sourceImage = sourceImages[i];
      if (sourceImage) {
        targetImage.replaceWith(sourceImage.cloneNode(true));
      }
    });
    return new XMLSerializer().serializeToString(targetDoc);
  };

  const insertText = (text: string) => {
    if (quillRef.current) {
      quillRef.current.focus();
      const cursorPosition = quillRef.current.getEditor().getSelection()?.index;
      quillRef.current.getEditor().insertText(cursorPosition, text);
    }
  };

  useEffect(() => {
    if (isImageLoading) {
      setDescription(replaceDescriptionImages(props.value, description));
      setImageLoading(false);
    }
  }, [props.value, description, isImageLoading]);

  const modules = useMemo(
    () => ({
      toolbar: {
        container: `#${id}`,
        handlers: {
          mention: () => {},
        },
      },
    }),
    [id],
  );

  return (
    <div className="text-editor">
      <EditorToolbar
        id={id}
        isMessageTextEditor={!!props.isMessageTextEditor}
        insertText={insertText}
        isEditable={props.isEditable}
      />
      <ReactQuill
        ref={quillRef}
        className={clsx(props.className)}
        readOnly={!props.isEditable}
        modules={modules}
        theme={props.theme ? props.theme : 'bubble'}
        style={{ height: props.height ? props.height : '' }}
        value={value}
        placeholder={props.placeholder ? props.placeholder : 'Description'}
        onChange={(value, delta, source, editor) => updateDescription(value)}
      />
    </div>
  );
};
