import { TextField, Button } from '@Shared/DS';
import { type ModalProps } from '@Shared/utils/utils';
import { Modal } from 'react-bootstrap';
import { type ReactNode } from 'react';
import { useDateModal } from './useDateModal';

interface IDateModal extends ModalProps {
  title: ReactNode | ReactNode[] | string;
  currentDate: string;
  saveDate: (value: string) => void;
}

export const DateModal = (props: IDateModal) => {
  const { title, currentDate, showModal, handleShowModal, saveDate } = props;
  const { state, setState } = useDateModal(currentDate);

  return (
    <Modal
      fullscreen={'md-down'}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      show={showModal}
      onHide={() => {
        handleShowModal(false);
      }}
    >
      <Modal.Header className="pb-0">
        <Modal.Title className="fs-5">{title}</Modal.Title>
        <button
          type="button"
          className="btn-close modal-close-btn"
          data-bs-dismiss="modal"
          onClick={() => {
            handleShowModal(false);
          }}
          aria-label="Close"
        ></button>
      </Modal.Header>

      <Modal.Body>
        <label
          className="fs-14-semi-bold col-12 text-start mt-2"
          htmlFor="date-modal"
        >
          Anchor date <span className="required-field-sign">*</span>
        </label>
        <TextField
          id={'date-modal'}
          defaultValue={state.date}
          type="date"
          setValue={(value: string) =>
            setState((state) => ({ ...state, date: value }))
          }
          fieldName="Date"
          placeholder=""
          additionalClasses={`
                    ${!state.date ? 'is-invalid' : ''}`}
        />
      </Modal.Body>

      <Modal.Footer>
        <div className="d-flex justify-content-end">
          <Button
            buttonStyle="outline"
            text="Cancel"
            onClick={() => {
              handleShowModal(false);
            }}
          />

          <Button
            text="Save"
            additionalClasses="ms-2"
            onClick={() => saveDate(state.date)}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};
