import type { ISvgIcon } from './types';

export const HamburgerIcon = ({
  className,
  width = 13,
  height = 9,
}: ISvgIcon) => (
  <svg
    className={className}
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 9H0.5C0.367392 9 0.240215 8.94732 0.146447 8.85355C0.0526784 8.75979 0 8.63261 0 8.5C0 8.36739 0.0526784 8.24021 0.146447 8.14645C0.240215 8.05268 0.367392 8 0.5 8H12.5C12.6326 8 12.7598 8.05268 12.8536 8.14645C12.9473 8.24021 13 8.36739 13 8.5C13 8.63261 12.9473 8.75979 12.8536 8.85355C12.7598 8.94732 12.6326 9 12.5 9ZM12.5 5H0.5C0.367392 5 0.240215 4.94732 0.146447 4.85355C0.0526784 4.75979 0 4.63261 0 4.5C0 4.36739 0.0526784 4.24021 0.146447 4.14645C0.240215 4.05268 0.367392 4 0.5 4H12.5C12.6326 4 12.7598 4.05268 12.8536 4.14645C12.9473 4.24021 13 4.36739 13 4.5C13 4.63261 12.9473 4.75979 12.8536 4.85355C12.7598 4.94732 12.6326 5 12.5 5ZM12.5 1H0.5C0.367392 1 0.240215 0.947322 0.146447 0.853553C0.0526784 0.759785 0 0.632608 0 0.5C0 0.367392 0.0526784 0.240215 0.146447 0.146447C0.240215 0.0526784 0.367392 0 0.5 0L12.5 0C12.6326 0 12.7598 0.0526784 12.8536 0.146447C12.9473 0.240215 13 0.367392 13 0.5C13 0.632608 12.9473 0.759785 12.8536 0.853553C12.7598 0.947322 12.6326 1 12.5 1Z"
      fill="#494C95"
    />
  </svg>
);
