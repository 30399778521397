import { PlaybookTypeCard, WarningBanner } from '@Shared/DS/Molecules';
import { BuilderType } from '@base/API';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { HiOutlineRocketLaunch } from 'react-icons/hi2';
import { MdSelfImprovement } from 'react-icons/md';

interface ICreateTemplateStepOne {
  selectedTemplateType: BuilderType | null;
  setSelectedTemplateType: (type: BuilderType) => void;
}

export const CreateTemplateStepOne = ({
  selectedTemplateType,
  setSelectedTemplateType,
}: ICreateTemplateStepOne) => {
  const playbookTypes = [
    {
      title: 'Onboarding',
      description: 'For new hires, returning parents',
      icon: <AiOutlineUserAdd size={75} />,
      selected: selectedTemplateType === BuilderType.ONBOARDING,
      handleClick: () => setSelectedTemplateType(BuilderType.ONBOARDING),
    },
    {
      title: 'Initiative',
      description: 'For promotions, reviews, offboarding',
      icon: <HiOutlineRocketLaunch size={75} />,
      selected: selectedTemplateType === BuilderType.INITIATIVE,
      handleClick: () => setSelectedTemplateType(BuilderType.INITIATIVE),
    },
    {
      title: 'Self-service',
      description: 'For upskilling on bespoke knowledge',
      icon: <MdSelfImprovement size={75} />,
      selected: selectedTemplateType === BuilderType.SELFSERVE,
      handleClick: () => setSelectedTemplateType(BuilderType.SELFSERVE),
    },
  ];

  return (
    <div>
      <div className="d-flex flex-row gap-2">
        {playbookTypes.map((playbookType, index) => (
          <PlaybookTypeCard key={index} {...playbookType} className="" />
        ))}
      </div>

      <div className="mt-4 px-0 px-lg-3">
        <WarningBanner message="Once the template has been created, the type cannot be changed." />
      </div>
    </div>
  );
};
