/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getShareInfo = /* GraphQL */ `query GetShareInfo($id: ID!) {
  getShareInfo(id: $id) {
    id
    name
    organizationID
    shareInfoOnUsers {
      nextToken
      __typename
    }
    learnInfoOnUsers {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetShareInfoQueryVariables,
  APITypes.GetShareInfoQuery
>;
export const listShareInfos = /* GraphQL */ `query ListShareInfos(
  $filter: ModelShareInfoFilterInput
  $limit: Int
  $nextToken: String
) {
  listShareInfos(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      organizationID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListShareInfosQueryVariables,
  APITypes.ListShareInfosQuery
>;
export const getWorkStyle = /* GraphQL */ `query GetWorkStyle($id: ID!) {
  getWorkStyle(id: $id) {
    id
    whatIDoAtWork
    myFocusTimeIs
    giveReceivingFeedback
    digestionInformation
    inMyElement
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetWorkStyleQueryVariables,
  APITypes.GetWorkStyleQuery
>;
export const listWorkStyles = /* GraphQL */ `query ListWorkStyles(
  $filter: ModelWorkStyleFilterInput
  $limit: Int
  $nextToken: String
) {
  listWorkStyles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      whatIDoAtWork
      myFocusTimeIs
      giveReceivingFeedback
      digestionInformation
      inMyElement
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListWorkStylesQueryVariables,
  APITypes.ListWorkStylesQuery
>;
export const getWorkPreference = /* GraphQL */ `query GetWorkPreference($id: ID!) {
  getWorkPreference(id: $id) {
    id
    giveMeAllTheContext
    messageTimingPreferene
    syncxVsAsync
    talkTogetherVsAlone
    recognitionPrivateVsPrivate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetWorkPreferenceQueryVariables,
  APITypes.GetWorkPreferenceQuery
>;
export const listWorkPreferences = /* GraphQL */ `query ListWorkPreferences(
  $filter: ModelWorkPreferenceFilterInput
  $limit: Int
  $nextToken: String
) {
  listWorkPreferences(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      giveMeAllTheContext
      messageTimingPreferene
      syncxVsAsync
      talkTogetherVsAlone
      recognitionPrivateVsPrivate
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListWorkPreferencesQueryVariables,
  APITypes.ListWorkPreferencesQuery
>;
export const getInterest = /* GraphQL */ `query GetInterest($id: ID!) {
  getInterest(id: $id) {
    id
    name
    organizationID
    users {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetInterestQueryVariables,
  APITypes.GetInterestQuery
>;
export const listInterests = /* GraphQL */ `query ListInterests(
  $filter: ModelInterestFilterInput
  $limit: Int
  $nextToken: String
) {
  listInterests(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      organizationID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInterestsQueryVariables,
  APITypes.ListInterestsQuery
>;
export const getLocation = /* GraphQL */ `query GetLocation($id: ID!) {
  getLocation(id: $id) {
    id
    name
    streetAddress
    city
    state
    zipCode
    country
    locationType
    organizationID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLocationQueryVariables,
  APITypes.GetLocationQuery
>;
export const listLocations = /* GraphQL */ `query ListLocations(
  $filter: ModelLocationFilterInput
  $limit: Int
  $nextToken: String
) {
  listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      streetAddress
      city
      state
      zipCode
      country
      locationType
      organizationID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListLocationsQueryVariables,
  APITypes.ListLocationsQuery
>;
export const getOrganization = /* GraphQL */ `query GetOrganization($id: ID!) {
  getOrganization(id: $id) {
    id
    name
    slackAccessToken
    mergeCompanyToken
    atsToken
    atsAccountId
    hrisToken
    hrisAccountId
    hrisIntegratedAt
    slackUserAccessToken
    outlookTenantId
    msTeamsTenantId
    Locations {
      nextToken
      __typename
    }
    Interests {
      nextToken
      __typename
    }
    ShareInfos {
      nextToken
      __typename
    }
    status
    activeUntil
    hasSubscription
    preboarding
    Journeys {
      nextToken
      __typename
    }
    features
    globalDataStores
    settings
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrganizationQueryVariables,
  APITypes.GetOrganizationQuery
>;
export const listOrganizations = /* GraphQL */ `query ListOrganizations(
  $filter: ModelOrganizationFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      slackAccessToken
      mergeCompanyToken
      atsToken
      atsAccountId
      hrisToken
      hrisAccountId
      hrisIntegratedAt
      slackUserAccessToken
      outlookTenantId
      msTeamsTenantId
      status
      activeUntil
      hasSubscription
      preboarding
      features
      globalDataStores
      settings
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrganizationsQueryVariables,
  APITypes.ListOrganizationsQuery
>;
export const getUser = /* GraphQL */ `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    offset
    firstName
    email
    phone
    sendWelcomeEmail
    personalEmail
    jobTitle
    birthday
    startDate
    homeLocation
    team
    photoUrl
    pronoun
    aboutMe
    mostHappy
    socialMediaUrl
    slackUserId
    isFirstLogin
    isHomeModelViewed
    isProfileModelViewed
    type
    isActive
    Organization {
      id
      name
      slackAccessToken
      mergeCompanyToken
      atsToken
      atsAccountId
      hrisToken
      hrisAccountId
      hrisIntegratedAt
      slackUserAccessToken
      outlookTenantId
      msTeamsTenantId
      status
      activeUntil
      hasSubscription
      preboarding
      features
      globalDataStores
      settings
      createdAt
      updatedAt
      __typename
    }
    Interests {
      nextToken
      __typename
    }
    WorkPreference {
      id
      giveMeAllTheContext
      messageTimingPreferene
      syncxVsAsync
      talkTogetherVsAlone
      recognitionPrivateVsPrivate
      createdAt
      updatedAt
      __typename
    }
    WorkStyle {
      id
      whatIDoAtWork
      myFocusTimeIs
      giveReceivingFeedback
      digestionInformation
      inMyElement
      createdAt
      updatedAt
      __typename
    }
    lastName
    ShareInfoOn {
      nextToken
      __typename
    }
    LearnMoreOn {
      nextToken
      __typename
    }
    WorkLocation {
      id
      name
      streetAddress
      city
      state
      zipCode
      country
      locationType
      organizationID
      createdAt
      updatedAt
      __typename
    }
    Journeys {
      nextToken
      __typename
    }
    Meetings {
      nextToken
      __typename
    }
    PreboardingUsers {
      nextToken
      __typename
    }
    PreboardingUserRelations {
      nextToken
      __typename
    }
    UserBadges {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    userOrganizationId
    userWorkPreferenceId
    userWorkStyleId
    userWorkLocationId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      offset
      firstName
      email
      phone
      sendWelcomeEmail
      personalEmail
      jobTitle
      birthday
      startDate
      homeLocation
      team
      photoUrl
      pronoun
      aboutMe
      mostHappy
      socialMediaUrl
      slackUserId
      isFirstLogin
      isHomeModelViewed
      isProfileModelViewed
      type
      isActive
      lastName
      createdAt
      updatedAt
      userOrganizationId
      userWorkPreferenceId
      userWorkStyleId
      userWorkLocationId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const getJourney = /* GraphQL */ `query GetJourney($id: ID!) {
  getJourney(id: $id) {
    id
    name
    createdByUserID
    assignedUserID
    mode
    startDate
    journeyProgress
    parentJourneyID
    collaborator
    status
    badgeID
    customBadgeName
    lockChapters
    type
    publicLink
    eventStartedAt
    userStartedAt
    userCompletedAt
    isArchived
    preboarding
    organizationID
    Milestone {
      nextToken
      __typename
    }
    Meetings {
      nextToken
      __typename
    }
    JourneyCollaborator {
      nextToken
      __typename
    }
    events {
      nextToken
      __typename
    }
    image
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetJourneyQueryVariables,
  APITypes.GetJourneyQuery
>;
export const listJourneys = /* GraphQL */ `query ListJourneys(
  $filter: ModelJourneyFilterInput
  $limit: Int
  $nextToken: String
) {
  listJourneys(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      createdByUserID
      assignedUserID
      mode
      startDate
      journeyProgress
      parentJourneyID
      collaborator
      status
      badgeID
      customBadgeName
      lockChapters
      type
      publicLink
      eventStartedAt
      userStartedAt
      userCompletedAt
      isArchived
      preboarding
      organizationID
      image
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListJourneysQueryVariables,
  APITypes.ListJourneysQuery
>;
export const listJourneyByAssignedUserId = /* GraphQL */ `query ListJourneyByAssignedUserId(
  $assignedUserID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelJourneyFilterInput
  $limit: Int
  $nextToken: String
) {
  listJourneyByAssignedUserId(
    assignedUserID: $assignedUserID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      createdByUserID
      assignedUserID
      mode
      startDate
      journeyProgress
      parentJourneyID
      collaborator
      status
      badgeID
      customBadgeName
      lockChapters
      type
      publicLink
      eventStartedAt
      userStartedAt
      userCompletedAt
      isArchived
      preboarding
      organizationID
      image
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListJourneyByAssignedUserIdQueryVariables,
  APITypes.ListJourneyByAssignedUserIdQuery
>;
export const listJourneyByOrganizationId = /* GraphQL */ `query ListJourneyByOrganizationId(
  $organizationID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelJourneyFilterInput
  $limit: Int
  $nextToken: String
) {
  listJourneyByOrganizationId(
    organizationID: $organizationID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      createdByUserID
      assignedUserID
      mode
      startDate
      journeyProgress
      parentJourneyID
      collaborator
      status
      badgeID
      customBadgeName
      lockChapters
      type
      publicLink
      eventStartedAt
      userStartedAt
      userCompletedAt
      isArchived
      preboarding
      organizationID
      image
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListJourneyByOrganizationIdQueryVariables,
  APITypes.ListJourneyByOrganizationIdQuery
>;
export const getBadge = /* GraphQL */ `query GetBadge($id: ID!) {
  getBadge(id: $id) {
    id
    name
    iconUrl
    isArchived
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetBadgeQueryVariables, APITypes.GetBadgeQuery>;
export const listBadges = /* GraphQL */ `query ListBadges(
  $filter: ModelBadgeFilterInput
  $limit: Int
  $nextToken: String
) {
  listBadges(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      iconUrl
      isArchived
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBadgesQueryVariables,
  APITypes.ListBadgesQuery
>;
export const getUserBadge = /* GraphQL */ `query GetUserBadge($id: ID!) {
  getUserBadge(id: $id) {
    id
    playbookId
    userID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserBadgeQueryVariables,
  APITypes.GetUserBadgeQuery
>;
export const listUserBadges = /* GraphQL */ `query ListUserBadges(
  $filter: ModelUserBadgeFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserBadges(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      playbookId
      userID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserBadgesQueryVariables,
  APITypes.ListUserBadgesQuery
>;
export const getJourneyCollaborator = /* GraphQL */ `query GetJourneyCollaborator($id: ID!) {
  getJourneyCollaborator(id: $id) {
    id
    assigneeRole
    journeyAccess
    assignedUserID
    actionID
    meetingID
    updatedInChild
    journeyID
    kinfolkTemplateID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetJourneyCollaboratorQueryVariables,
  APITypes.GetJourneyCollaboratorQuery
>;
export const listJourneyCollaborators = /* GraphQL */ `query ListJourneyCollaborators(
  $filter: ModelJourneyCollaboratorFilterInput
  $limit: Int
  $nextToken: String
) {
  listJourneyCollaborators(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      assigneeRole
      journeyAccess
      assignedUserID
      actionID
      meetingID
      updatedInChild
      journeyID
      kinfolkTemplateID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListJourneyCollaboratorsQueryVariables,
  APITypes.ListJourneyCollaboratorsQuery
>;
export const listJourneyCollaboratorByJourneyId = /* GraphQL */ `query ListJourneyCollaboratorByJourneyId(
  $journeyID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelJourneyCollaboratorFilterInput
  $limit: Int
  $nextToken: String
) {
  listJourneyCollaboratorByJourneyId(
    journeyID: $journeyID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      assigneeRole
      journeyAccess
      assignedUserID
      actionID
      meetingID
      updatedInChild
      journeyID
      kinfolkTemplateID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListJourneyCollaboratorByJourneyIdQueryVariables,
  APITypes.ListJourneyCollaboratorByJourneyIdQuery
>;
export const listJourneyCollaboratorByKinfolkTemplateId = /* GraphQL */ `query ListJourneyCollaboratorByKinfolkTemplateId(
  $kinfolkTemplateID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelJourneyCollaboratorFilterInput
  $limit: Int
  $nextToken: String
) {
  listJourneyCollaboratorByKinfolkTemplateId(
    kinfolkTemplateID: $kinfolkTemplateID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      assigneeRole
      journeyAccess
      assignedUserID
      actionID
      meetingID
      updatedInChild
      journeyID
      kinfolkTemplateID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListJourneyCollaboratorByKinfolkTemplateIdQueryVariables,
  APITypes.ListJourneyCollaboratorByKinfolkTemplateIdQuery
>;
export const getKinfolkTemplate = /* GraphQL */ `query GetKinfolkTemplate($id: ID!) {
  getKinfolkTemplate(id: $id) {
    id
    name
    description
    image
    isArchived
    status
    orderNo
    type
    mode
    isPublic
    Milestone {
      nextToken
      __typename
    }
    Meetings {
      nextToken
      __typename
    }
    JourneyCollaborator {
      nextToken
      __typename
    }
    events {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetKinfolkTemplateQueryVariables,
  APITypes.GetKinfolkTemplateQuery
>;
export const listKinfolkTemplates = /* GraphQL */ `query ListKinfolkTemplates(
  $filter: ModelKinfolkTemplateFilterInput
  $limit: Int
  $nextToken: String
) {
  listKinfolkTemplates(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      image
      isArchived
      status
      orderNo
      type
      mode
      isPublic
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListKinfolkTemplatesQueryVariables,
  APITypes.ListKinfolkTemplatesQuery
>;
export const getMilestone = /* GraphQL */ `query GetMilestone($id: ID!) {
  getMilestone(id: $id) {
    id
    name
    kinfolkTemplateID
    isCompleted
    isArchived
    journeyID
    orderNo
    parentMilestoneID
    EmbeddedContentBlocks {
      nextToken
      __typename
    }
    FreeTextQuestionBlocks {
      nextToken
      __typename
    }
    NoteBlocks {
      nextToken
      __typename
    }
    TaskBlocks {
      nextToken
      __typename
    }
    StandardContentBlocks {
      nextToken
      __typename
    }
    MultipleChoiceQuestionBlocks {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMilestoneQueryVariables,
  APITypes.GetMilestoneQuery
>;
export const listMilestones = /* GraphQL */ `query ListMilestones(
  $filter: ModelMilestoneFilterInput
  $limit: Int
  $nextToken: String
) {
  listMilestones(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      kinfolkTemplateID
      isCompleted
      isArchived
      journeyID
      orderNo
      parentMilestoneID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMilestonesQueryVariables,
  APITypes.ListMilestonesQuery
>;
export const listMilestoneByKinfolkTemplateId = /* GraphQL */ `query ListMilestoneByKinfolkTemplateId(
  $kinfolkTemplateID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelMilestoneFilterInput
  $limit: Int
  $nextToken: String
) {
  listMilestoneByKinfolkTemplateId(
    kinfolkTemplateID: $kinfolkTemplateID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      kinfolkTemplateID
      isCompleted
      isArchived
      journeyID
      orderNo
      parentMilestoneID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMilestoneByKinfolkTemplateIdQueryVariables,
  APITypes.ListMilestoneByKinfolkTemplateIdQuery
>;
export const listMilestoneByJourneyId = /* GraphQL */ `query ListMilestoneByJourneyId(
  $journeyID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelMilestoneFilterInput
  $limit: Int
  $nextToken: String
) {
  listMilestoneByJourneyId(
    journeyID: $journeyID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      kinfolkTemplateID
      isCompleted
      isArchived
      journeyID
      orderNo
      parentMilestoneID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMilestoneByJourneyIdQueryVariables,
  APITypes.ListMilestoneByJourneyIdQuery
>;
export const getEmbeddedContentBlock = /* GraphQL */ `query GetEmbeddedContentBlock($id: ID!) {
  getEmbeddedContentBlock(id: $id) {
    id
    type
    milestoneID
    title
    description
    resourceLink
    isFormLinked
    orderNo
    parentEmbeddedContentBlockID
    isCompleted
    isArchived
    updatedInChild
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetEmbeddedContentBlockQueryVariables,
  APITypes.GetEmbeddedContentBlockQuery
>;
export const listEmbeddedContentBlocks = /* GraphQL */ `query ListEmbeddedContentBlocks(
  $filter: ModelEmbeddedContentBlockFilterInput
  $limit: Int
  $nextToken: String
) {
  listEmbeddedContentBlocks(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      milestoneID
      title
      description
      resourceLink
      isFormLinked
      orderNo
      parentEmbeddedContentBlockID
      isCompleted
      isArchived
      updatedInChild
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEmbeddedContentBlocksQueryVariables,
  APITypes.ListEmbeddedContentBlocksQuery
>;
export const getFreeTextQuestionBlock = /* GraphQL */ `query GetFreeTextQuestionBlock($id: ID!) {
  getFreeTextQuestionBlock(id: $id) {
    id
    type
    milestoneID
    title
    description
    text
    orderNo
    parentFreeTextQuestionBlockID
    isCompleted
    isArchived
    updatedInChild
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFreeTextQuestionBlockQueryVariables,
  APITypes.GetFreeTextQuestionBlockQuery
>;
export const listFreeTextQuestionBlocks = /* GraphQL */ `query ListFreeTextQuestionBlocks(
  $filter: ModelFreeTextQuestionBlockFilterInput
  $limit: Int
  $nextToken: String
) {
  listFreeTextQuestionBlocks(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      milestoneID
      title
      description
      text
      orderNo
      parentFreeTextQuestionBlockID
      isCompleted
      isArchived
      updatedInChild
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFreeTextQuestionBlocksQueryVariables,
  APITypes.ListFreeTextQuestionBlocksQuery
>;
export const getNoteBlock = /* GraphQL */ `query GetNoteBlock($id: ID!) {
  getNoteBlock(id: $id) {
    id
    type
    milestoneID
    title
    description
    text
    orderNo
    parentNoteBlockID
    isCompleted
    isPrivate
    isArchived
    updatedInChild
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetNoteBlockQueryVariables,
  APITypes.GetNoteBlockQuery
>;
export const listNoteBlocks = /* GraphQL */ `query ListNoteBlocks(
  $filter: ModelNoteBlockFilterInput
  $limit: Int
  $nextToken: String
) {
  listNoteBlocks(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      type
      milestoneID
      title
      description
      text
      orderNo
      parentNoteBlockID
      isCompleted
      isPrivate
      isArchived
      updatedInChild
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListNoteBlocksQueryVariables,
  APITypes.ListNoteBlocksQuery
>;
export const getTaskBlock = /* GraphQL */ `query GetTaskBlock($id: ID!) {
  getTaskBlock(id: $id) {
    id
    type
    milestoneID
    title
    description
    status
    orderNo
    parentTaskBlockID
    isCompleted
    isArchived
    updatedInChild
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTaskBlockQueryVariables,
  APITypes.GetTaskBlockQuery
>;
export const listTaskBlocks = /* GraphQL */ `query ListTaskBlocks(
  $filter: ModelTaskBlockFilterInput
  $limit: Int
  $nextToken: String
) {
  listTaskBlocks(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      type
      milestoneID
      title
      description
      status
      orderNo
      parentTaskBlockID
      isCompleted
      isArchived
      updatedInChild
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTaskBlocksQueryVariables,
  APITypes.ListTaskBlocksQuery
>;
export const getStandardContentBlock = /* GraphQL */ `query GetStandardContentBlock($id: ID!) {
  getStandardContentBlock(id: $id) {
    id
    type
    milestoneID
    title
    description
    orderNo
    parentStandardContentBlockID
    isCompleted
    isArchived
    updatedInChild
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetStandardContentBlockQueryVariables,
  APITypes.GetStandardContentBlockQuery
>;
export const listStandardContentBlocks = /* GraphQL */ `query ListStandardContentBlocks(
  $filter: ModelStandardContentBlockFilterInput
  $limit: Int
  $nextToken: String
) {
  listStandardContentBlocks(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      milestoneID
      title
      description
      orderNo
      parentStandardContentBlockID
      isCompleted
      isArchived
      updatedInChild
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListStandardContentBlocksQueryVariables,
  APITypes.ListStandardContentBlocksQuery
>;
export const getMultipleChoiceQuestionBlock = /* GraphQL */ `query GetMultipleChoiceQuestionBlock($id: ID!) {
  getMultipleChoiceQuestionBlock(id: $id) {
    id
    type
    milestoneID
    title
    description
    option
    answer
    orderNo
    parentMultipleChoiceQuestionBlockID
    isCompleted
    isRequired
    isArchived
    updatedInChild
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMultipleChoiceQuestionBlockQueryVariables,
  APITypes.GetMultipleChoiceQuestionBlockQuery
>;
export const listMultipleChoiceQuestionBlocks = /* GraphQL */ `query ListMultipleChoiceQuestionBlocks(
  $filter: ModelMultipleChoiceQuestionBlockFilterInput
  $limit: Int
  $nextToken: String
) {
  listMultipleChoiceQuestionBlocks(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      milestoneID
      title
      description
      option
      answer
      orderNo
      parentMultipleChoiceQuestionBlockID
      isCompleted
      isRequired
      isArchived
      updatedInChild
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMultipleChoiceQuestionBlocksQueryVariables,
  APITypes.ListMultipleChoiceQuestionBlocksQuery
>;
export const getEvent = /* GraphQL */ `query GetEvent($id: ID!) {
  getEvent(id: $id) {
    id
    userId
    formId
    formInstanceId
    daysFrom
    daysFromDueDate
    type
    role
    emailTo
    title
    message
    sendTime
    sendTimeDate
    dueDate
    status
    channelId
    parentId
    channel
    cc
    archived
    updatedInChild
    journeyID
    kinfolkTemplateID
    reminders {
      nextToken
      __typename
    }
    meta
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetEventQueryVariables, APITypes.GetEventQuery>;
export const listEvents = /* GraphQL */ `query ListEvents(
  $filter: ModelEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userId
      formId
      formInstanceId
      daysFrom
      daysFromDueDate
      type
      role
      emailTo
      title
      message
      sendTime
      sendTimeDate
      dueDate
      status
      channelId
      parentId
      channel
      cc
      archived
      updatedInChild
      journeyID
      kinfolkTemplateID
      meta
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEventsQueryVariables,
  APITypes.ListEventsQuery
>;
export const listEventsByJourneyId = /* GraphQL */ `query ListEventsByJourneyId(
  $journeyID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listEventsByJourneyId(
    journeyID: $journeyID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      formId
      formInstanceId
      daysFrom
      daysFromDueDate
      type
      role
      emailTo
      title
      message
      sendTime
      sendTimeDate
      dueDate
      status
      channelId
      parentId
      channel
      cc
      archived
      updatedInChild
      journeyID
      kinfolkTemplateID
      meta
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEventsByJourneyIdQueryVariables,
  APITypes.ListEventsByJourneyIdQuery
>;
export const listEventsByKinfolkTemplateId = /* GraphQL */ `query ListEventsByKinfolkTemplateId(
  $kinfolkTemplateID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listEventsByKinfolkTemplateId(
    kinfolkTemplateID: $kinfolkTemplateID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      formId
      formInstanceId
      daysFrom
      daysFromDueDate
      type
      role
      emailTo
      title
      message
      sendTime
      sendTimeDate
      dueDate
      status
      channelId
      parentId
      channel
      cc
      archived
      updatedInChild
      journeyID
      kinfolkTemplateID
      meta
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEventsByKinfolkTemplateIdQueryVariables,
  APITypes.ListEventsByKinfolkTemplateIdQuery
>;
export const getEventReminder = /* GraphQL */ `query GetEventReminder($id: ID!) {
  getEventReminder(id: $id) {
    id
    eventId
    sendTimeDate
    daysFrom
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetEventReminderQueryVariables,
  APITypes.GetEventReminderQuery
>;
export const listEventReminders = /* GraphQL */ `query ListEventReminders(
  $filter: ModelEventReminderFilterInput
  $limit: Int
  $nextToken: String
) {
  listEventReminders(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      eventId
      sendTimeDate
      daysFrom
      status
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEventRemindersQueryVariables,
  APITypes.ListEventRemindersQuery
>;
export const listRemindersByEventId = /* GraphQL */ `query ListRemindersByEventId(
  $eventId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelEventReminderFilterInput
  $limit: Int
  $nextToken: String
) {
  listRemindersByEventId(
    eventId: $eventId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      eventId
      sendTimeDate
      daysFrom
      status
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRemindersByEventIdQueryVariables,
  APITypes.ListRemindersByEventIdQuery
>;
export const getMeeting = /* GraphQL */ `query GetMeeting($id: ID!) {
  getMeeting(id: $id) {
    id
    kinfolkTemplateID
    journeyID
    organizedByUserID
    status
    title
    description
    attendeesEmail
    startTime
    endTime
    parentMeetingID
    updatedInChild
    eventDate
    duration
    link
    assigneeRole
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMeetingQueryVariables,
  APITypes.GetMeetingQuery
>;
export const listMeetings = /* GraphQL */ `query ListMeetings(
  $filter: ModelMeetingFilterInput
  $limit: Int
  $nextToken: String
) {
  listMeetings(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      kinfolkTemplateID
      journeyID
      organizedByUserID
      status
      title
      description
      attendeesEmail
      startTime
      endTime
      parentMeetingID
      updatedInChild
      eventDate
      duration
      link
      assigneeRole
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMeetingsQueryVariables,
  APITypes.ListMeetingsQuery
>;
export const listMeetingByKinfolkTemplateId = /* GraphQL */ `query ListMeetingByKinfolkTemplateId(
  $kinfolkTemplateID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelMeetingFilterInput
  $limit: Int
  $nextToken: String
) {
  listMeetingByKinfolkTemplateId(
    kinfolkTemplateID: $kinfolkTemplateID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      kinfolkTemplateID
      journeyID
      organizedByUserID
      status
      title
      description
      attendeesEmail
      startTime
      endTime
      parentMeetingID
      updatedInChild
      eventDate
      duration
      link
      assigneeRole
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMeetingByKinfolkTemplateIdQueryVariables,
  APITypes.ListMeetingByKinfolkTemplateIdQuery
>;
export const listMeetingByJourneyId = /* GraphQL */ `query ListMeetingByJourneyId(
  $journeyID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelMeetingFilterInput
  $limit: Int
  $nextToken: String
) {
  listMeetingByJourneyId(
    journeyID: $journeyID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      kinfolkTemplateID
      journeyID
      organizedByUserID
      status
      title
      description
      attendeesEmail
      startTime
      endTime
      parentMeetingID
      updatedInChild
      eventDate
      duration
      link
      assigneeRole
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMeetingByJourneyIdQueryVariables,
  APITypes.ListMeetingByJourneyIdQuery
>;
export const getSecretKeyManager = /* GraphQL */ `query GetSecretKeyManager($id: ID!) {
  getSecretKeyManager(id: $id) {
    id
    organizationID
    clientEmail
    privateKey
    adminEmail
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSecretKeyManagerQueryVariables,
  APITypes.GetSecretKeyManagerQuery
>;
export const listSecretKeyManagers = /* GraphQL */ `query ListSecretKeyManagers(
  $filter: ModelSecretKeyManagerFilterInput
  $limit: Int
  $nextToken: String
) {
  listSecretKeyManagers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      organizationID
      clientEmail
      privateKey
      adminEmail
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSecretKeyManagersQueryVariables,
  APITypes.ListSecretKeyManagersQuery
>;
export const getPreboardingUser = /* GraphQL */ `query GetPreboardingUser($id: ID!) {
  getPreboardingUser(id: $id) {
    id
    userId
    isSubmitted
    consent
    journeyId
    personalEmail
    photoUrl
    bankName
    bankBranch
    bankSortCode
    bankAccountNumber
    bankAccountName
    buildingSocietyRoll
    street1
    street2
    city
    state
    zip
    country
    birthday
    gender
    title
    nationality
    mobileNumber
    niNumber
    laptopPreference
    secondMonitor
    corporateCard
    corporatePhone
    healthCare
    dental
    optical
    jobDeclaration
    expatDeclaration
    studentLoanRepayment
    studentLoanType
    pensionScheme
    pensionContribution
    passportNumber
    passportIssueDate
    passportExpiryDate
    passportIssuingCountry
    confirmation
    pensionSchemeOpt
    emergencyContactName
    emergencyContactRelationship
    emergencyContactPhone
    emergencyContactEmailAddress
    emergencyContactStreet1
    emergencyContactStreet2
    emergencyContactCity
    emergencyContactState
    emergencyContactZip
    emergencyContactCountry
    PreboardingUserRelations {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPreboardingUserQueryVariables,
  APITypes.GetPreboardingUserQuery
>;
export const listPreboardingUsers = /* GraphQL */ `query ListPreboardingUsers(
  $filter: ModelPreboardingUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listPreboardingUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userId
      isSubmitted
      consent
      journeyId
      personalEmail
      photoUrl
      bankName
      bankBranch
      bankSortCode
      bankAccountNumber
      bankAccountName
      buildingSocietyRoll
      street1
      street2
      city
      state
      zip
      country
      birthday
      gender
      title
      nationality
      mobileNumber
      niNumber
      laptopPreference
      secondMonitor
      corporateCard
      corporatePhone
      healthCare
      dental
      optical
      jobDeclaration
      expatDeclaration
      studentLoanRepayment
      studentLoanType
      pensionScheme
      pensionContribution
      passportNumber
      passportIssueDate
      passportExpiryDate
      passportIssuingCountry
      confirmation
      pensionSchemeOpt
      emergencyContactName
      emergencyContactRelationship
      emergencyContactPhone
      emergencyContactEmailAddress
      emergencyContactStreet1
      emergencyContactStreet2
      emergencyContactCity
      emergencyContactState
      emergencyContactZip
      emergencyContactCountry
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPreboardingUsersQueryVariables,
  APITypes.ListPreboardingUsersQuery
>;
export const getPreboardingUserRelation = /* GraphQL */ `query GetPreboardingUserRelation($id: ID!) {
  getPreboardingUserRelation(id: $id) {
    id
    firstName
    lastName
    relationshipToMember
    birthday
    userId
    formId
    healthCare
    dental
    optical
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPreboardingUserRelationQueryVariables,
  APITypes.GetPreboardingUserRelationQuery
>;
export const listPreboardingUserRelations = /* GraphQL */ `query ListPreboardingUserRelations(
  $filter: ModelPreboardingUserRelationFilterInput
  $limit: Int
  $nextToken: String
) {
  listPreboardingUserRelations(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      firstName
      lastName
      relationshipToMember
      birthday
      userId
      formId
      healthCare
      dental
      optical
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPreboardingUserRelationsQueryVariables,
  APITypes.ListPreboardingUserRelationsQuery
>;
export const getDigest = /* GraphQL */ `query GetDigest($id: ID!) {
  getDigest(id: $id) {
    id
    type
    organizationId
    userId
    frequency
    channel
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetDigestQueryVariables, APITypes.GetDigestQuery>;
export const listDigests = /* GraphQL */ `query ListDigests(
  $filter: ModelDigestFilterInput
  $limit: Int
  $nextToken: String
) {
  listDigests(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      type
      organizationId
      userId
      frequency
      channel
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDigestsQueryVariables,
  APITypes.ListDigestsQuery
>;
export const getGqlMessage = /* GraphQL */ `query GetGqlMessage($id: ID!) {
  getGqlMessage(id: $id) {
    id
    type
    organizationId
    recipientId
    payload
    response
    status
    callbackId
    deliveryInfo
    respondedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetGqlMessageQueryVariables,
  APITypes.GetGqlMessageQuery
>;
export const listGqlMessages = /* GraphQL */ `query ListGqlMessages(
  $filter: ModelGqlMessageFilterInput
  $limit: Int
  $nextToken: String
) {
  listGqlMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      type
      organizationId
      recipientId
      payload
      response
      status
      callbackId
      deliveryInfo
      respondedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListGqlMessagesQueryVariables,
  APITypes.ListGqlMessagesQuery
>;
export const getGqlMsTeamsUserReference = /* GraphQL */ `query GetGqlMsTeamsUserReference($id: ID!) {
  getGqlMsTeamsUserReference(id: $id) {
    tenantId
    userEmail
    teamsUserId
    conversation
    userId
    organizationId
    hasUndeliveredMessages
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetGqlMsTeamsUserReferenceQueryVariables,
  APITypes.GetGqlMsTeamsUserReferenceQuery
>;
export const listGqlMsTeamsUserReferences = /* GraphQL */ `query ListGqlMsTeamsUserReferences(
  $filter: ModelGqlMsTeamsUserReferenceFilterInput
  $limit: Int
  $nextToken: String
) {
  listGqlMsTeamsUserReferences(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      tenantId
      userEmail
      teamsUserId
      conversation
      userId
      organizationId
      hasUndeliveredMessages
      id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListGqlMsTeamsUserReferencesQueryVariables,
  APITypes.ListGqlMsTeamsUserReferencesQuery
>;
export const getGqlDataStore = /* GraphQL */ `query GetGqlDataStore($id: ID!) {
  getGqlDataStore(id: $id) {
    id
    organizationId
    name
    data
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetGqlDataStoreQueryVariables,
  APITypes.GetGqlDataStoreQuery
>;
export const listGqlDataStores = /* GraphQL */ `query ListGqlDataStores(
  $filter: ModelGqlDataStoreFilterInput
  $limit: Int
  $nextToken: String
) {
  listGqlDataStores(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      organizationId
      name
      data
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListGqlDataStoresQueryVariables,
  APITypes.ListGqlDataStoresQuery
>;
export const getGqlDataStoreRow = /* GraphQL */ `query GetGqlDataStoreRow($id: ID!) {
  getGqlDataStoreRow(id: $id) {
    id
    dataStoreId
    data
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetGqlDataStoreRowQueryVariables,
  APITypes.GetGqlDataStoreRowQuery
>;
export const listGqlDataStoreRows = /* GraphQL */ `query ListGqlDataStoreRows(
  $filter: ModelGqlDataStoreRowFilterInput
  $limit: Int
  $nextToken: String
) {
  listGqlDataStoreRows(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      dataStoreId
      data
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListGqlDataStoreRowsQueryVariables,
  APITypes.ListGqlDataStoreRowsQuery
>;
export const getGqlForm = /* GraphQL */ `query GetGqlForm($id: ID!) {
  getGqlForm(id: $id) {
    id
    organizationId
    name
    dataStoreId
    data
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetGqlFormQueryVariables,
  APITypes.GetGqlFormQuery
>;
export const listGqlForms = /* GraphQL */ `query ListGqlForms(
  $filter: ModelGqlFormFilterInput
  $limit: Int
  $nextToken: String
) {
  listGqlForms(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      organizationId
      name
      dataStoreId
      data
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListGqlFormsQueryVariables,
  APITypes.ListGqlFormsQuery
>;
export const getGqlFormInstance = /* GraphQL */ `query GetGqlFormInstance($id: ID!) {
  getGqlFormInstance(id: $id) {
    id
    organizationId
    formId
    assignedTo
    link
    slug
    data
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetGqlFormInstanceQueryVariables,
  APITypes.GetGqlFormInstanceQuery
>;
export const listGqlFormInstances = /* GraphQL */ `query ListGqlFormInstances(
  $filter: ModelGqlFormInstanceFilterInput
  $limit: Int
  $nextToken: String
) {
  listGqlFormInstances(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      organizationId
      formId
      assignedTo
      link
      slug
      data
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListGqlFormInstancesQueryVariables,
  APITypes.ListGqlFormInstancesQuery
>;
export const getUserShareInfo = /* GraphQL */ `query GetUserShareInfo($id: ID!) {
  getUserShareInfo(id: $id) {
    id
    shareInfoID
    userID
    shareInfo {
      id
      name
      organizationID
      createdAt
      updatedAt
      __typename
    }
    user {
      id
      offset
      firstName
      email
      phone
      sendWelcomeEmail
      personalEmail
      jobTitle
      birthday
      startDate
      homeLocation
      team
      photoUrl
      pronoun
      aboutMe
      mostHappy
      socialMediaUrl
      slackUserId
      isFirstLogin
      isHomeModelViewed
      isProfileModelViewed
      type
      isActive
      lastName
      createdAt
      updatedAt
      userOrganizationId
      userWorkPreferenceId
      userWorkStyleId
      userWorkLocationId
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserShareInfoQueryVariables,
  APITypes.GetUserShareInfoQuery
>;
export const listUserShareInfos = /* GraphQL */ `query ListUserShareInfos(
  $filter: ModelUserShareInfoFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserShareInfos(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      shareInfoID
      userID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserShareInfosQueryVariables,
  APITypes.ListUserShareInfosQuery
>;
export const getLearnMoreInfo = /* GraphQL */ `query GetLearnMoreInfo($id: ID!) {
  getLearnMoreInfo(id: $id) {
    id
    shareInfoID
    userID
    shareInfo {
      id
      name
      organizationID
      createdAt
      updatedAt
      __typename
    }
    user {
      id
      offset
      firstName
      email
      phone
      sendWelcomeEmail
      personalEmail
      jobTitle
      birthday
      startDate
      homeLocation
      team
      photoUrl
      pronoun
      aboutMe
      mostHappy
      socialMediaUrl
      slackUserId
      isFirstLogin
      isHomeModelViewed
      isProfileModelViewed
      type
      isActive
      lastName
      createdAt
      updatedAt
      userOrganizationId
      userWorkPreferenceId
      userWorkStyleId
      userWorkLocationId
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLearnMoreInfoQueryVariables,
  APITypes.GetLearnMoreInfoQuery
>;
export const listLearnMoreInfos = /* GraphQL */ `query ListLearnMoreInfos(
  $filter: ModelLearnMoreInfoFilterInput
  $limit: Int
  $nextToken: String
) {
  listLearnMoreInfos(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      shareInfoID
      userID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListLearnMoreInfosQueryVariables,
  APITypes.ListLearnMoreInfosQuery
>;
export const getUserInterest = /* GraphQL */ `query GetUserInterest($id: ID!) {
  getUserInterest(id: $id) {
    id
    interestID
    userID
    interest {
      id
      name
      organizationID
      createdAt
      updatedAt
      __typename
    }
    user {
      id
      offset
      firstName
      email
      phone
      sendWelcomeEmail
      personalEmail
      jobTitle
      birthday
      startDate
      homeLocation
      team
      photoUrl
      pronoun
      aboutMe
      mostHappy
      socialMediaUrl
      slackUserId
      isFirstLogin
      isHomeModelViewed
      isProfileModelViewed
      type
      isActive
      lastName
      createdAt
      updatedAt
      userOrganizationId
      userWorkPreferenceId
      userWorkStyleId
      userWorkLocationId
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserInterestQueryVariables,
  APITypes.GetUserInterestQuery
>;
export const listUserInterests = /* GraphQL */ `query ListUserInterests(
  $filter: ModelUserInterestFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserInterests(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      interestID
      userID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserInterestsQueryVariables,
  APITypes.ListUserInterestsQuery
>;
