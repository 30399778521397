import { ProgressBar } from 'react-bootstrap';
import { BsLightningFill } from 'react-icons/bs';
import { openExternalUrl } from '@Shared/utils/utils';
import { useNavbarSupport } from './useNavbarSupport';
import './NavbarSupport.scss';

export interface INavbarSupport {
  daysRemaining: number | null;
  hasSubscription: boolean | null;
}

export const NavbarSupport = () => {
  const { daysRemaining, hasSubscription } = useNavbarSupport();
  const progressPercentage = ((30 - daysRemaining) / 30) * 100;

  return (
    <div className="navbarsupport p-2">
      {!hasSubscription && (
        <>
          {daysRemaining <= 0 ? (
            <p className="pb-1">
              <span className="fw-bold">
                Your trial ends today, please contact us to continue.
              </span>
            </p>
          ) : (
            <>
              <p className="pb-1">
                <span className="fw-bold">{daysRemaining} days left</span> of
                your trial
              </p>
              <ProgressBar now={progressPercentage} />
            </>
          )}
          <button
            type="button"
            className="my-2 btn btn-kin-purple navbarsupport__upgradebtn"
            onClick={() =>
              openExternalUrl('https://calendly.com/kinfolkhq/jeet')
            }
          >
            <BsLightningFill /> Upgrade
          </button>
        </>
      )}
      {/* <ul className="navbarsupport__links">
        <li>
          <BsPersonPlus />
          <button type="button" className="btn btn-link">
            <span>Add members</span>
          </button>
        </li>
        <li>
          <BsLifePreserver />
          <button type="button" className="btn btn-link">
            <span>Help & Support</span>
          </button>
        </li>
      </ul> */}
    </div>
  );
};
