import { type ReactNode } from 'react';
import './PlaybookTypeCard.scss';

interface IPlaybookTypeCard {
  title: string;
  description?: string;
  icon: ReactNode;
  selected: boolean;
  className?: string;
  handleClick: () => void;
}

export const PlaybookTypeCard = ({
  title,
  description,
  icon,
  selected,
  className,
  handleClick,
}: IPlaybookTypeCard) => (
  <div
    className={`playbook-type-card text-center ${className ?? ''}`}
    onClick={handleClick}
  >
    <div className={`playbook-type ${selected ? 'active' : ''}`}>
      {icon}
      <h2 className="title">{title}</h2>
      {description && (
        <p className="playbook-type--description">{description}</p>
      )}
    </div>
  </div>
);
