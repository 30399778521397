import { ToggleDropDownSimple } from '@Shared/Components/DropDownField/DropDownField';
import { TextField } from '@Shared/DS/Molecules';
import { Row, Col } from 'react-bootstrap';

interface ICreatePlaybookStepTwo {
  playbookStartDateOptions: string[];
  playbookStartDate: string | null;
  setPlaybookStartDate: (value: string) => void;
  startDateOption: string;
  setStartDateOption: (value: string) => void;
  isSubmitted: boolean;
  showStartDateOptionError?: boolean;
}
export const CreatePlaybookStepTwo = ({
  setPlaybookStartDate,
  playbookStartDateOptions,
  startDateOption,
  setStartDateOption,
  showStartDateOptionError,
}: ICreatePlaybookStepTwo) => (
  <Row>
    <Col>
      <form>
        <label
          className="fs-14-semi-bold col-12 form-label text-start mt-2"
          htmlFor={'startDateOption'}
        >
          Select anchor date option<span className="required-sign">*</span>
        </label>
        <ToggleDropDownSimple
          items={playbookStartDateOptions}
          selectedItem={startDateOption}
          className={`w-100 ${showStartDateOptionError ? 'is-invalid' : ''}`}
          onSelect={setStartDateOption}
        />
        {showStartDateOptionError && (
          <div className="invalid-feedback text-start">
            Please select a anchor date option
          </div>
        )}

        {startDateOption === playbookStartDateOptions[2] && (
          <div className="col-12 col-lg-5">
            <label
              className="fs-14-semi-bold text-start mt-2"
              htmlFor="playbookStartDate"
            >
              Runbook anchor date
            </label>
            <TextField
              id={'playbookStartDate'}
              type="date"
              setValue={(value: string) => setPlaybookStartDate(value)}
              fieldName="Date"
              placeholder="Select start date"
            />
          </div>
        )}
      </form>
    </Col>
  </Row>
);
