import { useState } from 'react';
import { searchUser } from '@api/apis';
import { useUserContext } from '@base/Context/UserContext/UserContext';

export const useSearch = (orgId?: string) => {
  const [users, setUsers] = useState<any[]>([
    { role: 'HROPS' },
    { role: 'HRBP' },
    { role: 'Manager' },
    { role: 'Buddy' },
  ]);
  const [isSearching, setIsSearching] = useState(false);
  const { user } = useUserContext();

  const findUser = async (query: string, minLength = 3) => {
    if (query.length < minLength) {
      return;
    }

    setIsSearching(true);
    const filteredUsers = await searchUser(
      orgId ?? user.userOrganizationId,
      query,
    );
    if (filteredUsers) {
      setUsers([...users, ...filteredUsers]);
    }
    setIsSearching(false);
  };

  return {
    isSearching,
    users,
    findUser,
  };
};
