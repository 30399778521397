import clsx from 'clsx';
import { type MouseEventHandler, type ReactNode } from 'react';
import styles from './Pill.module.scss';

interface BasePillProps {
  children: ReactNode;
  className?: string;
  color:
    | 'brightGreen'
    | 'purple'
    | 'pink'
    | 'babyBlue'
    | 'concrete'
    | 'navy'
    | 'orange'
    | 'forestGreen'
    | 'red'
    | 'teal'
    | 'gray';
  size?: 'small' | 'big';
}

interface StaticPillProps extends BasePillProps {
  colorType: 'bright' | 'light' | 'white';
  isActive?: never;
  onClick?: never;
}

interface ClickablePillProps extends BasePillProps {
  colorType?: never;
  isActive?: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
}

export type PillProps = StaticPillProps | ClickablePillProps;

export const Pill = ({
  children,
  className,
  color,
  colorType,
  size = 'small',
  onClick,
  isActive,
}: PillProps) => {
  const commonProps = {
    className: clsx(
      className,
      styles.pill,
      styles[`color-${color}`],
      styles[`size-${size}`],
    ),
    children,
  };

  if (onClick) {
    return (
      <button
        {...commonProps}
        type="button"
        className={clsx(commonProps.className, styles.clickable, {
          [styles.active]: isActive,
        })}
        onClick={onClick}
      />
    );
  }

  return (
    <span
      {...commonProps}
      className={clsx(commonProps.className, styles[`colorType-${colorType}`])}
    />
  );
};
