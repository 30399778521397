import { useMemo } from 'react';
import { useUserContext } from '@base/Context/UserContext/UserContext';

export const useNavbarSupport = () => {
  const { user } = useUserContext();

  const { daysRemaining, hasSubscription } = useMemo(() => {
    if (!user.Organization || !user.Organization.activeUntil) {
      return { daysRemaining: 0, hasSubscription: false };
    }

    const { activeUntil } = user.Organization;
    const activeUntilEpoch = Number(activeUntil);
    if (isNaN(activeUntilEpoch)) {
      return { daysRemaining: 0, hasSubscription: false };
    }

    const currentDate = new Date();
    const activeUntilDate = new Date(activeUntilEpoch * 1000);
    const timeDiff = activeUntilDate.getTime() - currentDate.getTime();
    const daysDiff = timeDiff / (1000 * 3600 * 24);
    return {
      daysRemaining: Math.max(0, Math.ceil(daysDiff)),
      hasSubscription: !!user.Organization.hasSubscription,
    };
  }, [user.Organization]);

  return { daysRemaining, hasSubscription };
};
