import { useState, useEffect, useCallback, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import confetti from 'canvas-confetti';
import {
  type Milestone,
  BlockType,
  JourneyStatus,
  type Journey,
  UserType,
  type UserBadge,
  type Badge,
  BuilderType,
  type JourneyCollaborator,
} from '@API';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import {
  createUserBadge,
  deleteJourneyMilestone,
  deleteMilestoneBlocks,
  getJourneyCollaborators,
  getJourneyData,
  getPlaybookBadge,
  getUserBasicData,
  isJourneyCollaborator,
  listChildJourneys,
  substituteDynamicTags,
  startPlaybook as triggerSelfServePlaybookEvents,
  updateBlocksOrderNo,
  updateJourney,
  updateMilestone,
  updateMilestoneOrder,
} from '@api/apis';
import {
  addNotificationToApp,
  calculateProgress,
  checkMilestoneStatus,
  compareMilestones,
  handleAddEmbeddedContentBlock,
  handleBlocksUpdate,
  handleDeleteBlocks,
  handleMilestoneUpdate,
  handleNewBlock,
  updateMilestoneOrderNoAfterDelete,
  updateMilestoneOrderNumber,
} from '@Shared/utils/utils';
import { useNonInitialEffect } from '@Hooks/useNonInitialEffect';
import { useQuery } from '@tanstack/react-query';
import { Analytics } from '@base/analytics/analytics';
import { type SubstituteDynamicFieldsRequest } from '@api/api.model';
import { useDynamicTagsContext } from '@base/Context/DynamicTagsContext';
import { JourneyBuilderKey } from './queries';
import { handleNewMilestone, handleGetMilestones } from './helper';

export const useJourneyBuilder = () => {
  const navigate = useNavigate();
  const [showBlockBuilder, setShowBlockBuilder] = useState(false);
  const { id } = useParams();
  const location = useLocation();
  const [selectedMilestoneIndex, setSelectedMilestoneIndex] = useState(0);
  const [milestones, setMilestones] = useState<Milestone[]>([]);
  const [filteredMilestones, setFilteredMilestones] = useState<Milestone[]>([]);
  const [showEmbeddedContentModal, setShowEmbeddedContentModal] =
    useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [published, setPublished] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [showSaved, setShowSaved] = useState(false);
  const [showAssignJourneyModal, setShowAssignJourneyModal] = useState(false);
  const [currentJourney, setCurrentJourney] = useState<Journey>({} as Journey);
  const [isAssignee, setIsAssignee] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isCollaborator, setIsCollaborator] = useState(false);
  const [isJourneyNameChanged, setIsJourneyNameChanged] = useState(false);
  const [journeyProgress, setJourneyProgress] = useState(0);
  const [isBusy, setIsBusy] = useState(false);
  const [showRoleAssigneeModal, setShowRoleAssigneeModal] = useState(false);
  const [showCollaboratorModal, setShowCollaboratorModal] = useState(false);
  const [isJourneyLoading, setIsJourneyLoading] = useState(false);
  const [journeyBadge, setJourneyBadge] = useState<Badge | null>(null);
  const [showCompleteJourneyModal, setShowCompleteJourneyModal] =
    useState(false);
  const [userBadges, setUserBadges] = useState<(UserBadge | null)[]>([]);
  const [showEditConfirmationModal, setShowEditConfirmationModal] =
    useState(false);
  const [isContentCustomizationAllowed, setIsContentCustomizationAllowed] =
    useState(false);
  const [currentChapterId, setCurrentChapterId] = useState<string | null>();
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const {
    user,
    setCollaborators,
    setPlaybook,
    setJourneyAssignee,
    isParentJourneyChanged,
    setIsParentJourneyChanged,
  } = useUserContext();
  const { setTagsValues } = useDynamicTagsContext();

  const handleJourneyName = (journeyName: string) => {
    setIsJourneyNameChanged(true);
    currentJourney.name = journeyName;
    setCurrentJourney({ ...currentJourney });
    handleParentJourneyUpdated();
  };

  const addNewBlock = async (blockType: string) => {
    setShowBlockBuilder(false);
    handleParentJourneyUpdated();
    if (blockType === BlockType.EmbeddedContentBlock) {
      setShowEmbeddedContentModal(true);
    } else {
      const updatedMilestone = await handleNewBlock(
        blockType,
        filteredMilestones,
        selectedMilestoneIndex,
      );
      if (updatedMilestone) {
        setFilteredMilestones([...updatedMilestone]);
      }

      const currentProgress = calculateProgress(filteredMilestones);
      if (currentJourney.journeyProgress !== 0) {
        await updateJourney({
          id: currentJourney.id,
          journeyProgress:
            Math.abs(currentProgress) >= 100 ? 100 : Math.abs(currentProgress),
        } as Journey);
      }
    }

    Analytics.playbookMicrositeBlockAdded(blockType);
  };

  const addNewMilestone = async () => {
    if (!id) {
      return;
    }
    setShowLoader(true);
    handleParentJourneyUpdated();
    const updateMilestone = await handleNewMilestone(milestones, id);
    setShowLoader(false);

    if (updateMilestone) {
      setMilestones([...updateMilestone]);
      setCurrentChapterId(updateMilestone[updateMilestone.length - 1].id);
      setShowSaved(true);
    }
  };

  const addEmbeddedContentBlock = async (url: string, isFormLinked = false) => {
    handleParentJourneyUpdated();
    const updateMilestone = await handleAddEmbeddedContentBlock(
      url,
      milestones,
      selectedMilestoneIndex,
      isFormLinked,
    );
    setMilestones([...updateMilestone]);
  };

  const handleMilestoneTextChange = async (text: string) => {
    if (milestones[selectedMilestoneIndex].name !== text) {
      setIsBusy(true);
      milestones[selectedMilestoneIndex].name = text;
      setMilestones([...milestones]);
      setShowLoader(true);
      await handleMilestoneUpdate(milestones[selectedMilestoneIndex]);
      setShowLoader(false);
      setShowSaved(true);
      handleParentJourneyUpdated();
      setIsBusy(false);
      handleParentJourneyUpdated();
    }
  };

  const removeMilestone = async () => {
    if (!milestones.length) {
      return;
    }

    setShowLoader(true);
    const [id] = await Promise.all([
      deleteJourneyMilestone(milestones[selectedMilestoneIndex].id),
      updateMilestoneOrderNoAfterDelete(
        milestones,
        milestones[selectedMilestoneIndex].orderNo,
      ),
    ]);

    if (id) {
      const updatedMilestone = milestones.filter(
        (milestone) => milestone.id !== id,
      );
      if (selectedMilestoneIndex === 0) {
        setSelectedMilestoneIndex(selectedMilestoneIndex);
      } else {
        setSelectedMilestoneIndex(selectedMilestoneIndex - 1);
      }
      setMilestones([...updatedMilestone]);
      deleteMilestoneBlocks(id);
      handleParentJourneyUpdated();
      addNotificationToApp('Milestone deleted successfully', 'success');
    }

    setShowLoader(false);
    setShowSaved(true);
  };

  const archiveUnArchiveChapter = async (archive: boolean) => {
    setShowLoader(true);

    await updateMilestone({
      ...milestones[selectedMilestoneIndex],
      isArchived: archive,
    });
    setMilestones((prevMilestones) => {
      prevMilestones[selectedMilestoneIndex].isArchived = archive;
      return [...prevMilestones];
    });
    addNotificationToApp(
      `${archive ? 'Assignee will no longer have access to this chapter.' : 'Assignee now have access to this chapter again.'}`,
      'success',
    );

    setShowLoader(false);
    setShowSaved(true);
  };

  const { data: childJourneys } = useQuery({
    queryKey: [JourneyBuilderKey.listChildJourneys],
    queryFn: () => listChildJourneys(id ?? ''),
    enabled: !!id,
  });

  const handleBack = useCallback(() => {
    if (isAssignee || !isAdmin) {
      navigate('/');
    } else {
      navigate(`/runbook/${currentJourney.id}`);
    }
  }, [isAssignee, isAdmin, currentJourney.id, navigate]);

  const viewJourney = async () => {
    const currentChapter = filteredMilestones[selectedMilestoneIndex];
    setCurrentChapterId(currentChapter.isArchived ? null : currentChapter.id);
    navigate(`/runbook/${id}/microsite/preview`);
    if (currentJourney.assignedUserID) {
      setIsJourneyLoading(true);
      await handleDynamicTags(
        currentJourney.organizationID,
        currentJourney.id,
        filteredMilestones,
      );
      setIsJourneyLoading(false);
    }
  };

  const exitPreview = () => {
    setCurrentChapterId(filteredMilestones[selectedMilestoneIndex].id);
    navigate(`/runbook/${id}/microsite`);
  };

  const copyPlaybookLink = () => {
    if (!id) {
      return;
    }
    if (currentJourney.publicLink) {
      navigator.clipboard.writeText(currentJourney.publicLink);
    } else {
      const currentURL = window.location.href;
      let link = `${new URL(currentURL).origin}/invite/${Date.now()}`;

      updateJourney({ id, publicLink: link } as Journey);
      navigator.clipboard.writeText(link);
    }
    addNotificationToApp('Link copied to clipboard', 'success');
  };

  const handleUpdateMilestoneBlocks = (block: any) => {
    const updatedMilestone = handleBlocksUpdate(
      filteredMilestones,
      selectedMilestoneIndex,
      block,
    );
    setFilteredMilestones([...updatedMilestone]);
    handleParentJourneyUpdated();
  };

  const updateDeletedBlock = async (
    id: string,
    type: BlockType,
    orderNo: number,
  ) => {
    const updatedMilestone = handleDeleteBlocks(
      filteredMilestones[selectedMilestoneIndex],
      id,
      type,
      orderNo,
    );
    filteredMilestones[selectedMilestoneIndex] = updatedMilestone;
    setFilteredMilestones([...filteredMilestones]);
    setIsBusy(true);
    await updateBlocksOrderNo(updatedMilestone, orderNo);
    setIsBusy(false);
    handleParentJourneyUpdated();
  };

  const setPlaybookCollaborators = async (
    collaborators: JourneyCollaborator[],
  ) => {
    let playbookCollaborators = [];
    for (const collaborator of collaborators) {
      let collaboratorUser;
      if (collaborator.assignedUserID) {
        collaboratorUser = await getUserBasicData(collaborator.assignedUserID);
      }

      playbookCollaborators.push({
        ...collaborator,
        assignedUserName: collaboratorUser?.firstName
          ? `${collaboratorUser.firstName} ${collaboratorUser.lastName ?? ''}`
          : undefined,
        assignedUserEmail: collaboratorUser?.email,
      });
    }

    setCollaborators(playbookCollaborators);
  };

  const getJourney = async (
    id: string,
    organizationId: string,
  ): Promise<Journey | undefined> => {
    setIsJourneyLoading(true);
    setIsParentJourneyChanged(false);
    const [journey, allMilestones, collaborators] = await Promise.all([
      getJourneyData(id, organizationId),
      handleGetMilestones(milestones, id),
      getJourneyCollaborators(id, false),
    ]);

    if (journey && allMilestones && collaborators) {
      const status = getPlaybookStatus(journey);
      setMilestones([...allMilestones].sort(compareMilestones));
      setPlaybookCollaborators(collaborators);
      setPlaybook({ ...journey, status });

      // check if journey has assignee then update context
      if (journey.assignedUserID) {
        const userData = await getUserBasicData(journey.assignedUserID);
        if (userData) {
          setJourneyAssignee(userData);
        }

        await handleDynamicTags(organizationId, id, allMilestones);
      } else {
        setJourneyAssignee(null);
      }

      setJourneyProgress(journey.journeyProgress);
      setCurrentJourney({ ...journey, status });
      setPublished(journey.status !== JourneyStatus.DRAFT);
      setIsAssignee(user.id === journey.assignedUserID);
      setIsContentCustomizationAllowed(!journey.parentJourneyID);
    } else {
      navigate('/');
    }
    setIsJourneyLoading(false);

    return journey;
  };

  const handleDynamicTags = async (
    orgId: string,
    runbookId: string,
    milestones: Milestone[],
  ) => {
    const blockTypes = Object.values(BlockType).map((type) => `${type}s`);
    const texts = milestones.reduce(
      (acc, milestone) => {
        blockTypes.forEach((type) => {
          const blocks = milestone[type as keyof Milestone];
          if (blocks && typeof blocks === 'object' && 'items' in blocks) {
            blocks.items?.forEach((block: any) => {
              if (block?.description) {
                acc[block.id] = block.description;
              }
              if (block?.title) {
                acc[`${block.id}-title`] = block.title;
              }
            });
          }
        });
        return acc;
      },
      {} as { [key: string]: string },
    );

    const request: SubstituteDynamicFieldsRequest = {
      orgId,
      data: {
        context: {
          runbook: runbookId,
        },
        texts,
      },
    };
    const res = await substituteDynamicTags(request);
    if (res) {
      setTagsValues(res.texts);
    }
  };

  useQuery({
    queryKey: [JourneyBuilderKey.currentPlaybook],
    queryFn: () => getJourney(id ?? '', user.userOrganizationId),
    enabled: !!(id && user.userOrganizationId),
  });

  const handleCompleteMilestone = useCallback(
    async (isCompleted: boolean) => {
      if (!currentJourney.id) {
        return;
      }

      filteredMilestones[selectedMilestoneIndex].isCompleted = isCompleted;
      let currentProgress = calculateProgress(filteredMilestones);
      currentProgress = currentProgress > 100 ? 100 : currentProgress;

      setCurrentJourney({
        ...currentJourney,
        journeyProgress: currentProgress,
      });
      setFilteredMilestones([...filteredMilestones]);

      await Promise.all([
        updateJourney({
          id: currentJourney.id,
          journeyProgress: currentProgress,
        } as Journey),
        updateMilestone({
          id: filteredMilestones[selectedMilestoneIndex].id,
          isCompleted,
        } as Milestone),
      ]);
    },
    [currentJourney, filteredMilestones, selectedMilestoneIndex],
  );

  const getPlaybookStatus = (playbook: Journey) => {
    if (playbook.userCompletedAt) {
      return JourneyStatus.COMPLETED;
    }

    if (playbook.userStartedAt) {
      return JourneyStatus.IN_PROGRESS;
    }

    return JourneyStatus.NOT_STARTED;
  };

  const handleReorderMilestones = async (
    source: number,
    destination: number,
    draggedMilestone: Milestone,
  ) => {
    const updatedMilestones = updateMilestoneOrderNumber(
      source,
      destination,
      draggedMilestone,
      milestones,
    );
    setMilestones([...updatedMilestones].sort(compareMilestones));
    let milestonesToBeUpdated;
    if (source < destination) {
      milestonesToBeUpdated = updatedMilestones.slice(source, destination + 1);
    } else {
      milestonesToBeUpdated = updatedMilestones.slice(destination, source + 1);
    }

    setIsBusy(true);
    await Promise.all(
      milestonesToBeUpdated.map((milestone) =>
        updateMilestoneOrder(milestone.id, milestone.orderNo),
      ),
    );

    setIsBusy(false);
    handleParentJourneyUpdated();
  };

  const handleParentJourneyUpdated = () => {
    //check if it is parent journey and has child journeys and context is not updated yet
    if (
      !currentJourney.parentJourneyID &&
      childJourneys &&
      childJourneys.length &&
      !isParentJourneyChanged
    ) {
      setIsParentJourneyChanged(true);
    }
  };

  const startPlaybook = useCallback(async () => {
    setCurrentJourney({ ...currentJourney, status: JourneyStatus.IN_PROGRESS });
    if (currentJourney.type === BuilderType.SELFSERVE) {
      await triggerSelfServePlaybookEvents(currentJourney.id, user.email);
      return;
    }

    await updateJourney({
      id: currentJourney.id,
      status: JourneyStatus.IN_PROGRESS,
      userStartedAt: new Date().toISOString().split('T')[0],
    } as Journey);

    Analytics.playbookStarted(currentJourney.id);
  }, [currentJourney, user.email]);

  const completeJourney = useCallback(async () => {
    // confetti fireworks
    let duration = 5 * 1000;
    let animationEnd = Date.now() + duration;
    let defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

    function randomInRange(min: number, max: number) {
      return Math.random() * (max - min) + min;
    }

    let interval = setInterval(function (): void {
      let timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        return clearInterval(interval);
      }

      let particleCount = 50 * (timeLeft / duration);
      // since particles fall down, start a bit higher than random
      confetti({
        ...defaults,
        particleCount,
        origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
      });
      confetti({
        ...defaults,
        particleCount,
        origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
      });
    }, 250);

    setIsSaving(true);
    let sideEffects = [
      updateJourney({
        id: currentJourney.id,
        journeyProgress: 100,
        userCompletedAt: new Date().toISOString().split('T')[0],
      } as Journey),
    ];

    if (
      currentJourney.parentJourneyID &&
      !userBadges.some(
        (item) => item?.playbookId === currentJourney.parentJourneyID,
      )
    ) {
      const badge = await getPlaybookBadge(currentJourney.parentJourneyID);

      if (badge) {
        setJourneyBadge(badge);
        const newBadge = {
          playbookId: currentJourney.parentJourneyID,
          userID: currentJourney.assignedUserID,
        } as UserBadge;
        sideEffects = [...sideEffects, createUserBadge(newBadge)];
        setUserBadges([...userBadges, newBadge]);
        if (user.UserBadges) {
          user.UserBadges.items.push(newBadge);
        }
      }
    }

    setShowCompleteJourneyModal(true);
    await Promise.all([sideEffects]);
    setCurrentJourney({ ...currentJourney, status: JourneyStatus.COMPLETED });
    setIsSaving(false);

    Analytics.playbookCompleted(currentJourney.id);
  }, [currentJourney, userBadges, user.UserBadges]);

  const isNextChapterEnabled = () => {
    if (
      filteredMilestones.length &&
      currentJourney.lockChapters &&
      !isEditable
    ) {
      return !!filteredMilestones[selectedMilestoneIndex].isCompleted;
    }

    return true;
  };

  const setJourneyCollaborator = async (userId: string, journeyId: string) => {
    const isCollaborator = await isJourneyCollaborator(userId, journeyId);
    setIsCollaborator(isCollaborator || false);
  };
  useNonInitialEffect(() => {
    if (showSaved) {
      setIsSaving(false);
    }
    const timeId = setTimeout(() => {
      setShowSaved(false);
    }, 3000);

    return () => {
      clearTimeout(timeId);
    };
  }, [showSaved]);

  useEffect(() => {
    if (user.id && id) {
      setJourneyCollaborator(user.id, id);
    }
  }, [user, id]);

  useNonInitialEffect(() => {
    if (isJourneyNameChanged) {
      const timeOut = setTimeout(async () => {
        setShowLoader(true);
        await updateJourney(currentJourney);
        setShowLoader(false);
        setShowSaved(true);
        setIsJourneyNameChanged(false);
      }, 2000);
      return () => clearTimeout(timeOut);
    }
  }, [currentJourney.name]);

  useEffect(() => {
    setIsEditable(
      (user.type === UserType.COMPANY_ADMIN ||
        user.type === UserType.SUPER_ADMIN) &&
        !isAssignee &&
        !location.pathname.includes('/preview'),
    );
    setIsAdmin(
      user.type === UserType.COMPANY_ADMIN ||
        user.type === UserType.SUPER_ADMIN,
    );
  }, [location, user, isAssignee]);

  useEffect(() => {
    user && user.UserBadges && setUserBadges(user.UserBadges.items);
  }, [user]);

  useEffect(() => {
    if (currentJourney.status === JourneyStatus.NOT_STARTED && isAssignee) {
      startPlaybook();
    }
  }, [currentJourney, isAssignee, startPlaybook]);

  useEffect(() => {
    // If playbook is in edit mode or it is a parent playbook then always
    // refer to milestones state as we will show all the milestones even if
    // they are hidden.
    if (isEditable || !currentJourney.parentJourneyID) {
      setSelectedMilestoneIndex((prevIndex) => {
        if (currentChapterId) {
          setCurrentChapterId(null);
          return milestones.findIndex(
            (milestone) => milestone.id === currentChapterId,
          );
        }
        return prevIndex;
      });
      setFilteredMilestones([...milestones]);
    } else {
      const filteredMilestones = milestones.filter(
        (milestone) => !milestone.isArchived,
      );
      // If playbook is in preview mode and it has current chapter id that means
      // current selected chapter is not hidden so find that chapter index and
      // make it selected otherwise select first chapter because it means hidden
      // chapter was selected in edit mode and we don't show hidden chapter in preview mode.
      if (location.pathname.includes('/preview')) {
        setSelectedMilestoneIndex(() => {
          if (currentChapterId) {
            return filteredMilestones.findIndex(
              (milestone) => milestone.id === currentChapterId,
            );
          }
          return 0;
        });
      }

      setFilteredMilestones([...filteredMilestones]);
    }
  }, [
    currentChapterId,
    currentJourney.parentJourneyID,
    isEditable,
    location.pathname,
    milestones,
  ]);

  // responsible for completing/uncompleting chapters
  useEffect(() => {
    if (!isAssignee || !filteredMilestones[selectedMilestoneIndex]) {
      return;
    }

    const status = checkMilestoneStatus(
      filteredMilestones[selectedMilestoneIndex],
    );
    if (filteredMilestones[selectedMilestoneIndex].isCompleted !== status) {
      handleCompleteMilestone(status);
    }
  }, [
    isAssignee,
    filteredMilestones,
    selectedMilestoneIndex,
    handleCompleteMilestone,
  ]);

  /**
   * Check if all chapters are completed
   */
  const checkAllMilestoneCompleted = useMemo(
    () => filteredMilestones.every((chapter) => chapter.isCompleted),
    [filteredMilestones],
  );

  return {
    currentJourney,
    isEditable,
    showBlockBuilder,
    milestones,
    selectedMilestoneIndex,
    showEmbeddedContentModal,
    published,
    showLoader,
    showSaved,
    showAssignJourneyModal,
    id,
    isAssignee,
    isAdmin,
    isCollaborator,
    journeyProgress,
    isBusy,
    showRoleAssigneeModal,
    showCollaboratorModal,
    isSaving,
    isJourneyLoading,
    journeyBadge,
    showCompleteJourneyModal,
    childJourneys,
    showEditConfirmationModal,
    isContentCustomizationAllowed,
    filteredMilestones,
    showSettingsModal,
    location,
    setShowSettingsModal,
    setShowEditConfirmationModal,
    setIsContentCustomizationAllowed,
    setShowCompleteJourneyModal,
    viewJourney,
    exitPreview,
    setIsBusy,
    setShowBlockBuilder,
    setSelectedMilestoneIndex,
    setShowLoader,
    setShowSaved,
    setShowAssignJourneyModal,
    setIsSaving,
    addNewBlock,
    handleMilestoneTextChange,
    addNewMilestone,
    removeMilestone,
    setShowEmbeddedContentModal,
    addEmbeddedContentBlock,
    handleBack,
    handleUpdateMilestoneBlocks,
    updateDeletedBlock,
    handleJourneyName,
    setFilteredMilestones,
    handleReorderMilestones,
    setShowRoleAssigneeModal,
    setShowCollaboratorModal,
    completeJourney,
    isNextChapterEnabled,
    archiveUnArchiveChapter,
    setCurrentJourney,
    copyPlaybookLink,
    checkAllMilestoneCompleted,
  };
};
