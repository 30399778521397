import { useState } from 'react';
import { createActionCollaborator, createCopyJourney } from '@api/apis';
import { type Journey, JourneyStatus } from '@API';

export const useCopyPlaybookModal = (
  handleShowModal: Function,
  journey: Journey,
  handleUpdateJourneys: Function,
) => {
  const [journeyName, setJourneyName] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const copyJourney = async () => {
    setShowLoader(true);
    setIsSubmitted(true);
    if (journeyName.length > 0) {
      const id = await createCopyJourney(journeyName, journey.id);
      if (id) {
        //assign collaborators to the journey's action if they have any
        createActionCollaborator([id]);
        handleUpdateJourneys({
          id: id,
          name: journeyName,
          status: JourneyStatus.DRAFT,
          updatedAt: new Date().toDateString(),
        } as Journey);
      }
      handleShowModal(false);
    }
    setShowLoader(false);
  };

  const handleHideModal = () => {
    handleShowModal(false);
    setIsSubmitted(false);
    setJourneyName('');
  };

  return {
    journeyName,
    showLoader,
    isSubmitted,
    setJourneyName,
    handleHideModal,
    copyJourney,
  };
};
