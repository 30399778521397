import clsx from 'clsx';
import {
  Button,
  TriggerAction,
  SwitchButton,
  FormSubmitted,
  LogoIcon,
} from '@Shared/DS';
import { useNavigate } from 'react-router-dom';
import { Device } from '@base/models/common.model';
import { useIsInIframe } from '@base/Hooks/useInIframe';
import { useSearch } from '@base/Hooks/useSearch';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { useMemo } from 'react';
import { EditableContent } from '../EditableContent/EditableContent';
import Loader from '../Loader/Loader';
import { useForm } from './useForm';
import { AddElement } from './AddElement';
import { renderFormElements } from './helper';
import './Form.scss';

interface FormProps {
  formId?: string;
  formSlug?: string;
  isEmbedded?: boolean;
  canSwitchEditAndPreview?: boolean;
  canGoBackToForms?: boolean;
}

export const Form = ({
  formId,
  formSlug,
  canSwitchEditAndPreview = false,
  isEmbedded: isEmbeddedProp = false,
  canGoBackToForms = true,
}: FormProps) => {
  const { user } = useUserContext();
  const {
    state,
    form,
    isLoading,
    isMainForm,
    handleFormNameChange,
    handleInputChange,
    handleLabelChange,
    handleTextChange,
    handleAddElement,
    handleToggleRequired,
    handleRadioButtonRange,
    handleRadioButtonRangeLabels,
    handleOptionLabelChange,
    handleOptionAdd,
    handleOptionRemove,
    handleSubmit,
    handleDeleteElement,
    setState,
    handleSearchBarInput,
  } = useForm(formId, formSlug);
  const navigate = useNavigate();
  const isInIframe = useIsInIframe();
  const { users, findUser } = useSearch(form?.organizationId);
  const organizationLogo = useMemo(
    () =>
      [
        {
          id: 'bea98cc2-2075-4fb5-b25c-73191615fad9',
          logo: 'https://kin-assets.s3.eu-west-1.amazonaws.com/team17-logo.svg',
        },
        {
          id: '91e7d5ae-3884-4c18-a9b0-ef29a9fec561',
          logo: 'https://kin-assets.s3.eu-west-1.amazonaws.com/Creative-Fabrica-logo.png',
        },
        {
          id: 'b5f5fd1e-2805-49d5-8b5b-887dd5897227',
          logo: 'https://kin-assets.s3.eu-west-1.amazonaws.com/Birdie-logo.png',
        },
        {
          id: 'org_UG4mWwi0B0VpeJu18M9Js',
          logo: 'https://kin-assets.s3.eu-west-1.amazonaws.com/correla_logo.webp',
        },
        {
          id: 'c5e2150d-500c-4eac-91d9-f0f6bed95ae2',
          logo: 'https://kin-assets.s3.eu-west-1.amazonaws.com/rora-logo.png',
        },
        {
          id: 'org_UJGu6ko0LxSu62KGXpePk',
          logo: 'https://kin-assets.s3.eu-west-1.amazonaws.com/vividimpact-logo.png',
        },
        {
          id: 'd9ba16a7-12c8-4638-b78c-103e26506d8e',
          logo: 'https://kin-assets.s3.eu-west-1.amazonaws.com/vidyard_logo.png',
        },
      ].find((organization) => organization.id === user.userOrganizationId),
    [user],
  );

  const isEmbedded = isInIframe || isEmbeddedProp;

  return (
    <div
      className={clsx('form-container', {
        'form-container-embedded': isEmbedded,
        'form-container-editing': state.isInEditingMode,
      })}
    >
      {isMainForm && !isEmbedded && (
        <div className="device-controllers d-flex justify-content-between align-items-center">
          {canGoBackToForms && (
            <Button
              text="Back to Forms"
              buttonStyle="outline"
              icon="bi bi-arrow-left"
              onClick={() => {
                navigate('/forms');
              }}
            />
          )}
          <div className="d-flex flex-nowrap ms-auto">
            {!state.isInEditingMode && (
              <SwitchButton
                selected={state.device}
                textOn={Device.DESKTOP}
                textOnIcon="bi bi-display"
                textOff={Device.MOBILE}
                textOffIcon="bi bi-phone"
                handleToggle={(value) =>
                  setState({ ...state, device: value as Device })
                }
              />
            )}
            {canSwitchEditAndPreview && (
              <>
                {!state.isInEditingMode ? (
                  <Button
                    additionalClasses="ms-2"
                    text="Edit"
                    buttonStyle="tertiary"
                    icon="bi bi-pencil-square"
                    onClick={() =>
                      setState({ ...state, isInEditingMode: true })
                    }
                  />
                ) : (
                  <Button
                    text="Preview"
                    buttonStyle="tertiary"
                    icon="bi bi-eye"
                    onClick={() =>
                      setState({ ...state, isInEditingMode: false })
                    }
                  />
                )}
              </>
            )}
          </div>
        </div>
      )}

      {!isEmbedded && (
        <div className="header d-flex align-items-center justify-content-between">
          {!!organizationLogo ? (
            <img
              src={organizationLogo.logo}
              className="img-fluid"
              style={{ width: '100px' }}
              alt="company-logo"
            />
          ) : (
            <LogoIcon className="img-fluid" height={'24'} width={'136.426'} />
          )}
          <div className="w-100 d-flex justify-content-center">
            <EditableContent
              className="heading"
              value={state.formName ?? form?.name ?? ''}
              setValue={(value: string) => handleFormNameChange(value)}
              isEditable={state.isInEditingMode}
            />
          </div>
        </div>
      )}

      {isLoading ? (
        <Loader active={isLoading} styling="relative" />
      ) : !form?.submission && !state.isFormSubmitted ? (
        <div
          className={clsx('row', {
            'justify-content-center mx-3 g-0': !isEmbedded,
          })}
        >
          <div
            className={`mt-3 ${state.device === Device.MOBILE ? 'mobile-container' : 'col-12 col-sm-12 col-md-9 col-lg-8'}`}
          >
            <div
              className={`${state.device === Device.MOBILE ? 'screen' : ''}`}
            >
              <form className="content" id={form?.id} onSubmit={handleSubmit}>
                {renderFormElements(
                  state.formElements,
                  state.isInEditingMode,
                  users,
                  state.uploadProgress,
                  handleInputChange,
                  handleLabelChange,
                  handleTextChange,
                  handleToggleRequired,
                  handleRadioButtonRange,
                  handleRadioButtonRangeLabels,
                  handleOptionAdd,
                  handleOptionRemove,
                  handleOptionLabelChange,
                  handleDeleteElement,
                  findUser,
                  handleSearchBarInput,
                )}
                {state.isInEditingMode && (
                  <AddElement className="mt-4" onSelect={handleAddElement} />
                )}
              </form>

              {!isMainForm && (
                <div className="d-flex justify-content-center">
                  <Button
                    additionalClasses="mt-2 mb-5 w-25"
                    disabled={!!isMainForm || state.isFormSubmitting}
                    buttonStyle="purple"
                    showLoading={state.isFormSubmitting}
                    text="Submit"
                    type="submit"
                    form={form?.id}
                  />
                </div>
              )}
            </div>

            {isMainForm && !isEmbedded && (
              <TriggerAction
                className="form-action my-5"
                isEditable={state.isInEditingMode}
                form={form}
              />
            )}
          </div>
        </div>
      ) : (
        <FormSubmitted />
      )}
    </div>
  );
};
