import { UserType } from '@API';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { memo, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { LiaEdit } from 'react-icons/lia';
import { useFeatureFlags } from '@Hooks/useFeatureFlags';
import {
  HomeIcon,
  JourneysIcon,
  PeopleIcon,
  SettingsIcon,
  TemplatesIcon,
} from '../../Atoms';

export const NavbarMenu = memo(() => {
  const { user } = useUserContext();
  const isCompanyMember = useMemo(
    () => user?.type === UserType.COMPANY_MEMBER,
    [user],
  );
  const isSuperAdmin = useMemo(
    () => user?.type === UserType.SUPER_ADMIN,
    [user],
  );
  const isCandidate = useMemo(() => user?.type === UserType.CANDIDATE, [user]);
  const featureFlags = useFeatureFlags();

  return (
    <nav id="sidebarMenu" className="navbar pt-5">
      <button
        className="navbar-toggler position-absolute d-md-none collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#sidebarMenu"
        aria-controls="sidebarMenu"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <ul className="navbar-nav">
        <li className="nav-item">
          <NavLink className="nav-link text-nowrap" to="/">
            <HomeIcon />
            <span>Home</span>
          </NavLink>
        </li>
        {!isCompanyMember && !isCandidate ? (
          <li className="nav-item">
            <NavLink className="nav-link text-nowrap" to="/runbooks">
              <JourneysIcon />
              <span>Runbooks</span>
            </NavLink>
          </li>
        ) : null}

        {featureFlags.FORMS && !isCompanyMember && !isCandidate ? (
          <li className="nav-item">
            <NavLink className="nav-link text-nowrap" to="/forms">
              <LiaEdit size="20" color="#6b7380" />
              <span>Forms</span>
            </NavLink>
          </li>
        ) : null}

        {isSuperAdmin && (
          <li className="nav-item">
            <NavLink className="nav-link text-nowrap" to="/templates">
              <TemplatesIcon />
              <span>Templates</span>
            </NavLink>
          </li>
        )}

        <li className="nav-item">
          <NavLink className="nav-link text-nowrap" to="/people">
            <PeopleIcon />
            <span>People</span>
          </NavLink>
        </li>

        <li className="nav-item">
          {!isCompanyMember && !isCandidate && (
            <NavLink className="nav-link" to="/settings">
              <SettingsIcon />
              <span>Settings</span>
            </NavLink>
          )}
        </li>
      </ul>
    </nav>
  );
});
