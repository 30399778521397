import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { type User, UserType } from '@API';
import {
  downloadProductUsageStats,
  downloadFormsReport as downloadFormsReportApi,
  listOrganizationAdmins,
} from '@api/apis';
import { type AdminWithOrganization } from '@models/settings.model';

export const useGeneralTab = () => {
  const { user } = useUserContext();
  const [adminWithOrganization, setOrganizationsWithAdmin] = useState<
    AdminWithOrganization[]
  >([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isFormReportDownloading, setIsFormReportDownloading] = useState(false);

  const getAdminWithOrganization = async (): Promise<
    AdminWithOrganization[]
  > => {
    const admins = await listOrganizationAdmins(UserType.COMPANY_ADMIN);
    if (admins) {
      const adminsWithOrganizations = admins.map((admin: User) => ({
        name: admin.Organization.name,
        email: admin.email,
      }));
      return adminsWithOrganizations;
    }

    return [] as AdminWithOrganization[];
  };
  const { data: organizationAdmins } = useQuery({
    queryKey: ['getAdminWithOrganization'],
    queryFn: getAdminWithOrganization,
    enabled: !!user.id,
  });

  const downloadUsageReport = async () => {
    setIsDownloading(true);
    await downloadProductUsageStats();
    setIsDownloading(false);
  };

  const downloadFormsReport = async () => {
    setIsFormReportDownloading(true);
    await downloadFormsReportApi(user.userOrganizationId);
    setIsFormReportDownloading(false);
  };

  useEffect(() => {
    if (organizationAdmins) {
      setOrganizationsWithAdmin(organizationAdmins);
    }
  }, [organizationAdmins]);

  const addOrganization = () => {
    setOrganizationsWithAdmin(
      (organizations: { name: string; email: string }[]) => [
        ...organizations,
        {
          name: '',
          email: '',
        },
      ],
    );
  };

  return {
    adminWithOrganization,
    isDownloading,
    isFormReportDownloading,
    addOrganization,
    downloadUsageReport,
    downloadFormsReport,
  };
};
