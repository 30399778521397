/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  offset?: ModelFloatInput | null,
  firstName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  sendWelcomeEmail?: ModelBooleanInput | null,
  personalEmail?: ModelStringInput | null,
  jobTitle?: ModelStringInput | null,
  birthday?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  homeLocation?: ModelStringInput | null,
  team?: ModelStringInput | null,
  photoUrl?: ModelStringInput | null,
  pronoun?: ModelPronounInput | null,
  aboutMe?: ModelStringInput | null,
  mostHappy?: ModelStringInput | null,
  socialMediaUrl?: ModelStringInput | null,
  slackUserId?: ModelStringInput | null,
  isFirstLogin?: ModelBooleanInput | null,
  isHomeModelViewed?: ModelBooleanInput | null,
  isProfileModelViewed?: ModelBooleanInput | null,
  type?: ModelUserTypeInput | null,
  isActive?: ModelBooleanInput | null,
  lastName?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
  userOrganizationId?: ModelIDInput | null,
  userWorkPreferenceId?: ModelIDInput | null,
  userWorkStyleId?: ModelIDInput | null,
  userWorkLocationId?: ModelIDInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelPronounInput = {
  eq?: Pronoun | null,
  ne?: Pronoun | null,
};

export enum Pronoun {
  HE = "HE",
  SHE = "SHE",
  THEY = "THEY",
  NOT_SELECTED = "NOT_SELECTED",
}


export type ModelUserTypeInput = {
  eq?: UserType | null,
  ne?: UserType | null,
};

export enum UserType {
  SUPER_ADMIN = "SUPER_ADMIN",
  COMPANY_ADMIN = "COMPANY_ADMIN",
  COMPANY_MEMBER = "COMPANY_MEMBER",
  ANONYMOUS = "ANONYMOUS",
  CANDIDATE = "CANDIDATE",
}


export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
};

export type User = {
  __typename: "User",
  id: string,
  offset: number,
  firstName?: string | null,
  email: string,
  phone?: string | null,
  sendWelcomeEmail?: boolean | null,
  personalEmail?: string | null,
  jobTitle?: string | null,
  birthday: string,
  startDate?: string | null,
  homeLocation?: string | null,
  team?: string | null,
  photoUrl: string,
  pronoun: Pronoun,
  aboutMe?: string | null,
  mostHappy?: string | null,
  socialMediaUrl?: string | null,
  slackUserId?: string | null,
  isFirstLogin?: boolean | null,
  isHomeModelViewed?: boolean | null,
  isProfileModelViewed?: boolean | null,
  type: UserType,
  isActive?: boolean | null,
  Organization: Organization,
  Interests?: ModelUserInterestConnection | null,
  WorkPreference: WorkPreference,
  WorkStyle: WorkStyle,
  lastName?: string | null,
  ShareInfoOn?: ModelUserShareInfoConnection | null,
  LearnMoreOn?: ModelLearnMoreInfoConnection | null,
  WorkLocation?: Location | null,
  Journeys?: ModelJourneyConnection | null,
  Meetings?: ModelMeetingConnection | null,
  PreboardingUsers?: ModelPreboardingUserConnection | null,
  PreboardingUserRelations?: ModelPreboardingUserRelationConnection | null,
  UserBadges?: ModelUserBadgeConnection | null,
  createdAt: string,
  updatedAt: string,
  userOrganizationId: string,
  userWorkPreferenceId: string,
  userWorkStyleId: string,
  userWorkLocationId?: string | null,
};

export type Organization = {
  __typename: "Organization",
  id: string,
  name: string,
  slackAccessToken?: string | null,
  mergeCompanyToken?: string | null,
  atsToken?: string | null,
  atsAccountId?: string | null,
  hrisToken?: string | null,
  hrisAccountId?: string | null,
  hrisIntegratedAt?: string | null,
  slackUserAccessToken?: string | null,
  outlookTenantId?: string | null,
  msTeamsTenantId?: string | null,
  Locations?: ModelLocationConnection | null,
  Interests?: ModelInterestConnection | null,
  ShareInfos?: ModelShareInfoConnection | null,
  status: OrganizationStatus,
  activeUntil: string,
  hasSubscription?: boolean | null,
  preboarding?: boolean | null,
  Journeys?: ModelJourneyConnection | null,
  features?: string | null,
  globalDataStores?: string | null,
  settings?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelLocationConnection = {
  __typename: "ModelLocationConnection",
  items:  Array<Location | null >,
  nextToken?: string | null,
};

export type Location = {
  __typename: "Location",
  id: string,
  name?: string | null,
  streetAddress?: string | null,
  city?: string | null,
  state?: string | null,
  zipCode?: number | null,
  country?: string | null,
  locationType?: string | null,
  organizationID: string,
  createdAt: string,
  updatedAt: string,
};

export type ModelInterestConnection = {
  __typename: "ModelInterestConnection",
  items:  Array<Interest | null >,
  nextToken?: string | null,
};

export type Interest = {
  __typename: "Interest",
  id: string,
  name: string,
  organizationID: string,
  users?: ModelUserInterestConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelUserInterestConnection = {
  __typename: "ModelUserInterestConnection",
  items:  Array<UserInterest | null >,
  nextToken?: string | null,
};

export type UserInterest = {
  __typename: "UserInterest",
  id: string,
  interestID: string,
  userID: string,
  interest: Interest,
  user: User,
  createdAt: string,
  updatedAt: string,
};

export type ModelShareInfoConnection = {
  __typename: "ModelShareInfoConnection",
  items:  Array<ShareInfo | null >,
  nextToken?: string | null,
};

export type ShareInfo = {
  __typename: "ShareInfo",
  id: string,
  name: string,
  organizationID: string,
  shareInfoOnUsers?: ModelUserShareInfoConnection | null,
  learnInfoOnUsers?: ModelLearnMoreInfoConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelUserShareInfoConnection = {
  __typename: "ModelUserShareInfoConnection",
  items:  Array<UserShareInfo | null >,
  nextToken?: string | null,
};

export type UserShareInfo = {
  __typename: "UserShareInfo",
  id: string,
  shareInfoID: string,
  userID: string,
  shareInfo: ShareInfo,
  user: User,
  createdAt: string,
  updatedAt: string,
};

export type ModelLearnMoreInfoConnection = {
  __typename: "ModelLearnMoreInfoConnection",
  items:  Array<LearnMoreInfo | null >,
  nextToken?: string | null,
};

export type LearnMoreInfo = {
  __typename: "LearnMoreInfo",
  id: string,
  shareInfoID: string,
  userID: string,
  shareInfo: ShareInfo,
  user: User,
  createdAt: string,
  updatedAt: string,
};

export enum OrganizationStatus {
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  INITIAL = "INITIAL",
}


export type ModelJourneyConnection = {
  __typename: "ModelJourneyConnection",
  items:  Array<Journey | null >,
  nextToken?: string | null,
};

export type Journey = {
  __typename: "Journey",
  id: string,
  name: string,
  createdByUserID: string,
  assignedUserID?: string | null,
  mode: PlaybookMode,
  startDate?: string | null,
  journeyProgress: number,
  parentJourneyID?: string | null,
  collaborator?: Array< string | null > | null,
  status: JourneyStatus,
  badgeID?: string | null,
  customBadgeName?: string | null,
  lockChapters?: boolean | null,
  type: BuilderType,
  publicLink?: string | null,
  eventStartedAt?: string | null,
  userStartedAt?: string | null,
  userCompletedAt?: string | null,
  isArchived?: boolean | null,
  preboarding?: boolean | null,
  organizationID: string,
  Milestone?: ModelMilestoneConnection | null,
  Meetings?: ModelMeetingConnection | null,
  JourneyCollaborator?: ModelJourneyCollaboratorConnection | null,
  events?: ModelEventConnection | null,
  image?: string | null,
  createdAt: string,
  updatedAt: string,
};

export enum PlaybookMode {
  IMMERSIVE = "IMMERSIVE",
  WORKFLOW = "WORKFLOW",
}


export enum JourneyStatus {
  DRAFT = "DRAFT",
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
}


export enum BuilderType {
  ONBOARDING = "ONBOARDING",
  INITIATIVE = "INITIATIVE",
  SELFSERVE = "SELFSERVE",
}


export type ModelMilestoneConnection = {
  __typename: "ModelMilestoneConnection",
  items:  Array<Milestone | null >,
  nextToken?: string | null,
};

export type Milestone = {
  __typename: "Milestone",
  id: string,
  name: string,
  kinfolkTemplateID?: string | null,
  isCompleted?: boolean | null,
  isArchived?: boolean | null,
  journeyID?: string | null,
  orderNo: number,
  parentMilestoneID?: string | null,
  EmbeddedContentBlocks?: ModelEmbeddedContentBlockConnection | null,
  FreeTextQuestionBlocks?: ModelFreeTextQuestionBlockConnection | null,
  NoteBlocks?: ModelNoteBlockConnection | null,
  TaskBlocks?: ModelTaskBlockConnection | null,
  StandardContentBlocks?: ModelStandardContentBlockConnection | null,
  MultipleChoiceQuestionBlocks?: ModelMultipleChoiceQuestionBlockConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelEmbeddedContentBlockConnection = {
  __typename: "ModelEmbeddedContentBlockConnection",
  items:  Array<EmbeddedContentBlock | null >,
  nextToken?: string | null,
};

export type EmbeddedContentBlock = {
  __typename: "EmbeddedContentBlock",
  id: string,
  type: BlockType,
  milestoneID: string,
  title: string,
  description?: string | null,
  resourceLink: string,
  isFormLinked?: boolean | null,
  orderNo: number,
  parentEmbeddedContentBlockID?: string | null,
  isCompleted?: boolean | null,
  isArchived?: boolean | null,
  updatedInChild?: boolean | null,
  createdAt: string,
  updatedAt: string,
};

export enum BlockType {
  EmbeddedContentBlock = "EmbeddedContentBlock",
  FreeTextQuestionBlock = "FreeTextQuestionBlock",
  NoteBlock = "NoteBlock",
  TaskBlock = "TaskBlock",
  StandardContentBlock = "StandardContentBlock",
  MultipleChoiceQuestionBlock = "MultipleChoiceQuestionBlock",
}


export type ModelFreeTextQuestionBlockConnection = {
  __typename: "ModelFreeTextQuestionBlockConnection",
  items:  Array<FreeTextQuestionBlock | null >,
  nextToken?: string | null,
};

export type FreeTextQuestionBlock = {
  __typename: "FreeTextQuestionBlock",
  id: string,
  type: BlockType,
  milestoneID: string,
  title: string,
  description?: string | null,
  text?: string | null,
  orderNo: number,
  parentFreeTextQuestionBlockID?: string | null,
  isCompleted?: boolean | null,
  isArchived?: boolean | null,
  updatedInChild?: boolean | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelNoteBlockConnection = {
  __typename: "ModelNoteBlockConnection",
  items:  Array<NoteBlock | null >,
  nextToken?: string | null,
};

export type NoteBlock = {
  __typename: "NoteBlock",
  id: string,
  type: BlockType,
  milestoneID: string,
  title: string,
  description?: string | null,
  text?: string | null,
  orderNo: number,
  parentNoteBlockID?: string | null,
  isCompleted?: boolean | null,
  isPrivate?: boolean | null,
  isArchived?: boolean | null,
  updatedInChild?: boolean | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelTaskBlockConnection = {
  __typename: "ModelTaskBlockConnection",
  items:  Array<TaskBlock | null >,
  nextToken?: string | null,
};

export type TaskBlock = {
  __typename: "TaskBlock",
  id: string,
  type: BlockType,
  milestoneID: string,
  title: string,
  description?: string | null,
  status?: boolean | null,
  orderNo: number,
  parentTaskBlockID?: string | null,
  isCompleted?: boolean | null,
  isArchived?: boolean | null,
  updatedInChild?: boolean | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelStandardContentBlockConnection = {
  __typename: "ModelStandardContentBlockConnection",
  items:  Array<StandardContentBlock | null >,
  nextToken?: string | null,
};

export type StandardContentBlock = {
  __typename: "StandardContentBlock",
  id: string,
  type: BlockType,
  milestoneID: string,
  title: string,
  description?: string | null,
  orderNo: number,
  parentStandardContentBlockID?: string | null,
  isCompleted?: boolean | null,
  isArchived?: boolean | null,
  updatedInChild?: boolean | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelMultipleChoiceQuestionBlockConnection = {
  __typename: "ModelMultipleChoiceQuestionBlockConnection",
  items:  Array<MultipleChoiceQuestionBlock | null >,
  nextToken?: string | null,
};

export type MultipleChoiceQuestionBlock = {
  __typename: "MultipleChoiceQuestionBlock",
  id: string,
  type: BlockType,
  milestoneID: string,
  title: string,
  description?: string | null,
  option?: Array< string > | null,
  answer?: Array< string > | null,
  orderNo: number,
  parentMultipleChoiceQuestionBlockID?: string | null,
  isCompleted?: boolean | null,
  isRequired?: boolean | null,
  isArchived?: boolean | null,
  updatedInChild?: boolean | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelMeetingConnection = {
  __typename: "ModelMeetingConnection",
  items:  Array<Meeting | null >,
  nextToken?: string | null,
};

export type Meeting = {
  __typename: "Meeting",
  id: string,
  kinfolkTemplateID?: string | null,
  journeyID?: string | null,
  organizedByUserID?: string | null,
  status: MeetingStatus,
  title: string,
  description?: string | null,
  attendeesEmail?: Array< string | null > | null,
  startTime?: string | null,
  endTime?: string | null,
  parentMeetingID?: string | null,
  updatedInChild?: boolean | null,
  eventDate?: string | null,
  duration: string,
  link?: string | null,
  assigneeRole?: AssigneeRole | null,
  createdAt: string,
  updatedAt: string,
};

export enum MeetingStatus {
  DRAFT = "DRAFT",
  NOT_AVAILABLE = "NOT_AVAILABLE",
  SCHEDULED = "SCHEDULED",
  ARCHIVED = "ARCHIVED",
}


export enum AssigneeRole {
  HROPS = "HROPS",
  HRBP = "HRBP",
  MANAGER = "MANAGER",
  IT = "IT",
  BUDDY = "BUDDY",
  LEGAL = "LEGAL",
}


export type ModelJourneyCollaboratorConnection = {
  __typename: "ModelJourneyCollaboratorConnection",
  items:  Array<JourneyCollaborator | null >,
  nextToken?: string | null,
};

export type JourneyCollaborator = {
  __typename: "JourneyCollaborator",
  id: string,
  assigneeRole: AssigneeRole,
  journeyAccess?: JourneyAccess | null,
  assignedUserID?: string | null,
  actionID?: Array< string | null > | null,
  meetingID?: Array< string | null > | null,
  updatedInChild?: boolean | null,
  journeyID?: string | null,
  kinfolkTemplateID?: string | null,
  createdAt: string,
  updatedAt: string,
};

export enum JourneyAccess {
  VIEW_ONLY = "VIEW_ONLY",
  NO_ACCESS = "NO_ACCESS",
  CAN_EDIT = "CAN_EDIT",
}


export type ModelEventConnection = {
  __typename: "ModelEventConnection",
  items:  Array<Event | null >,
  nextToken?: string | null,
};

export type Event = {
  __typename: "Event",
  id: string,
  userId?: string | null,
  formId?: string | null,
  formInstanceId?: string | null,
  daysFrom: number,
  daysFromDueDate?: number | null,
  type: string,
  role?: AssigneeRole | null,
  emailTo?: string | null,
  title?: string | null,
  message?: string | null,
  sendTime?: string | null,
  sendTimeDate?: string | null,
  dueDate?: string | null,
  status: EventStatus,
  channelId?: string | null,
  parentId?: string | null,
  channel: Channel,
  cc?: Array< string | null > | null,
  archived?: boolean | null,
  updatedInChild?: boolean | null,
  journeyID?: string | null,
  kinfolkTemplateID?: string | null,
  reminders?: ModelEventReminderConnection | null,
  meta?: string | null,
  createdAt: string,
  updatedAt: string,
};

export enum EventStatus {
  DRAFT = "DRAFT",
  SCHEDULED = "SCHEDULED",
  SENT = "SENT",
  DONE = "DONE",
  SKIPPED = "SKIPPED",
  FAILED = "FAILED",
  OVERDUE = "OVERDUE",
}


export enum Channel {
  EMAIL = "EMAIL",
  SLACK = "SLACK",
  TEAMS = "TEAMS",
}


export type ModelEventReminderConnection = {
  __typename: "ModelEventReminderConnection",
  items:  Array<EventReminder | null >,
  nextToken?: string | null,
};

export type EventReminder = {
  __typename: "EventReminder",
  id: string,
  eventId: string,
  sendTimeDate?: string | null,
  daysFrom: number,
  status: EventStatus,
  createdAt: string,
  updatedAt: string,
};

export type WorkPreference = {
  __typename: "WorkPreference",
  id: string,
  giveMeAllTheContext: number,
  messageTimingPreferene: number,
  syncxVsAsync: number,
  talkTogetherVsAlone: number,
  recognitionPrivateVsPrivate: number,
  createdAt: string,
  updatedAt: string,
};

export type WorkStyle = {
  __typename: "WorkStyle",
  id: string,
  whatIDoAtWork?: string | null,
  myFocusTimeIs?: string | null,
  giveReceivingFeedback?: string | null,
  digestionInformation?: string | null,
  inMyElement?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelPreboardingUserConnection = {
  __typename: "ModelPreboardingUserConnection",
  items:  Array<PreboardingUser | null >,
  nextToken?: string | null,
};

export type PreboardingUser = {
  __typename: "PreboardingUser",
  id: string,
  userId: string,
  isSubmitted?: boolean | null,
  consent?: boolean | null,
  journeyId?: string | null,
  personalEmail?: string | null,
  photoUrl?: string | null,
  bankName?: string | null,
  bankBranch?: string | null,
  bankSortCode?: string | null,
  bankAccountNumber?: string | null,
  bankAccountName?: string | null,
  buildingSocietyRoll?: string | null,
  street1?: string | null,
  street2?: string | null,
  city?: string | null,
  state?: string | null,
  zip?: string | null,
  country?: string | null,
  birthday?: string | null,
  gender?: string | null,
  title?: string | null,
  nationality?: string | null,
  mobileNumber?: string | null,
  niNumber?: string | null,
  laptopPreference?: LaptopPreference | null,
  secondMonitor?: boolean | null,
  corporateCard?: boolean | null,
  corporatePhone?: PhonePreference | null,
  healthCare?: boolean | null,
  dental?: boolean | null,
  optical?: boolean | null,
  jobDeclaration?: Declaration | null,
  expatDeclaration?: Declaration | null,
  studentLoanRepayment?: boolean | null,
  studentLoanType?: StudentLoanType | null,
  pensionScheme?: boolean | null,
  pensionContribution?: number | null,
  passportNumber?: string | null,
  passportIssueDate?: string | null,
  passportExpiryDate?: string | null,
  passportIssuingCountry?: string | null,
  confirmation?: boolean | null,
  pensionSchemeOpt?: string | null,
  emergencyContactName?: string | null,
  emergencyContactRelationship?: string | null,
  emergencyContactPhone?: string | null,
  emergencyContactEmailAddress?: string | null,
  emergencyContactStreet1?: string | null,
  emergencyContactStreet2?: string | null,
  emergencyContactCity?: string | null,
  emergencyContactState?: string | null,
  emergencyContactZip?: string | null,
  emergencyContactCountry?: string | null,
  PreboardingUserRelations?: ModelPreboardingUserRelationConnection | null,
  createdAt: string,
  updatedAt: string,
};

export enum LaptopPreference {
  WINDOWS = "WINDOWS",
  MAC = "MAC",
  OTHER = "OTHER",
}


export enum PhonePreference {
  APPLE = "APPLE",
  ANDROID = "ANDROID",
}


export enum Declaration {
  STATEMENT_A = "STATEMENT_A",
  STATEMENT_B = "STATEMENT_B",
  STATEMENT_C = "STATEMENT_C",
}


export enum StudentLoanType {
  PLAN_1 = "PLAN_1",
  PLAN_2 = "PLAN_2",
  PLAN_4 = "PLAN_4",
  POST_GRADUATE_LOAN = "POST_GRADUATE_LOAN",
}


export type ModelPreboardingUserRelationConnection = {
  __typename: "ModelPreboardingUserRelationConnection",
  items:  Array<PreboardingUserRelation | null >,
  nextToken?: string | null,
};

export type PreboardingUserRelation = {
  __typename: "PreboardingUserRelation",
  id: string,
  firstName?: string | null,
  lastName?: string | null,
  relationshipToMember?: string | null,
  birthday?: string | null,
  userId: string,
  formId: string,
  healthCare?: boolean | null,
  dental?: boolean | null,
  optical?: boolean | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelUserBadgeConnection = {
  __typename: "ModelUserBadgeConnection",
  items:  Array<UserBadge | null >,
  nextToken?: string | null,
};

export type UserBadge = {
  __typename: "UserBadge",
  id: string,
  playbookId: string,
  userID: string,
  createdAt: string,
  updatedAt: string,
};

export type CreateMilestoneInput = {
  id?: string | null,
  name: string,
  kinfolkTemplateID?: string | null,
  isCompleted?: boolean | null,
  isArchived?: boolean | null,
  journeyID?: string | null,
  orderNo: number,
  parentMilestoneID?: string | null,
};

export type ModelMilestoneConditionInput = {
  name?: ModelStringInput | null,
  kinfolkTemplateID?: ModelIDInput | null,
  isCompleted?: ModelBooleanInput | null,
  isArchived?: ModelBooleanInput | null,
  journeyID?: ModelIDInput | null,
  orderNo?: ModelIntInput | null,
  parentMilestoneID?: ModelIDInput | null,
  and?: Array< ModelMilestoneConditionInput | null > | null,
  or?: Array< ModelMilestoneConditionInput | null > | null,
  not?: ModelMilestoneConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelMilestoneFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  kinfolkTemplateID?: ModelIDInput | null,
  isCompleted?: ModelBooleanInput | null,
  isArchived?: ModelBooleanInput | null,
  journeyID?: ModelIDInput | null,
  orderNo?: ModelIntInput | null,
  parentMilestoneID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelMilestoneFilterInput | null > | null,
  or?: Array< ModelMilestoneFilterInput | null > | null,
  not?: ModelMilestoneFilterInput | null,
};

export type CreateStandardContentBlockInput = {
  id?: string | null,
  type: BlockType,
  milestoneID: string,
  title: string,
  description?: string | null,
  orderNo: number,
  parentStandardContentBlockID?: string | null,
  isCompleted?: boolean | null,
  isArchived?: boolean | null,
  updatedInChild?: boolean | null,
};

export type ModelStandardContentBlockConditionInput = {
  type?: ModelBlockTypeInput | null,
  milestoneID?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  orderNo?: ModelIntInput | null,
  parentStandardContentBlockID?: ModelIDInput | null,
  isCompleted?: ModelBooleanInput | null,
  isArchived?: ModelBooleanInput | null,
  updatedInChild?: ModelBooleanInput | null,
  and?: Array< ModelStandardContentBlockConditionInput | null > | null,
  or?: Array< ModelStandardContentBlockConditionInput | null > | null,
  not?: ModelStandardContentBlockConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelBlockTypeInput = {
  eq?: BlockType | null,
  ne?: BlockType | null,
};

export type CreateMultipleChoiceQuestionBlockInput = {
  id?: string | null,
  type: BlockType,
  milestoneID: string,
  title: string,
  description?: string | null,
  option?: Array< string > | null,
  answer?: Array< string > | null,
  orderNo: number,
  parentMultipleChoiceQuestionBlockID?: string | null,
  isCompleted?: boolean | null,
  isRequired?: boolean | null,
  isArchived?: boolean | null,
  updatedInChild?: boolean | null,
};

export type ModelMultipleChoiceQuestionBlockConditionInput = {
  type?: ModelBlockTypeInput | null,
  milestoneID?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  option?: ModelStringInput | null,
  answer?: ModelStringInput | null,
  orderNo?: ModelIntInput | null,
  parentMultipleChoiceQuestionBlockID?: ModelIDInput | null,
  isCompleted?: ModelBooleanInput | null,
  isRequired?: ModelBooleanInput | null,
  isArchived?: ModelBooleanInput | null,
  updatedInChild?: ModelBooleanInput | null,
  and?: Array< ModelMultipleChoiceQuestionBlockConditionInput | null > | null,
  or?: Array< ModelMultipleChoiceQuestionBlockConditionInput | null > | null,
  not?: ModelMultipleChoiceQuestionBlockConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type CreateTaskBlockInput = {
  id?: string | null,
  type: BlockType,
  milestoneID: string,
  title: string,
  description?: string | null,
  status?: boolean | null,
  orderNo: number,
  parentTaskBlockID?: string | null,
  isCompleted?: boolean | null,
  isArchived?: boolean | null,
  updatedInChild?: boolean | null,
};

export type ModelTaskBlockConditionInput = {
  type?: ModelBlockTypeInput | null,
  milestoneID?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  status?: ModelBooleanInput | null,
  orderNo?: ModelIntInput | null,
  parentTaskBlockID?: ModelIDInput | null,
  isCompleted?: ModelBooleanInput | null,
  isArchived?: ModelBooleanInput | null,
  updatedInChild?: ModelBooleanInput | null,
  and?: Array< ModelTaskBlockConditionInput | null > | null,
  or?: Array< ModelTaskBlockConditionInput | null > | null,
  not?: ModelTaskBlockConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type CreateNoteBlockInput = {
  id?: string | null,
  type: BlockType,
  milestoneID: string,
  title: string,
  description?: string | null,
  text?: string | null,
  orderNo: number,
  parentNoteBlockID?: string | null,
  isCompleted?: boolean | null,
  isPrivate?: boolean | null,
  isArchived?: boolean | null,
  updatedInChild?: boolean | null,
};

export type ModelNoteBlockConditionInput = {
  type?: ModelBlockTypeInput | null,
  milestoneID?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  text?: ModelStringInput | null,
  orderNo?: ModelIntInput | null,
  parentNoteBlockID?: ModelIDInput | null,
  isCompleted?: ModelBooleanInput | null,
  isPrivate?: ModelBooleanInput | null,
  isArchived?: ModelBooleanInput | null,
  updatedInChild?: ModelBooleanInput | null,
  and?: Array< ModelNoteBlockConditionInput | null > | null,
  or?: Array< ModelNoteBlockConditionInput | null > | null,
  not?: ModelNoteBlockConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type CreateFreeTextQuestionBlockInput = {
  id?: string | null,
  type: BlockType,
  milestoneID: string,
  title: string,
  description?: string | null,
  text?: string | null,
  orderNo: number,
  parentFreeTextQuestionBlockID?: string | null,
  isCompleted?: boolean | null,
  isArchived?: boolean | null,
  updatedInChild?: boolean | null,
};

export type ModelFreeTextQuestionBlockConditionInput = {
  type?: ModelBlockTypeInput | null,
  milestoneID?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  text?: ModelStringInput | null,
  orderNo?: ModelIntInput | null,
  parentFreeTextQuestionBlockID?: ModelIDInput | null,
  isCompleted?: ModelBooleanInput | null,
  isArchived?: ModelBooleanInput | null,
  updatedInChild?: ModelBooleanInput | null,
  and?: Array< ModelFreeTextQuestionBlockConditionInput | null > | null,
  or?: Array< ModelFreeTextQuestionBlockConditionInput | null > | null,
  not?: ModelFreeTextQuestionBlockConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type CreateEmbeddedContentBlockInput = {
  id?: string | null,
  type: BlockType,
  milestoneID: string,
  title: string,
  description?: string | null,
  resourceLink: string,
  isFormLinked?: boolean | null,
  orderNo: number,
  parentEmbeddedContentBlockID?: string | null,
  isCompleted?: boolean | null,
  isArchived?: boolean | null,
  updatedInChild?: boolean | null,
};

export type ModelEmbeddedContentBlockConditionInput = {
  type?: ModelBlockTypeInput | null,
  milestoneID?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  resourceLink?: ModelStringInput | null,
  isFormLinked?: ModelBooleanInput | null,
  orderNo?: ModelIntInput | null,
  parentEmbeddedContentBlockID?: ModelIDInput | null,
  isCompleted?: ModelBooleanInput | null,
  isArchived?: ModelBooleanInput | null,
  updatedInChild?: ModelBooleanInput | null,
  and?: Array< ModelEmbeddedContentBlockConditionInput | null > | null,
  or?: Array< ModelEmbeddedContentBlockConditionInput | null > | null,
  not?: ModelEmbeddedContentBlockConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateStandardContentBlockInput = {
  id: string,
  type?: BlockType | null,
  milestoneID?: string | null,
  title?: string | null,
  description?: string | null,
  orderNo?: number | null,
  parentStandardContentBlockID?: string | null,
  isCompleted?: boolean | null,
  isArchived?: boolean | null,
  updatedInChild?: boolean | null,
};

export type UpdateFreeTextQuestionBlockInput = {
  id: string,
  type?: BlockType | null,
  milestoneID?: string | null,
  title?: string | null,
  description?: string | null,
  text?: string | null,
  orderNo?: number | null,
  parentFreeTextQuestionBlockID?: string | null,
  isCompleted?: boolean | null,
  isArchived?: boolean | null,
  updatedInChild?: boolean | null,
};

export type UpdateNoteBlockInput = {
  id: string,
  type?: BlockType | null,
  milestoneID?: string | null,
  title?: string | null,
  description?: string | null,
  text?: string | null,
  orderNo?: number | null,
  parentNoteBlockID?: string | null,
  isCompleted?: boolean | null,
  isPrivate?: boolean | null,
  isArchived?: boolean | null,
  updatedInChild?: boolean | null,
};

export type UpdateTaskBlockInput = {
  id: string,
  type?: BlockType | null,
  milestoneID?: string | null,
  title?: string | null,
  description?: string | null,
  status?: boolean | null,
  orderNo?: number | null,
  parentTaskBlockID?: string | null,
  isCompleted?: boolean | null,
  isArchived?: boolean | null,
  updatedInChild?: boolean | null,
};

export type UpdateMilestoneInput = {
  id: string,
  name?: string | null,
  kinfolkTemplateID?: string | null,
  isCompleted?: boolean | null,
  isArchived?: boolean | null,
  journeyID?: string | null,
  orderNo?: number | null,
  parentMilestoneID?: string | null,
};

export type UpdateMultipleChoiceQuestionBlockInput = {
  id: string,
  type?: BlockType | null,
  milestoneID?: string | null,
  title?: string | null,
  description?: string | null,
  option?: Array< string > | null,
  answer?: Array< string > | null,
  orderNo?: number | null,
  parentMultipleChoiceQuestionBlockID?: string | null,
  isCompleted?: boolean | null,
  isRequired?: boolean | null,
  isArchived?: boolean | null,
  updatedInChild?: boolean | null,
};

export type ModelJourneyFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  createdByUserID?: ModelIDInput | null,
  assignedUserID?: ModelIDInput | null,
  mode?: ModelPlaybookModeInput | null,
  startDate?: ModelStringInput | null,
  journeyProgress?: ModelIntInput | null,
  parentJourneyID?: ModelIDInput | null,
  collaborator?: ModelStringInput | null,
  status?: ModelJourneyStatusInput | null,
  badgeID?: ModelIDInput | null,
  customBadgeName?: ModelStringInput | null,
  lockChapters?: ModelBooleanInput | null,
  type?: ModelBuilderTypeInput | null,
  publicLink?: ModelStringInput | null,
  eventStartedAt?: ModelStringInput | null,
  userStartedAt?: ModelStringInput | null,
  userCompletedAt?: ModelStringInput | null,
  isArchived?: ModelBooleanInput | null,
  preboarding?: ModelBooleanInput | null,
  organizationID?: ModelIDInput | null,
  image?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelJourneyFilterInput | null > | null,
  or?: Array< ModelJourneyFilterInput | null > | null,
  not?: ModelJourneyFilterInput | null,
};

export type ModelPlaybookModeInput = {
  eq?: PlaybookMode | null,
  ne?: PlaybookMode | null,
};

export type ModelJourneyStatusInput = {
  eq?: JourneyStatus | null,
  ne?: JourneyStatus | null,
};

export type ModelBuilderTypeInput = {
  eq?: BuilderType | null,
  ne?: BuilderType | null,
};

export type CreateJourneyInput = {
  id?: string | null,
  name: string,
  createdByUserID: string,
  assignedUserID?: string | null,
  mode: PlaybookMode,
  startDate?: string | null,
  journeyProgress: number,
  parentJourneyID?: string | null,
  collaborator?: Array< string | null > | null,
  status: JourneyStatus,
  badgeID?: string | null,
  customBadgeName?: string | null,
  lockChapters?: boolean | null,
  type: BuilderType,
  publicLink?: string | null,
  eventStartedAt?: string | null,
  userStartedAt?: string | null,
  userCompletedAt?: string | null,
  isArchived?: boolean | null,
  preboarding?: boolean | null,
  organizationID: string,
  image?: string | null,
};

export type ModelJourneyConditionInput = {
  name?: ModelStringInput | null,
  createdByUserID?: ModelIDInput | null,
  assignedUserID?: ModelIDInput | null,
  mode?: ModelPlaybookModeInput | null,
  startDate?: ModelStringInput | null,
  journeyProgress?: ModelIntInput | null,
  parentJourneyID?: ModelIDInput | null,
  collaborator?: ModelStringInput | null,
  status?: ModelJourneyStatusInput | null,
  badgeID?: ModelIDInput | null,
  customBadgeName?: ModelStringInput | null,
  lockChapters?: ModelBooleanInput | null,
  type?: ModelBuilderTypeInput | null,
  publicLink?: ModelStringInput | null,
  eventStartedAt?: ModelStringInput | null,
  userStartedAt?: ModelStringInput | null,
  userCompletedAt?: ModelStringInput | null,
  isArchived?: ModelBooleanInput | null,
  preboarding?: ModelBooleanInput | null,
  organizationID?: ModelIDInput | null,
  image?: ModelStringInput | null,
  and?: Array< ModelJourneyConditionInput | null > | null,
  or?: Array< ModelJourneyConditionInput | null > | null,
  not?: ModelJourneyConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateJourneyInput = {
  id: string,
  name?: string | null,
  createdByUserID?: string | null,
  assignedUserID?: string | null,
  mode?: PlaybookMode | null,
  startDate?: string | null,
  journeyProgress?: number | null,
  parentJourneyID?: string | null,
  collaborator?: Array< string | null > | null,
  status?: JourneyStatus | null,
  badgeID?: string | null,
  customBadgeName?: string | null,
  lockChapters?: boolean | null,
  type?: BuilderType | null,
  publicLink?: string | null,
  eventStartedAt?: string | null,
  userStartedAt?: string | null,
  userCompletedAt?: string | null,
  isArchived?: boolean | null,
  preboarding?: boolean | null,
  organizationID?: string | null,
  image?: string | null,
};

export type UpdateEmbeddedContentBlockInput = {
  id: string,
  type?: BlockType | null,
  milestoneID?: string | null,
  title?: string | null,
  description?: string | null,
  resourceLink?: string | null,
  isFormLinked?: boolean | null,
  orderNo?: number | null,
  parentEmbeddedContentBlockID?: string | null,
  isCompleted?: boolean | null,
  isArchived?: boolean | null,
  updatedInChild?: boolean | null,
};

export type UpdateLocationInput = {
  id: string,
  name?: string | null,
  streetAddress?: string | null,
  city?: string | null,
  state?: string | null,
  zipCode?: number | null,
  country?: string | null,
  locationType?: string | null,
  organizationID?: string | null,
};

export type ModelLocationConditionInput = {
  name?: ModelStringInput | null,
  streetAddress?: ModelStringInput | null,
  city?: ModelStringInput | null,
  state?: ModelStringInput | null,
  zipCode?: ModelIntInput | null,
  country?: ModelStringInput | null,
  locationType?: ModelStringInput | null,
  organizationID?: ModelIDInput | null,
  and?: Array< ModelLocationConditionInput | null > | null,
  or?: Array< ModelLocationConditionInput | null > | null,
  not?: ModelLocationConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateUserInput = {
  id: string,
  offset?: number | null,
  firstName?: string | null,
  email?: string | null,
  phone?: string | null,
  sendWelcomeEmail?: boolean | null,
  personalEmail?: string | null,
  jobTitle?: string | null,
  birthday?: string | null,
  startDate?: string | null,
  homeLocation?: string | null,
  team?: string | null,
  photoUrl?: string | null,
  pronoun?: Pronoun | null,
  aboutMe?: string | null,
  mostHappy?: string | null,
  socialMediaUrl?: string | null,
  slackUserId?: string | null,
  isFirstLogin?: boolean | null,
  isHomeModelViewed?: boolean | null,
  isProfileModelViewed?: boolean | null,
  type?: UserType | null,
  isActive?: boolean | null,
  lastName?: string | null,
  userOrganizationId?: string | null,
  userWorkPreferenceId?: string | null,
  userWorkStyleId?: string | null,
  userWorkLocationId?: string | null,
};

export type ModelUserConditionInput = {
  offset?: ModelFloatInput | null,
  firstName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  sendWelcomeEmail?: ModelBooleanInput | null,
  personalEmail?: ModelStringInput | null,
  jobTitle?: ModelStringInput | null,
  birthday?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  homeLocation?: ModelStringInput | null,
  team?: ModelStringInput | null,
  photoUrl?: ModelStringInput | null,
  pronoun?: ModelPronounInput | null,
  aboutMe?: ModelStringInput | null,
  mostHappy?: ModelStringInput | null,
  socialMediaUrl?: ModelStringInput | null,
  slackUserId?: ModelStringInput | null,
  isFirstLogin?: ModelBooleanInput | null,
  isHomeModelViewed?: ModelBooleanInput | null,
  isProfileModelViewed?: ModelBooleanInput | null,
  type?: ModelUserTypeInput | null,
  isActive?: ModelBooleanInput | null,
  lastName?: ModelStringInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userOrganizationId?: ModelIDInput | null,
  userWorkPreferenceId?: ModelIDInput | null,
  userWorkStyleId?: ModelIDInput | null,
  userWorkLocationId?: ModelIDInput | null,
};

export type ModelSecretKeyManagerFilterInput = {
  id?: ModelIDInput | null,
  organizationID?: ModelIDInput | null,
  clientEmail?: ModelStringInput | null,
  privateKey?: ModelStringInput | null,
  adminEmail?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelSecretKeyManagerFilterInput | null > | null,
  or?: Array< ModelSecretKeyManagerFilterInput | null > | null,
  not?: ModelSecretKeyManagerFilterInput | null,
};

export type ModelSecretKeyManagerConnection = {
  __typename: "ModelSecretKeyManagerConnection",
  items:  Array<SecretKeyManager | null >,
  nextToken?: string | null,
};

export type SecretKeyManager = {
  __typename: "SecretKeyManager",
  id: string,
  organizationID: string,
  clientEmail: string,
  privateKey: string,
  adminEmail: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateSecretKeyManagerInput = {
  id: string,
  organizationID?: string | null,
  clientEmail?: string | null,
  privateKey?: string | null,
  adminEmail?: string | null,
};

export type ModelSecretKeyManagerConditionInput = {
  organizationID?: ModelIDInput | null,
  clientEmail?: ModelStringInput | null,
  privateKey?: ModelStringInput | null,
  adminEmail?: ModelStringInput | null,
  and?: Array< ModelSecretKeyManagerConditionInput | null > | null,
  or?: Array< ModelSecretKeyManagerConditionInput | null > | null,
  not?: ModelSecretKeyManagerConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type DeleteMilestoneInput = {
  id: string,
};

export type DeleteMeetingInput = {
  id: string,
};

export type ModelMeetingConditionInput = {
  kinfolkTemplateID?: ModelIDInput | null,
  journeyID?: ModelIDInput | null,
  organizedByUserID?: ModelIDInput | null,
  status?: ModelMeetingStatusInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  attendeesEmail?: ModelStringInput | null,
  startTime?: ModelStringInput | null,
  endTime?: ModelStringInput | null,
  parentMeetingID?: ModelIDInput | null,
  updatedInChild?: ModelBooleanInput | null,
  eventDate?: ModelStringInput | null,
  duration?: ModelStringInput | null,
  link?: ModelStringInput | null,
  assigneeRole?: ModelAssigneeRoleInput | null,
  and?: Array< ModelMeetingConditionInput | null > | null,
  or?: Array< ModelMeetingConditionInput | null > | null,
  not?: ModelMeetingConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelMeetingStatusInput = {
  eq?: MeetingStatus | null,
  ne?: MeetingStatus | null,
};

export type ModelAssigneeRoleInput = {
  eq?: AssigneeRole | null,
  ne?: AssigneeRole | null,
};

export type UpdateOrganizationInput = {
  id: string,
  name?: string | null,
  slackAccessToken?: string | null,
  mergeCompanyToken?: string | null,
  atsToken?: string | null,
  atsAccountId?: string | null,
  hrisToken?: string | null,
  hrisAccountId?: string | null,
  hrisIntegratedAt?: string | null,
  slackUserAccessToken?: string | null,
  outlookTenantId?: string | null,
  msTeamsTenantId?: string | null,
  status?: OrganizationStatus | null,
  activeUntil?: string | null,
  hasSubscription?: boolean | null,
  preboarding?: boolean | null,
  features?: string | null,
  globalDataStores?: string | null,
  settings?: string | null,
};

export type ModelOrganizationConditionInput = {
  name?: ModelStringInput | null,
  slackAccessToken?: ModelStringInput | null,
  mergeCompanyToken?: ModelStringInput | null,
  atsToken?: ModelStringInput | null,
  atsAccountId?: ModelStringInput | null,
  hrisToken?: ModelStringInput | null,
  hrisAccountId?: ModelStringInput | null,
  hrisIntegratedAt?: ModelStringInput | null,
  slackUserAccessToken?: ModelStringInput | null,
  outlookTenantId?: ModelStringInput | null,
  msTeamsTenantId?: ModelStringInput | null,
  status?: ModelOrganizationStatusInput | null,
  activeUntil?: ModelStringInput | null,
  hasSubscription?: ModelBooleanInput | null,
  preboarding?: ModelBooleanInput | null,
  features?: ModelStringInput | null,
  globalDataStores?: ModelStringInput | null,
  settings?: ModelStringInput | null,
  and?: Array< ModelOrganizationConditionInput | null > | null,
  or?: Array< ModelOrganizationConditionInput | null > | null,
  not?: ModelOrganizationConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelOrganizationStatusInput = {
  eq?: OrganizationStatus | null,
  ne?: OrganizationStatus | null,
};

export type CreateMeetingInput = {
  id?: string | null,
  kinfolkTemplateID?: string | null,
  journeyID?: string | null,
  organizedByUserID?: string | null,
  status: MeetingStatus,
  title: string,
  description?: string | null,
  attendeesEmail?: Array< string | null > | null,
  startTime?: string | null,
  endTime?: string | null,
  parentMeetingID?: string | null,
  updatedInChild?: boolean | null,
  eventDate?: string | null,
  duration: string,
  link?: string | null,
  assigneeRole?: AssigneeRole | null,
};

export type UpdateMeetingInput = {
  id: string,
  kinfolkTemplateID?: string | null,
  journeyID?: string | null,
  organizedByUserID?: string | null,
  status?: MeetingStatus | null,
  title?: string | null,
  description?: string | null,
  attendeesEmail?: Array< string | null > | null,
  startTime?: string | null,
  endTime?: string | null,
  parentMeetingID?: string | null,
  updatedInChild?: boolean | null,
  eventDate?: string | null,
  duration?: string | null,
  link?: string | null,
  assigneeRole?: AssigneeRole | null,
};

export type ModelJourneyCollaboratorFilterInput = {
  id?: ModelIDInput | null,
  assigneeRole?: ModelAssigneeRoleInput | null,
  journeyAccess?: ModelJourneyAccessInput | null,
  assignedUserID?: ModelIDInput | null,
  actionID?: ModelStringInput | null,
  meetingID?: ModelStringInput | null,
  updatedInChild?: ModelBooleanInput | null,
  journeyID?: ModelIDInput | null,
  kinfolkTemplateID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelJourneyCollaboratorFilterInput | null > | null,
  or?: Array< ModelJourneyCollaboratorFilterInput | null > | null,
  not?: ModelJourneyCollaboratorFilterInput | null,
};

export type ModelJourneyAccessInput = {
  eq?: JourneyAccess | null,
  ne?: JourneyAccess | null,
};

export type CreateJourneyCollaboratorInput = {
  id?: string | null,
  assigneeRole: AssigneeRole,
  journeyAccess?: JourneyAccess | null,
  assignedUserID?: string | null,
  actionID?: Array< string | null > | null,
  meetingID?: Array< string | null > | null,
  updatedInChild?: boolean | null,
  journeyID?: string | null,
  kinfolkTemplateID?: string | null,
};

export type ModelJourneyCollaboratorConditionInput = {
  assigneeRole?: ModelAssigneeRoleInput | null,
  journeyAccess?: ModelJourneyAccessInput | null,
  assignedUserID?: ModelIDInput | null,
  actionID?: ModelStringInput | null,
  meetingID?: ModelStringInput | null,
  updatedInChild?: ModelBooleanInput | null,
  journeyID?: ModelIDInput | null,
  kinfolkTemplateID?: ModelIDInput | null,
  and?: Array< ModelJourneyCollaboratorConditionInput | null > | null,
  or?: Array< ModelJourneyCollaboratorConditionInput | null > | null,
  not?: ModelJourneyCollaboratorConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateJourneyCollaboratorInput = {
  id: string,
  assigneeRole?: AssigneeRole | null,
  journeyAccess?: JourneyAccess | null,
  assignedUserID?: string | null,
  actionID?: Array< string | null > | null,
  meetingID?: Array< string | null > | null,
  updatedInChild?: boolean | null,
  journeyID?: string | null,
  kinfolkTemplateID?: string | null,
};

export type CreateKinfolkTemplateInput = {
  id?: string | null,
  name: string,
  description: string,
  image: string,
  isArchived?: boolean | null,
  status: TemplateStatus,
  orderNo?: number | null,
  type: BuilderType,
  mode: PlaybookMode,
  isPublic?: boolean | null,
};

export enum TemplateStatus {
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
}


export type ModelKinfolkTemplateConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  image?: ModelStringInput | null,
  isArchived?: ModelBooleanInput | null,
  status?: ModelTemplateStatusInput | null,
  orderNo?: ModelIntInput | null,
  type?: ModelBuilderTypeInput | null,
  mode?: ModelPlaybookModeInput | null,
  isPublic?: ModelBooleanInput | null,
  and?: Array< ModelKinfolkTemplateConditionInput | null > | null,
  or?: Array< ModelKinfolkTemplateConditionInput | null > | null,
  not?: ModelKinfolkTemplateConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelTemplateStatusInput = {
  eq?: TemplateStatus | null,
  ne?: TemplateStatus | null,
};

export type KinfolkTemplate = {
  __typename: "KinfolkTemplate",
  id: string,
  name: string,
  description: string,
  image: string,
  isArchived?: boolean | null,
  status: TemplateStatus,
  orderNo?: number | null,
  type: BuilderType,
  mode: PlaybookMode,
  isPublic?: boolean | null,
  Milestone?: ModelMilestoneConnection | null,
  Meetings?: ModelMeetingConnection | null,
  JourneyCollaborator?: ModelJourneyCollaboratorConnection | null,
  events?: ModelEventConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateKinfolkTemplateInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  image?: string | null,
  isArchived?: boolean | null,
  status?: TemplateStatus | null,
  orderNo?: number | null,
  type?: BuilderType | null,
  mode?: PlaybookMode | null,
  isPublic?: boolean | null,
};

export type ModelPreboardingUserFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  isSubmitted?: ModelBooleanInput | null,
  consent?: ModelBooleanInput | null,
  journeyId?: ModelIDInput | null,
  personalEmail?: ModelStringInput | null,
  photoUrl?: ModelStringInput | null,
  bankName?: ModelStringInput | null,
  bankBranch?: ModelStringInput | null,
  bankSortCode?: ModelStringInput | null,
  bankAccountNumber?: ModelStringInput | null,
  bankAccountName?: ModelStringInput | null,
  buildingSocietyRoll?: ModelStringInput | null,
  street1?: ModelStringInput | null,
  street2?: ModelStringInput | null,
  city?: ModelStringInput | null,
  state?: ModelStringInput | null,
  zip?: ModelStringInput | null,
  country?: ModelStringInput | null,
  birthday?: ModelStringInput | null,
  gender?: ModelStringInput | null,
  title?: ModelStringInput | null,
  nationality?: ModelStringInput | null,
  mobileNumber?: ModelStringInput | null,
  niNumber?: ModelStringInput | null,
  laptopPreference?: ModelLaptopPreferenceInput | null,
  secondMonitor?: ModelBooleanInput | null,
  corporateCard?: ModelBooleanInput | null,
  corporatePhone?: ModelPhonePreferenceInput | null,
  healthCare?: ModelBooleanInput | null,
  dental?: ModelBooleanInput | null,
  optical?: ModelBooleanInput | null,
  jobDeclaration?: ModelDeclarationInput | null,
  expatDeclaration?: ModelDeclarationInput | null,
  studentLoanRepayment?: ModelBooleanInput | null,
  studentLoanType?: ModelStudentLoanTypeInput | null,
  pensionScheme?: ModelBooleanInput | null,
  pensionContribution?: ModelIntInput | null,
  passportNumber?: ModelStringInput | null,
  passportIssueDate?: ModelStringInput | null,
  passportExpiryDate?: ModelStringInput | null,
  passportIssuingCountry?: ModelStringInput | null,
  confirmation?: ModelBooleanInput | null,
  pensionSchemeOpt?: ModelStringInput | null,
  emergencyContactName?: ModelStringInput | null,
  emergencyContactRelationship?: ModelStringInput | null,
  emergencyContactPhone?: ModelStringInput | null,
  emergencyContactEmailAddress?: ModelStringInput | null,
  emergencyContactStreet1?: ModelStringInput | null,
  emergencyContactStreet2?: ModelStringInput | null,
  emergencyContactCity?: ModelStringInput | null,
  emergencyContactState?: ModelStringInput | null,
  emergencyContactZip?: ModelStringInput | null,
  emergencyContactCountry?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelPreboardingUserFilterInput | null > | null,
  or?: Array< ModelPreboardingUserFilterInput | null > | null,
  not?: ModelPreboardingUserFilterInput | null,
};

export type ModelLaptopPreferenceInput = {
  eq?: LaptopPreference | null,
  ne?: LaptopPreference | null,
};

export type ModelPhonePreferenceInput = {
  eq?: PhonePreference | null,
  ne?: PhonePreference | null,
};

export type ModelDeclarationInput = {
  eq?: Declaration | null,
  ne?: Declaration | null,
};

export type ModelStudentLoanTypeInput = {
  eq?: StudentLoanType | null,
  ne?: StudentLoanType | null,
};

export type ModelEventFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  formId?: ModelIDInput | null,
  formInstanceId?: ModelIDInput | null,
  daysFrom?: ModelIntInput | null,
  daysFromDueDate?: ModelIntInput | null,
  type?: ModelStringInput | null,
  role?: ModelAssigneeRoleInput | null,
  emailTo?: ModelStringInput | null,
  title?: ModelStringInput | null,
  message?: ModelStringInput | null,
  sendTime?: ModelStringInput | null,
  sendTimeDate?: ModelStringInput | null,
  dueDate?: ModelStringInput | null,
  status?: ModelEventStatusInput | null,
  channelId?: ModelStringInput | null,
  parentId?: ModelStringInput | null,
  channel?: ModelChannelInput | null,
  cc?: ModelStringInput | null,
  archived?: ModelBooleanInput | null,
  updatedInChild?: ModelBooleanInput | null,
  journeyID?: ModelIDInput | null,
  kinfolkTemplateID?: ModelIDInput | null,
  meta?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelEventFilterInput | null > | null,
  or?: Array< ModelEventFilterInput | null > | null,
  not?: ModelEventFilterInput | null,
};

export type ModelEventStatusInput = {
  eq?: EventStatus | null,
  ne?: EventStatus | null,
};

export type ModelChannelInput = {
  eq?: Channel | null,
  ne?: Channel | null,
};

export type CreateShareInfoInput = {
  id?: string | null,
  name: string,
  organizationID: string,
};

export type ModelShareInfoConditionInput = {
  name?: ModelStringInput | null,
  organizationID?: ModelIDInput | null,
  and?: Array< ModelShareInfoConditionInput | null > | null,
  or?: Array< ModelShareInfoConditionInput | null > | null,
  not?: ModelShareInfoConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateShareInfoInput = {
  id: string,
  name?: string | null,
  organizationID?: string | null,
};

export type DeleteShareInfoInput = {
  id: string,
};

export type CreateWorkStyleInput = {
  id?: string | null,
  whatIDoAtWork?: string | null,
  myFocusTimeIs?: string | null,
  giveReceivingFeedback?: string | null,
  digestionInformation?: string | null,
  inMyElement?: string | null,
};

export type ModelWorkStyleConditionInput = {
  whatIDoAtWork?: ModelStringInput | null,
  myFocusTimeIs?: ModelStringInput | null,
  giveReceivingFeedback?: ModelStringInput | null,
  digestionInformation?: ModelStringInput | null,
  inMyElement?: ModelStringInput | null,
  and?: Array< ModelWorkStyleConditionInput | null > | null,
  or?: Array< ModelWorkStyleConditionInput | null > | null,
  not?: ModelWorkStyleConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateWorkStyleInput = {
  id: string,
  whatIDoAtWork?: string | null,
  myFocusTimeIs?: string | null,
  giveReceivingFeedback?: string | null,
  digestionInformation?: string | null,
  inMyElement?: string | null,
};

export type DeleteWorkStyleInput = {
  id: string,
};

export type CreateWorkPreferenceInput = {
  id?: string | null,
  giveMeAllTheContext: number,
  messageTimingPreferene: number,
  syncxVsAsync: number,
  talkTogetherVsAlone: number,
  recognitionPrivateVsPrivate: number,
};

export type ModelWorkPreferenceConditionInput = {
  giveMeAllTheContext?: ModelFloatInput | null,
  messageTimingPreferene?: ModelFloatInput | null,
  syncxVsAsync?: ModelFloatInput | null,
  talkTogetherVsAlone?: ModelFloatInput | null,
  recognitionPrivateVsPrivate?: ModelFloatInput | null,
  and?: Array< ModelWorkPreferenceConditionInput | null > | null,
  or?: Array< ModelWorkPreferenceConditionInput | null > | null,
  not?: ModelWorkPreferenceConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateWorkPreferenceInput = {
  id: string,
  giveMeAllTheContext?: number | null,
  messageTimingPreferene?: number | null,
  syncxVsAsync?: number | null,
  talkTogetherVsAlone?: number | null,
  recognitionPrivateVsPrivate?: number | null,
};

export type DeleteWorkPreferenceInput = {
  id: string,
};

export type CreateInterestInput = {
  id?: string | null,
  name: string,
  organizationID: string,
};

export type ModelInterestConditionInput = {
  name?: ModelStringInput | null,
  organizationID?: ModelIDInput | null,
  and?: Array< ModelInterestConditionInput | null > | null,
  or?: Array< ModelInterestConditionInput | null > | null,
  not?: ModelInterestConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateInterestInput = {
  id: string,
  name?: string | null,
  organizationID?: string | null,
};

export type DeleteInterestInput = {
  id: string,
};

export type CreateLocationInput = {
  id?: string | null,
  name?: string | null,
  streetAddress?: string | null,
  city?: string | null,
  state?: string | null,
  zipCode?: number | null,
  country?: string | null,
  locationType?: string | null,
  organizationID: string,
};

export type DeleteLocationInput = {
  id: string,
};

export type CreateOrganizationInput = {
  id?: string | null,
  name: string,
  slackAccessToken?: string | null,
  mergeCompanyToken?: string | null,
  atsToken?: string | null,
  atsAccountId?: string | null,
  hrisToken?: string | null,
  hrisAccountId?: string | null,
  hrisIntegratedAt?: string | null,
  slackUserAccessToken?: string | null,
  outlookTenantId?: string | null,
  msTeamsTenantId?: string | null,
  status: OrganizationStatus,
  activeUntil: string,
  hasSubscription?: boolean | null,
  preboarding?: boolean | null,
  features?: string | null,
  globalDataStores?: string | null,
  settings?: string | null,
};

export type DeleteOrganizationInput = {
  id: string,
};

export type CreateUserInput = {
  id?: string | null,
  offset: number,
  firstName?: string | null,
  email: string,
  phone?: string | null,
  sendWelcomeEmail?: boolean | null,
  personalEmail?: string | null,
  jobTitle?: string | null,
  birthday: string,
  startDate?: string | null,
  homeLocation?: string | null,
  team?: string | null,
  photoUrl: string,
  pronoun: Pronoun,
  aboutMe?: string | null,
  mostHappy?: string | null,
  socialMediaUrl?: string | null,
  slackUserId?: string | null,
  isFirstLogin?: boolean | null,
  isHomeModelViewed?: boolean | null,
  isProfileModelViewed?: boolean | null,
  type: UserType,
  isActive?: boolean | null,
  lastName?: string | null,
  userOrganizationId: string,
  userWorkPreferenceId: string,
  userWorkStyleId: string,
  userWorkLocationId?: string | null,
};

export type DeleteUserInput = {
  id: string,
};

export type DeleteJourneyInput = {
  id: string,
};

export type CreateBadgeInput = {
  id?: string | null,
  name?: string | null,
  iconUrl: string,
  isArchived?: boolean | null,
};

export type ModelBadgeConditionInput = {
  name?: ModelStringInput | null,
  iconUrl?: ModelStringInput | null,
  isArchived?: ModelBooleanInput | null,
  and?: Array< ModelBadgeConditionInput | null > | null,
  or?: Array< ModelBadgeConditionInput | null > | null,
  not?: ModelBadgeConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type Badge = {
  __typename: "Badge",
  id: string,
  name?: string | null,
  iconUrl: string,
  isArchived?: boolean | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateBadgeInput = {
  id: string,
  name?: string | null,
  iconUrl?: string | null,
  isArchived?: boolean | null,
};

export type DeleteBadgeInput = {
  id: string,
};

export type CreateUserBadgeInput = {
  id?: string | null,
  playbookId: string,
  userID: string,
};

export type ModelUserBadgeConditionInput = {
  playbookId?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  and?: Array< ModelUserBadgeConditionInput | null > | null,
  or?: Array< ModelUserBadgeConditionInput | null > | null,
  not?: ModelUserBadgeConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateUserBadgeInput = {
  id: string,
  playbookId?: string | null,
  userID?: string | null,
};

export type DeleteUserBadgeInput = {
  id: string,
};

export type DeleteJourneyCollaboratorInput = {
  id: string,
};

export type DeleteKinfolkTemplateInput = {
  id: string,
};

export type DeleteEmbeddedContentBlockInput = {
  id: string,
};

export type DeleteFreeTextQuestionBlockInput = {
  id: string,
};

export type DeleteNoteBlockInput = {
  id: string,
};

export type DeleteTaskBlockInput = {
  id: string,
};

export type DeleteStandardContentBlockInput = {
  id: string,
};

export type DeleteMultipleChoiceQuestionBlockInput = {
  id: string,
};

export type CreateEventInput = {
  id?: string | null,
  userId?: string | null,
  formId?: string | null,
  formInstanceId?: string | null,
  daysFrom: number,
  daysFromDueDate?: number | null,
  type: string,
  role?: AssigneeRole | null,
  emailTo?: string | null,
  title?: string | null,
  message?: string | null,
  sendTime?: string | null,
  sendTimeDate?: string | null,
  dueDate?: string | null,
  status: EventStatus,
  channelId?: string | null,
  parentId?: string | null,
  channel: Channel,
  cc?: Array< string | null > | null,
  archived?: boolean | null,
  updatedInChild?: boolean | null,
  journeyID?: string | null,
  kinfolkTemplateID?: string | null,
  meta?: string | null,
};

export type ModelEventConditionInput = {
  userId?: ModelIDInput | null,
  formId?: ModelIDInput | null,
  formInstanceId?: ModelIDInput | null,
  daysFrom?: ModelIntInput | null,
  daysFromDueDate?: ModelIntInput | null,
  type?: ModelStringInput | null,
  role?: ModelAssigneeRoleInput | null,
  emailTo?: ModelStringInput | null,
  title?: ModelStringInput | null,
  message?: ModelStringInput | null,
  sendTime?: ModelStringInput | null,
  sendTimeDate?: ModelStringInput | null,
  dueDate?: ModelStringInput | null,
  status?: ModelEventStatusInput | null,
  channelId?: ModelStringInput | null,
  parentId?: ModelStringInput | null,
  channel?: ModelChannelInput | null,
  cc?: ModelStringInput | null,
  archived?: ModelBooleanInput | null,
  updatedInChild?: ModelBooleanInput | null,
  journeyID?: ModelIDInput | null,
  kinfolkTemplateID?: ModelIDInput | null,
  meta?: ModelStringInput | null,
  and?: Array< ModelEventConditionInput | null > | null,
  or?: Array< ModelEventConditionInput | null > | null,
  not?: ModelEventConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateEventInput = {
  id: string,
  userId?: string | null,
  formId?: string | null,
  formInstanceId?: string | null,
  daysFrom?: number | null,
  daysFromDueDate?: number | null,
  type?: string | null,
  role?: AssigneeRole | null,
  emailTo?: string | null,
  title?: string | null,
  message?: string | null,
  sendTime?: string | null,
  sendTimeDate?: string | null,
  dueDate?: string | null,
  status?: EventStatus | null,
  channelId?: string | null,
  parentId?: string | null,
  channel?: Channel | null,
  cc?: Array< string | null > | null,
  archived?: boolean | null,
  updatedInChild?: boolean | null,
  journeyID?: string | null,
  kinfolkTemplateID?: string | null,
  meta?: string | null,
};

export type DeleteEventInput = {
  id: string,
};

export type CreateEventReminderInput = {
  id?: string | null,
  eventId: string,
  sendTimeDate?: string | null,
  daysFrom: number,
  status: EventStatus,
};

export type ModelEventReminderConditionInput = {
  eventId?: ModelIDInput | null,
  sendTimeDate?: ModelStringInput | null,
  daysFrom?: ModelIntInput | null,
  status?: ModelEventStatusInput | null,
  and?: Array< ModelEventReminderConditionInput | null > | null,
  or?: Array< ModelEventReminderConditionInput | null > | null,
  not?: ModelEventReminderConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateEventReminderInput = {
  id: string,
  eventId?: string | null,
  sendTimeDate?: string | null,
  daysFrom?: number | null,
  status?: EventStatus | null,
};

export type DeleteEventReminderInput = {
  id: string,
};

export type CreateSecretKeyManagerInput = {
  id?: string | null,
  organizationID: string,
  clientEmail: string,
  privateKey: string,
  adminEmail: string,
};

export type DeleteSecretKeyManagerInput = {
  id: string,
};

export type CreatePreboardingUserInput = {
  id?: string | null,
  userId: string,
  isSubmitted?: boolean | null,
  consent?: boolean | null,
  journeyId?: string | null,
  personalEmail?: string | null,
  photoUrl?: string | null,
  bankName?: string | null,
  bankBranch?: string | null,
  bankSortCode?: string | null,
  bankAccountNumber?: string | null,
  bankAccountName?: string | null,
  buildingSocietyRoll?: string | null,
  street1?: string | null,
  street2?: string | null,
  city?: string | null,
  state?: string | null,
  zip?: string | null,
  country?: string | null,
  birthday?: string | null,
  gender?: string | null,
  title?: string | null,
  nationality?: string | null,
  mobileNumber?: string | null,
  niNumber?: string | null,
  laptopPreference?: LaptopPreference | null,
  secondMonitor?: boolean | null,
  corporateCard?: boolean | null,
  corporatePhone?: PhonePreference | null,
  healthCare?: boolean | null,
  dental?: boolean | null,
  optical?: boolean | null,
  jobDeclaration?: Declaration | null,
  expatDeclaration?: Declaration | null,
  studentLoanRepayment?: boolean | null,
  studentLoanType?: StudentLoanType | null,
  pensionScheme?: boolean | null,
  pensionContribution?: number | null,
  passportNumber?: string | null,
  passportIssueDate?: string | null,
  passportExpiryDate?: string | null,
  passportIssuingCountry?: string | null,
  confirmation?: boolean | null,
  pensionSchemeOpt?: string | null,
  emergencyContactName?: string | null,
  emergencyContactRelationship?: string | null,
  emergencyContactPhone?: string | null,
  emergencyContactEmailAddress?: string | null,
  emergencyContactStreet1?: string | null,
  emergencyContactStreet2?: string | null,
  emergencyContactCity?: string | null,
  emergencyContactState?: string | null,
  emergencyContactZip?: string | null,
  emergencyContactCountry?: string | null,
};

export type ModelPreboardingUserConditionInput = {
  userId?: ModelIDInput | null,
  isSubmitted?: ModelBooleanInput | null,
  consent?: ModelBooleanInput | null,
  journeyId?: ModelIDInput | null,
  personalEmail?: ModelStringInput | null,
  photoUrl?: ModelStringInput | null,
  bankName?: ModelStringInput | null,
  bankBranch?: ModelStringInput | null,
  bankSortCode?: ModelStringInput | null,
  bankAccountNumber?: ModelStringInput | null,
  bankAccountName?: ModelStringInput | null,
  buildingSocietyRoll?: ModelStringInput | null,
  street1?: ModelStringInput | null,
  street2?: ModelStringInput | null,
  city?: ModelStringInput | null,
  state?: ModelStringInput | null,
  zip?: ModelStringInput | null,
  country?: ModelStringInput | null,
  birthday?: ModelStringInput | null,
  gender?: ModelStringInput | null,
  title?: ModelStringInput | null,
  nationality?: ModelStringInput | null,
  mobileNumber?: ModelStringInput | null,
  niNumber?: ModelStringInput | null,
  laptopPreference?: ModelLaptopPreferenceInput | null,
  secondMonitor?: ModelBooleanInput | null,
  corporateCard?: ModelBooleanInput | null,
  corporatePhone?: ModelPhonePreferenceInput | null,
  healthCare?: ModelBooleanInput | null,
  dental?: ModelBooleanInput | null,
  optical?: ModelBooleanInput | null,
  jobDeclaration?: ModelDeclarationInput | null,
  expatDeclaration?: ModelDeclarationInput | null,
  studentLoanRepayment?: ModelBooleanInput | null,
  studentLoanType?: ModelStudentLoanTypeInput | null,
  pensionScheme?: ModelBooleanInput | null,
  pensionContribution?: ModelIntInput | null,
  passportNumber?: ModelStringInput | null,
  passportIssueDate?: ModelStringInput | null,
  passportExpiryDate?: ModelStringInput | null,
  passportIssuingCountry?: ModelStringInput | null,
  confirmation?: ModelBooleanInput | null,
  pensionSchemeOpt?: ModelStringInput | null,
  emergencyContactName?: ModelStringInput | null,
  emergencyContactRelationship?: ModelStringInput | null,
  emergencyContactPhone?: ModelStringInput | null,
  emergencyContactEmailAddress?: ModelStringInput | null,
  emergencyContactStreet1?: ModelStringInput | null,
  emergencyContactStreet2?: ModelStringInput | null,
  emergencyContactCity?: ModelStringInput | null,
  emergencyContactState?: ModelStringInput | null,
  emergencyContactZip?: ModelStringInput | null,
  emergencyContactCountry?: ModelStringInput | null,
  and?: Array< ModelPreboardingUserConditionInput | null > | null,
  or?: Array< ModelPreboardingUserConditionInput | null > | null,
  not?: ModelPreboardingUserConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdatePreboardingUserInput = {
  id: string,
  userId?: string | null,
  isSubmitted?: boolean | null,
  consent?: boolean | null,
  journeyId?: string | null,
  personalEmail?: string | null,
  photoUrl?: string | null,
  bankName?: string | null,
  bankBranch?: string | null,
  bankSortCode?: string | null,
  bankAccountNumber?: string | null,
  bankAccountName?: string | null,
  buildingSocietyRoll?: string | null,
  street1?: string | null,
  street2?: string | null,
  city?: string | null,
  state?: string | null,
  zip?: string | null,
  country?: string | null,
  birthday?: string | null,
  gender?: string | null,
  title?: string | null,
  nationality?: string | null,
  mobileNumber?: string | null,
  niNumber?: string | null,
  laptopPreference?: LaptopPreference | null,
  secondMonitor?: boolean | null,
  corporateCard?: boolean | null,
  corporatePhone?: PhonePreference | null,
  healthCare?: boolean | null,
  dental?: boolean | null,
  optical?: boolean | null,
  jobDeclaration?: Declaration | null,
  expatDeclaration?: Declaration | null,
  studentLoanRepayment?: boolean | null,
  studentLoanType?: StudentLoanType | null,
  pensionScheme?: boolean | null,
  pensionContribution?: number | null,
  passportNumber?: string | null,
  passportIssueDate?: string | null,
  passportExpiryDate?: string | null,
  passportIssuingCountry?: string | null,
  confirmation?: boolean | null,
  pensionSchemeOpt?: string | null,
  emergencyContactName?: string | null,
  emergencyContactRelationship?: string | null,
  emergencyContactPhone?: string | null,
  emergencyContactEmailAddress?: string | null,
  emergencyContactStreet1?: string | null,
  emergencyContactStreet2?: string | null,
  emergencyContactCity?: string | null,
  emergencyContactState?: string | null,
  emergencyContactZip?: string | null,
  emergencyContactCountry?: string | null,
};

export type DeletePreboardingUserInput = {
  id: string,
};

export type CreatePreboardingUserRelationInput = {
  id?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  relationshipToMember?: string | null,
  birthday?: string | null,
  userId: string,
  formId: string,
  healthCare?: boolean | null,
  dental?: boolean | null,
  optical?: boolean | null,
};

export type ModelPreboardingUserRelationConditionInput = {
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  relationshipToMember?: ModelStringInput | null,
  birthday?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  formId?: ModelIDInput | null,
  healthCare?: ModelBooleanInput | null,
  dental?: ModelBooleanInput | null,
  optical?: ModelBooleanInput | null,
  and?: Array< ModelPreboardingUserRelationConditionInput | null > | null,
  or?: Array< ModelPreboardingUserRelationConditionInput | null > | null,
  not?: ModelPreboardingUserRelationConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdatePreboardingUserRelationInput = {
  id: string,
  firstName?: string | null,
  lastName?: string | null,
  relationshipToMember?: string | null,
  birthday?: string | null,
  userId?: string | null,
  formId?: string | null,
  healthCare?: boolean | null,
  dental?: boolean | null,
  optical?: boolean | null,
};

export type DeletePreboardingUserRelationInput = {
  id: string,
};

export type CreateDigestInput = {
  id?: string | null,
  type: DigestType,
  organizationId: string,
  userId: string,
  frequency: Frequency,
  channel: Channel,
};

export enum DigestType {
  ISSUE_NOTIFICATIONS = "ISSUE_NOTIFICATIONS",
  PLAYBOOK_PROGRESS = "PLAYBOOK_PROGRESS",
}


export enum Frequency {
  IMMEDIATELY = "IMMEDIATELY",
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
}


export type ModelDigestConditionInput = {
  type?: ModelDigestTypeInput | null,
  organizationId?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  frequency?: ModelFrequencyInput | null,
  channel?: ModelChannelInput | null,
  and?: Array< ModelDigestConditionInput | null > | null,
  or?: Array< ModelDigestConditionInput | null > | null,
  not?: ModelDigestConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelDigestTypeInput = {
  eq?: DigestType | null,
  ne?: DigestType | null,
};

export type ModelFrequencyInput = {
  eq?: Frequency | null,
  ne?: Frequency | null,
};

export type Digest = {
  __typename: "Digest",
  id: string,
  type: DigestType,
  organizationId: string,
  userId: string,
  frequency: Frequency,
  channel: Channel,
  createdAt: string,
  updatedAt: string,
};

export type UpdateDigestInput = {
  id: string,
  type?: DigestType | null,
  organizationId?: string | null,
  userId?: string | null,
  frequency?: Frequency | null,
  channel?: Channel | null,
};

export type DeleteDigestInput = {
  id: string,
};

export type CreateGqlMessageInput = {
  id?: string | null,
  type: string,
  organizationId: string,
  recipientId: string,
  payload: string,
  response?: string | null,
  status: string,
  callbackId?: string | null,
  deliveryInfo?: string | null,
  respondedAt?: string | null,
};

export type ModelGqlMessageConditionInput = {
  type?: ModelStringInput | null,
  organizationId?: ModelIDInput | null,
  recipientId?: ModelIDInput | null,
  payload?: ModelStringInput | null,
  response?: ModelStringInput | null,
  status?: ModelStringInput | null,
  callbackId?: ModelStringInput | null,
  deliveryInfo?: ModelStringInput | null,
  respondedAt?: ModelStringInput | null,
  and?: Array< ModelGqlMessageConditionInput | null > | null,
  or?: Array< ModelGqlMessageConditionInput | null > | null,
  not?: ModelGqlMessageConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type GqlMessage = {
  __typename: "GqlMessage",
  id: string,
  type: string,
  organizationId: string,
  recipientId: string,
  payload: string,
  response?: string | null,
  status: string,
  callbackId?: string | null,
  deliveryInfo?: string | null,
  respondedAt?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateGqlMessageInput = {
  id: string,
  type?: string | null,
  organizationId?: string | null,
  recipientId?: string | null,
  payload?: string | null,
  response?: string | null,
  status?: string | null,
  callbackId?: string | null,
  deliveryInfo?: string | null,
  respondedAt?: string | null,
};

export type DeleteGqlMessageInput = {
  id: string,
};

export type CreateGqlMsTeamsUserReferenceInput = {
  tenantId: string,
  userEmail: string,
  teamsUserId?: string | null,
  conversation?: string | null,
  userId?: string | null,
  organizationId?: string | null,
  hasUndeliveredMessages?: boolean | null,
  id?: string | null,
};

export type ModelGqlMsTeamsUserReferenceConditionInput = {
  tenantId?: ModelStringInput | null,
  userEmail?: ModelStringInput | null,
  teamsUserId?: ModelStringInput | null,
  conversation?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  hasUndeliveredMessages?: ModelBooleanInput | null,
  and?: Array< ModelGqlMsTeamsUserReferenceConditionInput | null > | null,
  or?: Array< ModelGqlMsTeamsUserReferenceConditionInput | null > | null,
  not?: ModelGqlMsTeamsUserReferenceConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type GqlMsTeamsUserReference = {
  __typename: "GqlMsTeamsUserReference",
  tenantId: string,
  userEmail: string,
  teamsUserId?: string | null,
  conversation?: string | null,
  userId?: string | null,
  organizationId?: string | null,
  hasUndeliveredMessages?: boolean | null,
  id: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateGqlMsTeamsUserReferenceInput = {
  tenantId?: string | null,
  userEmail?: string | null,
  teamsUserId?: string | null,
  conversation?: string | null,
  userId?: string | null,
  organizationId?: string | null,
  hasUndeliveredMessages?: boolean | null,
  id: string,
};

export type DeleteGqlMsTeamsUserReferenceInput = {
  id: string,
};

export type CreateGqlDataStoreInput = {
  id?: string | null,
  organizationId: string,
  name: string,
  data: string,
};

export type ModelGqlDataStoreConditionInput = {
  organizationId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  data?: ModelStringInput | null,
  and?: Array< ModelGqlDataStoreConditionInput | null > | null,
  or?: Array< ModelGqlDataStoreConditionInput | null > | null,
  not?: ModelGqlDataStoreConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type GqlDataStore = {
  __typename: "GqlDataStore",
  id: string,
  organizationId: string,
  name: string,
  data: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateGqlDataStoreInput = {
  id: string,
  organizationId?: string | null,
  name?: string | null,
  data?: string | null,
};

export type DeleteGqlDataStoreInput = {
  id: string,
};

export type CreateGqlDataStoreRowInput = {
  id?: string | null,
  dataStoreId: string,
  data: string,
};

export type ModelGqlDataStoreRowConditionInput = {
  dataStoreId?: ModelIDInput | null,
  data?: ModelStringInput | null,
  and?: Array< ModelGqlDataStoreRowConditionInput | null > | null,
  or?: Array< ModelGqlDataStoreRowConditionInput | null > | null,
  not?: ModelGqlDataStoreRowConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type GqlDataStoreRow = {
  __typename: "GqlDataStoreRow",
  id: string,
  dataStoreId: string,
  data: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateGqlDataStoreRowInput = {
  id: string,
  dataStoreId?: string | null,
  data?: string | null,
};

export type DeleteGqlDataStoreRowInput = {
  id: string,
};

export type CreateGqlFormInput = {
  id?: string | null,
  organizationId: string,
  name: string,
  dataStoreId: string,
  data: string,
};

export type ModelGqlFormConditionInput = {
  organizationId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  dataStoreId?: ModelIDInput | null,
  data?: ModelStringInput | null,
  and?: Array< ModelGqlFormConditionInput | null > | null,
  or?: Array< ModelGqlFormConditionInput | null > | null,
  not?: ModelGqlFormConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type GqlForm = {
  __typename: "GqlForm",
  id: string,
  organizationId: string,
  name: string,
  dataStoreId: string,
  data: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateGqlFormInput = {
  id: string,
  organizationId?: string | null,
  name?: string | null,
  dataStoreId?: string | null,
  data?: string | null,
};

export type DeleteGqlFormInput = {
  id: string,
};

export type CreateGqlFormInstanceInput = {
  id?: string | null,
  organizationId: string,
  formId: string,
  assignedTo: string,
  link: string,
  slug: string,
  data: string,
};

export type ModelGqlFormInstanceConditionInput = {
  organizationId?: ModelIDInput | null,
  formId?: ModelIDInput | null,
  assignedTo?: ModelStringInput | null,
  link?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  data?: ModelStringInput | null,
  and?: Array< ModelGqlFormInstanceConditionInput | null > | null,
  or?: Array< ModelGqlFormInstanceConditionInput | null > | null,
  not?: ModelGqlFormInstanceConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type GqlFormInstance = {
  __typename: "GqlFormInstance",
  id: string,
  organizationId: string,
  formId: string,
  assignedTo: string,
  link: string,
  slug: string,
  data: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateGqlFormInstanceInput = {
  id: string,
  organizationId?: string | null,
  formId?: string | null,
  assignedTo?: string | null,
  link?: string | null,
  slug?: string | null,
  data?: string | null,
};

export type DeleteGqlFormInstanceInput = {
  id: string,
};

export type CreateUserShareInfoInput = {
  id?: string | null,
  shareInfoID: string,
  userID: string,
};

export type ModelUserShareInfoConditionInput = {
  shareInfoID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  and?: Array< ModelUserShareInfoConditionInput | null > | null,
  or?: Array< ModelUserShareInfoConditionInput | null > | null,
  not?: ModelUserShareInfoConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateUserShareInfoInput = {
  id: string,
  shareInfoID?: string | null,
  userID?: string | null,
};

export type DeleteUserShareInfoInput = {
  id: string,
};

export type CreateLearnMoreInfoInput = {
  id?: string | null,
  shareInfoID: string,
  userID: string,
};

export type ModelLearnMoreInfoConditionInput = {
  shareInfoID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  and?: Array< ModelLearnMoreInfoConditionInput | null > | null,
  or?: Array< ModelLearnMoreInfoConditionInput | null > | null,
  not?: ModelLearnMoreInfoConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateLearnMoreInfoInput = {
  id: string,
  shareInfoID?: string | null,
  userID?: string | null,
};

export type DeleteLearnMoreInfoInput = {
  id: string,
};

export type CreateUserInterestInput = {
  id?: string | null,
  interestID: string,
  userID: string,
};

export type ModelUserInterestConditionInput = {
  interestID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  and?: Array< ModelUserInterestConditionInput | null > | null,
  or?: Array< ModelUserInterestConditionInput | null > | null,
  not?: ModelUserInterestConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateUserInterestInput = {
  id: string,
  interestID?: string | null,
  userID?: string | null,
};

export type DeleteUserInterestInput = {
  id: string,
};

export type ModelShareInfoFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  organizationID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelShareInfoFilterInput | null > | null,
  or?: Array< ModelShareInfoFilterInput | null > | null,
  not?: ModelShareInfoFilterInput | null,
};

export type ModelWorkStyleFilterInput = {
  id?: ModelIDInput | null,
  whatIDoAtWork?: ModelStringInput | null,
  myFocusTimeIs?: ModelStringInput | null,
  giveReceivingFeedback?: ModelStringInput | null,
  digestionInformation?: ModelStringInput | null,
  inMyElement?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelWorkStyleFilterInput | null > | null,
  or?: Array< ModelWorkStyleFilterInput | null > | null,
  not?: ModelWorkStyleFilterInput | null,
};

export type ModelWorkStyleConnection = {
  __typename: "ModelWorkStyleConnection",
  items:  Array<WorkStyle | null >,
  nextToken?: string | null,
};

export type ModelWorkPreferenceFilterInput = {
  id?: ModelIDInput | null,
  giveMeAllTheContext?: ModelFloatInput | null,
  messageTimingPreferene?: ModelFloatInput | null,
  syncxVsAsync?: ModelFloatInput | null,
  talkTogetherVsAlone?: ModelFloatInput | null,
  recognitionPrivateVsPrivate?: ModelFloatInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelWorkPreferenceFilterInput | null > | null,
  or?: Array< ModelWorkPreferenceFilterInput | null > | null,
  not?: ModelWorkPreferenceFilterInput | null,
};

export type ModelWorkPreferenceConnection = {
  __typename: "ModelWorkPreferenceConnection",
  items:  Array<WorkPreference | null >,
  nextToken?: string | null,
};

export type ModelInterestFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  organizationID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelInterestFilterInput | null > | null,
  or?: Array< ModelInterestFilterInput | null > | null,
  not?: ModelInterestFilterInput | null,
};

export type ModelLocationFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  streetAddress?: ModelStringInput | null,
  city?: ModelStringInput | null,
  state?: ModelStringInput | null,
  zipCode?: ModelIntInput | null,
  country?: ModelStringInput | null,
  locationType?: ModelStringInput | null,
  organizationID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelLocationFilterInput | null > | null,
  or?: Array< ModelLocationFilterInput | null > | null,
  not?: ModelLocationFilterInput | null,
};

export type ModelOrganizationFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  slackAccessToken?: ModelStringInput | null,
  mergeCompanyToken?: ModelStringInput | null,
  atsToken?: ModelStringInput | null,
  atsAccountId?: ModelStringInput | null,
  hrisToken?: ModelStringInput | null,
  hrisAccountId?: ModelStringInput | null,
  hrisIntegratedAt?: ModelStringInput | null,
  slackUserAccessToken?: ModelStringInput | null,
  outlookTenantId?: ModelStringInput | null,
  msTeamsTenantId?: ModelStringInput | null,
  status?: ModelOrganizationStatusInput | null,
  activeUntil?: ModelStringInput | null,
  hasSubscription?: ModelBooleanInput | null,
  preboarding?: ModelBooleanInput | null,
  features?: ModelStringInput | null,
  globalDataStores?: ModelStringInput | null,
  settings?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelOrganizationFilterInput | null > | null,
  or?: Array< ModelOrganizationFilterInput | null > | null,
  not?: ModelOrganizationFilterInput | null,
};

export type ModelOrganizationConnection = {
  __typename: "ModelOrganizationConnection",
  items:  Array<Organization | null >,
  nextToken?: string | null,
};

export type ModelBadgeFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  iconUrl?: ModelStringInput | null,
  isArchived?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelBadgeFilterInput | null > | null,
  or?: Array< ModelBadgeFilterInput | null > | null,
  not?: ModelBadgeFilterInput | null,
};

export type ModelBadgeConnection = {
  __typename: "ModelBadgeConnection",
  items:  Array<Badge | null >,
  nextToken?: string | null,
};

export type ModelUserBadgeFilterInput = {
  id?: ModelIDInput | null,
  playbookId?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserBadgeFilterInput | null > | null,
  or?: Array< ModelUserBadgeFilterInput | null > | null,
  not?: ModelUserBadgeFilterInput | null,
};

export type ModelKinfolkTemplateFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  image?: ModelStringInput | null,
  isArchived?: ModelBooleanInput | null,
  status?: ModelTemplateStatusInput | null,
  orderNo?: ModelIntInput | null,
  type?: ModelBuilderTypeInput | null,
  mode?: ModelPlaybookModeInput | null,
  isPublic?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelKinfolkTemplateFilterInput | null > | null,
  or?: Array< ModelKinfolkTemplateFilterInput | null > | null,
  not?: ModelKinfolkTemplateFilterInput | null,
};

export type ModelKinfolkTemplateConnection = {
  __typename: "ModelKinfolkTemplateConnection",
  items:  Array<KinfolkTemplate | null >,
  nextToken?: string | null,
};

export type ModelEmbeddedContentBlockFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelBlockTypeInput | null,
  milestoneID?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  resourceLink?: ModelStringInput | null,
  isFormLinked?: ModelBooleanInput | null,
  orderNo?: ModelIntInput | null,
  parentEmbeddedContentBlockID?: ModelIDInput | null,
  isCompleted?: ModelBooleanInput | null,
  isArchived?: ModelBooleanInput | null,
  updatedInChild?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelEmbeddedContentBlockFilterInput | null > | null,
  or?: Array< ModelEmbeddedContentBlockFilterInput | null > | null,
  not?: ModelEmbeddedContentBlockFilterInput | null,
};

export type ModelFreeTextQuestionBlockFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelBlockTypeInput | null,
  milestoneID?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  text?: ModelStringInput | null,
  orderNo?: ModelIntInput | null,
  parentFreeTextQuestionBlockID?: ModelIDInput | null,
  isCompleted?: ModelBooleanInput | null,
  isArchived?: ModelBooleanInput | null,
  updatedInChild?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelFreeTextQuestionBlockFilterInput | null > | null,
  or?: Array< ModelFreeTextQuestionBlockFilterInput | null > | null,
  not?: ModelFreeTextQuestionBlockFilterInput | null,
};

export type ModelNoteBlockFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelBlockTypeInput | null,
  milestoneID?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  text?: ModelStringInput | null,
  orderNo?: ModelIntInput | null,
  parentNoteBlockID?: ModelIDInput | null,
  isCompleted?: ModelBooleanInput | null,
  isPrivate?: ModelBooleanInput | null,
  isArchived?: ModelBooleanInput | null,
  updatedInChild?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelNoteBlockFilterInput | null > | null,
  or?: Array< ModelNoteBlockFilterInput | null > | null,
  not?: ModelNoteBlockFilterInput | null,
};

export type ModelTaskBlockFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelBlockTypeInput | null,
  milestoneID?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  status?: ModelBooleanInput | null,
  orderNo?: ModelIntInput | null,
  parentTaskBlockID?: ModelIDInput | null,
  isCompleted?: ModelBooleanInput | null,
  isArchived?: ModelBooleanInput | null,
  updatedInChild?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelTaskBlockFilterInput | null > | null,
  or?: Array< ModelTaskBlockFilterInput | null > | null,
  not?: ModelTaskBlockFilterInput | null,
};

export type ModelStandardContentBlockFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelBlockTypeInput | null,
  milestoneID?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  orderNo?: ModelIntInput | null,
  parentStandardContentBlockID?: ModelIDInput | null,
  isCompleted?: ModelBooleanInput | null,
  isArchived?: ModelBooleanInput | null,
  updatedInChild?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelStandardContentBlockFilterInput | null > | null,
  or?: Array< ModelStandardContentBlockFilterInput | null > | null,
  not?: ModelStandardContentBlockFilterInput | null,
};

export type ModelMultipleChoiceQuestionBlockFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelBlockTypeInput | null,
  milestoneID?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  option?: ModelStringInput | null,
  answer?: ModelStringInput | null,
  orderNo?: ModelIntInput | null,
  parentMultipleChoiceQuestionBlockID?: ModelIDInput | null,
  isCompleted?: ModelBooleanInput | null,
  isRequired?: ModelBooleanInput | null,
  isArchived?: ModelBooleanInput | null,
  updatedInChild?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelMultipleChoiceQuestionBlockFilterInput | null > | null,
  or?: Array< ModelMultipleChoiceQuestionBlockFilterInput | null > | null,
  not?: ModelMultipleChoiceQuestionBlockFilterInput | null,
};

export type ModelEventReminderFilterInput = {
  id?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  sendTimeDate?: ModelStringInput | null,
  daysFrom?: ModelIntInput | null,
  status?: ModelEventStatusInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelEventReminderFilterInput | null > | null,
  or?: Array< ModelEventReminderFilterInput | null > | null,
  not?: ModelEventReminderFilterInput | null,
};

export type ModelMeetingFilterInput = {
  id?: ModelIDInput | null,
  kinfolkTemplateID?: ModelIDInput | null,
  journeyID?: ModelIDInput | null,
  organizedByUserID?: ModelIDInput | null,
  status?: ModelMeetingStatusInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  attendeesEmail?: ModelStringInput | null,
  startTime?: ModelStringInput | null,
  endTime?: ModelStringInput | null,
  parentMeetingID?: ModelIDInput | null,
  updatedInChild?: ModelBooleanInput | null,
  eventDate?: ModelStringInput | null,
  duration?: ModelStringInput | null,
  link?: ModelStringInput | null,
  assigneeRole?: ModelAssigneeRoleInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelMeetingFilterInput | null > | null,
  or?: Array< ModelMeetingFilterInput | null > | null,
  not?: ModelMeetingFilterInput | null,
};

export type ModelPreboardingUserRelationFilterInput = {
  id?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  relationshipToMember?: ModelStringInput | null,
  birthday?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  formId?: ModelIDInput | null,
  healthCare?: ModelBooleanInput | null,
  dental?: ModelBooleanInput | null,
  optical?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelPreboardingUserRelationFilterInput | null > | null,
  or?: Array< ModelPreboardingUserRelationFilterInput | null > | null,
  not?: ModelPreboardingUserRelationFilterInput | null,
};

export type ModelDigestFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelDigestTypeInput | null,
  organizationId?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  frequency?: ModelFrequencyInput | null,
  channel?: ModelChannelInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelDigestFilterInput | null > | null,
  or?: Array< ModelDigestFilterInput | null > | null,
  not?: ModelDigestFilterInput | null,
};

export type ModelDigestConnection = {
  __typename: "ModelDigestConnection",
  items:  Array<Digest | null >,
  nextToken?: string | null,
};

export type ModelGqlMessageFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelStringInput | null,
  organizationId?: ModelIDInput | null,
  recipientId?: ModelIDInput | null,
  payload?: ModelStringInput | null,
  response?: ModelStringInput | null,
  status?: ModelStringInput | null,
  callbackId?: ModelStringInput | null,
  deliveryInfo?: ModelStringInput | null,
  respondedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelGqlMessageFilterInput | null > | null,
  or?: Array< ModelGqlMessageFilterInput | null > | null,
  not?: ModelGqlMessageFilterInput | null,
};

export type ModelGqlMessageConnection = {
  __typename: "ModelGqlMessageConnection",
  items:  Array<GqlMessage | null >,
  nextToken?: string | null,
};

export type ModelGqlMsTeamsUserReferenceFilterInput = {
  tenantId?: ModelStringInput | null,
  userEmail?: ModelStringInput | null,
  teamsUserId?: ModelStringInput | null,
  conversation?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  hasUndeliveredMessages?: ModelBooleanInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelGqlMsTeamsUserReferenceFilterInput | null > | null,
  or?: Array< ModelGqlMsTeamsUserReferenceFilterInput | null > | null,
  not?: ModelGqlMsTeamsUserReferenceFilterInput | null,
};

export type ModelGqlMsTeamsUserReferenceConnection = {
  __typename: "ModelGqlMsTeamsUserReferenceConnection",
  items:  Array<GqlMsTeamsUserReference | null >,
  nextToken?: string | null,
};

export type ModelGqlDataStoreFilterInput = {
  id?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  data?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelGqlDataStoreFilterInput | null > | null,
  or?: Array< ModelGqlDataStoreFilterInput | null > | null,
  not?: ModelGqlDataStoreFilterInput | null,
};

export type ModelGqlDataStoreConnection = {
  __typename: "ModelGqlDataStoreConnection",
  items:  Array<GqlDataStore | null >,
  nextToken?: string | null,
};

export type ModelGqlDataStoreRowFilterInput = {
  id?: ModelIDInput | null,
  dataStoreId?: ModelIDInput | null,
  data?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelGqlDataStoreRowFilterInput | null > | null,
  or?: Array< ModelGqlDataStoreRowFilterInput | null > | null,
  not?: ModelGqlDataStoreRowFilterInput | null,
};

export type ModelGqlDataStoreRowConnection = {
  __typename: "ModelGqlDataStoreRowConnection",
  items:  Array<GqlDataStoreRow | null >,
  nextToken?: string | null,
};

export type ModelGqlFormFilterInput = {
  id?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  dataStoreId?: ModelIDInput | null,
  data?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelGqlFormFilterInput | null > | null,
  or?: Array< ModelGqlFormFilterInput | null > | null,
  not?: ModelGqlFormFilterInput | null,
};

export type ModelGqlFormConnection = {
  __typename: "ModelGqlFormConnection",
  items:  Array<GqlForm | null >,
  nextToken?: string | null,
};

export type ModelGqlFormInstanceFilterInput = {
  id?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  formId?: ModelIDInput | null,
  assignedTo?: ModelStringInput | null,
  link?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  data?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelGqlFormInstanceFilterInput | null > | null,
  or?: Array< ModelGqlFormInstanceFilterInput | null > | null,
  not?: ModelGqlFormInstanceFilterInput | null,
};

export type ModelGqlFormInstanceConnection = {
  __typename: "ModelGqlFormInstanceConnection",
  items:  Array<GqlFormInstance | null >,
  nextToken?: string | null,
};

export type ModelUserShareInfoFilterInput = {
  id?: ModelIDInput | null,
  shareInfoID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserShareInfoFilterInput | null > | null,
  or?: Array< ModelUserShareInfoFilterInput | null > | null,
  not?: ModelUserShareInfoFilterInput | null,
};

export type ModelLearnMoreInfoFilterInput = {
  id?: ModelIDInput | null,
  shareInfoID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelLearnMoreInfoFilterInput | null > | null,
  or?: Array< ModelLearnMoreInfoFilterInput | null > | null,
  not?: ModelLearnMoreInfoFilterInput | null,
};

export type ModelUserInterestFilterInput = {
  id?: ModelIDInput | null,
  interestID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserInterestFilterInput | null > | null,
  or?: Array< ModelUserInterestFilterInput | null > | null,
  not?: ModelUserInterestFilterInput | null,
};

export type ModelSubscriptionShareInfoFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  organizationID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionShareInfoFilterInput | null > | null,
  or?: Array< ModelSubscriptionShareInfoFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionWorkStyleFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  whatIDoAtWork?: ModelSubscriptionStringInput | null,
  myFocusTimeIs?: ModelSubscriptionStringInput | null,
  giveReceivingFeedback?: ModelSubscriptionStringInput | null,
  digestionInformation?: ModelSubscriptionStringInput | null,
  inMyElement?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionWorkStyleFilterInput | null > | null,
  or?: Array< ModelSubscriptionWorkStyleFilterInput | null > | null,
};

export type ModelSubscriptionWorkPreferenceFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  giveMeAllTheContext?: ModelSubscriptionFloatInput | null,
  messageTimingPreferene?: ModelSubscriptionFloatInput | null,
  syncxVsAsync?: ModelSubscriptionFloatInput | null,
  talkTogetherVsAlone?: ModelSubscriptionFloatInput | null,
  recognitionPrivateVsPrivate?: ModelSubscriptionFloatInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionWorkPreferenceFilterInput | null > | null,
  or?: Array< ModelSubscriptionWorkPreferenceFilterInput | null > | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionInterestFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  organizationID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionInterestFilterInput | null > | null,
  or?: Array< ModelSubscriptionInterestFilterInput | null > | null,
};

export type ModelSubscriptionLocationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  streetAddress?: ModelSubscriptionStringInput | null,
  city?: ModelSubscriptionStringInput | null,
  state?: ModelSubscriptionStringInput | null,
  zipCode?: ModelSubscriptionIntInput | null,
  country?: ModelSubscriptionStringInput | null,
  locationType?: ModelSubscriptionStringInput | null,
  organizationID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionLocationFilterInput | null > | null,
  or?: Array< ModelSubscriptionLocationFilterInput | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionOrganizationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  slackAccessToken?: ModelSubscriptionStringInput | null,
  mergeCompanyToken?: ModelSubscriptionStringInput | null,
  atsToken?: ModelSubscriptionStringInput | null,
  atsAccountId?: ModelSubscriptionStringInput | null,
  hrisToken?: ModelSubscriptionStringInput | null,
  hrisAccountId?: ModelSubscriptionStringInput | null,
  hrisIntegratedAt?: ModelSubscriptionStringInput | null,
  slackUserAccessToken?: ModelSubscriptionStringInput | null,
  outlookTenantId?: ModelSubscriptionStringInput | null,
  msTeamsTenantId?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  activeUntil?: ModelSubscriptionStringInput | null,
  hasSubscription?: ModelSubscriptionBooleanInput | null,
  preboarding?: ModelSubscriptionBooleanInput | null,
  features?: ModelSubscriptionStringInput | null,
  globalDataStores?: ModelSubscriptionStringInput | null,
  settings?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionOrganizationFilterInput | null > | null,
  or?: Array< ModelSubscriptionOrganizationFilterInput | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionUserFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  offset?: ModelSubscriptionFloatInput | null,
  firstName?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  phone?: ModelSubscriptionStringInput | null,
  sendWelcomeEmail?: ModelSubscriptionBooleanInput | null,
  personalEmail?: ModelSubscriptionStringInput | null,
  jobTitle?: ModelSubscriptionStringInput | null,
  birthday?: ModelSubscriptionStringInput | null,
  startDate?: ModelSubscriptionStringInput | null,
  homeLocation?: ModelSubscriptionStringInput | null,
  team?: ModelSubscriptionStringInput | null,
  photoUrl?: ModelSubscriptionStringInput | null,
  pronoun?: ModelSubscriptionStringInput | null,
  aboutMe?: ModelSubscriptionStringInput | null,
  mostHappy?: ModelSubscriptionStringInput | null,
  socialMediaUrl?: ModelSubscriptionStringInput | null,
  slackUserId?: ModelSubscriptionStringInput | null,
  isFirstLogin?: ModelSubscriptionBooleanInput | null,
  isHomeModelViewed?: ModelSubscriptionBooleanInput | null,
  isProfileModelViewed?: ModelSubscriptionBooleanInput | null,
  type?: ModelSubscriptionStringInput | null,
  isActive?: ModelSubscriptionBooleanInput | null,
  lastName?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserFilterInput | null > | null,
  userOrganizationId?: ModelSubscriptionIDInput | null,
  userWorkPreferenceId?: ModelSubscriptionIDInput | null,
  userWorkStyleId?: ModelSubscriptionIDInput | null,
  userWorkLocationId?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionJourneyFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  createdByUserID?: ModelSubscriptionIDInput | null,
  assignedUserID?: ModelSubscriptionIDInput | null,
  mode?: ModelSubscriptionStringInput | null,
  startDate?: ModelSubscriptionStringInput | null,
  journeyProgress?: ModelSubscriptionIntInput | null,
  parentJourneyID?: ModelSubscriptionIDInput | null,
  collaborator?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  badgeID?: ModelSubscriptionIDInput | null,
  customBadgeName?: ModelSubscriptionStringInput | null,
  lockChapters?: ModelSubscriptionBooleanInput | null,
  type?: ModelSubscriptionStringInput | null,
  publicLink?: ModelSubscriptionStringInput | null,
  eventStartedAt?: ModelSubscriptionStringInput | null,
  userStartedAt?: ModelSubscriptionStringInput | null,
  userCompletedAt?: ModelSubscriptionStringInput | null,
  isArchived?: ModelSubscriptionBooleanInput | null,
  preboarding?: ModelSubscriptionBooleanInput | null,
  organizationID?: ModelSubscriptionIDInput | null,
  image?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionJourneyFilterInput | null > | null,
  or?: Array< ModelSubscriptionJourneyFilterInput | null > | null,
};

export type ModelSubscriptionBadgeFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  iconUrl?: ModelSubscriptionStringInput | null,
  isArchived?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionBadgeFilterInput | null > | null,
  or?: Array< ModelSubscriptionBadgeFilterInput | null > | null,
};

export type ModelSubscriptionUserBadgeFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  playbookId?: ModelSubscriptionIDInput | null,
  userID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserBadgeFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserBadgeFilterInput | null > | null,
};

export type ModelSubscriptionJourneyCollaboratorFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  assigneeRole?: ModelSubscriptionStringInput | null,
  journeyAccess?: ModelSubscriptionStringInput | null,
  assignedUserID?: ModelSubscriptionIDInput | null,
  actionID?: ModelSubscriptionStringInput | null,
  meetingID?: ModelSubscriptionStringInput | null,
  updatedInChild?: ModelSubscriptionBooleanInput | null,
  journeyID?: ModelSubscriptionIDInput | null,
  kinfolkTemplateID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionJourneyCollaboratorFilterInput | null > | null,
  or?: Array< ModelSubscriptionJourneyCollaboratorFilterInput | null > | null,
};

export type ModelSubscriptionKinfolkTemplateFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  image?: ModelSubscriptionStringInput | null,
  isArchived?: ModelSubscriptionBooleanInput | null,
  status?: ModelSubscriptionStringInput | null,
  orderNo?: ModelSubscriptionIntInput | null,
  type?: ModelSubscriptionStringInput | null,
  mode?: ModelSubscriptionStringInput | null,
  isPublic?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionKinfolkTemplateFilterInput | null > | null,
  or?: Array< ModelSubscriptionKinfolkTemplateFilterInput | null > | null,
};

export type ModelSubscriptionMilestoneFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  kinfolkTemplateID?: ModelSubscriptionIDInput | null,
  isCompleted?: ModelSubscriptionBooleanInput | null,
  isArchived?: ModelSubscriptionBooleanInput | null,
  journeyID?: ModelSubscriptionIDInput | null,
  orderNo?: ModelSubscriptionIntInput | null,
  parentMilestoneID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMilestoneFilterInput | null > | null,
  or?: Array< ModelSubscriptionMilestoneFilterInput | null > | null,
};

export type ModelSubscriptionEmbeddedContentBlockFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  milestoneID?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  resourceLink?: ModelSubscriptionStringInput | null,
  isFormLinked?: ModelSubscriptionBooleanInput | null,
  orderNo?: ModelSubscriptionIntInput | null,
  parentEmbeddedContentBlockID?: ModelSubscriptionIDInput | null,
  isCompleted?: ModelSubscriptionBooleanInput | null,
  isArchived?: ModelSubscriptionBooleanInput | null,
  updatedInChild?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionEmbeddedContentBlockFilterInput | null > | null,
  or?: Array< ModelSubscriptionEmbeddedContentBlockFilterInput | null > | null,
};

export type ModelSubscriptionFreeTextQuestionBlockFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  milestoneID?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  text?: ModelSubscriptionStringInput | null,
  orderNo?: ModelSubscriptionIntInput | null,
  parentFreeTextQuestionBlockID?: ModelSubscriptionIDInput | null,
  isCompleted?: ModelSubscriptionBooleanInput | null,
  isArchived?: ModelSubscriptionBooleanInput | null,
  updatedInChild?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionFreeTextQuestionBlockFilterInput | null > | null,
  or?: Array< ModelSubscriptionFreeTextQuestionBlockFilterInput | null > | null,
};

export type ModelSubscriptionNoteBlockFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  milestoneID?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  text?: ModelSubscriptionStringInput | null,
  orderNo?: ModelSubscriptionIntInput | null,
  parentNoteBlockID?: ModelSubscriptionIDInput | null,
  isCompleted?: ModelSubscriptionBooleanInput | null,
  isPrivate?: ModelSubscriptionBooleanInput | null,
  isArchived?: ModelSubscriptionBooleanInput | null,
  updatedInChild?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionNoteBlockFilterInput | null > | null,
  or?: Array< ModelSubscriptionNoteBlockFilterInput | null > | null,
};

export type ModelSubscriptionTaskBlockFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  milestoneID?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionBooleanInput | null,
  orderNo?: ModelSubscriptionIntInput | null,
  parentTaskBlockID?: ModelSubscriptionIDInput | null,
  isCompleted?: ModelSubscriptionBooleanInput | null,
  isArchived?: ModelSubscriptionBooleanInput | null,
  updatedInChild?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTaskBlockFilterInput | null > | null,
  or?: Array< ModelSubscriptionTaskBlockFilterInput | null > | null,
};

export type ModelSubscriptionStandardContentBlockFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  milestoneID?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  orderNo?: ModelSubscriptionIntInput | null,
  parentStandardContentBlockID?: ModelSubscriptionIDInput | null,
  isCompleted?: ModelSubscriptionBooleanInput | null,
  isArchived?: ModelSubscriptionBooleanInput | null,
  updatedInChild?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionStandardContentBlockFilterInput | null > | null,
  or?: Array< ModelSubscriptionStandardContentBlockFilterInput | null > | null,
};

export type ModelSubscriptionMultipleChoiceQuestionBlockFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  milestoneID?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  option?: ModelSubscriptionStringInput | null,
  answer?: ModelSubscriptionStringInput | null,
  orderNo?: ModelSubscriptionIntInput | null,
  parentMultipleChoiceQuestionBlockID?: ModelSubscriptionIDInput | null,
  isCompleted?: ModelSubscriptionBooleanInput | null,
  isRequired?: ModelSubscriptionBooleanInput | null,
  isArchived?: ModelSubscriptionBooleanInput | null,
  updatedInChild?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMultipleChoiceQuestionBlockFilterInput | null > | null,
  or?: Array< ModelSubscriptionMultipleChoiceQuestionBlockFilterInput | null > | null,
};

export type ModelSubscriptionEventFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionIDInput | null,
  formId?: ModelSubscriptionIDInput | null,
  formInstanceId?: ModelSubscriptionIDInput | null,
  daysFrom?: ModelSubscriptionIntInput | null,
  daysFromDueDate?: ModelSubscriptionIntInput | null,
  type?: ModelSubscriptionStringInput | null,
  role?: ModelSubscriptionStringInput | null,
  emailTo?: ModelSubscriptionStringInput | null,
  title?: ModelSubscriptionStringInput | null,
  message?: ModelSubscriptionStringInput | null,
  sendTime?: ModelSubscriptionStringInput | null,
  sendTimeDate?: ModelSubscriptionStringInput | null,
  dueDate?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  channelId?: ModelSubscriptionStringInput | null,
  parentId?: ModelSubscriptionStringInput | null,
  channel?: ModelSubscriptionStringInput | null,
  cc?: ModelSubscriptionStringInput | null,
  archived?: ModelSubscriptionBooleanInput | null,
  updatedInChild?: ModelSubscriptionBooleanInput | null,
  journeyID?: ModelSubscriptionIDInput | null,
  kinfolkTemplateID?: ModelSubscriptionIDInput | null,
  meta?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionEventFilterInput | null > | null,
  or?: Array< ModelSubscriptionEventFilterInput | null > | null,
};

export type ModelSubscriptionEventReminderFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  eventId?: ModelSubscriptionIDInput | null,
  sendTimeDate?: ModelSubscriptionStringInput | null,
  daysFrom?: ModelSubscriptionIntInput | null,
  status?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionEventReminderFilterInput | null > | null,
  or?: Array< ModelSubscriptionEventReminderFilterInput | null > | null,
};

export type ModelSubscriptionMeetingFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  kinfolkTemplateID?: ModelSubscriptionIDInput | null,
  journeyID?: ModelSubscriptionIDInput | null,
  organizedByUserID?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  title?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  attendeesEmail?: ModelSubscriptionStringInput | null,
  startTime?: ModelSubscriptionStringInput | null,
  endTime?: ModelSubscriptionStringInput | null,
  parentMeetingID?: ModelSubscriptionIDInput | null,
  updatedInChild?: ModelSubscriptionBooleanInput | null,
  eventDate?: ModelSubscriptionStringInput | null,
  duration?: ModelSubscriptionStringInput | null,
  link?: ModelSubscriptionStringInput | null,
  assigneeRole?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMeetingFilterInput | null > | null,
  or?: Array< ModelSubscriptionMeetingFilterInput | null > | null,
};

export type ModelSubscriptionSecretKeyManagerFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  organizationID?: ModelSubscriptionIDInput | null,
  clientEmail?: ModelSubscriptionStringInput | null,
  privateKey?: ModelSubscriptionStringInput | null,
  adminEmail?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionSecretKeyManagerFilterInput | null > | null,
  or?: Array< ModelSubscriptionSecretKeyManagerFilterInput | null > | null,
};

export type ModelSubscriptionPreboardingUserFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionIDInput | null,
  isSubmitted?: ModelSubscriptionBooleanInput | null,
  consent?: ModelSubscriptionBooleanInput | null,
  journeyId?: ModelSubscriptionIDInput | null,
  personalEmail?: ModelSubscriptionStringInput | null,
  photoUrl?: ModelSubscriptionStringInput | null,
  bankName?: ModelSubscriptionStringInput | null,
  bankBranch?: ModelSubscriptionStringInput | null,
  bankSortCode?: ModelSubscriptionStringInput | null,
  bankAccountNumber?: ModelSubscriptionStringInput | null,
  bankAccountName?: ModelSubscriptionStringInput | null,
  buildingSocietyRoll?: ModelSubscriptionStringInput | null,
  street1?: ModelSubscriptionStringInput | null,
  street2?: ModelSubscriptionStringInput | null,
  city?: ModelSubscriptionStringInput | null,
  state?: ModelSubscriptionStringInput | null,
  zip?: ModelSubscriptionStringInput | null,
  country?: ModelSubscriptionStringInput | null,
  birthday?: ModelSubscriptionStringInput | null,
  gender?: ModelSubscriptionStringInput | null,
  title?: ModelSubscriptionStringInput | null,
  nationality?: ModelSubscriptionStringInput | null,
  mobileNumber?: ModelSubscriptionStringInput | null,
  niNumber?: ModelSubscriptionStringInput | null,
  laptopPreference?: ModelSubscriptionStringInput | null,
  secondMonitor?: ModelSubscriptionBooleanInput | null,
  corporateCard?: ModelSubscriptionBooleanInput | null,
  corporatePhone?: ModelSubscriptionStringInput | null,
  healthCare?: ModelSubscriptionBooleanInput | null,
  dental?: ModelSubscriptionBooleanInput | null,
  optical?: ModelSubscriptionBooleanInput | null,
  jobDeclaration?: ModelSubscriptionStringInput | null,
  expatDeclaration?: ModelSubscriptionStringInput | null,
  studentLoanRepayment?: ModelSubscriptionBooleanInput | null,
  studentLoanType?: ModelSubscriptionStringInput | null,
  pensionScheme?: ModelSubscriptionBooleanInput | null,
  pensionContribution?: ModelSubscriptionIntInput | null,
  passportNumber?: ModelSubscriptionStringInput | null,
  passportIssueDate?: ModelSubscriptionStringInput | null,
  passportExpiryDate?: ModelSubscriptionStringInput | null,
  passportIssuingCountry?: ModelSubscriptionStringInput | null,
  confirmation?: ModelSubscriptionBooleanInput | null,
  pensionSchemeOpt?: ModelSubscriptionStringInput | null,
  emergencyContactName?: ModelSubscriptionStringInput | null,
  emergencyContactRelationship?: ModelSubscriptionStringInput | null,
  emergencyContactPhone?: ModelSubscriptionStringInput | null,
  emergencyContactEmailAddress?: ModelSubscriptionStringInput | null,
  emergencyContactStreet1?: ModelSubscriptionStringInput | null,
  emergencyContactStreet2?: ModelSubscriptionStringInput | null,
  emergencyContactCity?: ModelSubscriptionStringInput | null,
  emergencyContactState?: ModelSubscriptionStringInput | null,
  emergencyContactZip?: ModelSubscriptionStringInput | null,
  emergencyContactCountry?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPreboardingUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionPreboardingUserFilterInput | null > | null,
};

export type ModelSubscriptionPreboardingUserRelationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  firstName?: ModelSubscriptionStringInput | null,
  lastName?: ModelSubscriptionStringInput | null,
  relationshipToMember?: ModelSubscriptionStringInput | null,
  birthday?: ModelSubscriptionStringInput | null,
  userId?: ModelSubscriptionIDInput | null,
  formId?: ModelSubscriptionIDInput | null,
  healthCare?: ModelSubscriptionBooleanInput | null,
  dental?: ModelSubscriptionBooleanInput | null,
  optical?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPreboardingUserRelationFilterInput | null > | null,
  or?: Array< ModelSubscriptionPreboardingUserRelationFilterInput | null > | null,
};

export type ModelSubscriptionDigestFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  organizationId?: ModelSubscriptionStringInput | null,
  userId?: ModelSubscriptionIDInput | null,
  frequency?: ModelSubscriptionStringInput | null,
  channel?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionDigestFilterInput | null > | null,
  or?: Array< ModelSubscriptionDigestFilterInput | null > | null,
};

export type ModelSubscriptionGqlMessageFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  organizationId?: ModelSubscriptionIDInput | null,
  recipientId?: ModelSubscriptionIDInput | null,
  payload?: ModelSubscriptionStringInput | null,
  response?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  callbackId?: ModelSubscriptionStringInput | null,
  deliveryInfo?: ModelSubscriptionStringInput | null,
  respondedAt?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionGqlMessageFilterInput | null > | null,
  or?: Array< ModelSubscriptionGqlMessageFilterInput | null > | null,
};

export type ModelSubscriptionGqlMsTeamsUserReferenceFilterInput = {
  tenantId?: ModelSubscriptionStringInput | null,
  userEmail?: ModelSubscriptionStringInput | null,
  teamsUserId?: ModelSubscriptionStringInput | null,
  conversation?: ModelSubscriptionStringInput | null,
  userId?: ModelSubscriptionIDInput | null,
  organizationId?: ModelSubscriptionIDInput | null,
  hasUndeliveredMessages?: ModelSubscriptionBooleanInput | null,
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionGqlMsTeamsUserReferenceFilterInput | null > | null,
  or?: Array< ModelSubscriptionGqlMsTeamsUserReferenceFilterInput | null > | null,
};

export type ModelSubscriptionGqlDataStoreFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  organizationId?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  data?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionGqlDataStoreFilterInput | null > | null,
  or?: Array< ModelSubscriptionGqlDataStoreFilterInput | null > | null,
};

export type ModelSubscriptionGqlDataStoreRowFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  dataStoreId?: ModelSubscriptionIDInput | null,
  data?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionGqlDataStoreRowFilterInput | null > | null,
  or?: Array< ModelSubscriptionGqlDataStoreRowFilterInput | null > | null,
};

export type ModelSubscriptionGqlFormFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  organizationId?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  dataStoreId?: ModelSubscriptionIDInput | null,
  data?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionGqlFormFilterInput | null > | null,
  or?: Array< ModelSubscriptionGqlFormFilterInput | null > | null,
};

export type ModelSubscriptionGqlFormInstanceFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  organizationId?: ModelSubscriptionIDInput | null,
  formId?: ModelSubscriptionIDInput | null,
  assignedTo?: ModelSubscriptionStringInput | null,
  link?: ModelSubscriptionStringInput | null,
  slug?: ModelSubscriptionStringInput | null,
  data?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionGqlFormInstanceFilterInput | null > | null,
  or?: Array< ModelSubscriptionGqlFormInstanceFilterInput | null > | null,
};

export type ModelSubscriptionUserShareInfoFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  shareInfoID?: ModelSubscriptionIDInput | null,
  userID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserShareInfoFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserShareInfoFilterInput | null > | null,
};

export type ModelSubscriptionLearnMoreInfoFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  shareInfoID?: ModelSubscriptionIDInput | null,
  userID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionLearnMoreInfoFilterInput | null > | null,
  or?: Array< ModelSubscriptionLearnMoreInfoFilterInput | null > | null,
};

export type ModelSubscriptionUserInterestFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  interestID?: ModelSubscriptionIDInput | null,
  userID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserInterestFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserInterestFilterInput | null > | null,
};

export type listDetailedUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type listDetailedUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      firstName?: string | null,
      email: string,
      jobTitle?: string | null,
      birthday: string,
      startDate?: string | null,
      homeLocation?: string | null,
      team?: string | null,
      isActive?: boolean | null,
      photoUrl: string,
      pronoun: Pronoun,
      aboutMe?: string | null,
      mostHappy?: string | null,
      socialMediaUrl?: string | null,
      slackUserId?: string | null,
      type: UserType,
      isFirstLogin?: boolean | null,
      isHomeModelViewed?: boolean | null,
      isProfileModelViewed?: boolean | null,
      Organization:  {
        __typename: "Organization",
        id: string,
        name: string,
        preboarding?: boolean | null,
        slackAccessToken?: string | null,
        mergeCompanyToken?: string | null,
        outlookTenantId?: string | null,
        msTeamsTenantId?: string | null,
        createdAt: string,
        updatedAt: string,
        activeUntil: string,
        hasSubscription?: boolean | null,
      },
      WorkLocation?:  {
        __typename: "Location",
        id: string,
        name?: string | null,
        streetAddress?: string | null,
        city?: string | null,
        state?: string | null,
        zipCode?: number | null,
        country?: string | null,
        organizationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      Interests?:  {
        __typename: "ModelUserInterestConnection",
        items:  Array< {
          __typename: "UserInterest",
          id: string,
          interestID: string,
          interest:  {
            __typename: "Interest",
            id: string,
            name: string,
          },
        } | null >,
      } | null,
      WorkPreference:  {
        __typename: "WorkPreference",
        id: string,
        giveMeAllTheContext: number,
        messageTimingPreferene: number,
        syncxVsAsync: number,
        talkTogetherVsAlone: number,
        recognitionPrivateVsPrivate: number,
        createdAt: string,
        updatedAt: string,
      },
      WorkStyle:  {
        __typename: "WorkStyle",
        id: string,
        whatIDoAtWork?: string | null,
        myFocusTimeIs?: string | null,
        giveReceivingFeedback?: string | null,
        digestionInformation?: string | null,
        inMyElement?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      lastName?: string | null,
      ShareInfoOn?:  {
        __typename: "ModelUserShareInfoConnection",
        items:  Array< {
          __typename: "UserShareInfo",
          id: string,
          shareInfoID: string,
          shareInfo:  {
            __typename: "ShareInfo",
            id: string,
            name: string,
          },
        } | null >,
      } | null,
      UserBadges?:  {
        __typename: "ModelUserBadgeConnection",
        items:  Array< {
          __typename: "UserBadge",
          createdAt: string,
          id: string,
          updatedAt: string,
          playbookId: string,
          userID: string,
        } | null >,
      } | null,
      LearnMoreOn?:  {
        __typename: "ModelLearnMoreInfoConnection",
        items:  Array< {
          __typename: "LearnMoreInfo",
          id: string,
          shareInfoID: string,
          shareInfo:  {
            __typename: "ShareInfo",
            id: string,
            name: string,
          },
        } | null >,
      } | null,
      createdAt: string,
      updatedAt: string,
      userOrganizationId: string,
      userWorkPreferenceId: string,
      userWorkStyleId: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type getCompanyAdminOfOrganizationQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type getCompanyAdminOfOrganizationQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      email: string,
      Organization:  {
        __typename: "Organization",
        name: string,
      },
    } | null >,
  } | null,
};

export type listInitialUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type listInitialUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      personalEmail?: string | null,
      jobTitle?: string | null,
      photoUrl: string,
      type: UserType,
      isFirstLogin?: boolean | null,
      slackUserId?: string | null,
      homeLocation?: string | null,
      isActive?: boolean | null,
      team?: string | null,
      startDate?: string | null,
      userOrganizationId: string,
    } | null >,
  } | null,
};

export type listDetailedUsersWithNameAndIdQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type listDetailedUsersWithNameAndIdQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      team?: string | null,
      personalEmail?: string | null,
      photoUrl: string,
      startDate?: string | null,
      userOrganizationId: string,
      slackUserId?: string | null,
      Interests?:  {
        __typename: "ModelUserInterestConnection",
        items:  Array< {
          __typename: "UserInterest",
          interest:  {
            __typename: "Interest",
            name: string,
          },
        } | null >,
      } | null,
    } | null >,
  } | null,
};

export type listDetailedUsersWithPhotoUrlQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type listDetailedUsersWithPhotoUrlQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      photoUrl: string,
    } | null >,
  } | null,
};

export type getUserProfileDataQueryVariables = {
  id: string,
};

export type getUserProfileDataQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    firstName?: string | null,
    email: string,
    jobTitle?: string | null,
    birthday: string,
    startDate?: string | null,
    homeLocation?: string | null,
    team?: string | null,
    photoUrl: string,
    pronoun: Pronoun,
    aboutMe?: string | null,
    mostHappy?: string | null,
    socialMediaUrl?: string | null,
    slackUserId?: string | null,
    type: UserType,
    Organization:  {
      __typename: "Organization",
      id: string,
      name: string,
      preboarding?: boolean | null,
      slackAccessToken?: string | null,
      mergeCompanyToken?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    WorkLocation?:  {
      __typename: "Location",
      id: string,
      name?: string | null,
      streetAddress?: string | null,
      city?: string | null,
      state?: string | null,
      zipCode?: number | null,
      country?: string | null,
      organizationID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    Interests?:  {
      __typename: "ModelUserInterestConnection",
      items:  Array< {
        __typename: "UserInterest",
        id: string,
        interestID: string,
        interest:  {
          __typename: "Interest",
          id: string,
          name: string,
        },
      } | null >,
    } | null,
    WorkPreference:  {
      __typename: "WorkPreference",
      id: string,
      giveMeAllTheContext: number,
      messageTimingPreferene: number,
      syncxVsAsync: number,
      talkTogetherVsAlone: number,
      recognitionPrivateVsPrivate: number,
      createdAt: string,
      updatedAt: string,
    },
    WorkStyle:  {
      __typename: "WorkStyle",
      id: string,
      whatIDoAtWork?: string | null,
      myFocusTimeIs?: string | null,
      giveReceivingFeedback?: string | null,
      digestionInformation?: string | null,
      inMyElement?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    lastName?: string | null,
    ShareInfoOn?:  {
      __typename: "ModelUserShareInfoConnection",
      items:  Array< {
        __typename: "UserShareInfo",
        id: string,
        shareInfoID: string,
        shareInfo:  {
          __typename: "ShareInfo",
          id: string,
          name: string,
        },
      } | null >,
    } | null,
    LearnMoreOn?:  {
      __typename: "ModelLearnMoreInfoConnection",
      items:  Array< {
        __typename: "LearnMoreInfo",
        id: string,
        shareInfoID: string,
        shareInfo:  {
          __typename: "ShareInfo",
          id: string,
          name: string,
        },
      } | null >,
    } | null,
    UserBadges?:  {
      __typename: "ModelUserBadgeConnection",
      items:  Array< {
        __typename: "UserBadge",
        createdAt: string,
        id: string,
        updatedAt: string,
        playbookId: string,
        userID: string,
      } | null >,
    } | null,
    createdAt: string,
    updatedAt: string,
    userOrganizationId: string,
    userWorkPreferenceId: string,
    userWorkStyleId: string,
  } | null,
};

export type createMilestoneWithMilestoneIdReturnMutationVariables = {
  input: CreateMilestoneInput,
  condition?: ModelMilestoneConditionInput | null,
};

export type createMilestoneWithMilestoneIdReturnMutation = {
  createMilestone?:  {
    __typename: "Milestone",
    id: string,
  } | null,
};

export type listMilestoneWithBlocksByJourneyIdQueryVariables = {
  journeyID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMilestoneFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type listMilestoneWithBlocksByJourneyIdQuery = {
  listMilestoneByJourneyId?:  {
    __typename: "ModelMilestoneConnection",
    items:  Array< {
      __typename: "Milestone",
      id: string,
      name: string,
      isCompleted?: boolean | null,
      parentMilestoneID?: string | null,
      orderNo: number,
      isArchived?: boolean | null,
      EmbeddedContentBlocks?:  {
        __typename: "ModelEmbeddedContentBlockConnection",
        items:  Array< {
          __typename: "EmbeddedContentBlock",
          id: string,
          isCompleted?: boolean | null,
          milestoneID: string,
          description?: string | null,
          orderNo: number,
          resourceLink: string,
          isFormLinked?: boolean | null,
          title: string,
          type: BlockType,
        } | null >,
      } | null,
      FreeTextQuestionBlocks?:  {
        __typename: "ModelFreeTextQuestionBlockConnection",
        items:  Array< {
          __typename: "FreeTextQuestionBlock",
          description?: string | null,
          id: string,
          isCompleted?: boolean | null,
          milestoneID: string,
          orderNo: number,
          text?: string | null,
          title: string,
          type: BlockType,
        } | null >,
      } | null,
      MultipleChoiceQuestionBlocks?:  {
        __typename: "ModelMultipleChoiceQuestionBlockConnection",
        items:  Array< {
          __typename: "MultipleChoiceQuestionBlock",
          answer?: Array< string > | null,
          description?: string | null,
          id: string,
          isCompleted?: boolean | null,
          isRequired?: boolean | null,
          milestoneID: string,
          option?: Array< string > | null,
          orderNo: number,
          title: string,
          type: BlockType,
        } | null >,
      } | null,
      NoteBlocks?:  {
        __typename: "ModelNoteBlockConnection",
        items:  Array< {
          __typename: "NoteBlock",
          description?: string | null,
          id: string,
          isCompleted?: boolean | null,
          isPrivate?: boolean | null,
          milestoneID: string,
          orderNo: number,
          text?: string | null,
          title: string,
          type: BlockType,
        } | null >,
      } | null,
      StandardContentBlocks?:  {
        __typename: "ModelStandardContentBlockConnection",
        items:  Array< {
          __typename: "StandardContentBlock",
          title: string,
          orderNo: number,
          milestoneID: string,
          isCompleted?: boolean | null,
          id: string,
          description?: string | null,
          type: BlockType,
        } | null >,
      } | null,
      TaskBlocks?:  {
        __typename: "ModelTaskBlockConnection",
        items:  Array< {
          __typename: "TaskBlock",
          description?: string | null,
          id: string,
          isCompleted?: boolean | null,
          milestoneID: string,
          orderNo: number,
          status?: boolean | null,
          title: string,
          type: BlockType,
        } | null >,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type listMilestoneWithBlocksByKinfolkTemplateIdQueryVariables = {
  kinfolkTemplateID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMilestoneFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type listMilestoneWithBlocksByKinfolkTemplateIdQuery = {
  listMilestoneByKinfolkTemplateId?:  {
    __typename: "ModelMilestoneConnection",
    items:  Array< {
      __typename: "Milestone",
      id: string,
      name: string,
      isCompleted?: boolean | null,
      parentMilestoneID?: string | null,
      orderNo: number,
      isArchived?: boolean | null,
      EmbeddedContentBlocks?:  {
        __typename: "ModelEmbeddedContentBlockConnection",
        items:  Array< {
          __typename: "EmbeddedContentBlock",
          id: string,
          isCompleted?: boolean | null,
          milestoneID: string,
          description?: string | null,
          orderNo: number,
          resourceLink: string,
          title: string,
          type: BlockType,
        } | null >,
      } | null,
      FreeTextQuestionBlocks?:  {
        __typename: "ModelFreeTextQuestionBlockConnection",
        items:  Array< {
          __typename: "FreeTextQuestionBlock",
          description?: string | null,
          id: string,
          isCompleted?: boolean | null,
          milestoneID: string,
          orderNo: number,
          text?: string | null,
          title: string,
          type: BlockType,
        } | null >,
      } | null,
      MultipleChoiceQuestionBlocks?:  {
        __typename: "ModelMultipleChoiceQuestionBlockConnection",
        items:  Array< {
          __typename: "MultipleChoiceQuestionBlock",
          answer?: Array< string > | null,
          description?: string | null,
          id: string,
          isCompleted?: boolean | null,
          isRequired?: boolean | null,
          milestoneID: string,
          option?: Array< string > | null,
          orderNo: number,
          title: string,
          type: BlockType,
        } | null >,
      } | null,
      NoteBlocks?:  {
        __typename: "ModelNoteBlockConnection",
        items:  Array< {
          __typename: "NoteBlock",
          description?: string | null,
          id: string,
          isCompleted?: boolean | null,
          isPrivate?: boolean | null,
          milestoneID: string,
          orderNo: number,
          text?: string | null,
          title: string,
          type: BlockType,
        } | null >,
      } | null,
      StandardContentBlocks?:  {
        __typename: "ModelStandardContentBlockConnection",
        items:  Array< {
          __typename: "StandardContentBlock",
          title: string,
          orderNo: number,
          milestoneID: string,
          isCompleted?: boolean | null,
          id: string,
          description?: string | null,
          type: BlockType,
        } | null >,
      } | null,
      TaskBlocks?:  {
        __typename: "ModelTaskBlockConnection",
        items:  Array< {
          __typename: "TaskBlock",
          description?: string | null,
          id: string,
          isCompleted?: boolean | null,
          milestoneID: string,
          orderNo: number,
          status?: boolean | null,
          title: string,
          type: BlockType,
        } | null >,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type createStandardContentBlockWithIdReturnMutationVariables = {
  input: CreateStandardContentBlockInput,
  condition?: ModelStandardContentBlockConditionInput | null,
};

export type createStandardContentBlockWithIdReturnMutation = {
  createStandardContentBlock?:  {
    __typename: "StandardContentBlock",
    id: string,
  } | null,
};

export type createMultipleChoiceQuestionBlockWithIdReturnMutationVariables = {
  input: CreateMultipleChoiceQuestionBlockInput,
  condition?: ModelMultipleChoiceQuestionBlockConditionInput | null,
};

export type createMultipleChoiceQuestionBlockWithIdReturnMutation = {
  createMultipleChoiceQuestionBlock?:  {
    __typename: "MultipleChoiceQuestionBlock",
    id: string,
  } | null,
};

export type createTaskBlockWithIdReturnMutationVariables = {
  input: CreateTaskBlockInput,
  condition?: ModelTaskBlockConditionInput | null,
};

export type createTaskBlockWithIdReturnMutation = {
  createTaskBlock?:  {
    __typename: "TaskBlock",
    id: string,
  } | null,
};

export type createNoteBlockWithIdReturnMutationVariables = {
  input: CreateNoteBlockInput,
  condition?: ModelNoteBlockConditionInput | null,
};

export type createNoteBlockWithIdReturnMutation = {
  createNoteBlock?:  {
    __typename: "NoteBlock",
    id: string,
  } | null,
};

export type createFreeTextQuestionBlockWithIdReturnMutationVariables = {
  input: CreateFreeTextQuestionBlockInput,
  condition?: ModelFreeTextQuestionBlockConditionInput | null,
};

export type createFreeTextQuestionBlockWithIdReturnMutation = {
  createFreeTextQuestionBlock?:  {
    __typename: "FreeTextQuestionBlock",
    id: string,
  } | null,
};

export type createEmbeddedContentBlockWithIdReturnMutationVariables = {
  input: CreateEmbeddedContentBlockInput,
  condition?: ModelEmbeddedContentBlockConditionInput | null,
};

export type createEmbeddedContentBlockWithIdReturnMutation = {
  createEmbeddedContentBlock?:  {
    __typename: "EmbeddedContentBlock",
    id: string,
  } | null,
};

export type updateStandardContentBlockWithIdReturnMutationVariables = {
  input: UpdateStandardContentBlockInput,
  condition?: ModelStandardContentBlockConditionInput | null,
};

export type updateStandardContentBlockWithIdReturnMutation = {
  updateStandardContentBlock?:  {
    __typename: "StandardContentBlock",
    id: string,
  } | null,
};

export type updateFreeTextQuestionBlockWithIdReturnMutationVariables = {
  input: UpdateFreeTextQuestionBlockInput,
  condition?: ModelFreeTextQuestionBlockConditionInput | null,
};

export type updateFreeTextQuestionBlockWithIdReturnMutation = {
  updateFreeTextQuestionBlock?:  {
    __typename: "FreeTextQuestionBlock",
    id: string,
  } | null,
};

export type updateNoteBlockWithIdReturnMutationVariables = {
  input: UpdateNoteBlockInput,
  condition?: ModelNoteBlockConditionInput | null,
};

export type updateNoteBlockWithIdReturnMutation = {
  updateNoteBlock?:  {
    __typename: "NoteBlock",
    id: string,
  } | null,
};

export type updateTaskBlockWithIdReturnMutationVariables = {
  input: UpdateTaskBlockInput,
  condition?: ModelTaskBlockConditionInput | null,
};

export type updateTaskBlockWithIdReturnMutation = {
  updateTaskBlock?:  {
    __typename: "TaskBlock",
    id: string,
  } | null,
};

export type updateMilestoneWithIdReturnMutationVariables = {
  input: UpdateMilestoneInput,
  condition?: ModelMilestoneConditionInput | null,
};

export type updateMilestoneWithIdReturnMutation = {
  updateMilestone?:  {
    __typename: "Milestone",
    id: string,
  } | null,
};

export type updateMultipleChoiceQuestionBlockWithIdReturnMutationVariables = {
  input: UpdateMultipleChoiceQuestionBlockInput,
  condition?: ModelMultipleChoiceQuestionBlockConditionInput | null,
};

export type updateMultipleChoiceQuestionBlockWithIdReturnMutation = {
  updateMultipleChoiceQuestionBlock?:  {
    __typename: "MultipleChoiceQuestionBlock",
    id: string,
  } | null,
};

export type listUserJourneysQueryVariables = {
  filter?: ModelJourneyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type listUserJourneysQuery = {
  listJourneys?:  {
    __typename: "ModelJourneyConnection",
    items:  Array< {
      __typename: "Journey",
      id: string,
      name: string,
      status: JourneyStatus,
      mode: PlaybookMode,
      publicLink?: string | null,
      parentJourneyID?: string | null,
      organizationID: string,
      lockChapters?: boolean | null,
      journeyProgress: number,
      userCompletedAt?: string | null,
      collaborator?: Array< string | null > | null,
      assignedUserID?: string | null,
      startDate?: string | null,
      badgeID?: string | null,
      customBadgeName?: string | null,
      userStartedAt?: string | null,
      preboarding?: boolean | null,
      updatedAt: string,
      isArchived?: boolean | null,
      type: BuilderType,
      image?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type createJourneyWithIdReturnMutationVariables = {
  input: CreateJourneyInput,
  condition?: ModelJourneyConditionInput | null,
};

export type createJourneyWithIdReturnMutation = {
  createJourney?:  {
    __typename: "Journey",
    id: string,
  } | null,
};

export type updateJourneyWithIdReturnMutationVariables = {
  input: UpdateJourneyInput,
  condition?: ModelJourneyConditionInput | null,
};

export type updateJourneyWithIdReturnMutation = {
  updateJourney?:  {
    __typename: "Journey",
    id: string,
  } | null,
};

export type getJourneyWithIdAndNameQueryVariables = {
  id: string,
};

export type getJourneyWithIdAndNameQuery = {
  getJourney?:  {
    __typename: "Journey",
    id: string,
    name: string,
    status: JourneyStatus,
    journeyProgress: number,
    assignedUserID?: string | null,
    collaborator?: Array< string | null > | null,
    startDate?: string | null,
    parentJourneyID?: string | null,
    type: BuilderType,
  } | null,
};

export type updateEmbeddedContentBlockWithIdReturnMutationVariables = {
  input: UpdateEmbeddedContentBlockInput,
  condition?: ModelEmbeddedContentBlockConditionInput | null,
};

export type updateEmbeddedContentBlockWithIdReturnMutation = {
  updateEmbeddedContentBlock?:  {
    __typename: "EmbeddedContentBlock",
    id: string,
  } | null,
};

export type ListDetailedUserQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDetailedUserQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      email: string,
      photoUrl: string,
      type: UserType,
      slackUserId?: string | null,
      userOrganizationId: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type updateLocationWithIdReturnMutationVariables = {
  input: UpdateLocationInput,
  condition?: ModelLocationConditionInput | null,
};

export type updateLocationWithIdReturnMutation = {
  updateLocation?:  {
    __typename: "Location",
    id: string,
  } | null,
};

export type updateUserWithIdReturnMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type updateUserWithIdReturnMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
  } | null,
};

export type listSecretKeyManagersWithIdQueryVariables = {
  filter?: ModelSecretKeyManagerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type listSecretKeyManagersWithIdQuery = {
  listSecretKeyManagers?:  {
    __typename: "ModelSecretKeyManagerConnection",
    items:  Array< {
      __typename: "SecretKeyManager",
      id: string,
      organizationID: string,
      clientEmail: string,
      adminEmail: string,
      createdAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type updateSecretKeyManagerDetailsMutationVariables = {
  input: UpdateSecretKeyManagerInput,
  condition?: ModelSecretKeyManagerConditionInput | null,
};

export type updateSecretKeyManagerDetailsMutation = {
  updateSecretKeyManager?:  {
    __typename: "SecretKeyManager",
    clientEmail: string,
    createdAt: string,
  } | null,
};

export type getUserWithBasicDataQueryVariables = {
  id: string,
};

export type getUserWithBasicDataQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    email: string,
    firstName?: string | null,
    personalEmail?: string | null,
    lastName?: string | null,
    photoUrl: string,
    team?: string | null,
    jobTitle?: string | null,
    startDate?: string | null,
    slackUserId?: string | null,
    UserBadges?:  {
      __typename: "ModelUserBadgeConnection",
      items:  Array< {
        __typename: "UserBadge",
        createdAt: string,
        id: string,
        updatedAt: string,
        playbookId: string,
        userID: string,
      } | null >,
    } | null,
  } | null,
};

export type listDetailedJourneysQueryVariables = {
  filter?: ModelJourneyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type listDetailedJourneysQuery = {
  listJourneys?:  {
    __typename: "ModelJourneyConnection",
    items:  Array< {
      __typename: "Journey",
      id: string,
      name: string,
      status: JourneyStatus,
      lockChapters?: boolean | null,
      parentJourneyID?: string | null,
      journeyProgress: number,
      assignedUserID?: string | null,
      mode: PlaybookMode,
      userStartedAt?: string | null,
      startDate?: string | null,
      eventStartedAt?: string | null,
      type: BuilderType,
      Milestone?:  {
        __typename: "ModelMilestoneConnection",
        items:  Array< {
          __typename: "Milestone",
          EmbeddedContentBlocks?:  {
            __typename: "ModelEmbeddedContentBlockConnection",
            items:  Array< {
              __typename: "EmbeddedContentBlock",
              isCompleted?: boolean | null,
            } | null >,
          } | null,
          FreeTextQuestionBlocks?:  {
            __typename: "ModelFreeTextQuestionBlockConnection",
            items:  Array< {
              __typename: "FreeTextQuestionBlock",
              isCompleted?: boolean | null,
            } | null >,
          } | null,
          MultipleChoiceQuestionBlocks?:  {
            __typename: "ModelMultipleChoiceQuestionBlockConnection",
            items:  Array< {
              __typename: "MultipleChoiceQuestionBlock",
              isCompleted?: boolean | null,
            } | null >,
          } | null,
          TaskBlocks?:  {
            __typename: "ModelTaskBlockConnection",
            items:  Array< {
              __typename: "TaskBlock",
              isCompleted?: boolean | null,
            } | null >,
          } | null,
        } | null >,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        items:  Array< {
          __typename: "Event",
          id: string,
          status: EventStatus,
          reminders?:  {
            __typename: "ModelEventReminderConnection",
            items:  Array< {
              __typename: "EventReminder",
              id: string,
              status: EventStatus,
            } | null >,
          } | null,
        } | null >,
      } | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type deleteMilestoneWithIdReturnMutationVariables = {
  input: DeleteMilestoneInput,
  condition?: ModelMilestoneConditionInput | null,
};

export type deleteMilestoneWithIdReturnMutation = {
  deleteMilestone?:  {
    __typename: "Milestone",
    id: string,
  } | null,
};

export type deleteMeetingWithIdAndReturnMutationVariables = {
  input: DeleteMeetingInput,
  condition?: ModelMeetingConditionInput | null,
};

export type deleteMeetingWithIdAndReturnMutation = {
  deleteMeeting?:  {
    __typename: "Meeting",
    id: string,
  } | null,
};

export type updateUserWithIdAndEmailReturnMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type updateUserWithIdAndEmailReturnMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    email: string,
  } | null,
};

export type updateOrganizationWithIdReturnMutationVariables = {
  input: UpdateOrganizationInput,
  condition?: ModelOrganizationConditionInput | null,
};

export type updateOrganizationWithIdReturnMutation = {
  updateOrganization?:  {
    __typename: "Organization",
    id: string,
  } | null,
};

export type createMeetingWithIdReturnMutationVariables = {
  input: CreateMeetingInput,
  condition?: ModelMeetingConditionInput | null,
};

export type createMeetingWithIdReturnMutation = {
  createMeeting?:  {
    __typename: "Meeting",
    id: string,
  } | null,
};

export type updateMeetingWithIdReturnMutationVariables = {
  input: UpdateMeetingInput,
  condition?: ModelMeetingConditionInput | null,
};

export type updateMeetingWithIdReturnMutation = {
  updateMeeting?:  {
    __typename: "Meeting",
    id: string,
  } | null,
};

export type listJourneyCollaboratorsWithIdReturnQueryVariables = {
  filter?: ModelJourneyCollaboratorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type listJourneyCollaboratorsWithIdReturnQuery = {
  listJourneyCollaborators?:  {
    __typename: "ModelJourneyCollaboratorConnection",
    items:  Array< {
      __typename: "JourneyCollaborator",
      id: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type createJourneyCollaboratorWithIdReturnMutationVariables = {
  input: CreateJourneyCollaboratorInput,
  condition?: ModelJourneyCollaboratorConditionInput | null,
};

export type createJourneyCollaboratorWithIdReturnMutation = {
  createJourneyCollaborator?:  {
    __typename: "JourneyCollaborator",
    id: string,
  } | null,
};

export type updateJourneyCollaboratorDataMutationVariables = {
  input: UpdateJourneyCollaboratorInput,
  condition?: ModelJourneyCollaboratorConditionInput | null,
};

export type updateJourneyCollaboratorDataMutation = {
  updateJourneyCollaborator?:  {
    __typename: "JourneyCollaborator",
    id: string,
    assignedUserID?: string | null,
  } | null,
};

export type createKinfolkTemplateWithIdReturnMutationVariables = {
  input: CreateKinfolkTemplateInput,
  condition?: ModelKinfolkTemplateConditionInput | null,
};

export type createKinfolkTemplateWithIdReturnMutation = {
  createKinfolkTemplate?:  {
    __typename: "KinfolkTemplate",
    id: string,
  } | null,
};

export type updateKinfolkTemplateWithIdReturnMutationVariables = {
  input: UpdateKinfolkTemplateInput,
  condition?: ModelKinfolkTemplateConditionInput | null,
};

export type updateKinfolkTemplateWithIdReturnMutation = {
  updateKinfolkTemplate?:  {
    __typename: "KinfolkTemplate",
    id: string,
  } | null,
};

export type listPreboardingUsersWithRelationsQueryVariables = {
  filter?: ModelPreboardingUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type listPreboardingUsersWithRelationsQuery = {
  listPreboardingUsers?:  {
    __typename: "ModelPreboardingUserConnection",
    items:  Array< {
      __typename: "PreboardingUser",
      id: string,
      userId: string,
      isSubmitted?: boolean | null,
      consent?: boolean | null,
      journeyId?: string | null,
      personalEmail?: string | null,
      photoUrl?: string | null,
      bankName?: string | null,
      bankBranch?: string | null,
      bankSortCode?: string | null,
      bankAccountNumber?: string | null,
      bankAccountName?: string | null,
      buildingSocietyRoll?: string | null,
      street1?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      country?: string | null,
      birthday?: string | null,
      gender?: string | null,
      title?: string | null,
      nationality?: string | null,
      mobileNumber?: string | null,
      niNumber?: string | null,
      laptopPreference?: LaptopPreference | null,
      secondMonitor?: boolean | null,
      corporateCard?: boolean | null,
      corporatePhone?: PhonePreference | null,
      healthCare?: boolean | null,
      dental?: boolean | null,
      optical?: boolean | null,
      jobDeclaration?: Declaration | null,
      expatDeclaration?: Declaration | null,
      studentLoanRepayment?: boolean | null,
      studentLoanType?: StudentLoanType | null,
      pensionScheme?: boolean | null,
      pensionSchemeOpt?: string | null,
      pensionContribution?: number | null,
      passportNumber?: string | null,
      passportIssueDate?: string | null,
      passportExpiryDate?: string | null,
      passportIssuingCountry?: string | null,
      confirmation?: boolean | null,
      emergencyContactName?: string | null,
      emergencyContactRelationship?: string | null,
      emergencyContactPhone?: string | null,
      emergencyContactEmailAddress?: string | null,
      emergencyContactStreet1?: string | null,
      emergencyContactStreet2?: string | null,
      emergencyContactCity?: string | null,
      emergencyContactState?: string | null,
      emergencyContactZip?: string | null,
      emergencyContactCountry?: string | null,
      createdAt: string,
      updatedAt: string,
      PreboardingUserRelations?:  {
        __typename: "ModelPreboardingUserRelationConnection",
        items:  Array< {
          __typename: "PreboardingUserRelation",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          relationshipToMember?: string | null,
          birthday?: string | null,
          userId: string,
          formId: string,
          healthCare?: boolean | null,
          dental?: boolean | null,
          optical?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type listEventsWithRemindersByJourneyIdQueryVariables = {
  journeyID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type listEventsWithRemindersByJourneyIdQuery = {
  listEventsByJourneyId?:  {
    __typename: "ModelEventConnection",
    items:  Array< {
      __typename: "Event",
      id: string,
      userId?: string | null,
      daysFrom: number,
      type: string,
      role?: AssigneeRole | null,
      emailTo?: string | null,
      title?: string | null,
      message?: string | null,
      formId?: string | null,
      sendTime?: string | null,
      channel: Channel,
      sendTimeDate?: string | null,
      status: EventStatus,
      channelId?: string | null,
      parentId?: string | null,
      cc?: Array< string | null > | null,
      archived?: boolean | null,
      updatedInChild?: boolean | null,
      journeyID?: string | null,
      kinfolkTemplateID?: string | null,
      reminders?:  {
        __typename: "ModelEventReminderConnection",
        items:  Array< {
          __typename: "EventReminder",
          daysFrom: number,
          eventId: string,
          id: string,
          sendTimeDate?: string | null,
          status: EventStatus,
        } | null >,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type listEventsWithRemindersByKinfolkTemplateIdQueryVariables = {
  kinfolkTemplateID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type listEventsWithRemindersByKinfolkTemplateIdQuery = {
  listEventsByKinfolkTemplateId?:  {
    __typename: "ModelEventConnection",
    items:  Array< {
      __typename: "Event",
      id: string,
      userId?: string | null,
      daysFrom: number,
      type: string,
      role?: AssigneeRole | null,
      emailTo?: string | null,
      title?: string | null,
      message?: string | null,
      sendTime?: string | null,
      channel: Channel,
      sendTimeDate?: string | null,
      status: EventStatus,
      channelId?: string | null,
      parentId?: string | null,
      cc?: Array< string | null > | null,
      archived?: boolean | null,
      updatedInChild?: boolean | null,
      journeyID?: string | null,
      formId?: string | null,
      kinfolkTemplateID?: string | null,
      createdAt: string,
      updatedAt: string,
      reminders?:  {
        __typename: "ModelEventReminderConnection",
        items:  Array< {
          __typename: "EventReminder",
          daysFrom: number,
          eventId: string,
          id: string,
          sendTimeDate?: string | null,
          status: EventStatus,
        } | null >,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type onOrganizationByIdWithBasicDataSubscriptionVariables = {
  id: string,
};

export type onOrganizationByIdWithBasicDataSubscription = {
  onOrganizationById?:  {
    __typename: "Organization",
    id: string,
    name: string,
    slackAccessToken?: string | null,
    mergeCompanyToken?: string | null,
    status: OrganizationStatus,
  } | null,
};

export type CreateShareInfoMutationVariables = {
  input: CreateShareInfoInput,
  condition?: ModelShareInfoConditionInput | null,
};

export type CreateShareInfoMutation = {
  createShareInfo?:  {
    __typename: "ShareInfo",
    id: string,
    name: string,
    organizationID: string,
    shareInfoOnUsers?:  {
      __typename: "ModelUserShareInfoConnection",
      nextToken?: string | null,
    } | null,
    learnInfoOnUsers?:  {
      __typename: "ModelLearnMoreInfoConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateShareInfoMutationVariables = {
  input: UpdateShareInfoInput,
  condition?: ModelShareInfoConditionInput | null,
};

export type UpdateShareInfoMutation = {
  updateShareInfo?:  {
    __typename: "ShareInfo",
    id: string,
    name: string,
    organizationID: string,
    shareInfoOnUsers?:  {
      __typename: "ModelUserShareInfoConnection",
      nextToken?: string | null,
    } | null,
    learnInfoOnUsers?:  {
      __typename: "ModelLearnMoreInfoConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteShareInfoMutationVariables = {
  input: DeleteShareInfoInput,
  condition?: ModelShareInfoConditionInput | null,
};

export type DeleteShareInfoMutation = {
  deleteShareInfo?:  {
    __typename: "ShareInfo",
    id: string,
    name: string,
    organizationID: string,
    shareInfoOnUsers?:  {
      __typename: "ModelUserShareInfoConnection",
      nextToken?: string | null,
    } | null,
    learnInfoOnUsers?:  {
      __typename: "ModelLearnMoreInfoConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateWorkStyleMutationVariables = {
  input: CreateWorkStyleInput,
  condition?: ModelWorkStyleConditionInput | null,
};

export type CreateWorkStyleMutation = {
  createWorkStyle?:  {
    __typename: "WorkStyle",
    id: string,
    whatIDoAtWork?: string | null,
    myFocusTimeIs?: string | null,
    giveReceivingFeedback?: string | null,
    digestionInformation?: string | null,
    inMyElement?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateWorkStyleMutationVariables = {
  input: UpdateWorkStyleInput,
  condition?: ModelWorkStyleConditionInput | null,
};

export type UpdateWorkStyleMutation = {
  updateWorkStyle?:  {
    __typename: "WorkStyle",
    id: string,
    whatIDoAtWork?: string | null,
    myFocusTimeIs?: string | null,
    giveReceivingFeedback?: string | null,
    digestionInformation?: string | null,
    inMyElement?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteWorkStyleMutationVariables = {
  input: DeleteWorkStyleInput,
  condition?: ModelWorkStyleConditionInput | null,
};

export type DeleteWorkStyleMutation = {
  deleteWorkStyle?:  {
    __typename: "WorkStyle",
    id: string,
    whatIDoAtWork?: string | null,
    myFocusTimeIs?: string | null,
    giveReceivingFeedback?: string | null,
    digestionInformation?: string | null,
    inMyElement?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateWorkPreferenceMutationVariables = {
  input: CreateWorkPreferenceInput,
  condition?: ModelWorkPreferenceConditionInput | null,
};

export type CreateWorkPreferenceMutation = {
  createWorkPreference?:  {
    __typename: "WorkPreference",
    id: string,
    giveMeAllTheContext: number,
    messageTimingPreferene: number,
    syncxVsAsync: number,
    talkTogetherVsAlone: number,
    recognitionPrivateVsPrivate: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateWorkPreferenceMutationVariables = {
  input: UpdateWorkPreferenceInput,
  condition?: ModelWorkPreferenceConditionInput | null,
};

export type UpdateWorkPreferenceMutation = {
  updateWorkPreference?:  {
    __typename: "WorkPreference",
    id: string,
    giveMeAllTheContext: number,
    messageTimingPreferene: number,
    syncxVsAsync: number,
    talkTogetherVsAlone: number,
    recognitionPrivateVsPrivate: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteWorkPreferenceMutationVariables = {
  input: DeleteWorkPreferenceInput,
  condition?: ModelWorkPreferenceConditionInput | null,
};

export type DeleteWorkPreferenceMutation = {
  deleteWorkPreference?:  {
    __typename: "WorkPreference",
    id: string,
    giveMeAllTheContext: number,
    messageTimingPreferene: number,
    syncxVsAsync: number,
    talkTogetherVsAlone: number,
    recognitionPrivateVsPrivate: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateInterestMutationVariables = {
  input: CreateInterestInput,
  condition?: ModelInterestConditionInput | null,
};

export type CreateInterestMutation = {
  createInterest?:  {
    __typename: "Interest",
    id: string,
    name: string,
    organizationID: string,
    users?:  {
      __typename: "ModelUserInterestConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateInterestMutationVariables = {
  input: UpdateInterestInput,
  condition?: ModelInterestConditionInput | null,
};

export type UpdateInterestMutation = {
  updateInterest?:  {
    __typename: "Interest",
    id: string,
    name: string,
    organizationID: string,
    users?:  {
      __typename: "ModelUserInterestConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteInterestMutationVariables = {
  input: DeleteInterestInput,
  condition?: ModelInterestConditionInput | null,
};

export type DeleteInterestMutation = {
  deleteInterest?:  {
    __typename: "Interest",
    id: string,
    name: string,
    organizationID: string,
    users?:  {
      __typename: "ModelUserInterestConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateLocationMutationVariables = {
  input: CreateLocationInput,
  condition?: ModelLocationConditionInput | null,
};

export type CreateLocationMutation = {
  createLocation?:  {
    __typename: "Location",
    id: string,
    name?: string | null,
    streetAddress?: string | null,
    city?: string | null,
    state?: string | null,
    zipCode?: number | null,
    country?: string | null,
    locationType?: string | null,
    organizationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateLocationMutationVariables = {
  input: UpdateLocationInput,
  condition?: ModelLocationConditionInput | null,
};

export type UpdateLocationMutation = {
  updateLocation?:  {
    __typename: "Location",
    id: string,
    name?: string | null,
    streetAddress?: string | null,
    city?: string | null,
    state?: string | null,
    zipCode?: number | null,
    country?: string | null,
    locationType?: string | null,
    organizationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteLocationMutationVariables = {
  input: DeleteLocationInput,
  condition?: ModelLocationConditionInput | null,
};

export type DeleteLocationMutation = {
  deleteLocation?:  {
    __typename: "Location",
    id: string,
    name?: string | null,
    streetAddress?: string | null,
    city?: string | null,
    state?: string | null,
    zipCode?: number | null,
    country?: string | null,
    locationType?: string | null,
    organizationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateOrganizationMutationVariables = {
  input: CreateOrganizationInput,
  condition?: ModelOrganizationConditionInput | null,
};

export type CreateOrganizationMutation = {
  createOrganization?:  {
    __typename: "Organization",
    id: string,
    name: string,
    slackAccessToken?: string | null,
    mergeCompanyToken?: string | null,
    atsToken?: string | null,
    atsAccountId?: string | null,
    hrisToken?: string | null,
    hrisAccountId?: string | null,
    hrisIntegratedAt?: string | null,
    slackUserAccessToken?: string | null,
    outlookTenantId?: string | null,
    msTeamsTenantId?: string | null,
    Locations?:  {
      __typename: "ModelLocationConnection",
      nextToken?: string | null,
    } | null,
    Interests?:  {
      __typename: "ModelInterestConnection",
      nextToken?: string | null,
    } | null,
    ShareInfos?:  {
      __typename: "ModelShareInfoConnection",
      nextToken?: string | null,
    } | null,
    status: OrganizationStatus,
    activeUntil: string,
    hasSubscription?: boolean | null,
    preboarding?: boolean | null,
    Journeys?:  {
      __typename: "ModelJourneyConnection",
      nextToken?: string | null,
    } | null,
    features?: string | null,
    globalDataStores?: string | null,
    settings?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateOrganizationMutationVariables = {
  input: UpdateOrganizationInput,
  condition?: ModelOrganizationConditionInput | null,
};

export type UpdateOrganizationMutation = {
  updateOrganization?:  {
    __typename: "Organization",
    id: string,
    name: string,
    slackAccessToken?: string | null,
    mergeCompanyToken?: string | null,
    atsToken?: string | null,
    atsAccountId?: string | null,
    hrisToken?: string | null,
    hrisAccountId?: string | null,
    hrisIntegratedAt?: string | null,
    slackUserAccessToken?: string | null,
    outlookTenantId?: string | null,
    msTeamsTenantId?: string | null,
    Locations?:  {
      __typename: "ModelLocationConnection",
      nextToken?: string | null,
    } | null,
    Interests?:  {
      __typename: "ModelInterestConnection",
      nextToken?: string | null,
    } | null,
    ShareInfos?:  {
      __typename: "ModelShareInfoConnection",
      nextToken?: string | null,
    } | null,
    status: OrganizationStatus,
    activeUntil: string,
    hasSubscription?: boolean | null,
    preboarding?: boolean | null,
    Journeys?:  {
      __typename: "ModelJourneyConnection",
      nextToken?: string | null,
    } | null,
    features?: string | null,
    globalDataStores?: string | null,
    settings?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteOrganizationMutationVariables = {
  input: DeleteOrganizationInput,
  condition?: ModelOrganizationConditionInput | null,
};

export type DeleteOrganizationMutation = {
  deleteOrganization?:  {
    __typename: "Organization",
    id: string,
    name: string,
    slackAccessToken?: string | null,
    mergeCompanyToken?: string | null,
    atsToken?: string | null,
    atsAccountId?: string | null,
    hrisToken?: string | null,
    hrisAccountId?: string | null,
    hrisIntegratedAt?: string | null,
    slackUserAccessToken?: string | null,
    outlookTenantId?: string | null,
    msTeamsTenantId?: string | null,
    Locations?:  {
      __typename: "ModelLocationConnection",
      nextToken?: string | null,
    } | null,
    Interests?:  {
      __typename: "ModelInterestConnection",
      nextToken?: string | null,
    } | null,
    ShareInfos?:  {
      __typename: "ModelShareInfoConnection",
      nextToken?: string | null,
    } | null,
    status: OrganizationStatus,
    activeUntil: string,
    hasSubscription?: boolean | null,
    preboarding?: boolean | null,
    Journeys?:  {
      __typename: "ModelJourneyConnection",
      nextToken?: string | null,
    } | null,
    features?: string | null,
    globalDataStores?: string | null,
    settings?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    offset: number,
    firstName?: string | null,
    email: string,
    phone?: string | null,
    sendWelcomeEmail?: boolean | null,
    personalEmail?: string | null,
    jobTitle?: string | null,
    birthday: string,
    startDate?: string | null,
    homeLocation?: string | null,
    team?: string | null,
    photoUrl: string,
    pronoun: Pronoun,
    aboutMe?: string | null,
    mostHappy?: string | null,
    socialMediaUrl?: string | null,
    slackUserId?: string | null,
    isFirstLogin?: boolean | null,
    isHomeModelViewed?: boolean | null,
    isProfileModelViewed?: boolean | null,
    type: UserType,
    isActive?: boolean | null,
    Organization:  {
      __typename: "Organization",
      id: string,
      name: string,
      slackAccessToken?: string | null,
      mergeCompanyToken?: string | null,
      atsToken?: string | null,
      atsAccountId?: string | null,
      hrisToken?: string | null,
      hrisAccountId?: string | null,
      hrisIntegratedAt?: string | null,
      slackUserAccessToken?: string | null,
      outlookTenantId?: string | null,
      msTeamsTenantId?: string | null,
      status: OrganizationStatus,
      activeUntil: string,
      hasSubscription?: boolean | null,
      preboarding?: boolean | null,
      features?: string | null,
      globalDataStores?: string | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    Interests?:  {
      __typename: "ModelUserInterestConnection",
      nextToken?: string | null,
    } | null,
    WorkPreference:  {
      __typename: "WorkPreference",
      id: string,
      giveMeAllTheContext: number,
      messageTimingPreferene: number,
      syncxVsAsync: number,
      talkTogetherVsAlone: number,
      recognitionPrivateVsPrivate: number,
      createdAt: string,
      updatedAt: string,
    },
    WorkStyle:  {
      __typename: "WorkStyle",
      id: string,
      whatIDoAtWork?: string | null,
      myFocusTimeIs?: string | null,
      giveReceivingFeedback?: string | null,
      digestionInformation?: string | null,
      inMyElement?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    lastName?: string | null,
    ShareInfoOn?:  {
      __typename: "ModelUserShareInfoConnection",
      nextToken?: string | null,
    } | null,
    LearnMoreOn?:  {
      __typename: "ModelLearnMoreInfoConnection",
      nextToken?: string | null,
    } | null,
    WorkLocation?:  {
      __typename: "Location",
      id: string,
      name?: string | null,
      streetAddress?: string | null,
      city?: string | null,
      state?: string | null,
      zipCode?: number | null,
      country?: string | null,
      locationType?: string | null,
      organizationID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    Journeys?:  {
      __typename: "ModelJourneyConnection",
      nextToken?: string | null,
    } | null,
    Meetings?:  {
      __typename: "ModelMeetingConnection",
      nextToken?: string | null,
    } | null,
    PreboardingUsers?:  {
      __typename: "ModelPreboardingUserConnection",
      nextToken?: string | null,
    } | null,
    PreboardingUserRelations?:  {
      __typename: "ModelPreboardingUserRelationConnection",
      nextToken?: string | null,
    } | null,
    UserBadges?:  {
      __typename: "ModelUserBadgeConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    userOrganizationId: string,
    userWorkPreferenceId: string,
    userWorkStyleId: string,
    userWorkLocationId?: string | null,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    offset: number,
    firstName?: string | null,
    email: string,
    phone?: string | null,
    sendWelcomeEmail?: boolean | null,
    personalEmail?: string | null,
    jobTitle?: string | null,
    birthday: string,
    startDate?: string | null,
    homeLocation?: string | null,
    team?: string | null,
    photoUrl: string,
    pronoun: Pronoun,
    aboutMe?: string | null,
    mostHappy?: string | null,
    socialMediaUrl?: string | null,
    slackUserId?: string | null,
    isFirstLogin?: boolean | null,
    isHomeModelViewed?: boolean | null,
    isProfileModelViewed?: boolean | null,
    type: UserType,
    isActive?: boolean | null,
    Organization:  {
      __typename: "Organization",
      id: string,
      name: string,
      slackAccessToken?: string | null,
      mergeCompanyToken?: string | null,
      atsToken?: string | null,
      atsAccountId?: string | null,
      hrisToken?: string | null,
      hrisAccountId?: string | null,
      hrisIntegratedAt?: string | null,
      slackUserAccessToken?: string | null,
      outlookTenantId?: string | null,
      msTeamsTenantId?: string | null,
      status: OrganizationStatus,
      activeUntil: string,
      hasSubscription?: boolean | null,
      preboarding?: boolean | null,
      features?: string | null,
      globalDataStores?: string | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    Interests?:  {
      __typename: "ModelUserInterestConnection",
      nextToken?: string | null,
    } | null,
    WorkPreference:  {
      __typename: "WorkPreference",
      id: string,
      giveMeAllTheContext: number,
      messageTimingPreferene: number,
      syncxVsAsync: number,
      talkTogetherVsAlone: number,
      recognitionPrivateVsPrivate: number,
      createdAt: string,
      updatedAt: string,
    },
    WorkStyle:  {
      __typename: "WorkStyle",
      id: string,
      whatIDoAtWork?: string | null,
      myFocusTimeIs?: string | null,
      giveReceivingFeedback?: string | null,
      digestionInformation?: string | null,
      inMyElement?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    lastName?: string | null,
    ShareInfoOn?:  {
      __typename: "ModelUserShareInfoConnection",
      nextToken?: string | null,
    } | null,
    LearnMoreOn?:  {
      __typename: "ModelLearnMoreInfoConnection",
      nextToken?: string | null,
    } | null,
    WorkLocation?:  {
      __typename: "Location",
      id: string,
      name?: string | null,
      streetAddress?: string | null,
      city?: string | null,
      state?: string | null,
      zipCode?: number | null,
      country?: string | null,
      locationType?: string | null,
      organizationID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    Journeys?:  {
      __typename: "ModelJourneyConnection",
      nextToken?: string | null,
    } | null,
    Meetings?:  {
      __typename: "ModelMeetingConnection",
      nextToken?: string | null,
    } | null,
    PreboardingUsers?:  {
      __typename: "ModelPreboardingUserConnection",
      nextToken?: string | null,
    } | null,
    PreboardingUserRelations?:  {
      __typename: "ModelPreboardingUserRelationConnection",
      nextToken?: string | null,
    } | null,
    UserBadges?:  {
      __typename: "ModelUserBadgeConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    userOrganizationId: string,
    userWorkPreferenceId: string,
    userWorkStyleId: string,
    userWorkLocationId?: string | null,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    offset: number,
    firstName?: string | null,
    email: string,
    phone?: string | null,
    sendWelcomeEmail?: boolean | null,
    personalEmail?: string | null,
    jobTitle?: string | null,
    birthday: string,
    startDate?: string | null,
    homeLocation?: string | null,
    team?: string | null,
    photoUrl: string,
    pronoun: Pronoun,
    aboutMe?: string | null,
    mostHappy?: string | null,
    socialMediaUrl?: string | null,
    slackUserId?: string | null,
    isFirstLogin?: boolean | null,
    isHomeModelViewed?: boolean | null,
    isProfileModelViewed?: boolean | null,
    type: UserType,
    isActive?: boolean | null,
    Organization:  {
      __typename: "Organization",
      id: string,
      name: string,
      slackAccessToken?: string | null,
      mergeCompanyToken?: string | null,
      atsToken?: string | null,
      atsAccountId?: string | null,
      hrisToken?: string | null,
      hrisAccountId?: string | null,
      hrisIntegratedAt?: string | null,
      slackUserAccessToken?: string | null,
      outlookTenantId?: string | null,
      msTeamsTenantId?: string | null,
      status: OrganizationStatus,
      activeUntil: string,
      hasSubscription?: boolean | null,
      preboarding?: boolean | null,
      features?: string | null,
      globalDataStores?: string | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    Interests?:  {
      __typename: "ModelUserInterestConnection",
      nextToken?: string | null,
    } | null,
    WorkPreference:  {
      __typename: "WorkPreference",
      id: string,
      giveMeAllTheContext: number,
      messageTimingPreferene: number,
      syncxVsAsync: number,
      talkTogetherVsAlone: number,
      recognitionPrivateVsPrivate: number,
      createdAt: string,
      updatedAt: string,
    },
    WorkStyle:  {
      __typename: "WorkStyle",
      id: string,
      whatIDoAtWork?: string | null,
      myFocusTimeIs?: string | null,
      giveReceivingFeedback?: string | null,
      digestionInformation?: string | null,
      inMyElement?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    lastName?: string | null,
    ShareInfoOn?:  {
      __typename: "ModelUserShareInfoConnection",
      nextToken?: string | null,
    } | null,
    LearnMoreOn?:  {
      __typename: "ModelLearnMoreInfoConnection",
      nextToken?: string | null,
    } | null,
    WorkLocation?:  {
      __typename: "Location",
      id: string,
      name?: string | null,
      streetAddress?: string | null,
      city?: string | null,
      state?: string | null,
      zipCode?: number | null,
      country?: string | null,
      locationType?: string | null,
      organizationID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    Journeys?:  {
      __typename: "ModelJourneyConnection",
      nextToken?: string | null,
    } | null,
    Meetings?:  {
      __typename: "ModelMeetingConnection",
      nextToken?: string | null,
    } | null,
    PreboardingUsers?:  {
      __typename: "ModelPreboardingUserConnection",
      nextToken?: string | null,
    } | null,
    PreboardingUserRelations?:  {
      __typename: "ModelPreboardingUserRelationConnection",
      nextToken?: string | null,
    } | null,
    UserBadges?:  {
      __typename: "ModelUserBadgeConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    userOrganizationId: string,
    userWorkPreferenceId: string,
    userWorkStyleId: string,
    userWorkLocationId?: string | null,
  } | null,
};

export type CreateJourneyMutationVariables = {
  input: CreateJourneyInput,
  condition?: ModelJourneyConditionInput | null,
};

export type CreateJourneyMutation = {
  createJourney?:  {
    __typename: "Journey",
    id: string,
    name: string,
    createdByUserID: string,
    assignedUserID?: string | null,
    mode: PlaybookMode,
    startDate?: string | null,
    journeyProgress: number,
    parentJourneyID?: string | null,
    collaborator?: Array< string | null > | null,
    status: JourneyStatus,
    badgeID?: string | null,
    customBadgeName?: string | null,
    lockChapters?: boolean | null,
    type: BuilderType,
    publicLink?: string | null,
    eventStartedAt?: string | null,
    userStartedAt?: string | null,
    userCompletedAt?: string | null,
    isArchived?: boolean | null,
    preboarding?: boolean | null,
    organizationID: string,
    Milestone?:  {
      __typename: "ModelMilestoneConnection",
      nextToken?: string | null,
    } | null,
    Meetings?:  {
      __typename: "ModelMeetingConnection",
      nextToken?: string | null,
    } | null,
    JourneyCollaborator?:  {
      __typename: "ModelJourneyCollaboratorConnection",
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelEventConnection",
      nextToken?: string | null,
    } | null,
    image?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateJourneyMutationVariables = {
  input: UpdateJourneyInput,
  condition?: ModelJourneyConditionInput | null,
};

export type UpdateJourneyMutation = {
  updateJourney?:  {
    __typename: "Journey",
    id: string,
    name: string,
    createdByUserID: string,
    assignedUserID?: string | null,
    mode: PlaybookMode,
    startDate?: string | null,
    journeyProgress: number,
    parentJourneyID?: string | null,
    collaborator?: Array< string | null > | null,
    status: JourneyStatus,
    badgeID?: string | null,
    customBadgeName?: string | null,
    lockChapters?: boolean | null,
    type: BuilderType,
    publicLink?: string | null,
    eventStartedAt?: string | null,
    userStartedAt?: string | null,
    userCompletedAt?: string | null,
    isArchived?: boolean | null,
    preboarding?: boolean | null,
    organizationID: string,
    Milestone?:  {
      __typename: "ModelMilestoneConnection",
      nextToken?: string | null,
    } | null,
    Meetings?:  {
      __typename: "ModelMeetingConnection",
      nextToken?: string | null,
    } | null,
    JourneyCollaborator?:  {
      __typename: "ModelJourneyCollaboratorConnection",
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelEventConnection",
      nextToken?: string | null,
    } | null,
    image?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteJourneyMutationVariables = {
  input: DeleteJourneyInput,
  condition?: ModelJourneyConditionInput | null,
};

export type DeleteJourneyMutation = {
  deleteJourney?:  {
    __typename: "Journey",
    id: string,
    name: string,
    createdByUserID: string,
    assignedUserID?: string | null,
    mode: PlaybookMode,
    startDate?: string | null,
    journeyProgress: number,
    parentJourneyID?: string | null,
    collaborator?: Array< string | null > | null,
    status: JourneyStatus,
    badgeID?: string | null,
    customBadgeName?: string | null,
    lockChapters?: boolean | null,
    type: BuilderType,
    publicLink?: string | null,
    eventStartedAt?: string | null,
    userStartedAt?: string | null,
    userCompletedAt?: string | null,
    isArchived?: boolean | null,
    preboarding?: boolean | null,
    organizationID: string,
    Milestone?:  {
      __typename: "ModelMilestoneConnection",
      nextToken?: string | null,
    } | null,
    Meetings?:  {
      __typename: "ModelMeetingConnection",
      nextToken?: string | null,
    } | null,
    JourneyCollaborator?:  {
      __typename: "ModelJourneyCollaboratorConnection",
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelEventConnection",
      nextToken?: string | null,
    } | null,
    image?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateBadgeMutationVariables = {
  input: CreateBadgeInput,
  condition?: ModelBadgeConditionInput | null,
};

export type CreateBadgeMutation = {
  createBadge?:  {
    __typename: "Badge",
    id: string,
    name?: string | null,
    iconUrl: string,
    isArchived?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBadgeMutationVariables = {
  input: UpdateBadgeInput,
  condition?: ModelBadgeConditionInput | null,
};

export type UpdateBadgeMutation = {
  updateBadge?:  {
    __typename: "Badge",
    id: string,
    name?: string | null,
    iconUrl: string,
    isArchived?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteBadgeMutationVariables = {
  input: DeleteBadgeInput,
  condition?: ModelBadgeConditionInput | null,
};

export type DeleteBadgeMutation = {
  deleteBadge?:  {
    __typename: "Badge",
    id: string,
    name?: string | null,
    iconUrl: string,
    isArchived?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUserBadgeMutationVariables = {
  input: CreateUserBadgeInput,
  condition?: ModelUserBadgeConditionInput | null,
};

export type CreateUserBadgeMutation = {
  createUserBadge?:  {
    __typename: "UserBadge",
    id: string,
    playbookId: string,
    userID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserBadgeMutationVariables = {
  input: UpdateUserBadgeInput,
  condition?: ModelUserBadgeConditionInput | null,
};

export type UpdateUserBadgeMutation = {
  updateUserBadge?:  {
    __typename: "UserBadge",
    id: string,
    playbookId: string,
    userID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserBadgeMutationVariables = {
  input: DeleteUserBadgeInput,
  condition?: ModelUserBadgeConditionInput | null,
};

export type DeleteUserBadgeMutation = {
  deleteUserBadge?:  {
    __typename: "UserBadge",
    id: string,
    playbookId: string,
    userID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateJourneyCollaboratorMutationVariables = {
  input: CreateJourneyCollaboratorInput,
  condition?: ModelJourneyCollaboratorConditionInput | null,
};

export type CreateJourneyCollaboratorMutation = {
  createJourneyCollaborator?:  {
    __typename: "JourneyCollaborator",
    id: string,
    assigneeRole: AssigneeRole,
    journeyAccess?: JourneyAccess | null,
    assignedUserID?: string | null,
    actionID?: Array< string | null > | null,
    meetingID?: Array< string | null > | null,
    updatedInChild?: boolean | null,
    journeyID?: string | null,
    kinfolkTemplateID?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateJourneyCollaboratorMutationVariables = {
  input: UpdateJourneyCollaboratorInput,
  condition?: ModelJourneyCollaboratorConditionInput | null,
};

export type UpdateJourneyCollaboratorMutation = {
  updateJourneyCollaborator?:  {
    __typename: "JourneyCollaborator",
    id: string,
    assigneeRole: AssigneeRole,
    journeyAccess?: JourneyAccess | null,
    assignedUserID?: string | null,
    actionID?: Array< string | null > | null,
    meetingID?: Array< string | null > | null,
    updatedInChild?: boolean | null,
    journeyID?: string | null,
    kinfolkTemplateID?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteJourneyCollaboratorMutationVariables = {
  input: DeleteJourneyCollaboratorInput,
  condition?: ModelJourneyCollaboratorConditionInput | null,
};

export type DeleteJourneyCollaboratorMutation = {
  deleteJourneyCollaborator?:  {
    __typename: "JourneyCollaborator",
    id: string,
    assigneeRole: AssigneeRole,
    journeyAccess?: JourneyAccess | null,
    assignedUserID?: string | null,
    actionID?: Array< string | null > | null,
    meetingID?: Array< string | null > | null,
    updatedInChild?: boolean | null,
    journeyID?: string | null,
    kinfolkTemplateID?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateKinfolkTemplateMutationVariables = {
  input: CreateKinfolkTemplateInput,
  condition?: ModelKinfolkTemplateConditionInput | null,
};

export type CreateKinfolkTemplateMutation = {
  createKinfolkTemplate?:  {
    __typename: "KinfolkTemplate",
    id: string,
    name: string,
    description: string,
    image: string,
    isArchived?: boolean | null,
    status: TemplateStatus,
    orderNo?: number | null,
    type: BuilderType,
    mode: PlaybookMode,
    isPublic?: boolean | null,
    Milestone?:  {
      __typename: "ModelMilestoneConnection",
      nextToken?: string | null,
    } | null,
    Meetings?:  {
      __typename: "ModelMeetingConnection",
      nextToken?: string | null,
    } | null,
    JourneyCollaborator?:  {
      __typename: "ModelJourneyCollaboratorConnection",
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelEventConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateKinfolkTemplateMutationVariables = {
  input: UpdateKinfolkTemplateInput,
  condition?: ModelKinfolkTemplateConditionInput | null,
};

export type UpdateKinfolkTemplateMutation = {
  updateKinfolkTemplate?:  {
    __typename: "KinfolkTemplate",
    id: string,
    name: string,
    description: string,
    image: string,
    isArchived?: boolean | null,
    status: TemplateStatus,
    orderNo?: number | null,
    type: BuilderType,
    mode: PlaybookMode,
    isPublic?: boolean | null,
    Milestone?:  {
      __typename: "ModelMilestoneConnection",
      nextToken?: string | null,
    } | null,
    Meetings?:  {
      __typename: "ModelMeetingConnection",
      nextToken?: string | null,
    } | null,
    JourneyCollaborator?:  {
      __typename: "ModelJourneyCollaboratorConnection",
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelEventConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteKinfolkTemplateMutationVariables = {
  input: DeleteKinfolkTemplateInput,
  condition?: ModelKinfolkTemplateConditionInput | null,
};

export type DeleteKinfolkTemplateMutation = {
  deleteKinfolkTemplate?:  {
    __typename: "KinfolkTemplate",
    id: string,
    name: string,
    description: string,
    image: string,
    isArchived?: boolean | null,
    status: TemplateStatus,
    orderNo?: number | null,
    type: BuilderType,
    mode: PlaybookMode,
    isPublic?: boolean | null,
    Milestone?:  {
      __typename: "ModelMilestoneConnection",
      nextToken?: string | null,
    } | null,
    Meetings?:  {
      __typename: "ModelMeetingConnection",
      nextToken?: string | null,
    } | null,
    JourneyCollaborator?:  {
      __typename: "ModelJourneyCollaboratorConnection",
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelEventConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateMilestoneMutationVariables = {
  input: CreateMilestoneInput,
  condition?: ModelMilestoneConditionInput | null,
};

export type CreateMilestoneMutation = {
  createMilestone?:  {
    __typename: "Milestone",
    id: string,
    name: string,
    kinfolkTemplateID?: string | null,
    isCompleted?: boolean | null,
    isArchived?: boolean | null,
    journeyID?: string | null,
    orderNo: number,
    parentMilestoneID?: string | null,
    EmbeddedContentBlocks?:  {
      __typename: "ModelEmbeddedContentBlockConnection",
      nextToken?: string | null,
    } | null,
    FreeTextQuestionBlocks?:  {
      __typename: "ModelFreeTextQuestionBlockConnection",
      nextToken?: string | null,
    } | null,
    NoteBlocks?:  {
      __typename: "ModelNoteBlockConnection",
      nextToken?: string | null,
    } | null,
    TaskBlocks?:  {
      __typename: "ModelTaskBlockConnection",
      nextToken?: string | null,
    } | null,
    StandardContentBlocks?:  {
      __typename: "ModelStandardContentBlockConnection",
      nextToken?: string | null,
    } | null,
    MultipleChoiceQuestionBlocks?:  {
      __typename: "ModelMultipleChoiceQuestionBlockConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateMilestoneMutationVariables = {
  input: UpdateMilestoneInput,
  condition?: ModelMilestoneConditionInput | null,
};

export type UpdateMilestoneMutation = {
  updateMilestone?:  {
    __typename: "Milestone",
    id: string,
    name: string,
    kinfolkTemplateID?: string | null,
    isCompleted?: boolean | null,
    isArchived?: boolean | null,
    journeyID?: string | null,
    orderNo: number,
    parentMilestoneID?: string | null,
    EmbeddedContentBlocks?:  {
      __typename: "ModelEmbeddedContentBlockConnection",
      nextToken?: string | null,
    } | null,
    FreeTextQuestionBlocks?:  {
      __typename: "ModelFreeTextQuestionBlockConnection",
      nextToken?: string | null,
    } | null,
    NoteBlocks?:  {
      __typename: "ModelNoteBlockConnection",
      nextToken?: string | null,
    } | null,
    TaskBlocks?:  {
      __typename: "ModelTaskBlockConnection",
      nextToken?: string | null,
    } | null,
    StandardContentBlocks?:  {
      __typename: "ModelStandardContentBlockConnection",
      nextToken?: string | null,
    } | null,
    MultipleChoiceQuestionBlocks?:  {
      __typename: "ModelMultipleChoiceQuestionBlockConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteMilestoneMutationVariables = {
  input: DeleteMilestoneInput,
  condition?: ModelMilestoneConditionInput | null,
};

export type DeleteMilestoneMutation = {
  deleteMilestone?:  {
    __typename: "Milestone",
    id: string,
    name: string,
    kinfolkTemplateID?: string | null,
    isCompleted?: boolean | null,
    isArchived?: boolean | null,
    journeyID?: string | null,
    orderNo: number,
    parentMilestoneID?: string | null,
    EmbeddedContentBlocks?:  {
      __typename: "ModelEmbeddedContentBlockConnection",
      nextToken?: string | null,
    } | null,
    FreeTextQuestionBlocks?:  {
      __typename: "ModelFreeTextQuestionBlockConnection",
      nextToken?: string | null,
    } | null,
    NoteBlocks?:  {
      __typename: "ModelNoteBlockConnection",
      nextToken?: string | null,
    } | null,
    TaskBlocks?:  {
      __typename: "ModelTaskBlockConnection",
      nextToken?: string | null,
    } | null,
    StandardContentBlocks?:  {
      __typename: "ModelStandardContentBlockConnection",
      nextToken?: string | null,
    } | null,
    MultipleChoiceQuestionBlocks?:  {
      __typename: "ModelMultipleChoiceQuestionBlockConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateEmbeddedContentBlockMutationVariables = {
  input: CreateEmbeddedContentBlockInput,
  condition?: ModelEmbeddedContentBlockConditionInput | null,
};

export type CreateEmbeddedContentBlockMutation = {
  createEmbeddedContentBlock?:  {
    __typename: "EmbeddedContentBlock",
    id: string,
    type: BlockType,
    milestoneID: string,
    title: string,
    description?: string | null,
    resourceLink: string,
    isFormLinked?: boolean | null,
    orderNo: number,
    parentEmbeddedContentBlockID?: string | null,
    isCompleted?: boolean | null,
    isArchived?: boolean | null,
    updatedInChild?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateEmbeddedContentBlockMutationVariables = {
  input: UpdateEmbeddedContentBlockInput,
  condition?: ModelEmbeddedContentBlockConditionInput | null,
};

export type UpdateEmbeddedContentBlockMutation = {
  updateEmbeddedContentBlock?:  {
    __typename: "EmbeddedContentBlock",
    id: string,
    type: BlockType,
    milestoneID: string,
    title: string,
    description?: string | null,
    resourceLink: string,
    isFormLinked?: boolean | null,
    orderNo: number,
    parentEmbeddedContentBlockID?: string | null,
    isCompleted?: boolean | null,
    isArchived?: boolean | null,
    updatedInChild?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteEmbeddedContentBlockMutationVariables = {
  input: DeleteEmbeddedContentBlockInput,
  condition?: ModelEmbeddedContentBlockConditionInput | null,
};

export type DeleteEmbeddedContentBlockMutation = {
  deleteEmbeddedContentBlock?:  {
    __typename: "EmbeddedContentBlock",
    id: string,
    type: BlockType,
    milestoneID: string,
    title: string,
    description?: string | null,
    resourceLink: string,
    isFormLinked?: boolean | null,
    orderNo: number,
    parentEmbeddedContentBlockID?: string | null,
    isCompleted?: boolean | null,
    isArchived?: boolean | null,
    updatedInChild?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateFreeTextQuestionBlockMutationVariables = {
  input: CreateFreeTextQuestionBlockInput,
  condition?: ModelFreeTextQuestionBlockConditionInput | null,
};

export type CreateFreeTextQuestionBlockMutation = {
  createFreeTextQuestionBlock?:  {
    __typename: "FreeTextQuestionBlock",
    id: string,
    type: BlockType,
    milestoneID: string,
    title: string,
    description?: string | null,
    text?: string | null,
    orderNo: number,
    parentFreeTextQuestionBlockID?: string | null,
    isCompleted?: boolean | null,
    isArchived?: boolean | null,
    updatedInChild?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateFreeTextQuestionBlockMutationVariables = {
  input: UpdateFreeTextQuestionBlockInput,
  condition?: ModelFreeTextQuestionBlockConditionInput | null,
};

export type UpdateFreeTextQuestionBlockMutation = {
  updateFreeTextQuestionBlock?:  {
    __typename: "FreeTextQuestionBlock",
    id: string,
    type: BlockType,
    milestoneID: string,
    title: string,
    description?: string | null,
    text?: string | null,
    orderNo: number,
    parentFreeTextQuestionBlockID?: string | null,
    isCompleted?: boolean | null,
    isArchived?: boolean | null,
    updatedInChild?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteFreeTextQuestionBlockMutationVariables = {
  input: DeleteFreeTextQuestionBlockInput,
  condition?: ModelFreeTextQuestionBlockConditionInput | null,
};

export type DeleteFreeTextQuestionBlockMutation = {
  deleteFreeTextQuestionBlock?:  {
    __typename: "FreeTextQuestionBlock",
    id: string,
    type: BlockType,
    milestoneID: string,
    title: string,
    description?: string | null,
    text?: string | null,
    orderNo: number,
    parentFreeTextQuestionBlockID?: string | null,
    isCompleted?: boolean | null,
    isArchived?: boolean | null,
    updatedInChild?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateNoteBlockMutationVariables = {
  input: CreateNoteBlockInput,
  condition?: ModelNoteBlockConditionInput | null,
};

export type CreateNoteBlockMutation = {
  createNoteBlock?:  {
    __typename: "NoteBlock",
    id: string,
    type: BlockType,
    milestoneID: string,
    title: string,
    description?: string | null,
    text?: string | null,
    orderNo: number,
    parentNoteBlockID?: string | null,
    isCompleted?: boolean | null,
    isPrivate?: boolean | null,
    isArchived?: boolean | null,
    updatedInChild?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateNoteBlockMutationVariables = {
  input: UpdateNoteBlockInput,
  condition?: ModelNoteBlockConditionInput | null,
};

export type UpdateNoteBlockMutation = {
  updateNoteBlock?:  {
    __typename: "NoteBlock",
    id: string,
    type: BlockType,
    milestoneID: string,
    title: string,
    description?: string | null,
    text?: string | null,
    orderNo: number,
    parentNoteBlockID?: string | null,
    isCompleted?: boolean | null,
    isPrivate?: boolean | null,
    isArchived?: boolean | null,
    updatedInChild?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteNoteBlockMutationVariables = {
  input: DeleteNoteBlockInput,
  condition?: ModelNoteBlockConditionInput | null,
};

export type DeleteNoteBlockMutation = {
  deleteNoteBlock?:  {
    __typename: "NoteBlock",
    id: string,
    type: BlockType,
    milestoneID: string,
    title: string,
    description?: string | null,
    text?: string | null,
    orderNo: number,
    parentNoteBlockID?: string | null,
    isCompleted?: boolean | null,
    isPrivate?: boolean | null,
    isArchived?: boolean | null,
    updatedInChild?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateTaskBlockMutationVariables = {
  input: CreateTaskBlockInput,
  condition?: ModelTaskBlockConditionInput | null,
};

export type CreateTaskBlockMutation = {
  createTaskBlock?:  {
    __typename: "TaskBlock",
    id: string,
    type: BlockType,
    milestoneID: string,
    title: string,
    description?: string | null,
    status?: boolean | null,
    orderNo: number,
    parentTaskBlockID?: string | null,
    isCompleted?: boolean | null,
    isArchived?: boolean | null,
    updatedInChild?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTaskBlockMutationVariables = {
  input: UpdateTaskBlockInput,
  condition?: ModelTaskBlockConditionInput | null,
};

export type UpdateTaskBlockMutation = {
  updateTaskBlock?:  {
    __typename: "TaskBlock",
    id: string,
    type: BlockType,
    milestoneID: string,
    title: string,
    description?: string | null,
    status?: boolean | null,
    orderNo: number,
    parentTaskBlockID?: string | null,
    isCompleted?: boolean | null,
    isArchived?: boolean | null,
    updatedInChild?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTaskBlockMutationVariables = {
  input: DeleteTaskBlockInput,
  condition?: ModelTaskBlockConditionInput | null,
};

export type DeleteTaskBlockMutation = {
  deleteTaskBlock?:  {
    __typename: "TaskBlock",
    id: string,
    type: BlockType,
    milestoneID: string,
    title: string,
    description?: string | null,
    status?: boolean | null,
    orderNo: number,
    parentTaskBlockID?: string | null,
    isCompleted?: boolean | null,
    isArchived?: boolean | null,
    updatedInChild?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateStandardContentBlockMutationVariables = {
  input: CreateStandardContentBlockInput,
  condition?: ModelStandardContentBlockConditionInput | null,
};

export type CreateStandardContentBlockMutation = {
  createStandardContentBlock?:  {
    __typename: "StandardContentBlock",
    id: string,
    type: BlockType,
    milestoneID: string,
    title: string,
    description?: string | null,
    orderNo: number,
    parentStandardContentBlockID?: string | null,
    isCompleted?: boolean | null,
    isArchived?: boolean | null,
    updatedInChild?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateStandardContentBlockMutationVariables = {
  input: UpdateStandardContentBlockInput,
  condition?: ModelStandardContentBlockConditionInput | null,
};

export type UpdateStandardContentBlockMutation = {
  updateStandardContentBlock?:  {
    __typename: "StandardContentBlock",
    id: string,
    type: BlockType,
    milestoneID: string,
    title: string,
    description?: string | null,
    orderNo: number,
    parentStandardContentBlockID?: string | null,
    isCompleted?: boolean | null,
    isArchived?: boolean | null,
    updatedInChild?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteStandardContentBlockMutationVariables = {
  input: DeleteStandardContentBlockInput,
  condition?: ModelStandardContentBlockConditionInput | null,
};

export type DeleteStandardContentBlockMutation = {
  deleteStandardContentBlock?:  {
    __typename: "StandardContentBlock",
    id: string,
    type: BlockType,
    milestoneID: string,
    title: string,
    description?: string | null,
    orderNo: number,
    parentStandardContentBlockID?: string | null,
    isCompleted?: boolean | null,
    isArchived?: boolean | null,
    updatedInChild?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateMultipleChoiceQuestionBlockMutationVariables = {
  input: CreateMultipleChoiceQuestionBlockInput,
  condition?: ModelMultipleChoiceQuestionBlockConditionInput | null,
};

export type CreateMultipleChoiceQuestionBlockMutation = {
  createMultipleChoiceQuestionBlock?:  {
    __typename: "MultipleChoiceQuestionBlock",
    id: string,
    type: BlockType,
    milestoneID: string,
    title: string,
    description?: string | null,
    option?: Array< string > | null,
    answer?: Array< string > | null,
    orderNo: number,
    parentMultipleChoiceQuestionBlockID?: string | null,
    isCompleted?: boolean | null,
    isRequired?: boolean | null,
    isArchived?: boolean | null,
    updatedInChild?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateMultipleChoiceQuestionBlockMutationVariables = {
  input: UpdateMultipleChoiceQuestionBlockInput,
  condition?: ModelMultipleChoiceQuestionBlockConditionInput | null,
};

export type UpdateMultipleChoiceQuestionBlockMutation = {
  updateMultipleChoiceQuestionBlock?:  {
    __typename: "MultipleChoiceQuestionBlock",
    id: string,
    type: BlockType,
    milestoneID: string,
    title: string,
    description?: string | null,
    option?: Array< string > | null,
    answer?: Array< string > | null,
    orderNo: number,
    parentMultipleChoiceQuestionBlockID?: string | null,
    isCompleted?: boolean | null,
    isRequired?: boolean | null,
    isArchived?: boolean | null,
    updatedInChild?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteMultipleChoiceQuestionBlockMutationVariables = {
  input: DeleteMultipleChoiceQuestionBlockInput,
  condition?: ModelMultipleChoiceQuestionBlockConditionInput | null,
};

export type DeleteMultipleChoiceQuestionBlockMutation = {
  deleteMultipleChoiceQuestionBlock?:  {
    __typename: "MultipleChoiceQuestionBlock",
    id: string,
    type: BlockType,
    milestoneID: string,
    title: string,
    description?: string | null,
    option?: Array< string > | null,
    answer?: Array< string > | null,
    orderNo: number,
    parentMultipleChoiceQuestionBlockID?: string | null,
    isCompleted?: boolean | null,
    isRequired?: boolean | null,
    isArchived?: boolean | null,
    updatedInChild?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateEventMutationVariables = {
  input: CreateEventInput,
  condition?: ModelEventConditionInput | null,
};

export type CreateEventMutation = {
  createEvent?:  {
    __typename: "Event",
    id: string,
    userId?: string | null,
    formId?: string | null,
    formInstanceId?: string | null,
    daysFrom: number,
    daysFromDueDate?: number | null,
    type: string,
    role?: AssigneeRole | null,
    emailTo?: string | null,
    title?: string | null,
    message?: string | null,
    sendTime?: string | null,
    sendTimeDate?: string | null,
    dueDate?: string | null,
    status: EventStatus,
    channelId?: string | null,
    parentId?: string | null,
    channel: Channel,
    cc?: Array< string | null > | null,
    archived?: boolean | null,
    updatedInChild?: boolean | null,
    journeyID?: string | null,
    kinfolkTemplateID?: string | null,
    reminders?:  {
      __typename: "ModelEventReminderConnection",
      nextToken?: string | null,
    } | null,
    meta?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateEventMutationVariables = {
  input: UpdateEventInput,
  condition?: ModelEventConditionInput | null,
};

export type UpdateEventMutation = {
  updateEvent?:  {
    __typename: "Event",
    id: string,
    userId?: string | null,
    formId?: string | null,
    formInstanceId?: string | null,
    daysFrom: number,
    daysFromDueDate?: number | null,
    type: string,
    role?: AssigneeRole | null,
    emailTo?: string | null,
    title?: string | null,
    message?: string | null,
    sendTime?: string | null,
    sendTimeDate?: string | null,
    dueDate?: string | null,
    status: EventStatus,
    channelId?: string | null,
    parentId?: string | null,
    channel: Channel,
    cc?: Array< string | null > | null,
    archived?: boolean | null,
    updatedInChild?: boolean | null,
    journeyID?: string | null,
    kinfolkTemplateID?: string | null,
    reminders?:  {
      __typename: "ModelEventReminderConnection",
      nextToken?: string | null,
    } | null,
    meta?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteEventMutationVariables = {
  input: DeleteEventInput,
  condition?: ModelEventConditionInput | null,
};

export type DeleteEventMutation = {
  deleteEvent?:  {
    __typename: "Event",
    id: string,
    userId?: string | null,
    formId?: string | null,
    formInstanceId?: string | null,
    daysFrom: number,
    daysFromDueDate?: number | null,
    type: string,
    role?: AssigneeRole | null,
    emailTo?: string | null,
    title?: string | null,
    message?: string | null,
    sendTime?: string | null,
    sendTimeDate?: string | null,
    dueDate?: string | null,
    status: EventStatus,
    channelId?: string | null,
    parentId?: string | null,
    channel: Channel,
    cc?: Array< string | null > | null,
    archived?: boolean | null,
    updatedInChild?: boolean | null,
    journeyID?: string | null,
    kinfolkTemplateID?: string | null,
    reminders?:  {
      __typename: "ModelEventReminderConnection",
      nextToken?: string | null,
    } | null,
    meta?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateEventReminderMutationVariables = {
  input: CreateEventReminderInput,
  condition?: ModelEventReminderConditionInput | null,
};

export type CreateEventReminderMutation = {
  createEventReminder?:  {
    __typename: "EventReminder",
    id: string,
    eventId: string,
    sendTimeDate?: string | null,
    daysFrom: number,
    status: EventStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateEventReminderMutationVariables = {
  input: UpdateEventReminderInput,
  condition?: ModelEventReminderConditionInput | null,
};

export type UpdateEventReminderMutation = {
  updateEventReminder?:  {
    __typename: "EventReminder",
    id: string,
    eventId: string,
    sendTimeDate?: string | null,
    daysFrom: number,
    status: EventStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteEventReminderMutationVariables = {
  input: DeleteEventReminderInput,
  condition?: ModelEventReminderConditionInput | null,
};

export type DeleteEventReminderMutation = {
  deleteEventReminder?:  {
    __typename: "EventReminder",
    id: string,
    eventId: string,
    sendTimeDate?: string | null,
    daysFrom: number,
    status: EventStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateMeetingMutationVariables = {
  input: CreateMeetingInput,
  condition?: ModelMeetingConditionInput | null,
};

export type CreateMeetingMutation = {
  createMeeting?:  {
    __typename: "Meeting",
    id: string,
    kinfolkTemplateID?: string | null,
    journeyID?: string | null,
    organizedByUserID?: string | null,
    status: MeetingStatus,
    title: string,
    description?: string | null,
    attendeesEmail?: Array< string | null > | null,
    startTime?: string | null,
    endTime?: string | null,
    parentMeetingID?: string | null,
    updatedInChild?: boolean | null,
    eventDate?: string | null,
    duration: string,
    link?: string | null,
    assigneeRole?: AssigneeRole | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateMeetingMutationVariables = {
  input: UpdateMeetingInput,
  condition?: ModelMeetingConditionInput | null,
};

export type UpdateMeetingMutation = {
  updateMeeting?:  {
    __typename: "Meeting",
    id: string,
    kinfolkTemplateID?: string | null,
    journeyID?: string | null,
    organizedByUserID?: string | null,
    status: MeetingStatus,
    title: string,
    description?: string | null,
    attendeesEmail?: Array< string | null > | null,
    startTime?: string | null,
    endTime?: string | null,
    parentMeetingID?: string | null,
    updatedInChild?: boolean | null,
    eventDate?: string | null,
    duration: string,
    link?: string | null,
    assigneeRole?: AssigneeRole | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteMeetingMutationVariables = {
  input: DeleteMeetingInput,
  condition?: ModelMeetingConditionInput | null,
};

export type DeleteMeetingMutation = {
  deleteMeeting?:  {
    __typename: "Meeting",
    id: string,
    kinfolkTemplateID?: string | null,
    journeyID?: string | null,
    organizedByUserID?: string | null,
    status: MeetingStatus,
    title: string,
    description?: string | null,
    attendeesEmail?: Array< string | null > | null,
    startTime?: string | null,
    endTime?: string | null,
    parentMeetingID?: string | null,
    updatedInChild?: boolean | null,
    eventDate?: string | null,
    duration: string,
    link?: string | null,
    assigneeRole?: AssigneeRole | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSecretKeyManagerMutationVariables = {
  input: CreateSecretKeyManagerInput,
  condition?: ModelSecretKeyManagerConditionInput | null,
};

export type CreateSecretKeyManagerMutation = {
  createSecretKeyManager?:  {
    __typename: "SecretKeyManager",
    id: string,
    organizationID: string,
    clientEmail: string,
    privateKey: string,
    adminEmail: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSecretKeyManagerMutationVariables = {
  input: UpdateSecretKeyManagerInput,
  condition?: ModelSecretKeyManagerConditionInput | null,
};

export type UpdateSecretKeyManagerMutation = {
  updateSecretKeyManager?:  {
    __typename: "SecretKeyManager",
    id: string,
    organizationID: string,
    clientEmail: string,
    privateKey: string,
    adminEmail: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSecretKeyManagerMutationVariables = {
  input: DeleteSecretKeyManagerInput,
  condition?: ModelSecretKeyManagerConditionInput | null,
};

export type DeleteSecretKeyManagerMutation = {
  deleteSecretKeyManager?:  {
    __typename: "SecretKeyManager",
    id: string,
    organizationID: string,
    clientEmail: string,
    privateKey: string,
    adminEmail: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePreboardingUserMutationVariables = {
  input: CreatePreboardingUserInput,
  condition?: ModelPreboardingUserConditionInput | null,
};

export type CreatePreboardingUserMutation = {
  createPreboardingUser?:  {
    __typename: "PreboardingUser",
    id: string,
    userId: string,
    isSubmitted?: boolean | null,
    consent?: boolean | null,
    journeyId?: string | null,
    personalEmail?: string | null,
    photoUrl?: string | null,
    bankName?: string | null,
    bankBranch?: string | null,
    bankSortCode?: string | null,
    bankAccountNumber?: string | null,
    bankAccountName?: string | null,
    buildingSocietyRoll?: string | null,
    street1?: string | null,
    street2?: string | null,
    city?: string | null,
    state?: string | null,
    zip?: string | null,
    country?: string | null,
    birthday?: string | null,
    gender?: string | null,
    title?: string | null,
    nationality?: string | null,
    mobileNumber?: string | null,
    niNumber?: string | null,
    laptopPreference?: LaptopPreference | null,
    secondMonitor?: boolean | null,
    corporateCard?: boolean | null,
    corporatePhone?: PhonePreference | null,
    healthCare?: boolean | null,
    dental?: boolean | null,
    optical?: boolean | null,
    jobDeclaration?: Declaration | null,
    expatDeclaration?: Declaration | null,
    studentLoanRepayment?: boolean | null,
    studentLoanType?: StudentLoanType | null,
    pensionScheme?: boolean | null,
    pensionContribution?: number | null,
    passportNumber?: string | null,
    passportIssueDate?: string | null,
    passportExpiryDate?: string | null,
    passportIssuingCountry?: string | null,
    confirmation?: boolean | null,
    pensionSchemeOpt?: string | null,
    emergencyContactName?: string | null,
    emergencyContactRelationship?: string | null,
    emergencyContactPhone?: string | null,
    emergencyContactEmailAddress?: string | null,
    emergencyContactStreet1?: string | null,
    emergencyContactStreet2?: string | null,
    emergencyContactCity?: string | null,
    emergencyContactState?: string | null,
    emergencyContactZip?: string | null,
    emergencyContactCountry?: string | null,
    PreboardingUserRelations?:  {
      __typename: "ModelPreboardingUserRelationConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePreboardingUserMutationVariables = {
  input: UpdatePreboardingUserInput,
  condition?: ModelPreboardingUserConditionInput | null,
};

export type UpdatePreboardingUserMutation = {
  updatePreboardingUser?:  {
    __typename: "PreboardingUser",
    id: string,
    userId: string,
    isSubmitted?: boolean | null,
    consent?: boolean | null,
    journeyId?: string | null,
    personalEmail?: string | null,
    photoUrl?: string | null,
    bankName?: string | null,
    bankBranch?: string | null,
    bankSortCode?: string | null,
    bankAccountNumber?: string | null,
    bankAccountName?: string | null,
    buildingSocietyRoll?: string | null,
    street1?: string | null,
    street2?: string | null,
    city?: string | null,
    state?: string | null,
    zip?: string | null,
    country?: string | null,
    birthday?: string | null,
    gender?: string | null,
    title?: string | null,
    nationality?: string | null,
    mobileNumber?: string | null,
    niNumber?: string | null,
    laptopPreference?: LaptopPreference | null,
    secondMonitor?: boolean | null,
    corporateCard?: boolean | null,
    corporatePhone?: PhonePreference | null,
    healthCare?: boolean | null,
    dental?: boolean | null,
    optical?: boolean | null,
    jobDeclaration?: Declaration | null,
    expatDeclaration?: Declaration | null,
    studentLoanRepayment?: boolean | null,
    studentLoanType?: StudentLoanType | null,
    pensionScheme?: boolean | null,
    pensionContribution?: number | null,
    passportNumber?: string | null,
    passportIssueDate?: string | null,
    passportExpiryDate?: string | null,
    passportIssuingCountry?: string | null,
    confirmation?: boolean | null,
    pensionSchemeOpt?: string | null,
    emergencyContactName?: string | null,
    emergencyContactRelationship?: string | null,
    emergencyContactPhone?: string | null,
    emergencyContactEmailAddress?: string | null,
    emergencyContactStreet1?: string | null,
    emergencyContactStreet2?: string | null,
    emergencyContactCity?: string | null,
    emergencyContactState?: string | null,
    emergencyContactZip?: string | null,
    emergencyContactCountry?: string | null,
    PreboardingUserRelations?:  {
      __typename: "ModelPreboardingUserRelationConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePreboardingUserMutationVariables = {
  input: DeletePreboardingUserInput,
  condition?: ModelPreboardingUserConditionInput | null,
};

export type DeletePreboardingUserMutation = {
  deletePreboardingUser?:  {
    __typename: "PreboardingUser",
    id: string,
    userId: string,
    isSubmitted?: boolean | null,
    consent?: boolean | null,
    journeyId?: string | null,
    personalEmail?: string | null,
    photoUrl?: string | null,
    bankName?: string | null,
    bankBranch?: string | null,
    bankSortCode?: string | null,
    bankAccountNumber?: string | null,
    bankAccountName?: string | null,
    buildingSocietyRoll?: string | null,
    street1?: string | null,
    street2?: string | null,
    city?: string | null,
    state?: string | null,
    zip?: string | null,
    country?: string | null,
    birthday?: string | null,
    gender?: string | null,
    title?: string | null,
    nationality?: string | null,
    mobileNumber?: string | null,
    niNumber?: string | null,
    laptopPreference?: LaptopPreference | null,
    secondMonitor?: boolean | null,
    corporateCard?: boolean | null,
    corporatePhone?: PhonePreference | null,
    healthCare?: boolean | null,
    dental?: boolean | null,
    optical?: boolean | null,
    jobDeclaration?: Declaration | null,
    expatDeclaration?: Declaration | null,
    studentLoanRepayment?: boolean | null,
    studentLoanType?: StudentLoanType | null,
    pensionScheme?: boolean | null,
    pensionContribution?: number | null,
    passportNumber?: string | null,
    passportIssueDate?: string | null,
    passportExpiryDate?: string | null,
    passportIssuingCountry?: string | null,
    confirmation?: boolean | null,
    pensionSchemeOpt?: string | null,
    emergencyContactName?: string | null,
    emergencyContactRelationship?: string | null,
    emergencyContactPhone?: string | null,
    emergencyContactEmailAddress?: string | null,
    emergencyContactStreet1?: string | null,
    emergencyContactStreet2?: string | null,
    emergencyContactCity?: string | null,
    emergencyContactState?: string | null,
    emergencyContactZip?: string | null,
    emergencyContactCountry?: string | null,
    PreboardingUserRelations?:  {
      __typename: "ModelPreboardingUserRelationConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePreboardingUserRelationMutationVariables = {
  input: CreatePreboardingUserRelationInput,
  condition?: ModelPreboardingUserRelationConditionInput | null,
};

export type CreatePreboardingUserRelationMutation = {
  createPreboardingUserRelation?:  {
    __typename: "PreboardingUserRelation",
    id: string,
    firstName?: string | null,
    lastName?: string | null,
    relationshipToMember?: string | null,
    birthday?: string | null,
    userId: string,
    formId: string,
    healthCare?: boolean | null,
    dental?: boolean | null,
    optical?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePreboardingUserRelationMutationVariables = {
  input: UpdatePreboardingUserRelationInput,
  condition?: ModelPreboardingUserRelationConditionInput | null,
};

export type UpdatePreboardingUserRelationMutation = {
  updatePreboardingUserRelation?:  {
    __typename: "PreboardingUserRelation",
    id: string,
    firstName?: string | null,
    lastName?: string | null,
    relationshipToMember?: string | null,
    birthday?: string | null,
    userId: string,
    formId: string,
    healthCare?: boolean | null,
    dental?: boolean | null,
    optical?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePreboardingUserRelationMutationVariables = {
  input: DeletePreboardingUserRelationInput,
  condition?: ModelPreboardingUserRelationConditionInput | null,
};

export type DeletePreboardingUserRelationMutation = {
  deletePreboardingUserRelation?:  {
    __typename: "PreboardingUserRelation",
    id: string,
    firstName?: string | null,
    lastName?: string | null,
    relationshipToMember?: string | null,
    birthday?: string | null,
    userId: string,
    formId: string,
    healthCare?: boolean | null,
    dental?: boolean | null,
    optical?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateDigestMutationVariables = {
  input: CreateDigestInput,
  condition?: ModelDigestConditionInput | null,
};

export type CreateDigestMutation = {
  createDigest?:  {
    __typename: "Digest",
    id: string,
    type: DigestType,
    organizationId: string,
    userId: string,
    frequency: Frequency,
    channel: Channel,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateDigestMutationVariables = {
  input: UpdateDigestInput,
  condition?: ModelDigestConditionInput | null,
};

export type UpdateDigestMutation = {
  updateDigest?:  {
    __typename: "Digest",
    id: string,
    type: DigestType,
    organizationId: string,
    userId: string,
    frequency: Frequency,
    channel: Channel,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteDigestMutationVariables = {
  input: DeleteDigestInput,
  condition?: ModelDigestConditionInput | null,
};

export type DeleteDigestMutation = {
  deleteDigest?:  {
    __typename: "Digest",
    id: string,
    type: DigestType,
    organizationId: string,
    userId: string,
    frequency: Frequency,
    channel: Channel,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateGqlMessageMutationVariables = {
  input: CreateGqlMessageInput,
  condition?: ModelGqlMessageConditionInput | null,
};

export type CreateGqlMessageMutation = {
  createGqlMessage?:  {
    __typename: "GqlMessage",
    id: string,
    type: string,
    organizationId: string,
    recipientId: string,
    payload: string,
    response?: string | null,
    status: string,
    callbackId?: string | null,
    deliveryInfo?: string | null,
    respondedAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateGqlMessageMutationVariables = {
  input: UpdateGqlMessageInput,
  condition?: ModelGqlMessageConditionInput | null,
};

export type UpdateGqlMessageMutation = {
  updateGqlMessage?:  {
    __typename: "GqlMessage",
    id: string,
    type: string,
    organizationId: string,
    recipientId: string,
    payload: string,
    response?: string | null,
    status: string,
    callbackId?: string | null,
    deliveryInfo?: string | null,
    respondedAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteGqlMessageMutationVariables = {
  input: DeleteGqlMessageInput,
  condition?: ModelGqlMessageConditionInput | null,
};

export type DeleteGqlMessageMutation = {
  deleteGqlMessage?:  {
    __typename: "GqlMessage",
    id: string,
    type: string,
    organizationId: string,
    recipientId: string,
    payload: string,
    response?: string | null,
    status: string,
    callbackId?: string | null,
    deliveryInfo?: string | null,
    respondedAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateGqlMsTeamsUserReferenceMutationVariables = {
  input: CreateGqlMsTeamsUserReferenceInput,
  condition?: ModelGqlMsTeamsUserReferenceConditionInput | null,
};

export type CreateGqlMsTeamsUserReferenceMutation = {
  createGqlMsTeamsUserReference?:  {
    __typename: "GqlMsTeamsUserReference",
    tenantId: string,
    userEmail: string,
    teamsUserId?: string | null,
    conversation?: string | null,
    userId?: string | null,
    organizationId?: string | null,
    hasUndeliveredMessages?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateGqlMsTeamsUserReferenceMutationVariables = {
  input: UpdateGqlMsTeamsUserReferenceInput,
  condition?: ModelGqlMsTeamsUserReferenceConditionInput | null,
};

export type UpdateGqlMsTeamsUserReferenceMutation = {
  updateGqlMsTeamsUserReference?:  {
    __typename: "GqlMsTeamsUserReference",
    tenantId: string,
    userEmail: string,
    teamsUserId?: string | null,
    conversation?: string | null,
    userId?: string | null,
    organizationId?: string | null,
    hasUndeliveredMessages?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteGqlMsTeamsUserReferenceMutationVariables = {
  input: DeleteGqlMsTeamsUserReferenceInput,
  condition?: ModelGqlMsTeamsUserReferenceConditionInput | null,
};

export type DeleteGqlMsTeamsUserReferenceMutation = {
  deleteGqlMsTeamsUserReference?:  {
    __typename: "GqlMsTeamsUserReference",
    tenantId: string,
    userEmail: string,
    teamsUserId?: string | null,
    conversation?: string | null,
    userId?: string | null,
    organizationId?: string | null,
    hasUndeliveredMessages?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateGqlDataStoreMutationVariables = {
  input: CreateGqlDataStoreInput,
  condition?: ModelGqlDataStoreConditionInput | null,
};

export type CreateGqlDataStoreMutation = {
  createGqlDataStore?:  {
    __typename: "GqlDataStore",
    id: string,
    organizationId: string,
    name: string,
    data: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateGqlDataStoreMutationVariables = {
  input: UpdateGqlDataStoreInput,
  condition?: ModelGqlDataStoreConditionInput | null,
};

export type UpdateGqlDataStoreMutation = {
  updateGqlDataStore?:  {
    __typename: "GqlDataStore",
    id: string,
    organizationId: string,
    name: string,
    data: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteGqlDataStoreMutationVariables = {
  input: DeleteGqlDataStoreInput,
  condition?: ModelGqlDataStoreConditionInput | null,
};

export type DeleteGqlDataStoreMutation = {
  deleteGqlDataStore?:  {
    __typename: "GqlDataStore",
    id: string,
    organizationId: string,
    name: string,
    data: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateGqlDataStoreRowMutationVariables = {
  input: CreateGqlDataStoreRowInput,
  condition?: ModelGqlDataStoreRowConditionInput | null,
};

export type CreateGqlDataStoreRowMutation = {
  createGqlDataStoreRow?:  {
    __typename: "GqlDataStoreRow",
    id: string,
    dataStoreId: string,
    data: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateGqlDataStoreRowMutationVariables = {
  input: UpdateGqlDataStoreRowInput,
  condition?: ModelGqlDataStoreRowConditionInput | null,
};

export type UpdateGqlDataStoreRowMutation = {
  updateGqlDataStoreRow?:  {
    __typename: "GqlDataStoreRow",
    id: string,
    dataStoreId: string,
    data: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteGqlDataStoreRowMutationVariables = {
  input: DeleteGqlDataStoreRowInput,
  condition?: ModelGqlDataStoreRowConditionInput | null,
};

export type DeleteGqlDataStoreRowMutation = {
  deleteGqlDataStoreRow?:  {
    __typename: "GqlDataStoreRow",
    id: string,
    dataStoreId: string,
    data: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateGqlFormMutationVariables = {
  input: CreateGqlFormInput,
  condition?: ModelGqlFormConditionInput | null,
};

export type CreateGqlFormMutation = {
  createGqlForm?:  {
    __typename: "GqlForm",
    id: string,
    organizationId: string,
    name: string,
    dataStoreId: string,
    data: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateGqlFormMutationVariables = {
  input: UpdateGqlFormInput,
  condition?: ModelGqlFormConditionInput | null,
};

export type UpdateGqlFormMutation = {
  updateGqlForm?:  {
    __typename: "GqlForm",
    id: string,
    organizationId: string,
    name: string,
    dataStoreId: string,
    data: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteGqlFormMutationVariables = {
  input: DeleteGqlFormInput,
  condition?: ModelGqlFormConditionInput | null,
};

export type DeleteGqlFormMutation = {
  deleteGqlForm?:  {
    __typename: "GqlForm",
    id: string,
    organizationId: string,
    name: string,
    dataStoreId: string,
    data: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateGqlFormInstanceMutationVariables = {
  input: CreateGqlFormInstanceInput,
  condition?: ModelGqlFormInstanceConditionInput | null,
};

export type CreateGqlFormInstanceMutation = {
  createGqlFormInstance?:  {
    __typename: "GqlFormInstance",
    id: string,
    organizationId: string,
    formId: string,
    assignedTo: string,
    link: string,
    slug: string,
    data: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateGqlFormInstanceMutationVariables = {
  input: UpdateGqlFormInstanceInput,
  condition?: ModelGqlFormInstanceConditionInput | null,
};

export type UpdateGqlFormInstanceMutation = {
  updateGqlFormInstance?:  {
    __typename: "GqlFormInstance",
    id: string,
    organizationId: string,
    formId: string,
    assignedTo: string,
    link: string,
    slug: string,
    data: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteGqlFormInstanceMutationVariables = {
  input: DeleteGqlFormInstanceInput,
  condition?: ModelGqlFormInstanceConditionInput | null,
};

export type DeleteGqlFormInstanceMutation = {
  deleteGqlFormInstance?:  {
    __typename: "GqlFormInstance",
    id: string,
    organizationId: string,
    formId: string,
    assignedTo: string,
    link: string,
    slug: string,
    data: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUserShareInfoMutationVariables = {
  input: CreateUserShareInfoInput,
  condition?: ModelUserShareInfoConditionInput | null,
};

export type CreateUserShareInfoMutation = {
  createUserShareInfo?:  {
    __typename: "UserShareInfo",
    id: string,
    shareInfoID: string,
    userID: string,
    shareInfo:  {
      __typename: "ShareInfo",
      id: string,
      name: string,
      organizationID: string,
      createdAt: string,
      updatedAt: string,
    },
    user:  {
      __typename: "User",
      id: string,
      offset: number,
      firstName?: string | null,
      email: string,
      phone?: string | null,
      sendWelcomeEmail?: boolean | null,
      personalEmail?: string | null,
      jobTitle?: string | null,
      birthday: string,
      startDate?: string | null,
      homeLocation?: string | null,
      team?: string | null,
      photoUrl: string,
      pronoun: Pronoun,
      aboutMe?: string | null,
      mostHappy?: string | null,
      socialMediaUrl?: string | null,
      slackUserId?: string | null,
      isFirstLogin?: boolean | null,
      isHomeModelViewed?: boolean | null,
      isProfileModelViewed?: boolean | null,
      type: UserType,
      isActive?: boolean | null,
      lastName?: string | null,
      createdAt: string,
      updatedAt: string,
      userOrganizationId: string,
      userWorkPreferenceId: string,
      userWorkStyleId: string,
      userWorkLocationId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserShareInfoMutationVariables = {
  input: UpdateUserShareInfoInput,
  condition?: ModelUserShareInfoConditionInput | null,
};

export type UpdateUserShareInfoMutation = {
  updateUserShareInfo?:  {
    __typename: "UserShareInfo",
    id: string,
    shareInfoID: string,
    userID: string,
    shareInfo:  {
      __typename: "ShareInfo",
      id: string,
      name: string,
      organizationID: string,
      createdAt: string,
      updatedAt: string,
    },
    user:  {
      __typename: "User",
      id: string,
      offset: number,
      firstName?: string | null,
      email: string,
      phone?: string | null,
      sendWelcomeEmail?: boolean | null,
      personalEmail?: string | null,
      jobTitle?: string | null,
      birthday: string,
      startDate?: string | null,
      homeLocation?: string | null,
      team?: string | null,
      photoUrl: string,
      pronoun: Pronoun,
      aboutMe?: string | null,
      mostHappy?: string | null,
      socialMediaUrl?: string | null,
      slackUserId?: string | null,
      isFirstLogin?: boolean | null,
      isHomeModelViewed?: boolean | null,
      isProfileModelViewed?: boolean | null,
      type: UserType,
      isActive?: boolean | null,
      lastName?: string | null,
      createdAt: string,
      updatedAt: string,
      userOrganizationId: string,
      userWorkPreferenceId: string,
      userWorkStyleId: string,
      userWorkLocationId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserShareInfoMutationVariables = {
  input: DeleteUserShareInfoInput,
  condition?: ModelUserShareInfoConditionInput | null,
};

export type DeleteUserShareInfoMutation = {
  deleteUserShareInfo?:  {
    __typename: "UserShareInfo",
    id: string,
    shareInfoID: string,
    userID: string,
    shareInfo:  {
      __typename: "ShareInfo",
      id: string,
      name: string,
      organizationID: string,
      createdAt: string,
      updatedAt: string,
    },
    user:  {
      __typename: "User",
      id: string,
      offset: number,
      firstName?: string | null,
      email: string,
      phone?: string | null,
      sendWelcomeEmail?: boolean | null,
      personalEmail?: string | null,
      jobTitle?: string | null,
      birthday: string,
      startDate?: string | null,
      homeLocation?: string | null,
      team?: string | null,
      photoUrl: string,
      pronoun: Pronoun,
      aboutMe?: string | null,
      mostHappy?: string | null,
      socialMediaUrl?: string | null,
      slackUserId?: string | null,
      isFirstLogin?: boolean | null,
      isHomeModelViewed?: boolean | null,
      isProfileModelViewed?: boolean | null,
      type: UserType,
      isActive?: boolean | null,
      lastName?: string | null,
      createdAt: string,
      updatedAt: string,
      userOrganizationId: string,
      userWorkPreferenceId: string,
      userWorkStyleId: string,
      userWorkLocationId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateLearnMoreInfoMutationVariables = {
  input: CreateLearnMoreInfoInput,
  condition?: ModelLearnMoreInfoConditionInput | null,
};

export type CreateLearnMoreInfoMutation = {
  createLearnMoreInfo?:  {
    __typename: "LearnMoreInfo",
    id: string,
    shareInfoID: string,
    userID: string,
    shareInfo:  {
      __typename: "ShareInfo",
      id: string,
      name: string,
      organizationID: string,
      createdAt: string,
      updatedAt: string,
    },
    user:  {
      __typename: "User",
      id: string,
      offset: number,
      firstName?: string | null,
      email: string,
      phone?: string | null,
      sendWelcomeEmail?: boolean | null,
      personalEmail?: string | null,
      jobTitle?: string | null,
      birthday: string,
      startDate?: string | null,
      homeLocation?: string | null,
      team?: string | null,
      photoUrl: string,
      pronoun: Pronoun,
      aboutMe?: string | null,
      mostHappy?: string | null,
      socialMediaUrl?: string | null,
      slackUserId?: string | null,
      isFirstLogin?: boolean | null,
      isHomeModelViewed?: boolean | null,
      isProfileModelViewed?: boolean | null,
      type: UserType,
      isActive?: boolean | null,
      lastName?: string | null,
      createdAt: string,
      updatedAt: string,
      userOrganizationId: string,
      userWorkPreferenceId: string,
      userWorkStyleId: string,
      userWorkLocationId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateLearnMoreInfoMutationVariables = {
  input: UpdateLearnMoreInfoInput,
  condition?: ModelLearnMoreInfoConditionInput | null,
};

export type UpdateLearnMoreInfoMutation = {
  updateLearnMoreInfo?:  {
    __typename: "LearnMoreInfo",
    id: string,
    shareInfoID: string,
    userID: string,
    shareInfo:  {
      __typename: "ShareInfo",
      id: string,
      name: string,
      organizationID: string,
      createdAt: string,
      updatedAt: string,
    },
    user:  {
      __typename: "User",
      id: string,
      offset: number,
      firstName?: string | null,
      email: string,
      phone?: string | null,
      sendWelcomeEmail?: boolean | null,
      personalEmail?: string | null,
      jobTitle?: string | null,
      birthday: string,
      startDate?: string | null,
      homeLocation?: string | null,
      team?: string | null,
      photoUrl: string,
      pronoun: Pronoun,
      aboutMe?: string | null,
      mostHappy?: string | null,
      socialMediaUrl?: string | null,
      slackUserId?: string | null,
      isFirstLogin?: boolean | null,
      isHomeModelViewed?: boolean | null,
      isProfileModelViewed?: boolean | null,
      type: UserType,
      isActive?: boolean | null,
      lastName?: string | null,
      createdAt: string,
      updatedAt: string,
      userOrganizationId: string,
      userWorkPreferenceId: string,
      userWorkStyleId: string,
      userWorkLocationId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteLearnMoreInfoMutationVariables = {
  input: DeleteLearnMoreInfoInput,
  condition?: ModelLearnMoreInfoConditionInput | null,
};

export type DeleteLearnMoreInfoMutation = {
  deleteLearnMoreInfo?:  {
    __typename: "LearnMoreInfo",
    id: string,
    shareInfoID: string,
    userID: string,
    shareInfo:  {
      __typename: "ShareInfo",
      id: string,
      name: string,
      organizationID: string,
      createdAt: string,
      updatedAt: string,
    },
    user:  {
      __typename: "User",
      id: string,
      offset: number,
      firstName?: string | null,
      email: string,
      phone?: string | null,
      sendWelcomeEmail?: boolean | null,
      personalEmail?: string | null,
      jobTitle?: string | null,
      birthday: string,
      startDate?: string | null,
      homeLocation?: string | null,
      team?: string | null,
      photoUrl: string,
      pronoun: Pronoun,
      aboutMe?: string | null,
      mostHappy?: string | null,
      socialMediaUrl?: string | null,
      slackUserId?: string | null,
      isFirstLogin?: boolean | null,
      isHomeModelViewed?: boolean | null,
      isProfileModelViewed?: boolean | null,
      type: UserType,
      isActive?: boolean | null,
      lastName?: string | null,
      createdAt: string,
      updatedAt: string,
      userOrganizationId: string,
      userWorkPreferenceId: string,
      userWorkStyleId: string,
      userWorkLocationId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUserInterestMutationVariables = {
  input: CreateUserInterestInput,
  condition?: ModelUserInterestConditionInput | null,
};

export type CreateUserInterestMutation = {
  createUserInterest?:  {
    __typename: "UserInterest",
    id: string,
    interestID: string,
    userID: string,
    interest:  {
      __typename: "Interest",
      id: string,
      name: string,
      organizationID: string,
      createdAt: string,
      updatedAt: string,
    },
    user:  {
      __typename: "User",
      id: string,
      offset: number,
      firstName?: string | null,
      email: string,
      phone?: string | null,
      sendWelcomeEmail?: boolean | null,
      personalEmail?: string | null,
      jobTitle?: string | null,
      birthday: string,
      startDate?: string | null,
      homeLocation?: string | null,
      team?: string | null,
      photoUrl: string,
      pronoun: Pronoun,
      aboutMe?: string | null,
      mostHappy?: string | null,
      socialMediaUrl?: string | null,
      slackUserId?: string | null,
      isFirstLogin?: boolean | null,
      isHomeModelViewed?: boolean | null,
      isProfileModelViewed?: boolean | null,
      type: UserType,
      isActive?: boolean | null,
      lastName?: string | null,
      createdAt: string,
      updatedAt: string,
      userOrganizationId: string,
      userWorkPreferenceId: string,
      userWorkStyleId: string,
      userWorkLocationId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserInterestMutationVariables = {
  input: UpdateUserInterestInput,
  condition?: ModelUserInterestConditionInput | null,
};

export type UpdateUserInterestMutation = {
  updateUserInterest?:  {
    __typename: "UserInterest",
    id: string,
    interestID: string,
    userID: string,
    interest:  {
      __typename: "Interest",
      id: string,
      name: string,
      organizationID: string,
      createdAt: string,
      updatedAt: string,
    },
    user:  {
      __typename: "User",
      id: string,
      offset: number,
      firstName?: string | null,
      email: string,
      phone?: string | null,
      sendWelcomeEmail?: boolean | null,
      personalEmail?: string | null,
      jobTitle?: string | null,
      birthday: string,
      startDate?: string | null,
      homeLocation?: string | null,
      team?: string | null,
      photoUrl: string,
      pronoun: Pronoun,
      aboutMe?: string | null,
      mostHappy?: string | null,
      socialMediaUrl?: string | null,
      slackUserId?: string | null,
      isFirstLogin?: boolean | null,
      isHomeModelViewed?: boolean | null,
      isProfileModelViewed?: boolean | null,
      type: UserType,
      isActive?: boolean | null,
      lastName?: string | null,
      createdAt: string,
      updatedAt: string,
      userOrganizationId: string,
      userWorkPreferenceId: string,
      userWorkStyleId: string,
      userWorkLocationId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserInterestMutationVariables = {
  input: DeleteUserInterestInput,
  condition?: ModelUserInterestConditionInput | null,
};

export type DeleteUserInterestMutation = {
  deleteUserInterest?:  {
    __typename: "UserInterest",
    id: string,
    interestID: string,
    userID: string,
    interest:  {
      __typename: "Interest",
      id: string,
      name: string,
      organizationID: string,
      createdAt: string,
      updatedAt: string,
    },
    user:  {
      __typename: "User",
      id: string,
      offset: number,
      firstName?: string | null,
      email: string,
      phone?: string | null,
      sendWelcomeEmail?: boolean | null,
      personalEmail?: string | null,
      jobTitle?: string | null,
      birthday: string,
      startDate?: string | null,
      homeLocation?: string | null,
      team?: string | null,
      photoUrl: string,
      pronoun: Pronoun,
      aboutMe?: string | null,
      mostHappy?: string | null,
      socialMediaUrl?: string | null,
      slackUserId?: string | null,
      isFirstLogin?: boolean | null,
      isHomeModelViewed?: boolean | null,
      isProfileModelViewed?: boolean | null,
      type: UserType,
      isActive?: boolean | null,
      lastName?: string | null,
      createdAt: string,
      updatedAt: string,
      userOrganizationId: string,
      userWorkPreferenceId: string,
      userWorkStyleId: string,
      userWorkLocationId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetShareInfoQueryVariables = {
  id: string,
};

export type GetShareInfoQuery = {
  getShareInfo?:  {
    __typename: "ShareInfo",
    id: string,
    name: string,
    organizationID: string,
    shareInfoOnUsers?:  {
      __typename: "ModelUserShareInfoConnection",
      nextToken?: string | null,
    } | null,
    learnInfoOnUsers?:  {
      __typename: "ModelLearnMoreInfoConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListShareInfosQueryVariables = {
  filter?: ModelShareInfoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListShareInfosQuery = {
  listShareInfos?:  {
    __typename: "ModelShareInfoConnection",
    items:  Array< {
      __typename: "ShareInfo",
      id: string,
      name: string,
      organizationID: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetWorkStyleQueryVariables = {
  id: string,
};

export type GetWorkStyleQuery = {
  getWorkStyle?:  {
    __typename: "WorkStyle",
    id: string,
    whatIDoAtWork?: string | null,
    myFocusTimeIs?: string | null,
    giveReceivingFeedback?: string | null,
    digestionInformation?: string | null,
    inMyElement?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListWorkStylesQueryVariables = {
  filter?: ModelWorkStyleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListWorkStylesQuery = {
  listWorkStyles?:  {
    __typename: "ModelWorkStyleConnection",
    items:  Array< {
      __typename: "WorkStyle",
      id: string,
      whatIDoAtWork?: string | null,
      myFocusTimeIs?: string | null,
      giveReceivingFeedback?: string | null,
      digestionInformation?: string | null,
      inMyElement?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetWorkPreferenceQueryVariables = {
  id: string,
};

export type GetWorkPreferenceQuery = {
  getWorkPreference?:  {
    __typename: "WorkPreference",
    id: string,
    giveMeAllTheContext: number,
    messageTimingPreferene: number,
    syncxVsAsync: number,
    talkTogetherVsAlone: number,
    recognitionPrivateVsPrivate: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListWorkPreferencesQueryVariables = {
  filter?: ModelWorkPreferenceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListWorkPreferencesQuery = {
  listWorkPreferences?:  {
    __typename: "ModelWorkPreferenceConnection",
    items:  Array< {
      __typename: "WorkPreference",
      id: string,
      giveMeAllTheContext: number,
      messageTimingPreferene: number,
      syncxVsAsync: number,
      talkTogetherVsAlone: number,
      recognitionPrivateVsPrivate: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetInterestQueryVariables = {
  id: string,
};

export type GetInterestQuery = {
  getInterest?:  {
    __typename: "Interest",
    id: string,
    name: string,
    organizationID: string,
    users?:  {
      __typename: "ModelUserInterestConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListInterestsQueryVariables = {
  filter?: ModelInterestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInterestsQuery = {
  listInterests?:  {
    __typename: "ModelInterestConnection",
    items:  Array< {
      __typename: "Interest",
      id: string,
      name: string,
      organizationID: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetLocationQueryVariables = {
  id: string,
};

export type GetLocationQuery = {
  getLocation?:  {
    __typename: "Location",
    id: string,
    name?: string | null,
    streetAddress?: string | null,
    city?: string | null,
    state?: string | null,
    zipCode?: number | null,
    country?: string | null,
    locationType?: string | null,
    organizationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListLocationsQueryVariables = {
  filter?: ModelLocationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLocationsQuery = {
  listLocations?:  {
    __typename: "ModelLocationConnection",
    items:  Array< {
      __typename: "Location",
      id: string,
      name?: string | null,
      streetAddress?: string | null,
      city?: string | null,
      state?: string | null,
      zipCode?: number | null,
      country?: string | null,
      locationType?: string | null,
      organizationID: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOrganizationQueryVariables = {
  id: string,
};

export type GetOrganizationQuery = {
  getOrganization?:  {
    __typename: "Organization",
    id: string,
    name: string,
    slackAccessToken?: string | null,
    mergeCompanyToken?: string | null,
    atsToken?: string | null,
    atsAccountId?: string | null,
    hrisToken?: string | null,
    hrisAccountId?: string | null,
    hrisIntegratedAt?: string | null,
    slackUserAccessToken?: string | null,
    outlookTenantId?: string | null,
    msTeamsTenantId?: string | null,
    Locations?:  {
      __typename: "ModelLocationConnection",
      nextToken?: string | null,
    } | null,
    Interests?:  {
      __typename: "ModelInterestConnection",
      nextToken?: string | null,
    } | null,
    ShareInfos?:  {
      __typename: "ModelShareInfoConnection",
      nextToken?: string | null,
    } | null,
    status: OrganizationStatus,
    activeUntil: string,
    hasSubscription?: boolean | null,
    preboarding?: boolean | null,
    Journeys?:  {
      __typename: "ModelJourneyConnection",
      nextToken?: string | null,
    } | null,
    features?: string | null,
    globalDataStores?: string | null,
    settings?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListOrganizationsQueryVariables = {
  filter?: ModelOrganizationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrganizationsQuery = {
  listOrganizations?:  {
    __typename: "ModelOrganizationConnection",
    items:  Array< {
      __typename: "Organization",
      id: string,
      name: string,
      slackAccessToken?: string | null,
      mergeCompanyToken?: string | null,
      atsToken?: string | null,
      atsAccountId?: string | null,
      hrisToken?: string | null,
      hrisAccountId?: string | null,
      hrisIntegratedAt?: string | null,
      slackUserAccessToken?: string | null,
      outlookTenantId?: string | null,
      msTeamsTenantId?: string | null,
      status: OrganizationStatus,
      activeUntil: string,
      hasSubscription?: boolean | null,
      preboarding?: boolean | null,
      features?: string | null,
      globalDataStores?: string | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    offset: number,
    firstName?: string | null,
    email: string,
    phone?: string | null,
    sendWelcomeEmail?: boolean | null,
    personalEmail?: string | null,
    jobTitle?: string | null,
    birthday: string,
    startDate?: string | null,
    homeLocation?: string | null,
    team?: string | null,
    photoUrl: string,
    pronoun: Pronoun,
    aboutMe?: string | null,
    mostHappy?: string | null,
    socialMediaUrl?: string | null,
    slackUserId?: string | null,
    isFirstLogin?: boolean | null,
    isHomeModelViewed?: boolean | null,
    isProfileModelViewed?: boolean | null,
    type: UserType,
    isActive?: boolean | null,
    Organization:  {
      __typename: "Organization",
      id: string,
      name: string,
      slackAccessToken?: string | null,
      mergeCompanyToken?: string | null,
      atsToken?: string | null,
      atsAccountId?: string | null,
      hrisToken?: string | null,
      hrisAccountId?: string | null,
      hrisIntegratedAt?: string | null,
      slackUserAccessToken?: string | null,
      outlookTenantId?: string | null,
      msTeamsTenantId?: string | null,
      status: OrganizationStatus,
      activeUntil: string,
      hasSubscription?: boolean | null,
      preboarding?: boolean | null,
      features?: string | null,
      globalDataStores?: string | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    Interests?:  {
      __typename: "ModelUserInterestConnection",
      nextToken?: string | null,
    } | null,
    WorkPreference:  {
      __typename: "WorkPreference",
      id: string,
      giveMeAllTheContext: number,
      messageTimingPreferene: number,
      syncxVsAsync: number,
      talkTogetherVsAlone: number,
      recognitionPrivateVsPrivate: number,
      createdAt: string,
      updatedAt: string,
    },
    WorkStyle:  {
      __typename: "WorkStyle",
      id: string,
      whatIDoAtWork?: string | null,
      myFocusTimeIs?: string | null,
      giveReceivingFeedback?: string | null,
      digestionInformation?: string | null,
      inMyElement?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    lastName?: string | null,
    ShareInfoOn?:  {
      __typename: "ModelUserShareInfoConnection",
      nextToken?: string | null,
    } | null,
    LearnMoreOn?:  {
      __typename: "ModelLearnMoreInfoConnection",
      nextToken?: string | null,
    } | null,
    WorkLocation?:  {
      __typename: "Location",
      id: string,
      name?: string | null,
      streetAddress?: string | null,
      city?: string | null,
      state?: string | null,
      zipCode?: number | null,
      country?: string | null,
      locationType?: string | null,
      organizationID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    Journeys?:  {
      __typename: "ModelJourneyConnection",
      nextToken?: string | null,
    } | null,
    Meetings?:  {
      __typename: "ModelMeetingConnection",
      nextToken?: string | null,
    } | null,
    PreboardingUsers?:  {
      __typename: "ModelPreboardingUserConnection",
      nextToken?: string | null,
    } | null,
    PreboardingUserRelations?:  {
      __typename: "ModelPreboardingUserRelationConnection",
      nextToken?: string | null,
    } | null,
    UserBadges?:  {
      __typename: "ModelUserBadgeConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    userOrganizationId: string,
    userWorkPreferenceId: string,
    userWorkStyleId: string,
    userWorkLocationId?: string | null,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      offset: number,
      firstName?: string | null,
      email: string,
      phone?: string | null,
      sendWelcomeEmail?: boolean | null,
      personalEmail?: string | null,
      jobTitle?: string | null,
      birthday: string,
      startDate?: string | null,
      homeLocation?: string | null,
      team?: string | null,
      photoUrl: string,
      pronoun: Pronoun,
      aboutMe?: string | null,
      mostHappy?: string | null,
      socialMediaUrl?: string | null,
      slackUserId?: string | null,
      isFirstLogin?: boolean | null,
      isHomeModelViewed?: boolean | null,
      isProfileModelViewed?: boolean | null,
      type: UserType,
      isActive?: boolean | null,
      lastName?: string | null,
      createdAt: string,
      updatedAt: string,
      userOrganizationId: string,
      userWorkPreferenceId: string,
      userWorkStyleId: string,
      userWorkLocationId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetJourneyQueryVariables = {
  id: string,
};

export type GetJourneyQuery = {
  getJourney?:  {
    __typename: "Journey",
    id: string,
    name: string,
    createdByUserID: string,
    assignedUserID?: string | null,
    mode: PlaybookMode,
    startDate?: string | null,
    journeyProgress: number,
    parentJourneyID?: string | null,
    collaborator?: Array< string | null > | null,
    status: JourneyStatus,
    badgeID?: string | null,
    customBadgeName?: string | null,
    lockChapters?: boolean | null,
    type: BuilderType,
    publicLink?: string | null,
    eventStartedAt?: string | null,
    userStartedAt?: string | null,
    userCompletedAt?: string | null,
    isArchived?: boolean | null,
    preboarding?: boolean | null,
    organizationID: string,
    Milestone?:  {
      __typename: "ModelMilestoneConnection",
      nextToken?: string | null,
    } | null,
    Meetings?:  {
      __typename: "ModelMeetingConnection",
      nextToken?: string | null,
    } | null,
    JourneyCollaborator?:  {
      __typename: "ModelJourneyCollaboratorConnection",
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelEventConnection",
      nextToken?: string | null,
    } | null,
    image?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListJourneysQueryVariables = {
  filter?: ModelJourneyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListJourneysQuery = {
  listJourneys?:  {
    __typename: "ModelJourneyConnection",
    items:  Array< {
      __typename: "Journey",
      id: string,
      name: string,
      createdByUserID: string,
      assignedUserID?: string | null,
      mode: PlaybookMode,
      startDate?: string | null,
      journeyProgress: number,
      parentJourneyID?: string | null,
      collaborator?: Array< string | null > | null,
      status: JourneyStatus,
      badgeID?: string | null,
      customBadgeName?: string | null,
      lockChapters?: boolean | null,
      type: BuilderType,
      publicLink?: string | null,
      eventStartedAt?: string | null,
      userStartedAt?: string | null,
      userCompletedAt?: string | null,
      isArchived?: boolean | null,
      preboarding?: boolean | null,
      organizationID: string,
      image?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListJourneyByAssignedUserIdQueryVariables = {
  assignedUserID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelJourneyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListJourneyByAssignedUserIdQuery = {
  listJourneyByAssignedUserId?:  {
    __typename: "ModelJourneyConnection",
    items:  Array< {
      __typename: "Journey",
      id: string,
      name: string,
      createdByUserID: string,
      assignedUserID?: string | null,
      mode: PlaybookMode,
      startDate?: string | null,
      journeyProgress: number,
      parentJourneyID?: string | null,
      collaborator?: Array< string | null > | null,
      status: JourneyStatus,
      badgeID?: string | null,
      customBadgeName?: string | null,
      lockChapters?: boolean | null,
      type: BuilderType,
      publicLink?: string | null,
      eventStartedAt?: string | null,
      userStartedAt?: string | null,
      userCompletedAt?: string | null,
      isArchived?: boolean | null,
      preboarding?: boolean | null,
      organizationID: string,
      image?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListJourneyByOrganizationIdQueryVariables = {
  organizationID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelJourneyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListJourneyByOrganizationIdQuery = {
  listJourneyByOrganizationId?:  {
    __typename: "ModelJourneyConnection",
    items:  Array< {
      __typename: "Journey",
      id: string,
      name: string,
      createdByUserID: string,
      assignedUserID?: string | null,
      mode: PlaybookMode,
      startDate?: string | null,
      journeyProgress: number,
      parentJourneyID?: string | null,
      collaborator?: Array< string | null > | null,
      status: JourneyStatus,
      badgeID?: string | null,
      customBadgeName?: string | null,
      lockChapters?: boolean | null,
      type: BuilderType,
      publicLink?: string | null,
      eventStartedAt?: string | null,
      userStartedAt?: string | null,
      userCompletedAt?: string | null,
      isArchived?: boolean | null,
      preboarding?: boolean | null,
      organizationID: string,
      image?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBadgeQueryVariables = {
  id: string,
};

export type GetBadgeQuery = {
  getBadge?:  {
    __typename: "Badge",
    id: string,
    name?: string | null,
    iconUrl: string,
    isArchived?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListBadgesQueryVariables = {
  filter?: ModelBadgeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBadgesQuery = {
  listBadges?:  {
    __typename: "ModelBadgeConnection",
    items:  Array< {
      __typename: "Badge",
      id: string,
      name?: string | null,
      iconUrl: string,
      isArchived?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserBadgeQueryVariables = {
  id: string,
};

export type GetUserBadgeQuery = {
  getUserBadge?:  {
    __typename: "UserBadge",
    id: string,
    playbookId: string,
    userID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUserBadgesQueryVariables = {
  filter?: ModelUserBadgeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserBadgesQuery = {
  listUserBadges?:  {
    __typename: "ModelUserBadgeConnection",
    items:  Array< {
      __typename: "UserBadge",
      id: string,
      playbookId: string,
      userID: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetJourneyCollaboratorQueryVariables = {
  id: string,
};

export type GetJourneyCollaboratorQuery = {
  getJourneyCollaborator?:  {
    __typename: "JourneyCollaborator",
    id: string,
    assigneeRole: AssigneeRole,
    journeyAccess?: JourneyAccess | null,
    assignedUserID?: string | null,
    actionID?: Array< string | null > | null,
    meetingID?: Array< string | null > | null,
    updatedInChild?: boolean | null,
    journeyID?: string | null,
    kinfolkTemplateID?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListJourneyCollaboratorsQueryVariables = {
  filter?: ModelJourneyCollaboratorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListJourneyCollaboratorsQuery = {
  listJourneyCollaborators?:  {
    __typename: "ModelJourneyCollaboratorConnection",
    items:  Array< {
      __typename: "JourneyCollaborator",
      id: string,
      assigneeRole: AssigneeRole,
      journeyAccess?: JourneyAccess | null,
      assignedUserID?: string | null,
      actionID?: Array< string | null > | null,
      meetingID?: Array< string | null > | null,
      updatedInChild?: boolean | null,
      journeyID?: string | null,
      kinfolkTemplateID?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListJourneyCollaboratorByJourneyIdQueryVariables = {
  journeyID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelJourneyCollaboratorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListJourneyCollaboratorByJourneyIdQuery = {
  listJourneyCollaboratorByJourneyId?:  {
    __typename: "ModelJourneyCollaboratorConnection",
    items:  Array< {
      __typename: "JourneyCollaborator",
      id: string,
      assigneeRole: AssigneeRole,
      journeyAccess?: JourneyAccess | null,
      assignedUserID?: string | null,
      actionID?: Array< string | null > | null,
      meetingID?: Array< string | null > | null,
      updatedInChild?: boolean | null,
      journeyID?: string | null,
      kinfolkTemplateID?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListJourneyCollaboratorByKinfolkTemplateIdQueryVariables = {
  kinfolkTemplateID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelJourneyCollaboratorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListJourneyCollaboratorByKinfolkTemplateIdQuery = {
  listJourneyCollaboratorByKinfolkTemplateId?:  {
    __typename: "ModelJourneyCollaboratorConnection",
    items:  Array< {
      __typename: "JourneyCollaborator",
      id: string,
      assigneeRole: AssigneeRole,
      journeyAccess?: JourneyAccess | null,
      assignedUserID?: string | null,
      actionID?: Array< string | null > | null,
      meetingID?: Array< string | null > | null,
      updatedInChild?: boolean | null,
      journeyID?: string | null,
      kinfolkTemplateID?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetKinfolkTemplateQueryVariables = {
  id: string,
};

export type GetKinfolkTemplateQuery = {
  getKinfolkTemplate?:  {
    __typename: "KinfolkTemplate",
    id: string,
    name: string,
    description: string,
    image: string,
    isArchived?: boolean | null,
    status: TemplateStatus,
    orderNo?: number | null,
    type: BuilderType,
    mode: PlaybookMode,
    isPublic?: boolean | null,
    Milestone?:  {
      __typename: "ModelMilestoneConnection",
      nextToken?: string | null,
    } | null,
    Meetings?:  {
      __typename: "ModelMeetingConnection",
      nextToken?: string | null,
    } | null,
    JourneyCollaborator?:  {
      __typename: "ModelJourneyCollaboratorConnection",
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelEventConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListKinfolkTemplatesQueryVariables = {
  filter?: ModelKinfolkTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListKinfolkTemplatesQuery = {
  listKinfolkTemplates?:  {
    __typename: "ModelKinfolkTemplateConnection",
    items:  Array< {
      __typename: "KinfolkTemplate",
      id: string,
      name: string,
      description: string,
      image: string,
      isArchived?: boolean | null,
      status: TemplateStatus,
      orderNo?: number | null,
      type: BuilderType,
      mode: PlaybookMode,
      isPublic?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMilestoneQueryVariables = {
  id: string,
};

export type GetMilestoneQuery = {
  getMilestone?:  {
    __typename: "Milestone",
    id: string,
    name: string,
    kinfolkTemplateID?: string | null,
    isCompleted?: boolean | null,
    isArchived?: boolean | null,
    journeyID?: string | null,
    orderNo: number,
    parentMilestoneID?: string | null,
    EmbeddedContentBlocks?:  {
      __typename: "ModelEmbeddedContentBlockConnection",
      nextToken?: string | null,
    } | null,
    FreeTextQuestionBlocks?:  {
      __typename: "ModelFreeTextQuestionBlockConnection",
      nextToken?: string | null,
    } | null,
    NoteBlocks?:  {
      __typename: "ModelNoteBlockConnection",
      nextToken?: string | null,
    } | null,
    TaskBlocks?:  {
      __typename: "ModelTaskBlockConnection",
      nextToken?: string | null,
    } | null,
    StandardContentBlocks?:  {
      __typename: "ModelStandardContentBlockConnection",
      nextToken?: string | null,
    } | null,
    MultipleChoiceQuestionBlocks?:  {
      __typename: "ModelMultipleChoiceQuestionBlockConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListMilestonesQueryVariables = {
  filter?: ModelMilestoneFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMilestonesQuery = {
  listMilestones?:  {
    __typename: "ModelMilestoneConnection",
    items:  Array< {
      __typename: "Milestone",
      id: string,
      name: string,
      kinfolkTemplateID?: string | null,
      isCompleted?: boolean | null,
      isArchived?: boolean | null,
      journeyID?: string | null,
      orderNo: number,
      parentMilestoneID?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListMilestoneByKinfolkTemplateIdQueryVariables = {
  kinfolkTemplateID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMilestoneFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMilestoneByKinfolkTemplateIdQuery = {
  listMilestoneByKinfolkTemplateId?:  {
    __typename: "ModelMilestoneConnection",
    items:  Array< {
      __typename: "Milestone",
      id: string,
      name: string,
      kinfolkTemplateID?: string | null,
      isCompleted?: boolean | null,
      isArchived?: boolean | null,
      journeyID?: string | null,
      orderNo: number,
      parentMilestoneID?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListMilestoneByJourneyIdQueryVariables = {
  journeyID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMilestoneFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMilestoneByJourneyIdQuery = {
  listMilestoneByJourneyId?:  {
    __typename: "ModelMilestoneConnection",
    items:  Array< {
      __typename: "Milestone",
      id: string,
      name: string,
      kinfolkTemplateID?: string | null,
      isCompleted?: boolean | null,
      isArchived?: boolean | null,
      journeyID?: string | null,
      orderNo: number,
      parentMilestoneID?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEmbeddedContentBlockQueryVariables = {
  id: string,
};

export type GetEmbeddedContentBlockQuery = {
  getEmbeddedContentBlock?:  {
    __typename: "EmbeddedContentBlock",
    id: string,
    type: BlockType,
    milestoneID: string,
    title: string,
    description?: string | null,
    resourceLink: string,
    isFormLinked?: boolean | null,
    orderNo: number,
    parentEmbeddedContentBlockID?: string | null,
    isCompleted?: boolean | null,
    isArchived?: boolean | null,
    updatedInChild?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListEmbeddedContentBlocksQueryVariables = {
  filter?: ModelEmbeddedContentBlockFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEmbeddedContentBlocksQuery = {
  listEmbeddedContentBlocks?:  {
    __typename: "ModelEmbeddedContentBlockConnection",
    items:  Array< {
      __typename: "EmbeddedContentBlock",
      id: string,
      type: BlockType,
      milestoneID: string,
      title: string,
      description?: string | null,
      resourceLink: string,
      isFormLinked?: boolean | null,
      orderNo: number,
      parentEmbeddedContentBlockID?: string | null,
      isCompleted?: boolean | null,
      isArchived?: boolean | null,
      updatedInChild?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetFreeTextQuestionBlockQueryVariables = {
  id: string,
};

export type GetFreeTextQuestionBlockQuery = {
  getFreeTextQuestionBlock?:  {
    __typename: "FreeTextQuestionBlock",
    id: string,
    type: BlockType,
    milestoneID: string,
    title: string,
    description?: string | null,
    text?: string | null,
    orderNo: number,
    parentFreeTextQuestionBlockID?: string | null,
    isCompleted?: boolean | null,
    isArchived?: boolean | null,
    updatedInChild?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListFreeTextQuestionBlocksQueryVariables = {
  filter?: ModelFreeTextQuestionBlockFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFreeTextQuestionBlocksQuery = {
  listFreeTextQuestionBlocks?:  {
    __typename: "ModelFreeTextQuestionBlockConnection",
    items:  Array< {
      __typename: "FreeTextQuestionBlock",
      id: string,
      type: BlockType,
      milestoneID: string,
      title: string,
      description?: string | null,
      text?: string | null,
      orderNo: number,
      parentFreeTextQuestionBlockID?: string | null,
      isCompleted?: boolean | null,
      isArchived?: boolean | null,
      updatedInChild?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetNoteBlockQueryVariables = {
  id: string,
};

export type GetNoteBlockQuery = {
  getNoteBlock?:  {
    __typename: "NoteBlock",
    id: string,
    type: BlockType,
    milestoneID: string,
    title: string,
    description?: string | null,
    text?: string | null,
    orderNo: number,
    parentNoteBlockID?: string | null,
    isCompleted?: boolean | null,
    isPrivate?: boolean | null,
    isArchived?: boolean | null,
    updatedInChild?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListNoteBlocksQueryVariables = {
  filter?: ModelNoteBlockFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNoteBlocksQuery = {
  listNoteBlocks?:  {
    __typename: "ModelNoteBlockConnection",
    items:  Array< {
      __typename: "NoteBlock",
      id: string,
      type: BlockType,
      milestoneID: string,
      title: string,
      description?: string | null,
      text?: string | null,
      orderNo: number,
      parentNoteBlockID?: string | null,
      isCompleted?: boolean | null,
      isPrivate?: boolean | null,
      isArchived?: boolean | null,
      updatedInChild?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTaskBlockQueryVariables = {
  id: string,
};

export type GetTaskBlockQuery = {
  getTaskBlock?:  {
    __typename: "TaskBlock",
    id: string,
    type: BlockType,
    milestoneID: string,
    title: string,
    description?: string | null,
    status?: boolean | null,
    orderNo: number,
    parentTaskBlockID?: string | null,
    isCompleted?: boolean | null,
    isArchived?: boolean | null,
    updatedInChild?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTaskBlocksQueryVariables = {
  filter?: ModelTaskBlockFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTaskBlocksQuery = {
  listTaskBlocks?:  {
    __typename: "ModelTaskBlockConnection",
    items:  Array< {
      __typename: "TaskBlock",
      id: string,
      type: BlockType,
      milestoneID: string,
      title: string,
      description?: string | null,
      status?: boolean | null,
      orderNo: number,
      parentTaskBlockID?: string | null,
      isCompleted?: boolean | null,
      isArchived?: boolean | null,
      updatedInChild?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetStandardContentBlockQueryVariables = {
  id: string,
};

export type GetStandardContentBlockQuery = {
  getStandardContentBlock?:  {
    __typename: "StandardContentBlock",
    id: string,
    type: BlockType,
    milestoneID: string,
    title: string,
    description?: string | null,
    orderNo: number,
    parentStandardContentBlockID?: string | null,
    isCompleted?: boolean | null,
    isArchived?: boolean | null,
    updatedInChild?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListStandardContentBlocksQueryVariables = {
  filter?: ModelStandardContentBlockFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListStandardContentBlocksQuery = {
  listStandardContentBlocks?:  {
    __typename: "ModelStandardContentBlockConnection",
    items:  Array< {
      __typename: "StandardContentBlock",
      id: string,
      type: BlockType,
      milestoneID: string,
      title: string,
      description?: string | null,
      orderNo: number,
      parentStandardContentBlockID?: string | null,
      isCompleted?: boolean | null,
      isArchived?: boolean | null,
      updatedInChild?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMultipleChoiceQuestionBlockQueryVariables = {
  id: string,
};

export type GetMultipleChoiceQuestionBlockQuery = {
  getMultipleChoiceQuestionBlock?:  {
    __typename: "MultipleChoiceQuestionBlock",
    id: string,
    type: BlockType,
    milestoneID: string,
    title: string,
    description?: string | null,
    option?: Array< string > | null,
    answer?: Array< string > | null,
    orderNo: number,
    parentMultipleChoiceQuestionBlockID?: string | null,
    isCompleted?: boolean | null,
    isRequired?: boolean | null,
    isArchived?: boolean | null,
    updatedInChild?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListMultipleChoiceQuestionBlocksQueryVariables = {
  filter?: ModelMultipleChoiceQuestionBlockFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMultipleChoiceQuestionBlocksQuery = {
  listMultipleChoiceQuestionBlocks?:  {
    __typename: "ModelMultipleChoiceQuestionBlockConnection",
    items:  Array< {
      __typename: "MultipleChoiceQuestionBlock",
      id: string,
      type: BlockType,
      milestoneID: string,
      title: string,
      description?: string | null,
      option?: Array< string > | null,
      answer?: Array< string > | null,
      orderNo: number,
      parentMultipleChoiceQuestionBlockID?: string | null,
      isCompleted?: boolean | null,
      isRequired?: boolean | null,
      isArchived?: boolean | null,
      updatedInChild?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEventQueryVariables = {
  id: string,
};

export type GetEventQuery = {
  getEvent?:  {
    __typename: "Event",
    id: string,
    userId?: string | null,
    formId?: string | null,
    formInstanceId?: string | null,
    daysFrom: number,
    daysFromDueDate?: number | null,
    type: string,
    role?: AssigneeRole | null,
    emailTo?: string | null,
    title?: string | null,
    message?: string | null,
    sendTime?: string | null,
    sendTimeDate?: string | null,
    dueDate?: string | null,
    status: EventStatus,
    channelId?: string | null,
    parentId?: string | null,
    channel: Channel,
    cc?: Array< string | null > | null,
    archived?: boolean | null,
    updatedInChild?: boolean | null,
    journeyID?: string | null,
    kinfolkTemplateID?: string | null,
    reminders?:  {
      __typename: "ModelEventReminderConnection",
      nextToken?: string | null,
    } | null,
    meta?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListEventsQueryVariables = {
  filter?: ModelEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventsQuery = {
  listEvents?:  {
    __typename: "ModelEventConnection",
    items:  Array< {
      __typename: "Event",
      id: string,
      userId?: string | null,
      formId?: string | null,
      formInstanceId?: string | null,
      daysFrom: number,
      daysFromDueDate?: number | null,
      type: string,
      role?: AssigneeRole | null,
      emailTo?: string | null,
      title?: string | null,
      message?: string | null,
      sendTime?: string | null,
      sendTimeDate?: string | null,
      dueDate?: string | null,
      status: EventStatus,
      channelId?: string | null,
      parentId?: string | null,
      channel: Channel,
      cc?: Array< string | null > | null,
      archived?: boolean | null,
      updatedInChild?: boolean | null,
      journeyID?: string | null,
      kinfolkTemplateID?: string | null,
      meta?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListEventsByJourneyIdQueryVariables = {
  journeyID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventsByJourneyIdQuery = {
  listEventsByJourneyId?:  {
    __typename: "ModelEventConnection",
    items:  Array< {
      __typename: "Event",
      id: string,
      userId?: string | null,
      formId?: string | null,
      formInstanceId?: string | null,
      daysFrom: number,
      daysFromDueDate?: number | null,
      type: string,
      role?: AssigneeRole | null,
      emailTo?: string | null,
      title?: string | null,
      message?: string | null,
      sendTime?: string | null,
      sendTimeDate?: string | null,
      dueDate?: string | null,
      status: EventStatus,
      channelId?: string | null,
      parentId?: string | null,
      channel: Channel,
      cc?: Array< string | null > | null,
      archived?: boolean | null,
      updatedInChild?: boolean | null,
      journeyID?: string | null,
      kinfolkTemplateID?: string | null,
      meta?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListEventsByKinfolkTemplateIdQueryVariables = {
  kinfolkTemplateID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventsByKinfolkTemplateIdQuery = {
  listEventsByKinfolkTemplateId?:  {
    __typename: "ModelEventConnection",
    items:  Array< {
      __typename: "Event",
      id: string,
      userId?: string | null,
      formId?: string | null,
      formInstanceId?: string | null,
      daysFrom: number,
      daysFromDueDate?: number | null,
      type: string,
      role?: AssigneeRole | null,
      emailTo?: string | null,
      title?: string | null,
      message?: string | null,
      sendTime?: string | null,
      sendTimeDate?: string | null,
      dueDate?: string | null,
      status: EventStatus,
      channelId?: string | null,
      parentId?: string | null,
      channel: Channel,
      cc?: Array< string | null > | null,
      archived?: boolean | null,
      updatedInChild?: boolean | null,
      journeyID?: string | null,
      kinfolkTemplateID?: string | null,
      meta?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEventReminderQueryVariables = {
  id: string,
};

export type GetEventReminderQuery = {
  getEventReminder?:  {
    __typename: "EventReminder",
    id: string,
    eventId: string,
    sendTimeDate?: string | null,
    daysFrom: number,
    status: EventStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListEventRemindersQueryVariables = {
  filter?: ModelEventReminderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventRemindersQuery = {
  listEventReminders?:  {
    __typename: "ModelEventReminderConnection",
    items:  Array< {
      __typename: "EventReminder",
      id: string,
      eventId: string,
      sendTimeDate?: string | null,
      daysFrom: number,
      status: EventStatus,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListRemindersByEventIdQueryVariables = {
  eventId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventReminderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRemindersByEventIdQuery = {
  listRemindersByEventId?:  {
    __typename: "ModelEventReminderConnection",
    items:  Array< {
      __typename: "EventReminder",
      id: string,
      eventId: string,
      sendTimeDate?: string | null,
      daysFrom: number,
      status: EventStatus,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMeetingQueryVariables = {
  id: string,
};

export type GetMeetingQuery = {
  getMeeting?:  {
    __typename: "Meeting",
    id: string,
    kinfolkTemplateID?: string | null,
    journeyID?: string | null,
    organizedByUserID?: string | null,
    status: MeetingStatus,
    title: string,
    description?: string | null,
    attendeesEmail?: Array< string | null > | null,
    startTime?: string | null,
    endTime?: string | null,
    parentMeetingID?: string | null,
    updatedInChild?: boolean | null,
    eventDate?: string | null,
    duration: string,
    link?: string | null,
    assigneeRole?: AssigneeRole | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListMeetingsQueryVariables = {
  filter?: ModelMeetingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMeetingsQuery = {
  listMeetings?:  {
    __typename: "ModelMeetingConnection",
    items:  Array< {
      __typename: "Meeting",
      id: string,
      kinfolkTemplateID?: string | null,
      journeyID?: string | null,
      organizedByUserID?: string | null,
      status: MeetingStatus,
      title: string,
      description?: string | null,
      attendeesEmail?: Array< string | null > | null,
      startTime?: string | null,
      endTime?: string | null,
      parentMeetingID?: string | null,
      updatedInChild?: boolean | null,
      eventDate?: string | null,
      duration: string,
      link?: string | null,
      assigneeRole?: AssigneeRole | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListMeetingByKinfolkTemplateIdQueryVariables = {
  kinfolkTemplateID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMeetingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMeetingByKinfolkTemplateIdQuery = {
  listMeetingByKinfolkTemplateId?:  {
    __typename: "ModelMeetingConnection",
    items:  Array< {
      __typename: "Meeting",
      id: string,
      kinfolkTemplateID?: string | null,
      journeyID?: string | null,
      organizedByUserID?: string | null,
      status: MeetingStatus,
      title: string,
      description?: string | null,
      attendeesEmail?: Array< string | null > | null,
      startTime?: string | null,
      endTime?: string | null,
      parentMeetingID?: string | null,
      updatedInChild?: boolean | null,
      eventDate?: string | null,
      duration: string,
      link?: string | null,
      assigneeRole?: AssigneeRole | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListMeetingByJourneyIdQueryVariables = {
  journeyID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMeetingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMeetingByJourneyIdQuery = {
  listMeetingByJourneyId?:  {
    __typename: "ModelMeetingConnection",
    items:  Array< {
      __typename: "Meeting",
      id: string,
      kinfolkTemplateID?: string | null,
      journeyID?: string | null,
      organizedByUserID?: string | null,
      status: MeetingStatus,
      title: string,
      description?: string | null,
      attendeesEmail?: Array< string | null > | null,
      startTime?: string | null,
      endTime?: string | null,
      parentMeetingID?: string | null,
      updatedInChild?: boolean | null,
      eventDate?: string | null,
      duration: string,
      link?: string | null,
      assigneeRole?: AssigneeRole | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSecretKeyManagerQueryVariables = {
  id: string,
};

export type GetSecretKeyManagerQuery = {
  getSecretKeyManager?:  {
    __typename: "SecretKeyManager",
    id: string,
    organizationID: string,
    clientEmail: string,
    privateKey: string,
    adminEmail: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSecretKeyManagersQueryVariables = {
  filter?: ModelSecretKeyManagerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSecretKeyManagersQuery = {
  listSecretKeyManagers?:  {
    __typename: "ModelSecretKeyManagerConnection",
    items:  Array< {
      __typename: "SecretKeyManager",
      id: string,
      organizationID: string,
      clientEmail: string,
      privateKey: string,
      adminEmail: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPreboardingUserQueryVariables = {
  id: string,
};

export type GetPreboardingUserQuery = {
  getPreboardingUser?:  {
    __typename: "PreboardingUser",
    id: string,
    userId: string,
    isSubmitted?: boolean | null,
    consent?: boolean | null,
    journeyId?: string | null,
    personalEmail?: string | null,
    photoUrl?: string | null,
    bankName?: string | null,
    bankBranch?: string | null,
    bankSortCode?: string | null,
    bankAccountNumber?: string | null,
    bankAccountName?: string | null,
    buildingSocietyRoll?: string | null,
    street1?: string | null,
    street2?: string | null,
    city?: string | null,
    state?: string | null,
    zip?: string | null,
    country?: string | null,
    birthday?: string | null,
    gender?: string | null,
    title?: string | null,
    nationality?: string | null,
    mobileNumber?: string | null,
    niNumber?: string | null,
    laptopPreference?: LaptopPreference | null,
    secondMonitor?: boolean | null,
    corporateCard?: boolean | null,
    corporatePhone?: PhonePreference | null,
    healthCare?: boolean | null,
    dental?: boolean | null,
    optical?: boolean | null,
    jobDeclaration?: Declaration | null,
    expatDeclaration?: Declaration | null,
    studentLoanRepayment?: boolean | null,
    studentLoanType?: StudentLoanType | null,
    pensionScheme?: boolean | null,
    pensionContribution?: number | null,
    passportNumber?: string | null,
    passportIssueDate?: string | null,
    passportExpiryDate?: string | null,
    passportIssuingCountry?: string | null,
    confirmation?: boolean | null,
    pensionSchemeOpt?: string | null,
    emergencyContactName?: string | null,
    emergencyContactRelationship?: string | null,
    emergencyContactPhone?: string | null,
    emergencyContactEmailAddress?: string | null,
    emergencyContactStreet1?: string | null,
    emergencyContactStreet2?: string | null,
    emergencyContactCity?: string | null,
    emergencyContactState?: string | null,
    emergencyContactZip?: string | null,
    emergencyContactCountry?: string | null,
    PreboardingUserRelations?:  {
      __typename: "ModelPreboardingUserRelationConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPreboardingUsersQueryVariables = {
  filter?: ModelPreboardingUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPreboardingUsersQuery = {
  listPreboardingUsers?:  {
    __typename: "ModelPreboardingUserConnection",
    items:  Array< {
      __typename: "PreboardingUser",
      id: string,
      userId: string,
      isSubmitted?: boolean | null,
      consent?: boolean | null,
      journeyId?: string | null,
      personalEmail?: string | null,
      photoUrl?: string | null,
      bankName?: string | null,
      bankBranch?: string | null,
      bankSortCode?: string | null,
      bankAccountNumber?: string | null,
      bankAccountName?: string | null,
      buildingSocietyRoll?: string | null,
      street1?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      country?: string | null,
      birthday?: string | null,
      gender?: string | null,
      title?: string | null,
      nationality?: string | null,
      mobileNumber?: string | null,
      niNumber?: string | null,
      laptopPreference?: LaptopPreference | null,
      secondMonitor?: boolean | null,
      corporateCard?: boolean | null,
      corporatePhone?: PhonePreference | null,
      healthCare?: boolean | null,
      dental?: boolean | null,
      optical?: boolean | null,
      jobDeclaration?: Declaration | null,
      expatDeclaration?: Declaration | null,
      studentLoanRepayment?: boolean | null,
      studentLoanType?: StudentLoanType | null,
      pensionScheme?: boolean | null,
      pensionContribution?: number | null,
      passportNumber?: string | null,
      passportIssueDate?: string | null,
      passportExpiryDate?: string | null,
      passportIssuingCountry?: string | null,
      confirmation?: boolean | null,
      pensionSchemeOpt?: string | null,
      emergencyContactName?: string | null,
      emergencyContactRelationship?: string | null,
      emergencyContactPhone?: string | null,
      emergencyContactEmailAddress?: string | null,
      emergencyContactStreet1?: string | null,
      emergencyContactStreet2?: string | null,
      emergencyContactCity?: string | null,
      emergencyContactState?: string | null,
      emergencyContactZip?: string | null,
      emergencyContactCountry?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPreboardingUserRelationQueryVariables = {
  id: string,
};

export type GetPreboardingUserRelationQuery = {
  getPreboardingUserRelation?:  {
    __typename: "PreboardingUserRelation",
    id: string,
    firstName?: string | null,
    lastName?: string | null,
    relationshipToMember?: string | null,
    birthday?: string | null,
    userId: string,
    formId: string,
    healthCare?: boolean | null,
    dental?: boolean | null,
    optical?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPreboardingUserRelationsQueryVariables = {
  filter?: ModelPreboardingUserRelationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPreboardingUserRelationsQuery = {
  listPreboardingUserRelations?:  {
    __typename: "ModelPreboardingUserRelationConnection",
    items:  Array< {
      __typename: "PreboardingUserRelation",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      relationshipToMember?: string | null,
      birthday?: string | null,
      userId: string,
      formId: string,
      healthCare?: boolean | null,
      dental?: boolean | null,
      optical?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDigestQueryVariables = {
  id: string,
};

export type GetDigestQuery = {
  getDigest?:  {
    __typename: "Digest",
    id: string,
    type: DigestType,
    organizationId: string,
    userId: string,
    frequency: Frequency,
    channel: Channel,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListDigestsQueryVariables = {
  filter?: ModelDigestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDigestsQuery = {
  listDigests?:  {
    __typename: "ModelDigestConnection",
    items:  Array< {
      __typename: "Digest",
      id: string,
      type: DigestType,
      organizationId: string,
      userId: string,
      frequency: Frequency,
      channel: Channel,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetGqlMessageQueryVariables = {
  id: string,
};

export type GetGqlMessageQuery = {
  getGqlMessage?:  {
    __typename: "GqlMessage",
    id: string,
    type: string,
    organizationId: string,
    recipientId: string,
    payload: string,
    response?: string | null,
    status: string,
    callbackId?: string | null,
    deliveryInfo?: string | null,
    respondedAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListGqlMessagesQueryVariables = {
  filter?: ModelGqlMessageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListGqlMessagesQuery = {
  listGqlMessages?:  {
    __typename: "ModelGqlMessageConnection",
    items:  Array< {
      __typename: "GqlMessage",
      id: string,
      type: string,
      organizationId: string,
      recipientId: string,
      payload: string,
      response?: string | null,
      status: string,
      callbackId?: string | null,
      deliveryInfo?: string | null,
      respondedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetGqlMsTeamsUserReferenceQueryVariables = {
  id: string,
};

export type GetGqlMsTeamsUserReferenceQuery = {
  getGqlMsTeamsUserReference?:  {
    __typename: "GqlMsTeamsUserReference",
    tenantId: string,
    userEmail: string,
    teamsUserId?: string | null,
    conversation?: string | null,
    userId?: string | null,
    organizationId?: string | null,
    hasUndeliveredMessages?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListGqlMsTeamsUserReferencesQueryVariables = {
  filter?: ModelGqlMsTeamsUserReferenceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListGqlMsTeamsUserReferencesQuery = {
  listGqlMsTeamsUserReferences?:  {
    __typename: "ModelGqlMsTeamsUserReferenceConnection",
    items:  Array< {
      __typename: "GqlMsTeamsUserReference",
      tenantId: string,
      userEmail: string,
      teamsUserId?: string | null,
      conversation?: string | null,
      userId?: string | null,
      organizationId?: string | null,
      hasUndeliveredMessages?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetGqlDataStoreQueryVariables = {
  id: string,
};

export type GetGqlDataStoreQuery = {
  getGqlDataStore?:  {
    __typename: "GqlDataStore",
    id: string,
    organizationId: string,
    name: string,
    data: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListGqlDataStoresQueryVariables = {
  filter?: ModelGqlDataStoreFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListGqlDataStoresQuery = {
  listGqlDataStores?:  {
    __typename: "ModelGqlDataStoreConnection",
    items:  Array< {
      __typename: "GqlDataStore",
      id: string,
      organizationId: string,
      name: string,
      data: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetGqlDataStoreRowQueryVariables = {
  id: string,
};

export type GetGqlDataStoreRowQuery = {
  getGqlDataStoreRow?:  {
    __typename: "GqlDataStoreRow",
    id: string,
    dataStoreId: string,
    data: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListGqlDataStoreRowsQueryVariables = {
  filter?: ModelGqlDataStoreRowFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListGqlDataStoreRowsQuery = {
  listGqlDataStoreRows?:  {
    __typename: "ModelGqlDataStoreRowConnection",
    items:  Array< {
      __typename: "GqlDataStoreRow",
      id: string,
      dataStoreId: string,
      data: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetGqlFormQueryVariables = {
  id: string,
};

export type GetGqlFormQuery = {
  getGqlForm?:  {
    __typename: "GqlForm",
    id: string,
    organizationId: string,
    name: string,
    dataStoreId: string,
    data: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListGqlFormsQueryVariables = {
  filter?: ModelGqlFormFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListGqlFormsQuery = {
  listGqlForms?:  {
    __typename: "ModelGqlFormConnection",
    items:  Array< {
      __typename: "GqlForm",
      id: string,
      organizationId: string,
      name: string,
      dataStoreId: string,
      data: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetGqlFormInstanceQueryVariables = {
  id: string,
};

export type GetGqlFormInstanceQuery = {
  getGqlFormInstance?:  {
    __typename: "GqlFormInstance",
    id: string,
    organizationId: string,
    formId: string,
    assignedTo: string,
    link: string,
    slug: string,
    data: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListGqlFormInstancesQueryVariables = {
  filter?: ModelGqlFormInstanceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListGqlFormInstancesQuery = {
  listGqlFormInstances?:  {
    __typename: "ModelGqlFormInstanceConnection",
    items:  Array< {
      __typename: "GqlFormInstance",
      id: string,
      organizationId: string,
      formId: string,
      assignedTo: string,
      link: string,
      slug: string,
      data: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserShareInfoQueryVariables = {
  id: string,
};

export type GetUserShareInfoQuery = {
  getUserShareInfo?:  {
    __typename: "UserShareInfo",
    id: string,
    shareInfoID: string,
    userID: string,
    shareInfo:  {
      __typename: "ShareInfo",
      id: string,
      name: string,
      organizationID: string,
      createdAt: string,
      updatedAt: string,
    },
    user:  {
      __typename: "User",
      id: string,
      offset: number,
      firstName?: string | null,
      email: string,
      phone?: string | null,
      sendWelcomeEmail?: boolean | null,
      personalEmail?: string | null,
      jobTitle?: string | null,
      birthday: string,
      startDate?: string | null,
      homeLocation?: string | null,
      team?: string | null,
      photoUrl: string,
      pronoun: Pronoun,
      aboutMe?: string | null,
      mostHappy?: string | null,
      socialMediaUrl?: string | null,
      slackUserId?: string | null,
      isFirstLogin?: boolean | null,
      isHomeModelViewed?: boolean | null,
      isProfileModelViewed?: boolean | null,
      type: UserType,
      isActive?: boolean | null,
      lastName?: string | null,
      createdAt: string,
      updatedAt: string,
      userOrganizationId: string,
      userWorkPreferenceId: string,
      userWorkStyleId: string,
      userWorkLocationId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUserShareInfosQueryVariables = {
  filter?: ModelUserShareInfoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserShareInfosQuery = {
  listUserShareInfos?:  {
    __typename: "ModelUserShareInfoConnection",
    items:  Array< {
      __typename: "UserShareInfo",
      id: string,
      shareInfoID: string,
      userID: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetLearnMoreInfoQueryVariables = {
  id: string,
};

export type GetLearnMoreInfoQuery = {
  getLearnMoreInfo?:  {
    __typename: "LearnMoreInfo",
    id: string,
    shareInfoID: string,
    userID: string,
    shareInfo:  {
      __typename: "ShareInfo",
      id: string,
      name: string,
      organizationID: string,
      createdAt: string,
      updatedAt: string,
    },
    user:  {
      __typename: "User",
      id: string,
      offset: number,
      firstName?: string | null,
      email: string,
      phone?: string | null,
      sendWelcomeEmail?: boolean | null,
      personalEmail?: string | null,
      jobTitle?: string | null,
      birthday: string,
      startDate?: string | null,
      homeLocation?: string | null,
      team?: string | null,
      photoUrl: string,
      pronoun: Pronoun,
      aboutMe?: string | null,
      mostHappy?: string | null,
      socialMediaUrl?: string | null,
      slackUserId?: string | null,
      isFirstLogin?: boolean | null,
      isHomeModelViewed?: boolean | null,
      isProfileModelViewed?: boolean | null,
      type: UserType,
      isActive?: boolean | null,
      lastName?: string | null,
      createdAt: string,
      updatedAt: string,
      userOrganizationId: string,
      userWorkPreferenceId: string,
      userWorkStyleId: string,
      userWorkLocationId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListLearnMoreInfosQueryVariables = {
  filter?: ModelLearnMoreInfoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLearnMoreInfosQuery = {
  listLearnMoreInfos?:  {
    __typename: "ModelLearnMoreInfoConnection",
    items:  Array< {
      __typename: "LearnMoreInfo",
      id: string,
      shareInfoID: string,
      userID: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserInterestQueryVariables = {
  id: string,
};

export type GetUserInterestQuery = {
  getUserInterest?:  {
    __typename: "UserInterest",
    id: string,
    interestID: string,
    userID: string,
    interest:  {
      __typename: "Interest",
      id: string,
      name: string,
      organizationID: string,
      createdAt: string,
      updatedAt: string,
    },
    user:  {
      __typename: "User",
      id: string,
      offset: number,
      firstName?: string | null,
      email: string,
      phone?: string | null,
      sendWelcomeEmail?: boolean | null,
      personalEmail?: string | null,
      jobTitle?: string | null,
      birthday: string,
      startDate?: string | null,
      homeLocation?: string | null,
      team?: string | null,
      photoUrl: string,
      pronoun: Pronoun,
      aboutMe?: string | null,
      mostHappy?: string | null,
      socialMediaUrl?: string | null,
      slackUserId?: string | null,
      isFirstLogin?: boolean | null,
      isHomeModelViewed?: boolean | null,
      isProfileModelViewed?: boolean | null,
      type: UserType,
      isActive?: boolean | null,
      lastName?: string | null,
      createdAt: string,
      updatedAt: string,
      userOrganizationId: string,
      userWorkPreferenceId: string,
      userWorkStyleId: string,
      userWorkLocationId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUserInterestsQueryVariables = {
  filter?: ModelUserInterestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserInterestsQuery = {
  listUserInterests?:  {
    __typename: "ModelUserInterestConnection",
    items:  Array< {
      __typename: "UserInterest",
      id: string,
      interestID: string,
      userID: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnOrganizationByIdSubscriptionVariables = {
  id: string,
};

export type OnOrganizationByIdSubscription = {
  onOrganizationById?:  {
    __typename: "Organization",
    id: string,
    name: string,
    slackAccessToken?: string | null,
    mergeCompanyToken?: string | null,
    atsToken?: string | null,
    atsAccountId?: string | null,
    hrisToken?: string | null,
    hrisAccountId?: string | null,
    hrisIntegratedAt?: string | null,
    slackUserAccessToken?: string | null,
    outlookTenantId?: string | null,
    msTeamsTenantId?: string | null,
    Locations?:  {
      __typename: "ModelLocationConnection",
      nextToken?: string | null,
    } | null,
    Interests?:  {
      __typename: "ModelInterestConnection",
      nextToken?: string | null,
    } | null,
    ShareInfos?:  {
      __typename: "ModelShareInfoConnection",
      nextToken?: string | null,
    } | null,
    status: OrganizationStatus,
    activeUntil: string,
    hasSubscription?: boolean | null,
    preboarding?: boolean | null,
    Journeys?:  {
      __typename: "ModelJourneyConnection",
      nextToken?: string | null,
    } | null,
    features?: string | null,
    globalDataStores?: string | null,
    settings?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnPreboardingUserByIdSubscriptionVariables = {
  id: string,
};

export type OnPreboardingUserByIdSubscription = {
  onPreboardingUserById?:  {
    __typename: "PreboardingUser",
    id: string,
    userId: string,
    isSubmitted?: boolean | null,
    consent?: boolean | null,
    journeyId?: string | null,
    personalEmail?: string | null,
    photoUrl?: string | null,
    bankName?: string | null,
    bankBranch?: string | null,
    bankSortCode?: string | null,
    bankAccountNumber?: string | null,
    bankAccountName?: string | null,
    buildingSocietyRoll?: string | null,
    street1?: string | null,
    street2?: string | null,
    city?: string | null,
    state?: string | null,
    zip?: string | null,
    country?: string | null,
    birthday?: string | null,
    gender?: string | null,
    title?: string | null,
    nationality?: string | null,
    mobileNumber?: string | null,
    niNumber?: string | null,
    laptopPreference?: LaptopPreference | null,
    secondMonitor?: boolean | null,
    corporateCard?: boolean | null,
    corporatePhone?: PhonePreference | null,
    healthCare?: boolean | null,
    dental?: boolean | null,
    optical?: boolean | null,
    jobDeclaration?: Declaration | null,
    expatDeclaration?: Declaration | null,
    studentLoanRepayment?: boolean | null,
    studentLoanType?: StudentLoanType | null,
    pensionScheme?: boolean | null,
    pensionContribution?: number | null,
    passportNumber?: string | null,
    passportIssueDate?: string | null,
    passportExpiryDate?: string | null,
    passportIssuingCountry?: string | null,
    confirmation?: boolean | null,
    pensionSchemeOpt?: string | null,
    emergencyContactName?: string | null,
    emergencyContactRelationship?: string | null,
    emergencyContactPhone?: string | null,
    emergencyContactEmailAddress?: string | null,
    emergencyContactStreet1?: string | null,
    emergencyContactStreet2?: string | null,
    emergencyContactCity?: string | null,
    emergencyContactState?: string | null,
    emergencyContactZip?: string | null,
    emergencyContactCountry?: string | null,
    PreboardingUserRelations?:  {
      __typename: "ModelPreboardingUserRelationConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUserByIdSubscriptionVariables = {
  id: string,
};

export type OnUserByIdSubscription = {
  onUserById?:  {
    __typename: "User",
    id: string,
    offset: number,
    firstName?: string | null,
    email: string,
    phone?: string | null,
    sendWelcomeEmail?: boolean | null,
    personalEmail?: string | null,
    jobTitle?: string | null,
    birthday: string,
    startDate?: string | null,
    homeLocation?: string | null,
    team?: string | null,
    photoUrl: string,
    pronoun: Pronoun,
    aboutMe?: string | null,
    mostHappy?: string | null,
    socialMediaUrl?: string | null,
    slackUserId?: string | null,
    isFirstLogin?: boolean | null,
    isHomeModelViewed?: boolean | null,
    isProfileModelViewed?: boolean | null,
    type: UserType,
    isActive?: boolean | null,
    Organization:  {
      __typename: "Organization",
      id: string,
      name: string,
      slackAccessToken?: string | null,
      mergeCompanyToken?: string | null,
      atsToken?: string | null,
      atsAccountId?: string | null,
      hrisToken?: string | null,
      hrisAccountId?: string | null,
      hrisIntegratedAt?: string | null,
      slackUserAccessToken?: string | null,
      outlookTenantId?: string | null,
      msTeamsTenantId?: string | null,
      status: OrganizationStatus,
      activeUntil: string,
      hasSubscription?: boolean | null,
      preboarding?: boolean | null,
      features?: string | null,
      globalDataStores?: string | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    Interests?:  {
      __typename: "ModelUserInterestConnection",
      nextToken?: string | null,
    } | null,
    WorkPreference:  {
      __typename: "WorkPreference",
      id: string,
      giveMeAllTheContext: number,
      messageTimingPreferene: number,
      syncxVsAsync: number,
      talkTogetherVsAlone: number,
      recognitionPrivateVsPrivate: number,
      createdAt: string,
      updatedAt: string,
    },
    WorkStyle:  {
      __typename: "WorkStyle",
      id: string,
      whatIDoAtWork?: string | null,
      myFocusTimeIs?: string | null,
      giveReceivingFeedback?: string | null,
      digestionInformation?: string | null,
      inMyElement?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    lastName?: string | null,
    ShareInfoOn?:  {
      __typename: "ModelUserShareInfoConnection",
      nextToken?: string | null,
    } | null,
    LearnMoreOn?:  {
      __typename: "ModelLearnMoreInfoConnection",
      nextToken?: string | null,
    } | null,
    WorkLocation?:  {
      __typename: "Location",
      id: string,
      name?: string | null,
      streetAddress?: string | null,
      city?: string | null,
      state?: string | null,
      zipCode?: number | null,
      country?: string | null,
      locationType?: string | null,
      organizationID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    Journeys?:  {
      __typename: "ModelJourneyConnection",
      nextToken?: string | null,
    } | null,
    Meetings?:  {
      __typename: "ModelMeetingConnection",
      nextToken?: string | null,
    } | null,
    PreboardingUsers?:  {
      __typename: "ModelPreboardingUserConnection",
      nextToken?: string | null,
    } | null,
    PreboardingUserRelations?:  {
      __typename: "ModelPreboardingUserRelationConnection",
      nextToken?: string | null,
    } | null,
    UserBadges?:  {
      __typename: "ModelUserBadgeConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    userOrganizationId: string,
    userWorkPreferenceId: string,
    userWorkStyleId: string,
    userWorkLocationId?: string | null,
  } | null,
};

export type OnCreateShareInfoSubscriptionVariables = {
  filter?: ModelSubscriptionShareInfoFilterInput | null,
};

export type OnCreateShareInfoSubscription = {
  onCreateShareInfo?:  {
    __typename: "ShareInfo",
    id: string,
    name: string,
    organizationID: string,
    shareInfoOnUsers?:  {
      __typename: "ModelUserShareInfoConnection",
      nextToken?: string | null,
    } | null,
    learnInfoOnUsers?:  {
      __typename: "ModelLearnMoreInfoConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateShareInfoSubscriptionVariables = {
  filter?: ModelSubscriptionShareInfoFilterInput | null,
};

export type OnUpdateShareInfoSubscription = {
  onUpdateShareInfo?:  {
    __typename: "ShareInfo",
    id: string,
    name: string,
    organizationID: string,
    shareInfoOnUsers?:  {
      __typename: "ModelUserShareInfoConnection",
      nextToken?: string | null,
    } | null,
    learnInfoOnUsers?:  {
      __typename: "ModelLearnMoreInfoConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteShareInfoSubscriptionVariables = {
  filter?: ModelSubscriptionShareInfoFilterInput | null,
};

export type OnDeleteShareInfoSubscription = {
  onDeleteShareInfo?:  {
    __typename: "ShareInfo",
    id: string,
    name: string,
    organizationID: string,
    shareInfoOnUsers?:  {
      __typename: "ModelUserShareInfoConnection",
      nextToken?: string | null,
    } | null,
    learnInfoOnUsers?:  {
      __typename: "ModelLearnMoreInfoConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateWorkStyleSubscriptionVariables = {
  filter?: ModelSubscriptionWorkStyleFilterInput | null,
};

export type OnCreateWorkStyleSubscription = {
  onCreateWorkStyle?:  {
    __typename: "WorkStyle",
    id: string,
    whatIDoAtWork?: string | null,
    myFocusTimeIs?: string | null,
    giveReceivingFeedback?: string | null,
    digestionInformation?: string | null,
    inMyElement?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateWorkStyleSubscriptionVariables = {
  filter?: ModelSubscriptionWorkStyleFilterInput | null,
};

export type OnUpdateWorkStyleSubscription = {
  onUpdateWorkStyle?:  {
    __typename: "WorkStyle",
    id: string,
    whatIDoAtWork?: string | null,
    myFocusTimeIs?: string | null,
    giveReceivingFeedback?: string | null,
    digestionInformation?: string | null,
    inMyElement?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteWorkStyleSubscriptionVariables = {
  filter?: ModelSubscriptionWorkStyleFilterInput | null,
};

export type OnDeleteWorkStyleSubscription = {
  onDeleteWorkStyle?:  {
    __typename: "WorkStyle",
    id: string,
    whatIDoAtWork?: string | null,
    myFocusTimeIs?: string | null,
    giveReceivingFeedback?: string | null,
    digestionInformation?: string | null,
    inMyElement?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateWorkPreferenceSubscriptionVariables = {
  filter?: ModelSubscriptionWorkPreferenceFilterInput | null,
};

export type OnCreateWorkPreferenceSubscription = {
  onCreateWorkPreference?:  {
    __typename: "WorkPreference",
    id: string,
    giveMeAllTheContext: number,
    messageTimingPreferene: number,
    syncxVsAsync: number,
    talkTogetherVsAlone: number,
    recognitionPrivateVsPrivate: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateWorkPreferenceSubscriptionVariables = {
  filter?: ModelSubscriptionWorkPreferenceFilterInput | null,
};

export type OnUpdateWorkPreferenceSubscription = {
  onUpdateWorkPreference?:  {
    __typename: "WorkPreference",
    id: string,
    giveMeAllTheContext: number,
    messageTimingPreferene: number,
    syncxVsAsync: number,
    talkTogetherVsAlone: number,
    recognitionPrivateVsPrivate: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteWorkPreferenceSubscriptionVariables = {
  filter?: ModelSubscriptionWorkPreferenceFilterInput | null,
};

export type OnDeleteWorkPreferenceSubscription = {
  onDeleteWorkPreference?:  {
    __typename: "WorkPreference",
    id: string,
    giveMeAllTheContext: number,
    messageTimingPreferene: number,
    syncxVsAsync: number,
    talkTogetherVsAlone: number,
    recognitionPrivateVsPrivate: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateInterestSubscriptionVariables = {
  filter?: ModelSubscriptionInterestFilterInput | null,
};

export type OnCreateInterestSubscription = {
  onCreateInterest?:  {
    __typename: "Interest",
    id: string,
    name: string,
    organizationID: string,
    users?:  {
      __typename: "ModelUserInterestConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateInterestSubscriptionVariables = {
  filter?: ModelSubscriptionInterestFilterInput | null,
};

export type OnUpdateInterestSubscription = {
  onUpdateInterest?:  {
    __typename: "Interest",
    id: string,
    name: string,
    organizationID: string,
    users?:  {
      __typename: "ModelUserInterestConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteInterestSubscriptionVariables = {
  filter?: ModelSubscriptionInterestFilterInput | null,
};

export type OnDeleteInterestSubscription = {
  onDeleteInterest?:  {
    __typename: "Interest",
    id: string,
    name: string,
    organizationID: string,
    users?:  {
      __typename: "ModelUserInterestConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateLocationSubscriptionVariables = {
  filter?: ModelSubscriptionLocationFilterInput | null,
};

export type OnCreateLocationSubscription = {
  onCreateLocation?:  {
    __typename: "Location",
    id: string,
    name?: string | null,
    streetAddress?: string | null,
    city?: string | null,
    state?: string | null,
    zipCode?: number | null,
    country?: string | null,
    locationType?: string | null,
    organizationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateLocationSubscriptionVariables = {
  filter?: ModelSubscriptionLocationFilterInput | null,
};

export type OnUpdateLocationSubscription = {
  onUpdateLocation?:  {
    __typename: "Location",
    id: string,
    name?: string | null,
    streetAddress?: string | null,
    city?: string | null,
    state?: string | null,
    zipCode?: number | null,
    country?: string | null,
    locationType?: string | null,
    organizationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteLocationSubscriptionVariables = {
  filter?: ModelSubscriptionLocationFilterInput | null,
};

export type OnDeleteLocationSubscription = {
  onDeleteLocation?:  {
    __typename: "Location",
    id: string,
    name?: string | null,
    streetAddress?: string | null,
    city?: string | null,
    state?: string | null,
    zipCode?: number | null,
    country?: string | null,
    locationType?: string | null,
    organizationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateOrganizationSubscriptionVariables = {
  filter?: ModelSubscriptionOrganizationFilterInput | null,
};

export type OnCreateOrganizationSubscription = {
  onCreateOrganization?:  {
    __typename: "Organization",
    id: string,
    name: string,
    slackAccessToken?: string | null,
    mergeCompanyToken?: string | null,
    atsToken?: string | null,
    atsAccountId?: string | null,
    hrisToken?: string | null,
    hrisAccountId?: string | null,
    hrisIntegratedAt?: string | null,
    slackUserAccessToken?: string | null,
    outlookTenantId?: string | null,
    msTeamsTenantId?: string | null,
    Locations?:  {
      __typename: "ModelLocationConnection",
      nextToken?: string | null,
    } | null,
    Interests?:  {
      __typename: "ModelInterestConnection",
      nextToken?: string | null,
    } | null,
    ShareInfos?:  {
      __typename: "ModelShareInfoConnection",
      nextToken?: string | null,
    } | null,
    status: OrganizationStatus,
    activeUntil: string,
    hasSubscription?: boolean | null,
    preboarding?: boolean | null,
    Journeys?:  {
      __typename: "ModelJourneyConnection",
      nextToken?: string | null,
    } | null,
    features?: string | null,
    globalDataStores?: string | null,
    settings?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateOrganizationSubscriptionVariables = {
  filter?: ModelSubscriptionOrganizationFilterInput | null,
};

export type OnUpdateOrganizationSubscription = {
  onUpdateOrganization?:  {
    __typename: "Organization",
    id: string,
    name: string,
    slackAccessToken?: string | null,
    mergeCompanyToken?: string | null,
    atsToken?: string | null,
    atsAccountId?: string | null,
    hrisToken?: string | null,
    hrisAccountId?: string | null,
    hrisIntegratedAt?: string | null,
    slackUserAccessToken?: string | null,
    outlookTenantId?: string | null,
    msTeamsTenantId?: string | null,
    Locations?:  {
      __typename: "ModelLocationConnection",
      nextToken?: string | null,
    } | null,
    Interests?:  {
      __typename: "ModelInterestConnection",
      nextToken?: string | null,
    } | null,
    ShareInfos?:  {
      __typename: "ModelShareInfoConnection",
      nextToken?: string | null,
    } | null,
    status: OrganizationStatus,
    activeUntil: string,
    hasSubscription?: boolean | null,
    preboarding?: boolean | null,
    Journeys?:  {
      __typename: "ModelJourneyConnection",
      nextToken?: string | null,
    } | null,
    features?: string | null,
    globalDataStores?: string | null,
    settings?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteOrganizationSubscriptionVariables = {
  filter?: ModelSubscriptionOrganizationFilterInput | null,
};

export type OnDeleteOrganizationSubscription = {
  onDeleteOrganization?:  {
    __typename: "Organization",
    id: string,
    name: string,
    slackAccessToken?: string | null,
    mergeCompanyToken?: string | null,
    atsToken?: string | null,
    atsAccountId?: string | null,
    hrisToken?: string | null,
    hrisAccountId?: string | null,
    hrisIntegratedAt?: string | null,
    slackUserAccessToken?: string | null,
    outlookTenantId?: string | null,
    msTeamsTenantId?: string | null,
    Locations?:  {
      __typename: "ModelLocationConnection",
      nextToken?: string | null,
    } | null,
    Interests?:  {
      __typename: "ModelInterestConnection",
      nextToken?: string | null,
    } | null,
    ShareInfos?:  {
      __typename: "ModelShareInfoConnection",
      nextToken?: string | null,
    } | null,
    status: OrganizationStatus,
    activeUntil: string,
    hasSubscription?: boolean | null,
    preboarding?: boolean | null,
    Journeys?:  {
      __typename: "ModelJourneyConnection",
      nextToken?: string | null,
    } | null,
    features?: string | null,
    globalDataStores?: string | null,
    settings?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    offset: number,
    firstName?: string | null,
    email: string,
    phone?: string | null,
    sendWelcomeEmail?: boolean | null,
    personalEmail?: string | null,
    jobTitle?: string | null,
    birthday: string,
    startDate?: string | null,
    homeLocation?: string | null,
    team?: string | null,
    photoUrl: string,
    pronoun: Pronoun,
    aboutMe?: string | null,
    mostHappy?: string | null,
    socialMediaUrl?: string | null,
    slackUserId?: string | null,
    isFirstLogin?: boolean | null,
    isHomeModelViewed?: boolean | null,
    isProfileModelViewed?: boolean | null,
    type: UserType,
    isActive?: boolean | null,
    Organization:  {
      __typename: "Organization",
      id: string,
      name: string,
      slackAccessToken?: string | null,
      mergeCompanyToken?: string | null,
      atsToken?: string | null,
      atsAccountId?: string | null,
      hrisToken?: string | null,
      hrisAccountId?: string | null,
      hrisIntegratedAt?: string | null,
      slackUserAccessToken?: string | null,
      outlookTenantId?: string | null,
      msTeamsTenantId?: string | null,
      status: OrganizationStatus,
      activeUntil: string,
      hasSubscription?: boolean | null,
      preboarding?: boolean | null,
      features?: string | null,
      globalDataStores?: string | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    Interests?:  {
      __typename: "ModelUserInterestConnection",
      nextToken?: string | null,
    } | null,
    WorkPreference:  {
      __typename: "WorkPreference",
      id: string,
      giveMeAllTheContext: number,
      messageTimingPreferene: number,
      syncxVsAsync: number,
      talkTogetherVsAlone: number,
      recognitionPrivateVsPrivate: number,
      createdAt: string,
      updatedAt: string,
    },
    WorkStyle:  {
      __typename: "WorkStyle",
      id: string,
      whatIDoAtWork?: string | null,
      myFocusTimeIs?: string | null,
      giveReceivingFeedback?: string | null,
      digestionInformation?: string | null,
      inMyElement?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    lastName?: string | null,
    ShareInfoOn?:  {
      __typename: "ModelUserShareInfoConnection",
      nextToken?: string | null,
    } | null,
    LearnMoreOn?:  {
      __typename: "ModelLearnMoreInfoConnection",
      nextToken?: string | null,
    } | null,
    WorkLocation?:  {
      __typename: "Location",
      id: string,
      name?: string | null,
      streetAddress?: string | null,
      city?: string | null,
      state?: string | null,
      zipCode?: number | null,
      country?: string | null,
      locationType?: string | null,
      organizationID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    Journeys?:  {
      __typename: "ModelJourneyConnection",
      nextToken?: string | null,
    } | null,
    Meetings?:  {
      __typename: "ModelMeetingConnection",
      nextToken?: string | null,
    } | null,
    PreboardingUsers?:  {
      __typename: "ModelPreboardingUserConnection",
      nextToken?: string | null,
    } | null,
    PreboardingUserRelations?:  {
      __typename: "ModelPreboardingUserRelationConnection",
      nextToken?: string | null,
    } | null,
    UserBadges?:  {
      __typename: "ModelUserBadgeConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    userOrganizationId: string,
    userWorkPreferenceId: string,
    userWorkStyleId: string,
    userWorkLocationId?: string | null,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    offset: number,
    firstName?: string | null,
    email: string,
    phone?: string | null,
    sendWelcomeEmail?: boolean | null,
    personalEmail?: string | null,
    jobTitle?: string | null,
    birthday: string,
    startDate?: string | null,
    homeLocation?: string | null,
    team?: string | null,
    photoUrl: string,
    pronoun: Pronoun,
    aboutMe?: string | null,
    mostHappy?: string | null,
    socialMediaUrl?: string | null,
    slackUserId?: string | null,
    isFirstLogin?: boolean | null,
    isHomeModelViewed?: boolean | null,
    isProfileModelViewed?: boolean | null,
    type: UserType,
    isActive?: boolean | null,
    Organization:  {
      __typename: "Organization",
      id: string,
      name: string,
      slackAccessToken?: string | null,
      mergeCompanyToken?: string | null,
      atsToken?: string | null,
      atsAccountId?: string | null,
      hrisToken?: string | null,
      hrisAccountId?: string | null,
      hrisIntegratedAt?: string | null,
      slackUserAccessToken?: string | null,
      outlookTenantId?: string | null,
      msTeamsTenantId?: string | null,
      status: OrganizationStatus,
      activeUntil: string,
      hasSubscription?: boolean | null,
      preboarding?: boolean | null,
      features?: string | null,
      globalDataStores?: string | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    Interests?:  {
      __typename: "ModelUserInterestConnection",
      nextToken?: string | null,
    } | null,
    WorkPreference:  {
      __typename: "WorkPreference",
      id: string,
      giveMeAllTheContext: number,
      messageTimingPreferene: number,
      syncxVsAsync: number,
      talkTogetherVsAlone: number,
      recognitionPrivateVsPrivate: number,
      createdAt: string,
      updatedAt: string,
    },
    WorkStyle:  {
      __typename: "WorkStyle",
      id: string,
      whatIDoAtWork?: string | null,
      myFocusTimeIs?: string | null,
      giveReceivingFeedback?: string | null,
      digestionInformation?: string | null,
      inMyElement?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    lastName?: string | null,
    ShareInfoOn?:  {
      __typename: "ModelUserShareInfoConnection",
      nextToken?: string | null,
    } | null,
    LearnMoreOn?:  {
      __typename: "ModelLearnMoreInfoConnection",
      nextToken?: string | null,
    } | null,
    WorkLocation?:  {
      __typename: "Location",
      id: string,
      name?: string | null,
      streetAddress?: string | null,
      city?: string | null,
      state?: string | null,
      zipCode?: number | null,
      country?: string | null,
      locationType?: string | null,
      organizationID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    Journeys?:  {
      __typename: "ModelJourneyConnection",
      nextToken?: string | null,
    } | null,
    Meetings?:  {
      __typename: "ModelMeetingConnection",
      nextToken?: string | null,
    } | null,
    PreboardingUsers?:  {
      __typename: "ModelPreboardingUserConnection",
      nextToken?: string | null,
    } | null,
    PreboardingUserRelations?:  {
      __typename: "ModelPreboardingUserRelationConnection",
      nextToken?: string | null,
    } | null,
    UserBadges?:  {
      __typename: "ModelUserBadgeConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    userOrganizationId: string,
    userWorkPreferenceId: string,
    userWorkStyleId: string,
    userWorkLocationId?: string | null,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    offset: number,
    firstName?: string | null,
    email: string,
    phone?: string | null,
    sendWelcomeEmail?: boolean | null,
    personalEmail?: string | null,
    jobTitle?: string | null,
    birthday: string,
    startDate?: string | null,
    homeLocation?: string | null,
    team?: string | null,
    photoUrl: string,
    pronoun: Pronoun,
    aboutMe?: string | null,
    mostHappy?: string | null,
    socialMediaUrl?: string | null,
    slackUserId?: string | null,
    isFirstLogin?: boolean | null,
    isHomeModelViewed?: boolean | null,
    isProfileModelViewed?: boolean | null,
    type: UserType,
    isActive?: boolean | null,
    Organization:  {
      __typename: "Organization",
      id: string,
      name: string,
      slackAccessToken?: string | null,
      mergeCompanyToken?: string | null,
      atsToken?: string | null,
      atsAccountId?: string | null,
      hrisToken?: string | null,
      hrisAccountId?: string | null,
      hrisIntegratedAt?: string | null,
      slackUserAccessToken?: string | null,
      outlookTenantId?: string | null,
      msTeamsTenantId?: string | null,
      status: OrganizationStatus,
      activeUntil: string,
      hasSubscription?: boolean | null,
      preboarding?: boolean | null,
      features?: string | null,
      globalDataStores?: string | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    Interests?:  {
      __typename: "ModelUserInterestConnection",
      nextToken?: string | null,
    } | null,
    WorkPreference:  {
      __typename: "WorkPreference",
      id: string,
      giveMeAllTheContext: number,
      messageTimingPreferene: number,
      syncxVsAsync: number,
      talkTogetherVsAlone: number,
      recognitionPrivateVsPrivate: number,
      createdAt: string,
      updatedAt: string,
    },
    WorkStyle:  {
      __typename: "WorkStyle",
      id: string,
      whatIDoAtWork?: string | null,
      myFocusTimeIs?: string | null,
      giveReceivingFeedback?: string | null,
      digestionInformation?: string | null,
      inMyElement?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    lastName?: string | null,
    ShareInfoOn?:  {
      __typename: "ModelUserShareInfoConnection",
      nextToken?: string | null,
    } | null,
    LearnMoreOn?:  {
      __typename: "ModelLearnMoreInfoConnection",
      nextToken?: string | null,
    } | null,
    WorkLocation?:  {
      __typename: "Location",
      id: string,
      name?: string | null,
      streetAddress?: string | null,
      city?: string | null,
      state?: string | null,
      zipCode?: number | null,
      country?: string | null,
      locationType?: string | null,
      organizationID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    Journeys?:  {
      __typename: "ModelJourneyConnection",
      nextToken?: string | null,
    } | null,
    Meetings?:  {
      __typename: "ModelMeetingConnection",
      nextToken?: string | null,
    } | null,
    PreboardingUsers?:  {
      __typename: "ModelPreboardingUserConnection",
      nextToken?: string | null,
    } | null,
    PreboardingUserRelations?:  {
      __typename: "ModelPreboardingUserRelationConnection",
      nextToken?: string | null,
    } | null,
    UserBadges?:  {
      __typename: "ModelUserBadgeConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    userOrganizationId: string,
    userWorkPreferenceId: string,
    userWorkStyleId: string,
    userWorkLocationId?: string | null,
  } | null,
};

export type OnCreateJourneySubscriptionVariables = {
  filter?: ModelSubscriptionJourneyFilterInput | null,
};

export type OnCreateJourneySubscription = {
  onCreateJourney?:  {
    __typename: "Journey",
    id: string,
    name: string,
    createdByUserID: string,
    assignedUserID?: string | null,
    mode: PlaybookMode,
    startDate?: string | null,
    journeyProgress: number,
    parentJourneyID?: string | null,
    collaborator?: Array< string | null > | null,
    status: JourneyStatus,
    badgeID?: string | null,
    customBadgeName?: string | null,
    lockChapters?: boolean | null,
    type: BuilderType,
    publicLink?: string | null,
    eventStartedAt?: string | null,
    userStartedAt?: string | null,
    userCompletedAt?: string | null,
    isArchived?: boolean | null,
    preboarding?: boolean | null,
    organizationID: string,
    Milestone?:  {
      __typename: "ModelMilestoneConnection",
      nextToken?: string | null,
    } | null,
    Meetings?:  {
      __typename: "ModelMeetingConnection",
      nextToken?: string | null,
    } | null,
    JourneyCollaborator?:  {
      __typename: "ModelJourneyCollaboratorConnection",
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelEventConnection",
      nextToken?: string | null,
    } | null,
    image?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateJourneySubscriptionVariables = {
  filter?: ModelSubscriptionJourneyFilterInput | null,
};

export type OnUpdateJourneySubscription = {
  onUpdateJourney?:  {
    __typename: "Journey",
    id: string,
    name: string,
    createdByUserID: string,
    assignedUserID?: string | null,
    mode: PlaybookMode,
    startDate?: string | null,
    journeyProgress: number,
    parentJourneyID?: string | null,
    collaborator?: Array< string | null > | null,
    status: JourneyStatus,
    badgeID?: string | null,
    customBadgeName?: string | null,
    lockChapters?: boolean | null,
    type: BuilderType,
    publicLink?: string | null,
    eventStartedAt?: string | null,
    userStartedAt?: string | null,
    userCompletedAt?: string | null,
    isArchived?: boolean | null,
    preboarding?: boolean | null,
    organizationID: string,
    Milestone?:  {
      __typename: "ModelMilestoneConnection",
      nextToken?: string | null,
    } | null,
    Meetings?:  {
      __typename: "ModelMeetingConnection",
      nextToken?: string | null,
    } | null,
    JourneyCollaborator?:  {
      __typename: "ModelJourneyCollaboratorConnection",
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelEventConnection",
      nextToken?: string | null,
    } | null,
    image?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteJourneySubscriptionVariables = {
  filter?: ModelSubscriptionJourneyFilterInput | null,
};

export type OnDeleteJourneySubscription = {
  onDeleteJourney?:  {
    __typename: "Journey",
    id: string,
    name: string,
    createdByUserID: string,
    assignedUserID?: string | null,
    mode: PlaybookMode,
    startDate?: string | null,
    journeyProgress: number,
    parentJourneyID?: string | null,
    collaborator?: Array< string | null > | null,
    status: JourneyStatus,
    badgeID?: string | null,
    customBadgeName?: string | null,
    lockChapters?: boolean | null,
    type: BuilderType,
    publicLink?: string | null,
    eventStartedAt?: string | null,
    userStartedAt?: string | null,
    userCompletedAt?: string | null,
    isArchived?: boolean | null,
    preboarding?: boolean | null,
    organizationID: string,
    Milestone?:  {
      __typename: "ModelMilestoneConnection",
      nextToken?: string | null,
    } | null,
    Meetings?:  {
      __typename: "ModelMeetingConnection",
      nextToken?: string | null,
    } | null,
    JourneyCollaborator?:  {
      __typename: "ModelJourneyCollaboratorConnection",
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelEventConnection",
      nextToken?: string | null,
    } | null,
    image?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateBadgeSubscriptionVariables = {
  filter?: ModelSubscriptionBadgeFilterInput | null,
};

export type OnCreateBadgeSubscription = {
  onCreateBadge?:  {
    __typename: "Badge",
    id: string,
    name?: string | null,
    iconUrl: string,
    isArchived?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateBadgeSubscriptionVariables = {
  filter?: ModelSubscriptionBadgeFilterInput | null,
};

export type OnUpdateBadgeSubscription = {
  onUpdateBadge?:  {
    __typename: "Badge",
    id: string,
    name?: string | null,
    iconUrl: string,
    isArchived?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteBadgeSubscriptionVariables = {
  filter?: ModelSubscriptionBadgeFilterInput | null,
};

export type OnDeleteBadgeSubscription = {
  onDeleteBadge?:  {
    __typename: "Badge",
    id: string,
    name?: string | null,
    iconUrl: string,
    isArchived?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUserBadgeSubscriptionVariables = {
  filter?: ModelSubscriptionUserBadgeFilterInput | null,
};

export type OnCreateUserBadgeSubscription = {
  onCreateUserBadge?:  {
    __typename: "UserBadge",
    id: string,
    playbookId: string,
    userID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserBadgeSubscriptionVariables = {
  filter?: ModelSubscriptionUserBadgeFilterInput | null,
};

export type OnUpdateUserBadgeSubscription = {
  onUpdateUserBadge?:  {
    __typename: "UserBadge",
    id: string,
    playbookId: string,
    userID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserBadgeSubscriptionVariables = {
  filter?: ModelSubscriptionUserBadgeFilterInput | null,
};

export type OnDeleteUserBadgeSubscription = {
  onDeleteUserBadge?:  {
    __typename: "UserBadge",
    id: string,
    playbookId: string,
    userID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateJourneyCollaboratorSubscriptionVariables = {
  filter?: ModelSubscriptionJourneyCollaboratorFilterInput | null,
};

export type OnCreateJourneyCollaboratorSubscription = {
  onCreateJourneyCollaborator?:  {
    __typename: "JourneyCollaborator",
    id: string,
    assigneeRole: AssigneeRole,
    journeyAccess?: JourneyAccess | null,
    assignedUserID?: string | null,
    actionID?: Array< string | null > | null,
    meetingID?: Array< string | null > | null,
    updatedInChild?: boolean | null,
    journeyID?: string | null,
    kinfolkTemplateID?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateJourneyCollaboratorSubscriptionVariables = {
  filter?: ModelSubscriptionJourneyCollaboratorFilterInput | null,
};

export type OnUpdateJourneyCollaboratorSubscription = {
  onUpdateJourneyCollaborator?:  {
    __typename: "JourneyCollaborator",
    id: string,
    assigneeRole: AssigneeRole,
    journeyAccess?: JourneyAccess | null,
    assignedUserID?: string | null,
    actionID?: Array< string | null > | null,
    meetingID?: Array< string | null > | null,
    updatedInChild?: boolean | null,
    journeyID?: string | null,
    kinfolkTemplateID?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteJourneyCollaboratorSubscriptionVariables = {
  filter?: ModelSubscriptionJourneyCollaboratorFilterInput | null,
};

export type OnDeleteJourneyCollaboratorSubscription = {
  onDeleteJourneyCollaborator?:  {
    __typename: "JourneyCollaborator",
    id: string,
    assigneeRole: AssigneeRole,
    journeyAccess?: JourneyAccess | null,
    assignedUserID?: string | null,
    actionID?: Array< string | null > | null,
    meetingID?: Array< string | null > | null,
    updatedInChild?: boolean | null,
    journeyID?: string | null,
    kinfolkTemplateID?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateKinfolkTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionKinfolkTemplateFilterInput | null,
};

export type OnCreateKinfolkTemplateSubscription = {
  onCreateKinfolkTemplate?:  {
    __typename: "KinfolkTemplate",
    id: string,
    name: string,
    description: string,
    image: string,
    isArchived?: boolean | null,
    status: TemplateStatus,
    orderNo?: number | null,
    type: BuilderType,
    mode: PlaybookMode,
    isPublic?: boolean | null,
    Milestone?:  {
      __typename: "ModelMilestoneConnection",
      nextToken?: string | null,
    } | null,
    Meetings?:  {
      __typename: "ModelMeetingConnection",
      nextToken?: string | null,
    } | null,
    JourneyCollaborator?:  {
      __typename: "ModelJourneyCollaboratorConnection",
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelEventConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateKinfolkTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionKinfolkTemplateFilterInput | null,
};

export type OnUpdateKinfolkTemplateSubscription = {
  onUpdateKinfolkTemplate?:  {
    __typename: "KinfolkTemplate",
    id: string,
    name: string,
    description: string,
    image: string,
    isArchived?: boolean | null,
    status: TemplateStatus,
    orderNo?: number | null,
    type: BuilderType,
    mode: PlaybookMode,
    isPublic?: boolean | null,
    Milestone?:  {
      __typename: "ModelMilestoneConnection",
      nextToken?: string | null,
    } | null,
    Meetings?:  {
      __typename: "ModelMeetingConnection",
      nextToken?: string | null,
    } | null,
    JourneyCollaborator?:  {
      __typename: "ModelJourneyCollaboratorConnection",
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelEventConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteKinfolkTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionKinfolkTemplateFilterInput | null,
};

export type OnDeleteKinfolkTemplateSubscription = {
  onDeleteKinfolkTemplate?:  {
    __typename: "KinfolkTemplate",
    id: string,
    name: string,
    description: string,
    image: string,
    isArchived?: boolean | null,
    status: TemplateStatus,
    orderNo?: number | null,
    type: BuilderType,
    mode: PlaybookMode,
    isPublic?: boolean | null,
    Milestone?:  {
      __typename: "ModelMilestoneConnection",
      nextToken?: string | null,
    } | null,
    Meetings?:  {
      __typename: "ModelMeetingConnection",
      nextToken?: string | null,
    } | null,
    JourneyCollaborator?:  {
      __typename: "ModelJourneyCollaboratorConnection",
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelEventConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateMilestoneSubscriptionVariables = {
  filter?: ModelSubscriptionMilestoneFilterInput | null,
};

export type OnCreateMilestoneSubscription = {
  onCreateMilestone?:  {
    __typename: "Milestone",
    id: string,
    name: string,
    kinfolkTemplateID?: string | null,
    isCompleted?: boolean | null,
    isArchived?: boolean | null,
    journeyID?: string | null,
    orderNo: number,
    parentMilestoneID?: string | null,
    EmbeddedContentBlocks?:  {
      __typename: "ModelEmbeddedContentBlockConnection",
      nextToken?: string | null,
    } | null,
    FreeTextQuestionBlocks?:  {
      __typename: "ModelFreeTextQuestionBlockConnection",
      nextToken?: string | null,
    } | null,
    NoteBlocks?:  {
      __typename: "ModelNoteBlockConnection",
      nextToken?: string | null,
    } | null,
    TaskBlocks?:  {
      __typename: "ModelTaskBlockConnection",
      nextToken?: string | null,
    } | null,
    StandardContentBlocks?:  {
      __typename: "ModelStandardContentBlockConnection",
      nextToken?: string | null,
    } | null,
    MultipleChoiceQuestionBlocks?:  {
      __typename: "ModelMultipleChoiceQuestionBlockConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateMilestoneSubscriptionVariables = {
  filter?: ModelSubscriptionMilestoneFilterInput | null,
};

export type OnUpdateMilestoneSubscription = {
  onUpdateMilestone?:  {
    __typename: "Milestone",
    id: string,
    name: string,
    kinfolkTemplateID?: string | null,
    isCompleted?: boolean | null,
    isArchived?: boolean | null,
    journeyID?: string | null,
    orderNo: number,
    parentMilestoneID?: string | null,
    EmbeddedContentBlocks?:  {
      __typename: "ModelEmbeddedContentBlockConnection",
      nextToken?: string | null,
    } | null,
    FreeTextQuestionBlocks?:  {
      __typename: "ModelFreeTextQuestionBlockConnection",
      nextToken?: string | null,
    } | null,
    NoteBlocks?:  {
      __typename: "ModelNoteBlockConnection",
      nextToken?: string | null,
    } | null,
    TaskBlocks?:  {
      __typename: "ModelTaskBlockConnection",
      nextToken?: string | null,
    } | null,
    StandardContentBlocks?:  {
      __typename: "ModelStandardContentBlockConnection",
      nextToken?: string | null,
    } | null,
    MultipleChoiceQuestionBlocks?:  {
      __typename: "ModelMultipleChoiceQuestionBlockConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteMilestoneSubscriptionVariables = {
  filter?: ModelSubscriptionMilestoneFilterInput | null,
};

export type OnDeleteMilestoneSubscription = {
  onDeleteMilestone?:  {
    __typename: "Milestone",
    id: string,
    name: string,
    kinfolkTemplateID?: string | null,
    isCompleted?: boolean | null,
    isArchived?: boolean | null,
    journeyID?: string | null,
    orderNo: number,
    parentMilestoneID?: string | null,
    EmbeddedContentBlocks?:  {
      __typename: "ModelEmbeddedContentBlockConnection",
      nextToken?: string | null,
    } | null,
    FreeTextQuestionBlocks?:  {
      __typename: "ModelFreeTextQuestionBlockConnection",
      nextToken?: string | null,
    } | null,
    NoteBlocks?:  {
      __typename: "ModelNoteBlockConnection",
      nextToken?: string | null,
    } | null,
    TaskBlocks?:  {
      __typename: "ModelTaskBlockConnection",
      nextToken?: string | null,
    } | null,
    StandardContentBlocks?:  {
      __typename: "ModelStandardContentBlockConnection",
      nextToken?: string | null,
    } | null,
    MultipleChoiceQuestionBlocks?:  {
      __typename: "ModelMultipleChoiceQuestionBlockConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateEmbeddedContentBlockSubscriptionVariables = {
  filter?: ModelSubscriptionEmbeddedContentBlockFilterInput | null,
};

export type OnCreateEmbeddedContentBlockSubscription = {
  onCreateEmbeddedContentBlock?:  {
    __typename: "EmbeddedContentBlock",
    id: string,
    type: BlockType,
    milestoneID: string,
    title: string,
    description?: string | null,
    resourceLink: string,
    isFormLinked?: boolean | null,
    orderNo: number,
    parentEmbeddedContentBlockID?: string | null,
    isCompleted?: boolean | null,
    isArchived?: boolean | null,
    updatedInChild?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateEmbeddedContentBlockSubscriptionVariables = {
  filter?: ModelSubscriptionEmbeddedContentBlockFilterInput | null,
};

export type OnUpdateEmbeddedContentBlockSubscription = {
  onUpdateEmbeddedContentBlock?:  {
    __typename: "EmbeddedContentBlock",
    id: string,
    type: BlockType,
    milestoneID: string,
    title: string,
    description?: string | null,
    resourceLink: string,
    isFormLinked?: boolean | null,
    orderNo: number,
    parentEmbeddedContentBlockID?: string | null,
    isCompleted?: boolean | null,
    isArchived?: boolean | null,
    updatedInChild?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteEmbeddedContentBlockSubscriptionVariables = {
  filter?: ModelSubscriptionEmbeddedContentBlockFilterInput | null,
};

export type OnDeleteEmbeddedContentBlockSubscription = {
  onDeleteEmbeddedContentBlock?:  {
    __typename: "EmbeddedContentBlock",
    id: string,
    type: BlockType,
    milestoneID: string,
    title: string,
    description?: string | null,
    resourceLink: string,
    isFormLinked?: boolean | null,
    orderNo: number,
    parentEmbeddedContentBlockID?: string | null,
    isCompleted?: boolean | null,
    isArchived?: boolean | null,
    updatedInChild?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateFreeTextQuestionBlockSubscriptionVariables = {
  filter?: ModelSubscriptionFreeTextQuestionBlockFilterInput | null,
};

export type OnCreateFreeTextQuestionBlockSubscription = {
  onCreateFreeTextQuestionBlock?:  {
    __typename: "FreeTextQuestionBlock",
    id: string,
    type: BlockType,
    milestoneID: string,
    title: string,
    description?: string | null,
    text?: string | null,
    orderNo: number,
    parentFreeTextQuestionBlockID?: string | null,
    isCompleted?: boolean | null,
    isArchived?: boolean | null,
    updatedInChild?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateFreeTextQuestionBlockSubscriptionVariables = {
  filter?: ModelSubscriptionFreeTextQuestionBlockFilterInput | null,
};

export type OnUpdateFreeTextQuestionBlockSubscription = {
  onUpdateFreeTextQuestionBlock?:  {
    __typename: "FreeTextQuestionBlock",
    id: string,
    type: BlockType,
    milestoneID: string,
    title: string,
    description?: string | null,
    text?: string | null,
    orderNo: number,
    parentFreeTextQuestionBlockID?: string | null,
    isCompleted?: boolean | null,
    isArchived?: boolean | null,
    updatedInChild?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteFreeTextQuestionBlockSubscriptionVariables = {
  filter?: ModelSubscriptionFreeTextQuestionBlockFilterInput | null,
};

export type OnDeleteFreeTextQuestionBlockSubscription = {
  onDeleteFreeTextQuestionBlock?:  {
    __typename: "FreeTextQuestionBlock",
    id: string,
    type: BlockType,
    milestoneID: string,
    title: string,
    description?: string | null,
    text?: string | null,
    orderNo: number,
    parentFreeTextQuestionBlockID?: string | null,
    isCompleted?: boolean | null,
    isArchived?: boolean | null,
    updatedInChild?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateNoteBlockSubscriptionVariables = {
  filter?: ModelSubscriptionNoteBlockFilterInput | null,
};

export type OnCreateNoteBlockSubscription = {
  onCreateNoteBlock?:  {
    __typename: "NoteBlock",
    id: string,
    type: BlockType,
    milestoneID: string,
    title: string,
    description?: string | null,
    text?: string | null,
    orderNo: number,
    parentNoteBlockID?: string | null,
    isCompleted?: boolean | null,
    isPrivate?: boolean | null,
    isArchived?: boolean | null,
    updatedInChild?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateNoteBlockSubscriptionVariables = {
  filter?: ModelSubscriptionNoteBlockFilterInput | null,
};

export type OnUpdateNoteBlockSubscription = {
  onUpdateNoteBlock?:  {
    __typename: "NoteBlock",
    id: string,
    type: BlockType,
    milestoneID: string,
    title: string,
    description?: string | null,
    text?: string | null,
    orderNo: number,
    parentNoteBlockID?: string | null,
    isCompleted?: boolean | null,
    isPrivate?: boolean | null,
    isArchived?: boolean | null,
    updatedInChild?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteNoteBlockSubscriptionVariables = {
  filter?: ModelSubscriptionNoteBlockFilterInput | null,
};

export type OnDeleteNoteBlockSubscription = {
  onDeleteNoteBlock?:  {
    __typename: "NoteBlock",
    id: string,
    type: BlockType,
    milestoneID: string,
    title: string,
    description?: string | null,
    text?: string | null,
    orderNo: number,
    parentNoteBlockID?: string | null,
    isCompleted?: boolean | null,
    isPrivate?: boolean | null,
    isArchived?: boolean | null,
    updatedInChild?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateTaskBlockSubscriptionVariables = {
  filter?: ModelSubscriptionTaskBlockFilterInput | null,
};

export type OnCreateTaskBlockSubscription = {
  onCreateTaskBlock?:  {
    __typename: "TaskBlock",
    id: string,
    type: BlockType,
    milestoneID: string,
    title: string,
    description?: string | null,
    status?: boolean | null,
    orderNo: number,
    parentTaskBlockID?: string | null,
    isCompleted?: boolean | null,
    isArchived?: boolean | null,
    updatedInChild?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTaskBlockSubscriptionVariables = {
  filter?: ModelSubscriptionTaskBlockFilterInput | null,
};

export type OnUpdateTaskBlockSubscription = {
  onUpdateTaskBlock?:  {
    __typename: "TaskBlock",
    id: string,
    type: BlockType,
    milestoneID: string,
    title: string,
    description?: string | null,
    status?: boolean | null,
    orderNo: number,
    parentTaskBlockID?: string | null,
    isCompleted?: boolean | null,
    isArchived?: boolean | null,
    updatedInChild?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteTaskBlockSubscriptionVariables = {
  filter?: ModelSubscriptionTaskBlockFilterInput | null,
};

export type OnDeleteTaskBlockSubscription = {
  onDeleteTaskBlock?:  {
    __typename: "TaskBlock",
    id: string,
    type: BlockType,
    milestoneID: string,
    title: string,
    description?: string | null,
    status?: boolean | null,
    orderNo: number,
    parentTaskBlockID?: string | null,
    isCompleted?: boolean | null,
    isArchived?: boolean | null,
    updatedInChild?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateStandardContentBlockSubscriptionVariables = {
  filter?: ModelSubscriptionStandardContentBlockFilterInput | null,
};

export type OnCreateStandardContentBlockSubscription = {
  onCreateStandardContentBlock?:  {
    __typename: "StandardContentBlock",
    id: string,
    type: BlockType,
    milestoneID: string,
    title: string,
    description?: string | null,
    orderNo: number,
    parentStandardContentBlockID?: string | null,
    isCompleted?: boolean | null,
    isArchived?: boolean | null,
    updatedInChild?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateStandardContentBlockSubscriptionVariables = {
  filter?: ModelSubscriptionStandardContentBlockFilterInput | null,
};

export type OnUpdateStandardContentBlockSubscription = {
  onUpdateStandardContentBlock?:  {
    __typename: "StandardContentBlock",
    id: string,
    type: BlockType,
    milestoneID: string,
    title: string,
    description?: string | null,
    orderNo: number,
    parentStandardContentBlockID?: string | null,
    isCompleted?: boolean | null,
    isArchived?: boolean | null,
    updatedInChild?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteStandardContentBlockSubscriptionVariables = {
  filter?: ModelSubscriptionStandardContentBlockFilterInput | null,
};

export type OnDeleteStandardContentBlockSubscription = {
  onDeleteStandardContentBlock?:  {
    __typename: "StandardContentBlock",
    id: string,
    type: BlockType,
    milestoneID: string,
    title: string,
    description?: string | null,
    orderNo: number,
    parentStandardContentBlockID?: string | null,
    isCompleted?: boolean | null,
    isArchived?: boolean | null,
    updatedInChild?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateMultipleChoiceQuestionBlockSubscriptionVariables = {
  filter?: ModelSubscriptionMultipleChoiceQuestionBlockFilterInput | null,
};

export type OnCreateMultipleChoiceQuestionBlockSubscription = {
  onCreateMultipleChoiceQuestionBlock?:  {
    __typename: "MultipleChoiceQuestionBlock",
    id: string,
    type: BlockType,
    milestoneID: string,
    title: string,
    description?: string | null,
    option?: Array< string > | null,
    answer?: Array< string > | null,
    orderNo: number,
    parentMultipleChoiceQuestionBlockID?: string | null,
    isCompleted?: boolean | null,
    isRequired?: boolean | null,
    isArchived?: boolean | null,
    updatedInChild?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateMultipleChoiceQuestionBlockSubscriptionVariables = {
  filter?: ModelSubscriptionMultipleChoiceQuestionBlockFilterInput | null,
};

export type OnUpdateMultipleChoiceQuestionBlockSubscription = {
  onUpdateMultipleChoiceQuestionBlock?:  {
    __typename: "MultipleChoiceQuestionBlock",
    id: string,
    type: BlockType,
    milestoneID: string,
    title: string,
    description?: string | null,
    option?: Array< string > | null,
    answer?: Array< string > | null,
    orderNo: number,
    parentMultipleChoiceQuestionBlockID?: string | null,
    isCompleted?: boolean | null,
    isRequired?: boolean | null,
    isArchived?: boolean | null,
    updatedInChild?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteMultipleChoiceQuestionBlockSubscriptionVariables = {
  filter?: ModelSubscriptionMultipleChoiceQuestionBlockFilterInput | null,
};

export type OnDeleteMultipleChoiceQuestionBlockSubscription = {
  onDeleteMultipleChoiceQuestionBlock?:  {
    __typename: "MultipleChoiceQuestionBlock",
    id: string,
    type: BlockType,
    milestoneID: string,
    title: string,
    description?: string | null,
    option?: Array< string > | null,
    answer?: Array< string > | null,
    orderNo: number,
    parentMultipleChoiceQuestionBlockID?: string | null,
    isCompleted?: boolean | null,
    isRequired?: boolean | null,
    isArchived?: boolean | null,
    updatedInChild?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateEventSubscriptionVariables = {
  filter?: ModelSubscriptionEventFilterInput | null,
};

export type OnCreateEventSubscription = {
  onCreateEvent?:  {
    __typename: "Event",
    id: string,
    userId?: string | null,
    formId?: string | null,
    formInstanceId?: string | null,
    daysFrom: number,
    daysFromDueDate?: number | null,
    type: string,
    role?: AssigneeRole | null,
    emailTo?: string | null,
    title?: string | null,
    message?: string | null,
    sendTime?: string | null,
    sendTimeDate?: string | null,
    dueDate?: string | null,
    status: EventStatus,
    channelId?: string | null,
    parentId?: string | null,
    channel: Channel,
    cc?: Array< string | null > | null,
    archived?: boolean | null,
    updatedInChild?: boolean | null,
    journeyID?: string | null,
    kinfolkTemplateID?: string | null,
    reminders?:  {
      __typename: "ModelEventReminderConnection",
      nextToken?: string | null,
    } | null,
    meta?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateEventSubscriptionVariables = {
  filter?: ModelSubscriptionEventFilterInput | null,
};

export type OnUpdateEventSubscription = {
  onUpdateEvent?:  {
    __typename: "Event",
    id: string,
    userId?: string | null,
    formId?: string | null,
    formInstanceId?: string | null,
    daysFrom: number,
    daysFromDueDate?: number | null,
    type: string,
    role?: AssigneeRole | null,
    emailTo?: string | null,
    title?: string | null,
    message?: string | null,
    sendTime?: string | null,
    sendTimeDate?: string | null,
    dueDate?: string | null,
    status: EventStatus,
    channelId?: string | null,
    parentId?: string | null,
    channel: Channel,
    cc?: Array< string | null > | null,
    archived?: boolean | null,
    updatedInChild?: boolean | null,
    journeyID?: string | null,
    kinfolkTemplateID?: string | null,
    reminders?:  {
      __typename: "ModelEventReminderConnection",
      nextToken?: string | null,
    } | null,
    meta?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteEventSubscriptionVariables = {
  filter?: ModelSubscriptionEventFilterInput | null,
};

export type OnDeleteEventSubscription = {
  onDeleteEvent?:  {
    __typename: "Event",
    id: string,
    userId?: string | null,
    formId?: string | null,
    formInstanceId?: string | null,
    daysFrom: number,
    daysFromDueDate?: number | null,
    type: string,
    role?: AssigneeRole | null,
    emailTo?: string | null,
    title?: string | null,
    message?: string | null,
    sendTime?: string | null,
    sendTimeDate?: string | null,
    dueDate?: string | null,
    status: EventStatus,
    channelId?: string | null,
    parentId?: string | null,
    channel: Channel,
    cc?: Array< string | null > | null,
    archived?: boolean | null,
    updatedInChild?: boolean | null,
    journeyID?: string | null,
    kinfolkTemplateID?: string | null,
    reminders?:  {
      __typename: "ModelEventReminderConnection",
      nextToken?: string | null,
    } | null,
    meta?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateEventReminderSubscriptionVariables = {
  filter?: ModelSubscriptionEventReminderFilterInput | null,
};

export type OnCreateEventReminderSubscription = {
  onCreateEventReminder?:  {
    __typename: "EventReminder",
    id: string,
    eventId: string,
    sendTimeDate?: string | null,
    daysFrom: number,
    status: EventStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateEventReminderSubscriptionVariables = {
  filter?: ModelSubscriptionEventReminderFilterInput | null,
};

export type OnUpdateEventReminderSubscription = {
  onUpdateEventReminder?:  {
    __typename: "EventReminder",
    id: string,
    eventId: string,
    sendTimeDate?: string | null,
    daysFrom: number,
    status: EventStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteEventReminderSubscriptionVariables = {
  filter?: ModelSubscriptionEventReminderFilterInput | null,
};

export type OnDeleteEventReminderSubscription = {
  onDeleteEventReminder?:  {
    __typename: "EventReminder",
    id: string,
    eventId: string,
    sendTimeDate?: string | null,
    daysFrom: number,
    status: EventStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateMeetingSubscriptionVariables = {
  filter?: ModelSubscriptionMeetingFilterInput | null,
};

export type OnCreateMeetingSubscription = {
  onCreateMeeting?:  {
    __typename: "Meeting",
    id: string,
    kinfolkTemplateID?: string | null,
    journeyID?: string | null,
    organizedByUserID?: string | null,
    status: MeetingStatus,
    title: string,
    description?: string | null,
    attendeesEmail?: Array< string | null > | null,
    startTime?: string | null,
    endTime?: string | null,
    parentMeetingID?: string | null,
    updatedInChild?: boolean | null,
    eventDate?: string | null,
    duration: string,
    link?: string | null,
    assigneeRole?: AssigneeRole | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateMeetingSubscriptionVariables = {
  filter?: ModelSubscriptionMeetingFilterInput | null,
};

export type OnUpdateMeetingSubscription = {
  onUpdateMeeting?:  {
    __typename: "Meeting",
    id: string,
    kinfolkTemplateID?: string | null,
    journeyID?: string | null,
    organizedByUserID?: string | null,
    status: MeetingStatus,
    title: string,
    description?: string | null,
    attendeesEmail?: Array< string | null > | null,
    startTime?: string | null,
    endTime?: string | null,
    parentMeetingID?: string | null,
    updatedInChild?: boolean | null,
    eventDate?: string | null,
    duration: string,
    link?: string | null,
    assigneeRole?: AssigneeRole | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteMeetingSubscriptionVariables = {
  filter?: ModelSubscriptionMeetingFilterInput | null,
};

export type OnDeleteMeetingSubscription = {
  onDeleteMeeting?:  {
    __typename: "Meeting",
    id: string,
    kinfolkTemplateID?: string | null,
    journeyID?: string | null,
    organizedByUserID?: string | null,
    status: MeetingStatus,
    title: string,
    description?: string | null,
    attendeesEmail?: Array< string | null > | null,
    startTime?: string | null,
    endTime?: string | null,
    parentMeetingID?: string | null,
    updatedInChild?: boolean | null,
    eventDate?: string | null,
    duration: string,
    link?: string | null,
    assigneeRole?: AssigneeRole | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateSecretKeyManagerSubscriptionVariables = {
  filter?: ModelSubscriptionSecretKeyManagerFilterInput | null,
};

export type OnCreateSecretKeyManagerSubscription = {
  onCreateSecretKeyManager?:  {
    __typename: "SecretKeyManager",
    id: string,
    organizationID: string,
    clientEmail: string,
    privateKey: string,
    adminEmail: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateSecretKeyManagerSubscriptionVariables = {
  filter?: ModelSubscriptionSecretKeyManagerFilterInput | null,
};

export type OnUpdateSecretKeyManagerSubscription = {
  onUpdateSecretKeyManager?:  {
    __typename: "SecretKeyManager",
    id: string,
    organizationID: string,
    clientEmail: string,
    privateKey: string,
    adminEmail: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteSecretKeyManagerSubscriptionVariables = {
  filter?: ModelSubscriptionSecretKeyManagerFilterInput | null,
};

export type OnDeleteSecretKeyManagerSubscription = {
  onDeleteSecretKeyManager?:  {
    __typename: "SecretKeyManager",
    id: string,
    organizationID: string,
    clientEmail: string,
    privateKey: string,
    adminEmail: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePreboardingUserSubscriptionVariables = {
  filter?: ModelSubscriptionPreboardingUserFilterInput | null,
};

export type OnCreatePreboardingUserSubscription = {
  onCreatePreboardingUser?:  {
    __typename: "PreboardingUser",
    id: string,
    userId: string,
    isSubmitted?: boolean | null,
    consent?: boolean | null,
    journeyId?: string | null,
    personalEmail?: string | null,
    photoUrl?: string | null,
    bankName?: string | null,
    bankBranch?: string | null,
    bankSortCode?: string | null,
    bankAccountNumber?: string | null,
    bankAccountName?: string | null,
    buildingSocietyRoll?: string | null,
    street1?: string | null,
    street2?: string | null,
    city?: string | null,
    state?: string | null,
    zip?: string | null,
    country?: string | null,
    birthday?: string | null,
    gender?: string | null,
    title?: string | null,
    nationality?: string | null,
    mobileNumber?: string | null,
    niNumber?: string | null,
    laptopPreference?: LaptopPreference | null,
    secondMonitor?: boolean | null,
    corporateCard?: boolean | null,
    corporatePhone?: PhonePreference | null,
    healthCare?: boolean | null,
    dental?: boolean | null,
    optical?: boolean | null,
    jobDeclaration?: Declaration | null,
    expatDeclaration?: Declaration | null,
    studentLoanRepayment?: boolean | null,
    studentLoanType?: StudentLoanType | null,
    pensionScheme?: boolean | null,
    pensionContribution?: number | null,
    passportNumber?: string | null,
    passportIssueDate?: string | null,
    passportExpiryDate?: string | null,
    passportIssuingCountry?: string | null,
    confirmation?: boolean | null,
    pensionSchemeOpt?: string | null,
    emergencyContactName?: string | null,
    emergencyContactRelationship?: string | null,
    emergencyContactPhone?: string | null,
    emergencyContactEmailAddress?: string | null,
    emergencyContactStreet1?: string | null,
    emergencyContactStreet2?: string | null,
    emergencyContactCity?: string | null,
    emergencyContactState?: string | null,
    emergencyContactZip?: string | null,
    emergencyContactCountry?: string | null,
    PreboardingUserRelations?:  {
      __typename: "ModelPreboardingUserRelationConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePreboardingUserSubscriptionVariables = {
  filter?: ModelSubscriptionPreboardingUserFilterInput | null,
};

export type OnUpdatePreboardingUserSubscription = {
  onUpdatePreboardingUser?:  {
    __typename: "PreboardingUser",
    id: string,
    userId: string,
    isSubmitted?: boolean | null,
    consent?: boolean | null,
    journeyId?: string | null,
    personalEmail?: string | null,
    photoUrl?: string | null,
    bankName?: string | null,
    bankBranch?: string | null,
    bankSortCode?: string | null,
    bankAccountNumber?: string | null,
    bankAccountName?: string | null,
    buildingSocietyRoll?: string | null,
    street1?: string | null,
    street2?: string | null,
    city?: string | null,
    state?: string | null,
    zip?: string | null,
    country?: string | null,
    birthday?: string | null,
    gender?: string | null,
    title?: string | null,
    nationality?: string | null,
    mobileNumber?: string | null,
    niNumber?: string | null,
    laptopPreference?: LaptopPreference | null,
    secondMonitor?: boolean | null,
    corporateCard?: boolean | null,
    corporatePhone?: PhonePreference | null,
    healthCare?: boolean | null,
    dental?: boolean | null,
    optical?: boolean | null,
    jobDeclaration?: Declaration | null,
    expatDeclaration?: Declaration | null,
    studentLoanRepayment?: boolean | null,
    studentLoanType?: StudentLoanType | null,
    pensionScheme?: boolean | null,
    pensionContribution?: number | null,
    passportNumber?: string | null,
    passportIssueDate?: string | null,
    passportExpiryDate?: string | null,
    passportIssuingCountry?: string | null,
    confirmation?: boolean | null,
    pensionSchemeOpt?: string | null,
    emergencyContactName?: string | null,
    emergencyContactRelationship?: string | null,
    emergencyContactPhone?: string | null,
    emergencyContactEmailAddress?: string | null,
    emergencyContactStreet1?: string | null,
    emergencyContactStreet2?: string | null,
    emergencyContactCity?: string | null,
    emergencyContactState?: string | null,
    emergencyContactZip?: string | null,
    emergencyContactCountry?: string | null,
    PreboardingUserRelations?:  {
      __typename: "ModelPreboardingUserRelationConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletePreboardingUserSubscriptionVariables = {
  filter?: ModelSubscriptionPreboardingUserFilterInput | null,
};

export type OnDeletePreboardingUserSubscription = {
  onDeletePreboardingUser?:  {
    __typename: "PreboardingUser",
    id: string,
    userId: string,
    isSubmitted?: boolean | null,
    consent?: boolean | null,
    journeyId?: string | null,
    personalEmail?: string | null,
    photoUrl?: string | null,
    bankName?: string | null,
    bankBranch?: string | null,
    bankSortCode?: string | null,
    bankAccountNumber?: string | null,
    bankAccountName?: string | null,
    buildingSocietyRoll?: string | null,
    street1?: string | null,
    street2?: string | null,
    city?: string | null,
    state?: string | null,
    zip?: string | null,
    country?: string | null,
    birthday?: string | null,
    gender?: string | null,
    title?: string | null,
    nationality?: string | null,
    mobileNumber?: string | null,
    niNumber?: string | null,
    laptopPreference?: LaptopPreference | null,
    secondMonitor?: boolean | null,
    corporateCard?: boolean | null,
    corporatePhone?: PhonePreference | null,
    healthCare?: boolean | null,
    dental?: boolean | null,
    optical?: boolean | null,
    jobDeclaration?: Declaration | null,
    expatDeclaration?: Declaration | null,
    studentLoanRepayment?: boolean | null,
    studentLoanType?: StudentLoanType | null,
    pensionScheme?: boolean | null,
    pensionContribution?: number | null,
    passportNumber?: string | null,
    passportIssueDate?: string | null,
    passportExpiryDate?: string | null,
    passportIssuingCountry?: string | null,
    confirmation?: boolean | null,
    pensionSchemeOpt?: string | null,
    emergencyContactName?: string | null,
    emergencyContactRelationship?: string | null,
    emergencyContactPhone?: string | null,
    emergencyContactEmailAddress?: string | null,
    emergencyContactStreet1?: string | null,
    emergencyContactStreet2?: string | null,
    emergencyContactCity?: string | null,
    emergencyContactState?: string | null,
    emergencyContactZip?: string | null,
    emergencyContactCountry?: string | null,
    PreboardingUserRelations?:  {
      __typename: "ModelPreboardingUserRelationConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePreboardingUserRelationSubscriptionVariables = {
  filter?: ModelSubscriptionPreboardingUserRelationFilterInput | null,
};

export type OnCreatePreboardingUserRelationSubscription = {
  onCreatePreboardingUserRelation?:  {
    __typename: "PreboardingUserRelation",
    id: string,
    firstName?: string | null,
    lastName?: string | null,
    relationshipToMember?: string | null,
    birthday?: string | null,
    userId: string,
    formId: string,
    healthCare?: boolean | null,
    dental?: boolean | null,
    optical?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePreboardingUserRelationSubscriptionVariables = {
  filter?: ModelSubscriptionPreboardingUserRelationFilterInput | null,
};

export type OnUpdatePreboardingUserRelationSubscription = {
  onUpdatePreboardingUserRelation?:  {
    __typename: "PreboardingUserRelation",
    id: string,
    firstName?: string | null,
    lastName?: string | null,
    relationshipToMember?: string | null,
    birthday?: string | null,
    userId: string,
    formId: string,
    healthCare?: boolean | null,
    dental?: boolean | null,
    optical?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletePreboardingUserRelationSubscriptionVariables = {
  filter?: ModelSubscriptionPreboardingUserRelationFilterInput | null,
};

export type OnDeletePreboardingUserRelationSubscription = {
  onDeletePreboardingUserRelation?:  {
    __typename: "PreboardingUserRelation",
    id: string,
    firstName?: string | null,
    lastName?: string | null,
    relationshipToMember?: string | null,
    birthday?: string | null,
    userId: string,
    formId: string,
    healthCare?: boolean | null,
    dental?: boolean | null,
    optical?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateDigestSubscriptionVariables = {
  filter?: ModelSubscriptionDigestFilterInput | null,
};

export type OnCreateDigestSubscription = {
  onCreateDigest?:  {
    __typename: "Digest",
    id: string,
    type: DigestType,
    organizationId: string,
    userId: string,
    frequency: Frequency,
    channel: Channel,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateDigestSubscriptionVariables = {
  filter?: ModelSubscriptionDigestFilterInput | null,
};

export type OnUpdateDigestSubscription = {
  onUpdateDigest?:  {
    __typename: "Digest",
    id: string,
    type: DigestType,
    organizationId: string,
    userId: string,
    frequency: Frequency,
    channel: Channel,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteDigestSubscriptionVariables = {
  filter?: ModelSubscriptionDigestFilterInput | null,
};

export type OnDeleteDigestSubscription = {
  onDeleteDigest?:  {
    __typename: "Digest",
    id: string,
    type: DigestType,
    organizationId: string,
    userId: string,
    frequency: Frequency,
    channel: Channel,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateGqlMessageSubscriptionVariables = {
  filter?: ModelSubscriptionGqlMessageFilterInput | null,
};

export type OnCreateGqlMessageSubscription = {
  onCreateGqlMessage?:  {
    __typename: "GqlMessage",
    id: string,
    type: string,
    organizationId: string,
    recipientId: string,
    payload: string,
    response?: string | null,
    status: string,
    callbackId?: string | null,
    deliveryInfo?: string | null,
    respondedAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateGqlMessageSubscriptionVariables = {
  filter?: ModelSubscriptionGqlMessageFilterInput | null,
};

export type OnUpdateGqlMessageSubscription = {
  onUpdateGqlMessage?:  {
    __typename: "GqlMessage",
    id: string,
    type: string,
    organizationId: string,
    recipientId: string,
    payload: string,
    response?: string | null,
    status: string,
    callbackId?: string | null,
    deliveryInfo?: string | null,
    respondedAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteGqlMessageSubscriptionVariables = {
  filter?: ModelSubscriptionGqlMessageFilterInput | null,
};

export type OnDeleteGqlMessageSubscription = {
  onDeleteGqlMessage?:  {
    __typename: "GqlMessage",
    id: string,
    type: string,
    organizationId: string,
    recipientId: string,
    payload: string,
    response?: string | null,
    status: string,
    callbackId?: string | null,
    deliveryInfo?: string | null,
    respondedAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateGqlMsTeamsUserReferenceSubscriptionVariables = {
  filter?: ModelSubscriptionGqlMsTeamsUserReferenceFilterInput | null,
};

export type OnCreateGqlMsTeamsUserReferenceSubscription = {
  onCreateGqlMsTeamsUserReference?:  {
    __typename: "GqlMsTeamsUserReference",
    tenantId: string,
    userEmail: string,
    teamsUserId?: string | null,
    conversation?: string | null,
    userId?: string | null,
    organizationId?: string | null,
    hasUndeliveredMessages?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateGqlMsTeamsUserReferenceSubscriptionVariables = {
  filter?: ModelSubscriptionGqlMsTeamsUserReferenceFilterInput | null,
};

export type OnUpdateGqlMsTeamsUserReferenceSubscription = {
  onUpdateGqlMsTeamsUserReference?:  {
    __typename: "GqlMsTeamsUserReference",
    tenantId: string,
    userEmail: string,
    teamsUserId?: string | null,
    conversation?: string | null,
    userId?: string | null,
    organizationId?: string | null,
    hasUndeliveredMessages?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteGqlMsTeamsUserReferenceSubscriptionVariables = {
  filter?: ModelSubscriptionGqlMsTeamsUserReferenceFilterInput | null,
};

export type OnDeleteGqlMsTeamsUserReferenceSubscription = {
  onDeleteGqlMsTeamsUserReference?:  {
    __typename: "GqlMsTeamsUserReference",
    tenantId: string,
    userEmail: string,
    teamsUserId?: string | null,
    conversation?: string | null,
    userId?: string | null,
    organizationId?: string | null,
    hasUndeliveredMessages?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateGqlDataStoreSubscriptionVariables = {
  filter?: ModelSubscriptionGqlDataStoreFilterInput | null,
};

export type OnCreateGqlDataStoreSubscription = {
  onCreateGqlDataStore?:  {
    __typename: "GqlDataStore",
    id: string,
    organizationId: string,
    name: string,
    data: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateGqlDataStoreSubscriptionVariables = {
  filter?: ModelSubscriptionGqlDataStoreFilterInput | null,
};

export type OnUpdateGqlDataStoreSubscription = {
  onUpdateGqlDataStore?:  {
    __typename: "GqlDataStore",
    id: string,
    organizationId: string,
    name: string,
    data: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteGqlDataStoreSubscriptionVariables = {
  filter?: ModelSubscriptionGqlDataStoreFilterInput | null,
};

export type OnDeleteGqlDataStoreSubscription = {
  onDeleteGqlDataStore?:  {
    __typename: "GqlDataStore",
    id: string,
    organizationId: string,
    name: string,
    data: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateGqlDataStoreRowSubscriptionVariables = {
  filter?: ModelSubscriptionGqlDataStoreRowFilterInput | null,
};

export type OnCreateGqlDataStoreRowSubscription = {
  onCreateGqlDataStoreRow?:  {
    __typename: "GqlDataStoreRow",
    id: string,
    dataStoreId: string,
    data: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateGqlDataStoreRowSubscriptionVariables = {
  filter?: ModelSubscriptionGqlDataStoreRowFilterInput | null,
};

export type OnUpdateGqlDataStoreRowSubscription = {
  onUpdateGqlDataStoreRow?:  {
    __typename: "GqlDataStoreRow",
    id: string,
    dataStoreId: string,
    data: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteGqlDataStoreRowSubscriptionVariables = {
  filter?: ModelSubscriptionGqlDataStoreRowFilterInput | null,
};

export type OnDeleteGqlDataStoreRowSubscription = {
  onDeleteGqlDataStoreRow?:  {
    __typename: "GqlDataStoreRow",
    id: string,
    dataStoreId: string,
    data: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateGqlFormSubscriptionVariables = {
  filter?: ModelSubscriptionGqlFormFilterInput | null,
};

export type OnCreateGqlFormSubscription = {
  onCreateGqlForm?:  {
    __typename: "GqlForm",
    id: string,
    organizationId: string,
    name: string,
    dataStoreId: string,
    data: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateGqlFormSubscriptionVariables = {
  filter?: ModelSubscriptionGqlFormFilterInput | null,
};

export type OnUpdateGqlFormSubscription = {
  onUpdateGqlForm?:  {
    __typename: "GqlForm",
    id: string,
    organizationId: string,
    name: string,
    dataStoreId: string,
    data: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteGqlFormSubscriptionVariables = {
  filter?: ModelSubscriptionGqlFormFilterInput | null,
};

export type OnDeleteGqlFormSubscription = {
  onDeleteGqlForm?:  {
    __typename: "GqlForm",
    id: string,
    organizationId: string,
    name: string,
    dataStoreId: string,
    data: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateGqlFormInstanceSubscriptionVariables = {
  filter?: ModelSubscriptionGqlFormInstanceFilterInput | null,
};

export type OnCreateGqlFormInstanceSubscription = {
  onCreateGqlFormInstance?:  {
    __typename: "GqlFormInstance",
    id: string,
    organizationId: string,
    formId: string,
    assignedTo: string,
    link: string,
    slug: string,
    data: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateGqlFormInstanceSubscriptionVariables = {
  filter?: ModelSubscriptionGqlFormInstanceFilterInput | null,
};

export type OnUpdateGqlFormInstanceSubscription = {
  onUpdateGqlFormInstance?:  {
    __typename: "GqlFormInstance",
    id: string,
    organizationId: string,
    formId: string,
    assignedTo: string,
    link: string,
    slug: string,
    data: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteGqlFormInstanceSubscriptionVariables = {
  filter?: ModelSubscriptionGqlFormInstanceFilterInput | null,
};

export type OnDeleteGqlFormInstanceSubscription = {
  onDeleteGqlFormInstance?:  {
    __typename: "GqlFormInstance",
    id: string,
    organizationId: string,
    formId: string,
    assignedTo: string,
    link: string,
    slug: string,
    data: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUserShareInfoSubscriptionVariables = {
  filter?: ModelSubscriptionUserShareInfoFilterInput | null,
};

export type OnCreateUserShareInfoSubscription = {
  onCreateUserShareInfo?:  {
    __typename: "UserShareInfo",
    id: string,
    shareInfoID: string,
    userID: string,
    shareInfo:  {
      __typename: "ShareInfo",
      id: string,
      name: string,
      organizationID: string,
      createdAt: string,
      updatedAt: string,
    },
    user:  {
      __typename: "User",
      id: string,
      offset: number,
      firstName?: string | null,
      email: string,
      phone?: string | null,
      sendWelcomeEmail?: boolean | null,
      personalEmail?: string | null,
      jobTitle?: string | null,
      birthday: string,
      startDate?: string | null,
      homeLocation?: string | null,
      team?: string | null,
      photoUrl: string,
      pronoun: Pronoun,
      aboutMe?: string | null,
      mostHappy?: string | null,
      socialMediaUrl?: string | null,
      slackUserId?: string | null,
      isFirstLogin?: boolean | null,
      isHomeModelViewed?: boolean | null,
      isProfileModelViewed?: boolean | null,
      type: UserType,
      isActive?: boolean | null,
      lastName?: string | null,
      createdAt: string,
      updatedAt: string,
      userOrganizationId: string,
      userWorkPreferenceId: string,
      userWorkStyleId: string,
      userWorkLocationId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserShareInfoSubscriptionVariables = {
  filter?: ModelSubscriptionUserShareInfoFilterInput | null,
};

export type OnUpdateUserShareInfoSubscription = {
  onUpdateUserShareInfo?:  {
    __typename: "UserShareInfo",
    id: string,
    shareInfoID: string,
    userID: string,
    shareInfo:  {
      __typename: "ShareInfo",
      id: string,
      name: string,
      organizationID: string,
      createdAt: string,
      updatedAt: string,
    },
    user:  {
      __typename: "User",
      id: string,
      offset: number,
      firstName?: string | null,
      email: string,
      phone?: string | null,
      sendWelcomeEmail?: boolean | null,
      personalEmail?: string | null,
      jobTitle?: string | null,
      birthday: string,
      startDate?: string | null,
      homeLocation?: string | null,
      team?: string | null,
      photoUrl: string,
      pronoun: Pronoun,
      aboutMe?: string | null,
      mostHappy?: string | null,
      socialMediaUrl?: string | null,
      slackUserId?: string | null,
      isFirstLogin?: boolean | null,
      isHomeModelViewed?: boolean | null,
      isProfileModelViewed?: boolean | null,
      type: UserType,
      isActive?: boolean | null,
      lastName?: string | null,
      createdAt: string,
      updatedAt: string,
      userOrganizationId: string,
      userWorkPreferenceId: string,
      userWorkStyleId: string,
      userWorkLocationId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserShareInfoSubscriptionVariables = {
  filter?: ModelSubscriptionUserShareInfoFilterInput | null,
};

export type OnDeleteUserShareInfoSubscription = {
  onDeleteUserShareInfo?:  {
    __typename: "UserShareInfo",
    id: string,
    shareInfoID: string,
    userID: string,
    shareInfo:  {
      __typename: "ShareInfo",
      id: string,
      name: string,
      organizationID: string,
      createdAt: string,
      updatedAt: string,
    },
    user:  {
      __typename: "User",
      id: string,
      offset: number,
      firstName?: string | null,
      email: string,
      phone?: string | null,
      sendWelcomeEmail?: boolean | null,
      personalEmail?: string | null,
      jobTitle?: string | null,
      birthday: string,
      startDate?: string | null,
      homeLocation?: string | null,
      team?: string | null,
      photoUrl: string,
      pronoun: Pronoun,
      aboutMe?: string | null,
      mostHappy?: string | null,
      socialMediaUrl?: string | null,
      slackUserId?: string | null,
      isFirstLogin?: boolean | null,
      isHomeModelViewed?: boolean | null,
      isProfileModelViewed?: boolean | null,
      type: UserType,
      isActive?: boolean | null,
      lastName?: string | null,
      createdAt: string,
      updatedAt: string,
      userOrganizationId: string,
      userWorkPreferenceId: string,
      userWorkStyleId: string,
      userWorkLocationId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateLearnMoreInfoSubscriptionVariables = {
  filter?: ModelSubscriptionLearnMoreInfoFilterInput | null,
};

export type OnCreateLearnMoreInfoSubscription = {
  onCreateLearnMoreInfo?:  {
    __typename: "LearnMoreInfo",
    id: string,
    shareInfoID: string,
    userID: string,
    shareInfo:  {
      __typename: "ShareInfo",
      id: string,
      name: string,
      organizationID: string,
      createdAt: string,
      updatedAt: string,
    },
    user:  {
      __typename: "User",
      id: string,
      offset: number,
      firstName?: string | null,
      email: string,
      phone?: string | null,
      sendWelcomeEmail?: boolean | null,
      personalEmail?: string | null,
      jobTitle?: string | null,
      birthday: string,
      startDate?: string | null,
      homeLocation?: string | null,
      team?: string | null,
      photoUrl: string,
      pronoun: Pronoun,
      aboutMe?: string | null,
      mostHappy?: string | null,
      socialMediaUrl?: string | null,
      slackUserId?: string | null,
      isFirstLogin?: boolean | null,
      isHomeModelViewed?: boolean | null,
      isProfileModelViewed?: boolean | null,
      type: UserType,
      isActive?: boolean | null,
      lastName?: string | null,
      createdAt: string,
      updatedAt: string,
      userOrganizationId: string,
      userWorkPreferenceId: string,
      userWorkStyleId: string,
      userWorkLocationId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateLearnMoreInfoSubscriptionVariables = {
  filter?: ModelSubscriptionLearnMoreInfoFilterInput | null,
};

export type OnUpdateLearnMoreInfoSubscription = {
  onUpdateLearnMoreInfo?:  {
    __typename: "LearnMoreInfo",
    id: string,
    shareInfoID: string,
    userID: string,
    shareInfo:  {
      __typename: "ShareInfo",
      id: string,
      name: string,
      organizationID: string,
      createdAt: string,
      updatedAt: string,
    },
    user:  {
      __typename: "User",
      id: string,
      offset: number,
      firstName?: string | null,
      email: string,
      phone?: string | null,
      sendWelcomeEmail?: boolean | null,
      personalEmail?: string | null,
      jobTitle?: string | null,
      birthday: string,
      startDate?: string | null,
      homeLocation?: string | null,
      team?: string | null,
      photoUrl: string,
      pronoun: Pronoun,
      aboutMe?: string | null,
      mostHappy?: string | null,
      socialMediaUrl?: string | null,
      slackUserId?: string | null,
      isFirstLogin?: boolean | null,
      isHomeModelViewed?: boolean | null,
      isProfileModelViewed?: boolean | null,
      type: UserType,
      isActive?: boolean | null,
      lastName?: string | null,
      createdAt: string,
      updatedAt: string,
      userOrganizationId: string,
      userWorkPreferenceId: string,
      userWorkStyleId: string,
      userWorkLocationId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteLearnMoreInfoSubscriptionVariables = {
  filter?: ModelSubscriptionLearnMoreInfoFilterInput | null,
};

export type OnDeleteLearnMoreInfoSubscription = {
  onDeleteLearnMoreInfo?:  {
    __typename: "LearnMoreInfo",
    id: string,
    shareInfoID: string,
    userID: string,
    shareInfo:  {
      __typename: "ShareInfo",
      id: string,
      name: string,
      organizationID: string,
      createdAt: string,
      updatedAt: string,
    },
    user:  {
      __typename: "User",
      id: string,
      offset: number,
      firstName?: string | null,
      email: string,
      phone?: string | null,
      sendWelcomeEmail?: boolean | null,
      personalEmail?: string | null,
      jobTitle?: string | null,
      birthday: string,
      startDate?: string | null,
      homeLocation?: string | null,
      team?: string | null,
      photoUrl: string,
      pronoun: Pronoun,
      aboutMe?: string | null,
      mostHappy?: string | null,
      socialMediaUrl?: string | null,
      slackUserId?: string | null,
      isFirstLogin?: boolean | null,
      isHomeModelViewed?: boolean | null,
      isProfileModelViewed?: boolean | null,
      type: UserType,
      isActive?: boolean | null,
      lastName?: string | null,
      createdAt: string,
      updatedAt: string,
      userOrganizationId: string,
      userWorkPreferenceId: string,
      userWorkStyleId: string,
      userWorkLocationId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUserInterestSubscriptionVariables = {
  filter?: ModelSubscriptionUserInterestFilterInput | null,
};

export type OnCreateUserInterestSubscription = {
  onCreateUserInterest?:  {
    __typename: "UserInterest",
    id: string,
    interestID: string,
    userID: string,
    interest:  {
      __typename: "Interest",
      id: string,
      name: string,
      organizationID: string,
      createdAt: string,
      updatedAt: string,
    },
    user:  {
      __typename: "User",
      id: string,
      offset: number,
      firstName?: string | null,
      email: string,
      phone?: string | null,
      sendWelcomeEmail?: boolean | null,
      personalEmail?: string | null,
      jobTitle?: string | null,
      birthday: string,
      startDate?: string | null,
      homeLocation?: string | null,
      team?: string | null,
      photoUrl: string,
      pronoun: Pronoun,
      aboutMe?: string | null,
      mostHappy?: string | null,
      socialMediaUrl?: string | null,
      slackUserId?: string | null,
      isFirstLogin?: boolean | null,
      isHomeModelViewed?: boolean | null,
      isProfileModelViewed?: boolean | null,
      type: UserType,
      isActive?: boolean | null,
      lastName?: string | null,
      createdAt: string,
      updatedAt: string,
      userOrganizationId: string,
      userWorkPreferenceId: string,
      userWorkStyleId: string,
      userWorkLocationId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserInterestSubscriptionVariables = {
  filter?: ModelSubscriptionUserInterestFilterInput | null,
};

export type OnUpdateUserInterestSubscription = {
  onUpdateUserInterest?:  {
    __typename: "UserInterest",
    id: string,
    interestID: string,
    userID: string,
    interest:  {
      __typename: "Interest",
      id: string,
      name: string,
      organizationID: string,
      createdAt: string,
      updatedAt: string,
    },
    user:  {
      __typename: "User",
      id: string,
      offset: number,
      firstName?: string | null,
      email: string,
      phone?: string | null,
      sendWelcomeEmail?: boolean | null,
      personalEmail?: string | null,
      jobTitle?: string | null,
      birthday: string,
      startDate?: string | null,
      homeLocation?: string | null,
      team?: string | null,
      photoUrl: string,
      pronoun: Pronoun,
      aboutMe?: string | null,
      mostHappy?: string | null,
      socialMediaUrl?: string | null,
      slackUserId?: string | null,
      isFirstLogin?: boolean | null,
      isHomeModelViewed?: boolean | null,
      isProfileModelViewed?: boolean | null,
      type: UserType,
      isActive?: boolean | null,
      lastName?: string | null,
      createdAt: string,
      updatedAt: string,
      userOrganizationId: string,
      userWorkPreferenceId: string,
      userWorkStyleId: string,
      userWorkLocationId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserInterestSubscriptionVariables = {
  filter?: ModelSubscriptionUserInterestFilterInput | null,
};

export type OnDeleteUserInterestSubscription = {
  onDeleteUserInterest?:  {
    __typename: "UserInterest",
    id: string,
    interestID: string,
    userID: string,
    interest:  {
      __typename: "Interest",
      id: string,
      name: string,
      organizationID: string,
      createdAt: string,
      updatedAt: string,
    },
    user:  {
      __typename: "User",
      id: string,
      offset: number,
      firstName?: string | null,
      email: string,
      phone?: string | null,
      sendWelcomeEmail?: boolean | null,
      personalEmail?: string | null,
      jobTitle?: string | null,
      birthday: string,
      startDate?: string | null,
      homeLocation?: string | null,
      team?: string | null,
      photoUrl: string,
      pronoun: Pronoun,
      aboutMe?: string | null,
      mostHappy?: string | null,
      socialMediaUrl?: string | null,
      slackUserId?: string | null,
      isFirstLogin?: boolean | null,
      isHomeModelViewed?: boolean | null,
      isProfileModelViewed?: boolean | null,
      type: UserType,
      isActive?: boolean | null,
      lastName?: string | null,
      createdAt: string,
      updatedAt: string,
      userOrganizationId: string,
      userWorkPreferenceId: string,
      userWorkStyleId: string,
      userWorkLocationId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};
