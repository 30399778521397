import clsx from 'clsx';
import { type ComponentPropsWithoutRef } from 'react';
import { FormStatus } from '../FormStatus';
import { type FormEvent } from '../ActivityPanel.types';
import styles from './FormDetails.module.scss';

interface FormDetailsProps
  extends Omit<ComponentPropsWithoutRef<'button'>, 'onClick'> {
  formEvent: FormEvent;
  onClick?: () => void;
}

export const FormDetails = ({
  className,
  formEvent,
  ...props
}: FormDetailsProps) => (
  <button {...props} className={clsx(styles.formDetails, className)}>
    <span className={styles.title}>{formEvent.name}</span>
    <FormStatus status={formEvent.status} />
  </button>
);
