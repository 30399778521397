import {
  deleteActionFromCollaborator,
  deletePlaybookEvent,
  deletePlaybookEventReminder,
  getFilteredUser,
  listPlaybookEvents,
  substituteDynamicTags,
  testEventNotifications,
  updatePlaybookEvent,
  updatePlaybookEventReminder,
} from '@api/apis';
import {
  AssigneeRole,
  BuilderType,
  type Event,
  type EventReminder,
  type Journey,
  type User,
} from '@base/API';
import { Keys } from '@base/keys/queryKeys';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  type IPlaybookCalendarSchedules,
  type IEvent,
} from '@base/models/playbookHome.model';
import {
  calculateSendTimeDate,
  addNotificationToApp,
  formateDate,
  convertChannelSpecificFormatToPlainText,
} from '@Shared/utils/utils';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { useIntercom } from 'react-use-intercom';
import { type DropResult } from 'react-beautiful-dnd';
import { useEvents } from '@base/Hooks/useEvents';
import { EventType } from '@base/models/common.model';
import { type SubstituteDynamicFieldsRequest } from '@api/api.model';
import { useDynamicTagsContext } from '@base/Context/DynamicTagsContext';
import { sureString } from '@Shared/utils/sure';

export const usePlaybookCalendar = (playbook: Journey, assignee?: User) => {
  const { id } = useParams();
  const { user, collaborators, setIsParentJourneyChanged } = useUserContext();
  const { setTagsValues, substituteValues } = useDynamicTagsContext();
  const [states, setStates] = useState({
    calendarData: [] as IPlaybookCalendarSchedules[],
    selectedFilter: 'All',
    showCreateUpdateActionPanel: false,
    showCreateUpdateMessagePanel: false,
    showCreateUpdateFormEventPanel: false,
    showActivityPanel: false,
    showTestMessagesConfirmationModal: false,
    showDeletingLoader: false,
    selectedEvent: {} as IEvent,
  });
  const queryClient = useQueryClient();
  const { getEventStatus, getUserName } = useEvents();
  const { update: updateIntercom } = useIntercom();
  const daysOfWeek = useMemo(
    () => [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ],
    [],
  );
  const weekDays = useMemo(
    () => [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ],
    [],
  );
  const assigneeRoles = {
    MANAGER: 'Manager',
    HRBP: 'HRBP',
    IT: 'IT',
    BUDDY: 'Buddy',
    HROPS: 'HR Ops',
    LEGAL: 'Legal',
  };

  const WEEK_LENGTH = 5;

  // fetch events
  const { data: events, isLoading: isEventsLoading } = useQuery({
    queryKey: Keys.getEvents(id),
    queryFn: async () => {
      if (!id) {
        return [];
      }

      const events = await listPlaybookEvents(id);
      if (!events) {
        return [];
      }

      await handleDynamicTags(id, events);
      return Promise.all(
        events.map(async (event) => {
          const role = ((event.role && assigneeRoles[event.role]) ??
            '') as AssigneeRole;

          if (!event.userId && !event.emailTo) {
            return {
              ...event,
              role:
                event.type === EventType.MESSAGE
                  ? ('Assignee' as AssigneeRole)
                  : role,
              userName: getUserName(event, role),
            };
          }

          const user = await getFilteredUser({
            or: [
              { id: { eq: event.userId ?? '' } },
              { email: { eq: event.emailTo ?? '' } },
              { personalEmail: { eq: event.emailTo ?? '' } },
            ],
          });
          return {
            ...event,
            role,
            emailTo: event.emailTo ?? user?.email,
            userName: getUserName(event, role, user),
            userSlackId: user?.slackUserId?.toString(),
          };
        }),
      );
    },
    enabled: !!id,
  });

  const getStartDayIndex = useCallback(() => {
    let startDayIndex = 1; // Monday is start day if there is no runbook start date
    const playbookStartDate = playbook.startDate ?? assignee?.startDate;
    if (playbookStartDate) {
      return (startDayIndex = new Date(playbookStartDate).getUTCDay());
    }

    return startDayIndex;
  }, [assignee?.startDate, playbook.startDate]);

  const getDaysFromStartDate = useCallback(
    (currentDay: string, currentWeek: string) => {
      const startDayIndex = getStartDayIndex();
      let currentWeekNumber = 0;

      const eventDayIndex = daysOfWeek.indexOf(currentDay);
      let daysDifference = eventDayIndex - startDayIndex;

      if (currentWeek.includes('before')) {
        currentWeekNumber = -getWeekNumber(currentWeek);
      } else {
        currentWeekNumber = getWeekNumber(currentWeek);
      }

      if (currentWeekNumber === 0) {
        return daysDifference;
      } else if (currentWeekNumber > 0) {
        daysDifference += currentWeekNumber * WEEK_LENGTH;
        return daysDifference;
      }

      // If the event day is before the start day
      daysDifference -= Math.abs(currentWeekNumber) * WEEK_LENGTH;
      return daysDifference;
    },
    [daysOfWeek, getStartDayIndex],
  );

  const generateCalendarDefaultData =
    useCallback((): IPlaybookCalendarSchedules[] => {
      const weekLabels = [
        '4 week before start date',
        '3 week before start date',
        '2 week before start date',
        '1 week before start date',
        'First week',
        ...Array.from({ length: 26 }).map((_, index) => `Week ${index + 2}`),
      ];

      return Array.from({ length: 31 }).map((_, weekIndex) => ({
        weekLabel: weekLabels[weekIndex],
        days: Array.from({ length: WEEK_LENGTH }).map((_, dayIndex) => ({
          day: weekDays[dayIndex].charAt(0),
          isStartDay:
            weekLabels[weekIndex] === 'First week'
              ? playbook.startDate
                ? daysOfWeek[new Date(playbook.startDate).getUTCDay()] ===
                  weekDays[dayIndex]
                : dayIndex === 0
              : false,
          events: [] as IEvent[],
          daysFrom: getDaysFromStartDate(
            weekDays[dayIndex],
            weekLabels[weekIndex],
          ),
        })),
      }));
    }, [daysOfWeek, getDaysFromStartDate, playbook.startDate, weekDays]);

  const compareEventTimings = (a: IEvent, b: IEvent) => {
    if (!a.sendTime || !b.sendTime) {
      return 0;
    }

    const aTiming = +a.sendTime.split(':')[0];
    const bTiming = +b.sendTime.split(':')[0];
    return aTiming - bTiming;
  };

  const getDateFromWeekDay = (week: string, dayNumber: number) => {
    const playbookStartDate = playbook.startDate ?? assignee?.startDate;
    if (
      !playbookStartDate ||
      (playbook.type === BuilderType.SELFSERVE && !playbook.userStartedAt)
    ) {
      return null;
    }

    const startDate = new Date(playbookStartDate);
    const startDateInEpoch = startDate.getTime();
    const weekNumber = getWeekNumber(week);

    if (week.includes('before')) {
      const totalDays =
        weekNumber * 7 + startDate.getUTCDay() - (dayNumber + 1);
      const dateInEpoch = startDateInEpoch - totalDays * 86400000;
      return formateDate(new Date(dateInEpoch), true, false);
    }
    const totalDays = weekNumber * 7 - startDate.getUTCDay() + (dayNumber + 1);
    const dateInEpoch = startDateInEpoch + totalDays * 86400000;
    return formateDate(new Date(dateInEpoch), true, false);
  };

  const getWeekNumber = (title: string) => {
    if (title.includes('First week')) return 0;
    const match = title.match(/\d+/);
    return match
      ? title.includes('before')
        ? parseInt(match[0])
        : parseInt(match[0]) - 1
      : Number.MAX_VALUE; // Week 2 is actually week 1 and so on
  };

  const customSort = useCallback(
    (a: IPlaybookCalendarSchedules, b: IPlaybookCalendarSchedules) => {
      const weekA = getWeekNumber(a.weekLabel);
      const weekB = getWeekNumber(b.weekLabel);
      const isBeforeA = a.weekLabel.includes('before');
      const isBeforeB = b.weekLabel.includes('before');
      const isFirstWeekA = a.weekLabel.includes('First week');
      const isFirstWeekB = b.weekLabel.includes('First week');

      // Sort "before" items before "after" items
      if (isBeforeA && isBeforeB) {
        return weekB - weekA; // Higher number of weeks come first
      } else if (isBeforeA && !isBeforeB) {
        return -1;
      } else if (!isBeforeA && isBeforeB) {
        return 1;
      }

      // Sort "First week" in between "before" and "after" items
      if (isFirstWeekA && isFirstWeekB) {
        return 0;
      } else if (isFirstWeekA) {
        return -1;
      } else if (isFirstWeekB) {
        return 1;
      }

      // Sort weeks in ascending order
      return weekA - weekB;
    },
    [],
  );

  const refreshCalendarEvents = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: Keys.getEvents(id) });
    queryClient.invalidateQueries({ queryKey: Keys.getFormEvents(id) });
  }, [id, queryClient]);

  const editEvent = (eventId: string, type: EventType) => {
    const event = findEventById(eventId);

    if (!event) {
      return;
    }

    setStates((prevStates) => ({
      ...prevStates,
      selectedEvent: {
        ...event,
        role: event.role?.split(' ').join('').toUpperCase() as AssigneeRole,
      },
      showCreateUpdateActionPanel: type === EventType.ACTION,
      showCreateUpdateMessagePanel: type === EventType.MESSAGE,
      showCreateUpdateFormEventPanel: type === EventType.FORM,
    }));
  };

  const createEvent = (type: EventType, daysFrom?: number) => {
    setStates((prevStates) => ({
      ...prevStates,
      selectedEvent: { daysFrom } as IEvent,
      showCreateUpdateActionPanel: type === EventType.ACTION,
      showCreateUpdateMessagePanel: type === EventType.MESSAGE,
      showCreateUpdateFormEventPanel: type === EventType.FORM,
    }));
  };

  const findEventById = (eventId: string): Event | undefined => {
    if (!events) {
      return;
    }

    let event = events.find((event) => event.id === eventId);

    if (!event) {
      event = events.find((event) => {
        const reminders = event.reminders?.items || [];
        return reminders.some((reminder) => reminder?.id === eventId);
      });
    }

    return event;
  };

  const deleteEvent = async (
    id: string,
    type: EventType,
    isReminder?: boolean,
  ) => {
    if (!events) {
      return;
    }

    const event = findEventById(id);
    if (!event) {
      return;
    }

    setStates((prevStates) => ({ ...prevStates, showDeletingLoader: true }));

    if (isReminder) {
      await Promise.all([
        deletePlaybookEventReminder(id),
        updatePlaybookEvent({
          id: event.id,
          updatedInChild: !!playbook.parentJourneyID,
        } as Event),
      ]);
    } else {
      let sideEffects = [];
      if (!playbook.parentJourneyID || !event.parentId) {
        sideEffects.push(deletePlaybookEvent(event.id));
      } else {
        sideEffects.push(
          updatePlaybookEvent({
            id: event.id,
            archived: true,
            updatedInChild: true,
          } as Event),
        );
      }

      if (type === EventType.ACTION && event.role && event.journeyID) {
        sideEffects.push(
          deleteActionFromCollaborator(event.id, event.journeyID, {
            assigneeRole: {
              eq: event.role?.split(' ').join('').toUpperCase() as AssigneeRole,
            },
          }),
        );
      }

      await Promise.all(sideEffects);
    }

    setIsParentJourneyChanged(!playbook.parentJourneyID);
    setStates((prevStates) => ({ ...prevStates, showDeletingLoader: false }));
    refreshCalendarEvents();
  };

  const testEvents = async () => {
    if (!id) {
      return;
    }

    addNotificationToApp('We are sending notifications to you.', 'info');
    setStates((prevStates) => ({
      ...prevStates,
      showTestMessagesConfirmationModal: false,
    }));
    await testEventNotifications(id, user.id);
  };

  const getWeekLabelFromDays = useCallback(
    (daysFrom: number) => {
      const startDayIndex = getStartDayIndex();

      if (
        daysFrom === 0 ||
        (daysFrom < 0 && startDayIndex > Math.abs(daysFrom)) ||
        (daysFrom > 0 && startDayIndex + daysFrom <= WEEK_LENGTH)
      ) {
        return 'First week';
      } else if (daysFrom < 0) {
        const daysToWeek = Math.ceil(
          (daysFrom - (WEEK_LENGTH - startDayIndex)) / WEEK_LENGTH,
        ); // (daysFrom - (days in week - remaining days of the week from daysFrom))/days in week
        return `${Math.abs(daysToWeek)} week before start date`;
      } else {
        const daysToWeek =
          Math.floor((daysFrom + startDayIndex - 1) / WEEK_LENGTH) + 1; // ((daysFrom + remaining days of the week from daysFrom - exclude start day)/days in week) + 1 to move weeks one ahead to change week 1 to week 2 and so on
        return `Week ${daysToWeek}`;
      }
    },
    [getStartDayIndex],
  );

  const addEvent = useCallback(
    (weekIndex: number, event: IEvent, data: IPlaybookCalendarSchedules[]) => {
      const eventDayIndex = data[weekIndex].days.findIndex(
        (day) => day.daysFrom === event.daysFrom,
      );
      if (eventDayIndex >= 0) {
        const newEvent = {
          ...event,
          sendTime: event.sendTime ?? '8:00',
          message:
            substituteValues(
              event.isActionReminder ? sureString(event.eventId, '') : event.id,
            ) ?? parseEventMessage(event),
          title:
            substituteValues(
              event.isActionReminder ? sureString(event.eventId, '') : event.id,
            ) ?? event.title,
          role: event.role,
        } as IEvent;
        data[weekIndex].days[eventDayIndex].events.push(newEvent);
      }

      return [...data];
    },
    [substituteValues],
  );

  const checkStartDay = useCallback(
    (weekLabel: string, day: string) => {
      const playbookStartDate = playbook.startDate || assignee?.startDate;
      if (weekLabel !== 'First week') {
        return false;
      }

      if (playbookStartDate) {
        return daysOfWeek[new Date(playbookStartDate).getUTCDay()] === day;
      }

      return day === 'Monday';
    },
    [assignee?.startDate, daysOfWeek, playbook.startDate],
  );

  const createWeekAndAddEvent = useCallback(
    (weekLabel: string, event: IEvent, data: IPlaybookCalendarSchedules[]) => {
      data.push({
        weekLabel: weekLabel,
        days: Array.from({ length: WEEK_LENGTH }).map((_, dayIndex) => ({
          day: weekDays[dayIndex].charAt(0),
          isStartDay: checkStartDay(weekLabel, weekDays[dayIndex]),
          events: [],
          daysFrom: getDaysFromStartDate(weekDays[dayIndex], weekLabel),
        })),
      });

      data = addEvent(data.length - 1, event, data);
      return [...data];
    },
    [addEvent, checkStartDay, getDaysFromStartDate, weekDays],
  );

  const addEventToCalendarData = useCallback(
    (event: IEvent, data: IPlaybookCalendarSchedules[]) => {
      const weekLabel = getWeekLabelFromDays(event.daysFrom);
      const weekIndexAlreadyInCalendar = data.findIndex(
        (week) => week.weekLabel === weekLabel,
      );

      if (weekIndexAlreadyInCalendar >= 0) {
        data = addEvent(weekIndexAlreadyInCalendar, event, data);
      } else {
        data = createWeekAndAddEvent(weekLabel, event, data);
      }

      return data;
    },
    [addEvent, createWeekAndAddEvent, getWeekLabelFromDays],
  );

  const processPlaybookEvents = useCallback(
    (events: IEvent[], data: IPlaybookCalendarSchedules[]) => {
      events.forEach((event) => {
        data = addEventToCalendarData(event, data);

        const reminders = event.reminders;
        if (!reminders) {
          return;
        }

        reminders.items.forEach((reminder) => {
          if (!reminder) {
            return;
          }
          data = addEventToCalendarData(
            {
              ...event,
              id: reminder.id,
              daysFrom: reminder.daysFrom,
              eventId: event.id,
              sendTimeDate: reminder.sendTimeDate,
              status: reminder.status,
              isActionReminder: true,
            },
            data,
          );
        });
      });
      return data;
    },
    [addEventToCalendarData],
  );

  const updateEventSendTimeDate = useCallback(
    async (event: IEvent): Promise<void> => {
      const playbookStartDate = playbook.startDate ?? assignee?.startDate;
      let request = { id: event.id, daysFrom: event.daysFrom } as Event;

      if (playbookStartDate) {
        const sendTimeDate = calculateSendTimeDate(
          playbookStartDate,
          event.daysFrom,
          event.sendTime?.toString(),
        );
        const status = getEventStatus(
          event.status,
          !playbook.parentJourneyID,
          playbook.status,
          sendTimeDate,
        );
        request = { ...request, sendTimeDate, status };
      }

      if (event.isActionReminder) {
        await updatePlaybookEventReminder(request as unknown as EventReminder);
      } else {
        await updatePlaybookEvent({
          ...request,
          updatedInChild: !!playbook.parentJourneyID,
        });
      }

      refreshCalendarEvents();
    },
    [
      assignee?.startDate,
      getEventStatus,
      playbook.parentJourneyID,
      playbook.startDate,
      playbook.status,
      refreshCalendarEvents,
    ],
  );

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      const { destination, source, draggableId } = result;

      if (destination && destination.droppableId !== source.droppableId) {
        const { droppableId } = destination;
        let draggedEvent: IEvent | undefined;

        setStates((currState) => {
          currState.calendarData.forEach((data, index) => {
            data.days.forEach((day) => {
              if (!draggedEvent) {
                draggedEvent = day.events.find(
                  (event) => event.id === draggableId,
                );
                if (draggedEvent) {
                  day.events = day.events.filter(
                    (event) => event.id !== draggableId,
                  );
                }
              }
            });
          });

          if (draggedEvent) {
            currState.calendarData.forEach((data, index) => {
              data.days.forEach((day) => {
                if (day.daysFrom.toString() === droppableId && draggedEvent) {
                  day.events.push({ ...draggedEvent, daysFrom: day.daysFrom });
                  updateEventSendTimeDate({
                    ...draggedEvent,
                    daysFrom: day.daysFrom,
                  });
                }
              });
            });
          }
          return {
            ...currState,
            calendarData: [...currState.calendarData],
          };
        });
      }
    },
    [updateEventSendTimeDate],
  );

  const parseEventMessage = (event: IEvent) => {
    if (!event.message) {
      return '';
    }

    const description = convertChannelSpecificFormatToPlainText(
      event.message,
      event.channel,
    );

    return description.replace(/(<([^>]+)>)/gi, '');
  };

  const handleDynamicTags = async (runbookId: string, events: Event[]) => {
    if (!playbook.assignedUserID) {
      return;
    }

    let texts: { [key: string]: string } = {};
    events.forEach((event) => {
      texts[event.id] = !!event.title ? event.title : parseEventMessage(event);
    });

    const request: SubstituteDynamicFieldsRequest = {
      orgId: user.userOrganizationId,
      data: {
        texts,
        context: {
          runbook: runbookId,
        },
      },
    };
    const res = await substituteDynamicTags(request);
    if (res) {
      setTagsValues(res.texts);
    }
  };

  const defaultCalendarData = useMemo(() => {
    const data = generateCalendarDefaultData();
    return data.sort(customSort);
  }, [generateCalendarDefaultData, customSort]);

  useEffect(() => {
    if (!defaultCalendarData.length || !events) {
      return;
    }

    const data = [
      ...defaultCalendarData.map((data) => ({
        ...data,
        days: [
          ...data.days.map((day) => ({
            ...day,
            events: [],
          })),
        ],
      })),
    ];

    const calendarData = processPlaybookEvents(events, [...data]);
    setStates((currState) => ({
      ...currState,
      calendarData: [...calendarData.sort(customSort)],
    }));
  }, [customSort, events, defaultCalendarData, processPlaybookEvents]);

  const filteredCalendarEvents = useMemo(
    () =>
      states.calendarData.map((item) => ({
        ...item,
        days: item.days.map((day) => ({
          ...day,
          events: day.events
            .filter(
              (event) =>
                states.selectedFilter === 'All' ||
                states.selectedFilter.toLowerCase().trim() ===
                  event.userName?.toLowerCase(),
            )
            .sort(compareEventTimings),
        })),
      })),
    [states.calendarData, states.selectedFilter],
  );

  const setSelectedFilter = (filterItem: string) => {
    setStates((prevStates) => ({ ...prevStates, selectedFilter: filterItem }));
  };

  const filterItems = useMemo(() => {
    let quickFilters = ['All'];
    if (assignee) {
      quickFilters.push(
        assignee.firstName
          ? `${assignee.firstName ?? ''} ${assignee.lastName ?? ''}`
          : assignee.email,
      );
    } else {
      quickFilters.push('Assignee');
    }

    collaborators.forEach((collaborator) => {
      let name =
        collaborator.assignedUserName ?? collaborator.assignedUserEmail;
      name = name ? `(${name})` : '';

      if (collaborator.assigneeRole === AssigneeRole.HROPS) {
        quickFilters.push(`HR Ops ${name}`);
      } else if (collaborator.assigneeRole === AssigneeRole.HRBP) {
        quickFilters.push(`HRBP ${name}`);
      } else if (collaborator.assigneeRole === AssigneeRole.IT) {
        quickFilters.push(`IT ${name}`);
      } else if (collaborator.assigneeRole !== AssigneeRole.LEGAL) {
        quickFilters.push(
          `${collaborator.assigneeRole.charAt(0) + collaborator.assigneeRole.slice(1).toLowerCase()} ${name}`,
        );
      }
    });

    return quickFilters;
  }, [collaborators, assignee]);

  useEffect(() => {
    updateIntercom({
      hideDefaultLauncher:
        states.showCreateUpdateActionPanel ||
        states.showCreateUpdateMessagePanel ||
        states.showCreateUpdateFormEventPanel,
    });
  }, [
    states.showCreateUpdateActionPanel,
    states.showCreateUpdateMessagePanel,
    states.showCreateUpdateFormEventPanel,
    updateIntercom,
  ]);

  return {
    id,
    states,
    filterItems,
    filteredCalendarEvents,
    isEventsLoading,
    setSelectedFilter,
    getDateFromWeekDay,
    setStates,
    refreshCalendarEvents,
    editEvent,
    deleteEvent,
    createEvent,
    testEvents,
    handleDragEnd,
  };
};
