import { type ReactNode } from 'react';
import clsx from 'clsx';
import styles from './FloatingButton.module.scss';

interface IFloatingButton {
  icon: ReactNode;
  position?: 'middleLeft' | 'middleRight';
  onClick: () => void;
}

export const FloatingButton = ({
  icon,
  position = 'middleLeft',
  onClick,
}: IFloatingButton) => (
  <button
    onClick={onClick}
    className={clsx(styles.floatingButton, styles[position])}
  >
    {icon}
  </button>
);
