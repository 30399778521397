import { memo, useMemo } from 'react';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { UserType } from '@base/API';
import {
  NavbarFooter,
  NavbarLogo,
  NavbarMenu,
  NavbarSupport,
} from '../../Molecules';
import './NavBar.scss';

export const Navbar = memo(() => {
  const { user } = useUserContext();
  const isAnonymous = useMemo(() => user?.type === UserType.ANONYMOUS, [user]);

  return (
    <>
      {!isAnonymous && (
        <>
          {/* <div className="d-md-block d-lg-none">
            <NavFloatingMenu />
          </div> */}
          <div className="nav-bar-wrapper sticky-top pt-4 d-none">
            <NavbarLogo />
            <NavbarMenu />
            <NavbarSupport />
            <NavbarFooter />
          </div>
        </>
      )}
    </>
  );
});
