import { useState, type FormEvent, useEffect, useCallback } from 'react';
import {
  addNotificationToApp,
  isValidEmail,
  convertChannelSpecificFormatToHtml,
} from '@Shared/utils/utils';
import {
  searchUser,
  updateEventAndRoleAssignee,
  deleteActionFromCollaborator,
  createCollaborator,
  getUserBasicData,
  updatePlaybookEvent,
  createPlaybookEvent,
  deletePlaybookEventReminder,
  updatePlaybookEventReminder,
  createPlaybookEventReminder,
} from '@api/apis';
import {
  type User,
  AssigneeRole,
  Channel,
  type EventReminder,
  type Event,
  EventStatus,
} from '@base/API';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { type ICreateUpdateAction } from '@base/models/journeyLibrary.model';
import { type IEvent } from '@base/models/playbookHome.model';
import { useEvents } from '@base/Hooks/useEvents';
import { Analytics } from '@base/analytics/analytics';
import { EventType } from '@base/models/common.model';

const allRoles = ['HRBP', 'Buddy', 'HROPS', 'Manager', 'IT'];

export const useCreateUpdateAction = (params: ICreateUpdateAction) => {
  const {
    handleShowModal,
    refreshEvents,
    builderId,
    actionEvent,
    isParentJourney,
    runbookAssignee,
    journeyStartDate,
    isTemplate,
    playbookStatus,
    isEditingAllowed,
  } = params;
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [assignees, setAssignees] = useState<(User | { role: string })[]>([]);
  const { user, setIsParentJourneyChanged } = useUserContext();
  const [selectedAssignee, setSelectedAssignee] = useState<any>();
  const [showSlackError, setShowSlackError] = useState(false);
  const [channel, setChannel] = useState(Channel.EMAIL);
  const [slackID, setSlackID] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [isEditable, setIsEditable] = useState(true);
  const [assignedJourneyUserStartDate, setAssignedJourneyUserStartDate] =
    useState<string | undefined | null>();
  const [roles, setRoles] = useState(allRoles);
  const [showCCField, setShowCCField] = useState(false);
  const [selectedRole, setSelectedRole] = useState<
    AssigneeRole | null | undefined
  >(null);
  const [reminders, setReminders] = useState<
    { id: string | null; daysFrom: string }[]
  >([]);
  const [cc, setCc] = useState<any[]>([]);
  const [selectedDaysFrom, setSelectedDaysFrom] = useState('Start day');
  const {
    getDaysFrom,
    getEventStatus,
    getSendTimeDate,
    mapDaysFromToLabel,
    uploadEventDescription,
    daysFromOptions,
    getAssigneeRole,
  } = useEvents();

  const searchAssignee = async (query: string) => {
    setIsLoading(true);
    const users = await searchUser(user.userOrganizationId, query);
    if (users) {
      setAssignees([...users]);
    }
    setIsLoading(false);
  };

  const handleReminder = (value: string, index: number) => {
    setReminders((prev) => {
      prev[index]['daysFrom'] = value;
      return [...prev];
    });
  };

  const handleSelectedAssignee = (users: any[]) => {
    if (users && users.length > 0) {
      const user = users[0];
      if (user.label && !isValidEmail(user.label)) {
        addNotificationToApp('Please add valid email', 'error');
        return;
      }

      setSelectedAssignee(users[0]);
      setSelectedRole(null);
    } else {
      if (actionEvent) {
        setSelectedRole(actionEvent.role);
      }
      setSelectedAssignee(undefined);
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setShowLoader(true);
    setIsSubmitted(true);
    if (
      title.length &&
      selectedAssignee &&
      (!reminders.length || reminders.every((reminder) => reminder.daysFrom))
    ) {
      let newActionEvent = {
        title: title,
        userId: getAssignee().selectedAssigneeId,
        emailTo: getAssignee().selectedAssigneeEmail,
        cc: cc.map(
          (user) => user.email ?? user.role?.toUpperCase() ?? user.label,
        ),
        channelId: slackID,
        type: EventType.ACTION,
        daysFrom: getDaysFrom(selectedDaysFrom),
        sendTimeDate: getSendTimeDate(selectedDaysFrom, journeyStartDate),
        status: getEventStatus(
          actionEvent.status ?? EventStatus.DRAFT,
          isParentJourney,
          playbookStatus,
          getSendTimeDate(selectedDaysFrom, journeyStartDate),
        ),
        channel,
        role: getAssigneeRole(selectedRole),
      } as Event;

      if (isTemplate) {
        newActionEvent.kinfolkTemplateID = builderId;
      } else {
        newActionEvent.journeyID = builderId;
      }

      if (isParentJourney) {
        setIsParentJourneyChanged(true);
      }

      let id;
      if (actionEvent.id) {
        if (
          selectedAssignee &&
          selectedAssignee.role &&
          selectedRole &&
          actionEvent.role !== selectedRole
        ) {
          let sideEffects: any = [
            updateEventAndRoleAssignee({
              ...newActionEvent,
              id: actionEvent.id,
              updatedInChild: !isParentJourney,
            } as Event),
          ];

          if (actionEvent.reminders && actionEvent.journeyID)
            if (actionEvent.role && actionEvent.role !== selectedRole) {
              // check if collaborator role of the action has been changed, we need to remove action from the previous collaborator role
              sideEffects = [
                ...sideEffects,
                deleteActionFromCollaborator(
                  actionEvent.id,
                  actionEvent.journeyID,
                  {
                    assigneeRole: {
                      eq: actionEvent.role.toUpperCase() as AssigneeRole,
                    },
                  },
                  !isTemplate,
                ),
              ];
            }

          [id] = await Promise.all(sideEffects);
        } else {
          id = await updatePlaybookEvent({
            ...newActionEvent,
            id: actionEvent.id,
            updatedInChild: !isParentJourney,
          } as Event);
        }

        if (id) {
          let sideEffects: any[] = [handleReminders(id)];
          if (selectedAssignee && selectedAssignee.role && selectedRole) {
            sideEffects.push(
              createCollaborator({
                actionId: id,
                builderId,
                role: selectedRole.toUpperCase() as AssigneeRole,
                isTemplate: isTemplate || false,
              }),
            );
          }

          await Promise.all(sideEffects);
          await uploadEventDescription(
            newActionEvent,
            description,
            id,
            builderId,
            isParentJourney,
          );
          refreshEvents();

          onHideModal();
        }
      } else {
        id = await createPlaybookEvent({ ...newActionEvent } as Event);
        if (id) {
          await Promise.all([
            uploadEventDescription(
              newActionEvent,
              description,
              id,
              builderId,
              isParentJourney,
            ),
            handleReminders(id),
          ]);

          if (selectedAssignee && selectedAssignee.role && selectedRole) {
            const collaborator = await createCollaborator({
              actionId: id,
              builderId,
              role: selectedRole.toUpperCase() as AssigneeRole,
              isTemplate: isTemplate || false,
            });
            if (collaborator) {
              if (collaborator.assigneeId) {
                await updatePlaybookEvent({
                  ...newActionEvent,
                  id: id,
                  userId: collaborator.assigneeId,
                  updatedInChild: !isParentJourney,
                } as Event);
              }

              refreshEvents();
            }
          } else {
            refreshEvents();
          }

          onHideModal();
          Analytics.playbookEventAdded(
            newActionEvent.type as EventType,
            newActionEvent.channel,
          );
        }
      }

      handleShowModal(false);
    }
    setShowLoader(false);
  };

  const handleReminders = async (eventId: string) => {
    let sideEffects: any[] = [];
    const reminders = getReminders(eventId);
    if (actionEvent.id && actionEvent.reminders) {
      // find deleted reminders
      const deletedReminders = actionEvent.reminders.items.filter(
        (previousReminder) =>
          !reminders.find(
            (currentReminder) =>
              previousReminder && currentReminder.id === previousReminder.id,
          ),
      );
      if (deletedReminders.length) {
        sideEffects = deletedReminders.map(
          (reminder) => reminder && deletePlaybookEventReminder(reminder.id),
        );
      }
    }

    reminders.forEach((reminder) => {
      if (reminder) {
        if (reminder.id) {
          sideEffects.push(updatePlaybookEventReminder(reminder));
        } else {
          sideEffects.push(
            createPlaybookEventReminder({
              daysFrom: reminder.daysFrom,
              status: reminder.status,
              sendTimeDate: reminder.sendTimeDate,
              eventId: reminder.eventId,
            } as EventReminder),
          );
        }
      }
    });

    await Promise.all(sideEffects);
  };

  const getAssignee = () => {
    let selectedAssigneeId: string | null = null;
    let selectedAssigneeEmail: string | null = null;

    if (selectedAssignee?.label) {
      selectedAssigneeEmail = selectedAssignee.label;
    } else {
      selectedAssigneeId = selectedAssignee?.id ?? null;
      selectedAssigneeEmail = selectedAssignee?.email ?? null;
    }

    return { selectedAssigneeId, selectedAssigneeEmail };
  };

  const removeReminder = (index: number) => {
    setReminders((prevReminders) => {
      prevReminders.splice(index, 1);
      return [...prevReminders];
    });
  };

  const getReminders = (eventId: string): EventReminder[] =>
    reminders.map(
      (reminder) =>
        ({
          id: reminder.id,
          eventId,
          sendTimeDate: getSendTimeDate(
            reminder.daysFrom,
            journeyStartDate ?? assignedJourneyUserStartDate,
          ),
          daysFrom: getDaysFrom(reminder.daysFrom),
          status: getEventStatus(
            EventStatus.DRAFT,
            isParentJourney,
            playbookStatus,
            getSendTimeDate(
              reminder.daysFrom,
              journeyStartDate ?? assignedJourneyUserStartDate,
            ),
          ),
        }) as EventReminder,
    );

  const setActionReminders = useCallback(
    (
      reminders: (EventReminder | null)[],
    ): { id: string | null; daysFrom: string }[] =>
      reminders.map((reminder) => {
        if (reminder) {
          return {
            id: reminder.id,
            daysFrom: mapDaysFromToLabel(reminder.daysFrom),
          };
        }

        return { id: null, daysFrom: 'Start day' };
      }),
    [mapDaysFromToLabel],
  );

  const handleChannelType = async (channel: Channel) => {
    let slackError = false;
    if (channel === Channel.SLACK) {
      slackError = user.Organization?.slackAccessToken === '' ? true : false;
    } else {
      slackError = false;
    }
    if (!slackError) {
      setSlackID(selectedAssignee ? (selectedAssignee.slackUserId ?? '') : '');
    } else {
      setSlackID('');
    }
    setShowSlackError(slackError);
    setChannel(channel);
  };

  const getAssignedJourneyUserData = async (id: string) => {
    const user = await getUserBasicData(id);
    if (user) {
      setAssignedJourneyUserStartDate(user.startDate);
    }
  };

  const onSelectRole = (selectedRole: AssigneeRole | string) => {
    selectedRole = selectedRole
      .split(' ')
      .join('')
      .toUpperCase() as AssigneeRole;
    setRoles(
      allRoles.filter(
        (role) => role.toUpperCase() !== selectedRole.toUpperCase(),
      ),
    );
    setSelectedAssignee({
      role: allRoles.find(
        (role) => role.toLowerCase() === selectedRole.toLowerCase(),
      ),
    });
    setSelectedRole(selectedRole as AssigneeRole);
  };

  const setEmailCC = async (event: IEvent) => {
    if (event.cc && event.cc.length) {
      setCc(
        event.cc.map((item) => {
          if (
            Object.values(AssigneeRole).includes(
              item?.toUpperCase() as AssigneeRole,
            )
          ) {
            return {
              role: item,
            };
          }

          return {
            email: item,
          };
        }),
      );

      setShowCCField(true);
    } else {
      setCc([]);
    }
  };

  const onHideModal = () => {
    handleShowModal(false);
    setIsSubmitted(false);
    setDescription('');
    setTitle('');
    setCc([]);
    setShowCCField(false);
    setReminders([]);
    setSelectedAssignee(undefined);
    setSlackID('');
    setShowLoader(false);
    setSelectedRole(null);
    setRoles(allRoles);
    setChannel(Channel.EMAIL);
  };

  useEffect(() => {
    runbookAssignee && getAssignedJourneyUserData(runbookAssignee.id);
  }, [runbookAssignee]);

  useEffect(() => {
    if (actionEvent.id && actionEvent.type === EventType.ACTION) {
      setTitle(actionEvent.title ?? '');
      setDescription(
        convertChannelSpecificFormatToHtml(
          actionEvent.message ?? '',
          actionEvent.channel,
        ),
      );
      setSelectedDaysFrom(mapDaysFromToLabel(actionEvent.daysFrom));
      setSelectedRole(actionEvent.role);
      setChannel(actionEvent.channel);
      setEmailCC(actionEvent);
      setReminders(
        setActionReminders(
          actionEvent.reminders
            ? actionEvent.reminders.items
            : ([] as EventReminder[]),
        ),
      );
      const assignee = {
        id: actionEvent.userId,
        email: actionEvent.emailTo ?? '',
        role: actionEvent.role,
        name: actionEvent.userName,
      };
      setSelectedAssignee(assignee);
      setRoles(
        allRoles.filter(
          (role) =>
            actionEvent.role?.toLocaleLowerCase() !== role.toLocaleLowerCase(),
        ),
      );
      setIsEditable(
        actionEvent.status !== EventStatus.DONE && !!isEditingAllowed,
      );
    } else if (actionEvent.daysFrom) {
      setSelectedDaysFrom(mapDaysFromToLabel(actionEvent.daysFrom));
    }
  }, [
    assignedJourneyUserStartDate,
    isParentJourney,
    journeyStartDate,
    actionEvent,
    setActionReminders,
    mapDaysFromToLabel,
    isEditingAllowed,
  ]);

  useEffect(() => {
    setSlackID(selectedAssignee ? (selectedAssignee.slackUserId ?? '') : '');
  }, [selectedAssignee]);

  return {
    title,
    description,
    assignees,
    selectedAssignee,
    slackID,
    isSubmitted,
    isLoading,
    showLoader,
    selectedRole,
    showSlackError,
    roles,
    showCCField,
    reminders,
    cc,
    channel,
    daysFromOptions,
    selectedDaysFrom,
    isEditable,
    setChannel,
    setCc,
    handleSubmit,
    setReminders,
    handleReminder,
    setShowCCField,
    onSelectRole,
    setSelectedRole,
    setDescription,
    setAssignees,
    setSelectedAssignee,
    setSlackID,
    searchAssignee,
    handleSelectedAssignee,
    onHideModal,
    setTitle,
    handleChannelType,
    removeReminder,
    setSelectedDaysFrom,
  };
};
