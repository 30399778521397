import { useEffect, useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { type ShareInfo } from '@API';
import EditLearnMoreModal from '../LearnMoreModal/EditLearnMoreModal';
import './LearnMore.scss';

const LearnMore = () => {
  const [showModal, setShowModal] = useState(false);
  const { user, allOrganisationShareInfo } = useUserContext();

  const [suggestOptions, setSuggestOptions] = useState<ShareInfo[]>([]);

  useEffect(() => {
    setSuggestOptions(allOrganisationShareInfo);
  }, [allOrganisationShareInfo]);

  return (
    <div className="learn-more-wrapper">
      <Card>
        <Card.Body>
          <Row>
            <Col xs="9">
              <h4 className="fs-20-semi-bold-4C5564">I'm looking to learn</h4>
            </Col>
            <Col xs="3" className="text-end edit-btn">
              <span className="edit-btn d-inline">
                <svg
                  onClick={() => setShowModal(true)}
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="45"
                  height="45"
                  viewBox="0 0 58 58"
                >
                  <defs>
                    <filter
                      id="Ellipse_45"
                      x="0"
                      y="0"
                      width="58"
                      height="58"
                      filterUnits="userSpaceOnUse"
                    >
                      <feGaussianBlur stdDeviation="3" result="blur" />
                      <feFlood floodColor="#111828" floodOpacity="0.078" />
                      <feComposite operator="in" in2="blur" />
                      <feComposite in="SourceGraphic" />
                    </filter>
                  </defs>
                  <g
                    id="Button_-_Navigation_-_Edit"
                    data-name="Button - Navigation - Edit"
                    transform="translate(9 6)"
                  >
                    <g
                      transform="matrix(1, 0, 0, 1, -9, -6)"
                      filter="url(#Ellipse_45)"
                    >
                      <circle
                        id="Ellipse_45-2"
                        data-name="Ellipse 45"
                        cx="20"
                        cy="20"
                        r="20"
                        transform="translate(9 6)"
                        fill="#fff"
                      />
                    </g>
                    <g
                      id="Pencil_2"
                      data-name="Pencil 2"
                      transform="translate(13 11)"
                    >
                      <path
                        id="Shape"
                        d="M11.225,16H3.543c-.505,0-.9,0-1.237-.027a2.5,2.5,0,0,1-.963-.241A2.446,2.446,0,0,1,.268,14.656a2.492,2.492,0,0,1-.24-.963C0,13.36,0,12.961,0,12.456V4.774c0-.505,0-.9.028-1.237a2.492,2.492,0,0,1,.24-.963A2.446,2.446,0,0,1,1.344,1.5a2.491,2.491,0,0,1,.963-.24c.333-.028.732-.028,1.237-.028h6.3L8.616,2.461H3.569c-.542,0-.891,0-1.162.023a1.378,1.378,0,0,0-.5.111,1.232,1.232,0,0,0-.538.538,1.378,1.378,0,0,0-.111.5c-.022.271-.023.62-.023,1.162v7.631c0,.543,0,.892.023,1.163a1.38,1.38,0,0,0,.111.5,1.223,1.223,0,0,0,.538.537,1.287,1.287,0,0,0,.5.111c.277.023.629.023,1.162.023H11.2c.533,0,.885,0,1.163-.023a1.356,1.356,0,0,0,.5-.111A1.231,1.231,0,0,0,13.4,14.1a1.38,1.38,0,0,0,.111-.5c.023-.277.023-.629.023-1.163V7.385l1.231-1.231v6.3c0,.505,0,.9-.027,1.237a2.5,2.5,0,0,1-.241.963,2.446,2.446,0,0,1-1.076,1.076,2.5,2.5,0,0,1-.963.241C12.129,16,11.73,16,11.225,16ZM5.539,11.077a.615.615,0,0,1-.551-.89L7.4,5.352a1.238,1.238,0,0,1,.23-.32L12.488.18a.614.614,0,0,1,.87,0L15.82,2.642a.616.616,0,0,1,0,.871L10.967,8.364a1.193,1.193,0,0,1-.32.23L5.813,11.012A.615.615,0,0,1,5.539,11.077Zm7.385-9.592h0L8.506,5.9,7.228,8.458l.313.313L10.1,7.495l4.417-4.418L12.923,1.485Z"
                      />
                    </g>
                  </g>
                </svg>
              </span>
            </Col>
          </Row>
          {user.LearnMoreOn?.items.map((interest: any, i: number) => (
            <div key={i} className="pill-button">
              {interest.shareInfo !== undefined
                ? interest.shareInfo.name
                : interest.name}
            </div>
          ))}
        </Card.Body>
      </Card>

      {showModal && (
        <EditLearnMoreModal
          handleShowModal={setShowModal}
          showModal={showModal}
          suggestOptions={suggestOptions}
          setSuggestOptions={setSuggestOptions}
          options={suggestOptions}
          currentNumber={2}
          alreadySelectedOptions={user.LearnMoreOn}
        />
      )}
    </div>
  );
};

export default LearnMore;
