interface IAlert {
  type:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'light'
    | 'dark';
  text: string;
  icon?: string;
}
export const Alert = (props: IAlert) => {
  const { type, text, icon } = props;

  return (
    <div
      className={`alert alert-${type} d-flex align-items-center gap-2`}
      role="alert"
    >
      {icon && <i className={icon}></i>}
      <div>{text}</div>
    </div>
  );
};
