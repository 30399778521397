import { UserType } from '@base/API';

export const emptyState = {
  firstName: '',
  lastName: '',
  email: '',
  type: UserType.COMPANY_MEMBER,
  isSaving: false,
  isSubmitted: false,
  customFields: {},
};
