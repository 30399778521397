import { Dropdown } from 'react-bootstrap';
import { FaBars } from 'react-icons/fa6';
import { type MouseEventHandler } from 'react';
import { type IconType } from 'react-icons';
import { addZIndexToQuerySelector } from '@Shared/utils/utils';
import './CustomDropdown.scss';

interface ICustomDropdown {
  items: {
    title: string;
    onClick: MouseEventHandler<HTMLElement>;
    disabled?: boolean;
  }[];
  className?: string;
  title?: string;
  Icon?: IconType;
}

export const CustomDropdown = ({
  items,
  title,
  className,
  Icon,
}: ICustomDropdown) => (
  <Dropdown className={`custom-dropdown ${className ?? ''}`}>
    <Dropdown.Toggle className="p-0" variant="">
      <div onClick={(e) => addZIndexToQuerySelector(e, '.card')}>
        {Icon ? <Icon /> : <FaBars />} {title ?? ''}
      </div>
    </Dropdown.Toggle>

    <Dropdown.Menu>
      {items.map((item) => (
        <Dropdown.Item
          key={item.title}
          disabled={item.disabled}
          onClick={item.onClick}
        >
          {item.title}
        </Dropdown.Item>
      ))}
    </Dropdown.Menu>
  </Dropdown>
);
