import { useEffect, useState } from 'react';
import { Channel, type Event, EventStatus } from '@API';
import { createPlaybookEvent, updatePlaybookEvent } from '@api/apis';
import { convertChannelSpecificFormatToHtml } from '@Shared/utils/utils';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { type AddMessageModalProps } from '@base/models/journeyLibrary.model';
import { useEvents } from '@base/Hooks/useEvents';
import { Analytics } from '@base/analytics/analytics';
import { EventType } from '@base/models/common.model';
import { useMessengerChannel } from '@Hooks/useMessengerChannel';

export const useMessengerTab = (props: AddMessageModalProps) => {
  const {
    isParentJourney,
    builderId,
    assigneeSlackId,
    messageEvent,
    isTemplate,
    handleSave,
    onHideModal,
    handleUpdate,
    assigneeHireDate,
    assigneeId,
    journeyStartDate: playbookStartDate,
    playbookStatus,
    isEditingAllowed,
  } = props;
  const [messageBody, setMessageBody] = useState('');
  const [time, setTime] = useState('09:00');
  const times = Array.from({ length: 24 }, (_, i) =>
    i < 9 ? `0${i + 1}:00` : `${i + 1}:00`,
  );
  const [isSaving, setIsSaving] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const { user, setIsParentJourneyChanged } = useUserContext();
  const [isSubmitted, setSubmitted] = useState(false);
  const [isEditable, setIsEditable] = useState(true);
  const [selectedDaysFrom, setSelectedDaysFrom] = useState('Start day');
  const {
    getDaysFrom,
    getEventStatus,
    getSendTimeDate,
    mapDaysFromToLabel,
    uploadEventDescription,
    daysFromOptions,
  } = useEvents();
  const channel = useMessengerChannel();

  const createNewMessage = async () => {
    setSubmitted(true);
    setShowLoader(true);
    if (messageBody.length) {
      setIsSaving(true);

      let messageEventRequest = {
        userId: assigneeId,
        channelId: assigneeSlackId,
        type: EventType.MESSAGE,
        daysFrom: getDaysFrom(selectedDaysFrom),
        sendTime: time,
        sendTimeDate: getSendTimeDate(
          selectedDaysFrom,
          playbookStartDate ?? assigneeHireDate,
          time,
        ),
        status: getEventStatus(
          messageEvent.status ?? EventStatus.DRAFT,
          isParentJourney,
          playbookStatus,
          getSendTimeDate(
            selectedDaysFrom,
            playbookStartDate ?? assigneeHireDate,
            time,
          ),
        ),
        channel: user?.Organization.msTeamsTenantId
          ? Channel.TEAMS
          : Channel.SLACK,
      } as Event;

      if (isTemplate) {
        messageEventRequest = {
          ...messageEventRequest,
          kinfolkTemplateID: builderId,
        };
      } else {
        messageEventRequest = {
          ...messageEventRequest,
          journeyID: builderId,
        };
      }

      if (isParentJourney) {
        setIsParentJourneyChanged(true);
      }

      let id;
      if (messageEvent.id) {
        id = await updatePlaybookEvent({
          ...messageEventRequest,
          id: messageEvent.id,
          updatedInChild: !isParentJourney,
        } as Event);
        if (id) {
          messageEventRequest.message = await uploadEventDescription(
            messageEventRequest,
            messageBody,
            id,
            builderId,
            isParentJourney,
          );
          handleUpdate({ ...messageEventRequest, id: id });
        }
      } else {
        id = await createPlaybookEvent({ ...messageEventRequest } as Event);
        if (id) {
          messageEventRequest.message = await uploadEventDescription(
            messageEventRequest,
            messageBody,
            id,
            builderId,
            isParentJourney,
          );
          handleSave({ ...messageEventRequest, id: id });

          Analytics.playbookEventAdded(
            messageEventRequest.type as EventType,
            messageEventRequest.channel,
          );
        }
      }
      if (id) {
        handleHideModal();
      }
    }
    setShowLoader(false);
  };

  const handleHideModal = () => {
    onHideModal();
    setSubmitted(false);
    setIsSaving(false);
    setMessageBody('');
  };

  useEffect(() => {
    setSubmitted(false);
    if (messageEvent.id) {
      setMessageBody(
        convertChannelSpecificFormatToHtml(messageEvent.message ?? '', channel),
      );
      setIsEditable(
        messageEvent.status !== EventStatus.SENT && !!isEditingAllowed,
      );
      setTime(messageEvent.sendTime ?? '09:00');
      setSelectedDaysFrom(mapDaysFromToLabel(messageEvent.daysFrom));
    } else if (messageEvent.daysFrom) {
      setSelectedDaysFrom(mapDaysFromToLabel(messageEvent.daysFrom));
    }
  }, [channel, isEditingAllowed, mapDaysFromToLabel, messageEvent]);

  return {
    showLoader,
    onHideModal,
    messageBody,
    isSaving,
    isSubmitted,
    isEditable,
    user,
    time,
    times,
    daysFromOptions,
    selectedDaysFrom,
    setSelectedDaysFrom,
    setTime,
    setMessageBody,
    createNewMessage,
  };
};
