import { useEffect } from 'react';
import { useTabs } from '@base/Hooks/useTabs';
import { TabKeys } from '@base/models/playbookHome.model';
import { useNavigate, useLocation } from 'react-router-dom';

export const usePlaybookHomeTabs = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { key, setKey } = useTabs(TabKeys.CALENDAR);

  const navigateTo = (path: string) => {
    navigate(path);
  };

  useEffect(() => {
    if (location.pathname.includes('assignee')) {
      setKey(TabKeys.PARTICIPANTS);
    } else if (location.pathname.includes('meetings')) {
      setKey(TabKeys.MEETINGS);
    } else {
      setKey(TabKeys.CALENDAR);
    }
  }, [location, setKey]);

  return {
    key,
    setKey,
    navigateTo,
  };
};
