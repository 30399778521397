import { fetchJourneys, getFilteredUser, updateJourney } from '@api/apis';
import { type Journey, JourneyStatus } from '@base/API';
import { useUserContext } from '@base/Context/UserContext/UserContext';
import { addNotificationToApp } from '@Shared/utils/utils';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const usePreboardingLinkVerificationModal = () => {
  const [states, setStates] = useState({
    email: '',
    showModal: false,
    showLoader: false,
    isSearching: false,
    playbook: {} as Journey,
  });
  const navigate = useNavigate();
  const { setUser } = useUserContext();

  const handlePreboardingUser = useCallback(async () => {
    setStates((prev) => ({ ...prev, showLoader: true }));
    const link = extractUrlBeforeQueryString(window.location.href);
    const playbooks = await fetchJourneys({
      publicLink: { eq: link },
      status: { ne: JourneyStatus.DRAFT },
      isArchived: { eq: false },
    });
    if (!playbooks || !playbooks.length) {
      addNotificationToApp('Link not found.', 'error');
      navigate('/login');
      return;
    }

    const [playbook] = playbooks;
    setStates((prev) => ({
      ...prev,
      showLoader: false,
      showModal: true,
      playbook,
    }));
  }, [navigate]);

  const startPlaybook = async () => {
    setStates((prev) => ({ ...prev, isSearching: true }));
    const user = await getFilteredUser({
      or: [
        { email: { eq: states.email } },
        { personalEmail: { eq: states.email } },
      ],
    });

    if (!user) {
      addNotificationToApp('User not found.', 'error');
      setStates((prev) => ({ ...prev, isSearching: false }));
    } else if (user.id !== states.playbook.assignedUserID) {
      addNotificationToApp('Email address not matched.', 'error');
      setStates((prev) => ({ ...prev, isSearching: false }));
    } else if (states.playbook.userStartedAt) {
      setUser({ ...user });
      navigate(`/runbook/${states.playbook.id}/microsite`);
    } else {
      setUser({ ...user });
      await updateJourney({
        id: states.playbook.id,
        userStartedAt: new Date().toISOString().split('T')[0],
      } as Journey);
      navigate(`/runbook/${states.playbook.id}/microsite`);
    }
    setStates((prev) => ({ ...prev, isSearching: false }));
  };

  const extractUrlBeforeQueryString = (url: string) => {
    const index = url.indexOf('/?');
    if (index !== -1) {
      return url.substring(0, index);
    } else {
      return url;
    }
  };

  useEffect(() => {
    handlePreboardingUser();
  }, [handlePreboardingUser]);

  return {
    states,
    setStates,
    startPlaybook,
  };
};
